import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    border-color: transparent;
    margin-left: 8px;
    font-size: 18.5px;
    font-family: neue-montreal-regular;
    width: 100%;
    background: transparent;
    &:focus {
        outline: none;
    }
`;

const Container = styled.div`
    border-color: #C8C8C8;
    border-width: 1px;
    border-style: solid;
    border-radius: 40px;
    background-color: #FFF;
    width: 251px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: ${(({marginRight}) => marginRight ? marginRight : '0')}px;
    &:hover {
        border-color: #EB9542;
    }
    &:active {
        border-color: #AB5D12;
    }
`;

const Icon = styled.img`
    width: 13px;
    height: 13px;
    margin-left: 16px;
`;

const InputSearch = (props) => {
    return (
        <Container marginRight={props.marginRight}>
            <Icon src={require('@images/icons/search.png')} />
            <Input {...props} />
        </Container>
    )
}

export default InputSearch;