import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_PATIENT_FILES,
  QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
  QUERY_GET_PENDING_MESSAGES,
} from '@graphql/query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import Input from '@components/shared/Input';
import History from '@components/shared/History';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import {
  MUTATION_UPDATE_PATIENT,
  MUTATION_ADD_PHOTO_PATIENT,
  MUTATION_ARCHIVIED_TREATMENT,
  MUTATION_DAY_SMARTCHECK,
  MUTATION_UPLOAD_EVIDENCE,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import { Link } from 'react-router-dom';
import { validateEmail, loadPhotoProfileGender } from '../../utils';
import Skeleton from 'react-loading-skeleton';
import Card from '../../components/shared/Card';
import ResumeSS from '../../components/DoctorFormSS/resume';
import ResumeCA from '../../components/DoctorFormCA/resume';
import Details from '../../components/Case/Details';
import Actions from '../../components/Case/Actions';
import CardWithLink from '../../components/Case/CardWithLink';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const Case = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [edit, setEdit] = useState(false);
  const [historyClinic, setHistoryClinic] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [patient, setPatient] = useState(null);
  const [countryName, setCountryName] = useState('');
  const [treatment, setTreatment] = useState('');
  const [treatmentRelated, setTreatmentRelated] = useState(null);
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [validDate, setValidDate] = useState(true);
  const [archived, setArchived] = useState(false);
  const [photo, setPhoto] = useState('');
  const [photoEvidences, setPhotoEvidences] = useState('');
  const [selectItem, setSelectItem] = useState('Formulario');
  const [file, setFile] = useState({});

  const [filesTreatment, setFilesTreatment] = useState({});
  const [filesTreatmentStl, setFilesTreatmentSlt] = useState({});
  const [sendModelStl, setSendModelStl] = useState(false);
  const [sendLinkStl, setSendLinkStl] = useState(false);
  const [activeBasicChat, setActiveBasicChat] = useState(false);
  const [hasBasicChat, setHasBasicChat] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [link_data, setLinkData] = useState(['']);
  const [statusSm, setStatusSm] = useState('');
  const [treatmentStatus, setTreatmentStatus] = useState({});
  const [histories, setHistories] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [treatmentId, setTreatmentId] = useState('');

  const [formCAData, setFormCAData] = useState({});
  const [formSSData, setFormSSData] = useState({});
  const [pendingMessages, setPendingMessages] = useState(null);

  const role = localStorage.getItem('b360-role');

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;
        setPatient({
          id: parseInt(patient.id),
          firstName: patient.first_name,
          lastName: patient.last_name,
        });
        setTreatmentRelated(patient.treatment_related);
        let up_odontograma = [];
        let lo_odontograma = [];
        if (patient.treatment_related.formOdontogram) {
          const odontograma = patient.treatment_related.formOdontogram;
          up_odontograma = [
            JSON.parse(odontograma.up_left_third_molar_data),
            JSON.parse(odontograma.up_left_second_molar_data),
            JSON.parse(odontograma.up_left_first_molar_data),
            JSON.parse(odontograma.up_left_second_premolar_data),
            JSON.parse(odontograma.up_left_first_premolar_data),
            JSON.parse(odontograma.up_left_canine_data),
            JSON.parse(odontograma.up_left_lateral_incisor_data),
            JSON.parse(odontograma.up_left_central_incisor_data),
            JSON.parse(odontograma.up_right_third_molar_data),
            JSON.parse(odontograma.up_right_second_molar_data),
            JSON.parse(odontograma.up_right_first_molar_data),
            JSON.parse(odontograma.up_right_second_premolar_data),
            JSON.parse(odontograma.up_right_first_premolar_data),
            JSON.parse(odontograma.up_right_canine_data),
            JSON.parse(odontograma.up_right_lateral_incisor_data),
            JSON.parse(odontograma.up_right_central_incisor_data),
          ];
          lo_odontograma = [
            JSON.parse(odontograma.lo_left_third_molar_data),
            JSON.parse(odontograma.lo_left_second_molar_data),
            JSON.parse(odontograma.lo_left_first_molar_data),
            JSON.parse(odontograma.lo_left_second_premolar_data),
            JSON.parse(odontograma.lo_left_first_premolar_data),
            JSON.parse(odontograma.lo_left_canine_data),
            JSON.parse(odontograma.lo_left_lateral_incisor_data),
            JSON.parse(odontograma.lo_left_central_incisor_data),
            JSON.parse(odontograma.lo_right_third_molar_data),
            JSON.parse(odontograma.lo_right_second_molar_data),
            JSON.parse(odontograma.lo_right_first_molar_data),
            JSON.parse(odontograma.lo_right_second_premolar_data),
            JSON.parse(odontograma.lo_right_first_premolar_data),
            JSON.parse(odontograma.lo_right_canine_data),
            JSON.parse(odontograma.lo_right_lateral_incisor_data),
            JSON.parse(odontograma.lo_right_central_incisor_data),
          ];
        }

        if (
          up_odontograma ===
          [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ]
        )
          up_odontograma = null;
        if (
          lo_odontograma ===
          [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ]
        )
          lo_odontograma = null;

        setFormCAData({
          arcad: patient.treatment_related.formArcade,
          shape: patient.treatment_related.formShape,
          ipr: patient.treatment_related.formIpr,
          odontograma: patient.treatment_related.formOdontogram,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });
        setFormSSData({
          bracket_ss: patient.treatment_related.bracket_ss,
          odontograma: patient.treatment_related.formOdontogram,
          brackets: patient.treatment_related.formBracket,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setPhone(patient.phone);
        setCountryName(patient.doctor.user.country.name);
        setGender(patient.gender);
        setPhoto(
          patient.photo_filename
            ? `${process.env.REACT_APP_API_URL}/patients/${id}/profile/${patient.photo_filename}`
            : ''
        );
        setEmail(patient.email);
        setTreatment(patient.treatment);
        setTreatmentId(patient.treatment_related.id);
        setTreatmentStatus(patient.treatment_related.treatmentStatus);
        setArchived(patient.treatment_related.archived);
        setHistories(patient.treatment_related.histories);
        // patient.treatment_related.channels.map((item, key) => {
        //   if (
        //     item.channel_url ===
        //       `${
        //         process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
        //       }private_chat_treatment_${patient.treatment_related.id}` &&
        //     item.status === 'active'
        //   ) {
        //     setActiveBasicChat(true);
        //   }
        //   if (
        //     item.channel_url ===
        //     `${
        //       process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
        //     }private_chat_treatment_${patient.treatment_related.id}`
        //   ) {
        //     setHasBasicChat(true);
        //   }
        // });
        // patient.treatment_related.channelNotifications.map((item, key) => {
        //   if (
        //     (item.channel.channel_url ===
        //       `${
        //         process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
        //       }private_chat_treatment_${patient.treatment_related.id}` ||
        //       item.channel.channel_url ===
        //         `${
        //           process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
        //         }private_chat_planning_treatment_${
        //           patient.treatment_related.id
        //         }`) &&
        //     item.new_message_admin
        //   ) {
        //     setHasMessageAdmin(true);
        //   }
        // });
        setStatusSm(
          patient.treatment_related.smartsChecks.length > 0
            ? patient.treatment_related.smartsChecks[
                patient.treatment_related.smartsChecks.length - 1
              ].status
            : ''
        );

        if (patient.birthdate) {
          setDay(patient.birthdate.split('-')[2]);
          setMonth(patient.birthdate.split('-')[1]);
          setYear(patient.birthdate.split('-')[0]);
        }
        if (patient.treatment_related.stl_type === 'file') {
          let objectStls = {};
          let arcada_superior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_superior'
          );
          if (arcada_superior) {
            objectStls = {
              ...objectStls,
              arcada_superior: {
                file: {
                  name: arcada_superior.file_name,
                  file_url: arcada_superior.fileUrl,
                },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion_derecha = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_derecha'
          );
          if (oclusion_derecha) {
            objectStls = {
              ...objectStls,
              oclusion_derecha: {
                file: {
                  name: oclusion_derecha.file_name,
                  file_url: oclusion_derecha.fileUrl,
                },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion_izquierda = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_izquierda'
          );
          if (oclusion_izquierda) {
            objectStls = {
              ...objectStls,
              oclusion_izquierda: {
                file: {
                  name: oclusion_izquierda.file_name,
                  file_url: oclusion_izquierda.fileUrl,
                },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let arcada_inferior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_inferior'
          );
          if (arcada_inferior) {
            objectStls = {
              ...objectStls,
              arcada_inferior: {
                file: {
                  name: arcada_inferior.file_name,
                  file_url: arcada_inferior.fileUrl,
                },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          setFilesTreatmentSlt({
            ...filesTreatmentStl,
            ...objectStls,
          });
        } else if (patient.treatment_related.stl_type === 'url') {
          setSendLinkStl(true);
          setLinkData(
            patient.treatment_related.link_data
              ? JSON.parse(patient.treatment_related.link_data)
              : ['']
          );
        } else if (patient.treatment_related.stl_type === 'shipping') {
          setSendModelStl(true);
          getDefaultAddress({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              patient_id: id,
            },
          });
        }
        // if (patient.treatment_related.id) {
        //   getChatHisotry({
        //     variables: {
        //       channel_url: `${
        //         process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
        //       }private_chat_treatment_${patient.treatment_related.id}`,
        //     },
        //   });
        // }
        getPendingMessages({
          variables: { treatment: patient.treatment_related.id },
        });
        getPatientFiles({ variables: { patient_id: id, onlyErrors: false } });
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  const [getPendingMessages] = useLazyQuery(QUERY_GET_PENDING_MESSAGES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pendingChatMessages) {
        setPendingMessages(data.pendingChatMessages.length);
      } else {
        setPendingMessages(0);
      }
    },
  });

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;

        let decode = {};
        patientfiles.map((item) => {
          decode = {
            ...decode,
            [item.name]: {
              type: item.file_type,
              name: item.file_name,
              url: item.fileUrl,
            },
          };
        });
        setFilesTreatment(decode);
        if (decode.evidence)
          setPhotoEvidences(decode.evidence.url ? decode.evidence.url : '');
      }
    },
    onError: (err) => console.log(err),
  });

  const [uploadEvidence] = useMutation(MUTATION_UPLOAD_EVIDENCE, {
    onCompleted: (data) => {
      if (data.addPatientEvidencePhoto) {
        modal.close();
        if (
          !(treatmentStatus.type === 'finished' && treatmentStatus.index === 3)
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  setPhotoEvidences(
                    data.addPatientEvidencePhoto.fileUrl
                      ? data.addPatientEvidencePhoto.fileUrl
                      : ''
                  );
                  getPatient({ variables: { id } });
                })
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                ¡Felicidades! Un caso más a la bolsa
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Evidencias de fin de caso subidas, puedes consultarlas en tu
                historial clínico. <br />
                Te notificaremos de la acumulación de puntos cuando definamos la
                dificultad de tu caso.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  getPatient({ variables: { id } });
                })
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Evidencia actualizada
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Fotografía de evidencia correctamente actualizada
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    },
  });

  const [getDefaultAddress] = useLazyQuery(QUERY_GET_DEFAULT_ADDRESS_DOCTOR, {
    onCompleted: (data) => {
      if (data.getDefaultAddress) {
        setDefaultAddress(data.getDefaultAddress);
      }
    },
    onError: (err) => console.log(err, 'error address'),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getPatient({ variables: { id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (
        treatmentStatus.type === 'planning' &&
        treatmentStatus.index !== 7 &&
        statusSm === 'Pending' &&
        statusSm !== ''
      ) {
        changeDaySmartcheck({ variables: { patient_id: id } });
      }
    }, 2000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentStatus, statusSm]);

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: (data) => {
      if (file.name) addProfilePhoto({ variables: { file, patient_id: +id } });
      else {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setEdit(false);
                getPatient({ variables: { id } });
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_PHOTO_PATIENT, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setEdit(false);
              getPatient({ variables: { id } });
            })
          }
        >
          <LabelNormal color="#060809" fontSize="22" style={{ marginTop: 30 }}>
            Cambios a perfil guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const onChangeEvidences = (evt) => {
    const prefile = evt.target.files[0];
    if (prefile) {
      setPhotoEvidences(URL.createObjectURL(prefile));
      uploadEvidence({ variables: { file: prefile, patient_id: id } });
    }
  };

  const [archivedTreatment] = useMutation(MUTATION_ARCHIVIED_TREATMENT, {
    onCompleted: (data) => {
      if (data.changeArchivedTreatment) {
        modal.close();
        getPatient({ variables: { id } });
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  const [changeDaySmartcheck] = useMutation(MUTATION_DAY_SMARTCHECK, {
    onCompleted: (data) => {
      if (data.daySmartcheck) {
        getPatient({ variables: { id } });
      }
    },
  });

  const handleOpenModal = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se pausará el tratamiento
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Puedes encontrarlo en el tablero de tus casos buscando por nombre o
          filtrando por casos inactivos
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            archivedTreatment({ variables: { patient_id: id, archived: true } })
          }
          label="Mover a casos inactivos"
        />
      </ModalWarning>
    );
  };

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {
      let date = moment(`${year}-${month}-${day}`);
      setValidDate(date.isValid());
    }
  }, [day, month, year]);

  const historyBack = () => {
    if (historyClinic) {
      setHistoryClinic(false);
    } else if (edit) {
      setEdit(false);
    } else {
      history.push('/doctor/tus-casos');
    }
  };

  const navigateToInvoices = () => {
    history.push({
      pathname: `/doctor/invoices/${treatmentId}`,
      state: {
        firstName,
        lastName,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => historyBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {!edit && !historyClinic && (
                <div className="col-md-12">
                  <Title>Caso</Title>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.push('doctor/tus-casos')}
                      mainLabel={'Tus casos'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                </div>
              )}
              {edit && (
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <Title>Editar perfil</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Edita los datos y guarda los cambios.
                  </LabelNormal>
                </div>
              )}
              {historyClinic && (
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                  <Title>Historial clínico</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Consulta los archivos de tu paciente.
                  </LabelNormal>
                </div>
              )}
              <div className={historyClinic ? 'col-md-12' : 'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    {(treatmentStatus.type === 'finished' &&
                      treatmentStatus.index === 2) ||
                    photoEvidences !== '' ? (
                      <>
                        <InlineContainer
                          style={{ alignItems: 'center', marginBottom: 10 }}
                        >
                          {!loadingSkeleton ? (
                            <>
                              <Avatar
                                src={
                                  photo ? photo : loadPhotoProfileGender(gender)
                                }
                                floatButton={edit}
                                edit={!!photo}
                                onChange={onChange}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                              <FeatherIcon
                                icon="arrow-right"
                                size={45}
                                style={{ marginLeft: 40, marginRight: 40 }}
                              />
                              <Avatar
                                src={
                                  photoEvidences
                                    ? photoEvidences
                                    : loadPhotoProfileGender(gender)
                                }
                                onChange={onChangeEvidences}
                                floatButton
                                edit={photoEvidences !== '' ? true : false}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                            </>
                          ) : (
                            <div>
                              <Skeleton
                                height={161}
                                borderRadius={27}
                                width={161}
                                baseColor={'#EEEEF2'}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          )}
                        </InlineContainer>
                        <InlineContainer>
                          {!loadingSkeleton ? (
                            <>
                              {!edit && !historyClinic && (
                                <div style={{ marginLeft: 35 }}>
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <LabelNormal
                                      color="#436FF1"
                                      fontSize="21"
                                      style={{ marginRight: 7 }}
                                    >
                                      PX.
                                    </LabelNormal>
                                    <LabelNormal
                                      fontSize="30"
                                      color="#3C4549"
                                    >{`${firstName} ${lastName}`}</LabelNormal>
                                  </InlineContainer>
                                  <InlineContainer>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <InlineContainer>
                                          <LabelNormal
                                            color="#4F4F4F"
                                            fontSize="20"
                                          >
                                            ID
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#BDBDBD"
                                            fontSize="20"
                                          >
                                            {id}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      <div className="col-md-12">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="phone" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {phone}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      {email && (
                                        <div className="col-md-7">
                                          <InlineContainer
                                            style={{ alignItems: 'center' }}
                                          >
                                            <FeatherIcon
                                              icon="email"
                                              size={19}
                                            />
                                            <LabelNormal
                                              fontSize="14"
                                              color="#3C4549"
                                            >
                                              {email}
                                            </LabelNormal>
                                          </InlineContainer>
                                        </div>
                                      )}
                                    </div>
                                  </InlineContainer>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: 35,
                                marginTop: 10,
                                width: '100%',
                              }}
                            >
                              <div>
                                <Skeleton
                                  baseColor={'#F6F6F8'}
                                  width={'70%'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-7">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {historyClinic && (
                            <div style={{ marginLeft: 35 }}>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  fontSize="30"
                                  color="#3C4549"
                                >{`${firstName} ${lastName}`}</LabelNormal>
                              </InlineContainer>
                              <InlineContainer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginBottom: 10,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#828282"
                                        fontSize="13"
                                      >
                                        Tratamiento
                                      </LabelNormal>
                                      <LabelNormal
                                        color={
                                          treatment === 'cryst_aligner'
                                            ? '#18A1DC'
                                            : '#F0BB00'
                                        }
                                        fontSize="20.45"
                                      >
                                        {treatment === 'cryst_aligner'
                                          ? 'Cryst Aligner'
                                          : 'Smile System'}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div className="col-md-6">
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="user" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {gender}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div className="col-md-6">
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="phone" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {phone}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div
                                    className="col-md-6"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="gift" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >{`${year}-${month}-${day}`}</LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  {email && (
                                    <div
                                      className="col-md-6"
                                      style={{ marginTop: 8 }}
                                    >
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <img
                                          src={require('@images/icons/email.png')}
                                          style={{ height: 16, width: 15.5 }}
                                        />
                                        <LabelNormal
                                          fontSize="14"
                                          color="#3C4549"
                                        >
                                          {email}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                  )}
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </InlineContainer>
                      </>
                    ) : (
                      <InlineContainer>
                        {!loadingSkeleton ? (
                          <div>
                            <Avatar
                              src={
                                photo ? photo : loadPhotoProfileGender(gender)
                              }
                              floatButton={edit}
                              edit={!!photo}
                              onChange={onChange}
                              height="161"
                              width="161"
                              borderRadius="30"
                            />
                          </div>
                        ) : (
                          <div>
                            <Skeleton
                              height={161}
                              borderRadius={27}
                              width={161}
                              baseColor={'#EEEEF2'}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        )}
                        {!loadingSkeleton ? (
                          <>
                            {!edit && !historyClinic && (
                              <div style={{ marginLeft: 35 }}>
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 8,
                                  }}
                                >
                                  <LabelNormal
                                    color="#436FF1"
                                    fontSize="21"
                                    style={{
                                      marginRight: 7,
                                      fontFamily: 'neue-montreal-medium',
                                      margin: '0px 12px 0px 0px',
                                    }}
                                  >
                                    PX
                                  </LabelNormal>
                                  <LabelNormal
                                    fontSize="22"
                                    color="#3C4549"
                                    style={{
                                      fontFamily: 'neue-montreal-medium',
                                      margin: 0,
                                    }}
                                  >{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                                <InlineContainer>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ marginBottom: 8 }}
                                      >
                                        <LabelNormal
                                          color="#4F4F4F"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: '0px 12px 0px 0px',
                                          }}
                                        >
                                          ID
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#BDBDBD"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: 0,
                                            letterSpacing: '0.11em',
                                          }}
                                        >
                                          {id}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <FeatherIcon
                                          icon="phone"
                                          color="#757575"
                                          size={19}
                                        />
                                        <LabelNormal
                                          fontSize="17"
                                          color="#3C4549"
                                          style={{ margin: '0px 8px' }}
                                        >
                                          {countryName !== null
                                            ? countryName === 'México'
                                              ? '+52 '
                                              : countryName === 'Panamá'
                                              ? '+507 '
                                              : '+502 '
                                            : ''}
                                          {phone}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    {email && (
                                      <div className="col-md-7">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="email" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {email}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                    )}
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 35,
                              marginTop: 10,
                              width: '100%',
                            }}
                          >
                            <div>
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                width={'70%'}
                                height={27}
                                borderRadius={5}
                                highlightColor={'#FFF'}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <div className="row">
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-7">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {historyClinic && (
                          <div style={{ marginLeft: 35 }}>
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <LabelNormal
                                fontSize="30"
                                color="#3C4549"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <div className="row">
                                <div className="col-md-12">
                                  <InlineContainer
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <LabelNormal color="#828282" fontSize="13">
                                      Tratamiento
                                    </LabelNormal>
                                    <LabelNormal
                                      color={
                                        treatment === 'cryst_aligner'
                                          ? '#18A1DC'
                                          : '#F0BB00'
                                      }
                                      fontSize="20.45"
                                    >
                                      {treatment === 'cryst_aligner'
                                        ? 'Cryst Aligner'
                                        : 'Smile System'}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-6">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="user" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {gender}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-6">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="phone" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {phone}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 8 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="gift" />
                                    <LabelNormal
                                      fontSize="14"
                                      color="#3C4549"
                                    >{`${year}-${month}-${day}`}</LabelNormal>
                                  </InlineContainer>
                                </div>
                                {email && (
                                  <div
                                    className="col-md-6"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <img
                                        src={require('@images/icons/email.png')}
                                        style={{ height: 16, width: 15.5 }}
                                      />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {email}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                )}
                              </div>
                            </InlineContainer>
                          </div>
                        )}
                      </InlineContainer>
                    )}
                  </div>
                  {!edit && !historyClinic && (
                    <div
                      className="col-md-12"
                      style={{
                        marginTop: 47,
                        filter:
                          'drop-shadow(0px 0px 4px rgba(162, 162, 162, 0.1))',
                      }}
                    >
                      <Card
                        padding="0px"
                        border="none"
                        minWidth="572px"
                        borderRadius="30px"
                      >
                        <HeaderCard>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="17"
                            style={{
                              fontFamily: 'neue-montreal-medium',
                              margin: 0,
                            }}
                          >
                            Historial
                          </LabelNormal>
                        </HeaderCard>
                        {!loadingSkeleton ? (
                          <CardContainer>
                            <div style={{ textAlign: 'center' }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="12"
                                style={{ textTransform: 'uppercase' }}
                              >
                                Paso actual
                              </LabelNormal>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <History
                                  histories={histories}
                                  type="doctor"
                                ></History>
                              </div>
                            </div>
                          </CardContainer>
                        ) : (
                          <>
                            <CardContainer style={{ minHeight: 500 }}>
                              <center>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={16}
                                  width={'20%'}
                                  highlightColor={'#FFF'}
                                />
                              </center>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 33,
                                  border: '3px solid #EEEEF2',
                                  borderRadius: 10,
                                  padding: 10,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </CardContainer>
                          </>
                        )}
                      </Card>
                    </div>
                  )}
                  {edit && (
                    <div className="col-md-12" style={{ marginTop: 17 }}>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            style={{ marginTop: 38 }}
                            className="grid-layout"
                          >
                            <InlineContainer>
                              <div>
                                <Input
                                  placeholder="Nombre"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  label="Nombre del paciente"
                                />
                              </div>
                              <div>
                                <span
                                  style={{ fontSize: 18, visibility: 'hidden' }}
                                >
                                  none
                                </span>
                                <Input
                                  placeholder="Apellidos"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 20 }}
                          >
                            <LabelNormal color="#828282" fontSize="13">
                              Tratamiento
                            </LabelNormal>
                            <LabelNormal
                              color={
                                treatment === 'cryst_aligner'
                                  ? '#18A1DC'
                                  : '#F0BB00'
                              }
                              fontSize="20.45"
                            >
                              {treatment === 'cryst_aligner'
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        <div className="col-md-7">
                          <div>
                            <LabelInput>Sexo</LabelInput>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="gender-1"
                                  onClick={(e) => setGender('Masculino')}
                                  checked={
                                    gender === 'Masculino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-1"
                                  className="radio-label"
                                >
                                  Hombre
                                </label>
                              </div>
                              <div className="radio">
                                <input
                                  id="gender-2"
                                  onClick={(e) => setGender('Femenino')}
                                  checked={
                                    gender === 'Femenino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-2"
                                  className="radio-label"
                                >
                                  Mujer
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 28 }}>
                          <LabelInput>Fecha de nacimiento</LabelInput>
                          <InlineContainer>
                            <Input
                              placeholder="DD"
                              maxWidth="60px"
                              value={day}
                              onChange={setDay}
                              mask="date2"
                              type="day"
                            />
                            <Input
                              placeholder="MM"
                              maxWidth="60px"
                              value={month}
                              onChange={setMonth}
                              mask="date2"
                              type="monthInput"
                            />
                            <Input
                              placeholder="AAAA"
                              maxWidth="90px"
                              value={year}
                              onChange={setYear}
                              mask="date4"
                            />
                          </InlineContainer>
                          {!validDate && (
                            <InlineContainer>
                              <p
                                style={{
                                  color: '#C60E02',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Fecha incorrecta
                              </p>
                            </InlineContainer>
                          )}
                        </div>
                        <div className="col-md-7" style={{ marginTop: 28 }}>
                          <div>
                            <LabelInput>Teléfono</LabelInput>
                            <InlineContainer>
                              <Input
                                readOnly
                                disabled
                                value={
                                  countryName !== null
                                    ? countryName === 'México'
                                      ? '+52 '
                                      : countryName === 'Panamá'
                                      ? '+507 '
                                      : '+502 '
                                    : ''
                                }
                                backgroundColor="#F6F6F8"
                                maxWidth={
                                  countryName === 'México' ? '68px' : '75px'
                                }
                              />
                              <Input
                                placeholder="Teléfono"
                                value={phone}
                                onChange={setPhone}
                                mask="phone"
                                validation="phone"
                              />
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-5" style={{ marginTop: 28 }}>
                          <div className="grid-layout">
                            <Input
                              placeholder="Tu correo"
                              label="Email (Opcional)"
                              value={email}
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              maxWidth="300px"
                            />
                          </div>
                        </div>
                      </div>
                      <ButtonPrime
                        label="Guardar cambios"
                        disabled={
                          validDate &&
                          (email === null ||
                            email === '' ||
                            (email !== '' && validateEmail(email)))
                            ? false
                            : true
                        }
                        onClick={() =>
                          updatePatient({
                            variables: {
                              patient_id: id,
                              first_name: firstName,
                              last_name: lastName,
                              email: email === null ? '' : email,
                              phone,
                              gender,
                              birthdate: `${year}-${month}-${day}`,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: historyClinic ? 60 : 0 }}
                    >
                      <InlineContainer
                        style={{
                          backgroundColor: '#EEEFF1',
                          borderRadius: 40,
                          paddingTop: 30,
                          paddingLeft: 20,
                          paddingBottom: 30,
                          alignItems: 'self-start',
                        }}
                      >
                        <div
                          style={{
                            marginRight: 40,
                            position: 'sticky',
                            top: '50px',
                          }}
                        >
                          {[
                            'Formulario',
                            'Intraorales',
                            'Extraorales',
                            "STL's",
                            'Radiografias',
                          ].map((row, index) => (
                            <div
                              style={{
                                maxWidth: 165,
                                maxHeight: 55,
                                background:
                                  selectItem === row ? '#FFF' : 'transparent',
                                borderBottomColor:
                                  selectItem === row
                                    ? 'transparent'
                                    : '#D4D4D4',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                padding: 15,
                                borderRadius: selectItem !== row ? 0 : 10,
                              }}
                              key={Math.random()}
                            >
                              <LabelNormal
                                color="#000000"
                                fontSize="18.5"
                                onClick={() => setSelectItem(row)}
                                style={{ cursor: 'pointer' }}
                              >
                                {row}
                              </LabelNormal>
                            </div>
                          ))}
                        </div>
                        <div>
                          {selectItem === 'Formulario' && (
                            <div className="row">
                              <div className="col-md-12">
                                {formSSData.brackets &&
                                  treatment === 'smile_system' && (
                                    <ResumeSS
                                      formSSData={formSSData}
                                      chatMessages={chatMessages}
                                    />
                                  )}
                                {formCAData.arcad &&
                                  treatment === 'cryst_aligner' && (
                                    <ResumeCA
                                      formCAData={formCAData}
                                      chatMessages={chatMessages}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Radiografias' && (
                            <div className="row">
                              <div className="col-md-7">
                                <InlineContainer>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral'] &&
                                          filesTreatment['lateral'].url
                                            ? filesTreatment['lateral'].url
                                            : require('@images/patient/lateral.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral'] &&
                                      filesTreatment['lateral'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['lateral'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Lateral
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['panoramica'] &&
                                          filesTreatment['panoramica'].url
                                            ? filesTreatment['panoramica'].url
                                            : require('@images/patient/panoramica.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['panoramica'] &&
                                      filesTreatment['panoramica'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['panoramica'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Panorámica
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                      marginRight: 60,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ] &&
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ].url
                                            ? filesTreatment[
                                                'trazado_cefalometrico'
                                              ].url
                                            : require('@images/patient/trazado_cefalometrico.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['trazado_cefalometrico'] &&
                                      filesTreatment['trazado_cefalometrico']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment[
                                              'trazado_cefalometrico'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Trazado cefalométrico
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['tomografia'] &&
                                          filesTreatment['tomografia'].url
                                            ? require('@images/patient/zip-upload.png')
                                            : require('@images/patient/tomografia.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['tomografia'] &&
                                      filesTreatment['tomografia'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['tomografia'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Tomografía
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === "STL's" && (
                            <div className="row">
                              <div className="col-md-7">
                                {sendModelStl && (
                                  <div>
                                    <LabelNormal color="#3C4549" fontSize="17">
                                      Se seleccionó envío de modelos por
                                      paquetería a la siguiente dirección:
                                    </LabelNormal>
                                    <InlineContainer>
                                      <CardModel style={{ maxWidth: 356 }}>
                                        <LabelNormal
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            marginRight: 7,
                                            marginBottom: 10,
                                          }}
                                          color="#757575"
                                          fontSize="18"
                                        >
                                          Dirección de envío | Borgatta
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#828282"
                                          style={{ margin: 0, padding: 0 }}
                                          fontSize="17"
                                        >
                                          {defaultAddress.id
                                            ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code} ${defaultAddress.state}`
                                            : ''}
                                        </LabelNormal>
                                      </CardModel>
                                    </InlineContainer>
                                  </div>
                                )}
                                {sendLinkStl && (
                                  <div>
                                    {link_data.map((item, key) => (
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="20"
                                      >
                                        {item}
                                      </LabelNormal>
                                    ))}
                                  </div>
                                )}
                                {!sendLinkStl && !sendModelStl && (
                                  <InlineContainer>
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.arcada_superior
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/arcada_superior.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                        <a
                                          href={
                                            filesTreatmentStl.arcada_superior
                                              .file.file_url
                                          }
                                          target="_blank"
                                          style={{
                                            background:
                                              'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                            height: 24,
                                            width: 24,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 26,
                                            position: 'absolute',
                                            right: 32,
                                            bottom: 10,
                                          }}
                                          download={
                                            filesTreatmentStl.arcada_superior
                                              .file.file_url
                                          }
                                        >
                                          <FeatherIcon
                                            icon="download"
                                            color="#FFF"
                                            size="16"
                                          />
                                        </a>
                                      </div>
                                      {filesTreatmentStl.arcada_superior &&
                                        filesTreatmentStl.arcada_superior
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.arcada_superior
                                                .file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Arcada superior
                                      </LabelNormal>
                                    </div>
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.oclusion_derecha
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/oclusion.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                        <a
                                          href={
                                            filesTreatmentStl.oclusion_derecha
                                              .file.file_url
                                          }
                                          target="_blank"
                                          style={{
                                            background:
                                              'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                            height: 24,
                                            width: 24,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 26,
                                            position: 'absolute',
                                            right: 32,
                                            bottom: 10,
                                          }}
                                          download={
                                            filesTreatmentStl.oclusion_derecha
                                              .file.file_url
                                          }
                                        >
                                          <FeatherIcon
                                            icon="download"
                                            color="#FFF"
                                            size="16"
                                          />
                                        </a>
                                      </div>
                                      {filesTreatmentStl.oclusion_derecha &&
                                        filesTreatmentStl.oclusion_derecha
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.oclusion_derecha
                                                .file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Oclusión/Oclusión derecha
                                      </LabelNormal>
                                    </div>
                                    {filesTreatmentStl.oclusion_izquierda &&
                                      filesTreatmentStl.oclusion_izquierda
                                        .file && (
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              src={
                                                filesTreatmentStl.oclusion_izquierda
                                                  ? require('@images/patient/stl-upload.png')
                                                  : require('@images/patient/oclusion.png')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                              }}
                                            />
                                            <a
                                              href={
                                                filesTreatmentStl
                                                  .oclusion_izquierda.file
                                                  .file_url
                                              }
                                              target="_blank"
                                              style={{
                                                background:
                                                  'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                                height: 24,
                                                width: 24,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: 26,
                                                position: 'absolute',
                                                right: 32,
                                                bottom: 10,
                                              }}
                                              download={
                                                filesTreatmentStl
                                                  .oclusion_izquierda.file
                                                  .file_url
                                              }
                                            >
                                              <FeatherIcon
                                                icon="download"
                                                color="#FFF"
                                                size="16"
                                              />
                                            </a>
                                          </div>
                                          {filesTreatmentStl.oclusion_izquierda &&
                                            filesTreatmentStl.oclusion_izquierda
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="14"
                                              >
                                                {
                                                  filesTreatmentStl
                                                    .oclusion_izquierda.file
                                                    .name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="14"
                                          >
                                            Oclusión izquierda
                                          </LabelNormal>
                                        </div>
                                      )}
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.arcada_inferior
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/arcada_inferior.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                        <a
                                          href={
                                            filesTreatmentStl.arcada_inferior
                                              .file.file_url
                                          }
                                          target="_blank"
                                          style={{
                                            background:
                                              'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                            height: 24,
                                            width: 24,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 26,
                                            position: 'absolute',
                                            right: 32,
                                            bottom: 10,
                                          }}
                                          download={
                                            filesTreatmentStl.arcada_inferior
                                              .file.file_url
                                          }
                                        >
                                          <FeatherIcon
                                            icon="download"
                                            color="#FFF"
                                            size="16"
                                          />
                                        </a>
                                      </div>
                                      {filesTreatmentStl.arcada_inferior &&
                                        filesTreatmentStl.arcada_inferior
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.arcada_inferior
                                                .file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Arcada inferior
                                      </LabelNormal>
                                    </div>
                                  </InlineContainer>
                                )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Extraorales' && (
                            <div>
                              <div
                                className="row"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_serio'] &&
                                          filesTreatment['frontal_serio'].url
                                            ? filesTreatment['frontal_serio']
                                                .url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_serio'] &&
                                      filesTreatment['frontal_serio'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {filesTreatment['frontal_serio'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_sonriendo'] &&
                                          filesTreatment['frontal_sonriendo']
                                            .url
                                            ? filesTreatment[
                                                'frontal_sonriendo'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_sonriendo'] &&
                                      filesTreatment['frontal_sonriendo']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['frontal_sonriendo']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal sonriendo
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'frontal_retractores'
                                          ] &&
                                          filesTreatment['frontal_retractores']
                                            .url
                                            ? filesTreatment[
                                                'frontal_retractores'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_retractores'] &&
                                      filesTreatment['frontal_retractores']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment[
                                              'frontal_retractores'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal con retractores
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_izq_serio'] &&
                                          filesTreatment['lateral_izq_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_izq_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_izq_serio'] &&
                                      filesTreatment['lateral_izq_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_izq_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral izquierda serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_der_serio'] &&
                                          filesTreatment['lateral_der_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_der_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_der_serio'] &&
                                      filesTreatment['lateral_der_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_der_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral derecha serio
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectItem === 'Intraorales' && (
                            <div>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_superior'] &&
                                        filesTreatment['oclusal_superior'].url
                                          ? filesTreatment['oclusal_superior']
                                              .url
                                          : require('@images/patient/superior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_superior'] &&
                                    filesTreatment['oclusal_superior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_superior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal superior
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{
                                    width: 162,
                                    marginRight: 20,
                                    marginBottom: 20,
                                  }}
                                ></div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_inferior'] &&
                                        filesTreatment['oclusal_inferior'].url
                                          ? filesTreatment['oclusal_inferior']
                                              .url
                                          : require('@images/patient/inferior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_inferior'] &&
                                    filesTreatment['oclusal_inferior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_inferior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal inferior
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="derecha"
                                      src={
                                        filesTreatment['lat_der_oclusion'] &&
                                        filesTreatment['lat_der_oclusion'].url
                                          ? filesTreatment['lat_der_oclusion']
                                              .url
                                          : require('@images/patient/lateral-derecha.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_der_oclusion'] &&
                                    filesTreatment['lat_der_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_der_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal
                                    color="#3C4549"
                                    fontSize="15"
                                    style={{ width: 162 }}
                                  >
                                    Lateral derecha en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="frontal"
                                      src={
                                        filesTreatment['frontal_oclusion'] &&
                                        filesTreatment['frontal_oclusion'].url
                                          ? filesTreatment['frontal_oclusion']
                                              .url
                                          : require('@images/patient/frontal.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['frontal_oclusion'] &&
                                    filesTreatment['frontal_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['frontal_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Frontal en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="izquierda"
                                      src={
                                        filesTreatment['lat_izq_oclusion'] &&
                                        filesTreatment['lat_izq_oclusion'].url
                                          ? filesTreatment['lat_izq_oclusion']
                                              .url
                                          : require('@images/patient/lateral-izquierda.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_izq_oclusion'] &&
                                    filesTreatment['lat_izq_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_izq_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal
                                    color="#3C4549"
                                    fontSize="15"
                                    style={{ width: 162 }}
                                  >
                                    Lateral izquierda en oclusión
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!historyClinic && (
                  <div className="row">
                    <Details
                      patient={patient}
                      treatment={treatmentRelated}
                      activeBasicChat={activeBasicChat}
                      loadingSkeleton={loadingSkeleton}
                      pendingMessages={pendingMessages}
                      setPhotoEvidences={setPhotoEvidences}
                      reload={() => getPatient({ variables: { id } })}
                    />
                    <CardWithLink
                      linkTo={{
                        pathname: `/${
                          role === 'teacher' ? 'profesor' : 'doctor'
                        }/caso/mensajes-borgatta/${id}`,
                        state: { treatmentId },
                      }}
                      content="Mensajes de Borgatta"
                      icon="message-circle"
                      active={pendingMessages > 0}
                    />
                    <Actions
                      patientId={id}
                      treatmentRelated={treatmentRelated}
                      archived={archived}
                      loadingSkeleton={loadingSkeleton}
                      setEdit={setEdit}
                      setHistoryClinic={setHistoryClinic}
                      navigateToInvoices={navigateToInvoices}
                      handleOpenModal={handleOpenModal}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
