import React from 'react';
import styled from 'styled-components';
import { FileError } from '../Icons/FileError';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  top:${({ top }) => (top ? top : '0')}px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9.76')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 7.59px 0 0 0;
`;

const DownloadError = (props) => (
  <Container top={props.top}>
    <FileError size={props.size}/>
    <LabelNormal fontSize={props.fontSize} color={props.color} style={{ paddingLeft: 42, paddingRight: 42, textAlign: 'center' }}>
      { props.message ?? 'No se pudo subir tu archivo. Por favor, inténtalo de nuevo '}
    </LabelNormal>
  </Container>
);
  
export default DownloadError;