import ModalSuccess from '@components/Modal/ModalSuccess';
import { LabelNormal } from '../../shared/Styled';

/**
 * @param {{onClickClose: (goBack: boolean) => void}} props
 * @returns {JSX.Element}
 */
export const SendAsApproveModal = ({ onClickClose }) => {
  return (
    <ModalSuccess onClick={() => onClickClose(false)}>
      <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
        Caso enviado como listo para ser aprobado
      </LabelNormal>
      <LabelNormal
        fontSize="17"
        color="#4F4F4F"
        style={{ paddingTop: 14, marginBottom: 15 }}
      >
        Espera a que el doctor apruebe el caso.
      </LabelNormal>
    </ModalSuccess>
  );
};
