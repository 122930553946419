import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import Checkbox from 'react-custom-checkbox';
import { MUTATION_CREATE_CREDIT_CARD_V2 } from '@graphql/mutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getTypeCard, validateCVV, validateExpDate } from '../../utils';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalError from '@components/Modal/ModalError';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const CreditCards = (props) => {
  const history = useHistory();

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expDate, setExpDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [defaul, setDefault] = useState(false);
  const [brand, setBrand] = useState('');
  const [validForm, setvalidForm] = useState(false);

  const [createCardV2] = useMutation(MUTATION_CREATE_CREDIT_CARD_V2, {
    onCompleted: (data) => {
      if (data.addDoctorCardV2) {
        modal.close();
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => history.push('/doctor/perfil'))}
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Tarjeta agregada con éxito
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Ahora la podrás utilizar para pagar tus tratamientos.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      let errorMessage = '';
      if (graphQLErrors?.length > 0) {
        errorMessage = graphQLErrors[0].message;
      } else if (networkError)
        errorMessage =
          'Lo sentimos, no se puede procesar la transacción debido a una falla en tu conexión. Por favor, verifícala e inténtalo de nuevo.';
      modal.close();
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
          >
            Tarjeta no guardada
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Hubo un error al procesar tu tarjeta. Verifica tus datos e inténtalo
            de nuevo.
          </LabelNormal>
          <div
            style={{
              backgroundColor: '#FFE5E3',
              padding: '15px',
              borderRadius: 13,
              color: '#C60E02',
            }}
          >
            <p style={{ fontWeight: '600' }}>Error de sistema:</p>
            <p style={{ fontWeight: '600' }}>{errorMessage}</p>
          </div>
        </ModalError>
      );
    },
  });

  const onSubmit = async () => {
    if (validateForm()) {
      modal.open(
        <ModalWaiting>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14 }}
          >
            Procesando...
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Un momento por favor...
          </LabelNormal>
        </ModalWaiting>
      );
      createCardV2({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          card_name: cardName,
          card_number: cardNumber,
          exp_month: parseInt(expDate.split('/')[0]),
          exp_year: parseInt(`20${expDate.split('/')[1]}`),
          cvv: cvv,
          default: defaul,
        },
      });
    }
  };

  const getBrand = () => {
    setBrand(getTypeCard(cardNumber));
  };

  const validateForm = () => {
    if (
      cardName === '' ||
      cardNumber.length < 19 ||
      brand === '' ||
      !validateExpDate(expDate) ||
      !validateCVV(brand, cvv)
    ) {
      setvalidForm(true);
      return false;
    } else {
      setvalidForm(false);
      return true;
    }
  };

  useEffect(() => {
    if (cardNumber !== '') getBrand();
    else setBrand('');
  }, [cardNumber]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="perfil" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title style={{ marginBottom: 39 }}>Tarjetas guardadas</Title>
                <LabelNormal color="#454D58" fontSize="22">
                  Añadir tarjeta
                </LabelNormal>
              </div>
              <div class="col-md-9">
                <div className="bootstrap-wrapper">
                  <div style={{ marginTop: 20 }} className="row">
                    <div className="col-md-8">
                      <Input
                        placeholder="Nombre y apellidos"
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                        style={{
                          borderColor:
                            validForm && cardName === ''
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                        label="Nombre y apellidos (como aparece en el plástico)"
                        maxWidth="65%"
                      />
                      {validForm && cardName === '' && (
                        <p
                          style={{
                            color: '#C60E02',
                            width: 200,
                            fontSize: 18,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Introduce
                        </p>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }} className="row">
                    <div className="col-md-7">
                      <LabelNormal color="#3C4549" fontSize="18">
                        Número de tarjeta
                      </LabelNormal>
                      <div className="row" style={{ alignItems: 'flex-start' }}>
                        <div
                          className="col-md-2"
                          style={{
                            margin: 0,
                            marginLeft: 20,
                            padding: 0,
                            maxWidth: 60,
                          }}
                        >
                          <div
                            style={{
                              height: 52,
                              background: '#F6F6F8',
                              borderTopLeftRadius: 10,
                              borderBottomLeftRadius: 10,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {brand !== '' && brand !== 'unknown' && (
                              <img
                                src={require(`@images/icon-cc/${brand}-transparent.png`)}
                                style={{
                                  height: 19,
                                  width: 28,
                                  objectFit: 'contain !important',
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Input
                            mask="credit_card"
                            style={{
                              marginTop: 0,
                              borderColor:
                                validForm &&
                                (cardNumber === '' || cardNumber.length < 19)
                                  ? '#C60E02'
                                  : '#D1D1D1',
                            }}
                            value={cardNumber}
                            onChange={setCardNumber}
                            placeholder="Número de tarjeta"
                          />
                        </div>
                      </div>
                      {validForm &&
                        (cardNumber === '' || cardNumber.length < 19) && (
                          <p
                            style={{
                              color: '#C60E02',
                              width: 250,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            {validForm &&
                            cardNumber.length < 19 &&
                            cardNumber !== ''
                              ? '*Número de tarjeta inválido'
                              : '*Introduce tu número de tarjeta'}
                          </p>
                        )}
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }} className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-4">
                          <LabelNormal color="#3C4549" fontSize="18">
                            Vencimiento
                          </LabelNormal>
                          <Input
                            mask="exp_date"
                            value={expDate}
                            onChange={setExpDate}
                            style={{
                              borderColor:
                                validForm &&
                                (expDate === '' || !validateExpDate(expDate))
                                  ? '#C60E02'
                                  : '#D1D1D1',
                            }}
                            placeholder="Mes / año"
                          />
                          {validForm &&
                            (expDate === '' || !validateExpDate(expDate)) && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  width: 350,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                {validForm &&
                                !validateExpDate(expDate) &&
                                expDate !== ''
                                  ? '*Fecha de vencimiento inválida'
                                  : '*Introduce la fecha de vencimiento'}
                              </p>
                            )}
                        </div>
                        <div className="col-md-4">
                          <LabelNormal color="#3C4549" fontSize="18">
                            CVV
                          </LabelNormal>
                          <Input
                            mask="cvv"
                            value={cvv}
                            onChange={setCvv}
                            placeholder="CVV"
                            style={{
                              borderColor:
                                validForm &&
                                (cvv === '' || !validateCVV(brand, cvv))
                                  ? '#C60E02'
                                  : '#D1D1D1',
                            }}
                          />
                          {validForm &&
                            (cvv === '' || !validateCVV(brand, cvv)) && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  width: 250,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                {validForm &&
                                !validateCVV(brand, cvv) &&
                                cvv !== ''
                                  ? '*CVV inválido'
                                  : '*Introduce tu CVV'}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: 0, paddingTop: 28 }} className="row">
                    <Checkbox
                      checked={defaul}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setDefault(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                      }}
                      label="Definir como tarjeta predeterminada"
                    />
                  </div>
                  <div style={{ marginTop: 31, marginBottom: 41 }}>
                    {validForm && (
                      <div
                        style={{
                          marginBottom: 18,
                          background: '#FFE5E3',
                          border: '1px solid #E41D11',
                          padding: '5px 10px 7px 10px',
                          borderRadius: 7,
                          maxWidth: 256,
                        }}
                      >
                        <LabelNormal fontSize="14" color="#C60E02">
                          *Completa los campos faltantes
                        </LabelNormal>
                      </div>
                    )}
                    <ButtonPrime
                      label="Guardar tarjeta"
                      onClick={onSubmit}
                    ></ButtonPrime>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default CreditCards;
