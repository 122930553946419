import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import FeatherIcons from 'feather-icons-react';

import styled from 'styled-components';
import axios from 'axios';

import {
  MUTATION_UPDATE_PLANNING_DATA,
  MUTATION_SMARTCHECK_DOCTOR,
  MUTATION_CASE_READY,
  MUTATION_RESTART_CHAT,
  MUTATION_END_CHAT_TREATMENT,
} from '@graphql/mutation';
import { QUERY_GET_PATIENT, QUERY_SENDBIRS_ACCESS_TOKEN } from '@graphql/query';
import { modal } from '@components/ModalManagement';
import ButtonPrime from '@components/shared/ButtonPrime';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { PlanningReviewNavigation } from './PlanningReview/navigation';
import { PlanningReviewHeader } from './PlanningReview/header';
import { PlanningReviewSmartCheck } from './PlanningReview/smartCheck';
import { PlanningReviewCrystAligner } from './PlanningReview/crystAligner';
import { PlanningReviewSmileSystem } from './PlanningReview/smileSystem';
import {
  alignerSendTypes,
  defaultPartialitiesErrorMessages,
  defaultPartialities,
} from './Planning/consts';
import { usePartialAligner } from './Planning/usePartialAligner';

const ContainHome = styled.div`
  width: 100%;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Planning = (props) => {
  const DEFAULT_ALIGNER_TYPE = alignerSendTypes[0];

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatment, setTreatment] = useState('');
  const [treatmentId, setTreatmentId] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);

  const [software, setSoftware] = useState('ARCAD');
  const [brackets, setBrackets] = useState('');
  const [idCase, setIdCase] = useState('');
  const [alineadoresSuperiores, setAlineadoresSuperiores] = useState('0');
  const [alineadoresInferiores, setAlineadoresInferiores] = useState('0');
  const [
    alineadoresSuperioresSoloLectura,
    setAlineadoresSuperioresSoloLectura,
  ] = useState('0');
  const [
    alineadoresInferioresSoloLectura,
    setAlineadoresInferioresSoloLectura,
  ] = useState('0');
  const [typebracket, setTypeBracket] = useState('');
  /**
   * @TODO partiality model inside child components don't have id property.
   * Consider this type collision while a Typescript migration.
   * @typedef {{ upper: number, bottom: number, id: number }} Partiality
   *  @type {[Array<Partiality, React.Dispatch<Array<Partiality>>]} state
   */
  const [partialities, setPartialities] = useState(defaultPartialities);
  /** Notice: This state has the purpose to cache the partialities data obtained
   * from the backend, to be used as a backup when the user cancel the updates.
   * @TODO all the api response should be cached in some state following the same
   * purpose (IMO).
   *
   * @typedef {{ upper: number, bottom: number, id: number }} Partiality
   *  @type {[Array<Partiality, React.Dispatch<Array<Partiality>>]} state
   */
  const [onlyReadPartialities, setOnlyReadPartialities] =
    useState(defaultPartialities);
  /**
   *  @type {["Partial" | "Total", React.Dispatch<"Partial" | "Total">]} state
   */
  const [alignerSendType, setAlignerSendType] = useState(DEFAULT_ALIGNER_TYPE);
  /**
   *  @type {[Object<number, string>, React.Dispatch<Object<number, string>]} state
   */
  const [partialitiesErrorMessages, setPartialitiesErrorMessages] = useState(
    defaultPartialitiesErrorMessages
  );
  const [linkvisor, setLinkVisor] = useState('');
  const [linkvisordesk, setLinkVisorDesk] = useState('');

  const [messageDoctor, setMessageDoctor] = useState(false);
  const [makeSmart, setMakeSmart] = useState(false);
  const [statusSmart, setStatusSmart] = useState({});
  const [statusTreatment, setStatusTreatment] = useState({});
  const [linkvisorPatient, setLinkVisorPatient] = useState('');
  const [mp4LinkVisor, setMp4LinkVisor] = useState('');
  const [mp4FileName, setMp4FileName] = useState('');
  const [uploadMp4File, setUploadMp4File] = useState(null);
  const [progressUpload, setProgressUpload] = useState(null);
  const [errorMessages, setErrorMessages] = useState({
    idCase: '',
    alineadoresSuperiores: '',
    alineadoresInferiores: '',
    linkvisor: '',
    fileMp4: '',
    linkvisordesk: '',
    linkvisorPatient: '',
  });

  //Subida de archivo .mp4 -link de visor para compartir con paciente
  const refInput = useRef(null);
  const refInputSmartCheck = useRef(null);
  const [fileMp4, setFileMp4] = useState(false);

  const partialAligner = usePartialAligner({
    bottomAligners: alineadoresInferiores,
    errorMessages: partialitiesErrorMessages,
    partialities,
    setErrorMessages: setPartialitiesErrorMessages,
    upperAligners: alineadoresSuperiores,
  });

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setTreatment(patient.treatment);
        setAlignerSendType(patient.treatment_related.aligner_send_type);
        setOnlyReadPartialities(patient.treatment_related.partialities);
        setPartialities(patient.treatment_related.partialities);
        setTreatmentId(patient.treatment_related.id);
        if (!firstLoad) {
          setLinkVisor(patient.treatment_related.visor_url);
          setLinkVisorDesk(patient.treatment_related.visor_desk_link);
          setLinkVisorPatient(patient.treatment_related.visor_patient_link);
          setIdCase(patient.treatment_related.planning_case_id);
          setAlineadoresSuperiores(
            (patient.treatment_related.aligners_number ?? 0).toString()
          );
          setAlineadoresInferiores(
            (patient.treatment_related.bottom_aligners_number ?? 0).toString()
          );
          setAlineadoresSuperioresSoloLectura(
            (patient.treatment_related.aligners_number ?? 0).toString()
          );
          setAlineadoresInferioresSoloLectura(
            (patient.treatment_related.bottom_aligners_number ?? 0).toString()
          );
        }
        setBrackets(patient.treatment_related.bracket_ss);
        setSoftware(patient.treatment_related.planning_software);
        setMessageDoctor(patient.treatment_related.message_doctor);
        setMakeSmart(patient.treatment_related.make_smartcheck);
        setStatusTreatment(patient.treatment_related.treatmentStatus);
        const mp4File = patient.treatment_related.files.find((file) => {
          return file.name === 'link_visor_patient';
        });

        setMp4LinkVisor(
          mp4File
            ? `${process.env.REACT_APP_API_URL}/patients/${id}/treatment/form/${mp4File.file_name}`
            : null
        );
        setMp4FileName(mp4File ? mp4File.file_name : '');

        setStatusSmart(patient.treatment_related.last_smart_check);
        setFirstLoad(true);
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatientRefresh] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setMakeSmart(patient.treatment_related.make_smartcheck);
        setStatusSmart(patient.treatment_related.last_smart_check);
        setStatusTreatment(patient.treatment_related.treatmentStatus);
        setMessageDoctor(patient.treatment_related.message_doctor);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getPatient({ variables: { id } });
  }, []);

  /**
   * @todo This useEffect is somehow triggering new calls of the getPatient
   * function, and causing it to clear states when it shouldn't. It is a major
   * state bug. If statement (edit == false) provides a temporal fix.
   */
  useEffect(() => {
    getPatientRefresh({ variables: { id } });

    if (edit === false) {
      const intervalID = setInterval(() => {
        getPatientRefresh({ variables: { id } });
      }, 3000);

      return () => clearInterval(intervalID);
    }
  }, [edit, getPatientRefresh, id]);

  const [planningSend] = useMutation(MUTATION_UPDATE_PLANNING_DATA, {
    onCompleted: async (_) => {
      setEdit(false);
      setAlineadoresInferioresSoloLectura(alineadoresInferiores);
      setAlineadoresSuperioresSoloLectura(alineadoresSuperiores);
      modal.open(
        <ModalSuccess onClick={() => modal.close()}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Cambios a visor guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const sendPlanning = () => {
    if (validateForm()) {
      planningSend({
        variables: {
          aligner_send_type: alignerSendType,
          aligners_number: +alineadoresSuperiores,
          bottom_aligners_number: +alineadoresInferiores,
          partialities: partialities,
          planning_case_id: idCase,
          planning_software: software,
          treatment_id: treatmentId,
          visor_desk_link: linkvisordesk,
          visor_patient_link: linkvisorPatient,
          visor_url: linkvisor,
        },
      });
    } else {
    }
  };

  const openVisorDesck = () => {
    let dataArray = linkvisordesk.split('&');
    dataArray.forEach((item, index) => {
      dataArray[index] = item.split('=')[1];
    });
    window.open(
      `arcadviewer://ARCADIEWER/CaseID=${dataArray[0]}&UserID=${dataArray[3]}&PatientName=${dataArray[1]}`
    );
  };

  const uploadVisorVideo = async (mp4File) => {
    const token = localStorage.getItem('token-b360');

    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `
          mutation addVisorVideo($treatment_id: Int!, $file: Upload!) {
            addVisorVideo(input: { treatment_id: $treatment_id, file: $file }) {
              file_url
              file_name
            }
          }
        `,
        variables: {
          file: null,
          treatment_id: parseInt(treatmentId),
        },
      })
    );

    formData.append('0', mp4File ? mp4File : uploadMp4File);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));

    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        const result = response.data.data.addVisorVideo;
        if (result) {
          setProgressUpload(null);
          setMp4LinkVisor(result.file_url);
          setMp4FileName(result.file_name);
        }
      })
      .catch((_e) => {});
  };

  const onUploadMP4ForOtherInputRef = async (e) => {
    setUploadMp4File(e.target.files[0]);
    await uploadVisorVideo(e.target.files[0]);
  };

  const [smartcheckDoctor] = useMutation(MUTATION_SMARTCHECK_DOCTOR, {
    onCompleted: (data) => {
      getPatient({ variables: { id } });
      modal.open(
        <ModalSuccess onClick={() => modal.close()}>
          <LabelNormal fontSize="22" color="#060809">
            Smart check habilitado
          </LabelNormal>
          <LabelNormal color="#4F4F4F" fontSize="17">
            Espera a que el doctor agende su Smart check.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const modalDoctorSmartcheck = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809">
          El doctor podrá agendar Smart check
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17">
          El chat se finalizará y la conversación continuará por Smart check
          cuando el doctor lo agende.
        </LabelNormal>
        <ButtonPrime
          label="Habilitar Smart check"
          onClick={() => smartcheckDoctor({ variables: { patient_id: id } })}
        />
      </ModalWarning>
    );
  };

  const [userId, setUserId] = useState('');
  const [token, setToken] = useState('');

  const [getTokenUserId] = useLazyQuery(QUERY_SENDBIRS_ACCESS_TOKEN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getSendbirdAccessToken) {
        setUserId(data.getSendbirdAccessToken.userId);
        setToken(data.getSendbirdAccessToken.sendbird_access_token);
      }
    },
  });

  useEffect(() => {
    getTokenUserId({});
  }, [getTokenUserId]);

  const [endChatPlanning] = useMutation(MUTATION_END_CHAT_TREATMENT, {
    onCompleted: (data) => {
      if (data) {
        getPatient({ variables: { id } });
      }
    },
  });

  const [planningSendReady] = useMutation(MUTATION_CASE_READY, {
    onCompleted: (data) => {
      if (statusTreatment.type === 'planning' && statusTreatment.index === 11) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Caso enviado como listo para ser aprobrado
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14, marginBottom: 15 }}
            >
              Espera a que el doctor apruebe el caso.
            </LabelNormal>
          </ModalSuccess>
        );
      } else {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              El caso está listo para aprobarse
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14, marginBottom: 15 }}
            >
              Espera a que el doctor apruebe el caso para iniciar la
              manufactura.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [restartChatPlanning] = useMutation(MUTATION_RESTART_CHAT, {
    onCompleted: (data) => {
      if (data) {
        getPatient({ variables: { id } });
      }
    },
  });

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (idCase === '') {
      errors = {
        ...errors,
        idCase: '*Introduce el ID del caso',
      };
      isValid = false;
    }

    if (treatment === 'cryst_aligner') {
      if (alineadoresSuperiores === '' && alineadoresInferiores !== '') {
        errors = {
          ...errors,
          alineadoresSuperiores: '*Introduce una cantidad de alineadores',
        };
        isValid = false;
      }
      if (alineadoresInferiores === '' && alineadoresSuperiores !== '') {
        errors = {
          ...errors,
          alineadoresInferiores: '*Introduce una cantidad de alineadores',
        };
        isValid = false;
      }
      if (alineadoresSuperiores === '0') {
        errors = {
          ...errors,
          alineadoresSuperiores: '*Indica una cantidad mayor a 0',
        };
        isValid = false;
      }
      if (alineadoresInferiores === '0') {
        errors = {
          ...errors,
          alineadoresInferiores: '*Indica una cantidad mayor a 0',
        };
        isValid = false;
      }
      if (linkvisor === '') {
        errors = {
          ...errors,
          linkvisor: '*Introduce el link del visor',
        };
        isValid = false;
      }

      if (software === 'Nemocast') {
        if (uploadMp4File === null) {
          errors = {
            ...errors,
            fileMp4: '*Sube un archivo del video',
          };
          isValid = false;
        }
      } else {
        if (linkvisordesk === '') {
          errors = {
            ...errors,
            linkvisordesk: '*Introduce el link del visor',
          };
          isValid = false;
        }
        if (linkvisorPatient === '') {
          errors = {
            ...errors,
            linkvisorPatient: '*Introduce el link del visor',
          };
          isValid = false;
        }
      }
    }

    partialities.forEach((partiality, index) => {
      isValid = partialAligner.validateErrors(
        partialities,
        index,
        partiality.bottom
      );
      isValid = partialAligner.validateErrors(
        partialities,
        index,
        partiality.upper
      );
    });

    // Detect if some error in the partialitiesErrorMessages exists.
    Object.values(partialitiesErrorMessages).forEach((e) => {
      if (e !== '') isValid = false;
    });

    setErrorMessages(errors);
    return isValid;
  };

  const handleOnChange = (value, name) => {
    const errorsDescriptions = {
      idCase: '*Introduce el ID del caso',
      alineadoresInferiores: '*Introduce una cantidad de alineadores',
      alineadoresSuperiores: '*Introduce una cantidad de alineadores',
      linkvisor: '*Introduce el link del visor',
      fileMp4: '*Sube un archivo del video',
      linkvisordesk: '*Introduce el link del visor',
      linkvisorPatient: '*Introduce el link del visor',
    };

    if (
      (name === 'alineadoresInferiores' &&
        value === '0' &&
        alineadoresSuperiores === '0') ||
      (name === 'alineadoresSuperiores' &&
        value === '0' &&
        alineadoresInferiores === '0')
    ) {
      setErrorMessages({
        ...errorMessages,
        alineadoresInferiores: '*Indica una cantidad mayor a 0',
        alineadoresSuperiores: '*Indica una cantidad mayor a 0',
      });
    } else if (value === '') {
      setErrorMessages({
        ...errorMessages,
        [name]: errorsDescriptions[name],
      });
    } else {
      if (
        name === 'alineadoresSuperiores' ||
        name === 'alineadoresInferiores'
      ) {
        setErrorMessages({
          ...errorMessages,
          [name]: '',
          alineadoresInferiores: '',
          alineadoresSuperiores: '',
        });
      } else {
        setErrorMessages({
          ...errorMessages,
          [name]: '',
        });
      }
    }
  };

  const downloadFile = async (fileName, url) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'video/mp4',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    const path = url
      .split('?')[0]
      .replace('https://portal-b360.s3.amazonaws.com', '');

    try {
      const response = await instance.get(
        `/api/files/download?filePath=${path}&mimeType=video/mp4`
      );
      const href = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'video/mp4',
        })
      );

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.log(e);
    }
  };

  const onClickCancel = () => {
    setEdit(false);
    setAlineadoresInferiores(alineadoresInferioresSoloLectura);
    setAlineadoresSuperiores(alineadoresSuperioresSoloLectura);
    // Reset partialities state.
    setPartialities(onlyReadPartialities);
  };

  const calculateAlineadores = () => {
    const total =
      parseInt(alineadoresSuperiores) + parseInt(alineadoresInferiores);
    if (total > 0 && total <= 28) {
      return 'Terso';
    } else if (total <= 50 && total > 28) {
      return 'Moderado';
    } else if (total > 50) {
      return 'Integral';
    } else {
      return 'Introduce la cantidad de alineadores';
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <PlanningReviewNavigation history={history} />
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <PlanningReviewHeader
                edit={edit}
                firstName={firstName}
                history={history}
                lastName={lastName}
                location={location}
                onClickCancel={onClickCancel}
                progressUpload={progressUpload}
                sendPlanning={sendPlanning}
                setEdit={setEdit}
                software={software}
                statusTreatment={statusTreatment}
                treatment={treatment}
              />
              {treatment === 'cryst_aligner' ? (
                <PlanningReviewCrystAligner
                  alignerSendType={alignerSendType}
                  alineadoresInferiores={alineadoresInferiores}
                  alineadoresInferioresSoloLectura={
                    alineadoresInferioresSoloLectura
                  }
                  alineadoresSuperiores={alineadoresSuperiores}
                  alineadoresSuperioresSoloLectura={
                    alineadoresSuperioresSoloLectura
                  }
                  calculateAlineadores={calculateAlineadores}
                  isEditMode={edit}
                  errorMessages={errorMessages}
                  handleOnChange={handleOnChange}
                  idCase={idCase}
                  linkvisor={linkvisor}
                  linkvisorPatient={linkvisorPatient}
                  linkvisordesk={linkvisordesk}
                  location={location}
                  mp4FileName={mp4FileName}
                  mp4LinkVisor={mp4LinkVisor}
                  onUploadMP4ForOtherInputRef={onUploadMP4ForOtherInputRef}
                  openVisorDesck={openVisorDesck}
                  partialAligner={partialAligner}
                  partialities={partialities}
                  partialitiesErrorMessages={partialitiesErrorMessages}
                  progressUpload={progressUpload}
                  refInput={refInput}
                  setAlignerSendType={setAlignerSendType}
                  setAlineadoresInferiores={setAlineadoresInferiores}
                  setAlineadoresSuperiores={setAlineadoresSuperiores}
                  setFileMp4={setFileMp4}
                  setIdCase={setIdCase}
                  setLinkVisor={setLinkVisor}
                  setLinkVisorDesk={setLinkVisorDesk}
                  setLinkVisorPatient={setLinkVisorPatient}
                  setPartialities={setPartialities}
                  setPartialitiesErrorMessages={setPartialitiesErrorMessages}
                  software={software}
                  statusTreatment={statusTreatment}
                  treatment={treatment}
                />
              ) : (
                <PlanningReviewSmileSystem
                  brackets={brackets}
                  downloadFile={downloadFile}
                  edit={edit}
                  fileMp4={fileMp4}
                  handleOnChange={handleOnChange}
                  id={id}
                  idCase={idCase}
                  location={location}
                  mp4FileName={mp4FileName}
                  mp4LinkVisor={mp4LinkVisor}
                  onUploadMP4ForOtherInputRef={onUploadMP4ForOtherInputRef}
                  planningSendReady={planningSendReady}
                  progressUpload={progressUpload}
                  refInputSmartCheck={refInputSmartCheck}
                  sendPlanning={sendPlanning}
                  setEdit={setEdit}
                  setFileMp4={setFileMp4}
                  setIdCase={setIdCase}
                  statusTreatment={statusTreatment}
                />
              )}
              {location.state.visorReview && treatment === 'cryst_aligner' && (
                <PlanningReviewSmartCheck
                  channelStatus="active"
                  endChatPlanning={endChatPlanning}
                  id={id}
                  makeSmart={makeSmart}
                  messageDoctor={messageDoctor}
                  modalDoctorSmartcheck={modalDoctorSmartcheck}
                  planningSendReady={planningSendReady}
                  restartChatPlanning={restartChatPlanning}
                  statusSmart={statusSmart}
                  statusTreatment={statusTreatment}
                  token={token}
                  treatmentId={treatmentId}
                  userId={userId}
                />
              )}
            </div>
            {location.state.smart && (
              <InlineContainer style={{ alignItems: 'center' }}>
                <FeatherIcons icon="check" color="#EB9542" />
                <LabelNormal
                  color="#EB9542"
                  fontSize="17"
                  style={{ marginLeft: 14 }}
                >
                  Caso aprobado
                </LabelNormal>
              </InlineContainer>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Planning;
