import ModalSuccess from '@components/Modal/ModalSuccess';
import { LabelNormal } from '../../shared/Styled';

/**
 * @param {{onClickClose: (goBack: boolean) => void}} props
 * @returns {JSX.Element}
 */
export const AgreedChangesModal = ({ onClickClose }) => {
  return (
    <ModalSuccess onClick={() => onClickClose(true)}>
      <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
        Cambios acordados
      </LabelNormal>
      <LabelNormal
        fontSize="17"
        color="#4F4F4F"
        style={{ paddingTop: 14, marginBottom: 40 }}
      >
        Espera a que el doctor envíe sus correcciones.
      </LabelNormal>
    </ModalSuccess>
  );
};
