import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  MainLogin,
  ImgLogin,
  SpanLogin,
  SpanCorreo,
  H2Login,
  InputContainer,
  SpanInput,
} from '@components/Login/LoginLink.style';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_LINK_LOGIN, MUTATION_LOGIN } from '@graphql/mutation';
import LoginImg from '@images/Union.svg';
import { QUERY_GET_USER_BY_UUID } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';
import InputPassword from '@components/shared/InputPassword';

const LoginLink = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [userDetail, setUserDetails] = useState({});
  const [disabled, setDisabled] = useState(false);

  const [generatePassword] = useMutation(MUTATION_LINK_LOGIN, {
    onCompleted: (data) => {
      if (data.generatePassword) {
        if (
          userDetail.type === 'university' ||
          userDetail.type === 'student' ||
          userDetail.type === 'teacher'
        ) {
          if (userDetail.type === 'university')
            history.push({
              pathname: `/auth/verificar-universidad/${userDetail.role_id}`,
              state: { password },
            });
          if (userDetail.type === 'student')
            history.push({
              pathname: `/auth/verificar-estudiante/${userDetail.role_id}`,
              state: { password },
            });
          if (userDetail.type === 'teacher')
            history.push({
              pathname: `/auth/verificar-profesor/${userDetail.role_id}`,
              state: { password },
            });
        } else if (userDetail.type === 'doctor') {
          history.push({ pathname: `/auth/inicio-sesion/` });
        } else {
          login({ variables: { email: userDetail.email, password } });
        }
      }
    },
  });

  const [login] = useMutation(MUTATION_LOGIN, {
    onCompleted: (data) => {
      if (data.login) {
        props.login(
          data.login.token,
          data.login.type,
          data.login.user_id,
          data.login.role_id
        );
      }
    },
  });

  const [getUser] = useLazyQuery(QUERY_GET_USER_BY_UUID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getUserByUuid) {
        setUserDetails({
          ...data.getUserByUuid.user,
          role_id: data.getUserByUuid.role_id,
        });
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getUser({ variables: { uuid: id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     validationPrev();
  // },[password, confirmPass])

  const validationPrev = () => {
    if (password === '') {
      setDisabled(true);
    } else if (confirmPass === '') {
      setDisabled(true);
    } else if (password.length < 8) {
      setDisabled(true);
    } else if (password !== confirmPass) {
      setDisabled(true);
    } else {
      setDisabled(false);
      generatePassword({ variables: { uuid: id, password } });
    }
  };

  return (
    <MainLogin>
      <ImgLogin src={LoginImg} />
      <H2Login>
        Hola{' '}
        {userDetail.id
          ? `${userDetail.first_name} ${userDetail.last_name}`
          : ''}
      </H2Login>
      <SpanCorreo>{userDetail.id ? userDetail.email : ''}</SpanCorreo>
      <SpanLogin>Crea una contraseña para iniciar sesión.</SpanLogin>
      <InputContainer>
        <SpanInput>Contraseña</SpanInput>
        <InputPassword
          placeholder="8 caracteres"
          //   validation="passwordlenght"
          value={password}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          style={{
            borderColor:
              password !== '' &&
              confirmPass !== '' &&
              (password.length < 8 ||
                (password.length >= 8 && password !== confirmPass))
                ? '#C60E02'
                : password.length >= 8 && password === confirmPass
                ? '#169D00'
                : '',
            borderWeight: 1,
            borderstyle: 'solid',
            minWidth: 305,
          }}
        />
        {password === '' && !disabled && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 18,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *Introduce una contraseña
          </p>
        )}
      </InputContainer>
      <InputContainer>
        <SpanInput>Confirmar contraseña</SpanInput>
        <InputPassword
          placeholder="Confirmar contraseña"
          name="repassword"
          onChange={(e) => setConfirmPass(e.target.value)}
          style={{
            borderColor:
              password !== '' && confirmPass !== '' && password !== confirmPass
                ? '#C60E02'
                : password.length >= 8 && password === confirmPass
                ? '#169D00'
                : '',
            borderWeight: 1,
            borderstyle: 'solid',
            minWidth: 305,
          }}
        />
        {password !== '' &&
          confirmPass !== '' &&
          confirmPass !== password &&
          !disabled && (
            <p
              style={{
                color: '#C60E02',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Contraseñas no coinciden
            </p>
          )}
        {password !== '' &&
          confirmPass !== '' &&
          confirmPass === password &&
          !disabled && (
            <p
              style={{
                color: '#169D00',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Contraseñas coinciden
            </p>
          )}
      </InputContainer>
      <ButtonPrime label="Ingresar" onClick={() => validationPrev()} />
    </MainLogin>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (token, type, id, role_id) =>
    dispatch(action.loginSuccess(token, type, id, role_id)),
});

export default connect(null, mapDispatchToProps)(LoginLink);
