import FeatherIcon from 'feather-icons-react';
import { DownloadButton, LabelNormal } from '../shared/Styled';
import { downloadImage } from '../../utils';

const FileCard = ({ stl, name }) => (
    <div
        style={{
            marginRight: 20,
            marginBottom: 20,
        }}
    >
        <div style={{ position: 'relative' }}>
            <img
                src={require('@images/files/stl.jpg')}
                style={{
                    height: 104,
                    width: 162,
                    borderRadius: 16.28,
                }}
                alt=""
            />
            <DownloadButton
                width="32px"
                height="32px"
                onClick={() =>
                    downloadImage(
                        stl?.name,
                        stl?.url
                    )
                }
                style={{
                    display:
                    stl?.type === 'pdf'
                    ? 'none'
                    : 'flex',
                }}
            >
                <FeatherIcon
                    icon="download"
                    color="#FFF"
                    size="30"
                />
            </DownloadButton>
        </div>
        <LabelNormal
            style={{
                width: 162,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}
            color="#828282"
            fontSize="14"
        >
            { stl?.name }
        </LabelNormal>
        <LabelNormal color="#3C4549" fontSize="14">
            { name }
        </LabelNormal>
        </div>
);

export default FileCard;
