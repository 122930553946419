import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_HISTORY_CASE } from '@graphql/query';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;

const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 28px;
    margin-right: 79px;
    margin-bottom: 100px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ContainerRegister = styled.div`
    margin-top: 29px;
    margin-left: 51px;
    max-width: 875px;
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const HeaderCardTable = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;


const HistoryCase = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const { state } = useLocation()

    const [histories, setHistories] = useState([]);

    const [getHistory] = useLazyQuery(QUERY_GET_HISTORY_CASE, {
        onCompleted: data => {
            if(data.getAssingHistories){
                setHistories(data.getAssingHistories);
            }
        }
    })

    useEffect(() => {
        getHistory({ variables: { patient_id: id } });
    },[])

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="universidades" />
            <ContainHome className='bootstrap-wrapper'>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} />
                    </div>
                </Header>
                <ContainerRegister>
                    <Title>Historial de profesores y estudiantes asignados</Title>
                    <div style={{marginTop: 10, marginBottom: 39}}>
                        <Breadcrumb elements={3} onClick={() => history.push(`/app/universidades/${state.universityId}`)} mainLabel={`${state.nameUniversity}`} midLabel={'Caso'} onSecondClick={() => history.goBack()} midSecondLabel={`${state.patientName}`} onTirdClick={() => history.goBack()} endLabel={'Historial'}/>
                    </div>
                   
                   <div className='bootstrap-wrapper row'>
                        <div className='col-md-12' style={{marginTop: 66}}>
                            <InlineContainer style={{justifyContent: 'space-between', marginBottom: 17}}>
                                <LabelNormal fontSize="22" color="#454D58">Estudiantes asignados</LabelNormal>
                            </InlineContainer>

                            <HeaderCardTable>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Estudiante</LabelNormal>
                                    </div>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Pasos de historial</LabelNormal>
                                    </div>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Fecha</LabelNormal>
                                    </div>
                                </div>
                            </HeaderCardTable>
                            <CardContainer>
                                {(histories.doctors_histories && histories.doctors_histories.length > 0) ?
                                    <>
                                        {histories.doctors_histories.map(item => (
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <Link to={''}>
                                                        <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.doctor.user.first_name} ${item.doctor.user.last_name}`}</LabelNormal>
                                                    </Link>
                                                </div>
                                                {item.histories.length > 0 &&
                                                    <div className='col-md-8'>
                                                        {item.histories.map(h => h.message && (
                                                            <div className="row">
                                                                <div className='col-md-6'>
                                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{h.message}</LabelNormal>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{h.created_at}</LabelNormal>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                
                                            </div>
                                        ))}
                                    </>
                                :
                                    <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>No hay otros estudiantes asignados al caso</LabelNormal>
                                }
                            </CardContainer>
                        </div>

                        <div className='col-md-12' style={{marginTop: 66}}>
                            <InlineContainer style={{justifyContent: 'space-between', marginBottom: 17}}>
                                <LabelNormal fontSize="22" color="#454D58">Profesores asignados</LabelNormal>
                            </InlineContainer>

                            <HeaderCardTable>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Profesor</LabelNormal>
                                    </div>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Pasos de historial</LabelNormal>
                                    </div>
                                    <div className='col-md-4'>
                                        <LabelNormal color="#3C4549" fontSize="17">Fecha</LabelNormal>
                                    </div>
                                </div>
                            </HeaderCardTable>
                            <CardContainer>
                                {(histories.teachers_histories && histories.teachers_histories.length > 0) ?
                                    <>
                                        {histories.teachers_histories.map(item => (
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <Link to={''}>
                                                        <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.teacher.user.first_name} ${item.teacher.user.last_name}`}</LabelNormal>
                                                    </Link>
                                                </div>
                                                {item.histories.length > 0 &&
                                                    <div className='col-md-8'>
                                                        {item.histories.map(h => h.message && (
                                                            <div className="row">
                                                                <div className='col-md-6'>
                                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{h.message}</LabelNormal>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{h.created_at}</LabelNormal>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                
                                            </div>
                                        ))}
                                    </>
                                :
                                    <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>No hay otros profesores asignados al caso</LabelNormal>
                                }
                            </CardContainer>
                        </div>
                   </div>
                </ContainerRegister>
            </ContainHome>
        </div>
    );
}

export default HistoryCase;