import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 76px;
    background: #F6F6F8;
    padding: 9px 17px 9px 9px;
    border-radius: 33px;
    margin-right: 17px;
`;

const LabelNormal = styled.p`
    font-size: ${({fontSize}) => fontSize ? fontSize : '9'}px;
    font-family: neue-montreal-regular;
    color: ${({color}) => color ? color : '#757575'};
    margin: 0px;
    margin-left: 12px;
`;

const Cap = ({color}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.49 10.19L20.49 9.63996L11.49 4.63996H11.38C11.3187 4.61403 11.2551 4.59395 11.19 4.57996H11H10.82C10.7517 4.59396 10.6847 4.61404 10.62 4.63996H10.51L1.51 9.63996C1.3561 9.72717 1.22809 9.85365 1.13903 10.0065C1.04997 10.1593 1.00305 10.3331 1.00305 10.51C1.00305 10.6869 1.04997 10.8606 1.13903 11.0134C1.22809 11.1663 1.3561 11.2927 1.51 11.38L4 12.76V17.5C4 18.2956 4.31607 19.0587 4.87868 19.6213C5.44129 20.1839 6.20435 20.5 7 20.5H15C15.7956 20.5 16.5587 20.1839 17.1213 19.6213C17.6839 19.0587 18 18.2956 18 17.5V12.76L20 11.64V14.5C20 14.7652 20.1054 15.0195 20.2929 15.2071C20.4804 15.3946 20.7348 15.5 21 15.5C21.2652 15.5 21.5196 15.3946 21.7071 15.2071C21.8946 15.0195 22 14.7652 22 14.5V11.06C21.9997 10.8828 21.9523 10.7088 21.8627 10.556C21.7731 10.4031 21.6445 10.2768 21.49 10.19ZM16 17.5C16 17.7652 15.8946 18.0195 15.7071 18.2071C15.5196 18.3946 15.2652 18.5 15 18.5H7C6.73478 18.5 6.48043 18.3946 6.29289 18.2071C6.10536 18.0195 6 17.7652 6 17.5V13.87L10.51 16.37L10.66 16.43H10.75C10.833 16.4404 10.917 16.4404 11 16.43C11.083 16.4404 11.167 16.4404 11.25 16.43H11.34C11.3931 16.4188 11.4438 16.3985 11.49 16.37L16 13.87V17.5ZM11 14.36L4.06 10.5L11 6.63996L17.94 10.5L11 14.36Z" fill={color} />
    </svg>
)

const Libraries = ({color}) => (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z" fill={color} />
    </svg>
)

const ClipBoard = ({color}) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 12H5C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H9C9.26522 14 9.51957 13.8946 9.70711 13.7071C9.89464 13.5196 10 13.2652 10 13C10 12.7348 9.89464 12.4804 9.70711 12.2929C9.51957 12.1054 9.26522 12 9 12ZM13 2H11.82C11.6137 1.41645 11.2319 0.910998 10.7271 0.552938C10.2222 0.194879 9.61894 0.00173951 9 0H7C6.38106 0.00173951 5.7778 0.194879 5.27293 0.552938C4.76807 0.910998 4.38631 1.41645 4.18 2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2ZM6 3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H9C9.26522 2 9.51957 2.10536 9.70711 2.29289C9.89464 2.48043 10 2.73478 10 3V4H6V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H4V5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6H11C11.2652 6 11.5196 5.89464 11.7071 5.70711C11.8946 5.51957 12 5.26522 12 5V4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V17ZM11 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9C4 9.26522 4.10536 9.51957 4.29289 9.70711C4.48043 9.89464 4.73478 10 5 10H11C11.2652 10 11.5196 9.89464 11.7071 9.70711C11.8946 9.51957 12 9.26522 12 9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8Z" fill={color} />
    </svg>
)

const ChipIcons = (props) => (
    <Container>
        {props.icon === 'cap' &&
            <Cap color={props.color} />
        }
        {props.icon === 'libraries' &&
            <Libraries color={props.color} />
        }
        {props.icon === 'clipboard' &&
            <ClipBoard color={props.color} />
        }
        <LabelNormal color="#757575" fontSize="17">{props.name}</LabelNormal>
    </Container>
)

export default ChipIcons;
export {
    Cap,
    Libraries,
    ClipBoard
}