import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UilFileBlank } from '@iconscout/react-unicons';
import { useMutation } from '@apollo/client';
import FeatherIcons from 'feather-icons-react';
import styled from 'styled-components';
import axios from 'axios';

import { MUTATION_SEND_PLANNING_DATA } from '@graphql/mutation';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import { PlanningTotalAligners } from './Planning/TotalAligners';
import { PlanningAlignerPartialities } from './Planning/AlignerPartialities';
import { usePartialAligner } from './Planning/usePartialAligner';
import {
  alignerSendTypes,
  defaultPartialitiesErrorMessages,
  defaultPartialities,
} from './Planning/consts';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17rem;
  height: 2.8rem;
  background-color: #f6f6f8;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
  border-radius: 5px;
  margin: 0.6rem 0 1.9rem 0.3rem;
`;

const Planning = (props) => {
  const DEFAULT_ALIGNER_TYPE = alignerSendTypes[0];
  const isEditMode = true;
  const enablePartialities =
    process.env.REACT_APP_ENABLE_PARTIALITIES === 'true';

  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatment, setTreatment] = useState('');

  const [typebracket, setTypeBracket] = useState('');
  const [software, setSoftware] = useState('ARCAD');
  const [idCase, setIdCase] = useState('');
  const [alineadoresSuperiores, setAlineadoresSuperiores] = useState('0');
  const [alineadoresInferiores, setAlineadoresInferiores] = useState('0');
  const [linkvisor, setLinkVisor] = useState('');
  const [linkvisordesk, setLinkVisorDesk] = useState('');
  const [linkvisorPatient, setLinkVisorPatient] = useState('');
  /**
   *  @type {[Array<{ upper: number, bottom: number, isManufactured: boolean }>,
   * React.Dispatch<Array<{ upper: number, bottom: number }>>]} state
   */
  const [partialities, setPartialities] = useState(defaultPartialities);
  /**
   *  @type {["Partial" | "Total", React.Dispatch<"Partial" | "Total">]} state
   */
  const [alignerSendType, setAlignerSendType] = useState(DEFAULT_ALIGNER_TYPE);
  /**
   *  @type {[Object<number, string>, React.Dispatch<Object<number, string>]} state
   */
  const [partialitiesErrorMessages, setPartialitiesErrorMessages] = useState(
    defaultPartialitiesErrorMessages
  );

  const [errorForm, setErrorForm] = useState(false);
  const refInput = useRef(null);
  const [fileMp4, setFileMp4] = useState(false);
  const [uploadMp4File, setUploadMp4File] = useState(null);
  const [progressUpload, setProgressUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nameFileMp4, setFileNameMp4] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    idCase: '',
    alineadoresSuperiores: '',
    alineadoresInferiores: '',
    linkvisor: '',
    fileMp4: '',
    linkvisordesk: '',
    linkvisorPatient: '',
  });

  const partialAligner = usePartialAligner({
    bottomAligners: alineadoresInferiores,
    errorMessages: partialitiesErrorMessages,
    partialities,
    setErrorMessages: setPartialitiesErrorMessages,
    upperAligners: alineadoresSuperiores,
  });

  useEffect(() => {
    if (props.location.state) {
      const state = props.location.state;
      setFirstName(state.firstName);
      setLastName(state.lastName);
      setTreatment(state.treatment);
      setTypeBracket(state.brackets);
    }
  }, [props.location]);

  const [planningSend] = useMutation(MUTATION_SEND_PLANNING_DATA, {
    onCompleted: async (data) => {
      setIsLoading(false);
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Planificación enviada
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Espera a que el doctor revise el visor y lo apruebe o haga
            comentarios.
          </LabelNormal>
        </ModalSuccess>
      );
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const uploadSTLAxios = async () => {
    setIsLoading(true);
    if (!uploadMp4File) {
      idCase !== ''
        ? treatment === 'cryst_aligner'
          ? planningSend({
              variables: {
                aligners_number: +alineadoresSuperiores,
                aligner_send_type: alignerSendType,
                bottom_aligners_number: +alineadoresInferiores,
                partialities: enablePartialities
                  ? partialities.map((p) => {
                      return {
                        bottom: p.bottom,
                        upper: p.upper,
                      };
                    })
                  : [],
                planning_case_id: idCase,
                planning_software: software,
                treatment_id: props.location.state.treatmentId,
                visor_desk_link: linkvisordesk,
                visor_patient_link: linkvisorPatient,
                visor_url: linkvisor,
              },
            })
          : planningSend({
              variables: {
                treatment_id: props.location.state.treatmentId,
                planning_case_id: idCase,
                partialities: [],
              },
            })
        : setErrorForm(true);
      return;
    }
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query:
          'mutation addPatientForm($patient_id: Int!, $file: Upload!) {addPatientForm(input: { patient_id: $patient_id, file: $file }) {id}}',
        variables: {
          file: null,
          patient_id: parseInt(id),
        },
      })
    );
    formData.append('0', uploadMp4File);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));

    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addPatientForm) {
          setIsLoading(false);
          idCase !== ''
            ? treatment === 'cryst_aligner'
              ? planningSend({
                  variables: {
                    treatment_id: props.location.state.treatmentId,
                    planning_software: software,
                    planning_case_id: idCase,
                    aligners_number: +alineadoresSuperiores,
                    bottom_aligners_number: +alineadoresInferiores,
                    visor_url: linkvisor,
                    visor_desk_link: linkvisordesk,
                    visor_patient_link: linkvisorPatient,
                  },
                })
              : planningSend({
                  variables: {
                    treatment_id: props.location.state.treatmentId,
                    planning_case_id: idCase,
                  },
                })
            : setErrorForm(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onUploadMP4 = (e) => {
    setFileNameMp4(e.target.files[0].name);
    setUploadMp4File(e.target.files[0]);
  };

  const calculateAlineadores = () => {
    const total =
      parseInt(alineadoresSuperiores) + parseInt(alineadoresInferiores);
    if (total > 0 && total <= 28) {
      return 'Terso';
    } else if (total <= 50 && total > 28) {
      return 'Moderado';
    } else if (total > 50) {
      return 'Integral';
    } else {
      return 'Introduce la cantidad de alineadores';
    }
  };

  const sendToPlanification = async () => {
    if (validateForm()) {
      await uploadSTLAxios();
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (idCase === '') {
      errors = {
        ...errors,
        idCase: '*Introduce el ID del caso',
      };
      isValid = false;
    }

    if (treatment === 'cryst_aligner') {
      if (alineadoresSuperiores === '' && alineadoresInferiores !== '') {
        errors = {
          ...errors,
          alineadoresSuperiores: '*Introduce una cantidad de alineadores',
        };
        isValid = false;
      }
      if (alineadoresInferiores === '' && alineadoresSuperiores !== '') {
        errors = {
          ...errors,
          alineadoresInferiores: '*Introduce una cantidad de alineadores',
        };
        isValid = false;
      }
      if (alineadoresSuperiores === '0') {
        errors = {
          ...errors,
          alineadoresSuperiores: '*Indica una cantidad mayor a 0',
        };
        isValid = false;
      }
      if (alineadoresInferiores === '0') {
        errors = {
          ...errors,
          alineadoresInferiores: '*Indica una cantidad mayor a 0',
        };
        isValid = false;
      }
      if (linkvisor === '') {
        errors = {
          ...errors,
          linkvisor: '*Introduce el link del visor',
        };
        isValid = false;
      }

      if (software === 'Nemocast') {
        if (uploadMp4File === null) {
          errors = {
            ...errors,
            fileMp4: '*Sube un archivo del video',
          };
          isValid = false;
        }
      } else {
        if (linkvisordesk === '') {
          errors = {
            ...errors,
            linkvisordesk: '*Introduce el link del visor',
          };
          isValid = false;
        }
        if (linkvisorPatient === '') {
          errors = {
            ...errors,
            linkvisorPatient: '*Introduce el link del visor',
          };
          isValid = false;
        }
      }
      if (enablePartialities) {
        partialities.forEach((partiality, index) => {
          isValid = partialAligner.validateErrors(
            partialities,
            index,
            partiality.bottom
          );
          isValid = partialAligner.validateErrors(
            partialities,
            index,
            partiality.upper
          );
        });

        // Detect if some error in partial aligners exists.
        Object.values(partialitiesErrorMessages).forEach((e) => {
          if (e !== '') isValid = false;
        });
      }
    }

    console.log('Errors:', errors);
    console.log('Is valid:', isValid);

    setErrorMessages(errors);
    return isValid;
  };

  const handleOnChange = (value, name) => {
    const errorsDescriptions = {
      idCase: '*Introduce el ID del caso',
      alineadoresInferiores: '*Introduce una cantidad de alineadores',
      alineadoresSuperiores: '*Introduce una cantidad de alineadores',
      linkvisor: '*Introduce el link del visor',
      fileMp4: '*Sube un archivo del video',
      linkvisordesk: '*Introduce el link del visor',
      linkvisorPatient: '*Introduce el link del visor',
    };

    if (
      (name === 'alineadoresInferiores' &&
        value === '0' &&
        alineadoresSuperiores === '0') ||
      (name === 'alineadoresSuperiores' &&
        value === '0' &&
        alineadoresInferiores === '0')
    ) {
      setErrorMessages({
        ...errorMessages,
        alineadoresInferiores: '*Indica una cantidad mayor a 0',
        alineadoresSuperiores: '*Indica una cantidad mayor a 0',
      });
    } else if (value === '') {
      setErrorMessages({
        ...errorMessages,
        [name]: errorsDescriptions[name],
      });
    } else {
      if (
        name === 'alineadoresSuperiores' ||
        name === 'alineadoresInferiores'
      ) {
        setErrorMessages({
          ...errorMessages,
          [name]: '',
          alineadoresInferiores: '',
          alineadoresSuperiores: '',
        });
      } else {
        setErrorMessages({
          ...errorMessages,
          [name]: '',
        });
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Planificación</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.goBack()}
                    mainLabel={'Caso'}
                    midLabel={`${firstName} ${lastName}`}
                    onSecondClick={() => history.goBack()}
                    endLabel={'Planificación'}
                  />
                  {treatment === 'cryst_aligner' && (
                    <LabelNormal color="#757575" fontSize="17">
                      Selecciona un software para la planificación del caso
                      acorde a <br />
                      los archivos aprobados.
                    </LabelNormal>
                  )}
                  {treatment === 'smile_system' && (
                    <LabelNormal color="#757575" fontSize="17">
                      Introduce el ID de caso para activar la planificación
                    </LabelNormal>
                  )}
                </div>
              </div>
              <div className="col-md-12" style={{ maxWidth: 1145 }}>
                {treatment === 'cryst_aligner' && (
                  <div style={{ marginBottom: 30 }}>
                    <InlineContainer>
                      <div className="radio" style={{ marginRight: 20 }}>
                        <input
                          id="software-radio-1"
                          name="software"
                          onClick={() => setSoftware('ARCAD')}
                          defaultChecked={
                            software === 'ARCAD' ? 'checked' : false
                          }
                          type="radio"
                        />
                        <label
                          htmlFor="software-radio-1"
                          className="radio-label"
                        >
                          ARCAD
                        </label>
                      </div>
                      <div className="radio">
                        <input
                          id="software-radio-2"
                          name="software"
                          disabled={true}
                          onClick={() => setSoftware('Nemocast')}
                          defaultChecked={
                            software === 'Nemocast' ? 'checked' : false
                          }
                          type="radio"
                        />
                        <label
                          htmlFor="software-radio-2"
                          className="radio-label"
                        >
                          Nemocast
                        </label>
                      </div>
                    </InlineContainer>
                  </div>
                )}
                {treatment === 'cryst_aligner' && (
                  <LabelNormal color="#3C4549" fontSize="22">
                    Registro en software
                  </LabelNormal>
                )}
                {treatment === 'cryst_aligner' && (
                  <div className="row" style={{ marginTop: 30 }}>
                    <div className="col-md-4">
                      <Input
                        label={
                          software === 'ARCAD'
                            ? 'ID de caso de ARCAD'
                            : 'ID de caso'
                        }
                        placeholder="ID"
                        onChange={(e) => {
                          setIdCase(e.target.value);
                          handleOnChange(e.target.value, 'idCase');
                        }}
                        style={{ marginTop: 10 }}
                        className={errorMessages.idCase && 'etftxW'}
                      />
                      {errorMessages.idCase && (
                        <div style={{ marginBottom: 15 }}>
                          <LabelNormal fontSize="17" color="#C60E02">
                            {errorMessages.idCase}
                          </LabelNormal>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {treatment !== 'cryst_aligner' && (
                  <InlineContainer>
                    <div style={{ marginRight: 70 }}>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="18"
                        style={{ fontWeight: 'bolder' }}
                      >
                        Software de planificación
                      </LabelNormal>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="17"
                        style={{ fontWeight: 400, marginTop: 15 }}
                      >
                        Nemocast
                      </LabelNormal>
                    </div>
                    <div>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="18"
                        style={{ fontWeight: 'bolder' }}
                      >
                        Tipo de bracket
                      </LabelNormal>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="17"
                        style={{ fontWeight: 400, marginTop: 15 }}
                      >
                        {typebracket
                          ? typebracket.includes('Brackets')
                            ? typebracket.replace(' Brackets', '')
                            : typebracket
                          : ' '}
                      </LabelNormal>
                    </div>
                  </InlineContainer>
                )}

                {treatment !== 'cryst_aligner' && (
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 39 }}
                  >
                    Para la revisión del doctor
                  </LabelNormal>
                )}

                {treatment !== 'cryst_aligner' && (
                  <div
                    className="row"
                    style={{ marginTop: 10, marginLeft: -10 }}
                  >
                    <div className="col-md-4">
                      <Input
                        label="ID de caso"
                        placeholder="Case ID"
                        onChange={(e) => {
                          setIdCase(e.target.value);
                          handleOnChange(e.target.value, 'idCase');
                        }}
                        style={{ marginTop: 10, height: 45 }}
                      />
                    </div>
                  </div>
                )}

                {treatment !== 'cryst_aligner' && errorForm && (
                  <div style={{ marginBottom: 15 }}>
                    <LabelNormal fontSize="17" color="#C60E02">
                      *Introduce el ID de caso
                    </LabelNormal>
                  </div>
                )}

                {treatment !== 'cryst_aligner' && errorForm && (
                  <div
                    className="col-md-4"
                    style={{
                      background: '#FFE5E3',
                      border: '1px solid #E41D11',
                      padding: '2px 10px 2px 10px',
                      borderRadius: 7,
                      width: 229,
                    }}
                  >
                    <LabelNormal fontSize="14" color="#C60E02">
                      *Completa los campos faltantes
                    </LabelNormal>
                  </div>
                )}

                {treatment === 'cryst_aligner' && (
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 20 }}
                  >
                    Para la revisión del doctor
                  </LabelNormal>
                )}

                <div className="row" style={{ marginTop: 10 }}>
                  {software === 'ARCAD' && treatment === 'cryst_aligner' && (
                    <div className="col-md-4">
                      <Input
                        label="Link de visor para abrir en software de escritorio"
                        placeholder="Link"
                        style={{ marginTop: 10 }}
                        className={errorMessages.linkvisordesk && 'etftxW'}
                        onChange={(e) => {
                          setLinkVisorDesk(e.target.value);
                          handleOnChange(e.target.value, 'linkvisordesk');
                        }}
                        value={linkvisordesk}
                        validation="url"
                        type="url"
                      />
                      {errorMessages.linkvisordesk && (
                        <div style={{ marginBottom: 15 }}>
                          <LabelNormal fontSize="17" color="#C60E02">
                            {errorMessages.linkvisordesk}
                          </LabelNormal>
                        </div>
                      )}

                      <div
                        style={{
                          position: 'absolute',
                          top: 3,
                          left: 340,
                          zIndex: 99,
                        }}
                        className="tooltip"
                      >
                        <FeatherIcons
                          icon="help-circle"
                          size="15"
                          color="#BDBDBD"
                        />
                        <span className="tooltiptext">
                          <LabelNormal
                            color="#757575"
                            fontSize="14"
                            style={{ textAlign: 'left' }}
                          >
                            Copia y pega directo el link desde Smile Design
                            Viewer
                          </LabelNormal>
                          <img
                            src={require('@images/tooltipARCAD.png')}
                            style={{ height: 219, width: 247 }}
                            alt="tooltip-arcad"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {treatment === 'cryst_aligner' && (
                    <div className="col-md-4">
                      <Input
                        label={
                          software === 'Nemocast'
                            ? 'Link de video para ver visor'
                            : 'Link de visor para abrir en web'
                        }
                        placeholder="Link"
                        style={{ marginTop: software === 'ARCAD' ? 30 : 10 }}
                        onChange={(e) => {
                          setLinkVisor(e.target.value);
                          handleOnChange(e.target.value, 'linkvisor');
                        }}
                        value={linkvisor}
                        validation="url"
                        type="url"
                        className={errorMessages.linkvisor && 'etftxW'}
                      />
                      {errorMessages.linkvisor && (
                        <div style={{ marginBottom: 15 }}>
                          <LabelNormal fontSize="17" color="#C60E02">
                            {errorMessages.linkvisor}
                          </LabelNormal>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {software === 'Nemocast' && (
                  <React.Fragment>
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-4">
                        <LabelNormal
                          fontSize="17"
                          style={{
                            marginBottom: '0.6rem',
                            marginTop: '0.6rem',
                          }}
                        >
                          Para que el doctor comparta con el paciente
                        </LabelNormal>
                        <LabelNormal
                          fontSize="18"
                          color="#3C4549"
                          style={{ fontWeight: '600' }}
                        >
                          Link de visor para compartir con paciente
                        </LabelNormal>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                      {!fileMp4 && (
                        <div className="col-md-4">
                          <ButtonPrime
                            label="Subir video de visor"
                            icon="arrow-up"
                            size="24"
                            background="transparent"
                            color="#3C4549"
                            border="2px solid #3C4549"
                            minWidth="227"
                            onClick={() => refInput.current.click()}
                            className={errorMessages.fileMp4 && 'etftxW'}
                            style={{
                              marginLeft: 5,
                              fontWeight: '500',
                              width: 251,
                              height: 47,
                              marginTop: 10,
                            }}
                          />
                          {errorMessages.fileMp4 && (
                            <div style={{ marginBottom: 15 }}>
                              <LabelNormal fontSize="17" color="#C60E02">
                                {errorMessages.fileMp4}
                              </LabelNormal>
                            </div>
                          )}
                        </div>
                      )}
                      {fileMp4 && (
                        <div
                          className="col-12"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <VideoWrapper
                            style={{
                              marginRight: 30,
                              marginBottom: 0,
                            }}
                          >
                            <UilFileBlank size="24" color="#757575" />
                            <LabelNormal color="#828282" fontSize="17">
                              {isLoading && progressUpload >= 0
                                ? `cargando archivo... ${progressUpload}%`
                                : nameFileMp4}
                            </LabelNormal>
                          </VideoWrapper>
                          <ButtonPrime
                            label="Cambiar archivo"
                            background="transparent"
                            colorEnabled="#436FF1"
                            color="#757575"
                            border="none"
                            backgroundEnabled="transparent"
                            onClick={() => refInput.current.click()}
                            style={{
                              textDecoration: 'underline',
                              fontSize: 17,
                            }}
                          />
                        </div>
                      )}
                      <input
                        type="file"
                        accept=".mp4"
                        ref={refInput}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleOnChange('fileMp4', 'fileMp4');
                          setFileMp4(true);
                          onUploadMP4(e);
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}

                {software === 'ARCAD' && treatment === 'cryst_aligner' && (
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 20 }}
                  >
                    Para que el doctor lo comparta con el paciente
                  </LabelNormal>
                )}

                <div className="row" style={{ marginTop: 10 }}>
                  {software === 'ARCAD' && treatment === 'cryst_aligner' && (
                    <div className="col-md-4">
                      <Input
                        label="Link de visor para compartir con paciente"
                        placeholder="Link"
                        style={{ marginTop: 10 }}
                        className={errorMessages.linkvisorPatient && 'etftxW'}
                        onChange={(e) => {
                          setLinkVisorPatient(e.target.value);
                          handleOnChange(e.target.value, 'linkvisorPatient');
                        }}
                        value={linkvisorPatient}
                        validation="url"
                        type="url"
                      />
                      {errorMessages.linkvisorPatient && (
                        <div style={{ marginBottom: 15 }}>
                          <LabelNormal fontSize="17" color="#C60E02">
                            {errorMessages.linkvisorPatient}
                          </LabelNormal>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {treatment === 'cryst_aligner' && (
                  <>
                    <PlanningTotalAligners
                      alineadoresInferiores={alineadoresInferiores}
                      alineadoresSuperiores={alineadoresSuperiores}
                      calculateAlineadores={calculateAlineadores}
                      isEditMode={isEditMode}
                      errorMessages={errorMessages}
                      handleOnChange={handleOnChange}
                      setAlineadoresInferiores={setAlineadoresInferiores}
                      setAlineadoresSuperiores={setAlineadoresSuperiores}
                    />
                    {enablePartialities && (
                      <PlanningAlignerPartialities
                        alignerSendType={alignerSendType}
                        isEditMode={isEditMode}
                        errorMessages={partialitiesErrorMessages}
                        bottomAligners={alineadoresInferiores}
                        partialities={partialities}
                        setAlignerSendType={setAlignerSendType}
                        setErrorMessages={setPartialitiesErrorMessages}
                        setPartialities={setPartialities}
                        upperAligners={alineadoresSuperiores}
                        canAddPartiality={partialAligner.canAddPartiality}
                        canRemovePartiality={partialAligner.canRemovePartiality}
                        isAddPartialityEnabled={
                          partialAligner.isAddPartialityEnabled
                        }
                        validateErrors={partialAligner.validateErrors}
                      />
                    )}
                  </>
                )}
                <div className="row" style={{ marginTop: 39 }}>
                  <div className="col-md-4">
                    <ButtonPrime
                      onClick={sendToPlanification}
                      disabled={progressUpload}
                      label={'Enviar planificación'}
                      style={{ width: 218 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Planning;
