
import { Controller } from "react-hook-form"
import { Container, LabelInput, LabelMuted, InputComponent, InlineContainer, ErrorMessage } from "../Styled"
import Input from "../Input";

const PhoneInput = ({ name, control, errors, clearErrors, setValue, rules, countries = [], country, label, muted, ...props }) => {
    const maskPhone = (event) => {
        let val = event.target.value;
        val = val.replace(/ /gm, '').replace(/[^0-9]/g, '');
        if (val.length <= 10) {
          let num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
            6,
            val.length
          )}`;
          num = num.trim();
          setValue(name, num)
        }
    }
    return (
        <Container>
            { label && <LabelInput>{ label }</LabelInput> }
            { muted && <LabelMuted>{ muted }</LabelMuted> }
            <InlineContainer>
                <Input
                    readOnly
                    disabled
                    value={
                        country !== null
                        ? countries.find((c) => parseInt(c.id) === parseInt(country))?.lada_code
                        : '+52'
                    }
                    backgroundColor="#F6F6F8"
                    maxWidth="70px"
                />
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) => (
                        <InputComponent
                            {...field}
                            {...props}
                            onFocus={() => clearErrors && clearErrors(name)}
                            onChange={maskPhone}
                            borderColor={ errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)' }
                        />
                    )}
                />
            </InlineContainer>
            <InlineContainer>
                <ErrorMessage>{ errors && errors[name] && errors[name]?.message }</ErrorMessage>
            </InlineContainer>
        </Container>
    )
}

export default PhoneInput