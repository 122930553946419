import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Checkbox from "react-custom-checkbox";
import { MUTATION_UPDATE_CARD_DOCTOR } from '@graphql/mutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 79px;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;
const CardInlineContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
    display:inline-block;
    margin: 0px 5px;
`;
const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const options = {
      style: {
        base: {
          fontSize: '17px',
          color: '#3C4549',
          letterSpacing: '0.02em',
          background: '#FFF',
          '::placeholder': {
            color: '##828282',
          },
        },
        invalid: {
          color: '#3C4549',
        },
      },
    }

const EditCreditCards = (props) => {
    const history = useHistory();
    const [details, setDetails] = useState({});

    useEffect(() => {
        if(props.location){
            setDetails(props.location.state);
        }
    },[])

    const [changeDefaultCard] = useMutation(MUTATION_UPDATE_CARD_DOCTOR, {
        onCompleted: data => {
            if(data.updatePaymentDefault){
                setDetails({ ...details, default: true })
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => history.push('/doctor/perfil'))}>
                        <LabelNormal color="#060809" fontSize="22" style={{marginBottom: 14}}>Tarjeta definida como método de pago predeterminado</LabelNormal>
                    </ModalSuccess>
                )
            }
        }
    })

    const onModalWarning = () => {
        modal.open(
            <ModalWarning onClick={() => modal.close()}>
                <LabelNormal color="#060809" fontSize="22" style={{marginBottom: 14}}>Selecciona otra tarjeta</LabelNormal>
                <LabelNormal color="#4F4F4F" fontSize="17" style={{marginBottom: 14}}>Agrega o selecciona otra tarjeta para definirla como mètodo de pago predeterminado.</LabelNormal>
            </ModalWarning>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="perfil" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title style={{marginBottom: 39}}>Tarjetas guardadas</Title>
                                <LabelNormal color="#454D58" fontSize="22">Puedes seleccionar esta tarjeta como método de pago predeterminado o eliminarla.</LabelNormal>
                            </div>
                            <div className="col-md-9">
                                {details.id &&
                                    <CardInlineContainer style={{borderRadius: 10, minHeight: 71, maxWidth: 376, marginTop: 17, marginBottom: 18, padding: 16}} className={details.default ? 'gradiant-border' : ''}>
                                        <InlineContainer>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 38, width: 50, background: '#FAFAFB'}}>
                                                <img src={require(`@images/icon-cc/${details.brand}-transparent.png`)} style={{height: 19, width: 30}} />
                                            </div>
                                            <LabelNormal color="#3C4549" fontSize="18">****{details.last_digits}</LabelNormal>
                                        </InlineContainer>
                                    </CardInlineContainer>
                                }
                            </div>
                            <div className="col-md-9">
                                <div className='bootstrap-wrapper'>
                                    <div style={{margin: 0, paddingTop: 28}} className="row">
                                        <Checkbox
                                            checked={details.default ? details.default : false}
                                            icon={<FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />}
                                            borderColor="#C8C8C8"
                                            style={{ overflow: "hidden" }}
                                            size={20}
                                            onChange={(value) => value ? changeDefaultCard({ variables: { user_id: localStorage.getItem('b360-id'), payment_method_ca: details.service_id_ca, payment_method_ss: details.service_id_ss } }) : onModalWarning()}
                                            labelStyle={{ marginLeft: 5, userSelect: "none", fontFamily: 'neue-montreal-regular', color: '#3C4549' }}
                                            label='Definir como tarjeta predeterminada'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    );
}

export default EditCreditCards;