import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { InlineContainer, LabelNormal, Tag, InvoiceCard as Card } from "../shared/Styled";

const InvoiceCard = ({ invoice, downloadZip, current }) => (
    <Card className={ current ? 'current' : ''} style={{ padding: '10px', gap: '0px' }}>
        <InlineContainer style={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} onClick={() => downloadZip(invoice.id)}>
            <LabelNormal color="#000000" fontSize="17" style={{ display: 'flex', alignItems: 'center' }}>
                <FeatherIcon icon="file-minus" size={20} style={{ marginRight: 5 }}/>
                Factura pago { invoice.type }
            </LabelNormal>
            <LabelNormal color="#757575" fontSize="17">{ moment(invoice.created_at).format('DD.MM.YYYY') }</LabelNormal>
        </InlineContainer>
        <InlineContainer style={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', gap: '4px', width: '100%' }} onClick={() => downloadZip(invoice.id)}>
            <LabelNormal color="#757575" fontSize="17" style={{ flexGrow: 1, minWidth: '50px' }}> Folio <b>{invoice.id}</b></LabelNormal>
            <div style={{ flexGrow: 4, minWidth: '200px' }}>
                { invoice.tag ? <Tag>{ invoice.tag }</Tag> : null }
            </div>
            <LabelNormal color="#757575" fontSize="13" style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <FeatherIcon icon="download" size={20} />
            </LabelNormal>
        </InlineContainer>
    </Card>
);

export default InvoiceCard;
