import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Input from '@components/shared/Input';
import Checkbox from 'react-custom-checkbox';
import LinkUnderline from '@components/shared/LinkUnderline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import ButtonReactive from '../../../components/shared/ButtonReactive';
import {
  MUTATION_DELETED_DOCTOR,
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_EDIT_DOCTOR,
  MUTATION_APPROVED_DOCTOR,
  MUTATION_ADD_COMMENT,
} from '@graphql/mutation';
import {
  QUERY_GET_COUNTRIES,
  QUERY_GET_ROLES,
  QUERY_GET_DEPARTAMENT,
  QUERY_GET_DETAILS_DOCTOR,
} from '@graphql/query';
import { modal } from '@components/ModalManagement';
import UnSubscribeModal from '@components/Modal/ModalUnSubscribe';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useParams, useHistory, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {
  loadPhotoProfileGender,
  validateEmail,
  validatePhone,
} from '../../../utils';
import axios from 'axios';
import InternalNotesCard from '@components/shared/InternalNotesCard';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const InlineContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const NoteInput = styled.textarea`
  font-size: 17px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 20px;
  width: 100%;
  height: 47px;
  padding: 12px 17px 5px 17px;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border: 1px solid rgba(189, 189, 189, 0.7);
  border-radius: 16px;
  resize: none;
  text-align: justify;
  &:focus {
    outline: none;
    border: 1px solid #d97617;
  }
  &:hover {
    outline: none;
    border: 1px solid #d97617;
  }
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  min-width: 198px;
  padding: 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const CategoryCard = styled.div`
  background: ${({ background }) => (background ? background : '#828282')};
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ScrollHorizontal = styled.div`
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const ProfileDoctor = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [countryName, setCountryName] = useState('');
  const [treatment, setTreatment] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [asesor, setAsesor] = useState(false);
  const [category, setCategory] = useState('');
  const [idDoctor, setIdDoctor] = useState('');
  const [idAsesor, setIdAsesor] = useState('');
  const [premium, setPremium] = useState(false);
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState({});
  const [Id, setId] = useState('');
  const [timezone, setTimezone] = useState('');
  const [timezone_name, setTimezoneName] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicPhone, setClinicPhone] = useState('');
  const [street, setStreet] = useState('');
  const [exterior, setExterior] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [locality, setLocality] = useState('');
  const [comments, setComments] = useState([]);

  const [newComment, setNewComment] = useState('');
  const [readMore, setReadMore] = useState(false);

  const [status, setStatus] = useState(null);
  const [enabledAt, setEnabledAt] = useState('');
  const [professionalLicense, setProfessionalLicense] = useState('');

  const [crystAlignerTotal, setCrystAlignerTotal] = useState('');
  const [crystAlignerActive, setCrystAlignerActive] = useState('');

  const [smileSystemTotal, setSmileSystemTotal] = useState('');
  const [smileSystemActive, setSmileSystemActive] = useState('');

  const [taxDatas, setTaxDatas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [clinic, setClinic] = useState(null);

  const [loader, setLoader] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [downloadError, setDownloadError] = useState(false);
  const [commentSelected, setCommentSelected] = useState(null);

  const [getDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;

        setStatus(doctor.status);

        setTaxDatas(doctor.taxdatas);
        setAddresses(doctor.addresses);
        setCreditCards(doctor.cards);

        setId(doctor.user.id);
        setFirstName(doctor.user.first_name);
        setLastName(doctor.user.last_name);
        setEmail(doctor.user.email);
        setPhone(doctor.user.phone);
        setGender(doctor.user.gender);
        setBirthdate(doctor.birthdate);

        setDay(doctor.birthdate?.split('-')[2]);
        setMonth(doctor.birthdate?.split('-')[1]);
        setYear(doctor.birthdate?.split('-')[0]);

        setCountry(doctor.user.country.id);
        setCountryName(doctor.user.country.name);
        setPhoto(doctor.user.photo_filename);
        setCategory(doctor.category);
        setEnabledAt(doctor.enabled_at);
        setClinic(doctor.clinic);

        setTreatment(
          doctor.cryst_aligner && doctor.smile_system
            ? 'Ambos'
            : doctor.cryst_aligner
            ? 'Cryst Aligner'
            : doctor.smile_system
            ? 'Smile System'
            : ''
        );

        setTimezone(doctor.timezone);
        setTimezoneName(doctor.timezone_name);
        setIdDoctor(doctor.id_doctor);
        setAsesor(doctor.id_adviser !== '' ? true : false);
        setIdAsesor(doctor.id_adviser);
        setPremium(doctor.is_doctor);
        setProfessionalLicense(
          doctor.professional_license ? doctor.professional_license : ''
        );
        setClinicName(doctor.clinic?.name);
        setClinicPhone(doctor.clinic?.phone);
        setStreet(doctor.clinic?.street);
        setExterior(doctor.clinic?.number);
        setCodePostal(doctor.clinic?.postal_code);
        setCity(doctor.clinic?.suburb);
        setProvince(doctor.clinic?.state);
        setLocality(doctor.clinic?.locality);

        setCrystAlignerTotal(doctor.count_treatments_cryst_aligner);
        setCrystAlignerActive(doctor.active_treatments_cryst_aligner.length);

        setSmileSystemTotal(doctor.count_treatments_smile_system);
        setSmileSystemActive(doctor.active_treatments_smile_system.length);
        setComments(doctor.comments);
      }
    },
    onError: (err) => console.log(err),
  });

  const [unsubscribeDoctor] = useMutation(MUTATION_DELETED_DOCTOR, {
    onCompleted: (data) => {
      if (data.deleteDoctor) {
        modal.close();
        getDetails({ variables: { id } });
      }
    },
    onError: (err) => console.log(err),
  });

  const [updateDoctor] = useMutation(MUTATION_EDIT_DOCTOR, {
    onCompleted: (data) => {
      if (data.updateDoctor) {
        if (file.name) onSubmitFile(data.updateDoctor.id);
        else {
          getDetails({ variables: { id } });
          setEdit(false);
        }
      }
    },
  });

  const [addComment] = useMutation(MUTATION_ADD_COMMENT, {
    onCompleted: (data) => {
      setNewComment('');
      modal.open(
        <ModalSuccess
          onClick={() => modal.close(() => getDetails({ variables: { id } }))}
        >
          <LabelNormal
            fontSize="22"
            color="#060809"
            style={{ paddingTop: 26, fontWeight: 500, margin: 0 }}
          >
            Comentario añadido
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15, margin: 0 }}
          >
            Se ha agregado el comentario a notas internas
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onSubmitUpdateDoctor = () => {
    setDownloadError(false);
    updateDoctor({
      variables: {
        user_id: +Id,
        first_name: firstName,
        last_name: lastName,
        phone,
        //email,
        gender,
        birthdate: `${year}-${month}-${day}`,
        country_id: +country,
        clinic_name: clinicName,
        clinic_phone: clinicPhone,
        cryst_aligner:
          treatment === 'Cryst Aligner' || treatment === 'Ambos' ? true : false,
        smile_system:
          treatment === 'Smile System' || treatment === 'Ambos' ? true : false,
        street,
        number: exterior,
        suburb: city,
        locality: locality,
        state: province,
        postal_code: codePostal,
        timezone: timezone,
        timezone_name: timezone_name,
        id_doctor: idDoctor,
        id_adviser: idAsesor,
        has_adviser: asesor,
        is_doctor: premium,
        category: category,
        status: 'approved',
        professional_license: professionalLicense,
      },
    });
  };

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        setFile({});
        getDetails({ variables: { id } });
        setEdit(false);
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitFile = async (id) => {
    addProfilePhoto({ variables: { file: file, user_id: +id } });
  };

  useEffect(() => {
    getDetails({ variables: { id } });
    fetchSurgeries();
  }, []);

  useEffect(() => {
    validationForms();
  }, [
    firstName,
    lastName,
    gender,
    phone,
    email,
    treatment,
    idDoctor,
    category,
  ]);

  useEffect(() => {
    setReadMore(!readMore);
  }, [commentSelected]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (idDoctor === '') {
      setDisabled(true);
    } else if (category === '') {
      setDisabled(true);
    } else if (gender === '') {
      setDisabled(true);
    } else if (phone === '' || !validatePhone(phone)) {
      setDisabled(true);
    } else if (email === '' || !validateEmail(email)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleOpenModal = () => {
    modal.open(
      <UnSubscribeModal>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          El perfil se moverá a Doctores desactivados
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Puedes consultar a los Doctores desativados al filtrar por doctores en
          Usuarios.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() => unsubscribeDoctor({ variables: { doctor_id: id } })}
          label="Desactivar doctor"
        />
      </UnSubscribeModal>
    );
  };

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setLoader(true);
      onUploadPhoto(prefile);
      setDownloadError(false);
    }
    evt.target.value = '';
  };

  const onUploadPhoto = async (e) => {
    await uploadPhotoAxios(e);
  };

  const uploadPhotoAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query:
          'mutation addProfilePhoto($file: Upload!, $user_id: Int!) {addProfilePhoto(input: { user_id: $user_id, file: $file }) {id}}',
        variables: {
          file: 'file',
          user_id: +Id,
        },
      })
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addProfilePhoto) {
          setPhoto(URL.createObjectURL(file));
          setProgressUpload(0);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        setDownloadError(true);
      });
  };

  const [reactiveDoctor] = useMutation(MUTATION_APPROVED_DOCTOR, {
    onCompleted: (data) => {
      if (data.approveDoctor) {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => getDetails({ variables: { id } }))}
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Perfil de doctor reactivado
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const parentScroll = useRef(null);
  const [scrollY, setScrollY] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 220 && parentScroll.current.clientHeight >= 1024) {
        setScrollY('fixed-column-25-2');
      } else {
        setScrollY('');
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [window.scroll]);

  const getInstance = () => {
    const token = localStorage.getItem('token-b360');
    return axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchSurgeries = async () => {
    const instance = getInstance();
    const response = await instance.get(`/api/surgery/list/${id}`);
    const data = await response.data.surgeries;
    setSurgeries(data);
  };

  const navigateBack = () => {
    setDownloadError(false);
    setEdit(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="doctores" />
      <ContainHome>
        {status === 'disabled' && (
          <div
            style={{
              background: 'rgba(229, 229, 229, 0.4)',
              top: 0,
              bottom: 0,
              position: 'fixed',
              zIndex: 9,
              width: '100%',
            }}
          />
        )}
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => {
                edit ? navigateBack() : history.goBack();
              }}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>{edit ? 'Perfil' : 'Doctor'}</Title>
                {edit && (
                  <>
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginBottom: 39 }}
                    >
                      Consulta o edita los datos de contacto y registro.
                    </LabelNormal>
                    <LabelNormal
                      color="#454D58"
                      fontSize="22"
                      style={{ marginBottom: 30 }}
                    >
                      Editar datos de perfil
                    </LabelNormal>
                  </>
                )}
                {!edit && (
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.push('/app/doctores')}
                      mainLabel={'Doctores'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-7">
                <InlineContainer>
                  <div>
                    <Avatar
                      src={
                        photo
                          ? photo.includes('http')
                            ? photo
                            : `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${photo}`
                          : loadPhotoProfileGender(gender)
                      }
                      floatButton={edit}
                      onChange={onChange}
                      height="161"
                      width="161"
                      borderRadius="30"
                      fadeOut={progressUpload === 100 ? true : false}
                      loader={progressUpload}
                      error={downloadError}
                      state={loader}
                    />
                    {!edit && (
                      <>
                        <InlineContainer style={{ justifyContent: 'center' }}>
                          <LabelNormal color="#4F4F4F" fontSize="20">
                            ID
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="20">
                            {idDoctor}
                          </LabelNormal>
                        </InlineContainer>
                        <LabelNormal
                          color="#BDBDBD"
                          fontSize="17"
                          style={{ textAlign: 'center' }}
                        >
                          Desde: {moment(enabledAt).format('DD/MM/YYYY')}
                        </LabelNormal>
                      </>
                    )}
                  </div>
                  {!edit && (
                    <div style={{ marginLeft: 35 }}>
                      <InlineContainer
                        style={{ alignItems: 'center', marginBottom: 10 }}
                      >
                        <LabelNormal
                          color="#436FF1"
                          fontSize="21"
                          style={{ marginRight: 7 }}
                        >
                          Dr.
                        </LabelNormal>
                        <LabelNormal
                          fontSize="22"
                          color="#3C4549"
                        >{`${firstName} ${lastName}`}</LabelNormal>
                        <div
                          style={{ cursor: 'pointer', marginLeft: 20 }}
                          onClick={() => setEdit(true)}
                        >
                          <FeatherIcon icon="edit" size="19" />
                        </div>
                      </InlineContainer>
                      <InlineContainer>
                        <div className="row">
                          <div className="col-md-4">
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="user" size="16" />
                              <LabelNormal fontSize="14" color="#3C4549">
                                {gender}
                              </LabelNormal>
                            </InlineContainer>
                          </div>
                          <div className="col-md-7">
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="phone" size="16" />
                              <LabelNormal fontSize="14" color="#3C4549">
                                {phone}
                              </LabelNormal>
                            </InlineContainer>
                          </div>
                          <div className="col-md-4">
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="gift" size="16" />
                              <LabelNormal fontSize="14" color="#3C4549">
                                {`${day}/${month}/${year}`}
                              </LabelNormal>
                            </InlineContainer>
                          </div>
                          <div className="col-md-7">
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="mail" size="16" />
                              <LabelNormal fontSize="14" color="#3C4549">
                                {email}
                              </LabelNormal>
                            </InlineContainer>
                          </div>
                          <div className="col-md-4">
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="map-pin" size="16" />
                              <LabelNormal fontSize="14" color="#3C4549">
                                {countryName}
                              </LabelNormal>
                            </InlineContainer>
                          </div>
                          {premium && (
                            <div className="col-md-4">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <FeatherIcon icon="star" size="16" />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  Premium
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                          )}
                          {idAsesor && (
                            <div
                              className="col-md-12"
                              style={{ marginTop: 13.5 }}
                            >
                              <LabelNormal color="#BDBDBD" fontSize="17">
                                ID asesor: {idAsesor}
                              </LabelNormal>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </InlineContainer>
                {!edit && (
                  <div
                    className="bootstrap-wrapper"
                    style={{ marginTop: 66 }}
                    ref={parentScroll}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <LabelNormal color="#454D58" fontSize="22">
                          Consultorios Red B360
                        </LabelNormal>

                        <ScrollHorizontal>
                          {!clinic && (
                            <LabelNormal
                              color="#BDBDBD"
                              fontSize="17"
                              style={{ marginTop: 17, marginBottom: 17 }}
                            >
                              Sin consultorios registrados
                            </LabelNormal>
                          )}
                          {clinic &&
                            surgeries.map((surgery) => {
                              return (
                                <CardInlineContainer
                                  style={{
                                    borderRadius: 10,
                                    maxWidth: 334,
                                    marginTop: 17,
                                    marginBottom: 18,
                                    padding: 16,
                                  }}
                                  className={
                                    surgery.default ? 'gradiant-border' : ''
                                  }
                                  key={Math.random()}
                                >
                                  <LabelNormal
                                    color="#3C4549"
                                    fontSize="18"
                                    style={{ marginBottom: 10 }}
                                  >
                                    {surgery.name}
                                  </LabelNormal>
                                  <LabelNormal
                                    color="#757575"
                                    fontSize="16"
                                    style={{ marginBottom: 10 }}
                                  >
                                    {surgery.rfc}
                                  </LabelNormal>
                                  <LabelNormal
                                    color="#757575"
                                    fontSize="16"
                                    style={{ marginBottom: 10, width: 302 }}
                                  >{`${surgery.street} ${surgery.number}, ${surgery.suburb}, ${surgery.postal_code} ${surgery.state}`}</LabelNormal>
                                </CardInlineContainer>
                              );
                            })}
                        </ScrollHorizontal>
                      </div>

                      <div className="col-md-12">
                        <LabelNormal color="#454D58" fontSize="22">
                          Perfiles fiscales
                        </LabelNormal>
                        {!taxDatas.length && (
                          <LabelNormal
                            color="#BDBDBD"
                            fontSize="17"
                            style={{ marginTop: 17, marginBottom: 17 }}
                          >
                            Sin perfiles fiscales registrados
                          </LabelNormal>
                        )}
                        {taxDatas.length > 0 && (
                          <ScrollHorizontal>
                            {taxDatas.map((row) => (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 204,
                                  maxWidth: 334,
                                  marginTop: 17,
                                  marginBottom: 18,
                                  padding: 16,
                                }}
                                className={row.default ? 'gradiant-border' : ''}
                                key={Math.random()}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {row.business_name}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {row.rfc}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >{`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}</LabelNormal>
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {row.cfdiUsage.name}
                                </LabelNormal>
                              </CardInlineContainer>
                            ))}
                          </ScrollHorizontal>
                        )}
                      </div>
                      <div className="col-md-12">
                        <LabelNormal color="#454D58" fontSize="22">
                          Direcciones de envío
                        </LabelNormal>
                        {!addresses.length && (
                          <LabelNormal
                            color="#BDBDBD"
                            fontSize="17"
                            style={{ marginTop: 17, marginBottom: 17 }}
                          >
                            Sin direcciones de envío registradas
                          </LabelNormal>
                        )}
                        {addresses.length > 0 && (
                          <ScrollHorizontal>
                            {addresses.map((row) => (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 133,
                                  maxWidth: 334,
                                  marginTop: 17,
                                  marginBottom: 18,
                                  padding: 16,
                                }}
                                className={row.default ? 'gradiant-border' : ''}
                                key={Math.random()}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {row.name}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >{`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}</LabelNormal>
                              </CardInlineContainer>
                            ))}
                          </ScrollHorizontal>
                        )}
                      </div>
                      <div className="col-md-12">
                        <LabelNormal color="#454D58" fontSize="22">
                          Tarjetas guardadas
                        </LabelNormal>
                        {!creditCards.length && (
                          <LabelNormal
                            color="#BDBDBD"
                            fontSize="17"
                            style={{ marginTop: 17, marginBottom: 17 }}
                          >
                            Sin tarjetas registradas
                          </LabelNormal>
                        )}
                        {creditCards.length > 0 && (
                          <ScrollHorizontal>
                            {creditCards.map((row) => (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 71,
                                  maxWidth: 376,
                                  marginTop: 17,
                                  marginBottom: 18,
                                  padding: 16,
                                }}
                                className={row.default ? 'gradiant-border' : ''}
                                key={Math.random()}
                              >
                                <InlineContainer>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: 38,
                                      width: 50,
                                      background: '#FAFAFB',
                                    }}
                                  >
                                    <img
                                      src={require(`@images/icon-cc/${row.brand}-transparent.png`)}
                                      style={{ height: 19, width: 30 }}
                                    />
                                  </div>
                                  <LabelNormal color="#3C4549" fontSize="18">
                                    ****{row.last_digits}
                                  </LabelNormal>
                                </InlineContainer>
                                <LinkUnderline
                                  label={'Ver tarjeta'}
                                  link={`/doctor/editar-tarjeta-credito`}
                                  card={{ ...row }}
                                />
                              </CardInlineContainer>
                            ))}
                          </ScrollHorizontal>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`col-md-4 ${scrollY}`}>
                {!edit && (
                  <div className="row">
                    <div className="col-md-12">
                      <HeaderCard>
                        <LabelNormal color="#3C4549" fontSize="17">
                          Resumen
                        </LabelNormal>
                      </HeaderCard>
                      <CardContainer>
                        <LabelNormal
                          color="#828282"
                          fontSize="16"
                          style={{ marginBottom: 10 }}
                        >
                          Categorías
                        </LabelNormal>
                        <CategoryCard>
                          <LabelNormal color="#FFF" fontSize="17">
                            {category}
                          </LabelNormal>
                        </CategoryCard>
                        {treatment === 'Cryst Aligner' ||
                          (treatment === 'Ambos' && (
                            <>
                              <DecoratorBlue style={{ marginTop: 30 }}>
                                <LabelNormal color="#FFF" fontSize="17">
                                  Cryst Aligner
                                </LabelNormal>
                              </DecoratorBlue>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <InlineContainer
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <LabelNormal
                                    color="#BDBDBD"
                                    style={{ textTransform: 'uppercase' }}
                                    fontSize="13"
                                  >
                                    Activos
                                  </LabelNormal>
                                  <LabelNormal color="#757575" fontSize="17">
                                    {crystAlignerActive.toString()}
                                  </LabelNormal>
                                </InlineContainer>
                                <InlineContainer
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <LabelNormal
                                    color="#BDBDBD"
                                    style={{ textTransform: 'uppercase' }}
                                    fontSize="13"
                                  >
                                    Totales
                                  </LabelNormal>
                                  <LabelNormal color="#757575" fontSize="17">
                                    {crystAlignerTotal.toString()}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            </>
                          ))}
                        {treatment === 'Smile System' ||
                          (treatment === 'Ambos' && (
                            <>
                              <DecoratorYellow>
                                <LabelNormal color="#FFF" fontSize="17">
                                  Smile System
                                </LabelNormal>
                              </DecoratorYellow>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <InlineContainer
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <LabelNormal
                                    color="#BDBDBD"
                                    style={{ textTransform: 'uppercase' }}
                                    fontSize="13"
                                  >
                                    Activos
                                  </LabelNormal>
                                  <LabelNormal color="#757575" fontSize="17">
                                    {smileSystemActive.toString()}
                                  </LabelNormal>
                                </InlineContainer>
                                <InlineContainer
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <LabelNormal
                                    color="#BDBDBD"
                                    style={{ textTransform: 'uppercase' }}
                                    fontSize="13"
                                  >
                                    Totales
                                  </LabelNormal>
                                  <LabelNormal color="#757575" fontSize="17">
                                    {smileSystemTotal.toString()}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            </>
                          ))}
                        <div style={{ marginTop: 8, marginBottom: 30 }}>
                          <Link
                            to={`/app/doctores/casos-doctor/${id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <FeatherIcon icon="user" color="#000" size="16" />
                              <LabelNormal color="#3C4549" fontSize="20">
                                Ver casos
                              </LabelNormal>
                            </InlineContainer>
                          </Link>
                        </div>
                        {status === 'disabled' && (
                          <ButtonReactive
                            label={'Reactivar perfil'}
                            onClick={() =>
                              reactiveDoctor({ variables: { doctor_id: +id } })
                            }
                          />
                        )}
                      </CardContainer>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ marginTop: 43, marginBottom: 43 }}
                    >
                      <InternalNotesCard
                        comments={comments}
                        doctor_id={id}
                        addComment={addComment}
                      />
                    </div>
                    {status === 'approved' && (
                      <div className="col-md-12">
                        <CardContainer
                          style={{ cursor: 'pointer', borderRadius: 30 }}
                          onClick={() => handleOpenModal()}
                        >
                          <InlineContainer
                            style={{ alignItems: 'center', paddingLeft: 20 }}
                          >
                            <FeatherIcon icon="user-minus" size="19" />
                            <LabelNormal
                              style={{ marginLeft: 20 }}
                              fontSize="17"
                              color="#3C4549"
                            >
                              Dar de baja a doctor
                            </LabelNormal>
                          </InlineContainer>
                        </CardContainer>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-7">
                {edit && (
                  <>
                    <div className="bootstrap-wrapper">
                      <div style={{ marginTop: 38 }} className="row">
                        <div className="col-md-5">
                          <Input
                            placeholder="ID"
                            onChange={(e) => setIdDoctor(e.target.value)}
                            value={idDoctor}
                            label="ID de doctor"
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: 38 }}>
                        <LabelInput>Categoría</LabelInput>
                        <InlineContainer>
                          <div className="radio" style={{ marginRight: 20 }}>
                            <input
                              id="category-radio-1"
                              name="category"
                              onClick={() => setCategory('Cadenas')}
                              checked={
                                category === 'Cadenas' ? 'checked' : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor="category-radio-1"
                              className="radio-label"
                            >
                              Cadenas
                            </label>
                          </div>
                          <div className="radio" style={{ marginRight: 20 }}>
                            <input
                              id="category-radio-2"
                              name="category"
                              onClick={() => setCategory('Menudeo')}
                              checked={
                                category === 'Menudeo' ? 'checked' : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor="category-radio-2"
                              className="radio-label"
                            >
                              Menudeo
                            </label>
                          </div>
                        </InlineContainer>
                      </div>
                      <div style={{ marginTop: 38 }} className="grid-layout">
                        <InlineContainer>
                          <div>
                            <Input
                              placeholder="Nombre"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              label="Nombres y apellidos"
                              required
                            />
                          </div>
                          <div>
                            <span
                              style={{ fontSize: 18, visibility: 'hidden' }}
                            >
                              none
                            </span>
                            <Input
                              placeholder="Apellidos"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </InlineContainer>
                      </div>
                      <div>
                        <LabelInput>Género</LabelInput>
                        <InlineContainer>
                          <div className="radio" style={{ marginRight: 20 }}>
                            <input
                              id="radio-1"
                              onClick={(e) => setGender('Masculino')}
                              checked={
                                gender === 'Masculino' ? 'checked' : false
                              }
                              name="gender"
                              type="radio"
                            />
                            <label htmlFor="radio-1" className="radio-label">
                              Masculino
                            </label>
                          </div>
                          <div className="radio">
                            <input
                              id="radio-2"
                              onClick={(e) => setGender('Femenino')}
                              checked={
                                gender === 'Femenino' ? 'checked' : false
                              }
                              name="gender"
                              type="radio"
                            />
                            <label htmlFor="radio-2" className="radio-label">
                              Femenino
                            </label>
                          </div>
                        </InlineContainer>
                      </div>
                      <div style={{ marginTop: 28 }}>
                        <LabelInput>Fecha de nacimiento</LabelInput>
                        <InlineContainer>
                          <Input
                            placeholder="DD"
                            maxWidth="60px"
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                          />
                          <Input
                            placeholder="MM"
                            maxWidth="60px"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                          />
                          <Input
                            placeholder="AAAA"
                            maxWidth="90px"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                          />
                        </InlineContainer>
                      </div>
                      <div style={{ marginTop: 28 }}>
                        <LabelInput>Teléfono</LabelInput>
                        <InlineContainer>
                          <Input
                            readOnly
                            disabled
                            value="+52"
                            backgroundColor="#F6F6F8"
                            maxWidth="70px"
                          />
                          <Input
                            placeholder="Teléfono"
                            mask="phone"
                            type="phone"
                            validation="phone"
                            value={phone}
                            onChange={setPhone}
                          />
                        </InlineContainer>
                      </div>
                      <div style={{ marginTop: 28 }} className="row">
                        <div className="col-md-5">
                          <Input
                            placeholder="Tu correo"
                            label="Correo"
                            mask="email"
                            type="email"
                            validation="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: 28 }}>
                        <LabelInput>Tratamiento/s</LabelInput>
                        <InlineContainer>
                          <div className="radio" style={{ marginRight: 20 }}>
                            <input
                              id="treatment-1"
                              name="treatment"
                              onClick={() => setTreatment('Cryst Aligner')}
                              checked={
                                treatment === 'Cryst Aligner'
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor="treatment-1"
                              className="radio-label"
                            >
                              Cryst Aligner
                            </label>
                          </div>
                          <div className="radio" style={{ marginRight: 20 }}>
                            <input
                              id="treatment-2"
                              name="treatment"
                              onClick={() => setTreatment('Smile System')}
                              checked={
                                treatment === 'Smile System' ? 'checked' : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor="treatment-2"
                              className="radio-label"
                            >
                              Smile System
                            </label>
                          </div>
                          <div className="radio">
                            <input
                              id="treatment-3"
                              name="treatment"
                              onClick={() => setTreatment('Ambos')}
                              checked={
                                treatment === 'Ambos' ? 'checked' : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor="treatment-3"
                              className="radio-label"
                            >
                              Ambos tratamientos
                            </label>
                          </div>
                        </InlineContainer>
                      </div>
                      <div
                        style={{ margin: 0, paddingTop: 28 }}
                        className="row"
                      >
                        <Checkbox
                          checked={asesor}
                          icon={
                            <FontAwesomeIcon
                              icon={faCheck}
                              color="#EB9542"
                              size="sm"
                            />
                          }
                          borderColor="#C8C8C8"
                          style={{ overflow: 'hidden' }}
                          size={20}
                          onChange={(value) => setAsesor(value)}
                          labelStyle={{
                            marginLeft: 5,
                            userSelect: 'none',
                            fontFamily: 'neue-montreal-regular',
                            color: '#3C4549',
                          }}
                          label="Tiene asesor"
                        />
                      </div>
                      {asesor && (
                        <div style={{ marginTop: 10 }} className="row">
                          <div className="col-md-5">
                            <Input
                              placeholder="ID"
                              label="ID de asesor"
                              value={idAsesor}
                              onChange={(e) => setIdAsesor(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                      <div style={{ marginTop: 38, width: '40%' }}>
                        <div className="row">
                          <div className="col-md-12">
                            <Checkbox
                              checked={premium}
                              icon={
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#EB9542"
                                  size="sm"
                                />
                              }
                              borderColor="#C8C8C8"
                              style={{ overflow: 'hidden' }}
                              size={20}
                              onChange={(value) => setPremium(value)}
                              labelStyle={{
                                marginLeft: 5,
                                userSelect: 'none',
                                fontFamily: 'neue-montreal-regular',
                                color: '#3C4549',
                              }}
                              label={'Es doctor premium'}
                            />
                            <LabelMute>
                              *Seleccionar esta casilla le permite al doctor no
                              pagar la planificación en sus tratamientos de CA.
                            </LabelMute>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: 31, marginBottom: 41 }}>
                        <ButtonPrime
                          disabled={disabled}
                          label="Guardar cambios"
                          onClick={() => onSubmitUpdateDoctor()}
                        ></ButtonPrime>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ProfileDoctor;
