import { Controller } from "react-hook-form"
import { Container, ErrorMessage, InlineContainer, InputComponent, LabelInput, LabelMuted } from "../Styled"

const ZipCodeInput = ({ label, muted, name, control, rules, errors, clearErrors, setValue, parentProps, ...props}) => {
    const maskNumberCP = (event) => {
        let val = event.target.value
        if (val.length < 6) {
          val = val.replace(/[^0-9]/g, '')
          setValue(name, val)
        }
    };
    return (
        <Container {...parentProps}>
            {props.label && <LabelInput>{props.label}</LabelInput>}
            {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <InputComponent
                        {...field}
                        {...props}
                        onFocus={() => clearErrors && clearErrors(name)}
                        onChange={maskNumberCP}
                        borderColor={ errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)' }
                    />
                )}
            />
            <InlineContainer>
                <ErrorMessage>{ errors && errors[name] && errors[name]?.message }</ErrorMessage>
            </InlineContainer>
        </Container>
    )
}

export default ZipCodeInput