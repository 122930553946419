import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NativeClickListener from './NativeClickListener';
import { ErrorMessage, InlineContainer, LabelInput } from './Styled';

const DropDownContainer = styled('div')`
  position: relative;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '310px')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '310px')};
  font-family: neue-montreal-regular;
`;

const DropDownHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 500;
  font-size: 17px;
  color: #828282;
  background: #ffffff;
  border: ${({ validate }) =>
    validate
      ? '1px solid #BDBDBDB2'
      : validate === false
      ? '1px solid #D11105'
      : '1px solid #BDBDBDB2'};
  border-radius: 16px;
  width: 100%;
  margin: 3px 0px;
  padding: 15px;
  cursor: pointer;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '45px')};
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 999;
  min-width: 100%;
  border: 1px solid #bdbdbdb2;
  border-radius: 16px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '300px')};
  overflow-y: scroll;
`;

const DropDownList = styled('ul')`
  margin: 0;
  background: #ffffff;
  box-sizing: border-box;
  color: #3c4549;
  font-size: 17px;
  padding: 5px;
  border-radius: 16px;
`;

const ListItem = styled('li')`
  list-style: none;
  padding: 0 2em 0 1em;
  border: none;
  margin-bottom: 10px;
  &:hover {
    background: transparent;
  }
  cursor: pointer;
`;

const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState(null);
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if (props.value) {
      if (props.options.length > 0) {
        const option = props.options.find(
          (op) => parseInt(op.id) === parseInt(props.value)
        );
        if (option) setSelect(option.name);
      } else {
        setSelect(null);
      }
    } else {
      setSelect(null);
    }
  }, [props.value, props.options]);

  const toggling = () => {
    if (!isOpen && props.clearErrors) props.clearErrors();
    setIsOpen(!isOpen);
  };

  const handlerClick = (option) => {
    props.onSelect(option.id);
    toggling();
    setSelect(option.name);
    setIcon(option.icon);
  };

  return (
    <>
      {props.label && (
        <LabelInput style={{ marginBottom: 3 }}>{props.label}</LabelInput>
      )}
      <NativeClickListener
        onClick={() => {
          setIsOpen(false);
          if (props.onBlur) props.onBlur();
        }}
      >
        <DropDownContainer minWidth={props.minWidth} maxWidth={props.maxWidth}>
          <DropDownHeader
            onClick={() => {
              toggling();
              if (props.onBlur) props.onBlur();
            }}
            style={{
              color: select ? '#3C4549' : '#828282',
              borderColor:
                props.errors && props.name && props.errors[props.name]
                  ? '#C60E02'
                  : 'rgba(189, 189, 189, 0.698)',
            }}
            maxHeight={props.maxHeight}
            validate={props.validate}
          >
            {props.icon && (
              <img src={icon || props.iconDefault} alt="option-icon" />
            )}
            {select || props.placeholder}
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  {props.options &&
                    props.options.map((option) => (
                      <ListItem
                        onClick={() => handlerClick(option)}
                        key={Math.random()}
                        style={{
                          ...props.style,
                          background:
                            select === option.name ? '#F6F6F8' : 'transparent',
                        }}
                      >
                        {props.icon && (
                          <img src={option.icon} alt="option-icon" />
                        )}
                        {option.name}
                      </ListItem>
                    ))}
                </div>
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </NativeClickListener>
      {!props.hideEmptyError && (
        <InlineContainer>
          <ErrorMessage>
            {props.errors &&
              props.name &&
              props.errors[props.name] &&
              props.errors[props.name]?.message}
          </ErrorMessage>
        </InlineContainer>
      )}
    </>
  );
};

export default Select;
