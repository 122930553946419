import styled from 'styled-components';
import MapBrackets from '@components/DoctorFormSS/map';
import HistoryChat from '@components/shared/HistoryChat';
import ButtonFile from "@components/shared/ButtonFile";
import Odontogram from './odontogram';

const ResumeSS = ({ formSSData, chatMessages, review = false, formStatus, odontogramData, bracketsData }) => {
  return (
    <>
      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 20 }} bold>
        Brackets
      </LabelNormal>
      <LabelNormal style={{ marginTop: 20 }} fontSize="17" color="#757575">
        {formSSData.bracket_ss.split('Fit Jig + Brackets ')[1]}
      </LabelNormal>
      { review === true && formStatus === 'Rechazado' && (
        <div
          style={{
            background: '#C60E02',
            width: 188,
            height: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 19,
            position: 'absolute',
            top: '18%',
            right: '30%',
            left: '35%',
            zIndex: 9,
            filter: 'opacity(0.8)'
          }}
        >
          <LabelNormal fontSize="30" color="#F4F4F4">
            Rechazado
          </LabelNormal>
        </div>
      )}
      { review === true && formStatus === 'Aprobado' && (
        <div
          style={{
            background: '#1DBB24',
            width: 188,
            height: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 19,
            position: 'absolute',
            top: '18%',
            right: '30%',
            left: '35%',
            zIndex: 9,
            filter: 'opacity(0.8)'
          }}
        >
          <LabelNormal fontSize="30" color="#F4F4F4">
            Aceptado
          </LabelNormal>
        </div>
      )}
      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Odontograma
      </LabelNormal>
      {/* <LabelNormal fontSize="17" color="#757575">Pon el cursor sobre la pieza para ver el detalle de las modificaciones realizadas.</LabelNormal> */}
      <div className="row">
        {formSSData.odontograma.type === 'No tengo consideraciones' && (
          <div
            className="col-md-12"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <LabelNormal fontSize="17" color="#757575">
              No existen consideraciones especiales para el tratamiento.
            </LabelNormal>
          </div>
        )}

        { review === true && formSSData.odontograma !== 'No tengo consideraciones' && (
            <div
              className="col-md-12"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <ButtonFile
                onClick={() => odontogramData()}
                icon={'move'}
                size={35}
              />
            </div>
        )}
      </div>

      <div>
        {formSSData.up_odontograma &&
          formSSData.lo_odontograma &&
          formSSData.odontograma.type !== 'No tengo consideraciones' && (
            <Odontogram up_odontograma={formSSData.up_odontograma} lo_odontograma={formSSData.lo_odontograma}/>
          )}
      </div>
      <LabelNormal fontSize="17" color="#3C4549" style={{ marginTop: 20 }} bold>
        Comentarios adicionales
      </LabelNormal>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-12">
          <InlineContainer>
            <LabelNormal fontSize="17" color="#757575">
              {formSSData.odontograma.comments !== ''
                ? formSSData.odontograma.comments
                : 'No hay comentarios'}
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Posición de los brackets
      </LabelNormal>
      <div className="row">
        <div
          className="col-md-6"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LabelNormal fontSize="17" color="#3C4549">
            {formSSData.brackets.type}
          </LabelNormal>
        </div>
        { review === true && (
            <div
            className="col-md-6"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
            {formSSData.brackets.type === 'Personalizar posición de brackets' && (
                <ButtonFile
                onClick={() => bracketsData()}
                icon={'move'}
                size={35}
                />
            )}
            </div>
        )}
      </div>
      {/* <LabelNormal fontSize="17" color="#757575">Para mover la posición del bracket, haz clic sobre una pieza dental y mueve el punto hacia donde lo necesites.<br/> También puedes introducir el valor exacto.</LabelNormal> */}
      <MapBrackets
        disabled={true}
        initialNumerationTopLeft={19}
        initialNumerationTopRight={20}
        initialNumerationBottomRight={20}
        initialNumerationBottomLeft={49}
        width={30}
        height={79.25}
        topData={JSON.parse(formSSData.brackets.up_positions_data)}
        setTopData={() => {}}
        bottomData={JSON.parse(formSSData.brackets.lo_positions_data)}
        setBottomData={() => {}}
        disabledPointTop={formSSData.up_odontograma}
        disabledPointBottom={formSSData.lo_odontograma}
      />

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 0 }} bold>
        Comentarios adicionales (con respecto al tratamiento)
      </LabelNormal>
      <LabelNormal fontSize="17" color="#757575">
        { formSSData.additional !== '' ? formSSData.additional : "No hay comentarios adicionales" }
      </LabelNormal>

      {chatMessages.length > 0 && (
        <>
          <hr />
          <HistoryChat chat={chatMessages} />
        </>
      )}
    </>
  );
};

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export default ResumeSS;
