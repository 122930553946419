import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { MUTATION_RESTORE_CHANNEL_NOTIFICATION } from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';

const MessagesButton = ({
  role,
  patientId,
  treatmentId,
  messageDoctor,
  activeBasicChat,
  pendingMessages,
}) => {
  const [restoreChannelNotification] = useMutation(
    MUTATION_RESTORE_CHANNEL_NOTIFICATION
  );

  return (
    <Link
      to={{
        pathname: `/${
          role === 'teacher' ? 'profesor' : 'doctor'
        }/caso/mensajes-borgatta/${patientId}`,
        state: { treatmentId },
      }}
      onClick={() => {
        if (activeBasicChat && pendingMessages)
          restoreChannelNotification({ variables: { patient_id: patientId } });
      }}
    >
      <ButtonPrime
        style={{ padding: '9px 11px' }}
        fontSize={15}
        label="Contestar mensajes"
      />
    </Link>
  );
};

export default MessagesButton;
