import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Select from '@components/shared/Select';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_REGISTER_DOCTOR,
  MUTATION_ADD_MEMBER_PHOTO,
} from '@graphql/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_GET_COUNTRIES } from '@graphql/query';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
  validateEmail,
  validatePhone
} from '../../utils';
import moment from 'moment';
import B360NetworkDoctorOfficeForm from '../../components/Doctor/B360NetworkDoctorOfficeForm';
import { useForm } from 'react-hook-form';
import RegisterForm from '../../components/Doctor/RegisterForm';

const ContainHome = styled.div`
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  padding-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  min-width: 198px;
  padding: 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const FormRegisterDoctor = (props) => {
  const history = useHistory()
  const [errors, setErrors] = useState([])

  const [formValues, setFormValues] = useState({})
  const [formValid, setFormValid] = useState(false)
  const [submit, setSubmit] = useState(0)
  const [validated, setValidated] = useState(0)

  const [registerDoctor] = useMutation(MUTATION_REGISTER_DOCTOR, {
    onCompleted: (data) => {
      if (data.registerDoctor) {
        if (file.name)
          addProfilePhoto({
            variables: { file: file, user_id: +data.registerDoctor.id },
          });
        else {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.push('/auth/inicio-session'))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Solicitud enviada
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14 }}
              >
                Revisaremos tus datos para asegurarnos de que cumples con los
                requisitos necesarios para ser un Dr. B360. Te notificaremos por
                correo el estado de tu solicitud y accesos en caso de ser
                aceptada.{' '}
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
      console.log(data);
    },
    onError: (err) => {
      setErrors(err.graphQLErrors.map((error) => error.message));
      console.log(err);
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => history.push('/auth/inicio-session'))
            }
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Solicitud enviada
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14 }}
            >
              Revisaremos tus datos para asegurarnos de que cumples con los
              requisitos necesarios para ser un Dr. B360. Te notificaremos por
              correo el estado de tu solicitud y accesos en caso de ser
              aceptada.{' '}
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitRequest = () => {
    if (formValid) {
      registerDoctor({
        variables: {
          ...formValues,
          country_id: parseInt(formValues.country_id)
        }
      })
    }
  }

  const [file, setFile] = useState({});

  const [urlPrev, setUrlPrev] = useState('');

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setUrlPrev(URL.createObjectURL(prefile))
    }
  }

  const triggerValidation = () => setSubmit(submit + 1)

  useEffect(() => {
    if (validated > 0 && formValid) onSubmitRequest()
  }, [validated])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name={null} />
      <ContainHome>
        <ContainerRegister className="bootstrap-wrapper">
          <Title>Solicitud de Registro</Title>
          <Subtitle>
            Llena nuestro formulario para registrarte como Dr. B360
          </Subtitle>
          <ContainForm>
            <Avatar
              src={
                urlPrev
                  ? urlPrev
                  : require('@images/people/usuario_masculino.jpg')
              }
              floatButton
              onChange={onChange}
            />
          </ContainForm>
          <RegisterForm parentErrors={errors} setValid={setFormValid} setValues={setFormValues} submit={submit} afterValidate={setValidated}/>
          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Enviar solicitud"
              onClick={() => triggerValidation()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default FormRegisterDoctor;
