import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';

/*Componente boton general, estilos basicos
    Descripcion de props
    --------------------
    label: Texto que se mostrara en el boton,
    action: Acción correspondiente que se ejecutara en el evento onClick del boton, es una funcion que se describe en el componente padre

    Descripcion de state
    --------------------
    btnEnabled: Determina los estilos del boton para habilitarlo segun la funcionalidad del componente padre
*/

export const ButtonStyle = styled.button`
  display: flex;
  gap: 8px;
  min-height: ${({ size }) => (size === 'sm' ? '36px;' : 'auto')};
  min-width: ${({ size }) => (size === 'sm' ? '100px;' : 'auto')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '5')}px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '10')}px;
  padding: ${({ size, padding }) =>
    size === 'sm' ? '9px 15px' : padding ?? '12px 25px 11px'};
  font-size: ${({ size }) => (size === 'sm' ? '15' : size ?? '19')}px;
  line-height: ${({ size }) => (size === 'sm' ? '15' : size ?? '19')}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  color: ${({ color }) => (color ? color : 'white')};
  border: ${({ border }) => (border ? border : '0px')};
  border-radius: 50px;
  width: ${({ width }) => width ?? 'auto'};
  background: ${({ color }) => (color ? 'transparent' : '#EB9542')};
  transition: all 0.3s ease-out;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &:disabled {
    cursor: default;
    background: #c8c8c8;
  }
  &:hover:enabled {
    background: ${({ background, backgroundEnabled }) =>
      backgroundEnabled
        ? backgroundEnabled
        : background
        ? '#3C4549'
        : '#D97617'};
    color: ${({ color, colorEnabled }) =>
      colorEnabled ? colorEnabled : color ? 'white' : 'white'};
  }
  &:active:enabled {
    background: ${({ background, backgroundEnabled }) =>
      backgroundEnabled
        ? backgroundEnabled
        : background
        ? '#4F4F4F'
        : '#AB5D12'};
    color: ${({ color, colorEnabled }) =>
      colorEnabled ? colorEnabled : color ? 'white' : 'white'};
  }
`;

export const ButtonStyleLoading = styled.button`
  display: flex;
  min-height: ${({ size }) => (size === 'sm' ? '36px;' : '49px')};
  min-width: ${({ size }) => (size === 'sm' ? '100px;' : '150px')};
  padding: ${({ size }) => (size === 'sm' ? '9px 15px' : '12px 25px 11px')};
  border-radius: 50px;
  color: ${({ color }) => (color ? color : 'white')};
  border: ${({ border }) => (border ? border : '0px')};
  margin-top: 5px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '19px')};
  background: ${({ color }) =>
    color
      ? 'linear-gradient(to right, #3C4549 50%, #ABB5BA 50%)'
      : 'linear-gradient(to right, #D97617 50%, #EB9542 50%)'};
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
  cursor: pointer;
  align-items: center;
  &:disabled {
    cursor: default;
    background: #c8c8c8;
  }
  &:focus:enabled {
    background-position: left bottom;
  }
  &:hover:enabled {
  }
`;

const ButtonPrime = (props) => {
  return (
    <>
      {props.loading && (
        <ButtonStyleLoading {...props}>
          {props.icon && (
            <FeatherIcon
              icon={props.icon}
              size={props.iconSize ?? props.size}
              color={props.color}
              style={{ marginRight: 10 }}
            />
          )}
          {props.label}
        </ButtonStyleLoading>
      )}
      {!props.loading && (
        <ButtonStyle {...props}>
          {props.icon && (
            <FeatherIcon
              icon={props.icon}
              size={props.iconSize ?? props.size}
              color={props.color}
              marginright={props.marginRight}
            />
          )}
          <span style={{ lineHeight: `${props.size ?? '19'}px` }}>
            {props.label}
          </span>
        </ButtonStyle>
      )}
    </>
  );
};
export default ButtonPrime;
