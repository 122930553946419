import { Alert } from 'antd';
import moment from 'moment';

const AlignersAlert = ({ type, description, aligners, date, ...props }) => {
  const momentDate = moment(date, 'DD-MM-YYYY');

  return (
    <Alert
      {...props}
      className="aligners"
      style={{
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        borderColor: '#9E9E9E',
      }}
      description={
        <div>
          Tu paciente tiene un paquete <span>Terso</span> al que le quedan{' '}
          <span>01</span> refinamiento(s) de los <span>01</span> incluido(s) en
          su paquete. Cuentas con <span>{aligners}</span> alineadores
          disponibles para tu siguiente refinamiento. Este paquete esta vigente
          hasta que <span>{momentDate.format('DD')}</span> de{' '}
          <span>{momentDate.format('MMMM')}</span> del{' '}
          <span>{momentDate.year()}</span>
        </div>
      }
      type={type}
      showIcon
    />
  );
};

export default AlignersAlert;
