import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap-grid-only-css/dist/css/bootstrap-grid.css';
import 'react-loading-skeleton/dist/skeleton.css';
import * as Sentry from '@sentry/react';
import App from './App';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import AuthProvider from './provider/auth';
import ModalComponent from '@components/ModalManagement';
import ModalComponentMessages from '@components/ModalManagementMessages';
import OnBoarding from '@components/OnBoarding';
import { createUploadLink } from 'apollo-upload-client';
import { typeDefs } from './graphql/typedefs';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_URL_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token-b360');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  typeDefs,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://portal.b360.mx'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AuthProvider>
        <ModalComponent />
        <ModalComponentMessages />
        <OnBoarding />
        <App />
      </AuthProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
