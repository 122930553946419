const Error = ({ width, height }) => (
  <svg
    width={width ?? '19'}
    height={height ?? '18'}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12207 2C5.25645 2 2.12207 5.13438 2.12207 9C2.12207 12.8656 5.25645 16 9.12207 16C12.9877 16 16.1221 12.8656 16.1221 9C16.1221 5.13438 12.9877 2 9.12207 2ZM11.7064 11.6594L10.6752 11.6547L9.12207 9.80313L7.57051 11.6531L6.5377 11.6578C6.46895 11.6578 6.4127 11.6031 6.4127 11.5328C6.4127 11.5031 6.42363 11.475 6.44238 11.4516L8.4752 9.02969L6.44238 6.60938C6.4235 6.58647 6.41303 6.5578 6.4127 6.52812C6.4127 6.45937 6.46895 6.40312 6.5377 6.40312L7.57051 6.40781L9.12207 8.25938L10.6736 6.40938L11.7049 6.40469C11.7736 6.40469 11.8299 6.45937 11.8299 6.52969C11.8299 6.55937 11.8189 6.5875 11.8002 6.61094L9.77051 9.03125L11.8018 11.4531C11.8205 11.4766 11.8314 11.5047 11.8314 11.5344C11.8314 11.6031 11.7752 11.6594 11.7064 11.6594Z"
      fill="#E41D11"
    />
  </svg>
);

export default Error;
