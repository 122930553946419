const BarsLeft = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00752 15.4925L2.31564 15.1844C2.39769 15.2664 2.50897 15.3125 2.625 15.3125H11.375C11.491 15.3125 11.6023 15.2664 11.6844 15.1844C11.7664 15.1023 11.8125 14.991 11.8125 14.875C11.8125 14.759 11.7664 14.6477 11.6844 14.5656C11.6023 14.4836 11.491 14.4375 11.375 14.4375H2.625C2.50897 14.4375 2.39769 14.4836 2.31564 14.5656M2.00752 15.4925L2.31564 14.5656M2.00752 15.4925L2.31564 15.1844C2.23359 15.1023 2.1875 14.991 2.1875 14.875C2.1875 14.759 2.23359 14.6477 2.31564 14.5656M2.00752 15.4925L2.00628 14.2563L2.31564 14.5656M2.31564 10.8094L2.01259 11.1124L2.31564 10.8094C2.23359 10.7273 2.1875 10.616 2.1875 10.5C2.1875 10.384 2.23359 10.2727 2.31564 10.1906C2.39769 10.1086 2.50897 10.0625 2.625 10.0625H18.375C18.491 10.0625 18.6023 10.1086 18.6844 10.1906C18.7664 10.2727 18.8125 10.384 18.8125 10.5C18.8125 10.616 18.7664 10.7273 18.6844 10.8094C18.6023 10.8914 18.491 10.9375 18.375 10.9375H2.625C2.50897 10.9375 2.39769 10.8914 2.31564 10.8094ZM18.375 6.5625H2.625C2.50897 6.5625 2.39769 6.51641 2.31564 6.43436C2.23359 6.35231 2.1875 6.24103 2.1875 6.125C2.1875 6.00897 2.23359 5.89769 2.31564 5.81564C2.39769 5.73359 2.50897 5.6875 2.625 5.6875H18.375C18.491 5.6875 18.6023 5.73359 18.6844 5.81564C18.7664 5.89769 18.8125 6.00897 18.8125 6.125C18.8125 6.24103 18.7664 6.35231 18.6844 6.43436C18.6023 6.51641 18.491 6.5625 18.375 6.5625Z"
      stroke="#444E53"
      strokeWidth="0.875"
    />
  </svg>
);

export default BarsLeft;
