/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ButtonPrime from '@components/shared/ButtonPrime';
import styled from 'styled-components';
import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_ADD_TAX_DATA, MUTATION_UPDATE_TAX_DATA } from '@graphql/mutation';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import { modalMessages } from '@components/ModalManagementMessages'
import ButtonClose from '../shared/ButtonClose';
import FiscalForm from '../Doctor/FiscalForm';
import ModalError from './ModalError';

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const requiredFields = ['business_name', 'type', 'rfc', 'tax_system_id', 'cfdi_usage_id', 'street', 'number', 'postal_code', 'suburb']

const ModalBase = (props) => {
    const [disabledCase, setDisabledCase] = useState(true)
    const [country, setCountry] = useState({})
    const [formValid, setFormValid] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const [defaultValuesAddress, setDefaultValuesAddress] = useState(null)
    const [formValues, setFormValues] = useState({})
    const [canResetCfdiUsages, setCanResetCfdiUsages] = useState(false)
    const [submit, setSubmit] = useState(0)

    const onSubmitTaxData = () => {
        setSubmit(submit + 1)
        if (formValid && requiredFields.every((key) => Object.keys(formValues).includes(key))) {
            addTaxData({ variables: formValues })
        }
    }

    const onUpdateTaxData = () => {
        setSubmit(submit + 1)
        if (formValid && requiredFields.every((key) => Object.keys(formValues).includes(key))) {
            updateTaxData({ variables: {
                ...formValues,
                id: props.taxdata.id
            }})
        }
    }

    const [addTaxData] = useMutation(MUTATION_ADD_TAX_DATA, {
        onCompleted: data => {
            if(data.registerTaxData){
                modal.close();
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => props.onFinish())}>
                        <LabelNormal fontSize="22" color="#060809" style={{paddingTop: 30}}>Perfil fiscal agregado</LabelNormal>
                        <LabelNormal fontSize="17" color="#4F4F4F" style={{paddingTop: 14, marginBottom: 40}}>Ahora podrás elegir este perfil para facturar tus pagos.</LabelNormal>
                    </ModalSuccess>
                )
            }
        },
        onError: err => {
            modalMessages.open(
                <ModalError onClick={() => modalMessages.close() }>
                    <LabelNormal
                        color="#060809"
                        fontSize="22"
                        style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
                    >
                        Razón social no registrada
                    </LabelNormal>
                    <LabelNormal
                        color="#4F4F4F"
                        fontSize="17"
                        style={{ marginBottom: 14 }}
                    >
                        Ocurrió un error al registrar la razón social
                    </LabelNormal>
                    <div
                        style={{
                            backgroundColor: '#FFE5E3',
                            padding: '15px',
                            borderRadius: 13,
                            color: '#C60E02',
                        }}
                    >
                        <p style={{ fontWeight: '600' }}>{err?.message}</p>
                    </div>
                </ModalError>
            )
        }
    })

    const [updateTaxData] = useMutation(MUTATION_UPDATE_TAX_DATA, {
        onCompleted: data => {
            if(data.updateTaxData){
                modal.close();
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => props.onFinish())}>
                        <LabelNormal fontSize="22" color="#060809" style={{paddingTop: 30}}>Perfil fiscal actualizado</LabelNormal>
                        <LabelNormal fontSize="17" color="#4F4F4F" style={{paddingTop: 14, marginBottom: 40}}>Ahora podrás elegir este perfil para facturar tus pagos.</LabelNormal>
                    </ModalSuccess>
                )
            }
        },
        onError: err => {
            modalMessages.open(
                <ModalError onClick={() => modalMessages.close() }>
                    <LabelNormal
                        color="#060809"
                        fontSize="22"
                        style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
                    >
                        Razón social no registrada
                    </LabelNormal>
                    <LabelNormal
                        color="#4F4F4F"
                        fontSize="17"
                        style={{ marginBottom: 14 }}
                    >
                        Ocurrió un error al registrar la razón social
                    </LabelNormal>
                    <div
                        style={{
                            backgroundColor: '#FFE5E3',
                            padding: '15px',
                            borderRadius: 13,
                            color: '#C60E02',
                        }}
                    >
                        <p style={{ fontWeight: '600' }}>{err?.message}</p>
                    </div>
                </ModalError>
            )
        }
    })

    const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
        onCompleted: data => {
            if(data.doctor){
                setCountry(data.doctor.user.country?.id);
            }
        }
    })

    useEffect(() => {
        setDisabledCase(!formValid)
    },[formValid])

    useEffect(() => {
        if (props.taxdata) {
            setDefaultValues({
                business_name: props.taxdata.business_name,
                type: props.taxdata.type,
                rfc: props.taxdata.rfc,
                tax_system_id: props.taxdata.taxSystem ? parseInt(props.taxdata.taxSystem?.id) : null,
                cfdi_usage_id: props.taxdata.cfdiUsage !== null ? parseInt(props.taxdata.cfdiUsage?.id) : null,
                default: props.taxdata.default
            })
            setDefaultValuesAddress({
                street: props.taxdata.street,
                number: props.taxdata.number,
                internal_number: props.taxdata.internal_number,
                postal_code: props.taxdata.postal_code,
                city: props.taxdata.suburb ?? '',
                locality: props.taxdata.locality ?? '',
                state: props.taxdata.state ?? '',
                timezone: '',
                timezone_name: '',
                references: ''
            })
        } else {
            setCanResetCfdiUsages(true)
        }
        getDoctorDetails({ variables: { id: localStorage.getItem('b360-role_id') } })
    }, [])

    return (
      <div className='modal' style={{maxWidth: 855, height: '100%', margin: 74}}>
        <Inline>
            <ButtonClose onClick={() => modal.close()} />
        </Inline>
        <div>
          <div className='bootstrap-wrapper' style={{zIndex: 999}}>
            <div className='row'>
                <div className='col-md-12'>
                    <Title style={{marginBottom: 10}}>Perfil fiscal</Title>
                    <LabelNormal color="#757575" fontSize="17" style={{ marginBottom: 16, marginLeft: 0 }}>Con estos datos fiscales facturamos el pago de tus tratamientos</LabelNormal>
                    <LabelNormal color="#454D58" fontSize="22" style={{ marginBottom: 16, marginLeft: 0 }}>{props.taxdata ? "Editar perfil fiscal" : "Agregar perfil fiscal"}</LabelNormal>
                </div>
                <div class="col-md-12">
                    <FiscalForm
                        country={country} 
                        setValid={setFormValid} 
                        setValues={setFormValues} 
                        defaultValues={defaultValues} 
                        defaultValuesAddress={defaultValuesAddress} 
                        canReset={canResetCfdiUsages}
                        submit={submit}
                    />
                    <div style={{marginTop: 31, marginBottom: 41}}>
                        <ButtonPrime label={"Guardar perfil"} onClick={() => props.taxdata ? onUpdateTaxData() : onSubmitTaxData()}></ButtonPrime>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    );
};
   
export default ModalBase;