import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import InputSearch from '@components/shared/InputSearch';
import LinkUnderline from '@components/shared/LinkUnderline';
import { QUERY_GET_UNIVERSITY_ID, QUERY_GET_UNIVERSITY_DATA } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ChipIcons, { Cap, ClipBoard } from '@components/shared/ChipIcons';
import { 
    MUTATION_UPDATE_PATIENT,
    MUTATION_DISABLED_UNIVERSITY,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import moment from 'moment';
import { Count } from '@components/Icons/Count';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 79px;
    max-width: 1072px;
    margin-bottom: 100px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const CardInlineContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
    display:inline-block;
    margin: 0px 5px;
`;

const HeaderCard = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const HeaderCardTable = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const ContainForm = styled.div`
    margin-top: 39px;
`;

const LabelInput = styled.div`
    font-size: 18px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    line-height: 21.6px;
`;

const LabelMute = styled.p`
    font-size: 15px;
    font-family: neue-montreal-regular;
    color: #757575;
`;

const ButtonModal = styled.button`
    border-radius: 40px;
    min-width: 198px;
    padding: 12px;
    border: 1px solid #3C4549;
    background: transparent;
    cursor: pointer;
`;

const CategoryCard = styled.div`
    background: ${(({background}) => background ? background : '#828282')};
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;   
`;

const ButtonReactive = styled(Link)`
    border-radius: 69px;
    min-width: 180px;
    min-height: 46px;
    text-decoration: none;
    background: ${(({background}) => background ? background : '#C8C8C8')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
`;


const University = (props) => {
    const history = useHistory();
    const { id } = useParams();

    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [search, setSearch] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [photo, setPhoto] = useState('');
    const [status, setStatus] = useState(false);

    const [countCases, setCountCases] = useState('');
    const [countTeacher, setCountTeacher] = useState('');
    const [countStudent, setCountStudent] = useState('');
    const [countDetails, setCountDetails] = useState({});

    const [students, setStudents] = useState([]);
    const [teachers, setTeachers] = useState([]);

    const [idUser, setIdUser] = useState('');
    const [treatments, setTreatments] = useState([]);

    const [getUniversity] = useLazyQuery(QUERY_GET_UNIVERSITY_ID, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.university){
                const university = data.university;

                setIdUser(university.user.id);
                setTreatments(university.treatments);
                setStatus(university.disabled);

                setName(university.name);
                setCountry(university.user.country.name);
                setFirstName(university.user.first_name);
                setLastName(university.user.last_name);
                setPhone(university.user.phone);
                setEmail(university.user.email);
                setPhoto(university.user.photo_filename);
                setCreatedAt(university.user.created_at);

                setCountCases(university.count_treatments);
                setCountStudent(university.count_doctors);
                setCountTeacher(university.count_teachers);

                setStudents(university.students)
                setTeachers(university.teachers);

                setCountDetails(university);
            }
        },
        onError: err => console.log(err)
    })

    const [getUniversityData] = useLazyQuery(QUERY_GET_UNIVERSITY_DATA, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.getUniversityData){
                const university = data.getUniversityData;
                setTreatments(university.treatments);
                setStudents(university.doctors)
                setTeachers(university.teachers);
            }
        },
        onError: err => console.log(err)
    })

    useEffect(() => {
        getUniversity({ variables: { id } });
        getUniversityData({ variables: { university_id: +id }});
    },[])

    useEffect(() => {
        if(search){
            getUniversityData({ variables: { university_id: +id, search }});
        }else{
            getUniversityData({ variables: { university_id: +id }});
        }
    },[search])

    const [disabledUniversity] = useMutation(MUTATION_DISABLED_UNIVERSITY, {
        onCompleted: data => {
            modal.close()
            getUniversity({ variables: { id } });
        }
    })

    const confirmDisabled = () => {
        modal.open(
            <ModalWarning onClick={() => modal.close()}>
                <LabelNormal color="#060809" fontSize="22" style={{marginTop: 30}}>Los estudiantes, profesores y la universidad perderán acceso al portal y los casos serán desactivados</LabelNormal>
                <LabelNormal color="#4F4F4F" fontSize="17" style={{marginTop: 14}}>Puedes reactivar la universidad desde el perfil.</LabelNormal>
                <ButtonPrime style={{marginTop: 20}} onClick={() => disabledUniversity({ variables: { university_id: +id, disabled: true } })} label="Desactivar universidad" />
            </ModalWarning>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="universidades" />
            <ContainHome>
                {status &&
                    <div style={{background: 'rgba(229, 229, 229, 0.4)', top: 0, bottom: 0, position: 'fixed', zIndex: 9, width: '100%'}} />
                }
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title>Universidades</Title>
                                <div style={{marginTop: 10, marginBottom: 39}}>
                                    <Breadcrumb elements={1} onClick={() => history.goBack()} mainLabel={'Universidades'} endLabel={name}/>
                                </div>
                            </div>
                            <div className={'col-md-7'}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <InlineContainer>
                                            <div>
                                                <Avatar src={photo ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}` : require('@images/university.png')} height="161" width="161" borderRadius="30" />
                                                <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>Miembro desde</LabelNormal>
                                                <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>{moment(createdAt).format('DD/MM/YYYY')}</LabelNormal>
                                            </div>
                                            <div style={{marginLeft: 35}}>
                                                <InlineContainer style={{alignItems: 'center'}}>
                                                <LabelNormal style={{marginRight: 15}} fontSize="30" color="#3C4549">{name}</LabelNormal>
                                                    <Link to={`/app/universidades/${id}/actualizar-universidad/${id}`}>
                                                        <FeatherIcon icon="edit" color="#3C4549" />
                                                    </Link>
                                                </InlineContainer>
                                                <InlineContainer>
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="map-pin" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{country}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="user" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{`${firstName} ${lastName}`}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="phone" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{phone}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="mail" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{email}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                    </div>
                                                </InlineContainer>
                                            </div>
                                        </InlineContainer>
                                    </div>
                                    <div className='col-md-12' style={{marginTop:66}}>
                                        <InputSearch placeholder="Busca por nombre o ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <div style={{marginTop: 30}}>
                                            <div className='col-md-12'>
                                                <LabelNormal fontSize="22" color="#454D58">Casos</LabelNormal>

                                                <HeaderCardTable>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Paciente</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">ID</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Status</LabelNormal>
                                                        </div>
                                                    </div>
                                                </HeaderCardTable>
                                                <CardContainer>
                                                {treatments.length > 0 ?
                                                    <>
                                                        {treatments.map(item => (
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <Link to={`/app/universidades/${id}/caso/${item.patient.id}`}>
                                                                        <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.patient.first_name} ${item.patient.last_name}`}</LabelNormal>
                                                                    </Link>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <LabelNormal color="#757575" style={{textAlign: 'center'}} fontSize="15">{item.id}</LabelNormal>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.treatmentStatus.name}</LabelNormal>
                                                                </div>
                                                            </div>
                                                        )) }
                                                    </>
                                                :
                                                    <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin casos registrados</LabelNormal>
                                                }
                                                </CardContainer>
                                            </div>

                                            <div className='col-md-12' style={{marginTop: 66}}>
                                                <InlineContainer style={{justifyContent: 'space-between'}}>
                                                    <LabelNormal fontSize="22" color="#454D58">Estudiantes</LabelNormal>
                                                    <LinkUnderline id={id} label={"Agregar nuevo estudiante"} name={name} link={`/app/universidades/${id}/registrar-estudiante`}/>
                                                </InlineContainer>

                                                <HeaderCardTable>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Estudiante</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Activos</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Terminados</LabelNormal>
                                                        </div>
                                                    </div>
                                                </HeaderCardTable>
                                                <CardContainer>
                                                    {students.length > 0 ?
                                                        <>
                                                            {students.map(item => (
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <Link to={`/app/universidades/${id}/estudiante/${item.id}`}>
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        {item.count_treatments > 0 ?
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_treatments}</LabelNormal>
                                                                        :
                                                                            <Count />
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_inactives_treatments}</LabelNormal>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    :
                                                        <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin estudiantes registrados</LabelNormal>
                                                    }
                                                </CardContainer>
                                            </div>

                                            <div className='col-md-12' style={{marginTop: 66}}>
                                                <InlineContainer style={{justifyContent: 'space-between'}}>
                                                    <LabelNormal fontSize="22" color="#454D58">Profesores</LabelNormal>
                                                    <LinkUnderline id={id} label={"Agregar nuevo profesor"} name={name} link={`/app/universidades/${id}/registrar-profesor`}/>
                                                </InlineContainer>
                                                <HeaderCardTable>
                                                    <div className='row'>
                                                        <div className='col-md-7'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Profesor</LabelNormal>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <LabelNormal color="#3C4549" fontSize="17">Estudiantes asignados</LabelNormal>
                                                        </div>
                                                    </div>
                                                </HeaderCardTable>
                                                <CardContainer>
                                                    {teachers.length > 0 ?
                                                        <>
                                                            {teachers.map(item => (
                                                                <div className='row'>
                                                                    <div className='col-md-7'>
                                                                        <Link to={`/app/universidades/${id}/profesor/${item.id}`}>
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-5'>
                                                                        {item.count_students > 0 ?
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_students}</LabelNormal>
                                                                        :
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>0</LabelNormal>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    :
                                                        <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin profesores registrados</LabelNormal>
                                                    }
                                                </CardContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <HeaderCard>
                                            <LabelNormal color="#3C4549" fontSize="17">Resumen</LabelNormal>
                                        </HeaderCard>
                                        <CardContainer>
                                            <InlineContainer style={{justifyContent: 'space-between', marginBottom: 25}}>
                                                <LabelNormal color="#757575" fontSize="16">Estudiantes</LabelNormal>
                                                <ChipIcons name={countStudent} icon="cap" color="#C8C8C8" />
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between', marginBottom: 25}}>
                                                <LabelNormal color="#757575" fontSize="16">Profesores</LabelNormal>
                                                <ChipIcons name={countTeacher} icon="libraries" color="#C8C8C8" />
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between', marginBottom: 25}}>
                                                <LabelNormal color="#757575" fontSize="16">Casos</LabelNormal>
                                                <ChipIcons name={countCases} icon="clipboard" color="#C8C8C8" />
                                            </InlineContainer>
                                            {status &&
                                                <ButtonPrime style={{marginTop: 10, position: 'relative', zIndex: 9}} onClick={() => disabledUniversity({ variables: { university_id: +id, disabled: false } })} label="Reactivar perfil" />
                                            }
                                        </CardContainer>
                                    </div>
                                    <div className='col-md-12' style={{marginTop: 25}}>
                                        <HeaderCard>
                                            <LabelNormal color="#3C4549" fontSize="17">Detalle</LabelNormal>
                                        </HeaderCard>
                                        <CardContainer>
                                            <InlineContainer style={{alignItems: 'center'}}>
                                                <Cap color="#757575" />
                                                <LabelNormal fontSize="17" color='#757575'>Estudiantes</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between', marginTop: 10}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Actuales</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_doctors ? countDetails.count_doctors : '0'}</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Egresados activos</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_doctors_active ? countDetails.count_doctors_active : '0'}</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Egresados inactivos</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_doctors_inactive ? countDetails.count_doctors_inactive : '0'}</LabelNormal>
                                            </InlineContainer>

                                            <InlineContainer style={{justifyContent: 'space-between', borderTop: '1px solid #C8C8C8', marginTop: 10}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Totales</LabelNormal>
                                                <LabelNormal color="#4F4F4F" fontSize="17">{countDetails.count_doctors ? countDetails.count_doctors : '0'}</LabelNormal>
                                            </InlineContainer>

                                            <InlineContainer style={{alignItems: 'center', marginTop: 33}}>
                                                <ClipBoard color="#757575" />
                                                <LabelNormal fontSize="17" color='#757575'>Casos</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between', marginTop: 10}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Activos</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_treatments ? countDetails.count_treatments : '0'}</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Terminados</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_finished_treatments ? countDetails.count_finished_treatments : '0'}</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Inactivos</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_inactives_treatments ? countDetails.count_inactives_treatments : '0'}</LabelNormal>
                                            </InlineContainer>
                                            <InlineContainer style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Heredados</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{countDetails.count_heredity_treatments ? countDetails.count_heredity_treatments : '0'}</LabelNormal>
                                            </InlineContainer>

                                            <InlineContainer style={{justifyContent: 'space-between', borderTop: '1px solid #C8C8C8', marginTop: 10}}>
                                                <LabelNormal color="#BDBDBD" fontSize="14">Totales</LabelNormal>
                                                <LabelNormal color="#4F4F4F" fontSize="17">{countDetails.count_treatments ? countDetails.count_treatments : '0'}</LabelNormal>
                                            </InlineContainer>
                                        </CardContainer>
                                    </div>
                                    {!status &&
                                        <div className='col-md-12' style={{marginTop: 43}}>
                                            <CardContainer style={{cursor: 'pointer', borderRadius: 30}} onClick={() => confirmDisabled()}>
                                                <InlineContainer style={{alignItems: 'center', paddingLeft: 20}}>
                                                    <FeatherIcon icon="user-minus" size="19" />
                                                    <LabelNormal style={{marginLeft: 20}} fontSize="17" color="#3C4549">Desactivar universidad</LabelNormal>
                                                </InlineContainer>
                                            </CardContainer>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

export default University;