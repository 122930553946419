import Home from '@pages/App/Home';
import Team from '@pages/App/Team';
import ForgotPassword from '@pages/Auth/ForgotPassword';
import Login from '@pages/Auth/Login';
import LoginLink from '@pages/Auth/LoginLink';
import RegisterMember from '@pages/App/Team/RegisterMember';
import Profile from '@pages/App/Profile';
import ProfileMember from '@pages/App/Team/ProfileMember';
import FormRegisterDoctor from '@pages/Auth/FormRegisterDoctor';
import Doctors from '@pages/App/Doctors';
import FormRequestDoctor from '@pages/App/Doctors/FormRequestDoctor';
import ProfileDoctor from '@pages/App/Doctors/ProfileDoctor';
import YourCases from '@pages/Doctor/YourCases';
import Benefits from '@pages/Doctor/Benefits';
import Advisory from '@pages/Doctor/Advisory';
import ProfileDoc from '@pages/Doctor/Profile';
import ProfileFiscal from '@pages/Doctor/ProfileFiscal';
import Address from '@pages/Doctor/Address';
import NewCase from '@pages/Doctor/NewCase';
import ForgotPasswordToken from '@pages/Auth/ForgotPasswordToken';
import CreditCards from '@pages/Doctor/ProviderStripe';
import Case from '@pages/Doctor/Case';
import CasesDoctor from '@pages/App/Doctors/CasesDoctor';
import EditCase from '@pages/Doctor/EditCase';
import ApproveCase from '@pages/Doctor/ApproveCase';
import CaseClipboard from '@pages/App/Clipboard/Case';
import Review from '@pages/App/Clipboard/Review';
import CaseCorrections from '@pages/Doctor/Corrections';
import Planning from '@pages/App/Clipboard/Planning';
import ManufactStl from '@pages/App/Clipboard/ManufactStl';
import PlanningCase from '@pages/Doctor/Planning';
import ScheduleSmartcheck from '@pages/Doctor/ScheduleSmartcheck';
import SmartChecks from '@pages/App/SmartChecks';
import NumberAligner from '@pages/App/SmartChecks/ConfirmAligners';
import EditCreditCards from '@pages/Doctor/EditCreditCard';
import MessageBorgatta from '@pages/Doctor/MessageBorgatta';
import Support from '@pages/App/Clipboard/Support';
import SupportDoctor from '@pages/Doctor/Support';
import SendTreatment from '@pages/App/Clipboard/SendTreatment';
import Universities from '@pages/App/Universities';
import RegisterUniversity from '@pages/App/University/Register';
import University from '@pages/App/University/University';
import RegisterStudent from '@pages/App/University/RegisterStudent';
import RegisterTeacher from '@pages/App/University/RegisterTeacher';
import Student from '@pages/App/University/Student';
import UpdateStudent from '@pages/App/University/UpdateStudent';
import Teacher from '@pages/App/University/Teacher';
import UpdateUniversity from '@pages/App/University/UpdateUniversity';
import UpdateTeacher from '@pages/App/University/UpdateTeacher';
import VerifyUniversity from '@pages/Auth/VerifyUniversity';
import VerifyTeacher from '@pages/Auth/VerifyTeacher';
import VerifyStudent from '@pages/Auth/VerifyStudent';
import ProfileUniversity from '@pages/University/Profile';
import ProfileStudent from '@pages/Student/Profile';
import ProfileTeacher from '@pages/Teacher/Profile';
import UpdateProfileTeacher from '@pages/Teacher/UpdateProfile';
import UpdateProfileStudent from '@pages/Student/UpdateProfile';
import PlanningReview from '@pages/App/Clipboard/PlanningReview';
import VisorPlanning from '@pages/Doctor/PlanningVisor';
import UpdateProfileUniversity from '@pages/University/UpdateProfile';
import ListStudents from '@pages/University/ListStudents';
import ListTeachers from '@pages/University/ListTeachers';
import TeacherProfileUniversity from '@pages/University/ProfileTeacher';
import StudentProfileUniversity from '@pages/University/ProfileStudent';
import YouCasesStudent from '@pages/Student/YourCases';
import NewCaseStudent from '@pages/Student/NewCase';
import ContinueCaseStudent from '@pages/Student/ContinueCaseStudent';
import CaseUniversity from '@pages/App/University/Case';
import CasesUniversity from '@pages/University/ListCases';
import CaseRoleUniversity from '@pages/University/Case';
import CasesTeacher from '@pages/Teacher/Cases';
import HistoryCase from '@pages/App/University/HistoryCase';
import StudentsTeacher from '@pages/Teacher/Students';
import StudentTeacher from '@pages/Teacher/Student';
import Insights from '@pages/Insights/Insights';
import Invoice from '@pages/Doctor/Invoices';
import Surgery from '@pages/Doctor/Surgery';
import Payments from '@pages/App/Clipboard/Payments';

const routes = [
  {
    path: '/inicio-sesion',
    name: 'Login',
    component: Login,
    screen: '/auth',
  },
  {
    path: '/generar-contrasena/:id',
    name: 'Generate Password',
    component: LoginLink,
    screen: '/auth',
  },
  {
    path: '/recuperar-contrasena',
    name: 'Forgot Password',
    component: ForgotPassword,
    screen: '/auth',
  },
  {
    path: '/recuperar-contrasena/:id',
    name: 'Recuperar contrasena',
    component: ForgotPasswordToken,
    screen: '/auth',
  },
  {
    path: '/formulario-registro-doctor',
    name: 'Form register doctor',
    component: FormRegisterDoctor,
    screen: '/auth',
  },
  {
    path: '/verificar-universidad/:id',
    name: 'Verify University',
    component: VerifyUniversity,
    screen: '/auth',
  },
  {
    path: '/verificar-estudiante/:id',
    name: 'Verify Student',
    component: VerifyStudent,
    screen: '/auth',
  },
  {
    path: '/verificar-profesor/:id',
    name: 'Verify Teacher',
    component: VerifyTeacher,
    screen: '/auth',
  },
  {
    path: '/tablero',
    name: 'Home',
    component: Home,
    screen: '/app',
  },
  {
    path: '/equipo',
    name: 'Team',
    component: Team,
    screen: '/app',
  },
  {
    path: '/doctores',
    name: 'Doctors',
    component: Doctors,
    screen: '/app',
  },
  {
    path: '/smartchecks',
    name: 'SmartChecks',
    component: SmartChecks,
    screen: '/app',
  },
  {
    path: '/smartchecks/numero-alineadores/:id',
    name: 'SmartChecks',
    component: NumberAligner,
    screen: '/app',
  },
  {
    path: '/equipo/registrar-miembro',
    name: 'Register Member',
    component: RegisterMember,
    screen: '/app',
  },
  {
    path: '/doctores/formulario-solicitud-doctor/:id',
    name: 'Form Request Doctor',
    component: FormRequestDoctor,
    screen: '/app',
  },
  {
    path: '/equipo/perfil-miembro/:id',
    name: 'Profile Member',
    component: ProfileMember,
    screen: '/app',
  },
  {
    path: '/doctores/perfil-doctor/:id',
    name: 'Profile Doctor',
    component: ProfileDoctor,
    screen: '/app',
  },
  {
    path: '/doctores/casos-doctor/:id',
    name: 'Cases Doctor',
    component: CasesDoctor,
    screen: '/app',
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: Profile,
    screen: '/app',
  },
  {
    path: '/universidades',
    name: 'Universities',
    component: Universities,
    screen: '/app',
  },
  {
    path: '/universidades/registrar',
    name: 'University register',
    component: RegisterUniversity,
    screen: '/app',
  },
  {
    path: '/universidades/:id',
    name: 'University',
    component: University,
    screen: '/app',
  },
  {
    path: '/universidades/:id/actualizar-universidad/:id',
    name: 'Update University',
    component: UpdateUniversity,
    screen: '/app',
  },
  {
    path: '/universidades/:id/registrar-estudiante',
    name: 'Register Student',
    component: RegisterStudent,
    screen: '/app',
  },
  {
    path: '/universidades/:id/actualizar-estudiante/:id',
    name: 'Update Student',
    component: UpdateStudent,
    screen: '/app',
  },
  {
    path: '/universidades/:id/estudiante/:id',
    name: 'Student',
    component: Student,
    screen: '/app',
  },
  {
    path: '/universidades/:id/registrar-profesor',
    name: 'Register Teacher',
    component: RegisterTeacher,
    screen: '/app',
  },
  {
    path: '/universidades/:id/actualizar-profesor/:id',
    name: 'Update Teacher',
    component: UpdateTeacher,
    screen: '/app',
  },
  {
    path: '/universidades/:id/profesor/:id',
    name: 'Teacher',
    component: Teacher,
    screen: '/app',
  },
  {
    path: '/universidades/:id/caso/:id',
    name: 'Case University',
    component: CaseUniversity,
    screen: '/app',
  },
  {
    path: '/tablero/caso/:id',
    name: 'Case',
    component: CaseClipboard,
    screen: '/app',
  },
  {
    path: '/tablero/caso/revision/:id',
    name: 'Review',
    component: Review,
    screen: '/app',
  },
  {
    path: '/tablero/caso/planificacion/:id',
    name: 'Planning',
    component: Planning,
    screen: '/app',
  },
  {
    path: '/tablero/caso/stl-manufactura/:id',
    name: 'ManufactStl',
    component: ManufactStl,
    screen: '/app',
  },
  {
    path: '/tablero/revision-planificacion/:id',
    name: 'Review Planning',
    component: PlanningReview,
    screen: '/app',
  },
  {
    path: '/tablero/soporte/:patient_id/:treatment_id',
    name: 'Soporte',
    component: Support,
    screen: '/app',
  },
  {
    path: '/tablero/envio-tratamiento/:id',
    name: 'Send Treatment',
    component: SendTreatment,
    screen: '/app',
  },
  {
    path: '/soporte',
    name: 'Support',
    component: SupportDoctor,
    screen: '/app',
  },
  {
    path: '/caso/mensajes-borgatta/:id',
    name: 'Message borgatta',
    component: MessageBorgatta,
    screen: '/doctor',
  },
  {
    path: '/tus-casos',
    name: 'Tus Casos',
    component: YourCases,
    screen: '/doctor',
  },
  {
    path: '/beneficios',
    name: 'Beneficios',
    component: Benefits,
    screen: '/doctor',
  },
  {
    path: '/asesorias',
    name: 'Asesorías',
    component: Advisory,
    screen: '/doctor',
  },
  {
    path: '/soporte',
    name: 'Support Doctor',
    component: SupportDoctor,
    screen: '/doctor',
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: ProfileDoc,
    screen: '/doctor',
  },
  {
    path: '/agregar-perfil-fiscal',
    name: 'Add Profile Fiscal',
    component: ProfileFiscal,
    screen: '/doctor',
  },
  {
    path: '/editar-perfil-fiscal/:id',
    name: 'Edit Profile Fiscal',
    component: ProfileFiscal,
    screen: '/doctor',
  },
  {
    path: '/agregar-direccion-envio',
    name: 'Add Address',
    component: Address,
    screen: '/doctor',
  },
  {
    path: '/editar-direccion-envio/:id',
    name: 'Edit Address',
    component: Address,
    screen: '/doctor',
  },
  {
    path: '/editar-tarjeta-credito',
    name: 'Edit CreditCard',
    component: EditCreditCards,
    screen: '/doctor',
  },
  {
    path: '/agregar-tarjeta-credito',
    name: 'Add Credit Card',
    component: CreditCards,
    screen: '/doctor',
  },
  {
    path: '/nuevo-caso/:patient_id',
    name: 'New Case',
    component: NewCase,
    screen: '/doctor',
  },
  {
    path: '/nuevo-caso',
    name: 'New Case',
    component: NewCase,
    screen: '/doctor',
  },
  {
    path: '/continuar-caso/:patient_id',
    name: 'Continue Case',
    component: EditCase,
    screen: '/doctor',
  },
  {
    path: '/aprobar-caso/:patient_id',
    name: 'Approve Case',
    component: ApproveCase,
    screen: '/doctor',
  },
  {
    path: '/caso/:id',
    name: 'Case',
    component: Case,
    screen: '/doctor',
  },
  {
    path: '/caso/correccion/:id',
    name: 'Case Corrections',
    component: CaseCorrections,
    screen: '/doctor',
  },
  {
    path: '/caso/planificacion/:id',
    name: 'Planning',
    component: PlanningCase,
    screen: '/doctor',
  },
  {
    path: '/caso/agendar-smartcheck/:id',
    name: 'Schedule Smartcheck',
    component: ScheduleSmartcheck,
    screen: '/doctor',
  },
  {
    path: '/caso/visor/:id',
    name: 'Planning visor',
    component: VisorPlanning,
    screen: '/doctor',
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: ProfileUniversity,
    screen: '/universidad',
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: ProfileStudent,
    screen: '/estudiante',
  },
  {
    path: '/perfil/:id',
    name: 'Update Profile',
    component: UpdateProfileStudent,
    screen: '/estudiante',
  },
  {
    path: '/tus-casos',
    name: 'Your Case',
    component: YouCasesStudent,
    screen: '/estudiante',
  },
  {
    path: '/nuevo-caso',
    name: 'New Case',
    component: NewCaseStudent,
    screen: '/estudiante',
  },
  {
    path: '/continuar-caso/:patient_id',
    name: 'Continue Case',
    component: ContinueCaseStudent,
    screen: '/estudiante',
  },
  {
    path: '/soporte',
    name: 'Support',
    component: SupportDoctor,
    screen: '/estudiante',
  },
  {
    path: '/agregar-perfil-fiscal',
    name: 'Add Profile Fiscal',
    component: ProfileFiscal,
    screen: '/estudiante',
  },
  {
    path: '/editar-perfil-fiscal/:id',
    name: 'Edit Profile Fiscal',
    component: ProfileFiscal,
    screen: '/estudiante',
  },
  {
    path: '/agregar-direccion-envio',
    name: 'Add Address',
    component: Address,
    screen: '/estudiante',
  },
  {
    path: '/editar-direccion-envio/:id',
    name: 'Edit Address',
    component: Address,
    screen: '/estudiante',
  },
  {
    path: '/editar-tarjeta-credito',
    name: 'Edit CreditCard',
    component: EditCreditCards,
    screen: '/estudiante',
  },
  {
    path: '/agregar-tarjeta-credito',
    name: 'Add Credit Card',
    component: CreditCards,
    screen: '/estudiante',
  },
  {
    path: '/caso/:id',
    name: 'Case',
    component: Case,
    screen: '/estudiante',
  },
  {
    path: '/caso/correccion/:id',
    name: 'Case Corrections',
    component: CaseCorrections,
    screen: '/estudiante',
  },
  {
    path: '/caso/planificacion/:id',
    name: 'Planning',
    component: PlanningCase,
    screen: '/estudiante',
  },
  {
    path: '/caso/agendar-smartcheck/:id',
    name: 'Schedule Smartcheck',
    component: ScheduleSmartcheck,
    screen: '/estudiante',
  },
  {
    path: '/caso/visor/:id',
    name: 'Planning visor',
    component: VisorPlanning,
    screen: '/estudiante',
  },
  {
    path: '/aprobar-caso/:patient_id',
    name: 'Approve Case',
    component: ApproveCase,
    screen: '/estudiante',
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: ProfileTeacher,
    screen: '/profesor',
  },
  {
    path: '/perfil/:id',
    name: 'Update Profile',
    component: UpdateProfileTeacher,
    screen: '/profesor',
  },
  {
    path: '/soporte',
    name: 'Support',
    component: SupportDoctor,
    screen: '/profesor',
  },
  {
    path: '/casos',
    name: 'Cases',
    component: CasesTeacher,
    screen: '/profesor',
  },
  {
    path: '/caso/:id',
    name: 'Case',
    component: Case,
    screen: '/profesor',
  },
  {
    path: '/estudiantes',
    name: 'Students',
    component: StudentsTeacher,
    screen: '/profesor',
  },
  {
    path: '/estudiante/:id',
    name: 'Student',
    component: StudentTeacher,
    screen: '/profesor',
  },
  {
    path: '/caso/planificacion/:id',
    name: 'Planning visor',
    component: PlanningCase,
    screen: '/profesor',
  },
  {
    path: '/tablero/caso/revision-planificacion/:id',
    name: 'Revision planificacion',
    component: PlanningReview,
    screen: '/app',
  },
  {
    path: '/perfil/:id',
    name: 'Update Profile',
    component: UpdateProfileUniversity,
    screen: '/universidad',
  },
  {
    path: '/estudiantes',
    name: 'Students List',
    component: ListStudents,
    screen: '/universidad',
  },
  {
    path: '/profesores',
    name: 'Teachers List',
    component: ListTeachers,
    screen: '/universidad',
  },
  {
    path: '/profesor/:id',
    name: 'Teacher',
    component: TeacherProfileUniversity,
    screen: '/universidad',
  },
  {
    path: '/estudiante/:id',
    name: 'Student',
    component: StudentProfileUniversity,
    screen: '/universidad',
  },
  {
    path: '/casos',
    name: 'Cases',
    component: CasesUniversity,
    screen: '/universidad',
  },
  {
    path: '/caso/:id',
    name: 'Case',
    component: CaseRoleUniversity,
    screen: '/universidad',
  },
  {
    path: '/universidades/:id/historia-caso/:id',
    name: 'History Case',
    component: HistoryCase,
    screen: '/app',
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights,
    screen: '/app',
  },
  {
    path: '/invoices/:id',
    name: 'Invoice',
    component: Invoice,
    screen: '/doctor',
  },
  {
    path: '/surgery/:id',
    name: 'Consultorios',
    component: Surgery,
    screen: '/doctor',
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: Payments,
    screen: '/app',
  },
];

export default routes;
