import React, { useState, useEffect } from 'react';
import { UilCheck } from '@iconscout/react-unicons';
import ButtonPrime from '@components/shared/ButtonPrime';
import Input from '@components/shared/Input';
import { Inline, LabelNormal, BorderLoader } from './CouponForm.style';
import api from '@utils/api';
import './CouponForm.css';

const CouponForm = ({
  isDisabled,
  originalPrice,
  onChange,
  onReset,
  initialValues,
  reset,
}) => {
  const [code, setCode] = useState(initialValues?.code || '');
  const [hasDiscount, setHasDiscount] = useState(
    initialValues?.hasDiscount || false
  );
  const [isLoading, setLoading] = useState(false);
  const [isInvalidCode, setInvalidCode] = useState(false);

  useEffect(() => {
    if (reset) {
      resetForm();
    }
  }, [reset]);

  const resetForm = () => {
    setHasDiscount(false);
    setInvalidCode(false);
    setCode('');

    if (typeof onReset === 'function') {
      onReset();
    }
  };

  const submit = async () => {
    setLoading(true);
    const res = await api.get('/api/coupon/get', { params: { code } });
    const coupon = res.data.coupon;
    setLoading(false);
    setHasDiscount(true);
    setInvalidCode(coupon === null);

    if (coupon) {
      let price = originalPrice;

      if (coupon.discount_percent > 0) {
        price = originalPrice - originalPrice * (coupon.discount_percent / 100);
      } else {
        price = originalPrice - coupon.discount_lineal;
      }

      onChange({ discount: originalPrice - price, couponId: coupon.id });
    }
  };

  return (
    <div
      style={{
        width: 300,
        marginTop: 20,
        borderTop: '1px solid rgb(189, 189, 189)',
      }}
    >
      <LabelNormal
        color={!isDisabled ? '#3C4549' : '#9E9E9E'}
        fontSize="17"
        style={{ fontWeight: '600', marginTop: 20 }}
      >
        Código de descuento
      </LabelNormal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {!isInvalidCode && hasDiscount ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <UilCheck size="24" color="#169D00" />
            <LabelNormal color="#757575" fontSize="17">
              {code}
            </LabelNormal>
          </div>
        ) : (
          <Input
            type="text"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            placeholder="Ingresa el código"
            disabled={isDisabled}
            style={{
              width: 240,
              boxShadow: '0px 1px 2px rgba(69, 72, 74, 0.06)',
            }}
          />
        )}
        {isLoading && (
          <Inline>
            <BorderLoader
              className="text-center"
              style={{ backgroundColor: 'transparent' }}
            >
              <div className="loader-inner line-spin-fade-loader2">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </BorderLoader>
          </Inline>
        )}
      </div>
      {!isInvalidCode && hasDiscount && (
        <LabelNormal
          color="#169D00"
          fontSize="17"
          style={{ fontWeight: 'bolder' }}
        >
          Código aplicado
        </LabelNormal>
      )}
      {isInvalidCode && (
        <LabelNormal color="red" fontSize="17" style={{ fontWeight: 'bolder' }}>
          Código inválido
        </LabelNormal>
      )}
      <ButtonPrime
          label={
            !isInvalidCode && hasDiscount ? 'Anular código' : 'Aplicar código'
          }
          className={isDisabled ? 'button-disabled' : 'button-enabled'}
          disabled={isDisabled}
          background="transparent"
          style={
            hasDiscount
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }
              : {
                  width: 174,
                  fontWeight: '500',
                  marginTop: 15,
                  fontSize: 19,
                }
          }
          onClick={() => {
            if (!isInvalidCode && hasDiscount) {
              resetForm();
            } else {
              submit();
            }
          }}
        />
    </div>
  );
};

export default CouponForm;
