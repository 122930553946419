import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const Icon = styled.img`
    height: 16px;
    width: 16px;
    margin-right: 10px;
`;

const Button = styled(Link)`
    min-width: ${(({minWidth}) => minWidth ? minWidth : '150')}px;
    padding: 12px 25px 13px;
    border-radius: 69.9268px;
    margin-top: 5px;
    border: 1px solid #3C4549;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    text-decoration: none;
    width: fit-content;
    font-size: 19px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    &:hover {
        background: #3C4549;
        color: white;
    }
    &:active {
        background: #828282;
        color: white;
        border: 1px solid #828282;
    }
`;

const ButtonAction = (props) => (
    <Button {...props}>
        {props.icon && <FeatherIcon icon="plus" size="22"/>}
        <span style={{ marginLeft: 5 }}>{props.label}</span>
    </Button>
);

export default ButtonAction;