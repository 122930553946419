import React from 'react';
import Item from '../../Item/Item';
import { Cap, ClipBoard } from '@components/shared/ChipIcons';

const SectionsTeacher = (props) => {
  return (
    <>
      <Item
        label="Casos"
        to="/profesor/casos"
        icon={
          <ClipBoard
            color={props.name === 'casos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'casos' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'casos' && '#333C3F'}
      />
      <Item
        label="Estudiantes"
        to="/profesor/estudiantes"
        icon={
          <Cap
            color={
              props.name === 'estudiantes' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
            }
          />
        }
        color={props.name === 'estudiantes' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'estudiantes' && '#333C3F'}
        height={'19'}
        width={'20'}
      />
    </>
  );
};

export default SectionsTeacher;
