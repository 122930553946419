import React from 'react';
import styled from 'styled-components';
import ButtonClose from '../shared/ButtonClose';
import Map from '@components/DoctorFormCA/map';
import { modal } from '@components/ModalManagement';

const Inline = styled.div`
  display: flex;
  align-item: left;
  flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ModalPosterior = (props) => {

    return (
        <div className='modal' style={{maxWidth: 900, height: '100%', margin: 84}}>
            <Inline>
                <LabelNormal fontSize="26" color="#3C4549" bold>Sección posterior</LabelNormal>
                <ButtonClose onClick={() => modal.close()} top={'40px'}/>
            </Inline>
            <div>
                <div className='bootstrap-wrapper' style={{zIndex: 999}}>
                    <InlineContainer style={{alignItems:'center', marginTop: 20}}>
                        <LabelNormal fontSize="17" color="#3C4549" bold>Espacios</LabelNormal>
                        <LabelNormal fontSize="17" color="#757575">{props.formCAData.arcad.spaces}</LabelNormal>
                    </InlineContainer>
                    {props.formCAData.arcad.spaces !== 'Cerrar todos los espacios' &&
                        <Map personalize="Indicar milímetros por diente" containerNumber2 numerationTopLeft={['18','17','16','15', '14', '13', '12', '11']} numerationTopRight={['21','22','23','24', '25', '26', '27', '28']} numerationBottomLeft={['48', '47', '46', '45', '44', '43', '42', '41']} numerationBottomRight={['31', '32', '33', '34', '35', '36', '37', '38']} borderStyle="dashed" height={101.75} width={50} disabled topData={JSON.parse(props.formCAData.arcad.up_spaces_data)} bottomData={JSON.parse(props.formCAData.arcad.lo_spaces_data)} setTopData={() => {}} setBottomData={() => {}}  />
                    }
                    <LabelNormal fontSize="17" color="#757575" style={{marginTop: 50}}>*Medidas en milímetros</LabelNormal>
                </div>
            </div>
        </div>
    );
}

export default ModalPosterior;