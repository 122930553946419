import { InlineContainer, LabelNormal } from '../../shared/Styled';
import TreatmentTypeTag from './TreatmentTypeTag';
import StatusCase from './StatusCase';
import Buttons from './Buttons';
import UsersAssigned from './UsersAssigned';

const DetailsBody = ({
  patient,
  treatment,
  activeBasicChat,
  pendingMessages,
  setPhotoEvidences,
  reload,
}) => {
  const treatmentTypeId = parseInt(treatment?.treatmentType?.id) ?? null;
  const {
    type: statusType,
    index: statusIndex,
    color_type: statusColor,
    name: statusName,
  } = treatment?.treatmentStatus ?? {};
  const archived = treatment?.archived;
  const role = localStorage.getItem('b360-role');

  return (
    <>
      <div className="col-md-12">
        <LabelNormal color="#828282" fontSize="16">
          Tratamiento
        </LabelNormal>
        <TreatmentTypeTag treatmentTypeId={treatmentTypeId} />
      </div>
      {treatment?.doctor?.user?.type === 'student' && (
        <UsersAssigned treatment={treatment} currentView="student" />
      )}
      <div className="col-md-12">
        <LabelNormal color="#828282" fontSize="16">
          Status del caso
        </LabelNormal>
        <InlineContainer style={{ alignItems: 'center' }}>
          <StatusCase
            statusColor={statusColor}
            statusType={statusType}
            statusIndex={statusIndex}
            archived={archived}
            statusName={statusName}
          />
        </InlineContainer>
        <Buttons
          patient={patient}
          treatment={treatment}
          role={role}
          activeBasicChat={activeBasicChat}
          pendingMessages={pendingMessages}
          setPhotoEvidences={setPhotoEvidences}
          reload={reload}
        />
      </div>
    </>
  );
};

export default DetailsBody;
