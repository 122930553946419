import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonClose from '../shared/ButtonClose';
import { modal } from '@components/ModalManagement';
import RealoadIcon from '@images/reaload-icon.svg';
import Arrow from '@images/arrow.svg';

const Inline = styled.div`
  display: flex;
  align-item: left;
  flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const Contains = styled.td`
    position: relative;
    height: ${({height}) => height ? height : '206'}px;
    width: ${({width}) => width ? width : '160'}px;
    border-style: ${({borderStyle}) => borderStyle ? borderStyle : 'dashed'};
    border-color: ${({borderColor}) => borderColor ? borderColor : '#C8C8C8'};
    border-width: 1px;
    background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'};
    cursor: ${({cursor}) => cursor ? cursor : 'default'};
`;

const ModalPositionBrackets = (props) => {
    const [position, setPosition] = useState([13, 1, null, null])

    const isInteger = (value) => {
        return value.match(/^[0-9]+$/);
    }

    useEffect(() => {
        if(props.data.find((v, i) => i === props.index) !== '0,0'){
            setPosition([(+props.data.find((v, i) => i === props.index).split(',')[0]), (+props.data.find((v, i) => i === props.index).split(',')[1])], null, null)
        }
    },[])

    return (
        <div className='modal' style={{maxWidth: 571, height: '100%', margin: 84}}>
            <Inline>
                <LabelNormal fontSize="26" color="#3C4549" bold>Posición del bracket en diente n° {props.number}</LabelNormal>
                <ButtonClose onClick={() => modal.close()} top={'40px'}/>
            </Inline>
            <div>
                <div className='bootstrap-wrapper' style={{zIndex: 999}}>
                    <LabelNormal color="#757575" fontSize="17">Selecciona un punto de la cuadrícula o ingresa las<br/> coordenadas en los campos superiores. Las unidades son<br/> en milímetros y parten desde la esquina inferior izquierda.</LabelNormal>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 32}}>
                        <tr>
                            <Contains borderStyle={'solid'} 
                                backgroundColor={'#EDEDED'}
                                cursor={'default'}>
                                <table style={{borderCollapse: 'collapse', position: 'absolute', top:0, left: 0}}>
                                    {props.i === 0 ?
                                        <tbody>
                                            {Array.from(Array(13).keys()).map((_, y) => 
                                                <tr>
                                                    {Array.from(Array(10).keys()).map((_, x) => 
                                                        <td 
                                                            onClick={() => {
                                                                setPosition([(y+1), (x+1), props.i, props.index]);
                                                                props.setPositionFocus([(y+1), (x+1), props.i, props.index]);
                                                                props.setData(props.data.map((v, k) => k === props.index ? `${y+1},${x+1}` : v))
                                                            }} 
                                                            style={{borderColor: '#E41D11', borderWidth: .5, borderStyle: 'solid', height: 15.68, width: 15.85, background: '#FFF'}}
                                                        >
                                                            {(position[0] === (y+1) && position[1] === (x+1)) ? <div style={{width: 13, height: 13, background: '#EB9542', borderRadius: 50}} /> : null}
                                                        </td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>    
                                    :
                                        <tbody>
                                            {Array.from(Array(13).keys()).map((_, y) => 
                                                <tr>
                                                    {Array.from(Array(10).keys()).map((_, x) => 
                                                        <td 
                                                            onClick={() => {
                                                                setPosition([(y+1), (x+1), props.i, props.index]);
                                                                props.setPositionFocus([(y+1), (x+1), props.i, props.index]);
                                                                props.setData(props.data.map((v, k) => k === props.index ? `${y+1},${x+1}` : v))
                                                            }} 
                                                            style={{borderColor: '#E41D11', borderWidth: .5, borderStyle: 'solid', height: 15.68, width: 15.85, background: '#FFF'}}
                                                        >
                                                            {(position[0] === (y+1) && position[1] === (x+1)) ? <div style={{width: 13, height: 13, background: '#EB9542', borderRadius: 50}} /> : null}
                                                        </td>
                                                    )}
                                                </tr>
                                            ).reverse()}
                                        </tbody>
                                    }
                                </table>
                            </Contains>
                        </tr>
                        <div style={{marginLeft: 18}}>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                                <img src={Arrow} style={{marginRight: 6, height: 16}} />
                                <input type="text"
                                    value={position[0].toString()} 
                                    onChange={(e) => {
                                        if(isInteger(e.target.value) || e.target.value === ''){ 
                                            setPosition([(e.target.value ? (+e.target.value) : 0), position[1], props.i, props.index])
                                            props.setPositionFocus([(+e.target.value), position[1], props.i, props.index]);
                                            props.setData(props.data.map((v, i) => i === props.index ? `${e.target.value ? e.target.value : '0'},${v.split(',')[1]}` : v));
                                        }
                                    }} 
                                    style={{maxWidth: 31, height: 36, width: 31, border: `1px solid ${'#BDBDBDB2'}`, background: 'transparent', textAlign: 'center', borderRadius: 7, padding: '4px, 8px, 6px, 9px'}} 
                                />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={Arrow} style={{marginRight: 6, transform: 'rotate(90deg)', height: 16}} />
                                <input type="text" 
                                value={(position[1]).toString()}
                                onChange={(e) => {
                                    if(isInteger(e.target.value) || e.target.value === ''){ 
                                        setPosition([position[0], (+e.target.value), props.i, props.index])
                                        props.setPositionFocus([position[0], (e.target.value ? (+e.target.value) : 0), props.i, props.index]);
                                        props.setData(props.data.map((v, i) => i === props.index ? `${v.split(',')[0]},${e.target.value ? e.target.value : '0'}` : v));
                                    }
                                }} 
                                style={{maxWidth: 31, height: 36, width: 31, border: `1px solid ${'#BDBDBDB2'}`, background:'transparent', textAlign: 'center', borderRadius: 7, padding: '4px, 8px, 6px, 9px'}} />
                            </div>

                            <div onClick={() => {setPosition([0, 0, null, null]); props.setData(props.data.map((v, k) => k === props.index ? `0,0` : v))}} style={{marginLeft: 25, marginTop: 15, cursor: 'pointer', background: '#F6F6F8', textAlign: 'center', width: 24, height: 24, borderRadius: 23, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img src={RealoadIcon} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPositionBrackets;