import React from 'react';
import styled from "styled-components";

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    transition: all .3s ease-out;
    &:active {
        filter: invert(42%) sepia(47%) saturate(0%) hue-rotate(142deg) brightness(110%) contrast(92%);
    }
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
    transition: all .1s ease-out;
    &:hover {
        color: #3C4549;
        border-bottom: 2px solid #3C4549 !important;
    }
`;

 const CloseSession = (props) =>
    <InlineContainer>
        <img src={require('@images/icons/fi_log-out.png')} style={{height: 24, width: 24, marginRight: 7}} />
        <LabelNormal onClick={() => props.onClick()} color="#3C4549" fontSize="18" style={{cursor: 'pointer'}}>Cerrar sesión</LabelNormal>
    </InlineContainer>
 export default CloseSession;