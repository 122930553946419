import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Select from '@components/shared/Select';
import SelectCheckbox from '@components/shared/SelectCheckbox';
import Input from '@components/shared/Input';
import ToggleSwitch from '@components/shared/ToggleSwitch';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_REGISTER_MEMBER,
  MUTATION_ADD_MEMBER_PHOTO,
} from '@graphql/mutation';
import ButtonAction from '@components/shared/ButtonAction';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  QUERY_GET_COUNTRIES,
  QUERY_GET_ROLES,
  QUERY_GET_DEPARTAMENT,
} from '@graphql/query';
import { useHistory } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';

const ContainHome = styled.div`
  width: 100%;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const RegisterMember = (props) => {
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [admin, setAdmin] = useState(false);
  const [departament, setDepartament] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [role, setRole] = useState([]);
  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState(null);

  const [countries, setCountries] = useState([]);
  const [departaments, setDepartaments] = useState([]);
  const [roles, setRoles] = useState([]);

  const [loader, setLoader] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [photoUploadError, setPhotoUploadError] = useState(false);

  const [errors, setErrors] = useState([]);

  const [registerMember] = useMutation(MUTATION_REGISTER_MEMBER, {
    onCompleted: async (data) => {
      if (data.registerMember) {
        if (file) await onSubmitFile(data.registerMember.id);
        else {
          modal.open(
            <ModalSuccess
              onClick={() => modal.close(() => history.push('/app/equipo'))}
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 20 }}
              >
                Miembro del equipo registrado
              </LabelNormal>
              <LabelNormal fontSize="17" color="#4F4F4F">
                Puedes editar sus permisos desde su perfil.
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    },
    onError: (err) => {
      setErrors(err.graphQLErrors.map((error) => error.message));
      console.log(err);
      if (errors[0] === 'The email has already been registred.') {
        setPhotoUploadError(false);
      }
    },
  });

  const [getCountries] = useLazyQuery(QUERY_GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCountries(data.countries.data);
    },
  });

  const [getDepartament] = useLazyQuery(QUERY_GET_DEPARTAMENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDepartaments(data.departments.data);
    },
  });

  const [getRoles] = useLazyQuery(QUERY_GET_ROLES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setRoles(data.roles.data);
    },
  });

  useEffect(() => {
    getCountries({});
    getDepartament({});
    getRoles({});
  }, []);

  // useEffect(() => {
  //     validationForms();
  // },[role, firstName, lastName, gender, country, phone, email, departament, file])

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (gender === '') {
      setDisabled(true);
    } else if (country === '') {
      setDisabled(true);
    } else if (phone === '') {
      setDisabled(true);
    } else if (email === '') {
      setDisabled(true);
    } else if (departament === '') {
    } else if (selectedOption.length === 0) {
      setDisabled(true);
    } else if (role.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
      handlerSubmit();
    }
  };

  const handlerSubmit = () => {
    registerMember({
      variables: {
        first_name: firstName,
        last_name: lastName,
        gender,
        email,
        roles: role.map((x) => parseInt(x)),
        super_admin: admin,
        department_id: +departament,
        country_id: +country,
        countries: selectedOption.map((x) => parseInt(x)),
        phone,
        password: admin ? '12345678' : '',
      },
    });
  };

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => history.push('/app/equipo'))}
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Miembro del equipo registrado
            </LabelNormal>
            <LabelNormal fontSize="17" color="#4F4F4F">
              Puedes editar sus permisos desde su perfil.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => {
      console.log(err);
      if (errors[0] === 'The email has already been registred.') {
        setPhotoUploadError(false);
      } else {
        setPhotoUploadError(true);
      }
      setLoader(false);
    },
  });

  const onSubmitFile = async (id) => {
    addProfilePhoto({ variables: { file: file, user_id: +id } });
  };

  const [urlPrev, setUrlPrev] = useState('');

  let count = 0;
  const chargePercent = (prefile) => {
    const timer = setInterval(() => {
      if (count === 100) {
        setUrlPrev(URL.createObjectURL(prefile));
        setTimeout(() => {
          clearInterval(timer);
          setProgressUpload(0);
          setLoader(false);
        }, 1000);
      } else {
        count += 1;
        setProgressUpload(count);
      }
    }, 15);
  };

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    setLoader(true);
    setPhotoUploadError(false);

    if (prefile) {
      chargePercent(prefile);
    }

    evt.target.value = '';
  };

  useEffect(() => {
    if (admin) {
      setRole(roles.map((r) => r.id));
      setSelectedOption(countries.map((c) => c.id));
      setEdit(true);
    } else {
      setEdit(false);
      setRole([]);
      setSelectedOption([]);
    }
  }, [admin]);

  useEffect(() => {
    const rls = JSON.stringify(roles.map((r) => r.id));
    const ctr = JSON.stringify(countries.map((c) => c.id));
    if (
      JSON.stringify(role) !== rls ||
      JSON.stringify(selectedOption) !== ctr
    ) {
      setAdmin(false);
    }
  }, [role, selectedOption]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="equipo" />
      <ContainHome className="bootstrap-wrapper">
        <ContainerRegister>
          <Title>Registro de usuario</Title>
          <Subtitle>
            Llena los campos y asigna los permisos para el administrador.
          </Subtitle>
          <ContainForm>
            <Avatar
              src={
                urlPrev
                  ? urlPrev
                  : require('@images/people/usuario_masculino.jpg')
              }
              floatButton
              onChange={onChange}
              loader={progressUpload}
              state={loader ? true : false}
              error={photoUploadError}
              fadeOut={progressUpload === 100 ? true : false}
            />
          </ContainForm>
          <div style={{ marginTop: 38 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre"
                onChange={(e) => setFirstName(e.target.value)}
                label="Nombres y apellidos"
              />
              {firstName === '' && disabled && (
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce un nombre
                </p>
              )}
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                onChange={(e) => setLastName(e.target.value)}
              />
              {lastName === '' && disabled && (
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce un apellido
                </p>
              )}
            </div>
          </div>
          <div>
            <LabelInput>Género</LabelInput>
            <InlineContainer>
              <div className="radio" style={{ marginRight: 20 }}>
                <input
                  id="radio-1"
                  onClick={(e) => setGender('Masculino')}
                  checked={gender === 'Masculino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-1" className="radio-label">
                  Masculino
                </label>
              </div>
              <div className="radio">
                <input
                  id="radio-2"
                  onClick={(e) => setGender('Femenino')}
                  checked={gender === 'Femenino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-2" className="radio-label">
                  Femenino
                </label>
              </div>
            </InlineContainer>
          </div>
          <div className="row">
            <div className="col-md-4" style={{ marginTop: 30 }}>
              <LabelInput>País de residencia</LabelInput>
              <Select
                options={countries}
                onSelect={setCountry}
                value={country}
                placeholder="Selecciona un país"
              />
            </div>
          </div>
          <div style={{ marginTop: 38 }} className="grid-layout">
            <div>
              <LabelInput>Teléfono</LabelInput>
              <InlineContainer>
                <Input
                  readOnly
                  disabled
                  value={
                    country !== ''
                      ? countries.find((c) => c.id === country).lada_code
                      : '+52'
                  }
                  backgroundColor="#F6F6F8"
                  maxWidth="70px"
                />
                <Input
                  placeholder="Teléfono"
                  onChange={setPhone}
                  value={phone}
                  style={{ width: 256 }}
                  mask="phone"
                  validation="phone"
                />
              </InlineContainer>
              {phone === '' && disabled && (
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce un teléfono
                </p>
              )}
            </div>
            <div>
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors([]);
                }}
                required
                error={
                  errors.length > 0 &&
                  errors[0] === 'The email has already been registred.' &&
                  'The email has already been registred.'
                }
                emptyVal={
                  errors.length > 0 &&
                  errors[0] === 'The email has already been registred.' &&
                  '#C60E02'
                }
              />
              {email === '' && disabled && (
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce un correo
                </p>
              )}
              {errors.length > 0 &&
                errors[0] === 'The email has already been registred.' && (
                  <p
                    style={{
                      color: '#C60E02',
                      fontSize: 17,
                      margin: 0,
                      padding: 0,
                      marginTop: 5,
                      fontFamily: 'neue-montreal-regular',
                    }}
                  >
                    {errors[0] === 'The email has already been registred.'
                      ? '*Email ya registrado'
                      : ''}
                  </p>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <LabelInput>Departamento</LabelInput>
              <Select
                options={departaments}
                onSelect={setDepartament}
                value={departament}
                placeholder="Selecciona un dpto."
              />
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{ marginTop: 30, alignItems: 'center', display: 'flex' }}
            >
              <ToggleSwitch
                small
                id="toggle"
                checked={admin ? 'checked' : false}
                onChange={() => setAdmin(!admin)}
              />
              <label className="radio-label" style={{ color: '#3C4549' }}>
                Es superadministrador
              </label>
            </div>
            <LabelMute>
              *Seleccionar esta casilla le permite asignar/quitar permisos a
              otros usuarios
            </LabelMute>
          </div>
          <div style={{ marginTop: 38, width: '40%' }}>
            <LabelInput>Permisos por departamento</LabelInput>
            <SelectCheckbox
              data={countries}
              placeholder="Selecciona uno o varios países"
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <LabelMute>
              *Los permisos estarán habilitados para el/los países que
              selecciones
            </LabelMute>
          </div>
          <div
            className="bootstrap-wrapper"
            style={{ marginTop: 38, width: '40%' }}
          >
            {roles.length > 0 && (
              <div className="row">
                {roles.map((row) => (
                  <div
                    className="col-md-6"
                    style={{ marginTop: 20 }}
                    key={Math.random()}
                  >
                    <Checkbox
                      checked={role.indexOf(row.id) !== -1}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) =>
                        value
                          ? setRole([...role, row.id])
                          : setRole(role.filter((r) => r !== row.id))
                      }
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                      }}
                      label={row.name}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {role.length === 0 && disabled && (
            <p
              style={{
                color: '#C60E02',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Selecciona un departamento
            </p>
          )}
          <div style={{ marginTop: 10, width: '40%' }}>
            <InlineContainer>
              <div>
                <div style={{ marginTop: 15 }}>
                  <Checkbox
                    icon={
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#EB9542"
                        size="sm"
                      />
                    }
                    checked={edit}
                    onChange={(value) => setEdit(value)}
                    borderColor="#C8C8C8"
                    style={{ overflow: 'hidden' }}
                    size={20}
                    labelStyle={{
                      marginLeft: 5,
                      userSelect: 'none',
                      fontFamily: 'neue-montreal-regular',
                      color: '#3C4549',
                    }}
                    label="Puede editar datos de doctores"
                  />
                </div>
              </div>
            </InlineContainer>
          </div>
          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime label="Registrar perfil" onClick={validationForms} />
            <ButtonAction
              label="Cancelar"
              minWidth="127"
              style={{ marginLeft: 20 }}
              to="/app/equipo"
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default RegisterMember;
