import { LabelNormal, Row } from '../../shared/Styled';
import ButtonSecondary from '../../shared/buttons/secondary';
import Info from '../../Icons/Info';
import { fileStatusEnum, treatmentStatusEnum } from '../consts';

/**
 * @param {{
 * fileStatusId: number,
 * hadErrorsReported: boolean,
 * loadingUndo: boolean
 * onClickAgreeChanges: () => void,
 * onClickUndo: () => void,
 * statusId: number,
 * }} props
 * @returns
 *
 * @TODO each render case should be reafactored and componentized.
 */
export const FileReviewAction = ({
  fileStatusId,
  hadErrorsReported,
  loadingUndo,
  onClickAgreeChanges,
  onClickUndo,
  statusId,
}) => {
  const isFullDisabled = () => {
    return [
      treatmentStatusEnum.incompleteRegistry,
      treatmentStatusEnum.pendingPayment,
      treatmentStatusEnum.reviewingFiles,
      treatmentStatusEnum.waitingModel,
    ].includes(statusId);
  };

  const isReadyToPlanningWithoutComments = () => {
    return (
      statusId >= treatmentStatusEnum.readyToPlanning &&
      fileStatusId === fileStatusEnum.allFilesApproved
    );
  };

  const isReadyToPlanningWithAgreeChanges = () => {
    return (
      statusId === treatmentStatusEnum.readyToPlanning &&
      fileStatusId === fileStatusEnum.agreedChanges
    );
  };

  const isReadyToPlanningAndHadFileErrorWithoutComments = () => {
    return (
      statusId >= treatmentStatusEnum.readyToPlanning &&
      hadErrorsReported === true
    );
  };

  const isReviewingFilesWithQuestion = () => {
    return (
      statusId === treatmentStatusEnum.reviewingFiles &&
      fileStatusId === fileStatusEnum.agreedChanges
    );
  };

  const isPausedCase = () => {
    return statusId === treatmentStatusEnum.pausedCase;
  };

  const isPausedAndFilesPending = () => {
    return (
      statusId === treatmentStatusEnum.pausedCase &&
      fileStatusId === fileStatusEnum.errorsReported
    );
  };

  const isSolvedErrorWithoutComments = () => {
    return (
      statusId === treatmentStatusEnum.solvedError &&
      fileStatusId !== fileStatusEnum.agreedChanges
    );
  };

  const isSolvedErrorWithAgreeChanges = () => {
    return (
      statusId === treatmentStatusEnum.solvedError &&
      fileStatusId === fileStatusEnum.agreedChanges
    );
  };

  const hasFileErrorsWithAgreeChanges = () => {
    return (
      statusId === treatmentStatusEnum.reportedErrors &&
      fileStatusId === fileStatusEnum.agreedChanges
    );
  };

  const hasFileErrorsWithoutComments = () => {
    return (
      statusId === treatmentStatusEnum.reportedErrors ||
      fileStatusId === fileStatusEnum.errorsReported
    );
  };

  const isReadyVisorAndFurther = () => {
    return statusId >= treatmentStatusEnum.readyVisor;
  };

  const defaultCase = (
    <>
      <ButtonSecondary
        fontSize="15px"
        width="fit-content"
        background="transparent"
        label="Cambios acordados"
        disabled={true}
      />
      <Row justifyContent="space-between">
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        >
          <Info />
          La acción no está disponible
        </LabelNormal>
      </Row>
    </>
  );

  if (isReadyToPlanningWithoutComments())
    return (
      <Row justifyContent="space-between">
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        >
          <Info />
          Aprobaste todos los archivos
        </LabelNormal>
      </Row>
    );

  if (isReviewingFilesWithQuestion() || isPausedCase())
    return (
      <>
        <ButtonSecondary
          fontSize="15px"
          width="fit-content"
          background="##EB9542"
          label="Cambios acordados"
          onClick={onClickAgreeChanges}
        />
      </>
    );

  if (
    isReadyToPlanningWithAgreeChanges() ||
    isPausedAndFilesPending() ||
    hasFileErrorsWithAgreeChanges()
  )
    return (
      <Row justifyContent="space-between">
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        >
          <Info />
          Cambios acordados
        </LabelNormal>
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            cursor: loadingUndo ? 'not-allowed' : 'pointer',
            textDecoration: 'underline',
          }}
          onClick={onClickUndo}
        >
          Deshacer
        </LabelNormal>
      </Row>
    );

  if (
    isReadyToPlanningAndHadFileErrorWithoutComments() ||
    hasFileErrorsWithoutComments() ||
    isSolvedErrorWithoutComments()
  )
    return (
      <Row justifyContent="space-between">
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        >
          <Info />
          Reportaste errores en archivos
        </LabelNormal>
      </Row>
    );

  if (isReadyVisorAndFurther() || isSolvedErrorWithAgreeChanges())
    return (
      <Row justifyContent="space-between">
        <LabelNormal
          fontSize="14"
          fontWeight="500"
          style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        >
          <Info />
          Cambios acordados
        </LabelNormal>
      </Row>
    );

  if (isFullDisabled()) return defaultCase;

  /* Default case */
  return defaultCase;
};
