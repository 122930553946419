import React from 'react';
import Item from '../../Item/Item';
import { Cap, Libraries, ClipBoard } from '@components/shared/ChipIcons';

const SectionsUniversity = (props) => {
  return (
    <>
      <Item
        label='Casos'
        to='/universidad/casos'
        icon={
          <ClipBoard
            color={props.name === 'casos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'casos' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'casos' ? true : false}
        height={'18'}
        width={'20'}
      />
      <Item
        label='Profesores'
        to='/universidad/profesores'
        icon={
          <Libraries
            color={props.name === 'profesores' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'profesores' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'profesores' ? true : false}
        height={'19'}
        width={'20'}
      />
      <Item
        label='Estudiantes'
        to='/universidad/estudiantes'
        icon={
          <Cap
            color={props.name === 'estudiantes' ? '#000' : '#4E5A5F'}
          />
        }
        color={props.name === 'estudiantes' && true}
        $background={props.name === 'estudiantes' ? true : false}
        height={'19'}
        width={'20'}
      />
    </>
  );
};
  
export default SectionsUniversity;