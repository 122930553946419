import React from 'react';
import styled from "styled-components";
import FeatherIcon from 'feather-icons-react';
import { UilClipboardNotes } from '@iconscout/react-unicons';

const HoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    &:hover {
        filter: invert(56%) sepia(100%) saturate(404%) hue-rotate(341deg) brightness(98%) contrast(87%);
    }
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${(({marginTop})=> marginTop ? marginTop : '0' )}px;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

 const LabelCase = (props) =>{
    if(props.disabled){
        return(
            <>
                <InlineContainer style={{marginTop: 30, alignItems: 'center'}}>
                    <FeatherIcon icon="archive" size="24" color="#BDBDBD" />
                    <LabelNormal color="#BDBDBD" fontSize="20">Mover a casos inactivos</LabelNormal>
                    <FeatherIcon icon="help-circle" size="16" color="#fff" fill="#BDBDBD" />
                </InlineContainer>
                {props.description &&
                    <InlineContainer style={{alignItems: 'center', marginLeft: 25}}>
                        <LabelNormal color="#BDBDBD" fontSize="15.5">Contacto, Facturación, Envío</LabelNormal>
                    </InlineContainer>
                }
            </>
        );
    } else {
        return(
            <>
                {props.description &&
                    <HoverContainer>
                        <InlineContainer style={{alignItems: 'center', cursor: 'pointer'}} onClick={() => props.onClick()} marginTop={props.marginTop}>
                            <FeatherIcon icon={props.icon} size="24" color="#444E53" />
                            <div>
                                <LabelNormal color="#444E53" fontSize="20">{props.label}</LabelNormal>
                                <LabelNormal color="#BDBDBD" fontSize="15">{props.descriptionText}</LabelNormal>
                            </div>
                        </InlineContainer>
                    </HoverContainer>
                }
                {!props.description &&
                    <HoverContainer>
                        <InlineContainer style={{alignItems: 'center', cursor: 'pointer'}} onClick={() => props.onClick()} marginTop={props.marginTop}>
                            {props.icon === 'clipboard-notes' 
                                ? <UilClipboardNotes size="24" color="#444E53"/>
                                : <FeatherIcon icon={props.icon} size="24" color="#444E53" /> 
                            }
                            <div>
                                <LabelNormal color="#444E53" fontSize="20">{props.label}</LabelNormal>
                            </div>
                        </InlineContainer>
                    </HoverContainer>
                }
            </>
        );
    }
 }
 
 export default LabelCase;