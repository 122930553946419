import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import { QUERY_GET_PATIENT, QUERY_GET_PLANNING_DAY, QUERY_GET_PLANNING_CALENDAR } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_GENERATE_SMARTCHECK } from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { differenceInCalendarDays } from 'date-fns';
import moment from 'moment';
import 'moment/locale/es-mx';

const ContainHome = styled.div`
    width: 100%;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 79px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const CardInlineContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
    display:inline-block;
    margin: 0px 5px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const ContainForm = styled.div`
    margin-top: 39px;
`;

const LabelInput = styled.div`
    font-size: 18px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    line-height: 21.6px;
`;

const LabelMute = styled.p`
    font-size: 15px;
    font-family: neue-montreal-regular;
    color: #757575;
`;

const ButtonModal = styled.button`
    border-radius: 40px;
    padding: 5px 12px;
    border: 1px solid #3C4549;
    background: transparent;
    cursor: pointer;
`;

const DecoratorBlue = styled.div`
    background: linear-gradient(#1AA5E0, #088EC7);
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const DecoratorYellow = styled.div`
    background: #F0BB00;
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const CategoryCard = styled.div`
    background: ${(({background}) => background ? background : '#828282')};
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;   
`;

const ButtonReactive = styled.button`
    border-radius: 69px;
    min-width: 180px;
    min-height: 46px;
    background: ${(({background}) => background ? background : '#C8C8C8')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
`;

const CardModel = styled.div`
    border: 2px solid #757575;
    border-radius: 10.1164px;
    padding: 21px;
    max-width: 338px;
    min-height: 143px;
    margin-right: 50px;
`;

const CardNote = styled.div`
    max-height: 199px;
    border-radius: 30px;
    background: #FFF;
`;

const HeaderCard = styled.div`
    background: #FFF;
    border-bottom-color: #000;
    border-bottom-width: .7px;
    border-bottom-style: solid;
    min-height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;


const CardImg = styled.img`
    max-height: 150px;
    width: 100%;
    border-radius: 6px;
`;

const ButtonHours = styled.button`
    border: 1px solid #EB9542;
    padding: 10px;
    width: 217px;
    border-radius: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: transparent;
    cursor: pointer;
`;


const ScheduleSmartcheck = (props) => {
    const history = useHistory();
    const { id } = useParams();


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [treatment, setTreatment] = useState('');
    const [schedule, setSchedule] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [hours, setHours] = useState([]);
    const [selectHour, setSelectHour] = useState('');

    const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.pacient){
                const patient = data.pacient;

                setFirstName(patient.first_name);
                setLastName(patient.last_name);
                setTreatment(patient.treatment);
            }
        },
        onError: err => console.log(err)
    });

    const [disabledSchedule, setDisabledSchedule] = useState([]);

    const [getDaySchedule] = useLazyQuery(QUERY_GET_PLANNING_CALENDAR, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.getPlanningCalendar){
                const notAvailable = data.getPlanningCalendar.filter(date => date.available === true);
                setDisabledSchedule(
                    notAvailable.map(date => moment(date.date).format('YYYY-MM-DD'))
                )
            }
        }
    })

    useEffect(() => {
        getPatient({ variables: { id } });
    },[])

    useEffect(() => {
        getDaySchedule({ variables: { month: (month.getMonth() === 9 ? ((month.getMonth() + 1)+"") : month.getMonth() < 9 ? "0" + (month.getMonth() + 1) : (month.getMonth() + 1)+""), year: (month.getFullYear()).toString()}});
        // getDaySchedule({ variables: { month: (month.getMonth() < 10 ? "0" + (month.getMonth() + 1) : month.getMonth()+""), year: (month.getFullYear()).toString()}});
        setHours([]);
    },[month])

    const tileDisabled = ({ date, view }) => {
        return disabledSchedule.indexOf(moment(date).format('YYYY-MM-DD')) === -1;
    }

    const [getHours] = useLazyQuery(QUERY_GET_PLANNING_DAY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.getPlanningDay){
                setHours(data.getPlanningDay.hours.filter(h => h.available === true).map(h => h.hour_string));
            }
        }
    })

    useEffect(() => {
        getHours({ variables: { date: `${moment(schedule).format('YYYY-MM-DD')}` } });
    },[schedule])


    const [generateSmartcheck] = useMutation(MUTATION_GENERATE_SMARTCHECK, {
        onCompleted: data => {
            modal.open(
                <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
                    <LabelNormal color="#060809" fontSize="22" style={{marginTop: 30}}>Smart Check agendado con éxito</LabelNormal>
                    <LabelNormal style={{marginTop: 14}} color="#4F4F4F" fontSize="17">Tu Smart Check para verificar la planificación para tu paciente esta agendado para el {`${moment(schedule).format('DD [de] MMMM')} a las ${moment(selectHour, ["hh:mm a"]).format('hh:mm a')}`}.</LabelNormal>
                </ModalSuccess>
            )
        }
    })

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="tus-casos" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} />
                    </div>
                </Header>
                <ContainContent style={{marginBottom: 100}}>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title>Agenda tu Smart Check</Title>
                            </div>
                            <div className='col-md-12' style={{maxWidth: 1145, marginTop: 39}}>
                                <InlineContainer style={{alignItems: 'center'}}>
                                    <LabelNormal fontSize="15" color="#436FF1">PX</LabelNormal>
                                    <LabelNormal fontSize="22" color="#3C4549">{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                                <InlineContainer style={{alignItems: 'center'}}>
                                    <LabelNormal fontSize="15" color="#436FF1">TRATAMIENTO</LabelNormal>
                                    <LabelNormal fontSize="22" color="#3C4549">{treatment === 'smile_system' ? 'Smile System' : 'Cryst Aligner'}</LabelNormal>
                                </InlineContainer>
                                <div className='row'>
                                    <div className='col-md-5' style={{marginTop: 50}}>
                                        <LabelNormal style={{marginBottom: 29}} fontSize="24" color="#3C4549">Selecciona una fecha y hora</LabelNormal>
                                        <Calendar 
                                            onChange={setSchedule}
                                            onActiveStartDateChange={({ activeStartDate, value, view }) => setMonth(new Date(activeStartDate.toDateString()))}
                                            value={schedule}
                                            minDate={new Date()}
                                            tileDisabled={tileDisabled}
                                            calendarType="US"
                                        />
                                    </div>
                                    <div className='col-md-4' style={{marginTop: 120}}>
                                        <LabelNormal color="#3C4549" style={{marginBottom: 22}} fontSize="18.5">{moment(schedule).format('dddd, DD [de] MMMM').charAt(0).toUpperCase()+moment(schedule).format('dddd, DD [de] MMMM').slice(1)}</LabelNormal>
                                        {hours.map(h => (
                                            <ButtonHours key={Math.random()} onClick={() => setSelectHour(h)} style={selectHour === h ? { border: 'none', background: '#436FF1' } : {}}>
                                                <LabelNormal color={selectHour === h ? "#FFF" : "#EB9542"} fontSize="18">{moment(h, ["hh:mm a"]).format('hh:mm a')}</LabelNormal>
                                            </ButtonHours>
                                        ))}
                                    </div>
                                </div>
                                <ButtonPrime label="Confirmar Smart check" disabled={selectHour && schedule ? false : true} onClick={() => generateSmartcheck({ variables: { patient_id: id, start_date: `${moment(schedule).format('YYYY-MM-DD')} ${selectHour}:00`, finish_date: `${moment(schedule).format('YYYY-MM-DD')} ${moment(selectHour, 'HH:mm').add(1, 'hour').format('HH:mm')}:00` } })} style={{marginTop: 39}} />
                            </div>
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

export default ScheduleSmartcheck;