import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_STUDENTS_BY_UNIVERSITIES } from '@graphql/query';
import { useLazyQuery } from '@apollo/client';
import { loadPhotoProfileGender } from '../../utils';
import { Cap } from '@components/shared/ChipIcons';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const CardComponent = styled(Link)`
    text-decoration: none;
    border: none !important;
`;
const Container = styled.div`
    min-width: 262px;
    min-height: 125px;
    padding: 17px;
    background: #FFF;
    border-radius: 27px;
    box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
`;

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;

const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 30px;
`;

const TitleWithoutContent = styled.h1`
    font-family: neue-montreal-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #BDBDBD;
`;

const options = [
    {id: 'Con casos activos', name: 'Con casos activos'},
    {id: 'Sin casos', name: 'Sin casos'},
    {id: 'Desactivados', name: 'Desactivados'}
]

const ListStudents = (props) => {
    const history = useHistory();
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);

    const [selectedOption, setSelectedOption] = useState(['Con casos activos', 'Sin casos', 'Desactivados']);
    const [students, setStudents] = useState([]);
    const [search, setSearch] = useState('');

    const [getStudentByFilter] = useLazyQuery(QUERY_GET_STUDENTS_BY_UNIVERSITIES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.getStudentsByUniversities){
                setStudents(data.getStudentsByUniversities)
            }
            setLoadingSkeleton(false);
        }
    })

    useEffect(() => {
        setLoadingSkeleton(true);
        if(search !== ''){
            getStudentByFilter({ variables: { university_id: +localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
        }else{
            getStudentByFilter({ variables: { university_id: +localStorage.getItem('b360-role_id'), filters: selectedOption } });
        }
    },[selectedOption])

    useEffect(() => {
        setLoadingSkeleton(true);
        if(search !== ''){
            getStudentByFilter({ variables: { university_id: +localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
        }else{
            getStudentByFilter({ variables: { university_id: +localStorage.getItem('b360-role_id'), filters: selectedOption } });
        }
    },[search])

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="estudiantes" />
            <ContainHome>
                <Header style={{marginBottom: 30}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} />
                    </div>
                </Header>
                <ContainContent>
                    <InlineContainer style={{ justifyContent: 'space-between' }}>
                        <InlineContainer>
                            <InputSearch placeholder="Busca por nombre o ID" onChange={(e) => setSearch(e.target.value)} value={search} marginRight="33" />
                            <DropdownCustom label="Filtros" data={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                        </InlineContainer>
                    </InlineContainer>
                    <div className='bootstrap-wrapper' style={{marginTop: 43}}>
                    {!loadingSkeleton ?
                        <div className='row' style={students.length <= 0 ? {display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'} : { display: 'flex', gap: '20px', flex: 1}}>
                            {students.length > 0 ?
                            <>
                                {students.map(item => (
                                    <div style={{marginLeft: 15}} key={Math.random()}>
                                        <CardComponent to={`/universidad/estudiante/${item.id}`}>
                                            <Container style={props.style}>
                                                <InlineContainer style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                                    <img src={item.user.photo_filename ? `${process.env.REACT_APP_API_URL}/users/${item.user.id}/profile/${item.user.photo_filename}` : loadPhotoProfileGender(item.user.gender)} style={{height: 70, width: 70, borderRadius: 74.38, objectFit: 'cover'}} />
                                                    <Cap color="#C8C8C8" />
                                                </InlineContainer>
                                                <div style={{marginTop: 14}}>
                                                    <LabelNormal style={{color: '#3C4549', fontSize: 15}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                                    <InlineContainer style={{alignContent: 'center', justifyContent: 'space-between'}}>
                                                        <InlineContainer>
                                                            <LabelNormal color="#BDBDBD" fontSize="11" style={{letterSpacing: '0.11em', fontWeight: '400'}}>ACTIVOS </LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="12">{item.count_total_treatments}</LabelNormal>
                                                        </InlineContainer>
                                                        <InlineContainer>
                                                            <LabelNormal color="#BDBDBD" fontSize="11" style={{letterSpacing: '0.11em', fontWeight: '400'}}>TERMINADOS </LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="12">{item.count_inactives_treatments}</LabelNormal>
                                                        </InlineContainer>
                                                    </InlineContainer>
                                                </div>
                                            </Container>
                                        </CardComponent>
                                    </div>
                                ))}
                            </>
                            : 
                            <div style={{}}>
                                <TitleWithoutContent>Aún no hay ningún<br/> estudiante registrado</TitleWithoutContent>
                            </div>
                            }
                        </div>
                        :
                            <div>
                                <div style={{display: 'flex'}}>
                                    {Array.from(Array(5).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 0px'}}>
                                            <Skeleton height={128} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>

                                <div style={{display: 'flex', marginTop: 16}}>
                                    {Array.from(Array(5).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 0px'}}>
                                            <Skeleton height={128} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                                <div style={{display: 'flex', marginTop: 16}}>
                                    {Array.from(Array(5).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 0px'}}>
                                            <Skeleton height={128} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                                <div style={{display: 'flex', marginTop: 16}}>
                                    {Array.from(Array(5).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 0px'}}>
                                            <Skeleton height={128} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

export default ListStudents;