import styled from 'styled-components';

const ButtonStyle = styled.button`
  padding: 7px 15px;
  border-radius: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  width: ${({ width }) => width ?? '100%'};
  font-family: 'Neue Montreal';
  font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
  letter-spacing: 0.3px;
  word-wrap: break-word;
  color: white;
  background: #eb9542;
  &:hover:enabled {
    background: #f2b559;
    color: white;
  }
  &:disabled {
    color: #828282;
    border: 2px #828282 solid;
    background: transparent;
    cursor: not-allowed;
  }
`;

const ButtonOutlineStyle = styled.button`
  padding: ${({ padding }) => padding ?? '7px 15px'};
  border-radius: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  width: ${({ width }) => width ?? '100%'};
  font-family: 'Neue Montreal';
  font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
  letter-spacing: 0.3px;
  word-wrap: break-word;
  color: #3c4549;
  background: transparent;
  border: 2px solid #3c4549;
  &:hover:enabled {
    background: #3c4549;
    color: white;
  }
  &:active:enabled {
    background: #4f4f4f;
    color: white;
  }
  &:disabled {
    color: #828282;
    border: 2px #828282 solid;
    background: transparent;
    cursor: not-allowed;
  }
`;

const ButtonSecondary = (props) => {
  if (props.outline) {
    return (
      <ButtonOutlineStyle {...props}>
        {props.label ?? props.children}
      </ButtonOutlineStyle>
    );
  }
  return <ButtonStyle {...props}>{props.label ?? props.children}</ButtonStyle>;
};

export default ButtonSecondary;
