import styled from 'styled-components';
import Breadcrumb from '@components/Navigation/Breadcrumb/Breadcrumb';
import ButtonPrime from '@components/shared/ButtonPrime';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

export const PlanningReviewHeader = ({
  edit,
  firstName,
  history,
  lastName,
  location,
  onClickCancel,
  progressUpload,
  sendPlanning,
  setEdit,
  software,
  statusTreatment,
  treatment,
}) => {
  return (
    <div className="flex flex-row justify-between max-w-[1145px] w-full">
      <div className="flex flex-col">
        <Title>Planificación</Title>
        <div style={{ marginTop: 10, marginBottom: 39 }}>
          <Breadcrumb
            elements={2}
            onClick={() => history.goBack()}
            mainLabel={'Caso'}
            midLabel={`${firstName} ${lastName}`}
            onSecondClick={() => history.goBack()}
            endLabel={'Planificación'}
          />
          {treatment === 'smile_system' &&
          statusTreatment.type === 'planning' &&
          statusTreatment.index !== 11 ? (
            <LabelNormal color="#757575" fontSize="17">
              Esta información es la que se le muestra al doctor en el visor del
              caso.
            </LabelNormal>
          ) : (
            <LabelNormal color="#757575" fontSize="17">
              Edita el link de video del caso.
            </LabelNormal>
          )}

          {location.state.smart && treatment === 'cryst_aligner' && (
            <LabelNormal color="#757575" fontSize="17">
              Esta información es la que se le muestra al doctor en el visor del
              caso.
            </LabelNormal>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {!edit ? (
          <ButtonPrime
            background="transparent"
            border="1px solid #3C4549"
            icon="edit"
            label={
              software === 'Nemocast' ? 'Editar planificación' : 'Hacer cambios'
            }
            color="#3C4549"
            onClick={() => setEdit(true)}
          />
        ) : (
          <InlineContainer style={{ alignItems: 'center', gap: '24px' }}>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
                marginRight: 10,
              }}
              onClick={() => onClickCancel()}
            >
              Cancelar
            </LabelNormal>
            <ButtonPrime
              disabled={progressUpload > 0 ? true : false}
              label="Guardar cambios"
              onClick={() => sendPlanning()}
            />
          </InlineContainer>
        )}
      </div>
    </div>
  );
};
