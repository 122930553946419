import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Input from '@components/shared/Input';
import Checkbox from 'react-custom-checkbox';
import ButtonPrime from '@components/shared/ButtonPrime';
import LabelUnderline from '../../components/shared/LabelUnderline';
import ButtonFile from '../../components/shared/ButtonFile';
import Select from '@components/shared/Select';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_REGISTER_PATIENT,
  MUTATION_UPDATE_PATIENT,
  MUTATION_UPLOAD_PATIENT_PHOTO,
  MUTATION_SELECT_TREATMENT_BRACKETS,
  MUTATION_SET_SHIPPING_TREATMENT,
  MUTATION_CHANGE_DEFAULT_TAX_DATA,
  MUTATION_CHANGE_DEFAULT_ADDRESS,
  MUTATION_CHANGE_DEFAULT_CARD,
  MUTATION_PAY_PLANNING_TREATMENT,
  MUTATION_PAY_MANUFACTURE_TREATMENT,
  MUTATION_ADD_URL_STL,
  MUTATION_DELETE_EXTRA_STL,
  MUTATION_ASSIGN_CASE_TEACHER,
} from '@graphql/mutation';
import {
  QUERY_GET_PATIENT,
  QUERY_DEFAULT_ADDRESS_DATA,
  QUERY_GET_DETAILS_DOCTOR,
  QUERY_GET_DEFAULT_TAX_DATA,
  QUERY_GET_DEFAULT_PAYMENT,
  QUERY_GET_PRICE_BY_TYPE,
  QUERY_GET_PACK,
  QUERY_GET_PAYMENT_INSTALLMENTS,
  QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
  QUERY_GET_MESSAGES_CHAT,
  QUERY_GET_TEACHERS_BY_UNIVERSITIES,
} from '@graphql/query';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import ModalFormTaxData from '@components/Modal/ModalFormTaxData';
import ModalFormAddress from '@components/Modal/ModalFormAddress';
import ModalFormCard from '@components/Modal/ModalFormCard';
import { modal } from '@components/ModalManagement';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import { validateURL, loadPhotoProfileGender } from '../../utils';
import FormCA from '@components/DoctorFormCA';
import FormSS from '@components/DoctorFormSS';
import axios from 'axios';
import ModalError from '../../components/Modal/ModalError';
import ResumeSS from '../../components/DoctorFormSS/resume';
import ResumeCA from '../../components/DoctorFormCA/resume';
import Warning from '../../components/CaseForm/Warning';
import TransferInfo from '../../components/CaseForm/TransferInfo';
import Check from '../../components/Icons/Check';
import {
  Column,
  LabelDescription,
  LabelInput,
  LabelOrange,
  Row,
} from '../../components/shared/Styled';
import UploadFile from '../../components/NewCase/Files/UploadFile';
import NewCaseHeader from '@components/NewCase/Header';
import Stepper from '../../components/NewCase/Stepper';
import PatientInfo from '../../components/NewCase/PatientInfo';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import CouponForm from '@components/CouponForm/CouponForm';

const itemSteps = [
  { title: 'Paciente', name: 1 },
  { title: 'Formulario', name: 2 },
  { title: 'Fotografías', name: 3 },
  { title: `STL’s`, name: 4 },
  { title: 'Radiografías', name: 5 },
  { title: 'Resumen', name: 6 },
  { title: 'Pago', name: 7 },
];

const itemStepsSS = [
  { title: 'Paciente', name: 1 },
  { title: 'Formulario', name: 2 },
  { title: 'Fotografías', name: 3 },
  { title: `STL’s`, name: 4 },
  { title: 'Resumen', name: 5 },
  { title: 'Pago', name: 6 },
];

const itemStepsRefinement = [
  { title: 'Paciente', name: 1 },
  { title: 'Formulario', name: 2 },
  { title: 'Fotografías', name: 3 },
  { title: `STL’s`, name: 4 },
  { title: 'Radiografías', name: 5 },
  { title: 'Resumen', name: 6 },
];

const NewCases = (props) => {
  const history = useHistory();
  let { patient_id } = useParams();

  const [steps, setSteps] = useState(1);
  const [subSteps, setSubStep] = useState(0);
  const [lastStep, setLastStep] = useState(1);
  const [saved, setSaved] = useState(true);
  const [changePayment, setChangePayment] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [transferSend, setTransferSend] = useState(false);
  const [transferError, setTransferError] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [link_data, setLinkData] = useState(['']);
  const [treatmentId, setTreatmentId] = useState('');
  const [bankName, setBankName] = useState('');
  const [clabe, setClabe] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('payment_line');

  const [typePrice, setTypePrice] = useState({});
  const [originalTypePrice, setOriginalTypePrice] = useState({});
  const [total, setTotal] = useState(0);
  const [types, setTypes] = useState([]);
  const [packType, setPackType] = useState(null);
  const [paymentInstallments, setPaymentInstallments] = useState({});
  const [doctorData, setDoctorData] = useState(null);
  const [cards, setCards] = useState([]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [treatment, setTreatment] = useState('cryst_aligner');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState('');
  const [filePhoto, setFilePhoto] = useState({});

  const [more, setMore] = useState(true);
  const [selectItem, setSelectItem] = useState('Formulario');
  const [selectInstallment, setSelectInstallment] = useState({});

  const [patientId, setPatientId] = useState(null);

  const [brackets, setBrackets] = useState(null);

  const [photoUploadError, setPhotoUploadError] = useState(false);

  const [filesTreatment, setFilesTreatment] = useState({});
  const [filesTreatmentExtraorales, setFilesTreatmentExtraorales] = useState(
    {}
  );
  const [filesTreatmentStl, setFilesTreatmentSlt] = useState({});
  const [filesTreatmentRadiografia, setFilesTreatmentRadiografia] = useState(
    {}
  );

  const [radioStl, setRadioStl] = useState('');
  const [oclusionStl, setOclusionStl] = useState('');

  const [defaultAddress, setDefaultAddress] = useState({});
  const [defaultAddressDoctor, setDefaultAddressDoctor] = useState({});
  const [defaultTaxData, setDefaultTaxData] = useState({});
  const [defaultPayment, setDefaultPayment] = useState({});

  const [loading, setLoading] = useState(false);

  const [formCAData, setFormCAData] = useState({});
  const [formSSData, setFormSSData] = useState({});

  const [validateOnIntraoral, setValidateOnIntraoral] = useState(true);

  const [validateOnExtraoral, setValidateOnExtraoral] = useState(true);

  const [couponId, setCouponId] = useState(null);
  const [conversionToMXN, setConversionToMXN] = useState(0);
  const [patientTreatment, setPatient] = useState(null);
  const [treatmentType, setTreatmentType] = useState(false);
  const [usedAligners, setUsedAligners] = useState(0);
  const [teacherSelect, setTeacherSelect] = useState(null);
  const [universityId, setUniversityId] = useState(null);
  const [allTeachers, setAllTeachers] = useState([]);

  // Refactoring
  const [currentSteps, setCurrentSteps] = useState(itemSteps);
  const [showAddressError, setShowAddressError] = useState(false);
  const [selectedPatientData, setSelectedPatientData] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [resetCoupon, setResetCoupon] = useState(false);
  const [firstPaymentInstallment, setFirstPaymentInstallment] = useState(0);
  const [loadingPaymentInstallment, setLoadingPaymentInstallment] =
    useState(false);

  const [getPatientBasicData] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;
        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setGender(patient.gender);
        setEmail(patient.email !== null ? patient.email : '');
        setPhone(patient.phone);
        let date = patient.birthdate.split('-');
        setYear(date[0]);
        setMonth(date[1]);
        setDay(date[2]);
        if (patient.photo_filename !== null) {
          setPhoto(
            `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
          );
        }
        setUsedAligners(patient.treatment_related.aligners_number);
      }
    },
  });

  useEffect(() => {
    getPatientBasicData({ variables: { id: patient_id } });
  }, [patient_id]);

  // #region Queries
  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;
        setPatient(patient);

        let up_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        let lo_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        if (
          patient.treatment_related.formOdontogram &&
          patient.treatment_related.formOdontogram.up_left_second_molar_data &&
          patient.treatment_related.formOdontogram.type !== 'Sin cambios'
        ) {
          const odontograma = patient.treatment_related.formOdontogram;
          up_odontograma = [
            JSON.parse(odontograma.up_left_third_molar_data),
            JSON.parse(odontograma.up_left_second_molar_data),
            JSON.parse(odontograma.up_left_first_molar_data),
            JSON.parse(odontograma.up_left_second_premolar_data),
            JSON.parse(odontograma.up_left_first_premolar_data),
            JSON.parse(odontograma.up_left_canine_data),
            JSON.parse(odontograma.up_left_lateral_incisor_data),
            JSON.parse(odontograma.up_left_central_incisor_data),
            JSON.parse(odontograma.up_right_third_molar_data),
            JSON.parse(odontograma.up_right_second_molar_data),
            JSON.parse(odontograma.up_right_first_molar_data),
            JSON.parse(odontograma.up_right_second_premolar_data),
            JSON.parse(odontograma.up_right_first_premolar_data),
            JSON.parse(odontograma.up_right_canine_data),
            JSON.parse(odontograma.up_right_lateral_incisor_data),
            JSON.parse(odontograma.up_right_central_incisor_data),
          ];
          lo_odontograma = [
            JSON.parse(odontograma.lo_left_third_molar_data),
            JSON.parse(odontograma.lo_left_second_molar_data),
            JSON.parse(odontograma.lo_left_first_molar_data),
            JSON.parse(odontograma.lo_left_second_premolar_data),
            JSON.parse(odontograma.lo_left_first_premolar_data),
            JSON.parse(odontograma.lo_left_canine_data),
            JSON.parse(odontograma.lo_left_lateral_incisor_data),
            JSON.parse(odontograma.lo_left_central_incisor_data),
            JSON.parse(odontograma.lo_right_third_molar_data),
            JSON.parse(odontograma.lo_right_second_molar_data),
            JSON.parse(odontograma.lo_right_first_molar_data),
            JSON.parse(odontograma.lo_right_second_premolar_data),
            JSON.parse(odontograma.lo_right_first_premolar_data),
            JSON.parse(odontograma.lo_right_canine_data),
            JSON.parse(odontograma.lo_right_lateral_incisor_data),
            JSON.parse(odontograma.lo_right_central_incisor_data),
          ];
        }

        setFormCAData({
          arcad: patient.treatment_related.formArcade,
          shape: patient.treatment_related.formShape,
          ipr: patient.treatment_related.formIpr,
          odontograma: patient.treatment_related.formOdontogram,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });
        setFormSSData({
          bracket_ss: patient.treatment_related.bracket_ss,
          odontograma: patient.treatment_related.formOdontogram,
          brackets: patient.treatment_related.formBracket,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setGender(patient.gender);
        setTreatment(patient.treatment);
        setRadioStl(
          patient.treatment_related.stl_type === 'file'
            ? 'archivos'
            : patient.treatment_related.stl_type === 'url'
            ? 'url'
            : patient.treatment_related.stl_type === 'shipping'
            ? 'model'
            : ''
        );
        setEmail(patient.email !== null ? patient.email : '');
        setPhone(patient.phone);
        let date = patient.birthdate.split('-');
        setYear(date[0]);
        setMonth(date[1]);
        setDay(date[2]);
        if (patient.photo_filename !== null) {
          setPhoto(
            `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
          );
        }
        if (patient.treatment_related.bracket_ss) {
          setBrackets(patient.treatment_related.bracket_ss);
        }
        if (patient.treatment_related.stl_type !== null) {
          if (patient.treatment_related.stl_type === 'url') {
            // setSendLinkStl(true);
            setLinkData(
              patient.treatment_related.link_data
                ? JSON.parse(patient.treatment_related.link_data)
                : ['']
            );
          } else if (patient.treatment_related.stl_type === 'shipping') {
            // setSendModelStl(true);
          }
        }
        if (
          patient.treatment_related &&
          patient.treatment_related.files.length > 0
        ) {
          // let formFile = patient.treatment_related.files.find(
          //   (f) => f.name === 'patient_form'
          // );
          // if (formFile) setFilePatient({ name: formFile.file_name });
          let oclusalSuperior = patient.treatment_related.files.find(
            (f) => f.name === 'oclusal_superior'
          );
          let object = {};
          if (oclusalSuperior) {
            object = {
              ...object,
              superior: {
                file: { name: oclusalSuperior.file_name },
                preview: oclusalSuperior.fileUrl,
                error: false,
              },
            };
          }
          let oclusalInferior = patient.treatment_related.files.find(
            (f) => f.name === 'oclusal_inferior'
          );
          if (oclusalInferior) {
            object = {
              ...object,
              inferior: {
                file: { name: oclusalInferior.file_name },
                preview: oclusalInferior.fileUrl,
                error: false,
              },
            };
          }
          let latIzqOclusion = patient.treatment_related.files.find(
            (f) => f.name === 'lat_izq_oclusion'
          );
          if (latIzqOclusion) {
            object = {
              ...object,
              lateral_izquierda: {
                file: { name: latIzqOclusion.file_name },
                preview: latIzqOclusion.fileUrl,
                error: false,
              },
            };
          }
          let frontal = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_oclusion'
          );
          if (frontal) {
            object = {
              ...object,
              frontal: {
                file: { name: frontal.file_name },
                preview: frontal.fileUrl,
                error: false,
              },
            };
          }
          let latDerOclusion = patient.treatment_related.files.find(
            (f) => f.name === 'lat_der_oclusion'
          );
          if (latDerOclusion) {
            object = {
              ...object,
              lateral_derecha: {
                file: { name: latDerOclusion.file_name },
                preview: latDerOclusion.fileUrl,
                error: false,
              },
            };
          }
          setFilesTreatment({
            ...filesTreatment,
            ...object,
          });
          let objectExtraorales = {};
          let serio = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_serio'
          );
          if (serio) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_serio: {
                file: { name: serio.file_name },
                preview: serio.fileUrl,
                error: false,
              },
            };
          }
          let sonriendo = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_sonriendo'
          );
          if (sonriendo) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_sonriendo: {
                file: { name: sonriendo.file_name },
                preview: sonriendo.fileUrl,
                error: false,
              },
            };
          }
          let frontal_retractore = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_retractores'
          );
          if (frontal_retractore) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_retractore: {
                file: { name: frontal_retractore.file_name },
                preview: frontal_retractore.fileUrl,
                error: false,
              },
            };
          }
          let lateral_izquierdo = patient.treatment_related.files.find(
            (f) => f.name === 'lateral_izq_serio'
          );
          if (lateral_izquierdo) {
            objectExtraorales = {
              ...objectExtraorales,
              lateral_izquierda: {
                file: { name: lateral_izquierdo.file_name },
                preview: lateral_izquierdo.fileUrl,
                error: false,
              },
            };
          }
          let lateral_derecho = patient.treatment_related.files.find(
            (f) => f.name === 'lateral_der_serio'
          );
          if (lateral_derecho) {
            objectExtraorales = {
              ...objectExtraorales,
              lateral_derecha: {
                file: { name: lateral_derecho.file_name },
                preview: lateral_derecho.fileUrl,
                error: false,
              },
            };
          }
          setFilesTreatmentExtraorales({
            ...filesTreatmentExtraorales,
            ...objectExtraorales,
          });
          let objectStls = {};
          let arcada_superior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_superior'
          );
          if (arcada_superior) {
            objectStls = {
              ...objectStls,
              arcada_superior: {
                file: { name: arcada_superior.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion'
          );
          if (oclusion) {
            objectStls = {
              ...objectStls,
              oclusion: {
                file: { name: oclusion.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion_derecha = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_derecha'
          );
          if (oclusion_derecha) {
            objectStls = {
              ...objectStls,
              oclusion_derecha: {
                file: { name: oclusion_derecha.file_name },
                preview: require('@images/files/stl.jpg'),
                error: false,
              },
            };
          }
          let oclusion_izquierda = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_izquierda'
          );
          if (oclusion_izquierda) {
            objectStls = {
              ...objectStls,
              oclusion_izquierda: {
                file: { name: oclusion_izquierda.file_name },
                preview: require('@images/files/stl.jpg'),
                error: false,
              },
            };
          }
          let arcada_inferior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_inferior'
          );
          if (arcada_inferior) {
            objectStls = {
              ...objectStls,
              arcada_inferior: {
                file: { name: arcada_inferior.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          if (
            oclusion_izquierda &&
            oclusion_derecha &&
            arcada_inferior &&
            arcada_superior
          ) {
            setOclusionStl('si');
          } else {
            setOclusionStl('no');
          }

          setFilesTreatmentSlt({
            ...filesTreatmentStl,
            ...objectStls,
          });
          let radiografiasObject = {};
          let radiografia_lateral = patient.treatment_related.files.find(
            (f) => f.name === 'lateral'
          );
          if (radiografia_lateral) {
            radiografiasObject = {
              ...radiografiasObject,
              lateral: {
                file: { name: radiografia_lateral.file_name },
                preview: radiografia_lateral.fileUrl,
                error: false,
              },
            };
          }
          let radiografia_panoramica = patient.treatment_related.files.find(
            (f) => f.name === 'panoramica'
          );
          if (radiografia_panoramica) {
            radiografiasObject = {
              ...radiografiasObject,
              panoramica: {
                file: { name: radiografia_panoramica.file_name },
                preview: radiografia_panoramica.fileUrl,
                error: false,
              },
            };
          }
          let trazado_cefalometrico = patient.treatment_related.files.find(
            (f) => f.name === 'trazado_cefalometrico'
          );
          if (trazado_cefalometrico) {
            radiografiasObject = {
              ...radiografiasObject,
              trazado_cefalometrico: {
                file: { name: trazado_cefalometrico.file_name },
                preview: trazado_cefalometrico.fileUrl,
                error: false,
              },
            };
          }
          let tomografia = patient.treatment_related.files.find(
            (f) => f.name === 'tomografia'
          );
          if (tomografia) {
            radiografiasObject = {
              ...radiografiasObject,
              tomografia: {
                file: { name: tomografia.file_name },
                preview: require('@images/patient/zip-upload.png'),
                error: false,
              },
            };
          }
          setFilesTreatmentRadiografia({
            ...filesTreatmentRadiografia,
            ...radiografiasObject,
          });
        }
      }
    },
  });

  const [chatMessages, setChatMessages] = useState([]);

  const [getChatHisotry] = useLazyQuery(QUERY_GET_MESSAGES_CHAT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getChatMessages) {
        setChatMessages(data.getChatMessages.chatMessages);
      }
    },
    onError: (error) => {
      console.log(error, 'error de chat message');
    },
  });

  useEffect(() => {
    if (treatmentId)
      getChatHisotry({
        variables: {
          channel_url: `${
            process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
          }private_chat_treatment_${treatmentId}`,
        },
      });
  }, [treatmentId]);

  const [getPaymentInstallments] = useLazyQuery(
    QUERY_GET_PAYMENT_INSTALLMENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setLoadingPaymentInstallment(false);
        if (data.getPaymentInstallments) {
          if (discount > 0 && data.getPaymentInstallments.installments.length > 0) {
            setFirstPaymentInstallment(originalTypePrice.amount);
          } else {
            setPaymentInstallments(data.getPaymentInstallments);
            if (data.getPaymentInstallments.installments.length === 0) {
              setSelectInstallment({});
            }
          }
        }
      },
    }
  );

  const [getTeachersByFilter] = useLazyQuery(
    QUERY_GET_TEACHERS_BY_UNIVERSITIES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data.getTeachersByUniversities) {
          setAllTeachers(
            data.getTeachersByUniversities.map((t) => ({
              id: t.id,
              name: `${t.user.first_name} ${t.user.last_name}`,
            }))
          );
        }
      },
    }
  );

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        setDoctorData(data.doctor);
        setCards(data.doctor?.cards ?? []);
        setUniversityId(data.doctor.university.id ?? null);
        if (data.doctor.user?.type === 'student' && data.doctor.university) {
          getTeachersByFilter({
            variables: {
              university_id: parseInt(data.doctor.university.id),
              filters: [],
            },
          });
        }
        // if(patientId)
        //     getDefaultAddress({ variables: { user_id: localStorage.getItem('b360-id'), patient_id: patientId } });
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
        getDefaultAddressDoctor({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
      }
    },
  });

  useEffect(() => {
    if (defaultAddressDoctor.name) {
      setShowAddressError(false);
    }
  }, [defaultAddressDoctor]);

  const [getDefaultAddress] = useLazyQuery(QUERY_DEFAULT_ADDRESS_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getDefaultAddressData) {
        setDefaultAddress(data.getDefaultAddressData.defaultAddress);
      }
    },
    onError: (err) => {
      console.log(err, 'error address');
    },
  });

  const [getDefaultTaxData] = useLazyQuery(QUERY_GET_DEFAULT_TAX_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getDefaultTaxData) {
        setDefaultTaxData(data.getDefaultTaxData);
      }
    },
    onError: (err) => console.log(err, 'error taxdata'),
  });

  const [getDefaultAddressDoctor] = useLazyQuery(
    QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.getDefaultAddress) {
          setDefaultAddressDoctor(data.getDefaultAddress);
        }
      },
      onError: (err) => console.log(err, 'error default address doctor'),
    }
  );

  const [getDefaultMethodPayment] = useLazyQuery(QUERY_GET_DEFAULT_PAYMENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getDefaultPaymentMethod) {
        setDefaultPayment(data.getDefaultPaymentMethod);
      }
    },
    onError: (err) => console.log(err, 'error method payment'),
  });

  const [getTypePrice] = useLazyQuery(QUERY_GET_PRICE_BY_TYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTotal(data.getPriceByType.amount);
      setFirstPaymentInstallment(data.getPriceByType.amount);
      setTypePrice(data.getPriceByType);
      setOriginalTypePrice(data.getPriceByType);

      if (data.getPriceByType.currency === 'USD') {
        conversionUsdToMXN();
      }
    },
  });

  const [getPack] = useLazyQuery(QUERY_GET_PACK, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTypes(data.getPacksByType);
    },
  });

  const conversionUsdToMXN = async () => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await instance.get('/api/exchange/currency');
    const data = response.data;
    setConversionToMXN(data.exchangeRate);
  };

  // #endregion

  // #region Mutations

  const [payPlanningTreatment] = useMutation(MUTATION_PAY_PLANNING_TREATMENT, {
    onCompleted: (data) => {
      if (data.payPlanningTreatment) {
        if (doctorData.is_doctor) {
          localStorage.removeItem('Patient');
          localStorage.removeItem('lastStep');
          modal.close();
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.push(`/doctor/caso/${patientId}`))
              }
            >
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Nuevo caso agregado con éxito
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Revisaremos tu caso para asegurarnos de que no existan errores
                en los archivos o dudas en tu planeación. Te notificaremos
                cuando puedas revisarla. Este proceso dura aproximadamente 1 día
                hábil.
              </LabelNormal>
            </ModalSuccess>
          );
        } else if (paymentMethod === 'payment_line') {
          localStorage.removeItem('Patient');
          localStorage.removeItem('lastStep');
          modal.close();
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.push(`/doctor/caso/${patientId}`))
              }
            >
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                {total - discount > 0
                  ? 'Pago con código realizado y nuevo caso agregado con éxito'
                  : 'Pago realizado y nuevo caso agregado con éxito'}
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Revisaremos tu caso para asegurarnos de que no existan errores
                en los archivos o dudas en tu planeación. Te notificaremos
                cuando puedas revisarla. Este proceso dura aproximadamente 1 día
                hábil.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          setLoadingTransfer(false);
          setTransferSend(true);
          if (
            data.payPlanningTreatment.bank_code !== '' &&
            data.payPlanningTreatment.bank_name !== '' &&
            data.payPlanningTreatment.clabe !== '' &&
            data.payPlanningTreatment.referenceCode !== ''
          ) {
            setBankName(data.payPlanningTreatment.bank_name);
            setClabe(data.payPlanningTreatment.clabe);
            setReferenceCode(data.payPlanningTreatment.referenceCode);
          } else {
            setTransferError(true);
          }
        }
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      let errorMessage = '';
      if (graphQLErrors?.length > 0) {
        errorMessage = graphQLErrors[0].message;
      } else if (networkError)
        errorMessage =
          'Lo sentimos, no se puede procesar la transacción debido a una falla en tu conexión. Por favor, verifícala e inténtalo de nuevo.';
      modal.close();
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
          >
            Pago no procesado
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Hubo un error al procesar tu tarjeta. Verifica tus datos e inténtalo
            de nuevo.
          </LabelNormal>
          <div
            style={{
              backgroundColor: '#FFE5E3',
              padding: '15px',
              borderRadius: 13,
              color: '#C60E02',
            }}
          >
            <p style={{ fontWeight: '600' }}>Error de sistema:</p>
            <p style={{ fontWeight: '600' }}>{errorMessage}</p>
          </div>
        </ModalError>
      );
    },
  });

  const [payManufactureTreatment] = useMutation(
    MUTATION_PAY_MANUFACTURE_TREATMENT,
    {
      onCompleted: (data) => {
        if (data.payManufactureTreatment) {
          if (paymentMethod === 'payment_line') {
            localStorage.removeItem('Patient');
            localStorage.removeItem('lastStep');
            let points = data.payManufactureTreatment.points;

            let tratamiento = packType?.name.split('+')[1];
            if (treatment === 'cryst_aligner') {
              if (
                patientTreatment.treatment_related.aligners_number >= 0 &&
                patientTreatment.treatment_related.aligners_number <= 28
              ) {
                tratamiento = 'Terso';
              } else if (
                patientTreatment.treatment_related.aligners_number <= 50
              ) {
                tratamiento = 'Moderado';
              } else {
                tratamiento = 'Integral';
              }
            }

            modal.close();
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.push(`/doctor/caso/${patientId}`))
                }
              >
                <LabelNormal
                  color="#060809"
                  fontSize="22"
                  style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
                >
                  {total - discount > 0
                    ? 'Pago con código realizado y nuevo caso agregado con éxito'
                    : 'Pago realizado y nuevo caso agregado con éxito'}
                </LabelNormal>
                <LabelNormal
                  color="#4F4F4F"
                  fontSize="17"
                  style={{ marginBottom: 23 }}
                >
                  Revisaremos tu caso para asegurarnos de que no existan errores
                  en los archivos o dudas en tu planeación. Te notificaremos
                  cuando puedas revisarla. Este proceso dura aproximadamente 1
                  día hábil.
                </LabelNormal>
                {doctorData?.user?.type !== 'student' && (
                  <div
                    style={{
                      backgroundColor: '#F6F6F8',
                      padding: '9px 15px',
                      borderRadius: 15,
                    }}
                  >
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginBottom: 5 }}
                    >
                      Acumulaste puntos por el pago de manufactura de{' '}
                      <bold>{tratamiento}</bold>
                    </LabelNormal>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: 22,
                      }}
                    >
                      <LabelNormal color="#EB9542" fontSize="13">
                        PUNTOS ACUMULADOS
                      </LabelNormal>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ fontWeight: 500 }}
                      >
                        {points}
                      </LabelNormal>
                    </div>
                    <a a href={'/doctor/beneficios'}>
                      <ButtonPrime
                        style={{ padding: '12px 25px' }}
                        fontSize={19}
                        label="Ver puntos"
                      />
                    </a>
                  </div>
                )}
              </ModalSuccess>
            );
          } else {
            setLoadingTransfer(false);
            setTransferSend(true);
            if (
              data.payManufactureTreatment.bank_code !== '' &&
              data.payManufactureTreatment.bank_name !== '' &&
              data.payManufactureTreatment.clabe !== '' &&
              data.payManufactureTreatment.referenceCode !== ''
            ) {
              setBankName(data.payManufactureTreatment.bank_name);
              setClabe(data.payManufactureTreatment.clabe);
              setReferenceCode(data.payManufactureTreatment.referenceCode);
            } else {
              setTransferError(true);
            }
          }
        }
      },
      onError: ({ graphQLErrors, networkError }) => {
        let errorMessage = '';
        if (graphQLErrors?.length > 0) {
          errorMessage = graphQLErrors[0].message;
        } else if (networkError)
          errorMessage =
            'Lo sentimos, no se puede procesar la transacción debido a una falla en tu conexión. Por favor, verifícala e inténtalo de nuevo.';
        modal.close();
        modal.open(
          <ModalError onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
            >
              Pago no procesado
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Hubo un error al procesar tu tarjeta. Verifica tus datos e
              inténtalo de nuevo.
            </LabelNormal>
            <div
              style={{
                backgroundColor: '#FFE5E3',
                padding: '15px',
                borderRadius: 13,
                color: '#C60E02',
              }}
            >
              <p style={{ fontWeight: '600' }}>Error de sistema:</p>
              <p style={{ fontWeight: '600' }}>{errorMessage}</p>
            </div>
          </ModalError>
        );
      },
    }
  );

  const [addSTLURL] = useMutation(MUTATION_ADD_URL_STL, {
    onCompleted: (data) => {
      if (data) {
        setSubStep(0);
        setSteps(5);
      }
    },
  });

  const [registerPatient] = useMutation(MUTATION_REGISTER_PATIENT, {
    onCompleted: (data) => {
      if (data.registerPatient) {
        setPatientId(+data.registerPatient.patient_id);
        setTreatmentId(data.registerPatient.treatment_id);
        const patient = {
          id: data.registerPatient.patient_id,
          treatment_id: data.registerPatient.treatment_id,
        };
        localStorage.setItem('Patient', JSON.stringify(patient));
        setSelectedPatientData({
          id: patient.id,
          name: `${firstName} ${lastName}`,
        });
        if (filePhoto.name)
          uploadPhotoPatient({
            variables: {
              patient_id: +data.registerPatient.patient_id,
              file: filePhoto,
            },
            context: {
              fetchOptions: {
                onUploadProgress: (progress) => {
                  console.log(progress);
                },
              },
            },
          });
        else {
          setSaved(true);
          setSteps(2);
        }
      }
    },
    onError: (err) => {
      console.log(err);
      setPhotoUploadError(true);
    },
  });

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: (data) => {
      if (data.updatePatient) {
        setSelectedPatientData({
          id: patientId,
          name: `${firstName} ${lastName}`,
        });
        if (filePhoto.name)
          uploadPhotoPatient({
            variables: { patient_id: patientId, file: filePhoto },
            context: {
              fetchOptions: {
                onUploadProgress: (progress) => {
                  console.log(progress);
                },
              },
            },
          });
        else {
          setSaved(true);
          setSteps(2);
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [uploadPhotoPatient] = useMutation(MUTATION_UPLOAD_PATIENT_PHOTO, {
    onCompleted: (data) => {
      if (data.addPatientProfilePhoto) {
        setSaved(true);
        setSteps(2);
      }
    },
    onError: (err) => console.log(err),
  });

  const [selectBrackets] = useMutation(MUTATION_SELECT_TREATMENT_BRACKETS);

  const [setShipping] = useMutation(MUTATION_SET_SHIPPING_TREATMENT, {
    onCompleted: (data) => {
      if (data) {
        setSubStep(0);
        setSteps(5);
      }
    },
  });

  const [changeDefaultTaxdata] = useMutation(MUTATION_CHANGE_DEFAULT_TAX_DATA, {
    onCompleted: (data) => {
      if (data.updateTaxDataDefault) {
        // getDoctorDetails({ variables: { id: localStorage.getItem('b360-role_id') } })
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
      }
    },
  });

  const [changeDefaultAddressDoctor] = useMutation(
    MUTATION_CHANGE_DEFAULT_ADDRESS,
    {
      onCompleted: (data) => {
        if (data.updateAddressDefault) {
          getDefaultAddressDoctor({
            variables: { user_id: localStorage.getItem('b360-id') },
          });
        }
      },
    }
  );

  const [changeDefaultCard] = useMutation(MUTATION_CHANGE_DEFAULT_CARD, {
    onCompleted: (data) => {
      if (data.updatePaymentDefault) {
        setDefaultPayment(data.updatePaymentDefault);
        let cardsAux = [];
        cards.forEach((card) => {
          if (card.service_id === data.updatePaymentDefault.service_id) {
            cardsAux.push({
              ...card,
              default: true,
            });
          } else {
            cardsAux.push({
              ...card,
              default: false,
            });
          }
        });
        setDoctorData({
          ...doctorData,
          cards: cardsAux,
        });
      }
    },
  });

  const [errorPayment, setErrorPayment] = useState(false);

  const [deleteExtraStlOnChange] = useMutation(MUTATION_DELETE_EXTRA_STL, {
    onCompleted: (data) => {
      if (data.deleteExtraStlFile) {
      }
    },
  });

  const [assignTeacherCase] = useMutation(MUTATION_ASSIGN_CASE_TEACHER, {
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => console.log(error),
  });

  const [getPatientRefresh] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        let up_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        let lo_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        if (
          patient.treatment_related.formOdontogram &&
          patient.treatment_related.formOdontogram.up_left_second_molar_data &&
          patient.treatment_related.formOdontogram.type !== 'Sin cambios'
        ) {
          const odontograma = patient.treatment_related.formOdontogram;
          up_odontograma = [
            JSON.parse(odontograma.up_left_third_molar_data),
            JSON.parse(odontograma.up_left_second_molar_data),
            JSON.parse(odontograma.up_left_first_molar_data),
            JSON.parse(odontograma.up_left_second_premolar_data),
            JSON.parse(odontograma.up_left_first_premolar_data),
            JSON.parse(odontograma.up_left_canine_data),
            JSON.parse(odontograma.up_left_lateral_incisor_data),
            JSON.parse(odontograma.up_left_central_incisor_data),
            JSON.parse(odontograma.up_right_third_molar_data),
            JSON.parse(odontograma.up_right_second_molar_data),
            JSON.parse(odontograma.up_right_first_molar_data),
            JSON.parse(odontograma.up_right_second_premolar_data),
            JSON.parse(odontograma.up_right_first_premolar_data),
            JSON.parse(odontograma.up_right_canine_data),
            JSON.parse(odontograma.up_right_lateral_incisor_data),
            JSON.parse(odontograma.up_right_central_incisor_data),
          ];
          lo_odontograma = [
            JSON.parse(odontograma.lo_left_third_molar_data),
            JSON.parse(odontograma.lo_left_second_molar_data),
            JSON.parse(odontograma.lo_left_first_molar_data),
            JSON.parse(odontograma.lo_left_second_premolar_data),
            JSON.parse(odontograma.lo_left_first_premolar_data),
            JSON.parse(odontograma.lo_left_canine_data),
            JSON.parse(odontograma.lo_left_lateral_incisor_data),
            JSON.parse(odontograma.lo_left_central_incisor_data),
            JSON.parse(odontograma.lo_right_third_molar_data),
            JSON.parse(odontograma.lo_right_second_molar_data),
            JSON.parse(odontograma.lo_right_first_molar_data),
            JSON.parse(odontograma.lo_right_second_premolar_data),
            JSON.parse(odontograma.lo_right_first_premolar_data),
            JSON.parse(odontograma.lo_right_canine_data),
            JSON.parse(odontograma.lo_right_lateral_incisor_data),
            JSON.parse(odontograma.lo_right_central_incisor_data),
          ];
        }

        setFormCAData({
          arcad: patient.treatment_related.formArcade,
          shape: patient.treatment_related.formShape,
          ipr: patient.treatment_related.formIpr,
          odontograma: patient.treatment_related.formOdontogram,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });
        setFormSSData({
          bracket_ss: patient.treatment_related.bracket_ss,
          odontograma: patient.treatment_related.formOdontogram,
          brackets: patient.treatment_related.formBracket,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
        });
      }
    },
  });

  // #endregion

  const validation_payment_line = () => {
    if (treatment === 'smile_system' && !defaultAddressDoctor.name) {
      setShowAddressError(true);
      return;
    }
    if (
      (paymentMethod !== 'payment_transfer' &&
        treatment !== 'cryst_aligner' &&
        doctorData.addresses &&
        doctorData.addresses.length > 0 &&
        cards.length > 0 &&
        invoice &&
        defaultTaxData.business_name) ||
      (paymentMethod !== 'payment_transfer' &&
        treatment !== 'cryst_aligner' &&
        doctorData.addresses &&
        doctorData.addresses.length > 0 &&
        cards.length > 0 &&
        !invoice) ||
      (paymentMethod !== 'payment_transfer' &&
        treatment === 'cryst_aligner' &&
        cards.length > 0 &&
        filesTreatmentRadiografia.lateral &&
        filesTreatmentRadiografia.panoramica &&
        invoice &&
        defaultTaxData.business_name) ||
      (paymentMethod !== 'payment_transfer' &&
        treatment === 'cryst_aligner' &&
        cards.length > 0 &&
        filesTreatmentRadiografia.lateral &&
        filesTreatmentRadiografia.panoramica &&
        !invoice)
    ) {
      setErrorPayment(false);
      submitPayTreatment();
    } else {
      setErrorPayment(true);
    }
  };

  // #region Functions

  const validateIntraoral = () => {
    let hasMissingFiles = false;
    if (
      !(
        filesTreatment &&
        filesTreatment.superior &&
        !filesTreatment.superior.error
      )
    ) {
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatment &&
        filesTreatment.inferior &&
        !filesTreatment.inferior.error
      )
    ) {
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatment &&
        filesTreatment.lateral_izquierda &&
        !filesTreatment.lateral_izquierda.error
      )
    ) {
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatment &&
        filesTreatment.frontal &&
        !filesTreatment.frontal.error
      )
    ) {
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatment &&
        filesTreatment.lateral_derecha &&
        !filesTreatment.lateral_derecha.error
      )
    ) {
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }

    if (!hasMissingFiles) {
      setSubStep(1);
    }
  };

  const deleteExtraStl = () => {
    if (oclusionStl === 'no') {
      deleteExtraStlOnChange({ variables: { patient_id: patientId } });
    }
  };

  const validateExtraoral = () => {
    let hasMissingFiles = false;
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.frontal_serio &&
        !filesTreatmentExtraorales.frontal_serio.error
      )
    ) {
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.frontal_sonriendo &&
        !filesTreatmentExtraorales.frontal_sonriendo.error
      )
    ) {
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.frontal_retractore &&
        !filesTreatmentExtraorales.frontal_retractore.error
      )
    ) {
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.lateral_izquierda &&
        !filesTreatmentExtraorales.lateral_izquierda.error
      )
    ) {
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.lateral_derecha &&
        !filesTreatmentExtraorales.lateral_derecha.error
      )
    ) {
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }

    if (!hasMissingFiles) {
      setSubStep(0);
      setSteps(4);
      getDefaultAddress({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          patient_id: patientId,
        },
      });
    }
  };

  const getDoctorCards = async () => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('b360-token')}`,
      },
    });
    const response = await instance.get(
      `/api/cards/get_all/${patientTreatment.treatment_related.doctor.id}/${patientTreatment.treatment_related.treatmentType.id}`
    );
    const data = response.data;
    setCards(data.cards);
    const defaultCard = data?.cards?.find((card) => card.default === 1) ?? null;
    if (defaultCard) {
      setDefaultPayment(defaultCard);
    }
  };

  const sendToRefinement = async () => {
    try {
      await apiSendToRevision();
      setSaved(true);
      modal.close();
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => history.push(`/doctor/caso/${patientId}`))
          }
        >
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14 }}
          >
            Archivos enviados y refinamiento iniciado con éxito
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Revisaremos los archivos de tu paciente y tu formulario, si existe
            alguna duda te notificaremos. Podrás ver el estatus en el historial
            de este caso. Este proceso dura aproximadamente 1 día hábil.
          </LabelNormal>
        </ModalSuccess>
      );
    } catch (e) {
      console.log(e);
    }
  };

  const apiSendToRevision = async () => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    await instance.get(
      `/api/treatments/send_to_revision?doctorId=${doctorData.id}&patientId=${patientId}`
    );
  };

  const submitPayTreatment = () => {
    setTransferError(false);
    setSaved(true);
    if (treatment === 'cryst_aligner') {
      if (
        Object.keys(selectInstallment).length !== 0 &&
        paymentMethod === 'payment_line'
      ) {
        modal.open(
          <ModalWaiting>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Procesando...
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Procesando pago, por favor espera
            </LabelNormal>
          </ModalWaiting>
        );
        // if(urlSTL && sendLinkStl){
        //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
        // }
        payPlanningTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatmentId,
            taxdata_id: defaultTaxData.id,
            intent_id: paymentInstallments.intent_id + '',
            amount: parseFloat(originalTypePrice.amount),
            currency: originalTypePrice.currency,
            count: selectInstallment.count,
            interval: selectInstallment.interval,
            type: selectInstallment.type,
            method: paymentMethod,
            coupon_id: couponId,
            pack_id: packType?.id,
          },
        });
      } else {
        if (paymentMethod === 'payment_line') {
          modal.open(
            <ModalWaiting>
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Procesando...
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Procesando pago, por favor espera
              </LabelNormal>
            </ModalWaiting>
          );
          // if(urlSTL && sendLinkStl){
          //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
          // }
          payPlanningTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              amount: parseFloat(originalTypePrice.amount),
              currency: originalTypePrice.currency,
              intent_id: paymentInstallments.intent_id + '',
              method: paymentMethod,
              coupon_id: couponId,
              pack_id: packType?.id,
            },
          });
        } else {
          setLoadingTransfer(true);
          payPlanningTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              amount: parseFloat(originalTypePrice.amount),
              currency: originalTypePrice.currency,
              method: paymentMethod,
              coupon_id: couponId,
              pack_id: packType?.id,
            },
          });
        }
      }
    } else {
      if (
        Object.keys(selectInstallment).length !== 0 &&
        paymentMethod === 'payment_line'
      ) {
        modal.open(
          <ModalWaiting>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Procesando...
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Procesando pago, por favor espera
            </LabelNormal>
          </ModalWaiting>
        );
        // if(urlSTL && sendLinkStl){
        //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
        // }
        payManufactureTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatmentId,
            taxdata_id: defaultTaxData.id,
            address_id: defaultAddressDoctor.id,
            intent_id: paymentInstallments.intent_id + '',
            amount: parseFloat(originalTypePrice.amount),
            currency: originalTypePrice.currency,
            count: selectInstallment.count,
            interval: selectInstallment.interval,
            type: selectInstallment.type,
            currency_before: paymentInstallments.currency_before,
            amount_before: paymentInstallments.amount_before,
            installment_price: selectInstallment.installment_price,
            method: paymentMethod,
            coupon_id: couponId,
            pack_id: packType.id,
          },
        });
      } else {
        if (paymentMethod === 'payment_line') {
          modal.open(
            <ModalWaiting>
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Procesando...
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Procesando pago, por favor espera
              </LabelNormal>
            </ModalWaiting>
          );
          // if(urlSTL && sendLinkStl){
          //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
          // }
          payManufactureTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              address_id: defaultAddressDoctor.id,
              intent_id: paymentInstallments.intent_id + '',
              amount: parseFloat(originalTypePrice.amount),
              currency: originalTypePrice.currency,
              currency_before: paymentInstallments.currency_before,
              amount_before: paymentInstallments.amount_before,
              method: paymentMethod,
              coupon_id: couponId,
              pack_id: packType.id,
            },
          });
        } else {
          setLoadingTransfer(true);
          payManufactureTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              address_id: defaultAddressDoctor.id,
              amount: parseFloat(originalTypePrice.amount),
              currency: originalTypePrice.currency,
              method: paymentMethod,
              coupon_id: couponId,
              pack_id: packType.id,
            },
          });
        }
      }
    }
  };

  const addTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const editTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
        taxdata={defaultTaxData}
      />
    );
  };

  const addDefaultAddressDoctorModal = () => {
    modal.open(
      <ModalFormAddress
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const addCardModal = () => {
    modal.open(
      <ModalFormCard
        onFinish={() => {
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          });
          getDefaultMethodPayment({
            variables: { user_id: localStorage.getItem('b360-id') },
          });
          getDoctorCards();
        }}
      />
    );
  };

  // #endregion

  // #region Hooks

  useEffect(() => {
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    });
    getDefaultAddress({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultTaxData({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultAddressDoctor({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultMethodPayment({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    let patient = localStorage.getItem('Patient');
    if (patient !== undefined && patient !== null) {
      patient = JSON.parse(patient);
      // history.push(`/doctor/continuar-caso/${patient.id}`)
      setPatientId(parseInt(patient.id));
      setTreatmentId(patient.treatment_id);
      getPatient({ variables: { id: patient.id } });
    }
    let lastS = localStorage.getItem('lastStep');
    if (lastS !== undefined && lastS !== null) {
      setSteps(parseInt(lastS));
      setLastStep(parseInt(lastS));
    }
    setSaved(true);
    if (patient_id) {
      setTreatmentType(true);
      setTreatment('cryst_aligner');
    }
    return () => {
      localStorage.removeItem('Patient');
      localStorage.removeItem('lastStep');
    };
  }, []);

  useEffect(() => {
    setSaved(false);
  }, [
    steps,
    firstName,
    lastName,
    email,
    phone,
    treatment,
    gender,
    day,
    month,
    year,
  ]);

  useEffect(() => {
    if (packType !== null) {
      getTypePrice({
        variables: { type: 'manufact_ss', pack_id: +packType.id },
      });
      selectBrackets({
        variables: { patient_id: patientId, brackets: packType.name },
      });
    }
  }, [packType]);

  useEffect(() => {
    if (treatment === 'cryst_aligner') {
      getTypePrice({ variables: { type: 'planning_ca' } });
      if (patient_id) {
        setCurrentSteps(itemStepsRefinement);
      } else {
        setCurrentSteps(itemSteps);
      }
    } else {
      getPack({ variables: { type: 'manufact_ss' } });
      setCurrentSteps(itemStepsSS);
    }
    if (
      doctorData &&
      doctorData.user &&
      doctorData.user.type === 'student' &&
      doctorData.university
    ) {
      getTeachersByFilter({
        variables: {
          university_id: parseInt(doctorData.university.id),
          filters: [treatment],
        },
      });
    }
  }, [treatment]);

  useEffect(() => {
    if (treatmentType) {
      setCurrentSteps(itemStepsRefinement);
    } else {
      setCurrentSteps(itemSteps);
    }
  }, [treatmentType]);

  useEffect(() => {
    if (types.length > 0 && brackets !== null) {
      let type = types.find((t) => t.name === brackets);
      if (type !== null) setPackType(type);
    }
  }, [types, brackets]);

  useEffect(() => {
    if (
      paymentMethod === 'payment_line' &&
      ((steps === 7 && treatment === 'cryst_aligner') ||
        (steps === 6 && treatment === 'smile_system'))
    ) {
      if (Object.keys(originalTypePrice).length) {
        if (total - discount > 0) {
          setLoadingPaymentInstallment(true);
          getPaymentInstallments({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              treatment_id: treatmentId,
              payment_method_ca: defaultPayment.service_id_ca,
              payment_method_ss: defaultPayment.service_id_ss,
              amount: Math.round(total - discount, 2),
              currency: originalTypePrice.currency,
              treatment_type: treatment,
            },
          });
        }
      }
    }
  }, [steps, originalTypePrice, discount]);

  useEffect(() => {
    if (teacherSelect) {
      assignTeacherCase({
        variables: { treatment_id: treatmentId, teacher_id: teacherSelect },
      });
    }
  }, [teacherSelect]);

  useEffect(() => {
    if (steps > lastStep) {
      localStorage.setItem('lastStep', steps);
      setLastStep(steps);
    }
    if (steps === 5 || steps === 6 || steps === 2) {
      getPatientRefresh({ variables: { id: +patientId } });
    }
  }, [steps]);

  const [selectTab, setSelectTab] = useState(1);

  const [scrollY, setScrollY] = useState({ position: 'relative' });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 110) {
        setScrollY({ position: 'fixed', top: 70, right: 60, bottom: 0 });
      } else {
        setScrollY({ position: 'relative' });
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [window.scroll]);

  const resetCouponForm = () => {
    setResetCoupon(true);
  };
  
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="nuevo-caso" />
      <ContainHome>
        <NewCaseHeader
          steps={steps}
          setSteps={setSteps}
          selectTab={selectTab}
          setSelectTab={setSelectTab}
          subSteps={subSteps}
          setSubStep={setSubStep}
          lastStep={lastStep}
        />
        <ContainContent>
          {!changeCard && !changePayment && !changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <Stepper
                  steps={currentSteps}
                  setSteps={setSteps}
                  currentStep={steps}
                  lastStep={lastStep}
                />
                {steps === 1 && (
                  <PatientInfo
                    patientId={patientId}
                    doctor={doctorData}
                    setFilePhoto={setFilePhoto}
                    treatment={treatment}
                    setTreatment={setTreatment}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    gender={gender}
                    setGender={setGender}
                    day={day}
                    setDay={setDay}
                    month={month}
                    setMonth={setMonth}
                    year={year}
                    setYear={setYear}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    currentStep={steps}
                    registerPatient={registerPatient}
                    updatePatient={updatePatient}
                    uploadError={photoUploadError}
                    treatmentType={treatmentType}
                    setTreatmentType={setTreatmentType}
                    patient_id={patient_id}
                    aligners={usedAligners}
                    selectedPatientData={selectedPatientData}
                    setSelectedPatientData={setSelectedPatientData}
                    isEditing={selectedPatientData ? true : false}
                  />
                )}
                {steps === 2 && treatment === 'cryst_aligner' && (
                  <FormCA
                    treatment_id={treatmentId}
                    patient_id={patientId}
                    setSteps={setSteps}
                    formCAData={formCAData}
                    selectTab={selectTab}
                    setSelectTab={setSelectTab}
                    isRefinement={treatmentType}
                  />
                )}
                {steps === 2 && treatment === 'smile_system' && (
                  <FormSS
                    treatment_id={treatmentId}
                    patient_id={patientId}
                    setSteps={setSteps}
                    formSSData={formSSData}
                    setPackTypeBack={setPackType}
                  />
                )}
                {subSteps === 0 && steps === 3 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>
                        Fotografías intraorales
                      </Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube las fotografías para tu tratamiento. Puedes
                          arrastrar tu archivo hasta su
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            casilla correspondiente o hacer click en el botón de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                            alt=""
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg o png
                      </LabelNormal>
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        marginBottom: 100,
                        marginRight: 40,
                      }}
                    >
                      <InlineContainer
                        style={{
                          justifyContent: 'space-between',
                          maxWidth: '850px',
                        }}
                      >
                        <UploadFile
                          name="superior"
                          patientId={patientId}
                          section="INTRAORALES"
                          filesTreatment={filesTreatment}
                          setFilesTreatment={setFilesTreatment}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="inferior"
                          patientId={patientId}
                          section="INTRAORALES"
                          filesTreatment={filesTreatment}
                          setFilesTreatment={setFilesTreatment}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      <InlineContainer>
                        <UploadFile
                          name="lateral_izquierda"
                          patientId={patientId}
                          section="INTRAORALES"
                          filesTreatment={filesTreatment}
                          setFilesTreatment={setFilesTreatment}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="frontal"
                          patientId={patientId}
                          section="INTRAORALES"
                          filesTreatment={filesTreatment}
                          setFilesTreatment={setFilesTreatment}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="lateral_derecha"
                          patientId={patientId}
                          section="INTRAORALES"
                          filesTreatment={filesTreatment}
                          setFilesTreatment={setFilesTreatment}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      {!validateOnIntraoral && (
                        <div
                          style={{
                            marginBottom: 18,
                            background: '#FFE5E3',
                            border: '1px solid #E41D11',
                            padding: '2px 0px 2px 2px',
                            borderRadius: 7,
                            maxWidth: 230,
                          }}
                        >
                          <LabelNormal fontSize="14" color="#C60E02">
                            *Completa los campos faltantes
                          </LabelNormal>
                        </div>
                      )}
                      <ButtonPrime
                        label="Siguiente"
                        onClick={() => validateIntraoral()}
                      ></ButtonPrime>
                    </div>
                  </ScrollableBody>
                )}
                {subSteps === 1 && steps === 3 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>
                        Fotografías extraorales
                      </Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube las fotografías para tu tratamiento. Puedes
                          arrastrar tu archivo hasta su
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            casilla correspondiente o hacer click en el botón de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                            alt=""
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg o png
                      </LabelNormal>
                    </div>
                    <div style={{ maxWidth: '850px' }}>
                      <InlineContainer style={{ gap: '30px' }}>
                        <UploadFile
                          name="frontal_serio"
                          patientId={patientId}
                          section="EXTRAORALES"
                          filesTreatment={filesTreatmentExtraorales}
                          setFilesTreatment={setFilesTreatmentExtraorales}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="frontal_sonriendo"
                          patientId={patientId}
                          section="EXTRAORALES"
                          filesTreatment={filesTreatmentExtraorales}
                          setFilesTreatment={setFilesTreatmentExtraorales}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="frontal_retractore"
                          patientId={patientId}
                          section="EXTRAORALES"
                          filesTreatment={filesTreatmentExtraorales}
                          setFilesTreatment={setFilesTreatmentExtraorales}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      <InlineContainer
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '30px',
                          marginTop: '30px',
                        }}
                      >
                        <UploadFile
                          name="ext_lateral_izquierda"
                          patientId={patientId}
                          section="EXTRAORALES"
                          filesTreatment={filesTreatmentExtraorales}
                          setFilesTreatment={setFilesTreatmentExtraorales}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="ext_lateral_derecha"
                          patientId={patientId}
                          section="EXTRAORALES"
                          filesTreatment={filesTreatmentExtraorales}
                          setFilesTreatment={setFilesTreatmentExtraorales}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      <br />
                      {!validateOnExtraoral && (
                        <div
                          style={{
                            marginBottom: 18,
                            background: '#FFE5E3',
                            border: '1px solid #E41D11',
                            padding: '2px 0px 2px 2px',
                            borderRadius: 7,
                            maxWidth: 230,
                          }}
                        >
                          <LabelNormal fontSize="14" color="#C60E02">
                            *Completa los campos faltantes
                          </LabelNormal>
                        </div>
                      )}
                      <ButtonPrime
                        label="Siguiente"
                        onClick={() => validateExtraoral()}
                      />
                    </div>
                  </ScrollableBody>
                )}
                {steps === 4 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>STL’s</Title>
                      <>
                        <div style={{ marginBottom: 10 }}>
                          <LabelNormal color="#757575" fontSize="17">
                            Selecciona cuál es el formato con el que subirás los
                            escaneos intraorales.
                          </LabelNormal>
                        </div>
                        <div>
                          <InlineContainer>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="stl-send-1"
                                onClick={(e) => setRadioStl('archivos')}
                                checked={
                                  radioStl === 'archivos' ? 'checked' : false
                                }
                                name="stl-send"
                                type="radio"
                              />
                              <label
                                htmlFor="stl-send-1"
                                className="radio-label"
                              >
                                Archivos stl’s
                              </label>
                            </div>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="stl-send-2"
                                onClick={(e) => setRadioStl('url')}
                                checked={radioStl === 'url' ? 'checked' : false}
                                name="stl-send"
                                type="radio"
                              />
                              <label
                                htmlFor="stl-send-2"
                                className="radio-label"
                              >
                                Link de estudio radiológico
                              </label>
                            </div>
                            {treatment === 'cryst_aligner' && (
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="stl-send-3"
                                  onClick={(e) => setRadioStl('model')}
                                  checked={
                                    radioStl === 'model' ? 'checked' : false
                                  }
                                  name="stl-send"
                                  type="radio"
                                />
                                <label
                                  htmlFor="stl-send-3"
                                  className="radio-label"
                                >
                                  Envío de modelos (paquetería)
                                </label>
                              </div>
                            )}
                          </InlineContainer>
                        </div>
                      </>
                      {radioStl === 'model' && (
                        <div style={{ marginBottom: 30, marginTop: 30 }}>
                          <LabelNormal color="#4F4F4F" fontSize="22">
                            Envío de modelos
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            Manda tus modelos por el servicio de paquetería de
                            tu elección a la siguiente dirección y con los
                            siguientes datos en una hoja de papel dentro del
                            paquete.
                          </LabelNormal>
                        </div>
                      )}
                      {radioStl === 'url' && (
                        <div style={{ marginBottom: 30, marginTop: 30 }}>
                          <LabelNormal color="#4F4F4F" fontSize="22">
                            Envío por link
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            Pega el link que te otorgó tu estudio radiológico
                            donde se incluyen los archivos:
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Arcada superior
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Arcada inferior
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Oclusión (1 o 2 archivos)
                          </LabelNormal>
                        </div>
                      )}
                    </div>
                    {radioStl === 'model' && (
                      <div className="col-md-12">
                        <InlineContainer>
                          <CardModel>
                            <LabelNormal
                              style={{
                                margin: 0,
                                padding: 0,
                                marginRight: 7,
                                marginBottom: 10,
                              }}
                              color="#757575"
                              fontSize="18"
                            >
                              Datos a incluir
                            </LabelNormal>
                            <InlineContainer>
                              <LabelNormal
                                color="#BDBDBD"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginRight: 7,
                                }}
                                fontSize="17"
                              >
                                Nombre del doctor
                              </LabelNormal>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                {doctorData.user && doctorData.user.first_name
                                  ? `${doctorData.user.first_name} ${doctorData.user.last_name}`
                                  : ''}
                              </LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <LabelNormal
                                color="#BDBDBD"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginRight: 7,
                                }}
                                fontSize="17"
                              >
                                Nombre del paciente
                              </LabelNormal>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            {/* <InlineContainer>
                                                    <LabelNormal color="#BDBDBD" style={{margin: 0, padding: 0, marginRight: 7}} fontSize="17">ID de caso</LabelNormal>
                                                    <LabelNormal color="#828282" style={{margin: 0, padding: 0}} fontSize="17">{`${patientId}`}</LabelNormal>
                                                </InlineContainer> */}
                          </CardModel>
                          <CardModelExtended>
                            <LabelNormal
                              style={{ margin: 0, padding: 0, marginRight: 7 }}
                              color="#757575"
                              fontSize="18"
                            >
                              Dirección de envío | Borgatta
                            </LabelNormal>
                            <InlineContainer style={{ marginBottom: 10 }}>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                <span style={{ color: '#BDBDBD' }}>
                                  Atención a:{' '}
                                </span>
                                Karina Cruz, departamento Atención Inteligencia
                                Médica.
                              </LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                <span style={{ color: '#BDBDBD' }}>
                                  Dirección:{' '}
                                </span>
                                {defaultAddress.id
                                  ? `${defaultAddress.street}, ${defaultAddress.number} ${defaultAddress.suburb}, CP ${defaultAddress.postal_code} ${defaultAddress.city}, ${defaultAddress.state}`
                                  : ''}
                              </LabelNormal>
                            </InlineContainer>
                          </CardModelExtended>
                        </InlineContainer>
                      </div>
                    )}
                    {radioStl === 'url' && (
                      <div className="col-md-4">
                        {link_data.map((item, key) => (
                          <InlineContainer
                            style={{
                              marginTop: 20,
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Input
                              placeholder="URL"
                              type="url"
                              validation="url"
                              onChange={(e) =>
                                setLinkData([
                                  ...link_data.map((v, i) =>
                                    i === key ? `${e.target.value}` : v
                                  ),
                                ])
                              }
                              value={item !== '' ? item : ''}
                            />
                            {key === link_data.length - 1 && (
                              <ButtonFile
                                onClick={() =>
                                  setLinkData(['', ...link_data.map((v) => v)])
                                }
                                icon={'plus'}
                                size={27}
                                disabled={
                                  item !== '' && validateURL(item)
                                    ? false
                                    : true
                                }
                              />
                            )}
                            {key < link_data.length - 1 && (
                              <ButtonFile
                                onClick={() =>
                                  setLinkData([
                                    ...link_data.filter((v, i) => i !== key),
                                  ])
                                }
                                icon={'minus'}
                                size={27}
                              />
                            )}
                          </InlineContainer>
                        ))}
                        {/* <InlineContainer>
                                            <Input placeholder="URL" type="url" validation="url" value={urlSTL} onChange={(e) => setUrlSTL(e.target.value)}/>
                                        </InlineContainer> */}
                      </div>
                    )}
                    {radioStl === 'archivos' && (
                      <div className="col-md-12" style={{ marginTop: 45 }}>
                        <LabelNormal fontSize="22" color="#757575">
                          Instrucciones
                        </LabelNormal>
                        <InlineContainer
                          style={{
                            alignItems: 'center',
                            marginBottom: 30,
                            marginTop: 10,
                          }}
                        >
                          <InlineContainer>
                            <LabelNormal color="#757575" fontSize="17">
                              Sube los archivos STL para tu tratamiento. Puedes
                              arrastrar tu archivo hasta su casilla
                              correspondiente o hacer click en el botón de{' '}
                            </LabelNormal>
                            <img
                              src={require('@images/icons/upload-circle.png')}
                              style={{ height: 23, width: 23 }}
                              alt="upload"
                            />
                          </InlineContainer>
                        </InlineContainer>
                        <InlineContainer>
                          <UploadFile
                            name="arcada_superior"
                            patientId={patientId}
                            section="STL"
                            filesTreatment={filesTreatmentStl}
                            setFilesTreatment={setFilesTreatmentSlt}
                            parentLoading={loading}
                            setParentLoading={setLoading}
                          />
                          <UploadFile
                            name="arcada_inferior"
                            patientId={patientId}
                            section="STL"
                            filesTreatment={filesTreatmentStl}
                            setFilesTreatment={setFilesTreatmentSlt}
                            parentLoading={loading}
                            setParentLoading={setLoading}
                          />
                        </InlineContainer>
                        <InlineContainer>
                          {(oclusionStl === 'no' || oclusionStl === 'si') && (
                            <UploadFile
                              name="oclusion_derecha"
                              patientId={patientId}
                              section="STL"
                              filesTreatment={filesTreatmentStl}
                              setFilesTreatment={setFilesTreatmentSlt}
                              parentLoading={loading}
                              setParentLoading={setLoading}
                            />
                          )}
                          {oclusionStl === 'si' && (
                            <UploadFile
                              name="oclusion_izquierda"
                              patientId={patientId}
                              section="STL"
                              filesTreatment={filesTreatmentStl}
                              setFilesTreatment={setFilesTreatmentSlt}
                              parentLoading={loading}
                              setParentLoading={setLoading}
                            />
                          )}
                        </InlineContainer>
                        <div style={{ marginTop: 20 }}>
                          <LabelNormal fontSize="17" color="#757575">
                            ¿Tienes más de un archivo stl en oclusión?
                          </LabelNormal>
                          <InlineContainer style={{ marginTop: 10 }}>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="oclusion-stl-1"
                                onClick={(e) => setOclusionStl('no')}
                                checked={
                                  oclusionStl === 'no' ? 'checked' : false
                                }
                                name="oclusion-stl"
                                type="radio"
                              />
                              <label
                                htmlFor="oclusion-stl-1"
                                className="radio-label"
                              >
                                No, solo tengo uno
                              </label>
                            </div>
                            <div className="radio">
                              <input
                                id="oclusion-stl-2"
                                onClick={(e) => setOclusionStl('si')}
                                checked={
                                  oclusionStl === 'si' ? 'checked' : false
                                }
                                name="oclusion-stl"
                                type="radio"
                              />
                              <label
                                htmlFor="oclusion-stl-2"
                                className="radio-label"
                              >
                                Si (lado izquierdo y derecho)
                              </label>
                            </div>
                          </InlineContainer>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      {radioStl === 'archivos' && (
                        <>
                          <ContainerShowMore style={{ marginTop: 31 }}>
                            <HeaderShowMore
                              style={{ cursor: 'pointer' }}
                              onClick={() => setMore(!more)}
                            >
                              <LabelNormal color="#4F4F4F" fontSize="17">
                                Recomendaciones
                              </LabelNormal>
                              <FontAwesomeIcon
                                icon={more ? faChevronUp : faChevronDown}
                              />
                            </HeaderShowMore>
                            {more && (
                              <BodyShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    Si notas imperfecciones en el STL, confirma
                                    que coincidan en el paciente o sus
                                    fotografías
                                  </LabelNormal>
                                </ItemShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    Procura que los STL’s cuenten con suficiente
                                    encía y espacio retromolar
                                  </LabelNormal>
                                </ItemShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    El escaneo intraoral tiene mejor definición
                                    que el escaneo
                                    <br /> de impresiones o modelos de yeso, si
                                    tienes la opción, siempre envía STL’s
                                  </LabelNormal>
                                </ItemShowMore>
                              </BodyShowMore>
                            )}
                          </ContainerShowMore>
                        </>
                      )}
                      {radioStl !== '' && (
                        <div
                          style={{
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                          }}
                        >
                          {/* { (sendLinkStl || sendModelStl) && <ButtonAction label="Elegir otro método" onClick={() => { setSendLinkStl(false); setSendModelStl(false); }}/> } */}
                          <ButtonPrime
                            label="Siguiente"
                            disabled={
                              (radioStl === 'archivos' &&
                                oclusionStl === 'no' &&
                                filesTreatmentStl.arcada_superior &&
                                !filesTreatmentStl.arcada_superior.error &&
                                filesTreatmentStl.arcada_inferior &&
                                !filesTreatmentStl.arcada_inferior.error &&
                                filesTreatmentStl.oclusion_derecha &&
                                !filesTreatmentStl.oclusion_derecha.error) ||
                              (radioStl === 'archivos' &&
                                oclusionStl === 'si' &&
                                filesTreatmentStl.arcada_superior &&
                                !filesTreatmentStl.arcada_superior.error &&
                                filesTreatmentStl.arcada_inferior &&
                                !filesTreatmentStl.arcada_inferior.error &&
                                filesTreatmentStl.oclusion_derecha &&
                                !filesTreatmentStl.oclusion_derecha.error &&
                                filesTreatmentStl.oclusion_izquierda &&
                                !filesTreatmentStl.oclusion_izquierda.error) ||
                              (radioStl === 'url' &&
                                link_data.length >= 1 &&
                                link_data.filter((v) => validateURL(v))
                                  .length === link_data.length) ||
                              radioStl === 'model'
                                ? false
                                : true
                            }
                            onClick={() => {
                              if (radioStl === 'url' && link_data.length >= 1)
                                addSTLURL({
                                  variables: {
                                    patient_id: patientId,
                                    url_data: link_data,
                                  },
                                });
                              if (radioStl === 'model')
                                setShipping({
                                  variables: { patient_id: patientId },
                                });
                              if (radioStl === 'archivos') {
                                setSubStep(0);
                                setSteps(5);
                                deleteExtraStl();
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </ScrollableBody>
                )}
                {treatment === 'cryst_aligner' && steps === 5 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>Radiografías</Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube los archivos para tu tratamiento. Puedes
                          arrastrar tu archivo hasta
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            su casilla correspondiente o hacer click en el botón
                            de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                            alt=""
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg para radiografías y en zip
                        para tomografías
                      </LabelNormal>
                    </div>
                    <div className="col-md-12">
                      <InlineContainer style={{ alignItems: 'flex-start' }}>
                        <UploadFile
                          name="lateral"
                          patientId={patientId}
                          section="RADIO"
                          filesTreatment={filesTreatmentRadiografia}
                          setFilesTreatment={setFilesTreatmentRadiografia}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="panoramica"
                          patientId={patientId}
                          section="RADIO"
                          filesTreatment={filesTreatmentRadiografia}
                          setFilesTreatment={setFilesTreatmentRadiografia}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                        <UploadFile
                          name="trazado_cefalometrico"
                          patientId={patientId}
                          section="RADIO"
                          filesTreatment={filesTreatmentRadiografia}
                          setFilesTreatment={setFilesTreatmentRadiografia}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      <InlineContainer style={{ alignItems: 'center' }}>
                        <UploadFile
                          name="tomografia"
                          patientId={patientId}
                          section="RADIO"
                          filesTreatment={filesTreatmentRadiografia}
                          setFilesTreatment={setFilesTreatmentRadiografia}
                          parentLoading={loading}
                          setParentLoading={setLoading}
                        />
                      </InlineContainer>
                      <ButtonPrime
                        label="Siguiente"
                        disabled={
                          !filesTreatmentRadiografia.lateral ||
                          !filesTreatmentRadiografia.panoramica ||
                          (filesTreatmentRadiografia.trazado_cefalometrico &&
                            filesTreatmentRadiografia.trazado_cefalometrico
                              .error) ||
                          (filesTreatmentRadiografia.tomografia &&
                            filesTreatmentRadiografia.tomografia.error)
                        }
                        onClick={() => {
                          setSubStep(0);
                          setSteps(6);
                        }}
                      ></ButtonPrime>
                    </div>
                  </ScrollableBody>
                )}
                {((steps === 6 && treatment === 'cryst_aligner') ||
                  (steps === 5 && treatment === 'smile_system')) && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>Resumen</Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Confirma que los datos de registro sean correctos para
                          evitar retrasos en tu
                        </LabelNormal>
                        <LabelNormal color="#757575" fontSize="17">
                          tratamiento. Puedes regresar y editar cualquiera de
                          los pasos.
                        </LabelNormal>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InlineContainer style={{ marginBottom: 42 }}>
                        <div style={{ marginRight: 20 }}>
                          <Avatar
                            src={photo ? photo : loadPhotoProfileGender(gender)}
                            height="161"
                            width="161"
                            borderRadius="30"
                          />
                        </div>
                        <div>
                          <InlineContainer>
                            <div style={{ marginRight: 73 }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="22"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                              <InlineContainer
                                style={{
                                  alignItems: 'center',
                                  marginBottom: 21,
                                }}
                              >
                                <LabelNormal color="#828282" fontSize="13">
                                  Tratamiento
                                </LabelNormal>
                                <LabelNormal
                                  color={
                                    treatment === 'cryst_aligner'
                                      ? '#18A1DC'
                                      : '#F0BB00'
                                  }
                                  fontSize="20"
                                >
                                  {treatment === 'cryst_aligner'
                                    ? 'Cryst Aligner'
                                    : 'Smile System'}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div>
                              <ButtonFile
                                onClick={() => setSteps(1)}
                                icon={'edit'}
                                size={25}
                              />
                            </div>
                          </InlineContainer>

                          <div className="row">
                            <div
                              className="col-md-4"
                              style={{ marginBottom: 12 }}
                            >
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={require('@images/icons/user.png')}
                                  style={{ height: 16, width: 15.5 }}
                                  alt=""
                                />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {gender}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            {phone ? (
                              <div
                                className="col-md-7"
                                style={{ marginBottom: 12 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <img
                                    src={require('@images/icons/phone.png')}
                                    style={{ height: 16, width: 15.5 }}
                                    alt=""
                                  />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            ) : (
                              <div className="col-md-7" />
                            )}
                            <div className="col-md-4">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={require('@images/icons/grift.png')}
                                  style={{ height: 16, width: 15.5 }}
                                  alt=""
                                />
                                <LabelNormal
                                  fontSize="14"
                                  color="#3C4549"
                                >{`${day}/${month}/${year}`}</LabelNormal>
                              </InlineContainer>
                            </div>
                            {email && (
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <img
                                    src={require('@images/icons/email.png')}
                                    style={{ height: 16, width: 15.5 }}
                                    alt=""
                                  />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {email}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            )}
                          </div>
                        </div>
                      </InlineContainer>
                      {doctorData?.user?.type === 'student' && (
                        <div
                          style={{
                            marginBottom: 30,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <LabelInput style={{ marginBottom: 10 }}>
                            Selecciona un profesor asignado para el caso
                          </LabelInput>
                          <Select
                            options={allTeachers}
                            onSelect={(e) => {
                              setTeacherSelect(e);
                              setSaved(false);
                            }}
                            value={teacherSelect}
                            placeholder="Profesor"
                            maxWidth="310px"
                            hideEmptyError={true}
                          />
                        </div>
                      )}
                      {doctorData.is_doctor &&
                        treatment === 'cryst_aligner' && (
                          <>
                            {treatmentType ? (
                              <ButtonPrime
                                onClick={() => sendToRefinement()}
                                label="Enviar archivos para refinamiento"
                              ></ButtonPrime>
                            ) : (
                              <ButtonPrime
                                onClick={() => submitPayTreatment()}
                                label="Registrar Paciente"
                              ></ButtonPrime>
                            )}
                          </>
                        )}
                      {!doctorData.is_doctor &&
                        treatment === 'cryst_aligner' && (
                          <>
                            {treatmentType ? (
                              <ButtonPrime
                                onClick={() => sendToRefinement()}
                                label="Enviar archivos para refinamiento"
                              ></ButtonPrime>
                            ) : (
                              <ButtonPrime
                                onClick={() => setSteps(7)}
                                disabled={
                                  doctorData?.user?.type === 'student' &&
                                  teacherSelect === null
                                }
                                label="Proceder al pago"
                              ></ButtonPrime>
                            )}
                          </>
                        )}
                      {treatment === 'smile_system' && !treatmentType && (
                        <ButtonPrime
                          onClick={() => setSteps(6)}
                          label="Proceder al pago"
                        ></ButtonPrime>
                      )}
                      <div
                        className="col-md-12"
                        style={{ marginTop: 50, marginRight: 20 }}
                      >
                        <InlineContainer
                          style={{
                            backgroundColor: '#EEEFF1',
                            borderRadius: 40,
                            paddingTop: 30,
                            paddingLeft: 20,
                            paddingBottom: 30,
                            alignItems: 'self-start',
                          }}
                        >
                          <div style={{ marginRight: 40 }}>
                            {treatment === 'cryst_aligner' &&
                              [
                                'Formulario',
                                'Intraorales',
                                'Extraorales',
                                "STL's",
                                'Radiografias',
                              ].map((row, index) => (
                                <div
                                  style={{
                                    maxWidth: 165,
                                    maxHeight: 55,
                                    background:
                                      selectItem === row
                                        ? '#FFF'
                                        : 'transparent',
                                    borderBottomColor:
                                      selectItem === row
                                        ? 'transparent'
                                        : '#D4D4D4',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    padding: 15,
                                    borderRadius: selectItem !== row ? 0 : 10,
                                  }}
                                  key={Math.random()}
                                >
                                  <LabelNormal
                                    color="#000000"
                                    fontSize="18.5"
                                    onClick={() => setSelectItem(row)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {row}
                                  </LabelNormal>
                                </div>
                              ))}
                            {treatment === 'smile_system' &&
                              [
                                'Formulario',
                                'Intraorales',
                                'Extraorales',
                                "STL's",
                              ].map((row, index) => (
                                <div
                                  style={{
                                    maxWidth: 165,
                                    maxHeight: 55,
                                    background:
                                      selectItem === row
                                        ? '#FFF'
                                        : 'transparent',
                                    borderBottomColor:
                                      selectItem === row
                                        ? 'transparent'
                                        : '#D4D4D4',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    padding: 15,
                                    borderRadius: selectItem !== row ? 0 : 10,
                                  }}
                                  key={Math.random()}
                                >
                                  <LabelNormal
                                    color="#000000"
                                    fontSize="18.5"
                                    onClick={() => setSelectItem(row)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {row}
                                  </LabelNormal>
                                </div>
                              ))}
                          </div>
                          <div>
                            {selectItem === 'Formulario' && (
                              <div className="row">
                                <ButtonFile
                                  onClick={() => {
                                    setSteps(2);
                                    setSelectTab(1);
                                  }}
                                  icon={'edit'}
                                  size={25}
                                  style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 35,
                                  }}
                                />
                                <div className="col-md-12">
                                  {formSSData.brackets &&
                                    treatment === 'smile_system' && (
                                      <ResumeSS
                                        formSSData={formSSData}
                                        chatMessages={chatMessages}
                                      />
                                    )}
                                  {formCAData.arcad &&
                                    treatment === 'cryst_aligner' && (
                                      <ResumeCA
                                        formCAData={formCAData}
                                        chatMessages={chatMessages}
                                      />
                                    )}
                                </div>
                              </div>
                            )}
                            {selectItem === 'Radiografias' &&
                              treatment === 'cryst_aligner' && (
                                <div className="row">
                                  <ButtonFile
                                    onClick={() => setSteps(5)}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div className="col-md-7">
                                    <InlineContainer>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            alt="lateral"
                                            src={
                                              filesTreatmentRadiografia.lateral &&
                                              filesTreatmentRadiografia.lateral
                                                .preview
                                                ? filesTreatmentRadiografia
                                                    .lateral.preview
                                                : require('@images/radiografia/lateral.jpg')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                              backgroundPosition: 'cover',
                                            }}
                                          />
                                        </div>
                                        {filesTreatmentRadiografia.lateral &&
                                          filesTreatmentRadiografia.lateral
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="15"
                                            >
                                              {
                                                filesTreatmentRadiografia
                                                  .lateral.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="15"
                                        >
                                          Lateral
                                        </LabelNormal>
                                      </div>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            alt="panoramica"
                                            src={
                                              filesTreatmentRadiografia.panoramica &&
                                              filesTreatmentRadiografia
                                                .panoramica.preview
                                                ? filesTreatmentRadiografia
                                                    .panoramica.preview
                                                : require('@images/radiografia/panoramica.jpg')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                              backgroundPosition: 'cover',
                                            }}
                                          />
                                        </div>
                                        {filesTreatmentRadiografia.panoramica &&
                                          filesTreatmentRadiografia.panoramica
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="15"
                                            >
                                              {
                                                filesTreatmentRadiografia
                                                  .panoramica.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="15"
                                        >
                                          Panorámica
                                        </LabelNormal>
                                      </div>
                                      {filesTreatmentRadiografia.trazado_cefalometrico && (
                                        <div
                                          style={{
                                            marginBottom: 20,
                                            marginRight: 60,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              alt="cefalometrico"
                                              src={
                                                filesTreatmentRadiografia.trazado_cefalometrico &&
                                                filesTreatmentRadiografia
                                                  .trazado_cefalometrico.preview
                                                  ? filesTreatmentRadiografia
                                                      .trazado_cefalometrico
                                                      .preview
                                                  : require('@images/radiografia/trazado_cefalometrico.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentRadiografia.trazado_cefalometrico &&
                                            filesTreatmentRadiografia
                                              .trazado_cefalometrico.file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="15"
                                              >
                                                {
                                                  filesTreatmentRadiografia
                                                    .trazado_cefalometrico.file
                                                    .name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="15"
                                          >
                                            Trazado cefalométrico
                                          </LabelNormal>
                                        </div>
                                      )}
                                    </InlineContainer>
                                    {filesTreatmentRadiografia.tomografia && (
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              alt="tomografia"
                                              src={
                                                filesTreatmentRadiografia.tomografia &&
                                                filesTreatmentRadiografia
                                                  .tomografia.preview
                                                  ? filesTreatmentRadiografia
                                                      .tomografia.preview
                                                  : require('@images/radiografia/tomografia.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentRadiografia.tomografia &&
                                            filesTreatmentRadiografia.tomografia
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="15"
                                              >
                                                {
                                                  filesTreatmentRadiografia
                                                    .tomografia.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="15"
                                          >
                                            Tomografía
                                          </LabelNormal>
                                        </div>
                                      </InlineContainer>
                                    )}
                                  </div>
                                </div>
                              )}
                            {selectItem === "STL's" && (
                              <div className="row">
                                <ButtonFile
                                  onClick={() => setSteps(4)}
                                  icon={'edit'}
                                  size={25}
                                  style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 35,
                                  }}
                                />
                                <div className="col-md-7">
                                  {radioStl === 'model' && (
                                    <div>
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="17"
                                      >
                                        Se seleccionó envío de modelos por
                                        paquetería a la siguiente dirección:
                                      </LabelNormal>
                                      <InlineContainer>
                                        <CardModel style={{ maxWidth: 356 }}>
                                          <LabelNormal
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              marginRight: 7,
                                              marginBottom: 10,
                                            }}
                                            color="#757575"
                                            fontSize="18"
                                          >
                                            Dirección de envío | Borgatta
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#828282"
                                            style={{ margin: 0, padding: 0 }}
                                            fontSize="17"
                                          >
                                            {defaultAddress.id
                                              ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code} ${defaultAddress.state}`
                                              : ''}
                                          </LabelNormal>
                                        </CardModel>
                                      </InlineContainer>
                                    </div>
                                  )}
                                  {radioStl === 'url' && (
                                    <div>
                                      {link_data.map((item, key) => (
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="20"
                                        >
                                          {item}
                                        </LabelNormal>
                                      ))}
                                      {/* <img src={require('@images/icons/upload-edit-circle.png')} onClick={() => setSteps(4)} style={{height: 48, width: 48, alignSelf: 'flex-start', marginLeft: 30, cursor: 'pointer'}} /> */}
                                    </div>
                                  )}
                                  {radioStl === 'archivos' && (
                                    <>
                                      <InlineContainer>
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              alt="superior"
                                              src={
                                                filesTreatmentStl.arcada_superior &&
                                                filesTreatmentStl
                                                  .arcada_superior.preview
                                                  ? filesTreatmentStl
                                                      .arcada_superior.preview
                                                  : require('@images/stl/arcada-superior.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentStl.arcada_superior &&
                                            filesTreatmentStl.arcada_superior
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="14"
                                              >
                                                {
                                                  filesTreatmentStl
                                                    .arcada_superior.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="14"
                                          >
                                            Arcada superior
                                          </LabelNormal>
                                        </div>
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              alt="inferior"
                                              src={
                                                filesTreatmentStl.arcada_inferior &&
                                                filesTreatmentStl
                                                  .arcada_inferior.preview
                                                  ? filesTreatmentStl
                                                      .arcada_inferior.preview
                                                  : require('@images/stl/arcada-inferior.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentStl.arcada_inferior &&
                                            filesTreatmentStl.arcada_inferior
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="14"
                                              >
                                                {
                                                  filesTreatmentStl
                                                    .arcada_inferior.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="14"
                                          >
                                            Arcada inferior
                                          </LabelNormal>
                                        </div>
                                        {/* <img src={require('@images/icons/upload-edit-circle.png')} onClick={() => setSteps(4)} style={{height: 48, width: 48, alignSelf: 'flex-start', marginLeft: 30, cursor: 'pointer'}} /> */}
                                      </InlineContainer>
                                      <InlineContainer>
                                        {(oclusionStl === 'no' ||
                                          oclusionStl === 'si') && (
                                          <div
                                            style={{
                                              marginRight: 20,
                                              marginBottom: 20,
                                            }}
                                          >
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <img
                                                alt="derecha"
                                                src={
                                                  filesTreatmentStl.oclusion_derecha &&
                                                  filesTreatmentStl
                                                    .oclusion_derecha.preview
                                                    ? filesTreatmentStl
                                                        .oclusion_derecha
                                                        .preview
                                                    : require('@images/stl/oclusion-derecha.jpg')
                                                }
                                                style={{
                                                  height: 104,
                                                  width: 162,
                                                  borderRadius: 3,
                                                  backgroundPosition: 'cover',
                                                }}
                                              />
                                            </div>
                                            {filesTreatmentStl.oclusion_derecha &&
                                              filesTreatmentStl.oclusion_derecha
                                                .file && (
                                                <LabelNormal
                                                  style={{
                                                    width: 162,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                  }}
                                                  color="#828282"
                                                  fontSize="14"
                                                >
                                                  {
                                                    filesTreatmentStl
                                                      .oclusion_derecha.file
                                                      .name
                                                  }
                                                </LabelNormal>
                                              )}
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="14"
                                            >
                                              Oclusión derecha
                                            </LabelNormal>
                                          </div>
                                        )}
                                        {oclusionStl === 'si' && (
                                          <div
                                            style={{
                                              marginRight: 20,
                                              marginBottom: 20,
                                            }}
                                          >
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <img
                                                alt="derecha"
                                                src={
                                                  filesTreatmentStl.oclusion_derecha &&
                                                  filesTreatmentStl
                                                    .oclusion_derecha.preview
                                                    ? filesTreatmentStl
                                                        .oclusion_derecha
                                                        .preview
                                                    : require('@images/stl/oclusion-derecha.jpg')
                                                }
                                                style={{
                                                  height: 104,
                                                  width: 162,
                                                  borderRadius: 3,
                                                  backgroundPosition: 'cover',
                                                }}
                                              />
                                            </div>
                                            {filesTreatmentStl.oclusion_derecha &&
                                              filesTreatmentStl.oclusion_derecha
                                                .file && (
                                                <LabelNormal
                                                  style={{
                                                    width: 162,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                  }}
                                                  color="#828282"
                                                  fontSize="14"
                                                >
                                                  {
                                                    filesTreatmentStl
                                                      .oclusion_derecha.file
                                                      .name
                                                  }
                                                </LabelNormal>
                                              )}
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="14"
                                            >
                                              Oclusión derecha
                                            </LabelNormal>
                                          </div>
                                        )}
                                      </InlineContainer>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                            {selectItem === 'Extraorales' && (
                              <div>
                                <div
                                  className="row"
                                  style={{
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ButtonFile
                                    onClick={() => {
                                      setSteps(3);
                                      setSubStep(1);
                                    }}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_serio &&
                                            filesTreatmentExtraorales
                                              .frontal_serio.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_serio.preview
                                              : require('@images/extraorales/frontal_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_serio &&
                                        filesTreatmentExtraorales.frontal_serio
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_serio.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_sonriendo &&
                                            filesTreatmentExtraorales
                                              .frontal_sonriendo.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_sonriendo.preview
                                              : require('@images/extraorales/frontal_sonriendo.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                          alt="frontal"
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_sonriendo &&
                                        filesTreatmentExtraorales
                                          .frontal_sonriendo.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_sonriendo.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal sonriendo
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_retractore &&
                                            filesTreatmentExtraorales
                                              .frontal_retractore.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_retractore.preview
                                              : require('@images/extraorales/frontal_retractores.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_retractore &&
                                        filesTreatmentExtraorales
                                          .frontal_retractore.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_retractore.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal con retractores
                                      </LabelNormal>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.lateral_izquierda &&
                                            filesTreatmentExtraorales
                                              .lateral_izquierda.preview
                                              ? filesTreatmentExtraorales
                                                  .lateral_izquierda.preview
                                              : require('@images/extraorales/lateral_izq_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.lateral_izquierda &&
                                        filesTreatmentExtraorales
                                          .lateral_izquierda.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .lateral_izquierda.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Lateral izquierda serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.lateral_derecha &&
                                            filesTreatmentExtraorales
                                              .lateral_derecha.preview
                                              ? filesTreatmentExtraorales
                                                  .lateral_derecha.preview
                                              : require('@images/extraorales/lateral_der_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.lateral_derecha &&
                                        filesTreatmentExtraorales
                                          .lateral_derecha.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .lateral_derecha.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Lateral derecha serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectItem === 'Intraorales' && (
                              <div>
                                <InlineContainer>
                                  <ButtonFile
                                    onClick={() => setSteps(3)}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        alt="superior"
                                        src={
                                          filesTreatment.superior &&
                                          filesTreatment.superior.preview
                                            ? filesTreatment.superior.preview
                                            : require('@images/intraorales/superior.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.superior &&
                                      filesTreatment.superior.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.superior.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      width: 162,
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        alt="inferior"
                                        src={
                                          filesTreatment.inferior &&
                                          filesTreatment.inferior.preview
                                            ? filesTreatment.inferior.preview
                                            : require('@images/intraorales/inferior.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.inferior &&
                                      filesTreatment.inferior.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.inferior.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal inferior
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                                <InlineContainer>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        alt="derecha"
                                        src={
                                          filesTreatment.lateral_derecha &&
                                          filesTreatment.lateral_derecha.preview
                                            ? filesTreatment.lateral_derecha
                                                .preview
                                            : require('@images/intraorales/lateral-derecha.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.lateral_derecha &&
                                      filesTreatment.lateral_derecha.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment.lateral_derecha.file
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal
                                      color="#3C4549"
                                      fontSize="15"
                                      style={{ width: 162 }}
                                    >
                                      Lateral derecha en oclusión
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        alt="frontal"
                                        src={
                                          filesTreatment.frontal &&
                                          filesTreatment.frontal.preview
                                            ? filesTreatment.frontal.preview
                                            : require('@images/intraorales/frontal.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.frontal &&
                                      filesTreatment.frontal.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.frontal.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Frontal en oclusión
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        alt="izquierda"
                                        src={
                                          filesTreatment.lateral_izquierda &&
                                          filesTreatment.lateral_izquierda
                                            .preview
                                            ? filesTreatment.lateral_izquierda
                                                .preview
                                            : require('@images/intraorales/lateral-izquierda.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.lateral_izquierda &&
                                      filesTreatment.lateral_izquierda.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment.lateral_izquierda
                                              .file.name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal
                                      color="#3C4549"
                                      fontSize="15"
                                      style={{
                                        width: 162,
                                      }}
                                    >
                                      Lateral izquierda en oclusión
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </div>
                        </InlineContainer>
                      </div>
                    </div>
                  </ScrollableBody>
                )}
                {((steps === 7 && treatment === 'cryst_aligner') ||
                  (steps === 6 && treatment === 'smile_system')) && (
                  <ScrollableBody
                    style={{ width: '100%', overflowX: 'hidden' }}
                  >
                    <div className="row">
                      <div className="col-md-7">
                        {treatment === 'cryst_aligner' ? (
                          <>
                            <Title style={{ marginBottom: 5 }}>
                              Pago de planificación
                            </Title>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <LabelNormal color="#757575" fontSize="17">
                                Confirma tus datos de pago.
                              </LabelNormal>
                            </div>
                          </>
                        ) : (
                          <>
                            <Title style={{ marginBottom: 5 }}>
                              Pago de Smile System
                            </Title>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <LabelNormal color="#757575" fontSize="17">
                                Confirma tus datos de pago y aprueba tu caso
                              </LabelNormal>
                            </div>
                          </>
                        )}
                        <InlineContainer style={{ marginBottom: 10 }}>
                          <LabelNormal color="#436FF1" fontSize="15">
                            PX
                          </LabelNormal>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="22"
                          >{`${firstName} ${lastName}`}</LabelNormal>
                        </InlineContainer>
                        <InlineContainer style={{ marginBottom: 18 }}>
                          <ChipCategory>
                            <LabelNormal color="#060809" fontSize="13">
                              {treatment === 'cryst_aligner'
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </ChipCategory>
                        </InlineContainer>
                        {treatment === 'cryst_aligner' ? (
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            El pago de planificación incluye la revisión de{' '}
                            <br /> archivos, el visor para compartir con tu{' '}
                            <br /> paciente y su revisión. La manufactura de{' '}
                            <br /> tus alineadores se cotizará más adelante.
                          </LabelNormal>
                        ) : (
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            El pago del tratamiento incluye la revisión de
                            <br />
                            archivos, el Smartcheck, la manufactura y envío
                            <br />
                            de tu{' '}
                            {typePrice.pack && typePrice.pack.name
                              ? typePrice.pack.name
                              : ''}
                          </LabelNormal>
                        )}
                        <Checkbox
                          checked={invoice}
                          icon={
                            <FontAwesomeIcon
                              icon={faCheck}
                              color="#EB9542"
                              size="sm"
                            />
                          }
                          disabled={total - discount === 0 || transferSend}
                          borderColor="#C8C8C8"
                          style={{ overflow: 'hidden' }}
                          size={20}
                          onChange={(value) => setInvoice(value)}
                          labelStyle={{
                            marginLeft: 5,
                            userSelect: 'none',
                            fontFamily: 'neue-montreal-regular',
                            color:
                              total - discount === 0 ? '#3C454966' : '#3C4549',
                          }}
                          label="Facturar pago"
                        />
                        {total - discount === 0 && (
                          <LabelNormal
                            color="#9E9E9E"
                            fontSize="17"
                            style={{
                              marginLeft: 0,
                              marginTop: 10,
                            }}
                          >
                            En pagos con códigos no se emiten facturas
                          </LabelNormal>
                        )}
                        {invoice && (
                          <div style={{ marginTop: 29 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Información de facturación
                            </LabelNormal>
                            {defaultTaxData.business_name && (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 204,
                                  maxWidth: 334,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  padding: 16,
                                }}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.business_name
                                    ? defaultTaxData.business_name
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.rfc ? defaultTaxData.rfc : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >
                                  {defaultTaxData.street
                                    ? `${defaultTaxData.street} ${defaultTaxData.number}, ${defaultTaxData.suburb}, ${defaultTaxData.postal_code} ${defaultTaxData.state}`
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.cfdiUsage
                                    ? defaultTaxData.cfdiUsage.name
                                    : ''}
                                </LabelNormal>
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  label={'Editar'}
                                  onClick={() => editTaxDataModal()}
                                />
                              </CardInlineContainer>
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              marginBottom="30px"
                              onClick={() =>
                                defaultTaxData.business_name
                                  ? setChangePayment(true)
                                  : addTaxDataModal()
                              }
                              label={
                                defaultTaxData.business_name
                                  ? 'Cambiar perfil fiscal'
                                  : 'Añadir perfil fiscal'
                              }
                            />
                          </div>
                        )}
                        {treatment === 'smile_system' && (
                          <div style={{ marginTop: 20 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Información de envío
                            </LabelNormal>
                            {defaultAddressDoctor.name && (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 105,
                                  maxWidth: 334,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  padding: 16,
                                }}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultAddressDoctor.name
                                    ? defaultAddressDoctor.name
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >
                                  {defaultAddressDoctor.street
                                    ? `${defaultAddressDoctor.street} ${defaultAddressDoctor.number}, ${defaultAddressDoctor.suburb}, ${defaultAddressDoctor.postal_code} ${defaultAddressDoctor.state}`
                                    : ''}
                                </LabelNormal>
                              </CardInlineContainer>
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              marginBottom="30px"
                              onClick={() =>
                                defaultAddressDoctor.name
                                  ? setChangeAddress(true)
                                  : addDefaultAddressDoctorModal()
                              }
                              label={
                                defaultAddressDoctor.name
                                  ? 'Cambiar dirección de envío'
                                  : 'Añadir dirección de envío'
                              }
                            />
                            {!defaultAddressDoctor.name && showAddressError && (
                              <LabelNormal
                                color="#C60E02"
                                fontSize="17"
                                style={{ marginTop: 10 }}
                              >
                                *Agrega una dirección de envío
                              </LabelNormal>
                            )}
                            {errorPayment &&
                              treatment === 'smile_system' &&
                              (!doctorData.addresses ||
                                doctorData.addresses.length === 0) && (
                                <p
                                  style={{
                                    color: '#C60E02',
                                    width: 500,
                                    fontSize: 18,
                                    fontFamily: 'neue-montreal-regular',
                                  }}
                                >
                                  *Agrega una dirección de envío
                                </p>
                              )}
                          </div>
                        )}
                        <div style={{ marginTop: 46 }}>
                          <LabelNormal color="#3C4549" fontSize="22">
                            Método de pago
                          </LabelNormal>
                          <LabelNormal color="#757575" fontSize="17">
                            Selecciona un método de pago
                          </LabelNormal>
                          <div style={{ marginTop: 15 }}>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="payment-1"
                                  name="payment"
                                  onClick={() => {
                                    setPaymentMethod('payment_line');
                                    setSelectInstallment({});
                                    resetCouponForm();
                                  }}
                                  checked={
                                    paymentMethod === 'payment_line'
                                      ? 'checked'
                                      : false
                                  }
                                  type="radio"
                                />
                                <label for="payment-1" className="radio-label">
                                  Pago con tarjeta
                                </label>
                              </div>
                              <div
                                className="radio"
                                style={{
                                  marginRight: 20,
                                  cursor:
                                    total - discount === 0
                                      ? 'not-allowed'
                                      : 'pointer',
                                }}
                              >
                                <input
                                  id="payment-2"
                                  name="payment"
                                  onClick={() => {
                                    setPaymentMethod('payment_transfer');
                                    setTotal(originalTypePrice.amount);
                                    resetCouponForm();
                                  }}
                                  checked={
                                    paymentMethod === 'payment_transfer'
                                      ? 'checked'
                                      : false
                                  }
                                  type="radio"
                                  disabled={total - discount === 0}
                                />
                                <label
                                  for="payment-2"
                                  className="radio-label"
                                  style={{
                                    color:
                                      total - discount === 0
                                        ? '#3C454966'
                                        : '#3C4549',
                                    cursor:
                                      total - discount === 0
                                        ? 'not-allowed'
                                        : 'pointer',
                                  }}
                                >
                                  Pago por transferencia
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        {paymentMethod === 'payment_line' && (
                          <div
                            style={{
                              marginTop: 29,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 5,
                            }}
                          >
                            <LabelNormal color="#3C4549" fontSize="22">
                              Cobrado a
                            </LabelNormal>
                            {total - discount === 0 ? (
                              <LabelNormal color="#9E9E9E" fontSize="17">
                                No se realizará ningún cobro
                              </LabelNormal>
                            ) : (
                              <>
                                {defaultPayment.brand && (
                                  <CardInlineContainer
                                    style={{
                                      borderRadius: 10,
                                      minHeight: 71,
                                      maxWidth: 376,
                                      marginTop: 10,
                                      marginBottom: 10,
                                      padding: 16,
                                      minWidth: 338,
                                    }}
                                    key={Math.random()}
                                  >
                                    <InlineContainer>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: 38,
                                          width: 50,
                                          background: '#FAFAFB',
                                        }}
                                      >
                                        <img
                                          src={
                                            defaultPayment.brand ===
                                            'mastercard'
                                              ? require('@images/icon-cc/mastercard-transparent.png')
                                              : defaultPayment.brand === 'visa'
                                              ? require('@images/icon-cc/visa-transparent.png')
                                              : defaultPayment.brand === 'amex'
                                              ? require('@images/icon-cc/amex.jpg')
                                              : null
                                          }
                                          style={{ height: 'auto', width: 40 }}
                                          alt=""
                                        />
                                      </div>
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="18"
                                      >
                                        *
                                        {defaultPayment.last_digits
                                          ? defaultPayment.last_digits
                                          : ''}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </CardInlineContainer>
                                )}
                                {!loadingPaymentInstallment &&
                                  paymentInstallments.installments && (
                                    <>
                                      <CardInlineContainer
                                        style={{
                                          minHeight: 71,
                                          maxWidth: 376,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          padding: 16,
                                          minWidth: 338,
                                          borderTopRightRadius: 10,
                                          borderTopLeftRadius: 10,
                                          borderBottomRightRadius: 0,
                                          borderBottomLeftRadius: 0,
                                        }}
                                        key={Math.random()}
                                      >
                                        <InlineContainer>
                                          <div
                                            className="radio"
                                            style={{ marginRight: 8 }}
                                          >
                                            <input
                                              id="installments-0"
                                              name="installments"
                                              onClick={() => {
                                                resetCouponForm();
                                                setSelectInstallment({});
                                                setTotal(
                                                  originalTypePrice.amount
                                                );
                                              }}
                                              checked={
                                                Object.keys(selectInstallment)
                                                  .length !== 0
                                                  ? false
                                                  : 'checked'
                                              }
                                              type="radio"
                                              disabled={
                                                paymentInstallments.installments
                                                  .length === 0
                                              }
                                            />
                                            <label
                                              htmlFor="installments-0"
                                              className="radio-label"
                                            ></label>
                                          </div>
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="17"
                                          >{`1x ${firstPaymentInstallment.toFixed(
                                            2
                                          )} ${
                                            originalTypePrice.currency
                                          }`}</LabelNormal>
                                        </InlineContainer>
                                      </CardInlineContainer>
                                      {paymentInstallments.installments.map(
                                        (item, index) => {
                                          if (item.count < 9) {
                                            return (
                                              <CardInlineContainer
                                                style={{
                                                  minHeight: 71,
                                                  maxWidth: 376,
                                                  marginTop: 0,
                                                  marginBottom: 0,
                                                  padding: 16,
                                                  minWidth: 338,
                                                  borderTopRightRadius:
                                                    index + 1 === 0 ? 10 : 0,
                                                  borderTopLeftRadius:
                                                    index + 1 === 0 ? 10 : 0,
                                                  borderBottomRightRadius:
                                                    index + 1 ===
                                                    paymentInstallments
                                                      .installments.length -
                                                      1
                                                      ? 10
                                                      : 0,
                                                  borderBottomLeftRadius:
                                                    index + 1 ===
                                                    paymentInstallments
                                                      .installments.length -
                                                      1
                                                      ? 10
                                                      : 0,
                                                }}
                                                key={Math.random()}
                                              >
                                                <InlineContainer>
                                                  <div
                                                    className="radio"
                                                    style={{ marginRight: 8 }}
                                                  >
                                                    <input
                                                      id={`installments-${
                                                        index + 1
                                                      }`}
                                                      disabled={item.count === selectInstallment.count}
                                                      name="installments"
                                                      onClick={() => {
                                                        resetCouponForm();
                                                        setSelectInstallment({
                                                          count: item.count,
                                                          interval:
                                                            item.interval,
                                                          type: item.type,
                                                          installment_price:
                                                            item.price,
                                                        });
                                                        setTotal(
                                                          parseFloat(
                                                            item.price
                                                              .replace('$', '')
                                                              .replace(
                                                                /[USD||MXN]/g,
                                                                ''
                                                              )
                                                          )
                                                        );
                                                      }}
                                                      checked={
                                                        selectInstallment.count !==
                                                        item.count
                                                          ? false
                                                          : 'checked'
                                                      }
                                                      type="radio"
                                                    />
                                                    <label
                                                      htmlFor={`installments-${
                                                        index + 1
                                                      }`}
                                                      className="radio-label"
                                                    ></label>
                                                  </div>
                                                  <LabelNormal
                                                    color="#3C4549"
                                                    fontSize="17"
                                                  >{`${item.description}`}</LabelNormal>
                                                  <LabelNormal
                                                    color="#3C4549"
                                                    fontSize="17"
                                                    style={{
                                                      paddingLeft: 10,
                                                      color: '#757575',
                                                    }}
                                                  >{`${item.price}`}</LabelNormal>
                                                </InlineContainer>
                                              </CardInlineContainer>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </>
                                  )}
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  onClick={() =>
                                    defaultPayment.brand
                                      ? setChangeCard(true)
                                      : addCardModal()
                                  }
                                  label={
                                    defaultPayment.brand
                                      ? 'Cambiar método de pago'
                                      : 'Añadir método de pago'
                                  }
                                />
                              </>
                            )}

                            {errorPayment &&
                              (!doctorData.cards ||
                                doctorData.cards.length === 0) && (
                                <p
                                  style={{
                                    color: '#C60E02',
                                    position: 'absolute',
                                    bottom: -48,
                                    width: 550,
                                    fontSize: 18,
                                    fontFamily: 'neue-montreal-regular',
                                  }}
                                >
                                  *Agrega una tarjeta o selecciona Pago por
                                  transferencia
                                </p>
                              )}
                          </div>
                        )}
                        {paymentMethod === 'payment_transfer' && (
                          <div className="row">
                            <div
                              className="col-md-9"
                              style={{ marginTop: 25, marginBottom: 100 }}
                            >
                              <LabelNormal color="#3C4549" fontSize="22">
                                Datos para transferencia
                              </LabelNormal>
                              {!loadingTransfer && (
                                <>
                                  {!transferSend && (
                                    <LabelNormal color="#757575" fontSize="19">
                                      Da clic en Obtener datos bancarios para
                                      ver los datos bancarios
                                    </LabelNormal>
                                  )}
                                  {transferSend && !transferError && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: '5px',
                                        gap: '35px',
                                        marginTop: '15px',
                                      }}
                                    >
                                      <TransferInfo
                                        bankName={bankName}
                                        clabe={clabe}
                                        referenceCode={referenceCode}
                                        treatment={treatment}
                                      />
                                      <Warning
                                        title="En espera de pago"
                                        body={
                                          <ol style={{ paddingLeft: '25px' }}>
                                            <li>
                                              <b>
                                                Recuerda poner la referencia que
                                                se indica
                                              </b>
                                              , de lo contrario tu pago podría
                                              no reflejarse en el caso.
                                            </li>
                                            <li>
                                              <b>
                                                Realiza tu pago lo antes posible
                                                para activar tu caso.
                                              </b>{' '}
                                              Puedes volver a esta pantalla
                                              ingresando el perfil de caso para
                                              corroborar los datos.
                                            </li>
                                          </ol>
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {loadingTransfer && (
                                <div style={{ marginTop: 25 }}>
                                  <Inline>
                                    <BorderLoader className="text-center">
                                      <div className="loader-inner line-spin-fade-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    </BorderLoader>
                                  </Inline>
                                  <div
                                    className="text-center"
                                    style={{ marginTop: 20 }}
                                  >
                                    <LabelNormal
                                      color="#4F4F4F"
                                      fontSize="17"
                                      style={{ marginBottom: 14 }}
                                    >
                                      Cargando datos bancarios
                                    </LabelNormal>
                                  </div>
                                </div>
                              )}
                              {transferError && (
                                <div
                                  style={{
                                    marginTop: 45,
                                    borderRadius: 13,
                                    padding: '15px 18px',
                                  }}
                                >
                                  <InlineContainer style={{ marginBottom: 11 }}>
                                    <img
                                      src={require('@images/icons/warning-dark.png')}
                                      style={{
                                        height: 29,
                                        width: 29,
                                        marginRight: 10,
                                        marginBottom: 50,
                                      }}
                                      alt=""
                                    />
                                    <LabelNormal color="#6B4D00" fontSize="17">
                                      Lo sentimos, hubo un problema al a cargar
                                      los datos de pago por transferencia,
                                      intenta con Pago con tarjeta o inténtalo
                                      más tarde.
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              )}
                              <br />
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="col-md-4"
                        style={{ ...scrollY, overflowY: 'auto' }}
                      >
                        {treatment === 'smile_system' && (
                          <div>
                            <LabelNormal color="#757575" fontSize="17">
                              Tu pago incluye:
                            </LabelNormal>
                            {packType?.name && (
                              <>
                                {packType?.name ===
                                  'Fit Jig + Brackets Black X-pression' && (
                                  <img
                                    src={require('@images/payment-include/x-pression.png')}
                                    style={{ height: 130, width: 142 }}
                                    alt=""
                                  />
                                )}
                                {packType?.name ===
                                  'Fit Jig + Brackets X-pression C' && (
                                  <img
                                    src={require('@images/payment-include/x-pression-C.png')}
                                    style={{ height: 130, width: 142 }}
                                    alt=""
                                  />
                                )}
                                {packType?.name ===
                                  'Fit Jig + Brackets Nova C' && (
                                  <img
                                    src={require('@images/payment-include/nova-ceramico.png')}
                                    style={{ height: 130, width: 142 }}
                                    alt=""
                                  />
                                )}
                                {packType?.name ===
                                  'Fit Jig + Brackets Nova metálico' && (
                                  <img
                                    src={require('@images/payment-include/Nova-metalico.png')}
                                    style={{ height: 130, width: 142 }}
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                            <img
                              src={require('@images/payment-include/fitJig.png')}
                              style={{ height: 130, width: 142 }}
                              alt=""
                            />
                          </div>
                        )}
                        {treatment === 'cryst_aligner' && (
                          <div>
                            <LabelNormal color="#757575" fontSize="17">
                              Tu pago incluye:
                            </LabelNormal>
                            <img
                              src={require('@images/payment-include/planificacion.png')}
                              style={{ height: 130, width: 142 }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ marginTop: 40, marginBottom: 20 }}>
                          <LabelNormal
                            color="#757575"
                            fontSize="13"
                            style={{ letterSpacing: '0.2em' }}
                          >
                            TOTAL
                          </LabelNormal>
                          <InlineContainer>
                            <LabelNormal color="#3C4549" fontSize="37">
                              $ {(total - discount).toFixed(2)}
                            </LabelNormal>
                            <LabelNormal fontSize="24" color="#3C4549">
                              {typePrice.currency ? typePrice.currency : ''}
                            </LabelNormal>
                          </InlineContainer>
                          {discount !== 0 && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <LabelNormal
                                color="#BDBDBD"
                                fontSize="13"
                                style={{ marginBottom: 0 }}
                              >
                                {treatment === 'cryst_aligner'
                                  ? 'Planificación'
                                  : 'Planificación y manufactura'}
                              </LabelNormal>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <LabelNormal
                                  color="#EB9542"
                                  fontSize="23.75"
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  $ {total.toFixed(2)}{' '}
                                </LabelNormal>
                                <LabelNormal fontSize="15.4" color="#828282">
                                  {typePrice.currency ? typePrice.currency : ''}
                                </LabelNormal>
                              </div>
                            </div>
                          )}
                          {treatment === 'smile_system' &&
                            conversionToMXN !== 0 && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <LabelNormal
                                    color="#9E9E9E"
                                    fontSize="23.75"
                                    style={{ margin: '0px 0px 0px 5px' }}
                                  >
                                    ${' '}
                                    {(
                                      conversionToMXN *
                                      (total - discount)
                                    ).toFixed(2)}
                                  </LabelNormal>
                                  <LabelNormal
                                    fontSize="15.4"
                                    color="#9E9E9E"
                                    style={{ marginBottom: 3 }}
                                  >
                                    MXN
                                  </LabelNormal>
                                </div>
                              </>
                            )}
                        </div>

                        {paymentMethod === 'payment_line' &&
                          treatment === 'cryst_aligner' &&
                          errorPayment && (
                            <div
                              style={{
                                marginBottom: 18,
                                background: '#FFE5E3',
                                border: '1px solid #E41D11',
                                padding: '5px 10px 7px 10px',
                                borderRadius: 7,
                                maxWidth: 256,
                              }}
                            >
                              <LabelNormal fontSize="14" color="#C60E02">
                                *Completa los campos faltantes
                              </LabelNormal>
                            </div>
                          )}
                        {paymentMethod === 'payment_line' && (
                          <ButtonPrime
                            disabled={loadingPaymentInstallment}
                            onClick={() => validation_payment_line()}
                            label="Enviar caso"
                          ></ButtonPrime>
                        )}

                        {paymentMethod === 'payment_transfer' && (
                          <>
                            <ButtonPrime
                              disabled={transferSend ? true : false}
                              onClick={() => submitPayTreatment()}
                              label="Obtener datos bancarios"
                            ></ButtonPrime>
                            {transferSend ? (
                              <Column style={{ maxWidth: '300px' }}>
                                <Row alignItems="center" gap="10px">
                                  <Check />
                                  <LabelOrange>
                                    Pago por transferencia habilitado
                                  </LabelOrange>
                                </Row>
                                <LabelDescription>
                                  El caso quedará activo una vez veamos
                                  reflejado tu pago, el proceso puede tomar
                                  algunas horas.
                                </LabelDescription>
                              </Column>
                            ) : null}
                          </>
                        )}

                        {(treatment === 'cryst_aligner' ||
                          treatment === 'smile_system') && (
                          <CouponForm
                            reset={resetCoupon}
                            isDisabled={transferSend}
                            originalPrice={total}
                            onReset={() => {
                              setDiscount(0);
                              setCouponId(null);
                              setFirstPaymentInstallment(
                                originalTypePrice.amount
                              );
                              setTotal(originalTypePrice.amount);
                              setSelectInstallment({});
                            }}
                            onChange={({ discount, couponId }) => {
                              const newPrice = total - discount;
                              setDiscount(discount);
                              setCouponId(couponId);

                              if (newPrice === 0) {
                                setInvoice(false);
                              }
                              
                              setResetCoupon(false);
                              setFirstPaymentInstallment(newPrice);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </ScrollableBody>
                )}
              </div>
            </div>
          )}
          {changeCard && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <Title style={{ marginBottom: 5 }}>Datos de pago</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta tarjeta cargaremos el monto del tratamiento
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Tarjetas guardadas
                  </LabelNormal>
                  {cards && cards.length > 0 && (
                    <>
                      {cards.map((card, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`card-payment-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={card.default ? 'checked' : false}
                              type="radio"
                            />
                            <label
                              htmlFor={`card-payment-${index}`}
                              onClick={() =>
                                changeDefaultCard({
                                  variables: {
                                    user_id: localStorage.getItem('b360-id'),
                                    payment_method_ca: card.service_id_ca,
                                    payment_method_ss: card.service_id_ss,
                                  },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 71,
                              maxWidth: 376,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                            }}
                          >
                            <InlineContainer>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: 38,
                                  width: 50,
                                  background: '#FAFAFB',
                                }}
                              >
                                <img
                                  src={
                                    card.brand === 'mastercard'
                                      ? require('@images/icon-cc/mastercard-transparent.png')
                                      : card.brand === 'visa'
                                      ? require('@images/icon-cc/visa-transparent.png')
                                      : card.brand === 'amex'
                                      ? require('@images/icon-cc/amex-transparent.png')
                                      : null
                                  }
                                  style={{ height: 'auto', width: 40 }}
                                  alt=""
                                />
                              </div>
                              <LabelNormal color="#757575" fontSize="17">
                                {card.brand
                                  ? card.brand.charAt(0).toUpperCase() +
                                    card.brand.slice(1)
                                  : ''}
                              </LabelNormal>
                              <LabelNormal color="#3C4549" fontSize="18">
                                ****{card.last_digits ? card.last_digits : ''}
                              </LabelNormal>
                            </InlineContainer>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addCardModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir tarjeta'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeCard(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
          {changePayment && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <Title style={{ marginBottom: 5 }}>
                    Información de facturación
                  </Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      Con estos datos fiscales facturamos el pago de tus
                      tratamientos, si usas perfiles diferentes puedes
                      agregarlos todos aquí.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Datos fiscales guardados
                  </LabelNormal>
                  {doctorData.taxdatas && doctorData.taxdatas.length > 0 && (
                    <>
                      {doctorData.taxdatas.map((taxdata, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`taxdata-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                taxdata.id === defaultTaxData.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor={`taxdata-${index}`}
                              onClick={() =>
                                changeDefaultTaxdata({
                                  variables: { tax_data_id: taxdata.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 204,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.business_name
                                ? taxdata.business_name
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.rfc ? taxdata.rfc : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {taxdata.street
                                ? `${taxdata.street} ${taxdata.number}, ${taxdata.suburb}, ${taxdata.postal_code} ${taxdata.state}`
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#3C4549"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.cfdiUsage ? taxdata.cfdiUsage.name : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addTaxDataModal()}
                    marginBottom="30px"
                    label={'Añadir perfil fiscal'}
                  />
                  <ButtonPrime
                    onClick={() => setChangePayment(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
          {changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7" style={{ marginBottom: 100 }}>
                  <Title style={{ marginBottom: 5 }}>Dirección de Envío</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta dirección enviaremos tu tratamiento.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Direcciones guardados
                  </LabelNormal>
                  {doctorData.addresses && doctorData.addresses.length > 0 && (
                    <>
                      {doctorData.addresses.map((address, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`address-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                address.id === defaultAddressDoctor.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor={`address-${index}`}
                              onClick={() =>
                                changeDefaultAddressDoctor({
                                  variables: { address_id: address.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 105,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {address.name ? address.name : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {address.street
                                ? `${address.street} ${address.number}, ${address.suburb}, ${address.postal_code} ${address.state}`
                                : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    marginBottom="30px"
                    onClick={() => addDefaultAddressDoctorModal()}
                    label={'Añadir dirección de envío'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeAddress(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
        </ContainContent>
      </ContainHome>
      <Prompt
        when={!saved && steps < 7}
        message="¿Seguro que deseas salir? Hay cambios sin guardar"
      />
    </div>
  );
};

// Styled Components

const ContainHome = styled.div`
  width: 100%;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const ScrollableBody = styled.div`
  // overflow-y: auto;
  // max-height: 79vh;
  width: 100%;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const ChipCategory = styled.div`
  border: 1.15px solid #060809;
  border-radius: 51.37px;
  padding: 5px 13px 6px 13px;
  box-sizing: border-box;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const CardModelExtended = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 500px;
  min-height: 143px;
  margin-right: 50px;
`;

const SeparatorItem = styled.div`
  width: 10.5px !important;
  height: 5.5px;
  border-radius: 20px;
  margin-top: 14px;
  border: 1.5px solid #dd9139;
`;

const ItemShowMore = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyShowMore = styled.div`
  margin: 15px 6px;
  padding-left: 10px;
`;

const HeaderShowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 22.5px;
`;

const ContainerShowMore = styled.div`
  min-height: 43px;
  max-width: 518px;
  border-radius: 25px;
  background: #fff;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;

const mapStateToProps = (state) => ({
  role_id: state.auth.role_id,
});

export default connect(mapStateToProps, null)(NewCases);
