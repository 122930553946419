import { useForm, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_COUNTRIES } from '@graphql/query';
import { Column, ErrorMessage, InlineContainer, LabelInput, Row } from "../shared/Styled"
import TextInput from "../shared/inputs/TextInput"
import Select from "../shared/Select"
import { useState, useEffect } from "react"
import NumberInput from "../shared/inputs/NumberInput";
import DateInput from "../shared/inputs/DateInput";
import PhoneInput from "../shared/inputs/PhoneInput";
import Checkbox from "react-custom-checkbox";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import B360NetworkDoctorOfficeForm from "./B360NetworkDoctorOfficeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateDate } from "../../utils";

const RegisterForm = ({
  parentErrors,
  setValid,
  setValues,
  defaultValues = null,
  officeDefaultValues = null,
  addressDefaultValues = null,
  mode = 'onBlur',
  submit = 0,
  afterValidate = () => {},
  edit = false,
}) => {
  const [countries, setCountries] = useState([]);
  const [treatment, setTreatment] = useState('');
  const [checkB360, setCheckB360] = useState(false);
  const [validDoctorOffice, setValidDoctorOffice] = useState(true);
  const [doctorOfficeData, setDoctorOfficeData] = useState({});
  const [triggerClick, setTriggerClick] = useState(0);
  const [clear, setClear] = useState(0);

  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    setError,
    setValue,
    watch,
    register,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      gender: '',
      country_id: '',
      cryst_aligner: false,
      smile_system: false,
      birthdate: '',
      professional_license: '',
    },
    mode: mode,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key]),
      );
      if (defaultValues.cryst_aligner && defaultValues.smile_system)
        setTreatment('Ambos');
      else if (defaultValues.cryst_aligner) setTreatment('Cryst Aligner');
      else if (defaultValues.smile_system) setTreatment('Smile System');
      trigger();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (officeDefaultValues) setCheckB360(true);
  }, [officeDefaultValues]);

  const watchAllFields = useWatch({ control });

  const [getCountries] = useLazyQuery(QUERY_GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCountries(data.countries.data);
    },
  });

  const watchGender = watch('gender');
  const watchBirthdate = watch('birthdate');
  const watchCountry = watch('country_id');
  const watchCrystAligner = watch('cryst_aligner');
  const watchSmileSystem = watch('smile_system');

  const setCountry = (id) => setValue('country_id', id);

  const validateCountry = () => {
    if (watchCountry === null || watchCountry === '')
      setError('country_id', {
        type: 'required',
        message: 'Selecciona un país',
      });
    else clearErrors('country_id');
  };

  const validateTreatment = () => {
    if (!watchCrystAligner && !watchSmileSystem)
      setError('cryst_aligner', {
        type: 'required',
        message: 'Selecciona un tratamiento',
      });
    else clearErrors('cryst_aligner');
  };

  const validateBirthdate = () => {
    if (!watchBirthdate || !validateDate(watchBirthdate))
      setError('birthdate', { type: 'custom', message: '*Fecha incorrecta' });
    else clearErrors('birthdate');
  };

  useEffect(() => {
    if (treatment === 'Cryst Aligner' || treatment === 'Ambos')
      setValue('cryst_aligner', true);
    else setValue('cryst_aligner', false);
    if (treatment === 'Smile System' || treatment === 'Ambos')
      setValue('smile_system', true);
    else setValue('smile_system', false);
  }, [treatment]);

  useEffect(() => {
    if (watchGender) clearErrors('gender');
  }, [watchGender]);

  useEffect(() => {
    if (watchCountry) validateCountry();
  }, [watchCountry]);

  useEffect(() => {
    if (watchBirthdate) validateCountry()
}, [watchBirthdate]);

  useEffect(() => {
    if (watchCrystAligner || watchSmileSystem) validateTreatment();
  }, [watchCrystAligner, watchSmileSystem]);

  useEffect(() => {
    if (checkB360) {
      setValid(isValid && validDoctorOffice);
      if (isValid && validDoctorOffice) {
        const values = getValues();
        setValues({
          ...values,
          ...doctorOfficeData,
        });
      } else {
        setValues({});
      }
    } else {
      setValid(isValid);
      if (isValid) {
        const values = getValues();
        setValues({
          ...values,
        });
      } else {
        setValues({});
      }
    }
  }, [isValid, validDoctorOffice, watchAllFields, doctorOfficeData, checkB360]);

  useEffect(() => {
    if (checkB360) setClear(clear + 1);
  }, [checkB360]);

  useEffect(() => {
    const validate = async () => {
      if (submit > 0) {
        validateCountry();
        validateTreatment();
        validateBirthdate();
        await trigger();
        afterValidate(triggerClick + 1);
        setTriggerClick(triggerClick + 1);
      }
    };
    validate();
  }, [submit]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Column>
      <Row alignItems="flex-end" marginTop="32" gap="10px">
        <TextInput
          control={control}
          name="first_name"
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: '*Introduce el nombre(s)' }}
          placeholder="Nombre(s)"
          label="Nombres y apellidos"
          parentProps={{
            style: {
              flex: '0 0 40%',
              maxWidth: '40%',
            },
          }}
        />
        <TextInput
          control={control}
          name="last_name"
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: '*Introduce tus apellidos' }}
          placeholder="Apellidos"
          parentProps={{
            style: {
              flex: '0 0 40%',
              maxWidth: '40%',
            },
          }}
        />
      </Row>
      <Row>
        <Column gap="5px">
          <LabelInput>Género</LabelInput>
          <InlineContainer>
            <div className="radio" style={{ marginRight: 20 }}>
              <input
                id="radio-1"
                {...register('gender', { required: '*Selecciona tu género' })}
                type="radio"
                value="Masculino"
              />
              <label htmlFor="radio-1" className="radio-label">
                Masculino
              </label>
            </div>
            <div className="radio">
              <input
                id="radio-2"
                {...register('gender', { required: '*Selecciona tu género' })}
                type="radio"
                value="Femenino"
              />
              <label htmlFor="radio-2" className="radio-label">
                Femenino
              </label>
            </div>
          </InlineContainer>
          <InlineContainer>
            <ErrorMessage>
              {errors && errors['gender'] && errors['gender']?.message}
            </ErrorMessage>
          </InlineContainer>
        </Column>
      </Row>
      <Row>
        <div style={{ flex: '0 0 40%', maxWidth: '40%' }}>
          <Select
            name="country_id"
            options={countries}
            errors={errors}
            onSelect={setCountry}
            value={watchCountry}
            placeholder="Selecciona un país"
            label="País de residencia"
            style={{
              borderColor: errors['country_id'] ? '#C60E02' : '#D1D1D1',
            }}
            maxHeight="51px"
          />
        </div>
      </Row>
      <Row>
        <Column>
          <DateInput
            control={control}
            name="birthdate"
            errors={errors}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            value={defaultValues}
            rules={{
              required: '*Introduce tu fecha de nacimiento',
              pattern: {
                value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                message: '*Fecha incorrecta',
              },
            }}
            label="Fecha de nacimiento"
          />
        </Column>
      </Row>
      <Row gap="10px">
        <PhoneInput
          control={control}
          name="phone"
          countries={countries}
          country={watchCountry}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          rules={{ required: '*Ingresa el teléfono' }}
          label="Teléfono"
          placeholder="Teléfono"
        />
        <TextInput
          control={control}
          name="email"
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: '*Ingresa tu correo',
            pattern: {
              value:
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
              message: 'Correo inválido',
            },
          }}
          label="Correo"
          placeholder="Tu correo"
          parentProps={{
            style: {
              flex: '0 0 40%',
              maxWidth: '40%',
            },
          }}
        />
      </Row>
      <Row>
        <Column gap="5px">
          <LabelInput>Tratamiento/s</LabelInput>
          <InlineContainer>
            <div className="radio" style={{ marginRight: 20 }}>
              <input
                id="treatament-1"
                name="treatment"
                onClick={() => setTreatment('Cryst Aligner')}
                checked={treatment === 'Cryst Aligner' ? 'checked' : false}
                onChange={() => {}}
                type="radio"
              />
              <label htmlFor="treatament-1" className="radio-label">
                Cryst Aligner
              </label>
            </div>
            <div className="radio" style={{ marginRight: 20 }}>
              <input
                id="treatament-2"
                name="treatment"
                onClick={() => setTreatment('Smile System')}
                checked={treatment === 'Smile System' ? 'checked' : false}
                onChange={() => {}}
                type="radio"
              />
              <label htmlFor="treatament-2" className="radio-label">
                Smile System
              </label>
            </div>
            <div className="radio">
              <input
                id="treatament-3"
                name="treatment"
                onClick={() => setTreatment('Ambos')}
                checked={treatment === 'Ambos' ? 'checked' : false}
                onChange={() => {}}
                type="radio"
              />
              <label htmlFor="treatament-3" className="radio-label">
                Ambos tratamientos
              </label>
            </div>
          </InlineContainer>
          <InlineContainer>
            <ErrorMessage>
              {errors &&
                errors['cryst_aligner'] &&
                errors['cryst_aligner']?.message}
            </ErrorMessage>
          </InlineContainer>
        </Column>
      </Row>
      <Row marginTop="6">
        <Checkbox
          checked={checkB360}
          icon={<FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />}
          borderColor="#C8C8C8"
          style={{ overflow: 'hidden' }}
          size={20}
          onChange={(value) => setCheckB360(value)}
          labelStyle={{
            marginLeft: 5,
            userSelect: 'none',
            fontFamily: 'neue-montreal-regular',
            color: '#3C4549',
          }}
          label="Por este medio acepto pertenecer a la RED B360 y autorizo que se publiquen mis datos en www.b360.mx"
        />
      </Row>
      {checkB360 && (
        <Row marginTop="24" style={{ width: '100%' }}>
          <B360NetworkDoctorOfficeForm
            countries={countries}
            country={watchCountry}
            setValid={setValidDoctorOffice}
            setValues={setDoctorOfficeData}
            submit={submit}
            clear={clear}
            defaultValues={officeDefaultValues}
            defaultValuesAddress={addressDefaultValues}
          />
        </Row>
      )}
      <Row marginTop={checkB360 ? '0' : '24'}>
        <NumberInput
          control={control}
          label="Cédula profesional"
          placeholder="Cédula"
          name="professional_license"
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          rules={{
            required: '*Introduce tu cédula profesional',
            maxLength: {
              value: 8,
              message: 'Longitud máxima de 8 caracteres',
            },
            minLength: {
              value: 8,
              message: 'Longitud mínima de 8 caracteres',
            },
          }}
          limit={8}
        />
      </Row>
      <Row>
        {parentErrors.length > 0 && (
          <ErrorMessage>
            {parentErrors[0] === 'The email has already been registred.'
              ? 'Correo registrado anteriormente'
              : parentErrors[0]}
          </ErrorMessage>
        )}
      </Row>
    </Column>
  );
};

export default RegisterForm;
