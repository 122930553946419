import React from 'react';
import styled from "styled-components";

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelNormalWH = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
    transition: all .3s ease-out;
    &:hover {
        text-decoration: underline;
    }
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

 const Breadcrumb = (props) =>{
    if(props.elements === 1 && props.onEndClick){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onEndClick()}>{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    } else if(props.elements === 1){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14">{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    } else if(props.elements === 2 && props.onSecondClick){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} >{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} fontSize="14" onClick={() => props.onSecondClick()}>{props.midLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14">{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    } else if(props.elements === 2){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} >{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onSecondClick()} fontSize="14">{props.midLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14">{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    } else if(props.elements === 3 && props.onSecondClick && props.onTirdClick){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onSecondClick()} fontSize="14">{props.midLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onTirdClick()} fontSize="14">{props.midSecondLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14">{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    } else if(props.elements === 3 && props.onTirdClick){
        return(
            <InlineContainer style={{alignItems: 'center'}}>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onClick()} fontSize="14">{props.mainLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} fontSize="14">{props.midLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormalWH color="#BDBDBD" style={{marginRight: 5, cursor: 'pointer'}} onClick={() => props.onTirdClick()} fontSize="14">{props.midSecondLabel}</LabelNormalWH>
                <LabelNormal color="#757575" style={{marginRight: 5}} fontSize="14">{'>'}</LabelNormal>
                <LabelNormal color="#757575" fontSize="14">{props.endLabel}</LabelNormal>
            </InlineContainer>
        );
    }
 }
 
 export default Breadcrumb;