import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import Checkbox from "./Checkbox";
import NativeClickListener from "./NativeClickListener";

const DropDownContainer = styled("div")`
  width: 10.5em;
`;

const DropDownHeader = styled("div")`
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 500;
  font-size: 18.5px;
  font-family: neue-montreal-regular;
  color: #000;
  display:flex;
  flex-direction: row;
  align-items:center;
  cursor: pointer;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  width: 216px;
  margin-left: 18px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  min-width: 270px;
  border-radius: 17px;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelItem = styled.label`
  font-family: neue-montreal-regular;
  font-size: 17px;
  font-weight: 400;
  color: #3C4549;
`;

export default function DropdownCustom(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  return (
    <NativeClickListener onClick={() => setIsOpen(false)}>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {props.label || ''}
          {isOpen ? 
          <FontAwesomeIcon icon={faChevronUp} size="xs" style={{marginLeft: 15}} />
          : <FontAwesomeIcon icon={faChevronDown} size="xs" style={{marginLeft: 15}} />
          }
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {props.data.map(option => (
                <ListItem key={Math.random()}>
                  <Checkbox
                    id={`${option.id}-checked`} 
                    checked={props.selectedOption.indexOf(option.id) !== -1 ? 'checked' : false} 
                    onChange={() => {
                      if(props.selectedOption.indexOf(option.id) === -1) {
                        props.setSelectedOption([...props.selectedOption, option.id])
                      } else {
                        props.setSelectedOption(props.selectedOption.filter(sl => sl !== option.id))
                      }
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <LabelItem htmlFor={`${option.id}-checked`} >{option.name}</LabelItem>
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </NativeClickListener>
  );
}