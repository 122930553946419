import styled from "styled-components";
import { LabelNormal } from "../shared/Styled";
import LabelUnderline from "../shared/LabelUnderline";

const TaxDataCard = ({ taxData, onClick }) => (
  <CardInlineContainer
    style={{
      borderRadius: 10,
      minHeight: 204,
      maxWidth: 334,
      marginTop: 10,
      marginBottom: 10,
      padding: 16,
      boxShadow: '0px 0px 8px rgba(162, 162, 162, 0.1)'
    }}
  >
    <LabelNormal color="#3C4549" fontSize="18" style={{ marginBottom: 10 }}>
      {taxData.business_name ? taxData.business_name : ''}
    </LabelNormal>
    <LabelNormal color="#757575" fontSize="16" style={{ marginBottom: 10 }}>
      {taxData.rfc ? taxData.rfc : ''}
    </LabelNormal>
    <LabelNormal
      color="#757575"
      fontSize="16"
      style={{ marginBottom: 10, width: 302 }}
    >
      {taxData.street
        ? `${taxData.street} ${taxData.number}, ${taxData.suburb}, ${taxData.postal_code} ${taxData.state}`
        : ''}
    </LabelNormal>
    <LabelNormal color="#3C4549" fontSize="16" style={{ marginBottom: 10 }}>
      {taxData.cfdiUsage ? taxData.cfdiUsage.name : ''}
    </LabelNormal>
    <LabelUnderline
      color="#757575"
      fontSize="17"
      label={'Editar'}
      onClick={onClick}
    />
  </CardInlineContainer>
);

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

export default TaxDataCard