/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import DownloadError from '@components/shared/DownloadError';
import FileUploader from '@components/shared/FileUploader';
import ButtonFile from '@components/shared/ButtonFile';
import { LabelNormal } from '@components/shared/Styled';
import DragBack from '@images/background-drag.svg';
import FeatherIcon from 'feather-icons-react';
import { DownloadButton, StateContainer } from '../../shared/Styled';

const type = {
  // Intraorales
  superior: 'OCLUSAL_SUPERIOR',
  inferior: 'OCLUSAL_INFERIOR',
  lateral_izquierda: 'LAT_IZQ_OCLUSION',
  lateral_derecha: 'LAT_DER_OCLUSION',
  frontal: 'FRONTAL_OCLUSION',
  // Extraorales
  frontal_serio: 'FRONTAL_SERIO',
  frontal_sonriendo: 'FRONTAL_SONRIENDO',
  ext_lateral_izquierda: 'LATERAL_IZQ_SERIO',
  ext_lateral_derecha: 'LATERAL_DER_SERIO',
  frontal_retractore: 'FRONTAL_RETRACTORES',
  // STLs
  arcada_superior: 'ARCADA_SUPERIOR',
  arcada_inferior: 'ARCADA_INFERIOR',
  oclusion_izquierda: 'OCLUSION_IZQUIERDA',
  oclusion_derecha: 'OCLUSION_DERECHA',
  // Radiografías
  lateral: 'LATERAL',
  panoramica: 'PANORAMICA',
  trazado_cefalometrico: 'TRAZADO_CEFALOMETRICO',
  tomografia: 'TOMOGRAFIA',
  /** Corrections */
  oclusal_superior: 'OCLUSAL_SUPERIOR',
  oclusal_inferior: 'OCLUSAL_INFERIOR',
  lat_izq_oclusion: 'LAT_IZQ_OCLUSION',
  lat_der_oclusion: 'LAT_DER_OCLUSION',
  frontal_oclusion: 'FRONTAL_OCLUSION',
  lateral_izq_serio: 'LATERAL_IZQ_SERIO',
  lateral_der_serio: 'LATERAL_DER_SERIO',
  frontal_retractores: 'FRONTAL_RETRACTORES',
  modelo: 'MODELO',
};

const names = {
  // Intraorales
  superior: 'Oclusal superior',
  inferior: 'Oclusal inferior',
  lateral_izquierda: 'Lateral izquierda en oclusión',
  lateral_derecha: 'Lateral derecha en oclusión',
  frontal: 'Frontal en oclusión',
  // Extraorales
  frontal_serio: 'Frontal serio',
  frontal_sonriendo: 'Frontal sonriendo',
  ext_lateral_izquierda: 'Lateral izquierda serio',
  ext_lateral_derecha: 'Lateral derecha serio',
  frontal_retractore: 'Frontal con retractores',
  // STLs
  arcada_superior: 'Arcada superior',
  arcada_inferior: 'Arcada inferior',
  oclusion_izquierda: 'Oclusión izquierda',
  oclusion_derecha: 'Oclusión derecha',
  // Radiografías
  lateral: 'Lateral',
  panoramica: 'Panorámica',
  trazado_cefalometrico: 'Trazado cefalométrico',
  tomografia: 'Tomografía',
  /** Corrections */
  oclusal_superior: 'oclusal superior',
  oclusal_inferior: 'oclusal inferior',
  lat_izq_oclusion: 'lateral izquierda en oclusión',
  lat_der_oclusion: 'lateral derecha en oclusión',
  frontal_oclusion: 'frontal en oclusión',
  lateral_izq_serio: 'Lateral izquierda serio',
  lateral_der_serio: 'Lateral derecha serio',
  frontal_retractores: 'Frontal con retractores',
  modelo: 'Modelo',
};

const files = {
  // Intraorales
  superior: require('@images/intraorales/superior.jpg'),
  inferior: require('@images/intraorales/inferior.jpg'),
  lateral_izquierda: require('@images/intraorales/lateral-izquierda.jpg'),
  lateral_derecha: require('@images/intraorales/lateral-derecha.jpg'),
  frontal: require('@images/intraorales/frontal.jpg'),
  // Extraorales
  frontal_serio: require('@images/extraorales/frontal_serio.jpg'),
  frontal_sonriendo: require('@images/extraorales/frontal_sonriendo.jpg'),
  ext_lateral_izquierda: require('@images/extraorales/lateral_izq_serio.jpg'),
  ext_lateral_derecha: require('@images/extraorales/lateral_der_serio.jpg'),
  frontal_retractore: require('@images/extraorales/frontal_retractores.jpg'),
  // STLs
  arcada_superior: require('@images/stl/arcada-superior.jpg'),
  arcada_inferior: require('@images/stl/arcada-inferior.jpg'),
  oclusion_izquierda: require('@images/stl/oclusion-izquierda.jpg'),
  oclusion_derecha: require('@images/stl/oclusion-derecha.jpg'),
  modelo: require('@images/files/modelo-placeholder.png'),
  // Radiografías
  lateral: require('@images/radiografia/lateral.jpg'),
  panoramica: require('@images/radiografia/panoramica.jpg'),
  trazado_cefalometrico: require('@images/radiografia/trazado_cefalometrico.jpg'),
  tomografia: require('@images/radiografia/tomografia.jpg'),
};

const UploadFile = ({
  userType = 'doctor',
  name,
  patientId,
  section,
  filesTreatment,
  setFilesTreatment,
  parentLoading,
  setParentLoading,
  corrections = false,
  click = 0,
  errorForm = false,
  typeSTL = 'file',
  statusTreatment = {},
  setSelectTreatmentFile = () => {},
}) => {
  const [buttonToShow, setButtonToShow] = useState(null);
  const [disableStlButton, setDisableStlButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [dropIn, setDropIn] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);

  const inputRef = useRef(null);

  const onDragOver = () => setDragOver(true);
  const onDragLeave = () => setDragOver(false);

  const onChangeFileTreatment = (e) => {
    if (parentLoading) return;
    e.preventDefault();
    setDisableStlButton(true);
    const auxName = (
      dragOver ? e.target.parentElement.getAttribute('name') : e.target.name
    ).replace('ext_', '');
    setLoading(true);
    setParentLoading(true);
    setError(false);
    setErrorMessage(null);
    const file = dragOver ? e.dataTransfer.files[0] : e.target.files[0];
    setDragOver(false);
    setDropIn(false);
    if (file) {
      uploadFileAxios(file, type[name], section, auxName);
    }
    e.target.value = '';
  };

  const uploadFileAxios = async (
    fileObject,
    fileType,
    fileSection,
    auxName
  ) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      // timeout: 60000
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `mutation addPatientTreatmentPhoto($patient_id: ID!, $file: Upload!, $fileType: FileType!, $fileSection: FileSection!){
              addPatientTreatmentPhoto(input: { patient_id: $patient_id, file: $file, fileType: $fileType, fileSection: $fileSection }){
                  status
                  id
                  name
                  url
              }}`,
        variables: {
          file: fileObject,
          patient_id: patientId,
          fileType: fileType,
          fileSection: fileSection,
        },
      })
    );
    formData.append('0', fileObject);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));

    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          console.log('Progress event:', progressEvent);
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data?.errors) {
          setErrorMessage(response.data?.errors[0]?.message);
          setLoading(false);
          setParentLoading(false);
          setDisableStlButton(false);
          setError(true);
          if (corrections) {
            const index = filesTreatment.findIndex((ft) => ft.review === name);
            if (index >= 0) {
              const auxFiles = filesTreatment;
              auxFiles[index] = {
                ...auxFiles[index],
                error: true,
              };
              setFilesTreatment(auxFiles);
            }
          } else {
            setFilesTreatment({
              ...filesTreatment,
              [auxName]: {
                file,
                preview: files[name],
                error: true,
              },
            });
          }
        } else if (response.data.data.addPatientTreatmentPhoto) {
          setProgressUpload(0);
          if (corrections) {
            const index = filesTreatment.findIndex((ft) => ft.review === name);
            if (index >= 0) {
              const auxFiles = filesTreatment;
              auxFiles[index] = {
                ...auxFiles[index],
                id:
                  userType === 'doctor'
                    ? auxFiles[index].id
                    : response.data?.data?.addPatientTreatmentPhoto?.id,
                name: response.data?.data?.addPatientTreatmentPhoto?.name,
                url: response.data?.data?.addPatientTreatmentPhoto?.url,
                status:
                  userType === 'doctor'
                    ? response.data?.data?.addPatientTreatmentPhoto?.status
                    : 'Pendiente',
                statusId: '4',
                preview:
                  section === 'STL' && typeSTL !== 'shipping'
                    ? require('@images/files/stl.jpg')
                    : section === 'RADIO' && auxName === 'tomografia'
                    ? require('@images/files/zip.jpg')
                    : URL.createObjectURL(fileObject),
                error: false,
              };
              setFilesTreatment(auxFiles);
            }
            setFile({
              ...file,
              id:
                userType === 'doctor'
                  ? file?.id
                  : response.data?.data?.addPatientTreatmentPhoto?.id,
              name: response.data?.data?.addPatientTreatmentPhoto?.name,
              url: response.data?.data?.addPatientTreatmentPhoto?.url,
              status:
                userType === 'doctor'
                  ? response.data?.data?.addPatientTreatmentPhoto?.status
                  : 'Pendiente',
              statusId: '4',
              preview:
                section === 'STL' && typeSTL !== 'shipping'
                  ? require('@images/files/stl.jpg')
                  : section === 'RADIO' && auxName === 'tomografia'
                  ? require('@images/files/zip.jpg')
                  : URL.createObjectURL(fileObject),
              error: false,
            });
            setSelectTreatmentFile({
              ...file,
              id:
                userType === 'doctor'
                  ? file?.id
                  : response.data?.data?.addPatientTreatmentPhoto?.id,
              name: response.data?.data?.addPatientTreatmentPhoto?.name,
              url: response.data?.data?.addPatientTreatmentPhoto?.url,
              status:
                userType === 'doctor'
                  ? response.data?.data?.addPatientTreatmentPhoto?.status
                  : 'Pendiente',
              statusId: '4',
              preview:
                section === 'STL' && typeSTL !== 'shipping'
                  ? require('@images/files/stl.jpg')
                  : section === 'RADIO' && auxName === 'tomografia'
                  ? require('@images/files/zip.jpg')
                  : URL.createObjectURL(fileObject),
              error: false,
            });
          } else {
            setFilesTreatment({
              ...filesTreatment,
              [auxName]: {
                fileObject,
                name: response.data?.data?.addPatientTreatmentPhoto?.name,
                url: response.data?.data?.addPatientTreatmentPhoto?.url,
                preview:
                  section === 'STL' && typeSTL !== 'shipping'
                    ? require('@images/files/stl.jpg')
                    : section === 'RADIO' && auxName === 'tomografia'
                    ? require('@images/files/zip.jpg')
                    : URL.createObjectURL(fileObject),
                error: false,
              },
            });
            setFile({
              ...file,
              status: response.data.data.addPatientTreatmentPhoto.status,
              name: response.data?.data?.addPatientTreatmentPhoto?.name,
              url: response.data?.data?.addPatientTreatmentPhoto?.url,
              preview:
                section === 'STL' && typeSTL !== 'shipping'
                  ? require('@images/files/stl.jpg')
                  : section === 'RADIO' && auxName === 'tomografia'
                  ? require('@images/files/zip.jpg')
                  : URL.createObjectURL(fileObject),
              error: false,
            });
          }

          setDisableStlButton(false);
          setLoading(false);
          setParentLoading(false);
        }
      })
      .catch((e) => {
        console.log('Error:', e);
        setLoading(false);
        setParentLoading(false);
        setDisableStlButton(false);
        setError(true);
        if (corrections) {
          const index = filesTreatment.findIndex((ft) => ft.review === name);
          if (index >= 0) {
            const auxFiles = filesTreatment;
            auxFiles[index] = {
              ...auxFiles[index],
              error: true,
            };
            setFilesTreatment(auxFiles);
          }
        } else {
          setFilesTreatment({
            ...filesTreatment,
            [auxName]: {
              fileObject,
              preview: files[name],
              error: true,
            },
          });
        }
      });
  };

  const downloadImage = async (fileName, url) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'image/jpeg',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    const path = url
      .split('?')[0]
      .replace('https://portal-b360.s3.amazonaws.com', '');

    try {
      const response = await instance.get(
        `/api/files/download?filePath=${path}&mimeType=image/jpeg`
      );
      const href = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'image/jpeg',
        })
      );

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (click > 0) {
      inputRef.current?.click();
    }
  }, [click]);

  useEffect(() => {
    if (parentLoading) {
      setDisableStlButton(true);
    } else {
      setDisableStlButton(false);
    }
  }, [parentLoading]);

  useEffect(() => {
    if (!file) setButtonToShow('add');
    else if (file && file?.preview && userType === 'doctor') {
      if (file.review === 'modelo') setButtonToShow(null);
      else setButtonToShow('edit');
    } else if (file && userType === 'admin') {
      if (file?.status === 'upload') {
        setButtonToShow('add');
      } else if (file?.review === 'modelo') {
        if (file?.statusId === '4') setButtonToShow('edit');
        else setButtonToShow('add');
      } else if (file?.type !== 'pdf') {
        setButtonToShow('download');
      }
    }
  }, [file, userType, typeSTL, statusTreatment]);

  useEffect(() => {
    const auxName = name.replace('ext_', '');
    if (corrections) {
      if (filesTreatment && auxName) {
        const auxFile = filesTreatment.find((ft) => ft.review === auxName);
        setFile(auxFile);
        setError(auxFile?.error ?? false);
      }
    } else {
      if (filesTreatment && auxName && filesTreatment[auxName]) {
        setFile(filesTreatment[auxName]);
        setError(filesTreatment[auxName]?.error ?? false);
      }
    }
  }, [filesTreatment, name]);

  return (
    <div
      style={{
        marginRight: corrections ? 0 : 20,
        marginBottom: corrections ? 0 : 20,
        width: corrections ? '100%' : 'auto',
      }}
    >
      <div
        style={{
          position: 'relative',
          backgroundColor:
            corrections && !loading && !error ? '#252A2D' : 'transparent',
          borderRadius: 7,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!error &&
          file &&
          file?.status === 'Rechazado' &&
          ((!loading &&
            !errorForm &&
            file?.statusId !== '4' &&
            file?.type !== 'stl') ||
            (section === 'STL' && !loading && !errorForm)) && (
            <StateContainer background="#C60E02">
              <LabelNormal fontSize="30" color="#F4F4F4" style={{ margin: 0 }}>
                Requiere atención
              </LabelNormal>
            </StateContainer>
          )}
        {!error &&
          file &&
          (file?.status === 'Aprobado' ||
            file?.status === 'Correción Aprobada') &&
          ((!loading &&
            !errorForm &&
            file?.statusId !== '4' &&
            file?.type !== 'stl') ||
            ((section === 'STL' || section === 'stl') &&
              !loading &&
              !errorForm)) && (
            <StateContainer background="#1DBB24">
              <LabelNormal fontSize="30" color="#F4F4F4" style={{ margin: 0 }}>
                Aceptado
              </LabelNormal>
            </StateContainer>
          )}
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          >
            <FileUploader
              loader={progressUpload}
              top={'0'}
              left={'0'}
              fileSection={true}
              size={corrections ? 'md' : null}
            />
          </div>
        )}
        <label
          htmlFor={name}
          onDragOver={(e) => {
            e.preventDefault();
            setDropIn(true);
            onDragOver();
          }}
          onDragLeave={() => {
            setDropIn(false);
            onDragLeave();
          }}
          name={name}
          onDrop={onChangeFileTreatment}
          style={{ height: corrections ? '26vw' : 'auto' }}
        >
          {error && (
            <DownloadError
              fontSize="14"
              size={'sm'}
              top="-25"
              message={errorMessage}
            />
          )}
          {dropIn ? (
            <img
              src={DragBack}
              style={{
                height: !corrections
                  ? section === 'EXTRAORALES'
                    ? 258
                    : 167
                  : '100%',
                width: !corrections ? 258 : 'auto',
                backgroundPosition: 'cover',
              }}
              alt={name}
            />
          ) : (
            <img
              src={file && file?.preview ? file?.preview : files[name]}
              style={{
                height: !corrections
                  ? section === 'EXTRAORALES'
                    ? 258
                    : 167
                  : '100%',
                width: !corrections ? 258 : 'auto',
                backgroundPosition: 'cover',
                opacity: loading || error ? '0.4' : '1',
              }}
              alt={name}
            />
          )}
          {!loading && (
            <label
              htmlFor={name}
              style={
                file && file?.preview
                  ? {
                      position: 'absolute',
                      bottom: corrections ? 24 : 10,
                      right: corrections ? 24 : 19,
                      cursor: 'pointer',
                    }
                  : {
                      position: 'absolute',
                      bottom: 15,
                      right: 12,
                      cursor: 'pointer',
                    }
              }
            >
              {buttonToShow === 'add' && (
                <ButtonFile
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  icon={'plus'}
                  size={25}
                  disabled={disableStlButton}
                />
              )}
              {buttonToShow === 'edit' && (
                <ButtonFile
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  icon={'edit'}
                  size={25}
                  disabled={disableStlButton}
                />
              )}
              {buttonToShow === 'download' && (
                <DownloadButton
                  onClick={() => downloadImage(file?.name, file?.url)}
                  style={{ display: 'flex' }}
                >
                  <FeatherIcon icon="download" color="#FFF" size="30" />
                </DownloadButton>
              )}
              <input
                type="file"
                ref={inputRef}
                disabled={
                  parentLoading ||
                  (userType === 'doctor' && file?.review === 'modelo')
                }
                accept={
                  section === 'STL' && typeSTL !== 'shipping'
                    ? '.stl'
                    : section === 'RADIO' && name === 'tomografia'
                    ? '.zip'
                    : '.png, .jpeg, .jpg'
                }
                onChange={onChangeFileTreatment}
                name={name}
                id={name}
                style={{ display: 'none' }}
              />
            </label>
          )}
        </label>
      </div>
      {file && (
        <LabelNormal
          style={{
            width: 258,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          color="#828282"
          fontSize="17"
        >
          {file?.file?.name}
        </LabelNormal>
      )}
      <LabelNormal color="#3C4549" fontSize={corrections ? '28' : '17'}>
        {corrections && file
          ? file?.type === 'image' ||
            ((section === 'STL' || section === 'stl') && typeSTL === 'shipping')
            ? `Fotografía `
            : section === 'STL' || section === 'stl'
            ? `STL `
            : file?.type === 'pdf'
            ? `Formulario `
            : ''
          : ''}{' '}
        {name !== 'tomografia' && corrections
          ? names[name]?.toLowerCase() ?? ''
          : names[name] ?? ''}
      </LabelNormal>
      {corrections && file && file?.name && (
        <LabelNormal
          style={{
            width: 300,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          color="#828282"
          fontSize="17"
        >
          {file?.name}
        </LabelNormal>
      )}
      {!corrections &&
        section === 'RADIO' &&
        (name === 'trazado_cefalometrico' || name === 'tomografia') && (
          <LabelNormal color="#757575" fontSize="14">
            *Opcional
          </LabelNormal>
        )}
    </div>
  );
};

export default UploadFile;
