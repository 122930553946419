import { useState } from 'react';
import axios from 'axios';
import Avatar from '@components/shared/Avatar';

const AvatarUploader = ({ patientId, setFilePhoto, uploadError }) => {
  const [photo, setPhoto] = useState('');
  const [loader, setLoader] = useState(false);
  const [photoUploadError, setPhotoUploadError] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);

  const uploadPhotoAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `mutation addPatientProfilePhoto($patient_id: Int!, $file: Upload!) {
                  addPatientProfilePhoto(input: { patient_id: $patient_id, file: $file }) {
                    id
                  }
              }`,
        variables: {
          file: 'file',
          patient_id: patientId,
        },
      }),
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total,
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addPatientProfilePhoto) {
          setProgressUpload(0);
          setLoader(false);
          setPhoto(URL.createObjectURL(file));
        }
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
        setPhotoUploadError(true);
      });
  };

  const onUploadPhoto = async (e) => {
    await uploadPhotoAxios(e);
  };

  const chargePercent = (prefile) => {
    let count = 0;
    const timer = setInterval(() => {
      if (count === 100) {
        setPhoto(URL.createObjectURL(prefile));
        setTimeout(() => {
          clearInterval(timer);
          setProgressUpload(0);
          setLoader(false);
        }, 1000);
      } else {
        count += 1;
        setProgressUpload(count);
      }
    }, 15);
  };

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    if (prefile) {
      setLoader(true);
      setFilePhoto(prefile);
      setPhotoUploadError(false);
      if (!patientId) {
        chargePercent(prefile);
      } else {
        onUploadPhoto(prefile);
      }
    }
  };

  return (
    <div>
      <Avatar
        src={photo ? photo : require('@images/people/usuario_masculino.jpg')}
        onChange={onChangeFile}
        floatButton
        height="161"
        width="161"
        borderRadius="30"
        loader={progressUpload}
        error={photoUploadError || uploadError}
        state={loader ? true : false}
        fadeOut={progressUpload === 100 ? true : false}
      />
    </div>
  );
};

export default AvatarUploader;
