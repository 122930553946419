import FeatherIcon from 'feather-icons-react';
import { LabelNormal, DownloadButton } from '../shared/Styled';
import { downloadImage } from '../../utils';

const ExtraoralCard = ({ image, alt, name }) => (
  <div style={{ width: '130px' }}>
    <div>
      <div style={{ position: 'relative' }}>
        <img
          src={image && image.url
              ? image.url
              : require('@images/patient/empty-patient.png')
          }
          style={{
            height: 130,
            width: 130,
            borderRadius: 4,
          }}
          alt={alt}
        />
        <DownloadButton
            width="32px"
            height="32px"
            onClick={() =>
                downloadImage(
                    image.name,
                    image.url
                )
            }
            style={{
                display: image.type === 'pdf' ? 'none' : 'flex',
                right: '8px'
            }}
        >
            <FeatherIcon
                icon="download"
                color="#FFF"
                size="30"
            />
        </DownloadButton>
      </div>
      { image && image.name && (
          <LabelNormal
            style={{
              width: 105,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            color="#828282"
            fontSize="14"
          >
            { image.name }
          </LabelNormal>
        )}
      <LabelNormal color="#3C4549" fontSize="14">
        { name }
      </LabelNormal>
    </div>
  </div>
);

export default ExtraoralCard;
