/** All related logic and error validations to Partial Aligners.
 *
 * @param {{
 * bottomAligners: number,
 * errorMessages: Object<number, string>,
 * partialities: Array<{ upper: number, bottom: number }>,
 * setErrorMessages: React.Dispatch<Object<number, string>>,
 * upperAligners: number,
 * }} props
 * @typedef {{
 * canAddPartiality: (index: number) => boolean,
 * canRemovePartiality: (index: number) => boolean,
 * isAddPartialityEnabled: (index: any) => boolean,
 * validateErrors: (list: Array<{ upper: number, bottom: number }>, index: number, value: string) => boolean
 * }} UsePartialAlignerResult
 * @returns {UsePartialAlignerResult} custom hook result.
 */
export const usePartialAligner = ({
  errorMessages,
  bottomAligners,
  partialities,
  setErrorMessages,
  upperAligners,
}) => {
  const PARTIALITIES_LIMIT = 4;
  const MANDATORY_LIMIT = 2;

  /** Determines if the length of partialities has reached the limit.
   *
   * @returns true or false.
   */
  const isPartialityLimitReached = (index) => {
    return index === PARTIALITIES_LIMIT - 1;
  };

  /** Determines if the current partiality can be added. The first mandatory
   * partiality lacks this behaviour.
   *
   * @param {number} index Index value of partiality render.
   * @returns true or false.
   */
  const isAddable = (index) => {
    return index >= MANDATORY_LIMIT - 1;
  };

  /** Determines if the current partiality was added and isn't the first two
   * mandatory partialities instances.
   *
   * @param {number} index Index value of partiality render.
   * @returns true or false.
   */
  const isMandatory = (index) => {
    return index <= MANDATORY_LIMIT - 1;
  };

  /** Determines if the current partiality is the last partiality added..
   *
   * @param {number} index Index value of partiality render.
   * @returns true or false.
   */
  const isLastPartiality = (index) => {
    return index === partialities.length - 1;
  };

  const isAddPartialityEnabled = (index) => {
    return isLastPartiality(index);
  };

  /** Only the second mandatory and any index that isn't the limit can have
   * the add button.
   *
   * @param {number} index Index value of partiality render.
   * @returns true or false.
   */
  const canAddPartiality = (index) => {
    return isAddable(index) && isPartialityLimitReached(index) === false;
  };

  /** Only those partialities that are not mandatories and the last one can have
   * the remove button.
   *
   * @param {number} index Index value of partiality render.
   * @returns true or false.
   */
  const canRemovePartiality = (index) => {
    return isMandatory(index) === false && isLastPartiality(index);
  };

  /** Error validations while onChange is triggered.
   *
   * @param {Array<{ upper: number, bottom: number }>} list
   * @param {number} index
   * @param {string} value
   * @returns {boolean} returns true or false for submit validations. True if was
   * a positive validation with no errors found.
   */
  const validateErrors = (list, index, value) => {
    let message = '';

    message = validateAlignerTotalError(list);
    if (message !== '') {
      setErrorMessages({ ...errorMessages, [index]: message });
      return false;
    }

    message = validateEmptyError(value);
    if (message !== '') {
      setErrorMessages({ ...errorMessages, [index]: message });
      return false;
    }

    message = validateZeroValueError(value);
    if (message !== '') {
      setErrorMessages({ ...errorMessages, [index]: message });
      return false;
    }

    // Empty clean
    setErrorMessages({ ...errorMessages, [index]: '' });

    return true;
  };

  /**
   * @param {Array<{ upper: number, bottom: number }>} list
   */
  const validateAlignerTotalError = (list) => {
    let totalLower = list
      .map((p) => Number(p.bottom))
      .reduce((sum, n) => sum + n, 0);
    let totalUpper = list
      .map((p) => Number(p.upper))
      .reduce((sum, n) => sum + n, 0);

    if (
      totalLower > Number(bottomAligners) ||
      totalUpper > Number(upperAligners)
    ) {
      return '*Introduce una cantidad que coincida con el total de alineadores';
    } else return '';
  };

  const validateEmptyError = (value) => {
    return value === '' ? '*Introduce una cantidad de alineadores' : '';
  };

  const validateZeroValueError = (value) => {
    return value === 0 ? '*Introduce una cantidad mayor a 0' : '';
  };

  return {
    isAddPartialityEnabled,
    canAddPartiality,
    canRemovePartiality,
    validateErrors,
  };
};
