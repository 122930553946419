import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardComponent = styled(Link)`
  text-decoration: none;
  border: none !important;
`;

const CardComponent2 = styled.div`
  text-decoration: none;
  border: none !important;
`;

const Container = styled.div`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '262px')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '128px')};
  max-width: ${({ noMaxWidth }) => (noMaxWidth ? 'none' : '285px')};
  padding: ${({ padding }) => (padding ? padding : '17px')};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '14px'};
  background: #fff;
  box-shadow: 0px 2px 0px rgba(162, 162, 162, 0.1),
    0px 1px 3px rgba(162, 162, 162, 0.1);
  transition: ${({ border }) => (border ? 'none' : 'all .3s ease-out')};
  border: ${({ border }) =>
    border ? border : '2px solid transparent'} !important;
  &:hover {
    border: ${({ border }) =>
      border ? 'none' : '2px solid #D97617'} !important;
  }
  &:active {
    border: ${({ border }) =>
      border ? 'none' : '2px solid #AB5D12'} !important;
  }
`;

const Card = (props) =>
  props.to ? (
    <CardComponent to={props.to}>
      <Container
        style={props.style}
        noMaxWidth={props.noMaxWidth}
        minWidth={props.minWidth}
        padding={props.padding}
        border={props.border}
        borderRadius={props.borderRadius}
        minHeight={props.minHeight}
      >
        {props.children}
      </Container>
    </CardComponent>
  ) : (
    <CardComponent2 {...props}>
      <Container
        style={props.style}
        noMaxWidth={props.noMaxWidth}
        minWidth={props.minWidth}
        padding={props.padding}
        border={props.border}
        borderRadius={props.borderRadius}
        minHeight={props.minHeight}
      >
        {props.children}
      </Container>
    </CardComponent2>
  );

export default Card;
