import React, { useState } from 'react';
import { MainLogin, ImgLogin, SpanLogin, H2Login, InputContainer } from '@components/Login/Login.style';
import ButtonPrime from '@components/shared/ButtonPrime';
import InputSet from '@components/shared/InputSet';
import LoginImg from '@images/Union.svg';
import LabelUnderline from '@components/shared/LabelUnderline';
import { useMutation } from '@apollo/client';
import { MUTATION_FORGOT_PASSWORD } from '@graphql/mutation';
import { useHistory } from 'react-router-dom';

const ForgotPassword = (props) => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [error, setError] = useState('')

    const [setForgotPass] = useMutation(MUTATION_FORGOT_PASSWORD, {
        onCompleted: data => {
            if(data.forgotPassword){
                setStatus(true);
            }
            console.log(data);
        },
        onError: err => {
            console.log('Error:', err.graphQLErrors)
            if(err.graphQLErrors.length > 0) {
                if(err.graphQLErrors[0].message === 'The email given has no user related.') {
                    setError('Correo no registrado')
                } else {
                    setError('Error interno')
                }
            }
        }
    })

    const validateOnSubbmitt = () => {
        if(email === ''){
            setError('')
            setEmptyEmail(true)
        }else{
            setError('')
            setEmptyEmail(false)
            setForgotPass({ variables: { email } })
        }
    }

     return (
        <MainLogin>
            <ImgLogin src={LoginImg} />
            <H2Login>Bienvenido a B360</H2Login>
            {status ?
                <SpanLogin>Te hemos enviado un correo para <br/> recuperar tu contraseña</SpanLogin>
            :
                <SpanLogin>Ingresa tu correo para recuperar tu contraseña.</SpanLogin>
            }
            {!status &&
            <>
                <InputContainer>
                    <InputSet inputLabel="Email" placeholder="Tu Correo" type="email" name="email" validation={error !== ''} onClick={() => setError('')} onChange={(e) => setEmail(e.target.value)} onBlur={() => setEmptyEmail(false)}/>
                </InputContainer>
                {error !== '' && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 0, marginBottom: 10, fontFamily: 'neue-montreal-regular'}}>{error}</p>}
                {emptyEmail && <p style={{color: '#C60E02', fontSize: 18, marginBottom: 5, fontFamily: 'neue-montreal-regular', textAlign: 'left'}}>*Introduce tu correo</p>}
                <ButtonPrime label="Recuperar contraseña"  onClick={() => validateOnSubbmitt()}/>
            </>
            }
            <LabelUnderline color="#757575" fontSize="17" onClick={() => history.push('/auth/iniciar-sesion')} marginTop={40} label={'Volver'}/>
        </MainLogin>
    );
}

export default ForgotPassword;