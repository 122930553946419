import React from "react"
import { Controller } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import { Container, ErrorMessage, InlineContainer } from "./Styled"

const SelectCustomAddress = ({ control, name, rules, errors, clearErrors, parentProps = {}, ...props }) => {
    const colourStyles = {
        container: styles => ({
            ...styles,
            paddingRight: 12,
            paddingTop: 3,
            paddingBottom: 0
        }),
        control: (styles, state) => {
            return ({
                ...styles,
                borderBottomLeftRadius: state.menuIsOpen ? 0 : 16,
                borderBottomRightRadius: state.menuIsOpen ? 0 : 16,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                backgroundColor: '#FFF',
                border: state.isFocused && !state.menuIsOpen ? '1px solid rgba(189, 189, 189, 0.7)' : '',
                padding: 7,
                boxShadow: 'none',
                borderColor: 'rgba(189, 189, 189, 0.7)',
                "&:hover": {
                    borderColor: 'rgba(189, 189, 189, 0.7)',
                    borderBottomColor: state.menuIsOpen ? '#FFF' : 'rgba(189, 189, 189, 0.7)'
                },
                borderBottomColor: state.menuIsOpen ? '#FFF' : 'rgba(189, 189, 189, 0.7)'
            })
        },
        menu: (provided) => {
            return ({
                ...provided,
                width: '96.3%',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
                padding: 10,
                margin: 0,
                boxShadow: '-1px 1px 0 0 rgba(189, 189, 189, 0.7), 1px 0 0 0 rgba(189, 189, 189, 0.7)',
            })
        },
        indicatorSeparator: () => ({}),
        valueContainer: (styles) => ({...styles, color: '#3C4549', fontSize: 18, fontFamily:"neue-montreal-regular" }),
        option: (_, { isSelected }) => {
            return {
                padding: 5,
                backgroundColor: isSelected ? '#FFF' : '#FFF',
                color: '#3C4549',
                fontSize: 18,
                fontFamily: "neue-montreal-regular"
            };
        },
    }

    const customStyle = {
        container: styles => ({
            ...styles,
            paddingTop: 3,
            paddingBottom: 0,
            marginBottom: 3
        }),
        control: (styles, state) => {
            return ({
                ...styles,
                borderBottomLeftRadius: state.menuIsOpen ? 0 : 16,
                borderBottomRightRadius: state.menuIsOpen ? 0 : 16,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                backgroundColor: '#FFF',
                border: state.isFocused && !state.menuIsOpen ? '1px solid rgba(189, 189, 189, 0.7)' : errors && errors[name] ? '1px solid  rgb(209, 17, 5)' : '',
                padding: 7,
                boxShadow: 'none',
                // borderColor: 'rgba(189, 189, 189, 0.7)',
                "&:hover": {
                    borderColor: errors && errors[name] ? '1px solid  rgb(209, 17, 5)' : 'rgba(189, 189, 189, 0.7)',
                    borderBottomColor: state.menuIsOpen ? '#FFF' : errors && errors[name] ? '1px solid  rgb(209, 17, 5)' : 'rgba(189, 189, 189, 0.7)'
                },
                borderBottomColor: state.menuIsOpen ? '#FFF' : errors && errors[name] ? '1px solid  rgb(209, 17, 5)' : 'rgba(189, 189, 189, 0.7)'
            })
        },
        menu: (provided) => {
            return ({
                ...provided,
                top: '97%',
                left: '1px',
                width: '99.3%',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
                padding: 10,
                margin: 0,
                boxShadow: '-1px 1px 0 0 rgba(189, 189, 189, 0.7), 1px 0 0 0 rgba(189, 189, 189, 0.7)',
            })
        },
        indicatorSeparator: () => ({}),
        valueContainer: (styles) => ({...styles, color: '#3C4549', fontSize: 18, fontFamily:"neue-montreal-regular" }),
        option: (_, { isSelected }) => {
            return {
                padding: 5,
                backgroundColor: isSelected ? '#FFF' : '#FFF',
                color: '#3C4549',
                fontSize: 18,
                fontFamily: "neue-montreal-regular"
            };
        },
    }
    if (control) {
        return (
            <Container {...parentProps}>
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) => (
                        <CreatableSelect
                            {...field}
                            {...props}
                            onFocus={() => clearErrors && clearErrors(name)}
                            styles={customStyle}
                            formatCreateLabel={() => undefined}
                        />
                    )}
                />
                <InlineContainer>
                    <ErrorMessage>{ errors && errors[name] && errors[name]?.message }</ErrorMessage>
                </InlineContainer>
            </Container>
        )
    }
    return (
        <CreatableSelect
            {...props}
            styles={colourStyles}
            formatCreateLabel={() => undefined}
        />
    )
}

export default SelectCustomAddress;