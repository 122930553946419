import { Link } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';

const NewSmartCheckButton = ({ role, patientId, reschedule }) => (
  <Link
    to={{
      pathname: `/${
        role === 'teacher' ? 'profesor' : 'doctor'
      }/caso/agendar-smartcheck/${patientId}`,
      state: { treatmentId: patientId },
    }}
  >
    <ButtonPrime
      style={{ padding: '9px 11px' }}
      fontSize={15}
      label={reschedule ? 'Reagendar Smart Check' : 'Agendar Smart Check'}
    />
  </Link>
);

export default NewSmartCheckButton;
