/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
const AuthContext = createContext();

const AuthProvider = (props) => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		checkLogin();
	}, [props.isLoggedIn]);

	const checkLogin = () => {
		setUser(props.isLoggedIn);
	}

	return (
		<AuthContext.Provider
			value={{
				user,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const mapStateToProps = state => ({
	userData: state.auth.userData,
	isLoggedIn: state.auth.isLoggedIn
});

export { AuthContext };
export default connect(mapStateToProps, null)(AuthProvider);
