import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
  QUERY_GET_PATIENT_FILES,
} from '@graphql/query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Chat from 'borgatta-chat';
import { useLazyQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import ButtonPrime from '@components/shared/ButtonPrime';
import FeatherIcons from 'feather-icons-react';
import { Row, Tag } from '@components/shared/Styled';
import ButtonSecondary from '@components/shared/buttons/secondary';
import Breadcrumb from '@components/Navigation/Breadcrumb/Breadcrumb';
import Error from '@components/Icons/Error';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-medium;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const MessageBorgatta = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [viewModal, setViewModal] = useState(false);
  const [changeStatus] = useState(false);
  // const [channelStatus, setChannelStatus] = useState({});
  const [treatmentName, setTreatmentName] = useState('');
  const [treatment, setTreatment] = useState(null);
  const [treatmentStatus, setTreatmentStatus] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [hasFileError, setHasFileError] = useState(false);
  const [hasChangesSS, setHasChangesSS] = useState(false);
  const [hasCorrection, setHasCorrection] = useState(false);
  const [onlyForm, setOnlyForm] = useState(false);
  const [formApproved, setFormApproved] = useState(false);

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;
        let decode = [];
        patientfiles.forEach((item) => {
          decode.push({
            id: item.id,
            review: item.name,
            type: item.file_type,
            name: item.file_name,
            url: item.fileUrl,
            section: item.section,
            status: item.FileStatus.name,
            statusId: item.FileStatus.id,
          });
        });

        if (decode.filter((v) => v.status === 'Rechazado').length > 0) {
          setHasFileError(true);
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatientRefresh] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;
        setHasChangesSS(patient.treatment_related.has_changes_ss);
        setTreatmentStatus(patient.treatment_related.treatmentStatus);
        setTreatmentName(patient.treatment);
        if (hasCorrection && !onlyForm && !formApproved) {
          getCommentsCorrection({
            variables: {
              onlyErrors: true,
              treatment_id: patient.treatment_related.id,
            },
          });
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setTreatmentName(patient.treatment);

        setTreatment(patient.treatment_related.id);
        setTreatmentStatus(patient.treatment_related.treatmentStatus);

        getCommentsCorrection({
          variables: {
            onlyErrors: true,
            treatment_id: patient.treatment_related.id,
          },
        });
      }
    },
    onError: (err) => console.log(err),
  });

  const [getCommentsCorrection] = useLazyQuery(
    QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getCorrectionCommentsByTreatment.correctionsComments) {
          const corrections =
            data.getCorrectionCommentsByTreatment.correctionsComments;
          const historyCorrections =
            data.getCorrectionCommentsByTreatment.history ?? [];
          if (corrections.length > 0) {
            setHasCorrection(true);
          }
          const formCorrection = corrections.filter(
            (correction) => correction.file.name === 'patient_form'
          );
          const filesCorrection = corrections.filter(
            (correction) => correction.file.name !== 'patient_form'
          );
          if (filesCorrection.length > 0) {
            setOnlyForm(false);
            setFormApproved(
              historyCorrections?.filter(
                (item) => item.file?.name === 'patient_form'
              ).length > 0
            );
          } else if (formCorrection.length > 0) {
            setOnlyForm(true);
          }
        }
      },
    }
  );

  useEffect(() => {
    getPatientFiles({ variables: { onlyErrors: true, patient_id: id } });
    getPatient({ variables: { id } });
    const intervalID = setInterval(() => {
      getPatientRefresh({ variables: { id } });
    }, 10000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Mensajes de Borgatta</Title>
                <div style={{ marginTop: 10, marginBottom: 46 }}>
                  <LabelNormal fontSize="17" color="#757575">
                    Escribe y responde a nuestro equipo para resolver las dudas
                    y que tu caso avance.
                  </LabelNormal>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.replace(`/doctor/caso/${id}`)}
                    mainLabel={'Caso'}
                    onSecondClick={() => history.replace(`/doctor/caso/${id}`)}
                    midLabel={`${firstName} ${lastName}`}
                    endLabel={'Mensajes a Borgatta'}
                  />
                </div>
              </div>
              <Row
                className="w-100"
                gap="25px"
                paddingLeft="15px"
                paddingRight="15px"
              >
                <div
                  className=""
                  style={{ maxWidth: 680, minWidth: 680, height: 584 }}
                >
                  {treatment && (
                    <Chat
                      token={localStorage.getItem('token-b360')}
                      treatment_id={parseInt(treatment)}
                      user_id={parseInt(localStorage.getItem('b360-id'))}
                      api_uri={process.env.REACT_APP_CHAT_API_URL}
                      environment="development"
                    />
                  )}
                </div>
                <div className="flex-grow-1" style={{ height: 584 }}>
                  {treatmentName === 'smile_system' &&
                    treatmentStatus.type === 'new_case' &&
                    treatmentStatus.index === 7 &&
                    hasChangesSS && (
                      <>
                        <LabelNormal color="#757575" fontSize="22">
                          Aprobación de caso
                        </LabelNormal>
                        <InlineContainer style={{ alignItems: 'center' }}>
                          <FeatherIcons icon="clock" color="#EB9542" />
                          <LabelNormal fontSize="17" color="#EB9542">
                            Esperando aprobación
                          </LabelNormal>
                        </InlineContainer>
                        <ButtonPrime
                          label="Volver y aprobar caso"
                          icon="arrow-left"
                          onClick={() => history.goBack()}
                        />
                      </>
                    )}
                  {hasCorrection && !changeStatus && !onlyForm ? (
                    <div className="flex flex-col gap-4">
                      {formApproved ? (
                        <>
                          <ButtonPrime
                            label="Volver a errores por corregir"
                            icon="arrow-left"
                            width="fit-content"
                            onClick={() =>
                              history.push({
                                pathname: `/${
                                  location.state && location.state.student
                                    ? 'estudiante'
                                    : 'doctor'
                                }/caso/correccion/${id}`,
                                state: {
                                  treatmentId: treatment,
                                  firstName,
                                  lastName,
                                },
                              })
                            }
                          />
                          <Tag
                            type="danger"
                            fontSize="16px"
                            fontWeight="600"
                            padding="9px 16px"
                            borderRadius="8px"
                          >
                            <Error />
                            Corrige el resto de tus archivos
                          </Tag>
                        </>
                      ) : (
                        <ButtonSecondary
                          fontSize="19px"
                          icon="arrow-left"
                          width="fit-content"
                          outline
                          onClick={() =>
                            history.push({
                              pathname: `/${
                                location.state && location.state.student
                                  ? 'estudiante'
                                  : 'doctor'
                              }/caso/correccion/${id}`,
                              state: {
                                treatmentId: treatment,
                                firstName,
                                lastName,
                              },
                            })
                          }
                        >
                          <span
                            className="flex gap-2 items-center"
                            style={{ marginRight: '12px' }}
                          >
                            <FeatherIcon icon="arrow-left" />
                            <p
                              style={{
                                fontWeight: 500,
                                fontFamily: 'Neue Montreal',
                              }}
                            >
                              Volver a errores por corregir
                            </p>
                          </span>
                        </ButtonSecondary>
                      )}
                    </div>
                  ) : (
                    <>
                      {treatmentStatus.type === 'new_case' &&
                      treatmentStatus.index === 9 ? (
                        <ButtonSecondary
                          fontSize="19px"
                          icon="arrow-left"
                          onClick={() =>
                            history.replace(`/doctor/caso/planificacion/${id}`)
                          }
                          width="fit-content"
                          outline
                        >
                          <span
                            className="flex gap-2 items-center"
                            style={{ marginRight: '12px' }}
                          >
                            <FeatherIcon icon="arrow-left" />
                            <p
                              style={{
                                fontWeight: 500,
                                fontFamily: 'Neue Montreal',
                              }}
                            >
                              Volver al visor de tratamiento
                            </p>
                          </span>
                        </ButtonSecondary>
                      ) : (
                        <ButtonSecondary
                          fontSize="19px"
                          icon="arrow-left"
                          onClick={() => history.replace(`/doctor/caso/${id}`)}
                          width="fit-content"
                          outline
                        >
                          <span
                            className="flex gap-2 items-center"
                            style={{ marginRight: '12px' }}
                          >
                            <FeatherIcon icon="arrow-left" />
                            <p
                              style={{
                                fontWeight: 500,
                                fontFamily: 'Neue Montreal',
                              }}
                            >
                              Volver al caso
                            </p>
                          </span>
                        </ButtonSecondary>
                      )}
                    </>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default MessageBorgatta;
