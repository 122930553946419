import React, { useEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Input from '@components/shared/Input';
import Checkbox from 'react-custom-checkbox';
import ButtonPrime from '@components/shared/ButtonPrime';
import LabelUnderline from '../../components/shared/LabelUnderline';
import ButtonAction from '@components/shared/ButtonAction';
import ButtonFile from '../../components/shared/ButtonFile';
import Select from '@components/shared/Select';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_REGISTER_PATIENT,
  MUTATION_UPDATE_PATIENT,
  MUTATION_UPLOAD_PATIENT_PHOTO,
  MUTATION_SELECT_TREATMENT_BRACKETS,
  MUTATION_ADD_FORM_PATIENT_FILE,
  MUTATION_ADD_PATIENT_FILE_TREATMENT_PHOTO,
  MUTATION_SET_SHIPPING_TREATMENT,
  MUTATION_SET_URL_TREATMENT,
  MUTATION_CHANGE_DEFAULT_TAX_DATA,
  MUTATION_CHANGE_DEFAULT_ADDRESS,
  MUTATION_CHANGE_DEFAULT_CARD,
  MUTATION_PAY_PLANNING_TREATMENT,
  MUTATION_PAY_MANUFACTURE_TREATMENT,
  MUTATION_ADD_URL_STL,
  MUTATION_CANCEL_INTENT,
  MUTATION_ASSIGN_CASE_TEACHER,
  MUTATION_DELETE_EXTRA_STL,
} from '@graphql/mutation';
import {
  QUERY_GET_PATIENT,
  QUERY_DEFAULT_ADDRESS_DATA,
  QUERY_GET_DETAILS_DOCTOR,
  QUERY_GET_DEFAULT_TAX_DATA,
  QUERY_GET_DEFAULT_PAYMENT,
  QUERY_GET_PRICE_BY_TYPE,
  QUERY_GET_PACK,
  QUERY_GET_PAYMENT_INSTALLMENTS,
  QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
  QUERY_GET_TEACHERS_BY_UNIVERSITIES,
} from '@graphql/query';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import ModalFormTaxData from '@components/Modal/ModalFormTaxData';
import ModalFormAddress from '@components/Modal/ModalFormAddress';
import ModalFormCard from '@components/Modal/ModalFormCard';
import { modal } from '@components/ModalManagement';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import {
  validatePhone,
  validateEmail,
  validateURL,
  loadPhotoProfileGender,
} from '../../utils';
import Loader from '@components/shared/Loader';
import DragBack from '@images/background-drag.svg';
import FormCA from '@components/DoctorFormCA';
import FormSS from '@components/DoctorFormSS';
import Map from '@components/DoctorFormCA/map';
import MapBrackets from '@components/DoctorFormSS/map';

import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';
import { tops, bottoms } from '@components/DoctorFormCA/icons';
import RealoadIcon from '@images/reaload-icon.svg';

const odontograma = [
  { name: 'Diente ausente', icon: DienteAusente, id: 'diente_ausente' },
  { name: 'Diente a extraer', icon: DienteExtraer, id: 'diente_a_extraer' },
  { name: 'Pieza a excluir', icon: PiesaExcluir, id: 'pieza_a_excluir' },
  { name: 'Implante', icon: Implante, id: 'implante' },
  { name: 'Pieza en erupción', icon: PiezaErupcion, id: 'pieza_en_erupcion' },
  { name: 'Inscrustación', icon: Inscrustacion, id: 'inscrustacion' },
  { name: 'Prótesis fija', icon: ProtesisFija, id: 'protesis_fija' },
  {
    name: 'Diente incluido retenido',
    icon: DienteIncluidoRetenido,
    id: 'diente_incluido_retenido',
  },
  {
    name: 'Diente con endodoncia',
    icon: DienteConEndodoncia,
    id: 'diente_con_endodoncia',
  },
  { name: 'Erosión dental', icon: ErosionDental, id: 'erosion_dental' },
  {
    name: 'Fractura en corona',
    icon: FracturaCorona,
    id: 'fractura_en_corona',
  },
  { name: 'Restauración', icon: Restauracion, id: 'restauracion' },
];

const itemSteps = [
  { title: 'Paciente', name: 1 },
  { title: 'Formulario', name: 2 },
  { title: 'Fotografías', name: 3 },
  { title: `STL’s`, name: 4 },
  { title: 'Radiografías', name: 5 },
  { title: 'Resumen', name: 6 },
  { title: 'Pago', name: 7 },
];

const itemStepsSS = [
  { title: 'Paciente', name: 1 },
  { title: 'Formulario', name: 2 },
  { title: 'Fotografías', name: 3 },
  { title: `STL’s`, name: 4 },
  { title: 'Resumen', name: 5 },
  { title: 'Pago', name: 6 },
];

const NewCases = (props) => {
  const history = useHistory();

  const [dragOver, setDragOver] = useState(false);
  const onDragOver = () => setDragOver(true);
  const onDragLeave = () => setDragOver(false);

  const [dropIn, setDropIn] = useState(null);

  const [steps, setSteps] = useState(1);
  const [subSteps, setSubStep] = useState(0);
  const [lastStep, setLastStep] = useState(1);
  const [saved, setSaved] = useState(true);
  const [changePayment, setChangePayment] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [transferSend, setTransferSend] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [transferError, setTransferError] = useState(false);
  const [link_data, setLinkData] = useState(['']);
  const [urlSTL, setUrlSTL] = useState('');
  const [treatmentId, setTreatmentId] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [clabe, setClabe] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('payment_line');

  const [teacherSelect, setTeacherSelect] = useState('');

  const [typePrice, setTypePrice] = useState({});
  const [total, setTotal] = useState(0);
  const [types, setTypes] = useState([]);
  const [packType, setPackType] = useState(null);
  const [paymentInstallments, setPaymentInstallments] = useState({});
  const [doctorData, setDoctorData] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [treatment, setTreatment] = useState('');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState('');
  const [filePhoto, setFilePhoto] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [validName, setvalidName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [crystAligner, setCrystAligner] = useState(false);
  const [smileSystem, setSmileSystem] = useState(false);

  const [more, setMore] = useState(true);
  const [selectItem, setSelectItem] = useState('Formulario');
  const [selectInstallment, setSelectInstallment] = useState({});

  const [patientId, setPatientId] = useState(null);

  const [brackets, setBrackets] = useState(null);

  const [stateFile, setStateFile] = useState(false);
  const [filePatient, setFilePatient] = useState({});

  const inputRef = useRef(null);

  const inputRefOclSup = useRef(null);
  const inputRefOclInf = useRef(null);
  const inputRefLateralIzqOcl = useRef(null);
  const inputRefLateralDerOcl = useRef(null);
  const inputRefFrontalOcl = useRef(null);

  const inputRefFroSer = useRef(null);
  const inputRefFroSon = useRef(null);
  const inputRefFroRet = useRef(null);
  const inputRefLateraIzqSer = useRef(null);
  const inputRefLateralDerSer = useRef(null);

  const inputRefArcSup = useRef(null);
  const inputRefArcInf = useRef(null);
  const inputRefOclDer = useRef(null);
  const inputRefOclIzq = useRef(null);

  const inputRefLatRad = useRef(null);
  const inputRefPanRad = useRef(null);
  const inputRefTrazado = useRef(null);
  const inputRefTomograf = useRef(null);

  const [filesTreatment, setFilesTreatment] = useState({});
  const [filesTreatmentExtraorales, setFilesTreatmentExtraorales] = useState(
    {}
  );
  const [filesTreatmentStl, setFilesTreatmentSlt] = useState({});
  const [filesTreatmentRadiografia, setFilesTreatmentRadiografia] = useState(
    {}
  );

  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const [sendModelStl, setSendModelStl] = useState(false);
  const [sendLinkStl, setSendLinkStl] = useState(false);

  const [radioStl, setRadioStl] = useState('');
  const [oclusionStl, setOclusionStl] = useState('');

  const [defaultAddress, setDefaultAddress] = useState({});
  const [defaultAddressDoctor, setDefaultAddressDoctor] = useState({});
  const [defaultTaxData, setDefaultTaxData] = useState({});
  const [defaultPayment, setDefaultPayment] = useState({});

  const [loading, setLoading] = useState(false);
  const [compLoading, setCompLoading] = useState('');

  const [formCAData, setFormCAData] = useState({});
  const [formSSData, setFormSSData] = useState({});
  const [selectTab, setSelectTab] = useState(1);

  const [validateOclSup, setValidateOclSup] = useState(true);
  const [validateOclInf, setValidateOclInf] = useState(true);
  const [validateLateralIzqOcl, setValidateLateralIzqOcl] = useState(true);
  const [validateLateralDerOcl, setValidateLateralDerOcl] = useState(true);
  const [validateFrontalOcl, setValidateFrontalOcl] = useState(true);
  const [validateOnIntraoral, setValidateOnIntraoral] = useState(true);

  const [validateFroSer, setValidateFroSer] = useState(true);
  const [validateFroSon, setValidateFroSon] = useState(true);
  const [validateFroRet, setValidateFroRet] = useState(true);
  const [validateLateraIzqSer, setValidateLateraIzqSer] = useState(true);
  const [validateLateralDerSer, setValidateLateralDerSer] = useState(true);
  const [validateOnExtraoral, setValidateOnExtraoral] = useState(true);

  // #region Queries

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setGender(patient.gender);
        setTreatment(patient.treatment);
        setRadioStl(
          patient.treatment_related.stl_type === 'file'
            ? 'archivos'
            : patient.treatment_related.stl_type === 'url'
            ? 'url'
            : patient.treatment_related.stl_type === 'shipping'
            ? 'model'
            : ''
        );
        setEmail(patient.email !== null ? patient.email : '');
        setPhone(patient.phone);
        let date = patient.birthdate.split('-');
        setYear(date[0]);
        setMonth(date[1]);
        setDay(date[2]);
        if (patient.photo_filename !== null) {
          setPhoto(
            `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
          );
        }
        if (patient.treatment_related.bracket_ss) {
          setBrackets(patient.treatment_related.bracket_ss);
        }
        if (patient.treatment_related.stl_type !== null) {
          if (patient.treatment_related.stl_type === 'url') {
            setSendLinkStl(true);
            setLinkData(
              patient.treatment_related.link_data
                ? JSON.parse(patient.treatment_related.link_data)
                : ['']
            );
          } else if (patient.treatment_related.stl_type === 'shipping') {
            setSendModelStl(true);
          }
        }
        if (
          patient.treatment_related &&
          patient.treatment_related.files.length > 0
        ) {
          let formFile = patient.treatment_related.files.find(
            (f) => f.name === 'patient_form'
          );
          if (formFile) setFilePatient({ name: formFile.file_name });
          let oclusalSuperior = patient.treatment_related.files.find(
            (f) => f.name === 'oclusal_superior'
          );
          let object = {};
          if (oclusalSuperior) {
            object = {
              ...object,
              superior: {
                file: { name: oclusalSuperior.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/intraoral_photos/${oclusalSuperior.file_name}`,
                error: false,
              },
            };
          }
          let oclusalInferior = patient.treatment_related.files.find(
            (f) => f.name === 'oclusal_inferior'
          );
          if (oclusalInferior) {
            object = {
              ...object,
              inferior: {
                file: { name: oclusalInferior.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/intraoral_photos/${oclusalInferior.file_name}`,
                error: false,
              },
            };
          }
          let latIzqOclusion = patient.treatment_related.files.find(
            (f) => f.name === 'lat_izq_oclusion'
          );
          if (latIzqOclusion) {
            object = {
              ...object,
              lateral_izquierda: {
                file: { name: latIzqOclusion.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/intraoral_photos/${latIzqOclusion.file_name}`,
                error: false,
              },
            };
          }
          let frontal = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_oclusion'
          );
          if (frontal) {
            object = {
              ...object,
              frontal: {
                file: { name: frontal.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/intraoral_photos/${frontal.file_name}`,
                error: false,
              },
            };
          }
          let latDerOclusion = patient.treatment_related.files.find(
            (f) => f.name === 'lat_der_oclusion'
          );
          if (latDerOclusion) {
            object = {
              ...object,
              lateral_derecha: {
                file: { name: latDerOclusion.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/intraoral_photos/${latDerOclusion.file_name}`,
                error: false,
              },
            };
          }
          setFilesTreatment({
            ...filesTreatment,
            ...object,
          });
          let objectExtraorales = {};
          let serio = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_serio'
          );
          if (serio) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_serio: {
                file: { name: serio.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/extraoral_photos/${serio.file_name}`,
                error: false,
              },
            };
          }
          let sonriendo = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_sonriendo'
          );
          if (sonriendo) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_sonriendo: {
                file: { name: sonriendo.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/extraoral_photos/${sonriendo.file_name}`,
                error: false,
              },
            };
          }
          let frontal_retractore = patient.treatment_related.files.find(
            (f) => f.name === 'frontal_retractores'
          );
          if (frontal_retractore) {
            objectExtraorales = {
              ...objectExtraorales,
              frontal_retractore: {
                file: { name: frontal_retractore.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/extraoral_photos/${frontal_retractore.file_name}`,
                error: false,
              },
            };
          }
          let lateral_izquierdo = patient.treatment_related.files.find(
            (f) => f.name === 'lateral_izq_serio'
          );
          if (lateral_izquierdo) {
            objectExtraorales = {
              ...objectExtraorales,
              lateral_izquierda: {
                file: { name: lateral_izquierdo.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/extraoral_photos/${lateral_izquierdo.file_name}`,
                error: false,
              },
            };
          }
          let lateral_derecho = patient.treatment_related.files.find(
            (f) => f.name === 'lateral_der_serio'
          );
          if (lateral_derecho) {
            objectExtraorales = {
              ...objectExtraorales,
              lateral_derecha: {
                file: { name: lateral_derecho.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/extraoral_photos/${lateral_derecho.file_name}`,
                error: false,
              },
            };
          }
          setFilesTreatmentExtraorales({
            ...filesTreatmentExtraorales,
            ...objectExtraorales,
          });
          let objectStls = {};
          let arcada_superior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_superior'
          );
          if (arcada_superior) {
            objectStls = {
              ...objectStls,
              arcada_superior: {
                file: { name: arcada_superior.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion'
          );
          if (oclusion) {
            objectStls = {
              ...objectStls,
              oclusion: {
                file: { name: oclusion.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          let oclusion_derecha = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_derecha'
          );
          if (oclusion_derecha) {
            objectStls = {
              ...objectStls,
              oclusion_derecha: {
                file: { name: oclusion_derecha.file_name },
                preview: require('@images/files/stl.jpg'),
                error: false,
              },
            };
          }
          let oclusion_izquierda = patient.treatment_related.files.find(
            (f) => f.name === 'oclusion_izquierda'
          );
          if (oclusion_izquierda) {
            objectStls = {
              ...objectStls,
              oclusion_izquierda: {
                file: { name: oclusion_izquierda.file_name },
                preview: require('@images/files/stl.jpg'),
                error: false,
              },
            };
          }
          let arcada_inferior = patient.treatment_related.files.find(
            (f) => f.name === 'arcada_inferior'
          );
          if (arcada_inferior) {
            objectStls = {
              ...objectStls,
              arcada_inferior: {
                file: { name: arcada_inferior.file_name },
                preview: require('@images/patient/stl-upload.png'),
                error: false,
              },
            };
          }
          if (
            oclusion_izquierda &&
            oclusion_derecha &&
            arcada_inferior &&
            arcada_superior
          ) {
            setOclusionStl('si');
          } else {
            setOclusionStl('no');
          }

          setFilesTreatmentSlt({
            ...filesTreatmentStl,
            ...objectStls,
          });
          let radiografiasObject = {};
          let radiografia_lateral = patient.treatment_related.files.find(
            (f) => f.name === 'lateral'
          );
          if (radiografia_lateral) {
            radiografiasObject = {
              ...radiografiasObject,
              lateral: {
                file: { name: radiografia_lateral.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/radio/${radiografia_lateral.file_name}`,
                error: false,
              },
            };
          }
          let radiografia_panoramica = patient.treatment_related.files.find(
            (f) => f.name === 'panoramica'
          );
          if (radiografia_panoramica) {
            radiografiasObject = {
              ...radiografiasObject,
              panoramica: {
                file: { name: radiografia_panoramica.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/radio/${radiografia_panoramica.file_name}`,
                error: false,
              },
            };
          }
          let trazado_cefalometrico = patient.treatment_related.files.find(
            (f) => f.name === 'trazado_cefalometrico'
          );
          if (trazado_cefalometrico) {
            radiografiasObject = {
              ...radiografiasObject,
              trazado_cefalometrico: {
                file: { name: trazado_cefalometrico.file_name },
                preview: `${process.env.REACT_APP_API_URL}/patients/${patient.id}/treatment/radio/${trazado_cefalometrico.file_name}`,
                error: false,
              },
            };
          }
          let tomografia = patient.treatment_related.files.find(
            (f) => f.name === 'tomografia'
          );
          if (tomografia) {
            radiografiasObject = {
              ...radiografiasObject,
              tomografia: {
                file: { name: tomografia.file_name },
                preview: require('@images/patient/zip-upload.png'),
                error: false,
              },
            };
          }
          setFilesTreatmentRadiografia({
            ...filesTreatmentRadiografia,
            ...radiografiasObject,
          });
        }
      }
    },
  });

  const [getPaymentInstallments] = useLazyQuery(
    QUERY_GET_PAYMENT_INSTALLMENTS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data.getPaymentInstallments) {
          setPaymentInstallments(data.getPaymentInstallments);
        }
      },
    }
  );

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        setDoctorData(data.doctor);
        getDefaultAddress({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            patient_id: patientId,
          },
        });
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
        getDefaultAddressDoctor({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
        getDefaultMethodPayment({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
        setCrystAligner(data.doctor.cryst_aligner);
        setSmileSystem(data.doctor.smile_system);
      }
    },
  });

  const [getDefaultAddress] = useLazyQuery(QUERY_DEFAULT_ADDRESS_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getDefaultAddressData) {
        setDefaultAddress(data.getDefaultAddressData.defaultAddress);
      }
    },
    onError: (err) => {
      console.log(err, 'error address');
    },
  });

  const [getDefaultTaxData] = useLazyQuery(QUERY_GET_DEFAULT_TAX_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDefaultTaxData) {
        setDefaultTaxData(data.getDefaultTaxData);
      }
    },
    onError: (err) => console.log(err, 'error taxdata'),
  });

  const [getDefaultAddressDoctor] = useLazyQuery(
    QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getDefaultAddress) {
          setDefaultAddressDoctor(data.getDefaultAddress);
        }
      },
      onError: (err) => console.log(err, 'error default address doctor'),
    }
  );

  const [getDefaultMethodPayment] = useLazyQuery(QUERY_GET_DEFAULT_PAYMENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDefaultPaymentMethod) {
        setDefaultPayment(data.getDefaultPaymentMethod);
        getPaymentInstallments({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            payment_method_ca: defaultPayment.service_id_ca,
            payment_method_ss: defaultPayment.service_id_ss,
            amount: parseFloat(typePrice.amount),
            currency: typePrice.currency,
          },
        });
      }
    },
    onError: (err) => console.log(err, 'error method payment'),
  });

  const [getTypePrice] = useLazyQuery(QUERY_GET_PRICE_BY_TYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTypePrice(data.getPriceByType);
      setTotal(data.getPriceByType.amount);
    },
  });

  const [getPack] = useLazyQuery(QUERY_GET_PACK, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTypes(data.getPacksByType);
    },
  });

  // #endregion

  // #region Mutations

  const [payPlanningTreatment] = useMutation(MUTATION_PAY_PLANNING_TREATMENT, {
    onCompleted: (data) => {
      if (data.payPlanningTreatment) {
        if (paymentMethod == 'payment_line') {
          localStorage.removeItem('Patient');
          localStorage.removeItem('lastStep');
          modal.close();
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.push('/estudiante/tus-casos'))
              }
            >
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Pago realizado y nuevo caso agregado con éxito
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Revisaremos tu caso para asegurarnos de que no existan errores
                en los archivos o dudas en tu planeación. Te notificaremos
                cuando puedas revisarla. Este proceso dura aproximadamente 1 día
                hábil.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          setLoadingTransfer(false);
          setTransferSend(true);
          if (
            data.payPlanningTreatment.bank_code !== '' &&
            data.payPlanningTreatment.bank_name !== '' &&
            data.payPlanningTreatment.clabe !== ''
          ) {
            setBankCode(data.payPlanningTreatment.bank_code);
            setBankName(data.payPlanningTreatment.bank_name);
            setClabe(data.payPlanningTreatment.clabe);
          } else {
            setTransferError(true);
          }
        }
      }
    },
  });

  const [payManufactureTreatment] = useMutation(
    MUTATION_PAY_MANUFACTURE_TREATMENT,
    {
      onCompleted: (data) => {
        if (data.payManufactureTreatment) {
          if (paymentMethod == 'payment_line') {
            localStorage.removeItem('Patient');
            localStorage.removeItem('lastStep');
            modal.close();
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.push('/estudiante/tus-casos'))
                }
              >
                <LabelNormal
                  color="#060809"
                  fontSize="22"
                  style={{ marginBottom: 14 }}
                >
                  Pago realizado y nuevo caso agregado con éxito
                </LabelNormal>
                <LabelNormal
                  color="#4F4F4F"
                  fontSize="17"
                  style={{ marginBottom: 14 }}
                >
                  Revisaremos tu caso para asegurarnos de que no existan errores
                  en los archivos o dudas en tu planeación. Te notificaremos
                  cuando puedas revisarla. Este proceso dura aproximadamente 1
                  día hábil.
                </LabelNormal>
              </ModalSuccess>
            );
          } else {
            setLoadingTransfer(false);
            setTransferSend(true);
            if (
              data.payManufactureTreatment.bank_code !== '' &&
              data.payManufactureTreatment.bank_name !== '' &&
              data.payManufactureTreatment.clabe !== ''
            ) {
              setBankCode(data.payManufactureTreatment.bank_code);
              setBankName(data.payManufactureTreatment.bank_name);
              setClabe(data.payManufactureTreatment.clabe);
            } else {
              setTransferError(true);
            }
          }
        }
      },
    }
  );

  const [addSTLURL] = useMutation(MUTATION_ADD_URL_STL, {
    onCompleted: (data) => console.log(data),
  });

  const [registerPatient] = useMutation(MUTATION_REGISTER_PATIENT, {
    onCompleted: (data) => {
      if (data.registerPatient) {
        setPatientId(+data.registerPatient.patient_id);
        setTreatmentId(data.registerPatient.treatment_id);
        const patient = {
          id: data.registerPatient.patient_id,
          treatment_id: data.registerPatient.treatment_id,
        };
        localStorage.setItem('Patient', JSON.stringify(patient));
        if (filePhoto.name)
          uploadPhotoPatient({
            variables: {
              patient_id: +data.registerPatient.patient_id,
              file: filePhoto,
            },
          });
        else {
          setSaved(true);
          setSteps(2);
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: (data) => {
      if (data.updatePatient) {
        if (filePhoto.name)
          uploadPhotoPatient({
            variables: { patient_id: patientId, file: filePhoto },
          });
        else {
          setSaved(true);
          setSteps(2);
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [uploadPhotoPatient] = useMutation(MUTATION_UPLOAD_PATIENT_PHOTO, {
    onCompleted: (data) => {
      if (data.addPatientProfilePhoto) {
        setSaved(true);
        setSteps(2);
      }
    },
    onError: (err) => console.log(err),
  });

  const [selectBrackets] = useMutation(MUTATION_SELECT_TREATMENT_BRACKETS);

  const [uploadPatientForm] = useMutation(MUTATION_ADD_FORM_PATIENT_FILE, {
    onCompleted: (data) => {
      if (data.addPatientForm) {
        setStateFile(false);
      }
    },
  });

  const [uploadFileTreatment] = useMutation(
    MUTATION_ADD_PATIENT_FILE_TREATMENT_PHOTO
  );

  const [setShipping] = useMutation(MUTATION_SET_SHIPPING_TREATMENT);

  const [setUrl] = useMutation(MUTATION_SET_URL_TREATMENT);

  const [changeDefaultTaxdata] = useMutation(MUTATION_CHANGE_DEFAULT_TAX_DATA, {
    onCompleted: (data) => {
      if (data.updateTaxDataDefault) {
        // getDoctorDetails({ variables: { id: localStorage.getItem('b360-role_id') } })
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
      }
    },
  });

  const [changeDefaultAddressDoctor] = useMutation(
    MUTATION_CHANGE_DEFAULT_ADDRESS,
    {
      onCompleted: (data) => {
        if (data.updateAddressDefault) {
          getDefaultAddressDoctor({
            variables: { user_id: localStorage.getItem('b360-id') },
          });
        }
      },
    }
  );

  const [changeDefaultCard] = useMutation(MUTATION_CHANGE_DEFAULT_CARD, {
    onCompleted: (data) => {
      if (data.updatePaymentDefault) {
        setDefaultPayment(data.updatePaymentDefault);
        let cards = doctorData.cards;
        let cardsAux = [];
        cards.forEach((card) => {
          if (card.service_id === data.updatePaymentDefault.service_id) {
            cardsAux.push({
              ...card,
              default: true,
            });
          } else {
            cardsAux.push({
              ...card,
              default: false,
            });
          }
        });
        setDoctorData({
          ...doctorData,
          cards: cardsAux,
        });
      }
    },
  });

  const [deleteExtraStlOnChange] = useMutation(MUTATION_DELETE_EXTRA_STL, {
    onCompleted: (data) => {
      if (data.deleteExtraStlFile) {
      }
    },
  });

  // #endregion

  // #region Functions

  const submitPayTreatment = () => {
    setTransferError(false);
    setSaved(true);
    assignTeacherCase({
      variables: { treatment_id: treatmentId, teacher_id: teacherSelect },
    });
    if (treatment === 'cryst_aligner') {
      if (
        Object.keys(selectInstallment).length !== 0 &&
        paymentMethod == 'payment_line'
      ) {
        modal.open(
          <ModalWaiting>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Procesando...
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Un momento por favor...
            </LabelNormal>
          </ModalWaiting>
        );
        // if(urlSTL && sendLinkStl){
        //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
        // }
        payPlanningTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatmentId,
            taxdata_id: defaultTaxData.id,
            intent_id: paymentInstallments.intent_id + '',
            count: selectInstallment.count,
            interval: selectInstallment.interval,
            type: selectInstallment.type,
            method: paymentMethod,
          },
        });
      } else {
        if (paymentMethod == 'payment_line') {
          modal.open(
            <ModalWaiting>
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Procesando...
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Un momento por favor...
              </LabelNormal>
            </ModalWaiting>
          );
          // if(urlSTL && sendLinkStl){
          //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
          // }
          payPlanningTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              intent_id: paymentInstallments.intent_id + '',
              method: paymentMethod,
            },
          });
        } else {
          setLoadingTransfer(true);
          payPlanningTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              amount: parseFloat(typePrice.amount),
              currency: typePrice.currency,
              method: paymentMethod,
            },
          });
        }
      }
    } else {
      if (
        Object.keys(selectInstallment).length !== 0 &&
        paymentMethod == 'payment_line'
      ) {
        modal.open(
          <ModalWaiting>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Procesando...
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Un momento por favor...
            </LabelNormal>
          </ModalWaiting>
        );
        // if(urlSTL && sendLinkStl){
        //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
        // }
        payManufactureTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatmentId,
            taxdata_id: defaultTaxData.id,
            address_id: defaultAddressDoctor.id,
            intent_id: paymentInstallments.intent_id + '',
            count: selectInstallment.count,
            interval: selectInstallment.interval,
            type: selectInstallment.type,
            currency_before: paymentInstallments.currency_before,
            amount_before: paymentInstallments.amount_before,
            installment_price: selectInstallment.installment_price,
            method: paymentMethod,
          },
        });
      } else {
        if (paymentMethod == 'payment_line') {
          modal.open(
            <ModalWaiting>
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Procesando...
              </LabelNormal>
              <LabelNormal
                color="#4F4F4F"
                fontSize="17"
                style={{ marginBottom: 14 }}
              >
                Un momento por favor...
              </LabelNormal>
            </ModalWaiting>
          );
          // if(urlSTL && sendLinkStl){
          //     addSTLURL({ variables: { patient_id: patientId, url: urlSTL } });
          // }
          payManufactureTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              address_id: defaultAddressDoctor.id,
              intent_id: paymentInstallments.intent_id + '',
              currency_before: paymentInstallments.currency_before,
              amount_before: paymentInstallments.amount_before,
              method: paymentMethod,
            },
          });
        } else {
          setLoadingTransfer(true);
          payManufactureTreatment({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              invoice,
              treatment_id: treatmentId,
              taxdata_id: defaultTaxData.id,
              address_id: defaultAddressDoctor.id,
              amount: parseFloat(typePrice.amount),
              currency: typePrice.currency,
              method: paymentMethod,
            },
          });
        }
      }
    }
  };

  const [cancelIntentTransfer] = useMutation(MUTATION_CANCEL_INTENT, {
    onCompleted: (data) => {
      if (data.cancelTransferIntent) {
        setTransferSend(false);
        setLoadingTransfer(false);
        getPaymentInstallments({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            payment_method_ca: defaultPayment.service_id_ca,
            payment_method_ss: defaultPayment.service_id_ss,
            amount: parseFloat(typePrice.amount),
            currency: typePrice.currency,
          },
        });
      }
    },
  });

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    setFilePhoto(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const validation = () => {
    let valid = true;
    if (day !== '' && month !== '' && year !== '') {
      let date = moment(`${year}-${month}-${day}`);
      if (!date.isValid()) valid = false;
      setValidDate(date.isValid());
    }
    if (
      steps === 1 &&
      firstName !== '' &&
      lastName !== '' &&
      treatment !== '' &&
      gender !== '' &&
      day !== '' &&
      month !== '' &&
      year !== '' &&
      (phone === '' || (phone !== '' && validatePhone(phone))) &&
      (email === '' || (email !== '' && validateEmail(email))) &&
      valid
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const validateIntraoral = () => {
    let hasMissingFiles = false;
    console.log(filesTreatment);
    if (!(filesTreatment && filesTreatment.superior)) {
      setValidateOclSup(false);
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (!(filesTreatment && filesTreatment.inferior)) {
      setValidateOclInf(false);
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (!(filesTreatment && filesTreatment.lateral_izquierda)) {
      setValidateLateralIzqOcl(false);
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (!(filesTreatment && filesTreatment.frontal)) {
      setValidateFrontalOcl(false);
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }
    if (!(filesTreatment && filesTreatment.lateral_derecha)) {
      setValidateLateralDerOcl(false);
      setValidateOnIntraoral(false);
      hasMissingFiles = true;
    }

    if (!hasMissingFiles) {
      setSubStep(1);
    }
  };

  const validateExtraoral = () => {
    let hasMissingFiles = false;
    if (
      !(filesTreatmentExtraorales && filesTreatmentExtraorales.frontal_serio)
    ) {
      setValidateFroSer(false);
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales && filesTreatmentExtraorales.frontal_sonriendo
      )
    ) {
      setValidateFroSon(false);
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales &&
        filesTreatmentExtraorales.frontal_retractore
      )
    ) {
      setValidateFroRet(false);
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(
        filesTreatmentExtraorales && filesTreatmentExtraorales.lateral_izquierda
      )
    ) {
      setValidateLateraIzqSer(false);
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }
    if (
      !(filesTreatmentExtraorales && filesTreatmentExtraorales.lateral_derecha)
    ) {
      setValidateLateralDerSer(false);
      setValidateOnExtraoral(false);
      hasMissingFiles = true;
    }

    if (!hasMissingFiles) {
      setSubStep(0);
      setSteps(4);
      getDefaultAddress({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          patient_id: patientId,
        },
      });
    }
  };

  const deleteExtraStl = () => {
    if (oclusionStl === 'no') {
      deleteExtraStlOnChange({ variables: { patient_id: patientId } });
    }
  };

  const onChangeFileTreatment = (e) => {
    setValidateOclSup(true);
    setValidateOclInf(true);
    setValidateLateralIzqOcl(true);
    setValidateFrontalOcl(true);
    setValidateLateralDerOcl(true);
    setValidateOnIntraoral(true);
    e.preventDefault();
    const name = dragOver
      ? e.target.parentElement.getAttribute('name')
      : e.target.name;
    setLoading(true);
    setCompLoading(name);
    const section = 'INTRAORALES';
    const type = {
      superior: 'OCLUSAL_SUPERIOR',
      inferior: 'OCLUSAL_INFERIOR',
      lateral_izquierda: 'LAT_IZQ_OCLUSION',
      lateral_derecha: 'LAT_DER_OCLUSION',
      frontal: 'FRONTAL_OCLUSION',
    };
    const file = dragOver ? e.dataTransfer.files[0] : e.target.files[0];
    setDragOver(false);
    setDropIn(null);
    if (file) {
      uploadFileTreatment({
        variables: {
          patient_id: patientId,
          file,
          fileType: type[name],
          fileSection: section,
        },
        onCompleted: (data) => {
          if (data.addPatientTreatmentPhoto) {
            setFilesTreatment({
              ...filesTreatment,
              [name]: {
                file,
                preview: URL.createObjectURL(file),
                error: false,
              },
            });
            setLoading(false);
            setCompLoading('');
          }
        },
        onError: (err) => {
          setLoading(false);
          setCompLoading('');
        },
      });
    }
  };

  const onChangeFileTreatmentExtraorales = (e) => {
    setValidateFroSer(true);
    setValidateFroSon(true);
    setValidateFroRet(true);
    setValidateLateraIzqSer(true);
    setValidateLateralDerSer(true);
    setValidateOnExtraoral(true);
    e.preventDefault();
    const name = dragOver
      ? e.target.parentElement.getAttribute('name')
      : e.target.name;
    setLoading(true);
    setCompLoading(name);
    const section = 'EXTRAORALES';
    const type = {
      frontal_serio: 'FRONTAL_SERIO',
      frontal_sonriendo: 'FRONTAL_SONRIENDO',
      lateral_izquierda: 'LATERAL_IZQ_SERIO',
      lateral_derecha: 'LATERAL_DER_SERIO',
      frontal_retractore: 'FRONTAL_RETRACTORES',
    };
    const file = dragOver ? e.dataTransfer.files[0] : e.target.files[0];
    setDragOver(false);
    setDropIn(null);
    if (file) {
      uploadFileTreatment({
        variables: {
          patient_id: patientId,
          file,
          fileType: type[name],
          fileSection: section,
        },
        onCompleted: (data) => {
          if (data.addPatientTreatmentPhoto) {
            setFilesTreatmentExtraorales({
              ...filesTreatmentExtraorales,
              [name]: {
                file,
                preview: URL.createObjectURL(file),
                error: false,
              },
            });
            setLoading(false);
            setCompLoading('');
          }
        },
        onError: (err) => {
          setLoading(false);
          setCompLoading('');
        },
      });
    }
  };

  const onChangeFileTreatmentSTL = (e) => {
    e.preventDefault();
    const name = dragOver
      ? e.target.parentElement.getAttribute('name')
      : e.target.name;
    setLoading(true);
    setCompLoading(name);
    const section = 'STL';
    const type = {
      arcada_superior: 'ARCADA_SUPERIOR',
      //"oclusion": "OCLUSION",
      arcada_inferior: 'ARCADA_INFERIOR',
      oclusion_izquierda: 'OCLUSION_IZQUIERDA',
      oclusion_derecha: 'OCLUSION_DERECHA',
    };
    const file = dragOver ? e.dataTransfer.files[0] : e.target.files[0];
    setDragOver(false);
    setDropIn(null);
    if (file) {
      uploadFileTreatment({
        variables: {
          patient_id: patientId,
          file,
          fileType: type[name],
          fileSection: section,
        },
        onCompleted: (data) => {
          if (data.addPatientTreatmentPhoto) {
            setFilesTreatmentSlt({
              ...filesTreatmentStl,
              [name]: {
                file,
                preview: require('@images/files/stl.jpg'),
                error: false,
              },
            });
            setLoading(false);
            setCompLoading('');
          }
        },
        onError: (err) => {
          setLoading(false);
          setCompLoading('');
        },
      });
    }
  };

  const onChangeFileTreatmentRadiografia = (e) => {
    e.preventDefault();
    const name = dragOver
      ? e.target.parentElement.getAttribute('name')
      : e.target.name;
    setLoading(true);
    setCompLoading(name);
    const section = 'RADIO';
    const type = {
      lateral: 'LATERAL',
      panoramica: 'PANORAMICA',
      trazado_cefalometrico: 'TRAZADO_CEFALOMETRICO',
      tomografia: 'TOMOGRAFIA',
    };
    const file = dragOver ? e.dataTransfer.files[0] : e.target.files[0];
    setDragOver(false);
    setDropIn(null);
    if (file) {
      uploadFileTreatment({
        variables: {
          patient_id: patientId,
          file,
          fileType: type[name],
          fileSection: section,
        },
        onCompleted: (data) => {
          if (data.addPatientTreatmentPhoto) {
            setFilesTreatmentRadiografia({
              ...filesTreatmentRadiografia,
              [name]: {
                file,
                preview:
                  type[name] !== 'TOMOGRAFIA'
                    ? URL.createObjectURL(file)
                    : require('@images/files/zip.jpg'),
                error: false,
              },
            });
            setLoading(false);
            setCompLoading('');
          }
        },
        onError: (err) => {
          setLoading(false);
          setCompLoading('');
        },
      });
    }
  };

  const checkTransferIntent = () => {
    if (transferSend) {
      cancelIntentTransfer({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          treatment_id: treatmentId,
        },
      });
    }
  };

  const addTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const editTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
        taxdata={defaultAddressDoctor}
      />
    );
  };

  const addDefaultAddressDoctorModal = () => {
    modal.open(
      <ModalFormAddress
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const addCardModal = () => {
    modal.open(
      <ModalFormCard
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const emptyValidation = () => {
    let valid = true;
    if (day !== '' && month !== '' && year !== '') {
      let date = moment(`${year}-${month}-${day}`);
      if (!date.isValid()) valid = false;
      setValidDate(date.isValid());
    }
    if (
      steps === 1 &&
      firstName !== '' &&
      lastName !== '' &&
      treatment !== '' &&
      gender !== '' &&
      day !== '' &&
      month !== '' &&
      year !== '' &&
      (phone === '' || (phone !== '' && validatePhone(phone))) &&
      (email === '' || (email !== '' && validateEmail(email))) &&
      valid
    ) {
      if (patientId === null) {
        registerPatient({
          variables: {
            doctor_id: props.role_id,
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            birthdate: `${year}-${month}-${day}`,
            gender,
            treatment,
          },
        });
      } else {
        updatePatient({
          variables: {
            patient_id: patientId,
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            birthdate: `${year}-${month}-${day}`,
            gender,
            treatment,
          },
        });
      }
    } else {
      if (firstName === '') {
        setvalidName(false);
      } else {
        setvalidName(true);
      }
      if (lastName === '') {
        setValidLastName(false);
      } else {
        setValidLastName(true);
      }
      if (day === '' || month === '' || year === '') {
        setValidDate(false);
      } else {
        setValidDate(true);
      }
    }
  };

  // #endregion

  // #region Hooks

  useEffect(() => {
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    });
    getDefaultAddress({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultTaxData({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultAddressDoctor({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultMethodPayment({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    let patient = localStorage.getItem('Patient');
    if (patient !== undefined && patient !== null) {
      patient = JSON.parse(patient);
      // history.push(`/doctor/continuar-caso/${patient.id}`)
      setPatientId(parseInt(patient.id));
      setTreatmentId(patient.treatment_id);
      getPatient({ variables: { id: patient.id } });
    }
    let lastS = localStorage.getItem('lastStep');
    if (lastS !== undefined && lastS !== null) {
      setSteps(parseInt(lastS));
      setLastStep(parseInt(lastS));
    }
    setSaved(true);
    return () => {
      localStorage.removeItem('Patient');
      localStorage.removeItem('lastStep');
    };
  }, []);

  useEffect(() => {
    if (steps > lastStep) {
      localStorage.setItem('lastStep', steps);
      setLastStep(steps);
    }
  }, [steps]);

  useEffect(() => {
    if (filePatient.name && filePatient.type) {
      uploadPatientForm({
        variables: { patient_id: patientId, file: filePatient },
      });
    }
  }, [filePatient]);

  useEffect(() => {
    validation();
    setSaved(false);
  }, [
    steps,
    firstName,
    lastName,
    email,
    phone,
    treatment,
    gender,
    day,
    month,
    year,
  ]);

  // useEffect(() => {
  //     validation()
  //     if(firstName !== '' && lastName !== '' && email !== '' && phone !== '' && treatment !== '' && gender !== '' && day !== '' && month !== '' && year !== '') setSaved(false)
  // }, [firstName, lastName, email, phone, treatment, gender, day, month, year])

  useEffect(() => {
    if (packType !== null) {
      getTypePrice({
        variables: { type: 'manufact_ss', pack_id: +packType.id },
      });
      selectBrackets({
        variables: { patient_id: patientId, brackets: packType.name },
      });
    }
  }, [packType]);

  useEffect(() => {
    if (treatment === 'cryst_aligner') {
      getTypePrice({ variables: { type: 'planning_ca' } });
    } else {
      getPack({ variables: { type: 'manufact_ss' } });
    }
  }, [treatment]);

  useEffect(() => {
    if (types.length > 0 && brackets !== null) {
      let type = types.find((t) => t.name === brackets);
      if (type !== null) setPackType(type);
    }
  }, [types, brackets]);

  // useEffect(() => {
  //     if(sendModelStl) setShipping({ variables: { patient_id: patientId }})
  // }, [sendModelStl])

  useEffect(() => {
    if (
      Object.keys(defaultPayment).length !== 0 &&
      Object.keys(typePrice).length !== 0
    ) {
      getPaymentInstallments({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          payment_method_ca: defaultPayment.service_id_ca,
          payment_method_ss: defaultPayment.service_id_ss,
          amount: parseFloat(typePrice.amount),
          currency: typePrice.currency,
        },
      });
    }
  }, [defaultPayment, typePrice]);

  useEffect(() => {
    if (!changeCard) {
      getPaymentInstallments({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          payment_method_ca: defaultPayment.service_id_ca,
          payment_method_ss: defaultPayment.service_id_ss,
          amount: parseFloat(typePrice.amount),
          currency: typePrice.currency,
        },
      });
    }
  }, [changeCard]);

  const [universityId, setUniversityId] = useState('');
  const [allTeachers, setAllTeachers] = useState([]);

  const [getStudent] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;
        setUniversityId(doctor.university.id);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getStudent({ variables: { id: localStorage.getItem('b360-role_id') } });
  }, []);

  const [getTeachersByFilter] = useLazyQuery(
    QUERY_GET_TEACHERS_BY_UNIVERSITIES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data.getTeachersByUniversities) {
          setAllTeachers(
            data.getTeachersByUniversities.map((t) => ({
              id: t.id,
              name: `${t.user.first_name} ${t.user.last_name}`,
            }))
          );
        }
      },
    }
  );

  useEffect(() => {
    if (steps === 6 || (treatment === 'smile_system' && steps === 5))
      getTeachersByFilter({
        variables: { university_id: +universityId, filters: [] },
      });
  }, [steps]);

  const [assignTeacherCase] = useMutation(MUTATION_ASSIGN_CASE_TEACHER, {
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => console.log(error),
  });

  // #endregion

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="nuevo-caso" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => {
                if (steps === 2) {
                  if (selectTab > 1) {
                    setSelectTab(selectTab - 1);
                  } else {
                    setSteps(1);
                  }
                } else if (steps === 1) history.goBack();
                else if (steps === 3 && subSteps === 1) {
                  setSubStep(subSteps - 1);
                } else {
                  setSteps(steps - 1);
                }
              }}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => {
                if (steps === 2) {
                  if (selectTab < 5) {
                    setSelectTab(selectTab + 1);
                  } else {
                    setSteps(3);
                  }
                } else if (steps === 7) history.goForward();
                else if (steps === 3 && subSteps === 0) {
                  setSubStep(subSteps + 1);
                } else if (steps < lastStep) {
                  setSteps(steps + 1);
                }
              }}
            />
          </div>
        </Header>
        <ContainContent>
          {!changeCard && !changePayment && !changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                {(treatment === 'cryst_aligner' || treatment === '') && (
                  <ul
                    className="clr-timeline clr-timeline-horizontal"
                    style={{ marginBottom: 30 }}
                  >
                    {itemSteps.map((step) => (
                      <li
                        className="clr-timeline-step"
                        key={Math.random()}
                        onClick={() => {
                          if (step.name <= lastStep) {
                            setSteps(step.name);
                          }
                        }}
                      >
                        <div
                          className={`circle-timeline ${
                            step.name < steps ? 'actives-circle-timeline' : ''
                          } ${
                            step.name === steps ? 'active-circle-timeline' : ''
                          }`}
                        >
                          <LabelNormal
                            color={
                              step.name === steps
                                ? '#EB9542'
                                : step.name < steps
                                ? '#FFF'
                                : '#828282'
                            }
                            fontSize="10"
                          >
                            {step.name}
                          </LabelNormal>
                        </div>
                        <div
                          className={
                            step.name !== 7
                              ? `clr-timeline-step-body ${
                                  step.name < steps ? 'active-step' : ''
                                }`
                              : ''
                          }
                        >
                          <LabelNormal
                            style={{ textAlign: 'center', marginLeft: '-70%' }}
                            color={step.name === steps ? '#EB9542' : '#757575'}
                            fontSize="13"
                          >
                            {step.title}
                          </LabelNormal>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {treatment === 'smile_system' && (
                  <ul
                    className="clr-timeline clr-timeline-horizontal"
                    style={{ marginBottom: 30 }}
                  >
                    {itemStepsSS.map((step) => (
                      <li
                        className="clr-timeline-step"
                        key={Math.random()}
                        onClick={() => {
                          if (step.name <= lastStep) {
                            setSteps(step.name);
                          }
                        }}
                      >
                        <div
                          className={`circle-timeline ${
                            step.name < steps ? 'actives-circle-timeline' : ''
                          } ${
                            step.name === steps ? 'active-circle-timeline' : ''
                          }`}
                        >
                          <LabelNormal
                            color={
                              step.name === steps
                                ? '#EB9542'
                                : step.name < steps
                                ? '#FFF'
                                : '#828282'
                            }
                            fontSize="10"
                          >
                            {step.name}
                          </LabelNormal>
                        </div>
                        <div
                          className={
                            step.name !== 6
                              ? `clr-timeline-step-body ${
                                  step.name < steps ? 'active-step' : ''
                                }`
                              : ''
                          }
                        >
                          <LabelNormal
                            style={{ textAlign: 'center', marginLeft: '-70%' }}
                            color={step.name === steps ? '#EB9542' : '#757575'}
                            fontSize="13"
                          >
                            {step.title}
                          </LabelNormal>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {steps === 1 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>
                        Datos del paciente
                      </Title>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        Ingresa la información para guardar el perfil de tu
                        paciente.
                      </LabelNormal>
                    </div>
                    <div className="col-md-7">
                      <InlineContainer>
                        <div>
                          <Avatar
                            src={
                              photo
                                ? photo
                                : require('@images/people/usuario_masculino.jpg')
                            }
                            onChange={onChangeFile}
                            floatButton
                            height="161"
                            width="161"
                            borderRadius="30"
                          />
                        </div>
                      </InlineContainer>
                    </div>
                    <div className="col-md-7">
                      <div style={{ marginTop: 28 }}>
                        <LabelInput>Tratamiento</LabelInput>
                        <InlineContainer>
                          {crystAligner && (
                            <div className="radio" style={{ width: 180 }}>
                              <input
                                id="treatment-1"
                                name="treatment"
                                onClick={() => setTreatment('cryst_aligner')}
                                checked={
                                  treatment === 'cryst_aligner'
                                    ? 'checked'
                                    : false
                                }
                                type="radio"
                                onChange={() => {}}
                              />
                              <label
                                htmlFor="treatment-1"
                                className="radio-label"
                              >
                                Cryst Aligner
                              </label>
                            </div>
                          )}
                          {smileSystem && (
                            <div className="radio" style={{ width: 180 }}>
                              <input
                                id="treatment-2"
                                name="treatment"
                                onClick={() => setTreatment('smile_system')}
                                checked={
                                  treatment === 'smile_system'
                                    ? 'checked'
                                    : false
                                }
                                type="radio"
                                onChange={() => {}}
                              />
                              <label
                                htmlFor="treatment-2"
                                className="radio-label"
                              >
                                Smile System
                              </label>
                            </div>
                          )}
                        </InlineContainer>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div style={{ marginTop: 38 }} className="grid">
                        <InlineContainer>
                          <div>
                            <Input
                              placeholder="Nombre(s)"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              label="Nombre del paciente"
                              type="custom"
                              validation={validName}
                            />
                            {!validName && (
                              <InlineContainer>
                                <p
                                  style={{
                                    color: '#C60E02',
                                    fontSize: 18,
                                    margin: 0,
                                    padding: 0,
                                    marginTop: 5,
                                    fontFamily: 'neue-montreal-regular',
                                  }}
                                >
                                  *Introduce el nombre(s)
                                </p>
                              </InlineContainer>
                            )}
                          </div>
                          <div>
                            <span
                              style={{ fontSize: 18, visibility: 'hidden' }}
                            >
                              none
                            </span>
                            <Input
                              placeholder="Apellidos"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              type="custom"
                              validation={validLastName}
                            />
                            {!validLastName && (
                              <InlineContainer>
                                <p
                                  style={{
                                    color: '#C60E02',
                                    fontSize: 18,
                                    margin: 0,
                                    padding: 0,
                                    marginTop: 5,
                                    fontFamily: 'neue-montreal-regular',
                                  }}
                                >
                                  *Introduce los apellidos
                                </p>
                              </InlineContainer>
                            )}
                          </div>
                        </InlineContainer>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div>
                        <LabelInput>Sexo</LabelInput>
                        <InlineContainer>
                          <div
                            className="radio"
                            style={{ marginRight: 20, width: 150 }}
                          >
                            <input
                              id="gender-1"
                              onClick={(e) => setGender('Masculino')}
                              checked={
                                gender === 'Masculino' ? 'checked' : false
                              }
                              name="gender"
                              type="radio"
                              onChange={() => {}}
                            />
                            <label htmlFor="gender-1" className="radio-label">
                              Hombre
                            </label>
                          </div>
                          <div
                            className="radio"
                            style={{ marginRight: 20, width: 150 }}
                          >
                            <input
                              id="gender-2"
                              onClick={(e) => setGender('Femenino')}
                              checked={
                                gender === 'Femenino' ? 'checked' : false
                              }
                              name="gender"
                              type="radio"
                              onChange={() => {}}
                            />
                            <label htmlFor="gender-2" className="radio-label">
                              Mujer
                            </label>
                          </div>
                        </InlineContainer>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginTop: 28 }}>
                      <LabelInput>Fecha de nacimiento</LabelInput>
                      <InlineContainer>
                        <Input
                          placeholder="DD"
                          maxWidth="60px"
                          value={day}
                          onChange={setDay}
                          mask="date2"
                          type="day"
                          validDate={validDate}
                        />
                        <Input
                          placeholder="MM"
                          maxWidth="60px"
                          ref={monthRef}
                          value={month}
                          onChange={setMonth}
                          mask="date2"
                          type="monthInput"
                          validDate={validDate}
                        />
                        <Input
                          placeholder="AAAA"
                          maxWidth="90px"
                          ref={yearRef}
                          value={year}
                          onChange={setYear}
                          mask="date4"
                          validDate={validDate}
                        />
                      </InlineContainer>
                      {!validDate && (
                        <InlineContainer>
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              margin: 0,
                              padding: 0,
                              marginTop: 5,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            {(!validDate && day === '') ||
                            month === '' ||
                            year === ''
                              ? '*Introduce tu fecha de nacimiento'
                              : '*Fecha incorrecta'}
                          </p>
                        </InlineContainer>
                      )}
                    </div>
                    <div className="col-md-7">
                      <div style={{ marginTop: 28 }}>
                        <LabelInput>Teléfono (Opcional)</LabelInput>
                        <InlineContainer>
                          <Input
                            readOnly
                            disabled
                            value={'+52'}
                            backgroundColor="#F6F6F8"
                            maxWidth="70px"
                          />
                          <Input
                            placeholder="Teléfono"
                            value={phone}
                            onChange={setPhone}
                            mask="phone"
                            validation="phone"
                          />
                        </InlineContainer>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={{ marginTop: 38 }} className="grid">
                        <Input
                          placeholder="Tu correo"
                          label="Email (Opcional)"
                          value={email}
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          maxWidth="300px"
                        />
                      </div>
                    </div>
                    <div className="col-md-7" style={{ marginBottom: 85 }}>
                      <ButtonPrime
                        label="Siguiente"
                        onClick={() => emptyValidation()}
                      />
                    </div>
                  </ScrollableBody>
                )}
                {steps === 2 && treatment === 'cryst_aligner' && (
                  <FormCA
                    treatment_id={treatmentId}
                    patient_id={patientId}
                    setSteps={setSteps}
                    formCAData={formCAData}
                    selectTab={selectTab}
                    setSelectTab={setSelectTab}
                  />
                )}
                {steps === 2 && treatment === 'smile_system' && (
                  <FormSS
                    treatment_id={treatmentId}
                    patient_id={patientId}
                    setSteps={setSteps}
                    formSSData={formSSData}
                    setPackTypeBack={setPackType}
                  />
                )}
                {steps === 0.1 && (
                  <ScrollableBody>
                    {treatment === 'smile_system' && (
                      <div className="col-md-12">
                        <Title style={{ marginBottom: 5 }}>Brackets</Title>
                        <LabelNormal
                          color="#757575"
                          fontSize="17"
                          style={{ marginBottom: 15 }}
                        >
                          Selecciona un tipo de bracket antes de subir tu
                          formulario.
                        </LabelNormal>
                        <InlineContainer style={{ marginBottom: 35 }}>
                          {types.map((item) => (
                            <div
                              className="radio"
                              style={{ marginRight: 20 }}
                              key={Math.random()}
                            >
                              <input
                                id={`typespack-${item.id}`}
                                name="typespack"
                                onClick={() => setPackType(item)}
                                defaultChecked={
                                  packType !== null && packType.id === item.id
                                    ? 'checked'
                                    : false
                                }
                                type="radio"
                              />
                              <label
                                htmlFor={`typespack-${item.id}`}
                                style={{ color: '#3C4549' }}
                                className="radio-label"
                              >
                                {item.name.split('Fit Jig + Brackets ')[1]}
                              </label>
                            </div>
                          ))}
                        </InlineContainer>
                      </div>
                    )}
                    {treatment === 'cryst_aligner' && (
                      <div className="col-md-12">
                        <Title style={{ marginBottom: 5 }}>
                          Formulario de tratamiento
                        </Title>
                        <LabelNormal
                          color="#757575"
                          fontSize="17"
                          style={{ marginBottom: 30 }}
                        >
                          Descarga el{' '}
                          <CustomHref
                            style={{ color: '#18A1DC', textDecoration: 'none' }}
                            href={`${process.env.REACT_APP_API_URL}/formats/Formato-B360-CA.pdf`}
                            target="_blank"
                          >
                            formato
                          </CustomHref>
                          , llénalo, escánealo y súbelo en formato PDF.
                        </LabelNormal>
                      </div>
                    )}
                    {treatment === 'smile_system' && (
                      <div className="col-md-12">
                        <Title style={{ marginBottom: 5 }}>
                          Formulario de tratamiento
                        </Title>
                        <LabelNormal
                          color="#757575"
                          fontSize="17"
                          style={{ marginBottom: 30 }}
                        >
                          Descarga el{' '}
                          <CustomHref
                            style={{ color: '#18A1DC', textDecoration: 'none' }}
                            href={`${process.env.REACT_APP_API_URL}/formats/Formato-B360-SS.pdf`}
                            target="_blank"
                          >
                            formato
                          </CustomHref>
                          , llénalo, escánealo y súbelo en formato PDF.
                        </LabelNormal>
                      </div>
                    )}
                    <div className="col-md-7">
                      {filePatient.name && (
                        <InlineContainer
                          style={{ alignItems: 'center', marginBottom: 30 }}
                        >
                          <InlineContainer
                            style={{
                              padding: 10,
                              background: '#F6F6F8',
                              marginTop: 10,
                              borderRadius: 5,
                            }}
                          >
                            {stateFile ? (
                              <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ) : (
                              <img
                                src={require('@images/files/pdf-file.jpg')}
                                style={{ height: 20, width: 16 }}
                              />
                            )}
                            <LabelNormal fontSize="17" color="#757575">
                              {filePatient.name}
                            </LabelNormal>
                          </InlineContainer>
                          <div style={{ marginLeft: 20, paddingTop: 14 }}>
                            {filePatient.name && !stateFile && (
                              <LabelUnderline
                                color="#757575"
                                fontSize="17"
                                onClick={() => inputRef.current.click()}
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginLeft: 30,
                                }}
                                label={'Cambiar archivo'}
                              />
                            )}
                          </div>
                        </InlineContainer>
                      )}
                      <ButtonPrime
                        label={
                          filePatient.name ? 'Siguiente' : 'Subir formulario'
                        }
                        onClick={() => {
                          filePatient.name
                            ? setSteps(3)
                            : inputRef.current.click();
                        }}
                      />
                      <input
                        type="file"
                        ref={inputRef}
                        accept=".pdf"
                        onChange={(e) => {
                          setStateFile(true);
                          setFilePatient(e.target.files[0]);
                        }}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </ScrollableBody>
                )}
                {subSteps === 0 && steps === 3 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>
                        Fotografías intraorales
                      </Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube las fotografías para tu tratamiento. Puedes
                          arrastrar tu archivo hasta su
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            casilla correspondiente o hacer click en el botón de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg o png
                      </LabelNormal>
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        marginBottom: 100,
                        marginRight: 40,
                      }}
                    >
                      <InlineContainer>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="superior"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('superior');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="superior"
                              onDrop={onChangeFileTreatment}
                            >
                              {loading && compLoading === 'superior' && (
                                <Loader />
                              )}
                              {dropIn === 'superior' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 4,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  name="superior"
                                  src={
                                    filesTreatment.superior &&
                                    filesTreatment.superior.preview
                                      ? filesTreatment.superior.preview
                                      : require('@images/intraorales/superior.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateOclSup
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'superior') &&
                                dropIn !== 'superior' && (
                                  <label
                                    htmlFor="superior"
                                    style={
                                      filesTreatment.superior &&
                                      filesTreatment.superior.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatment.superior &&
                                    filesTreatment.superior.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefOclSup.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefOclSup.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefOclSup}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={onChangeFileTreatment}
                                      name="superior"
                                      id="superior"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatment.superior &&
                            filesTreatment.superior.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatment.superior.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Oclusal superior
                          </LabelNormal>
                          {!validateOclSup && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            width: 258,
                            marginRight: 20,
                            marginBottom: 20,
                          }}
                        ></div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="inferior"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('inferior');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="inferior"
                              onDrop={onChangeFileTreatment}
                            >
                              {loading && compLoading === 'inferior' && (
                                <Loader />
                              )}
                              {dropIn === 'inferior' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 4,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  name="inferior"
                                  src={
                                    filesTreatment.inferior &&
                                    filesTreatment.inferior.preview
                                      ? filesTreatment.inferior.preview
                                      : require('@images/intraorales/inferior.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateOclInf
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'inferior') &&
                                dropIn !== 'inferior' && (
                                  <label
                                    htmlFor="inferior"
                                    style={
                                      filesTreatment.inferior &&
                                      filesTreatment.inferior.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatment.inferior &&
                                    filesTreatment.inferior.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefOclInf.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefOclInf.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefOclInf}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={onChangeFileTreatment}
                                      name="inferior"
                                      id="inferior"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatment.inferior &&
                            filesTreatment.inferior.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatment.inferior.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Oclusal inferior
                          </LabelNormal>
                          {!validateOclInf && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                      </InlineContainer>
                      <InlineContainer>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="lateral_izquierda"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('lateral_izquierda');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="lateral_izquierda"
                              onDrop={onChangeFileTreatment}
                            >
                              {loading &&
                                compLoading === 'lateral_izquierda' && (
                                  <Loader />
                                )}
                              {dropIn === 'lateral_izquierda' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 4,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  name="lateral_izquierda"
                                  src={
                                    filesTreatment.lateral_izquierda &&
                                    filesTreatment.lateral_izquierda.preview
                                      ? filesTreatment.lateral_izquierda.preview
                                      : require('@images/intraorales/lateral-izquierda.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateLateralIzqOcl
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'lateral_izquierda') &&
                                dropIn !== 'lateral_izquierda' && (
                                  <label
                                    htmlFor="lateral_izquierda"
                                    style={
                                      filesTreatment.lateral_izquierda &&
                                      filesTreatment.lateral_izquierda.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatment.lateral_izquierda &&
                                    filesTreatment.lateral_izquierda.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralIzqOcl.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralIzqOcl.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefLateralIzqOcl}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={onChangeFileTreatment}
                                      name="lateral_izquierda"
                                      id="lateral_izquierda"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatment.lateral_izquierda &&
                            filesTreatment.lateral_izquierda.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatment.lateral_izquierda.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Lateral izquierda en oclusión
                          </LabelNormal>
                          {!validateLateralIzqOcl && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="frontal"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('frontal');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="frontal"
                              onDrop={onChangeFileTreatment}
                            >
                              {loading && compLoading === 'frontal' && (
                                <Loader />
                              )}
                              {dropIn === 'frontal' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 4,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  name="frontal"
                                  src={
                                    filesTreatment.frontal &&
                                    filesTreatment.frontal.preview
                                      ? filesTreatment.frontal.preview
                                      : require('@images/intraorales/frontal.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateFrontalOcl
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'frontal') &&
                                dropIn !== 'frontal' && (
                                  <label
                                    htmlFor="frontal"
                                    style={
                                      filesTreatment.frontal &&
                                      filesTreatment.frontal.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatment.frontal &&
                                    filesTreatment.frontal.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFrontalOcl.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFrontalOcl.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefFrontalOcl}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={onChangeFileTreatment}
                                      name="frontal"
                                      id="frontal"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatment.frontal &&
                            filesTreatment.frontal.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatment.frontal.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Frontal en oclusión
                          </LabelNormal>
                          {!validateFrontalOcl && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="lateral_derecha"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('lateral_derecha');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="lateral_derecha"
                              onDrop={onChangeFileTreatment}
                            >
                              {loading && compLoading === 'lateral_derecha' && (
                                <Loader />
                              )}
                              {dropIn === 'lateral_derecha' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 4,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  name="lateral_derecha"
                                  src={
                                    filesTreatment.lateral_derecha &&
                                    filesTreatment.lateral_derecha.preview
                                      ? filesTreatment.lateral_derecha.preview
                                      : require('@images/intraorales/lateral-derecha.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateLateralDerOcl
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'lateral_derecha') &&
                                dropIn !== 'lateral_derecha' && (
                                  <label
                                    htmlFor="lateral_derecha"
                                    style={
                                      filesTreatment.lateral_derecha &&
                                      filesTreatment.lateral_derecha.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatment.lateral_derecha &&
                                    filesTreatment.lateral_derecha.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralDerOcl.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralDerOcl.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefLateralDerOcl}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={onChangeFileTreatment}
                                      name="lateral_derecha"
                                      id="lateral_derecha"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatment.lateral_derecha &&
                            filesTreatment.lateral_derecha.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatment.lateral_derecha.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Lateral derecha en oclusión
                          </LabelNormal>
                          {!validateLateralDerOcl && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                      </InlineContainer>
                      {!validateOnIntraoral && (
                        <div
                          style={{
                            marginBottom: 18,
                            background: '#FFE5E3',
                            border: '1px solid #E41D11',
                            padding: '2px 0px 2px 2px',
                            borderRadius: 7,
                            maxWidth: 230,
                          }}
                        >
                          <LabelNormal fontSize="14" color="#C60E02">
                            *Completa los campos faltantes
                          </LabelNormal>
                        </div>
                      )}
                      <ButtonPrime
                        label="Siguiente"
                        onClick={() => validateIntraoral()}
                      ></ButtonPrime>
                    </div>
                  </ScrollableBody>
                )}
                {subSteps === 1 && steps === 3 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>
                        Fotografías extraorales
                      </Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube las fotografías para tu tratamiento. Puedes
                          arrastrar tu archivo hasta su
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            casilla correspondiente o hacer click en el botón de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg o png
                      </LabelNormal>
                    </div>
                    <div style={{ maxWidth: '850px' }}>
                      <InlineContainer style={{ gap: '30px' }}>
                        <div>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="frontal_serio"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('frontal_serio');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="frontal_serio"
                              onDrop={onChangeFileTreatmentExtraorales}
                            >
                              {loading && compLoading === 'frontal_serio' && (
                                <Loader />
                              )}
                              {dropIn === 'frontal_serio' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentExtraorales.frontal_serio &&
                                    filesTreatmentExtraorales.frontal_serio
                                      .preview
                                      ? filesTreatmentExtraorales.frontal_serio
                                          .preview
                                      : require('@images/extraorales/frontal_serio.jpg')
                                  }
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateFroSer
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'frontal_serio') &&
                                dropIn !== 'frontal_serio' && (
                                  <label
                                    htmlFor="frontal_serio"
                                    style={
                                      filesTreatmentExtraorales.frontal_serio &&
                                      filesTreatmentExtraorales.frontal_serio
                                        .preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentExtraorales.frontal_serio &&
                                    filesTreatmentExtraorales.frontal_serio
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroSer.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroSer.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefFroSer}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentExtraorales
                                      }
                                      name="frontal_serio"
                                      id="frontal_serio"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentExtraorales.frontal_serio &&
                            filesTreatmentExtraorales.frontal_serio.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentExtraorales.frontal_serio.file
                                    .name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Frontal serio
                          </LabelNormal>
                          {!validateFroSer && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="frontal_sonriendo"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('frontal_sonriendo');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="frontal_sonriendo"
                              onDrop={onChangeFileTreatmentExtraorales}
                            >
                              {loading &&
                                compLoading === 'frontal_sonriendo' && (
                                  <Loader />
                                )}
                              {dropIn === 'frontal_sonriendo' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentExtraorales.frontal_sonriendo &&
                                    filesTreatmentExtraorales.frontal_sonriendo
                                      .preview
                                      ? filesTreatmentExtraorales
                                          .frontal_sonriendo.preview
                                      : require('@images/extraorales/frontal_sonriendo.jpg')
                                  }
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateFroSon
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'frontal_sonriendo') &&
                                dropIn !== 'frontal_sonriendo' && (
                                  <label
                                    htmlFor="frontal_sonriendo"
                                    style={
                                      filesTreatmentExtraorales.frontal_sonriendo &&
                                      filesTreatmentExtraorales
                                        .frontal_sonriendo.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentExtraorales.frontal_sonriendo &&
                                    filesTreatmentExtraorales.frontal_sonriendo
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroSon.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroSon.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefFroSon}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentExtraorales
                                      }
                                      name="frontal_sonriendo"
                                      id="frontal_sonriendo"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentExtraorales.frontal_sonriendo &&
                            filesTreatmentExtraorales.frontal_sonriendo
                              .file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentExtraorales.frontal_sonriendo
                                    .file.name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Frontal sonriendo
                          </LabelNormal>
                          {!validateFroSon && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="frontal_retractore"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('frontal_retractore');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="frontal_retractore"
                              onDrop={onChangeFileTreatmentExtraorales}
                            >
                              {loading &&
                                compLoading === 'frontal_retractore' && (
                                  <Loader />
                                )}
                              {dropIn === 'frontal_retractore' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentExtraorales.frontal_retractore &&
                                    filesTreatmentExtraorales.frontal_retractore
                                      .preview
                                      ? filesTreatmentExtraorales
                                          .frontal_retractore.preview
                                      : require('@images/extraorales/frontal_retractores.jpg')
                                  }
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateFroRet
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'frontal_retractore') &&
                                dropIn !== 'frontal_retractore' && (
                                  <label
                                    htmlFor="frontal_retractore"
                                    style={
                                      filesTreatmentExtraorales.frontal_retractore &&
                                      filesTreatmentExtraorales
                                        .frontal_retractore.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentExtraorales.frontal_retractore &&
                                    filesTreatmentExtraorales.frontal_retractore
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroRet.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefFroRet.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefFroRet}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentExtraorales
                                      }
                                      name="frontal_retractore"
                                      id="frontal_retractore"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentExtraorales.frontal_retractore &&
                            filesTreatmentExtraorales.frontal_retractore
                              .file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentExtraorales.frontal_retractore
                                    .file.name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Frontal con retractores
                          </LabelNormal>
                          {!validateFroRet && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                      </InlineContainer>
                      <InlineContainer
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '30px',
                          marginTop: '30px',
                        }}
                      >
                        <div>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="lateral_izquierda"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('lateral_izquierda');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="lateral_izquierda"
                              onDrop={onChangeFileTreatmentExtraorales}
                            >
                              {loading &&
                                compLoading === 'lateral_izquierda' && (
                                  <Loader />
                                )}
                              {dropIn === 'lateral_izquierda' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentExtraorales.lateral_izquierda &&
                                    filesTreatmentExtraorales.lateral_izquierda
                                      .preview
                                      ? filesTreatmentExtraorales
                                          .lateral_izquierda.preview
                                      : require('@images/extraorales/lateral_izq_serio.jpg')
                                  }
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateLateraIzqSer
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'lateral_izquierda') &&
                                dropIn !== 'lateral_izquierda' && (
                                  <label
                                    htmlFor="lateral_izquierda"
                                    style={
                                      filesTreatmentExtraorales.lateral_izquierda &&
                                      filesTreatmentExtraorales
                                        .lateral_izquierda.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentExtraorales.lateral_izquierda &&
                                    filesTreatmentExtraorales.lateral_izquierda
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateraIzqSer.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateraIzqSer.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefLateraIzqSer}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentExtraorales
                                      }
                                      name="lateral_izquierda"
                                      id="lateral_izquierda"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentExtraorales.lateral_izquierda &&
                            filesTreatmentExtraorales.lateral_izquierda
                              .file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentExtraorales.lateral_izquierda
                                    .file.name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Lateral izquierda serio
                          </LabelNormal>
                          {!validateLateraIzqSer && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                        <div>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="lateral_derecha"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('lateral_derecha');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="lateral_derecha"
                              onDrop={onChangeFileTreatmentExtraorales}
                            >
                              {loading && compLoading === 'lateral_derecha' && (
                                <Loader />
                              )}
                              {dropIn === 'lateral_derecha' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentExtraorales.lateral_derecha &&
                                    filesTreatmentExtraorales.lateral_derecha
                                      .preview
                                      ? filesTreatmentExtraorales
                                          .lateral_derecha.preview
                                      : require('@images/extraorales/lateral_der_serio.jpg')
                                  }
                                  style={{
                                    height: 258,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                    borderRadius: 4,
                                    border: validateLateralDerSer
                                      ? '2px transparent solid'
                                      : '2px #C60E02 solid',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'lateral_derecha') &&
                                dropIn !== 'lateral_derecha' && (
                                  <label
                                    htmlFor="lateral_derecha"
                                    style={
                                      filesTreatmentExtraorales.lateral_derecha &&
                                      filesTreatmentExtraorales.lateral_derecha
                                        .preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentExtraorales.lateral_derecha &&
                                    filesTreatmentExtraorales.lateral_derecha
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralDerSer.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLateralDerSer.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefLateralDerSer}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentExtraorales
                                      }
                                      name="lateral_derecha"
                                      id="lateral_derecha"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentExtraorales.lateral_derecha &&
                            filesTreatmentExtraorales.lateral_derecha.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentExtraorales.lateral_derecha.file
                                    .name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Lateral derecha serio
                          </LabelNormal>
                          {!validateLateralDerSer && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 17,
                                fontFamily: 'neue-montreal-regular',
                                margin: 0,
                              }}
                            >
                              *Sube la fotografía
                            </p>
                          )}
                        </div>
                      </InlineContainer>
                      <ButtonPrime
                        label="Siguiente"
                        onClick={() => validateExtraoral()}
                      />
                    </div>
                  </ScrollableBody>
                )}
                {steps === 4 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>STL’s</Title>
                      <>
                        <div style={{ marginBottom: 10 }}>
                          <LabelNormal color="#757575" fontSize="17">
                            Selecciona cuál es el formato con el que subirás los
                            escaneos intraorales.
                          </LabelNormal>
                        </div>
                        <div>
                          <InlineContainer>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="stl-send-1"
                                onClick={(e) => setRadioStl('archivos')}
                                checked={
                                  radioStl === 'archivos' ? 'checked' : false
                                }
                                name="stl-send"
                                type="radio"
                              />
                              <label
                                htmlFor="stl-send-1"
                                className="radio-label"
                              >
                                Archivos stl’s
                              </label>
                            </div>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="stl-send-2"
                                onClick={(e) => setRadioStl('url')}
                                checked={radioStl === 'url' ? 'checked' : false}
                                name="stl-send"
                                type="radio"
                              />
                              <label
                                htmlFor="stl-send-2"
                                className="radio-label"
                              >
                                Link de estudio radiológico
                              </label>
                            </div>
                            {treatment === 'cryst_aligner' && (
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="stl-send-3"
                                  onClick={(e) => setRadioStl('model')}
                                  checked={
                                    radioStl === 'model' ? 'checked' : false
                                  }
                                  name="stl-send"
                                  type="radio"
                                />
                                <label
                                  htmlFor="stl-send-3"
                                  className="radio-label"
                                >
                                  Envío de modelos (paquetería)
                                </label>
                              </div>
                            )}
                          </InlineContainer>
                        </div>
                      </>
                      {radioStl === 'model' && (
                        <div style={{ marginBottom: 30, marginTop: 30 }}>
                          <LabelNormal color="#4F4F4F" fontSize="22">
                            Envío de modelos
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            Manda tus modelos por el servicio de paquetería de
                            tu elección a la siguiente dirección y con los
                            siguientes datos en una hoja de papel dentro del
                            paquete.
                          </LabelNormal>
                        </div>
                      )}
                      {radioStl === 'url' && (
                        <div style={{ marginBottom: 30, marginTop: 30 }}>
                          <LabelNormal color="#4F4F4F" fontSize="22">
                            Envío por link
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            Pega el link que te otorgó tu estudio radiológico
                            donde se incluyen los archivos:
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Arcada superior
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Arcada inferior
                          </LabelNormal>
                          <LabelNormal color="#828282" fontSize="17">
                            - Oclusión (1 o 2 archivos)
                          </LabelNormal>
                        </div>
                      )}
                    </div>
                    {radioStl === 'model' && (
                      <div className="col-md-12">
                        <InlineContainer>
                          <CardModel>
                            <LabelNormal
                              style={{
                                margin: 0,
                                padding: 0,
                                marginRight: 7,
                                marginBottom: 10,
                              }}
                              color="#757575"
                              fontSize="18"
                            >
                              Datos a incluir
                            </LabelNormal>
                            <InlineContainer>
                              <LabelNormal
                                color="#BDBDBD"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginRight: 7,
                                }}
                                fontSize="17"
                              >
                                Nombre del doctor
                              </LabelNormal>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                {doctorData.user && doctorData.user.first_name
                                  ? `${doctorData.user.first_name} ${doctorData.user.last_name}`
                                  : ''}
                              </LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <LabelNormal
                                color="#BDBDBD"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginRight: 7,
                                }}
                                fontSize="17"
                              >
                                Nombre del paciente
                              </LabelNormal>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            {/* <InlineContainer>
                                                    <LabelNormal color="#BDBDBD" style={{margin: 0, padding: 0, marginRight: 7}} fontSize="17">ID de caso</LabelNormal>
                                                    <LabelNormal color="#828282" style={{margin: 0, padding: 0}} fontSize="17">{`${patientId}`}</LabelNormal>
                                                </InlineContainer> */}
                          </CardModel>
                          <CardModelExtended>
                            <LabelNormal
                              style={{ margin: 0, padding: 0, marginRight: 7 }}
                              color="#757575"
                              fontSize="18"
                            >
                              Dirección de envío | Borgatta
                            </LabelNormal>
                            <InlineContainer style={{ marginBottom: 10 }}>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                <span style={{ color: '#BDBDBD' }}>
                                  Atención a:{' '}
                                </span>
                                Karina Cruz, departamento Atención Inteligencia
                                Médica.
                              </LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <LabelNormal
                                color="#828282"
                                style={{ margin: 0, padding: 0 }}
                                fontSize="17"
                              >
                                <span style={{ color: '#BDBDBD' }}>
                                  Dirección:{' '}
                                </span>
                                {defaultAddress.id
                                  ? `${defaultAddress.street}, ${defaultAddress.number} ${defaultAddress.suburb}, CP ${defaultAddress.postal_code} ${defaultAddress.city}, ${defaultAddress.state}`
                                  : ''}
                              </LabelNormal>
                            </InlineContainer>
                          </CardModelExtended>
                        </InlineContainer>
                      </div>
                    )}
                    {radioStl === 'url' && (
                      <div className="col-md-4">
                        {link_data.map((item, key) => (
                          <InlineContainer
                            style={{
                              marginTop: 20,
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Input
                              placeholder="URL"
                              type="url"
                              validation="url"
                              onChange={(e) =>
                                setLinkData([
                                  ...link_data.map((v, i) =>
                                    i === key ? `${e.target.value}` : v
                                  ),
                                ])
                              }
                              value={item !== '' ? item : ''}
                            />
                            {key === link_data.length - 1 && (
                              <ButtonFile
                                onClick={() =>
                                  setLinkData(['', ...link_data.map((v) => v)])
                                }
                                icon={'plus'}
                                size={27}
                                disabled={
                                  item !== '' && validateURL(item)
                                    ? false
                                    : true
                                }
                              />
                            )}
                            {key < link_data.length - 1 && (
                              <ButtonFile
                                onClick={() =>
                                  setLinkData([
                                    ...link_data.filter((v, i) => i !== key),
                                  ])
                                }
                                icon={'minus'}
                                size={27}
                              />
                            )}
                          </InlineContainer>
                        ))}
                        {/* <InlineContainer>
                                            <Input placeholder="URL" type="url" validation="url" value={urlSTL} onChange={(e) => setUrlSTL(e.target.value)}/>
                                        </InlineContainer> */}
                      </div>
                    )}
                    {radioStl === 'archivos' && (
                      <div className="col-md-12" style={{ marginTop: 45 }}>
                        <LabelNormal fontSize="22" color="#757575">
                          Instrucciones
                        </LabelNormal>
                        <InlineContainer
                          style={{
                            alignItems: 'center',
                            marginBottom: 30,
                            marginTop: 10,
                          }}
                        >
                          <LabelNormal fontSize="17" color="#757575">
                            Sube los archivos STL para tu tratamiento. Puedes
                            arrastrar tu archivo hasta
                          </LabelNormal>
                          <InlineContainer>
                            <LabelNormal color="#757575" fontSize="17">
                              su casilla correspondiente o hacer click en el
                              botón de{' '}
                            </LabelNormal>
                            <img
                              src={require('@images/icons/upload-circle.png')}
                              style={{ height: 23, width: 23 }}
                            />
                          </InlineContainer>
                        </InlineContainer>
                        <InlineContainer>
                          <div style={{ marginRight: 20, marginBottom: 20 }}>
                            <div style={{ position: 'relative' }}>
                              <label
                                htmlFor="arcada_superior"
                                onDragOver={(e) => {
                                  e.preventDefault();
                                  setDropIn('arcada_superior');
                                  onDragOver();
                                }}
                                onDragLeave={() => {
                                  setDropIn(null);
                                  onDragLeave();
                                }}
                                name="arcada_superior"
                                onDrop={onChangeFileTreatmentSTL}
                              >
                                {loading &&
                                  compLoading === 'arcada_superior' && (
                                    <Loader />
                                  )}
                                {dropIn === 'arcada_superior' ? (
                                  <img
                                    src={DragBack}
                                    style={{
                                      height: 167,
                                      width: 258,
                                      backgroundPosition: 'cover',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      filesTreatmentStl.arcada_superior &&
                                      filesTreatmentStl.arcada_superior.preview
                                        ? filesTreatmentStl.arcada_superior
                                            .preview
                                        : require('@images/stl/arcada-superior.jpg')
                                    }
                                    style={{
                                      height: 167,
                                      width: 258,
                                      borderRadius: 7,
                                      backgroundPosition: 'cover',
                                    }}
                                  />
                                )}
                                {(loading === false ||
                                  compLoading !== 'arcada_superior') &&
                                  dropIn !== 'arcada_superior' && (
                                    <label
                                      htmlFor="arcada_superior"
                                      style={
                                        filesTreatmentStl.arcada_superior &&
                                        filesTreatmentStl.arcada_superior
                                          .preview
                                          ? {
                                              position: 'absolute',
                                              bottom: 10,
                                              right: 19,
                                              cursor: 'pointer',
                                            }
                                          : {
                                              position: 'absolute',
                                              bottom: 15,
                                              right: 12,
                                              cursor: 'pointer',
                                            }
                                      }
                                    >
                                      {filesTreatmentStl.arcada_superior &&
                                      filesTreatmentStl.arcada_superior
                                        .preview ? (
                                        <ButtonFile
                                          onClick={() => {
                                            inputRefArcSup.current.click();
                                          }}
                                          icon={'edit'}
                                          size={25}
                                        />
                                      ) : (
                                        <ButtonFile
                                          onClick={() => {
                                            inputRefArcSup.current.click();
                                          }}
                                          icon={'plus'}
                                          size={25}
                                        />
                                      )}
                                      <input
                                        type="file"
                                        ref={inputRefArcSup}
                                        accept=".stl"
                                        onChange={onChangeFileTreatmentSTL}
                                        name="arcada_superior"
                                        id="arcada_superior"
                                        style={{ display: 'none' }}
                                      />
                                    </label>
                                  )}
                              </label>
                            </div>
                            {filesTreatmentStl.arcada_superior &&
                              filesTreatmentStl.arcada_superior.file && (
                                <LabelNormal
                                  style={{
                                    width: 258,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                  color="#828282"
                                  fontSize="17"
                                >
                                  {filesTreatmentStl.arcada_superior.file.name}
                                </LabelNormal>
                              )}
                            <LabelNormal color="#3C4549" fontSize="17">
                              Arcada superior
                            </LabelNormal>
                          </div>
                          <div style={{ marginRight: 20, marginBottom: 20 }}>
                            <div style={{ position: 'relative' }}>
                              <label
                                htmlFor="arcada_inferior"
                                onDragOver={(e) => {
                                  e.preventDefault();
                                  setDropIn('arcada_inferior');
                                  onDragOver();
                                }}
                                onDragLeave={() => {
                                  setDropIn(null);
                                  onDragLeave();
                                }}
                                name="arcada_inferior"
                                onDrop={onChangeFileTreatmentSTL}
                              >
                                {loading &&
                                  compLoading === 'arcada_inferior' && (
                                    <Loader />
                                  )}
                                {dropIn === 'arcada_inferior' ? (
                                  <img
                                    src={DragBack}
                                    style={{
                                      height: 167,
                                      width: 258,
                                      backgroundPosition: 'cover',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      filesTreatmentStl.arcada_inferior &&
                                      filesTreatmentStl.arcada_inferior.preview
                                        ? filesTreatmentStl.arcada_inferior
                                            .preview
                                        : require('@images/stl/arcada-inferior.jpg')
                                    }
                                    style={{
                                      height: 167,
                                      width: 258,
                                      borderRadius: 7,
                                      backgroundPosition: 'cover',
                                    }}
                                  />
                                )}
                                {(loading === false ||
                                  compLoading !== 'arcada_inferior') &&
                                  dropIn !== 'arcada_inferior' && (
                                    <label
                                      htmlFor="arcada_inferior"
                                      style={
                                        filesTreatmentStl.arcada_inferior &&
                                        filesTreatmentStl.arcada_inferior
                                          .preview
                                          ? {
                                              position: 'absolute',
                                              bottom: 10,
                                              right: 19,
                                              cursor: 'pointer',
                                            }
                                          : {
                                              position: 'absolute',
                                              bottom: 15,
                                              right: 12,
                                              cursor: 'pointer',
                                            }
                                      }
                                    >
                                      {filesTreatmentStl.arcada_inferior &&
                                      filesTreatmentStl.arcada_inferior
                                        .preview ? (
                                        <ButtonFile
                                          onClick={() => {
                                            inputRefArcInf.current.click();
                                          }}
                                          icon={'edit'}
                                          size={25}
                                        />
                                      ) : (
                                        <ButtonFile
                                          onClick={() => {
                                            inputRefArcInf.current.click();
                                          }}
                                          icon={'plus'}
                                          size={25}
                                        />
                                      )}
                                      <input
                                        type="file"
                                        ref={inputRefArcInf}
                                        accept=".stl"
                                        onChange={onChangeFileTreatmentSTL}
                                        name="arcada_inferior"
                                        id="arcada_inferior"
                                        style={{ display: 'none' }}
                                      />
                                    </label>
                                  )}
                              </label>
                            </div>
                            {filesTreatmentStl.arcada_inferior &&
                              filesTreatmentStl.arcada_inferior.file && (
                                <LabelNormal
                                  style={{
                                    width: 258,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                  color="#828282"
                                  fontSize="17"
                                >
                                  {filesTreatmentStl.arcada_inferior.file.name}
                                </LabelNormal>
                              )}
                            <LabelNormal color="#3C4549" fontSize="17">
                              Arcada inferior
                            </LabelNormal>
                          </div>
                        </InlineContainer>
                        <InlineContainer>
                          {(oclusionStl === 'no' || oclusionStl === 'si') && (
                            <div style={{ marginRight: 20, marginBottom: 20 }}>
                              <div style={{ position: 'relative' }}>
                                <label
                                  htmlFor="oclusion_derecha"
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                    setDropIn('oclusion_derecha');
                                    onDragOver();
                                  }}
                                  onDragLeave={() => {
                                    setDropIn(null);
                                    onDragLeave();
                                  }}
                                  name="oclusion_derecha"
                                  onDrop={onChangeFileTreatmentSTL}
                                >
                                  {loading &&
                                    compLoading === 'oclusion_derecha' && (
                                      <Loader />
                                    )}
                                  {dropIn === 'oclusion_derecha' ? (
                                    <img
                                      src={DragBack}
                                      style={{
                                        height: 167,
                                        width: 258,
                                        backgroundPosition: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        filesTreatmentStl.oclusion_derecha &&
                                        filesTreatmentStl.oclusion_derecha
                                          .preview
                                          ? filesTreatmentStl.oclusion_derecha
                                              .preview
                                          : require('@images/stl/oclusion-derecha.jpg')
                                      }
                                      style={{
                                        height: 167,
                                        width: 258,
                                        borderRadius: 7,
                                        backgroundPosition: 'cover',
                                      }}
                                    />
                                  )}
                                  {(loading === false ||
                                    compLoading !== 'oclusion') &&
                                    dropIn !== 'oclusion_derecha' && (
                                      <label
                                        htmlFor="oclusion_derecha"
                                        style={
                                          filesTreatmentStl.oclusion_derecha &&
                                          filesTreatmentStl.oclusion_derecha
                                            .preview
                                            ? {
                                                position: 'absolute',
                                                bottom: 10,
                                                right: 19,
                                                cursor: 'pointer',
                                              }
                                            : {
                                                position: 'absolute',
                                                bottom: 15,
                                                right: 12,
                                                cursor: 'pointer',
                                              }
                                        }
                                      >
                                        {filesTreatmentStl.oclusion_derecha &&
                                        filesTreatmentStl.oclusion_derecha
                                          .preview ? (
                                          <ButtonFile
                                            onClick={() => {
                                              inputRefOclDer.current.click();
                                            }}
                                            icon={'edit'}
                                            size={25}
                                          />
                                        ) : (
                                          <ButtonFile
                                            onClick={() => {
                                              inputRefOclDer.current.click();
                                            }}
                                            icon={'plus'}
                                            size={25}
                                          />
                                        )}
                                        <input
                                          type="file"
                                          ref={inputRefOclDer}
                                          accept=".stl"
                                          onChange={onChangeFileTreatmentSTL}
                                          name="oclusion_derecha"
                                          id="oclusion_derecha"
                                          style={{ display: 'none' }}
                                        />
                                      </label>
                                    )}
                                </label>
                              </div>
                              {filesTreatmentStl.oclusion_derecha &&
                                filesTreatmentStl.oclusion_derecha.file && (
                                  <LabelNormal
                                    style={{
                                      width: 258,
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                    color="#828282"
                                    fontSize="17"
                                  >
                                    {
                                      filesTreatmentStl.oclusion_derecha.file
                                        .name
                                    }
                                  </LabelNormal>
                                )}
                              <LabelNormal color="#3C4549" fontSize="17">
                                Oclusión derecha
                              </LabelNormal>
                            </div>
                          )}
                          {oclusionStl === 'si' && (
                            <div style={{ marginRight: 20, marginBottom: 20 }}>
                              <div style={{ position: 'relative' }}>
                                <label
                                  htmlFor="oclusion_izquierda"
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                    setDropIn('oclusion_izquierda');
                                    onDragOver();
                                  }}
                                  onDragLeave={() => {
                                    setDropIn(null);
                                    onDragLeave();
                                  }}
                                  name="oclusion_izquierda"
                                  onDrop={onChangeFileTreatmentSTL}
                                >
                                  {loading &&
                                    compLoading === 'oclusion_izquierda' && (
                                      <Loader />
                                    )}
                                  {dropIn === 'oclusion_izquierda' ? (
                                    <img
                                      src={DragBack}
                                      style={{
                                        height: 167,
                                        width: 258,
                                        backgroundPosition: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        filesTreatmentStl.oclusion_izquierda &&
                                        filesTreatmentStl.oclusion_izquierda
                                          .preview
                                          ? filesTreatmentStl.oclusion_izquierda
                                              .preview
                                          : require('@images/stl/oclusion-izquierda.jpg')
                                      }
                                      style={{
                                        height: 167,
                                        width: 258,
                                        borderRadius: 7,
                                        backgroundPosition: 'cover',
                                      }}
                                    />
                                  )}
                                  {(loading === false ||
                                    compLoading !== 'oclusion') &&
                                    dropIn !== 'oclusion_izquierda' && (
                                      <label
                                        htmlFor="oclusion_izquierda"
                                        style={
                                          filesTreatmentStl.oclusion_izquierda &&
                                          filesTreatmentStl.oclusion_izquierda
                                            .preview
                                            ? {
                                                position: 'absolute',
                                                bottom: 10,
                                                right: 19,
                                                cursor: 'pointer',
                                              }
                                            : {
                                                position: 'absolute',
                                                bottom: 15,
                                                right: 12,
                                                cursor: 'pointer',
                                              }
                                        }
                                      >
                                        {filesTreatmentStl.oclusion_izquierda &&
                                        filesTreatmentStl.oclusion_izquierda
                                          .preview ? (
                                          <ButtonFile
                                            onClick={() => {
                                              inputRefOclIzq.current.click();
                                            }}
                                            icon={'edit'}
                                            size={25}
                                          />
                                        ) : (
                                          <ButtonFile
                                            onClick={() => {
                                              inputRefOclIzq.current.click();
                                            }}
                                            icon={'plus'}
                                            size={25}
                                          />
                                        )}
                                        <input
                                          type="file"
                                          ref={inputRefOclIzq}
                                          accept=".stl"
                                          onChange={onChangeFileTreatmentSTL}
                                          name="oclusion_izquierda"
                                          id="oclusion_izquierda"
                                          style={{ display: 'none' }}
                                        />
                                      </label>
                                    )}
                                </label>
                              </div>
                              {filesTreatmentStl.oclusion_izquierda &&
                                filesTreatmentStl.oclusion_izquierda.file && (
                                  <LabelNormal
                                    style={{
                                      width: 258,
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                    color="#828282"
                                    fontSize="17"
                                  >
                                    {
                                      filesTreatmentStl.oclusion_izquierda.file
                                        .name
                                    }
                                  </LabelNormal>
                                )}
                              <LabelNormal color="#3C4549" fontSize="17">
                                Oclusión izquierda
                              </LabelNormal>
                            </div>
                          )}
                        </InlineContainer>
                        <div style={{ marginTop: 20 }}>
                          <LabelNormal fontSize="17" color="#757575">
                            ¿Tienes más de un archivo stl en oclusión?
                          </LabelNormal>
                          <InlineContainer style={{ marginTop: 10 }}>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="oclusion-stl-1"
                                onClick={(e) => setOclusionStl('no')}
                                checked={
                                  oclusionStl === 'no' ? 'checked' : false
                                }
                                name="oclusion-stl"
                                type="radio"
                              />
                              <label
                                htmlFor="oclusion-stl-1"
                                className="radio-label"
                              >
                                No, solo tengo uno
                              </label>
                            </div>
                            <div className="radio">
                              <input
                                id="oclusion-stl-2"
                                onClick={(e) => setOclusionStl('si')}
                                checked={
                                  oclusionStl === 'si' ? 'checked' : false
                                }
                                name="oclusion-stl"
                                type="radio"
                              />
                              <label
                                htmlFor="oclusion-stl-2"
                                className="radio-label"
                              >
                                Si (lado izquierdo y derecho)
                              </label>
                            </div>
                          </InlineContainer>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      {radioStl === 'archivos' && (
                        <>
                          <ContainerShowMore style={{ marginTop: 31 }}>
                            <HeaderShowMore
                              style={{ cursor: 'pointer' }}
                              onClick={() => setMore(!more)}
                            >
                              <LabelNormal color="#4F4F4F" fontSize="17">
                                Recomendaciones
                              </LabelNormal>
                              <FontAwesomeIcon
                                icon={more ? faChevronUp : faChevronDown}
                              />
                            </HeaderShowMore>
                            {more && (
                              <BodyShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    Si notas imperfecciones en el STL, confirma
                                    que coincidan en el paciente o sus
                                    fotografías
                                  </LabelNormal>
                                </ItemShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    Procura que los STL’s cuenten con suficiente
                                    encía y espacio retromolar
                                  </LabelNormal>
                                </ItemShowMore>
                                <ItemShowMore>
                                  <SeparatorItem />
                                  <LabelNormal color="#828282" fontSize="18">
                                    El escaneo intraoral tiene mejor definición
                                    que el escaneo
                                    <br /> de impresiones o modelos de yeso, si
                                    tienes la opción, siempre envía STL’s
                                  </LabelNormal>
                                </ItemShowMore>
                              </BodyShowMore>
                            )}
                          </ContainerShowMore>
                        </>
                      )}
                      {radioStl !== '' && (
                        <div
                          style={{
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                          }}
                        >
                          {/* { (sendLinkStl || sendModelStl) && <ButtonAction label="Elegir otro método" onClick={() => { setSendLinkStl(false); setSendModelStl(false); }}/> } */}
                          <ButtonPrime
                            label="Siguiente"
                            disabled={
                              (radioStl === 'archivos' &&
                                oclusionStl === 'no' &&
                                filesTreatmentStl.arcada_superior &&
                                filesTreatmentStl.arcada_inferior &&
                                filesTreatmentStl.oclusion_derecha) ||
                              (radioStl === 'archivos' &&
                                oclusionStl === 'si' &&
                                filesTreatmentStl.arcada_superior &&
                                filesTreatmentStl.arcada_inferior &&
                                filesTreatmentStl.oclusion_derecha &&
                                filesTreatmentStl.oclusion_izquierda) ||
                              (radioStl === 'url' && link_data.length >= 1) ||
                              radioStl === 'model'
                                ? false
                                : true
                            }
                            onClick={() => {
                              setSubStep(0);
                              setSteps(5);
                              if (radioStl === 'url' && link_data.length >= 1)
                                addSTLURL({
                                  variables: {
                                    patient_id: patientId,
                                    url_data: link_data,
                                  },
                                });
                              if (radioStl === 'model')
                                setShipping({
                                  variables: { patient_id: patientId },
                                });
                              if (radioStl === 'archivos') {
                                setSubStep(0);
                                setSteps(5);
                                deleteExtraStl();
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </ScrollableBody>
                )}
                {treatment === 'cryst_aligner' && steps === 5 && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>Radiografías</Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Sube los archivos para tu tratamiento. Puedes
                          arrastrar tu archivo hasta
                        </LabelNormal>
                        <InlineContainer>
                          <LabelNormal color="#757575" fontSize="17">
                            su casilla correspondiente o hacer click en el botón
                            de{' '}
                          </LabelNormal>
                          <img
                            src={require('@images/icons/upload-circle.png')}
                            style={{ height: 23, width: 23 }}
                          />
                        </InlineContainer>
                      </div>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ marginBottom: 30 }}
                      >
                        *Solo archivos en formato jpg para
                        radiografías/tomografías y en zip para tomografías
                      </LabelNormal>
                    </div>
                    <div className="col-md-12">
                      <InlineContainer style={{ alignItems: 'flex-start' }}>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="lateral"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('lateral');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="lateral"
                              onDrop={onChangeFileTreatmentRadiografia}
                            >
                              {loading && compLoading === 'lateral' && (
                                <Loader />
                              )}
                              {dropIn === 'lateral' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentRadiografia.lateral &&
                                    filesTreatmentRadiografia.lateral.preview
                                      ? filesTreatmentRadiografia.lateral
                                          .preview
                                      : require('@images/radiografia/lateral.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 5,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'lateral') &&
                                dropIn !== 'lateral' && (
                                  <label
                                    htmlFor="lateral"
                                    style={
                                      filesTreatmentRadiografia.lateral &&
                                      filesTreatmentRadiografia.lateral.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentRadiografia.lateral &&
                                    filesTreatmentRadiografia.lateral
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLatRad.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefLatRad.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefLatRad}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentRadiografia
                                      }
                                      name="lateral"
                                      id="lateral"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentRadiografia.lateral &&
                            filesTreatmentRadiografia.lateral.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatmentRadiografia.lateral.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Lateral
                          </LabelNormal>
                        </div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="panoramica"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('panoramica');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="panoramica"
                              onDrop={onChangeFileTreatmentRadiografia}
                            >
                              {loading && compLoading === 'panoramica' && (
                                <Loader />
                              )}
                              {dropIn === 'panoramica' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentRadiografia.panoramica &&
                                    filesTreatmentRadiografia.panoramica.preview
                                      ? filesTreatmentRadiografia.panoramica
                                          .preview
                                      : require('@images/radiografia/panoramica.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 5,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'panoramica') &&
                                dropIn !== 'panoramica' && (
                                  <label
                                    htmlFor="panoramica"
                                    style={
                                      filesTreatmentRadiografia.panoramica &&
                                      filesTreatmentRadiografia.panoramica
                                        .preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentRadiografia.panoramica &&
                                    filesTreatmentRadiografia.panoramica
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefPanRad.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefPanRad.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefPanRad}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentRadiografia
                                      }
                                      name="panoramica"
                                      id="panoramica"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentRadiografia.panoramica &&
                            filesTreatmentRadiografia.panoramica.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatmentRadiografia.panoramica.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Panorámica
                          </LabelNormal>
                        </div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="trazado_cefalometrico"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('trazado_cefalometrico');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="trazado_cefalometrico"
                              onDrop={onChangeFileTreatmentRadiografia}
                            >
                              {loading &&
                                compLoading === 'trazado_cefalometrico' && (
                                  <Loader />
                                )}
                              {dropIn === 'trazado_cefalometrico' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentRadiografia.trazado_cefalometrico &&
                                    filesTreatmentRadiografia
                                      .trazado_cefalometrico.preview
                                      ? filesTreatmentRadiografia
                                          .trazado_cefalometrico.preview
                                      : require('@images/radiografia/trazado_cefalometrico.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 5,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'trazado_cefalometrico') &&
                                dropIn !== 'trazado_cefalometrico' && (
                                  <label
                                    htmlFor="trazado_cefalometrico"
                                    style={
                                      filesTreatmentRadiografia.trazado_cefalometrico &&
                                      filesTreatmentRadiografia
                                        .trazado_cefalometrico.preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentRadiografia.trazado_cefalometrico &&
                                    filesTreatmentRadiografia
                                      .trazado_cefalometrico.preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefTrazado.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefTrazado.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefTrazado}
                                      accept=".png, .jpeg, .jpg"
                                      onChange={
                                        onChangeFileTreatmentRadiografia
                                      }
                                      name="trazado_cefalometrico"
                                      id="trazado_cefalometrico"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentRadiografia.trazado_cefalometrico &&
                            filesTreatmentRadiografia.trazado_cefalometrico
                              .file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {
                                  filesTreatmentRadiografia
                                    .trazado_cefalometrico.file.name
                                }
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Trazado cefalométrico
                          </LabelNormal>
                          <LabelNormal color="#757575" fontSize="14">
                            *Opcional
                          </LabelNormal>
                        </div>
                      </InlineContainer>
                      <InlineContainer style={{ alignItems: 'center' }}>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <label
                              htmlFor="tomografia"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setDropIn('tomografia');
                                onDragOver();
                              }}
                              onDragLeave={() => {
                                setDropIn(null);
                                onDragLeave();
                              }}
                              name="tomografia"
                              onDrop={onChangeFileTreatmentRadiografia}
                            >
                              {loading && compLoading === 'tomografia' && (
                                <Loader />
                              )}
                              {dropIn === 'tomografia' ? (
                                <img
                                  src={DragBack}
                                  style={{
                                    height: 167,
                                    width: 258,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    filesTreatmentRadiografia.tomografia &&
                                    filesTreatmentRadiografia.tomografia.preview
                                      ? require('@images/files/zip.jpg')
                                      : require('@images/radiografia/tomografia.jpg')
                                  }
                                  style={{
                                    height: 167,
                                    width: 258,
                                    borderRadius: 5,
                                    backgroundPosition: 'cover',
                                  }}
                                />
                              )}
                              {(loading === false ||
                                compLoading !== 'tomografia') &&
                                dropIn !== 'tomografia' && (
                                  <label
                                    htmlFor="tomografia"
                                    style={
                                      filesTreatmentRadiografia.tomografia &&
                                      filesTreatmentRadiografia.tomografia
                                        .preview
                                        ? {
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 19,
                                            cursor: 'pointer',
                                          }
                                        : {
                                            position: 'absolute',
                                            bottom: 15,
                                            right: 12,
                                            cursor: 'pointer',
                                          }
                                    }
                                  >
                                    {filesTreatmentRadiografia.tomografia &&
                                    filesTreatmentRadiografia.tomografia
                                      .preview ? (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefTomograf.current.click();
                                        }}
                                        icon={'edit'}
                                        size={25}
                                      />
                                    ) : (
                                      <ButtonFile
                                        onClick={() => {
                                          inputRefTomograf.current.click();
                                        }}
                                        icon={'plus'}
                                        size={25}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={inputRefTomograf}
                                      accept=".zip"
                                      onChange={
                                        onChangeFileTreatmentRadiografia
                                      }
                                      name="tomografia"
                                      id="tomografia"
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                )}
                            </label>
                          </div>
                          {filesTreatmentRadiografia.tomografia &&
                            filesTreatmentRadiografia.tomografia.file && (
                              <LabelNormal
                                style={{
                                  width: 258,
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                color="#828282"
                                fontSize="17"
                              >
                                {filesTreatmentRadiografia.tomografia.file.name}
                              </LabelNormal>
                            )}
                          <LabelNormal color="#3C4549" fontSize="17">
                            Tomografía
                          </LabelNormal>
                          <LabelNormal color="#757575" fontSize="14">
                            *Opcional
                          </LabelNormal>
                        </div>
                      </InlineContainer>
                      <ButtonPrime
                        label="Siguiente"
                        disabled={
                          filesTreatmentRadiografia.lateral &&
                          filesTreatmentRadiografia.panoramica
                            ? false
                            : true
                        }
                        onClick={() => {
                          setSubStep(0);
                          setSteps(6);
                        }}
                      ></ButtonPrime>
                    </div>
                  </ScrollableBody>
                )}
                {((steps === 6 && treatment === 'cryst_aligner') ||
                  (steps === 5 && treatment === 'smile_system')) && (
                  <ScrollableBody>
                    <div className="col-md-12">
                      <Title style={{ marginBottom: 5 }}>Resumen</Title>
                      <div style={{ marginBottom: 30 }}>
                        <LabelNormal color="#757575" fontSize="17">
                          Confirma que los datos de registro sean correctos para
                          evitar retrasos en tu
                        </LabelNormal>
                        <LabelNormal color="#757575" fontSize="17">
                          tratamiento. Puedes regresar y editar cualquiera de
                          los pasos.
                        </LabelNormal>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InlineContainer style={{ marginBottom: 42 }}>
                        <div style={{ marginRight: 20 }}>
                          <Avatar
                            src={photo ? photo : loadPhotoProfileGender(gender)}
                            height="161"
                            width="161"
                            borderRadius="30"
                          />
                        </div>
                        <div>
                          <InlineContainer>
                            <div style={{ marginRight: 73 }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="22"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                              <InlineContainer
                                style={{
                                  alignItems: 'center',
                                  marginBottom: 21,
                                }}
                              >
                                <LabelNormal color="#828282" fontSize="13">
                                  Tratamiento
                                </LabelNormal>
                                <LabelNormal
                                  color={
                                    treatment === 'cryst_aligner'
                                      ? '#18A1DC'
                                      : '#F0BB00'
                                  }
                                  fontSize="20"
                                >
                                  {treatment === 'cryst_aligner'
                                    ? 'Cryst Aligner'
                                    : 'Smile System'}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div>
                              <ButtonFile
                                onClick={() => setSteps(1)}
                                icon={'edit'}
                                size={25}
                              />
                            </div>
                          </InlineContainer>
                          <div className="row">
                            <div
                              className="col-md-4"
                              style={{ marginBottom: 12 }}
                            >
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={require('@images/icons/user.png')}
                                  style={{ height: 16, width: 15.5 }}
                                />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {gender}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div
                              className="col-md-7"
                              style={{ marginBottom: 12 }}
                            >
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={require('@images/icons/phone.png')}
                                  style={{ height: 16, width: 15.5 }}
                                />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {phone}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div className="col-md-4">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={require('@images/icons/grift.png')}
                                  style={{ height: 16, width: 15.5 }}
                                />
                                <LabelNormal
                                  fontSize="14"
                                  color="#3C4549"
                                >{`${day}/${month}/${year}`}</LabelNormal>
                              </InlineContainer>
                            </div>
                            {email && (
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <img
                                    src={require('@images/icons/email.png')}
                                    style={{ height: 16, width: 15.5 }}
                                  />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {email}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            )}
                          </div>
                        </div>
                      </InlineContainer>

                      <div
                        className="row col-md-4"
                        style={{ marginBottom: 30 }}
                      >
                        <LabelInput style={{ marginBottom: 10 }}>
                          Selecciona un profesor asignado para el caso
                        </LabelInput>
                        <Select
                          options={allTeachers}
                          onSelect={(e) => {
                            setTeacherSelect(e);
                            setSaved(false);
                          }}
                          value={teacherSelect}
                          placeholder="profesor"
                        />
                      </div>

                      {treatment === 'cryst_aligner' && (
                        <ButtonPrime
                          onClick={() => setSteps(7)}
                          label="Proceder al pago"
                          disabled={!teacherSelect}
                        ></ButtonPrime>
                      )}
                      {treatment === 'smile_system' && (
                        <ButtonPrime
                          onClick={() => setSteps(6)}
                          label="Proceder al pago"
                          disabled={!teacherSelect}
                        ></ButtonPrime>
                      )}

                      <div
                        className="col-md-12"
                        style={{ marginTop: 50, marginRight: 20 }}
                      >
                        <InlineContainer
                          style={{
                            background: '#F6F6F8',
                            borderRadius: 40,
                            paddingTop: 30,
                            paddingLeft: 20,
                            paddingBottom: 30,
                            alignItems: 'self-start',
                          }}
                        >
                          <div style={{ marginRight: 40 }}>
                            {treatment === 'cryst_aligner' &&
                              [
                                'Formulario',
                                'Intraorales',
                                'Extraorales',
                                "STL's",
                                'Radiografias',
                              ].map((row, index) => (
                                <div
                                  style={{
                                    maxWidth: 165,
                                    maxHeight: 55,
                                    background:
                                      selectItem === row
                                        ? '#FFF'
                                        : 'transparent',
                                    borderBottomColor:
                                      selectItem === row
                                        ? 'transparent'
                                        : '#D4D4D4',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    padding: 15,
                                    borderRadius: selectItem !== row ? 0 : 10,
                                  }}
                                  key={Math.random()}
                                >
                                  <LabelNormal
                                    color="#000000"
                                    fontSize="18.5"
                                    onClick={() => setSelectItem(row)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {row}
                                  </LabelNormal>
                                </div>
                              ))}
                            {treatment === 'smile_system' &&
                              [
                                'Formulario',
                                'Intraorales',
                                'Extraorales',
                                "STL's",
                              ].map((row, index) => (
                                <div
                                  style={{
                                    maxWidth: 165,
                                    maxHeight: 55,
                                    background:
                                      selectItem === row
                                        ? '#FFF'
                                        : 'transparent',
                                    borderBottomColor:
                                      selectItem === row
                                        ? 'transparent'
                                        : '#D4D4D4',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    padding: 15,
                                    borderRadius: selectItem !== row ? 0 : 10,
                                  }}
                                  key={Math.random()}
                                >
                                  <LabelNormal
                                    color="#000000"
                                    fontSize="18.5"
                                    onClick={() => setSelectItem(row)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {row}
                                  </LabelNormal>
                                </div>
                              ))}
                          </div>
                          <div>
                            {selectItem === 'Formulario' && (
                              <div className="row">
                                <div className="col-md-12">
                                  {filePatient.name && (
                                    <InlineContainer
                                      style={{
                                        marginBottom: 30,
                                        alignSelf: 'flex-start',
                                      }}
                                    >
                                      <InlineContainer
                                        style={{
                                          padding: 10,
                                          background: '#F6F6F8',
                                          marginTop: 10,
                                          borderRadius: 5,
                                        }}
                                      >
                                        {stateFile ? (
                                          <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        ) : (
                                          <img
                                            src={require('@images/files/pdf-file.jpg')}
                                            style={{ height: 20, width: 16 }}
                                          />
                                        )}
                                        <LabelNormal
                                          fontSize="17"
                                          color="#757575"
                                        >
                                          {filePatient.name}
                                        </LabelNormal>
                                      </InlineContainer>
                                      <div>
                                        {filePatient.name && !stateFile && (
                                          <LabelUnderline
                                            color="#757575"
                                            fontSize="17"
                                            onClick={() => setSteps(2)}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              marginLeft: 30,
                                            }}
                                            label={'Cambiar archivo'}
                                          />
                                        )}
                                      </div>
                                    </InlineContainer>
                                  )}
                                </div>
                              </div>
                            )}
                            {selectItem === 'Radiografias' &&
                              treatment === 'cryst_aligner' && (
                                <div className="row">
                                  <ButtonFile
                                    onClick={() => setSteps(5)}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div className="col-md-7">
                                    <InlineContainer>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            src={
                                              filesTreatmentRadiografia.lateral &&
                                              filesTreatmentRadiografia.lateral
                                                .preview
                                                ? filesTreatmentRadiografia
                                                    .lateral.preview
                                                : require('@images/radiografia/lateral.jpg')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                              backgroundPosition: 'cover',
                                            }}
                                          />
                                        </div>
                                        {filesTreatmentRadiografia.lateral &&
                                          filesTreatmentRadiografia.lateral
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="15"
                                            >
                                              {
                                                filesTreatmentRadiografia
                                                  .lateral.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="15"
                                        >
                                          Lateral
                                        </LabelNormal>
                                      </div>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            src={
                                              filesTreatmentRadiografia.panoramica &&
                                              filesTreatmentRadiografia
                                                .panoramica.preview
                                                ? filesTreatmentRadiografia
                                                    .panoramica.preview
                                                : require('@images/radiografia/panoramica.jpg')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                              backgroundPosition: 'cover',
                                            }}
                                          />
                                        </div>
                                        {filesTreatmentRadiografia.panoramica &&
                                          filesTreatmentRadiografia.panoramica
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="15"
                                            >
                                              {
                                                filesTreatmentRadiografia
                                                  .panoramica.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="15"
                                        >
                                          Panorámica
                                        </LabelNormal>
                                      </div>
                                      {filesTreatmentRadiografia.trazado_cefalometrico && (
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                            marginRight: 60,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              src={
                                                filesTreatmentRadiografia.trazado_cefalometrico &&
                                                filesTreatmentRadiografia
                                                  .trazado_cefalometrico.preview
                                                  ? filesTreatmentRadiografia
                                                      .trazado_cefalometrico
                                                      .preview
                                                  : require('@images/radiografia/trazado_cefalometrico.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentRadiografia.trazado_cefalometrico &&
                                            filesTreatmentRadiografia
                                              .trazado_cefalometrico.file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="15"
                                              >
                                                {
                                                  filesTreatmentRadiografia
                                                    .trazado_cefalometrico.file
                                                    .name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="15"
                                          >
                                            Trazado cefalométrico
                                          </LabelNormal>
                                        </div>
                                      )}
                                    </InlineContainer>
                                    {filesTreatmentRadiografia.tomografia && (
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              src={
                                                filesTreatmentRadiografia.tomografia &&
                                                filesTreatmentRadiografia
                                                  .tomografia.preview
                                                  ? filesTreatmentRadiografia
                                                      .tomografia.preview
                                                  : require('@images/radiografia/tomografia.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentRadiografia.tomografia &&
                                            filesTreatmentRadiografia.tomografia
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="15"
                                              >
                                                {
                                                  filesTreatmentRadiografia
                                                    .tomografia.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="15"
                                          >
                                            Tomografía
                                          </LabelNormal>
                                        </div>
                                      </InlineContainer>
                                    )}
                                  </div>
                                </div>
                              )}
                            {selectItem === "STL's" && (
                              <div className="row">
                                <ButtonFile
                                  onClick={() => setSteps(4)}
                                  icon={'edit'}
                                  size={25}
                                  style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 35,
                                  }}
                                />
                                <div className="col-md-7">
                                  {radioStl === 'model' && (
                                    <div>
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="17"
                                      >
                                        Se seleccionó envío de modelos por
                                        paquetería a la siguiente dirección:
                                      </LabelNormal>
                                      <InlineContainer>
                                        <CardModel style={{ maxWidth: 356 }}>
                                          <LabelNormal
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              marginRight: 7,
                                              marginBottom: 10,
                                            }}
                                            color="#757575"
                                            fontSize="18"
                                          >
                                            Dirección de envío | Borgatta
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#828282"
                                            style={{ margin: 0, padding: 0 }}
                                            fontSize="17"
                                          >
                                            {defaultAddress.id
                                              ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code} ${defaultAddress.state}`
                                              : ''}
                                          </LabelNormal>
                                        </CardModel>
                                      </InlineContainer>
                                    </div>
                                  )}
                                  {radioStl === 'url' && (
                                    <div>
                                      {link_data.map((item, key) => (
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="20"
                                        >
                                          {item}
                                        </LabelNormal>
                                      ))}
                                      {/* <img src={require('@images/icons/upload-edit-circle.png')} onClick={() => setSteps(4)} style={{height: 48, width: 48, alignSelf: 'flex-start', marginLeft: 30, cursor: 'pointer'}} /> */}
                                    </div>
                                  )}
                                  {radioStl === 'archivos' && (
                                    <>
                                      <InlineContainer>
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              src={
                                                filesTreatmentStl.arcada_superior &&
                                                filesTreatmentStl
                                                  .arcada_superior.preview
                                                  ? filesTreatmentStl
                                                      .arcada_superior.preview
                                                  : require('@images/stl/arcada-superior.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentStl.arcada_superior &&
                                            filesTreatmentStl.arcada_superior
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="14"
                                              >
                                                {
                                                  filesTreatmentStl
                                                    .arcada_superior.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="14"
                                          >
                                            Arcada superior
                                          </LabelNormal>
                                        </div>
                                        <div
                                          style={{
                                            marginRight: 20,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <img
                                              src={
                                                filesTreatmentStl.arcada_inferior &&
                                                filesTreatmentStl
                                                  .arcada_inferior.preview
                                                  ? filesTreatmentStl
                                                      .arcada_inferior.preview
                                                  : require('@images/stl/arcada-inferior.jpg')
                                              }
                                              style={{
                                                height: 104,
                                                width: 162,
                                                borderRadius: 3,
                                                backgroundPosition: 'cover',
                                              }}
                                            />
                                          </div>
                                          {filesTreatmentStl.arcada_inferior &&
                                            filesTreatmentStl.arcada_inferior
                                              .file && (
                                              <LabelNormal
                                                style={{
                                                  width: 162,
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                }}
                                                color="#828282"
                                                fontSize="14"
                                              >
                                                {
                                                  filesTreatmentStl
                                                    .arcada_inferior.file.name
                                                }
                                              </LabelNormal>
                                            )}
                                          <LabelNormal
                                            color="#3C4549"
                                            fontSize="14"
                                          >
                                            Arcada inferior
                                          </LabelNormal>
                                        </div>
                                        {/* <img src={require('@images/icons/upload-edit-circle.png')} onClick={() => setSteps(4)} style={{height: 48, width: 48, alignSelf: 'flex-start', marginLeft: 30, cursor: 'pointer'}} /> */}
                                      </InlineContainer>
                                      <InlineContainer>
                                        {(oclusionStl === 'no' ||
                                          oclusionStl === 'si') && (
                                          <div
                                            style={{
                                              marginRight: 20,
                                              marginBottom: 20,
                                            }}
                                          >
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <img
                                                src={
                                                  filesTreatmentStl.oclusion_derecha &&
                                                  filesTreatmentStl
                                                    .oclusion_derecha.preview
                                                    ? filesTreatmentStl
                                                        .oclusion_derecha
                                                        .preview
                                                    : require('@images/stl/oclusion-derecha.jpg')
                                                }
                                                style={{
                                                  height: 104,
                                                  width: 162,
                                                  borderRadius: 3,
                                                  backgroundPosition: 'cover',
                                                }}
                                              />
                                            </div>
                                            {filesTreatmentStl.oclusion_derecha &&
                                              filesTreatmentStl.oclusion_derecha
                                                .file && (
                                                <LabelNormal
                                                  style={{
                                                    width: 162,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                  }}
                                                  color="#828282"
                                                  fontSize="14"
                                                >
                                                  {
                                                    filesTreatmentStl
                                                      .oclusion_derecha.file
                                                      .name
                                                  }
                                                </LabelNormal>
                                              )}
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="14"
                                            >
                                              Oclusión derecha
                                            </LabelNormal>
                                          </div>
                                        )}
                                        {oclusionStl === 'si' && (
                                          <div
                                            style={{
                                              marginRight: 20,
                                              marginBottom: 20,
                                            }}
                                          >
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <img
                                                src={
                                                  filesTreatmentStl.oclusion_derecha &&
                                                  filesTreatmentStl
                                                    .oclusion_derecha.preview
                                                    ? filesTreatmentStl
                                                        .oclusion_derecha
                                                        .preview
                                                    : require('@images/stl/oclusion-derecha.jpg')
                                                }
                                                style={{
                                                  height: 104,
                                                  width: 162,
                                                  borderRadius: 3,
                                                  backgroundPosition: 'cover',
                                                }}
                                              />
                                            </div>
                                            {filesTreatmentStl.oclusion_derecha &&
                                              filesTreatmentStl.oclusion_derecha
                                                .file && (
                                                <LabelNormal
                                                  style={{
                                                    width: 162,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                  }}
                                                  color="#828282"
                                                  fontSize="14"
                                                >
                                                  {
                                                    filesTreatmentStl
                                                      .oclusion_derecha.file
                                                      .name
                                                  }
                                                </LabelNormal>
                                              )}
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="14"
                                            >
                                              Oclusión derecha
                                            </LabelNormal>
                                          </div>
                                        )}
                                      </InlineContainer>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                            {selectItem === 'Extraorales' && (
                              <div>
                                <div
                                  className="row"
                                  style={{
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ButtonFile
                                    onClick={() => {
                                      setSteps(3);
                                      setSubStep(1);
                                    }}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_serio &&
                                            filesTreatmentExtraorales
                                              .frontal_serio.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_serio.preview
                                              : require('@images/extraorales/frontal_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_serio &&
                                        filesTreatmentExtraorales.frontal_serio
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_serio.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_sonriendo &&
                                            filesTreatmentExtraorales
                                              .frontal_sonriendo.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_sonriendo.preview
                                              : require('@images/extraorales/frontal_sonriendo.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_sonriendo &&
                                        filesTreatmentExtraorales
                                          .frontal_sonriendo.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_sonriendo.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal sonriendo
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.frontal_retractore &&
                                            filesTreatmentExtraorales
                                              .frontal_retractore.preview
                                              ? filesTreatmentExtraorales
                                                  .frontal_retractore.preview
                                              : require('@images/extraorales/frontal_retractores.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.frontal_retractore &&
                                        filesTreatmentExtraorales
                                          .frontal_retractore.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .frontal_retractore.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Frontal con retractores
                                      </LabelNormal>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.lateral_izquierda &&
                                            filesTreatmentExtraorales
                                              .lateral_izquierda.preview
                                              ? filesTreatmentExtraorales
                                                  .lateral_izquierda.preview
                                              : require('@images/extraorales/lateral_izq_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.lateral_izquierda &&
                                        filesTreatmentExtraorales
                                          .lateral_izquierda.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .lateral_izquierda.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Lateral izquierda serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentExtraorales.lateral_derecha &&
                                            filesTreatmentExtraorales
                                              .lateral_derecha.preview
                                              ? filesTreatmentExtraorales
                                                  .lateral_derecha.preview
                                              : require('@images/extraorales/lateral_der_serio.jpg')
                                          }
                                          style={{
                                            height: 105,
                                            width: 105,
                                            borderRadius: 4,
                                            backgroundPosition: 'cover',
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentExtraorales.lateral_derecha &&
                                        filesTreatmentExtraorales
                                          .lateral_derecha.file && (
                                          <LabelNormal
                                            style={{
                                              width: 105,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentExtraorales
                                                .lateral_derecha.file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Lateral derecha serio
                                      </LabelNormal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectItem === 'Intraorales' && (
                              <div>
                                <InlineContainer>
                                  <ButtonFile
                                    onClick={() => setSteps(3)}
                                    icon={'edit'}
                                    size={25}
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      right: 35,
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment.superior &&
                                          filesTreatment.superior.preview
                                            ? filesTreatment.superior.preview
                                            : require('@images/intraorales/superior.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.superior &&
                                      filesTreatment.superior.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.superior.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      width: 162,
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment.inferior &&
                                          filesTreatment.inferior.preview
                                            ? filesTreatment.inferior.preview
                                            : require('@images/intraorales/inferior.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.inferior &&
                                      filesTreatment.inferior.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.inferior.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                                <InlineContainer>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment.lateral_izquierda &&
                                          filesTreatment.lateral_izquierda
                                            .preview
                                            ? filesTreatment.lateral_izquierda
                                                .preview
                                            : require('@images/intraorales/lateral-izquierda.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.lateral_izquierda &&
                                      filesTreatment.lateral_izquierda.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment.lateral_izquierda
                                              .file.name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment.frontal &&
                                          filesTreatment.frontal.preview
                                            ? filesTreatment.frontal.preview
                                            : require('@images/intraorales/frontal.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.frontal &&
                                      filesTreatment.frontal.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment.frontal.file.name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment.lateral_derecha &&
                                          filesTreatment.lateral_derecha.preview
                                            ? filesTreatment.lateral_derecha
                                                .preview
                                            : require('@images/intraorales/lateral-derecha.jpg')
                                        }
                                        style={{
                                          height: 105,
                                          width: 162,
                                          backgroundPosition: 'cover',
                                        }}
                                      />
                                    </div>
                                    {filesTreatment.lateral_derecha &&
                                      filesTreatment.lateral_derecha.file && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment.lateral_derecha.file
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Oclusal superior
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </div>
                        </InlineContainer>
                      </div>
                    </div>
                  </ScrollableBody>
                )}
                {((steps === 7 && treatment === 'cryst_aligner') ||
                  (steps === 6 && treatment === 'smile_system')) && (
                  <ScrollableBody
                    style={{ width: '100%', overflowX: 'hidden' }}
                  >
                    <div className="row">
                      <div className="col-md-7">
                        {treatment === 'cryst_aligner' ? (
                          <>
                            <Title style={{ marginBottom: 5 }}>
                              Pago de planificación
                            </Title>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <LabelNormal color="#757575" fontSize="17">
                                Confirma tus datos de pago.
                              </LabelNormal>
                            </div>
                          </>
                        ) : (
                          <>
                            <Title style={{ marginBottom: 5 }}>
                              Pago de Smile System
                            </Title>
                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <LabelNormal color="#757575" fontSize="17">
                                Confirma tus datos de pago y aprueba tu caso
                              </LabelNormal>
                            </div>
                          </>
                        )}
                        <InlineContainer style={{ marginBottom: 10 }}>
                          <LabelNormal color="#436FF1" fontSize="15">
                            PX
                          </LabelNormal>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="22"
                          >{`${firstName} ${lastName}`}</LabelNormal>
                        </InlineContainer>
                        <InlineContainer style={{ marginBottom: 18 }}>
                          <ChipCategory>
                            <LabelNormal color="#060809" fontSize="13">
                              {treatment === 'cryst_aligner'
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </ChipCategory>
                        </InlineContainer>
                        {treatment === 'cryst_aligner' ? (
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            El pago de planificación incluye la revisión de{' '}
                            <br /> archivos, el visor para compartir con tu{' '}
                            <br /> paciente y su revisión. La manufactura de{' '}
                            <br /> tus alineadores se cotizará más adelante.
                          </LabelNormal>
                        ) : (
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            El pago del tratamiento incluye la revisión de
                            <br />
                            archivos, el Smartcheck, la manufactura y envío
                            <br />
                            de tu{' '}
                            {typePrice.pack && typePrice.pack.name
                              ? typePrice.pack.name
                              : ''}
                          </LabelNormal>
                        )}
                        <Checkbox
                          checked={invoice}
                          icon={
                            <FontAwesomeIcon
                              icon={faCheck}
                              color="#EB9542"
                              size="sm"
                            />
                          }
                          borderColor="#C8C8C8"
                          style={{ overflow: 'hidden' }}
                          size={20}
                          onChange={(value) => setInvoice(value)}
                          labelStyle={{
                            marginLeft: 5,
                            userSelect: 'none',
                            fontFamily: 'neue-montreal-regular',
                            color: '#3C4549',
                          }}
                          label="Facturar pago"
                        />
                        {invoice && (
                          <div style={{ marginTop: 29 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Información de facturación
                            </LabelNormal>
                            {defaultTaxData.business_name && (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 204,
                                  maxWidth: 334,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  padding: 16,
                                }}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.business_name
                                    ? defaultTaxData.business_name
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.rfc ? defaultTaxData.rfc : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >
                                  {defaultTaxData.street
                                    ? `${defaultTaxData.street} ${defaultTaxData.number}, ${defaultTaxData.suburb}, ${defaultTaxData.postal_code} ${defaultTaxData.state}`
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="16"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultTaxData.cfdiUsage
                                    ? defaultTaxData.cfdiUsage.name
                                    : ''}
                                </LabelNormal>
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  label={'Editar'}
                                  onClick={() => editTaxDataModal()}
                                />
                              </CardInlineContainer>
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              onClick={() =>
                                defaultTaxData.business_name
                                  ? setChangePayment(true)
                                  : addTaxDataModal()
                              }
                              label={
                                defaultTaxData.business_name
                                  ? 'Cambiar perfil fiscal'
                                  : 'Añadir perfil fiscal'
                              }
                            />
                          </div>
                        )}
                        {treatment === 'smile_system' && (
                          <div style={{ marginTop: 20 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Información de envío
                            </LabelNormal>
                            {defaultAddressDoctor.name && (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 105,
                                  maxWidth: 334,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  padding: 16,
                                }}
                              >
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="18"
                                  style={{ marginBottom: 10 }}
                                >
                                  {defaultAddressDoctor.name
                                    ? defaultAddressDoctor.name
                                    : ''}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="16"
                                  style={{ marginBottom: 10, width: 302 }}
                                >
                                  {defaultAddressDoctor.street
                                    ? `${defaultAddressDoctor.street} ${defaultAddressDoctor.number}, ${defaultAddressDoctor.suburb}, ${defaultAddressDoctor.postal_code} ${defaultAddressDoctor.state}`
                                    : ''}
                                </LabelNormal>
                              </CardInlineContainer>
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              onClick={() =>
                                defaultAddressDoctor.name
                                  ? setChangeAddress(true)
                                  : addDefaultAddressDoctorModal()
                              }
                              label={
                                defaultAddressDoctor.name
                                  ? 'Cambiar dirección de envío'
                                  : 'Añadir dirección de envío'
                              }
                            />
                          </div>
                        )}
                        <div style={{ marginTop: 46 }}>
                          <LabelNormal color="#3C4549" fontSize="22">
                            Método de pago
                          </LabelNormal>
                          <LabelNormal color="#757575" fontSize="17">
                            Selecciona un método de pago
                          </LabelNormal>
                          <div style={{ marginTop: 15 }}>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="payment-1"
                                  name="payment"
                                  onClick={() => {
                                    setPaymentMethod('payment_line');
                                    setSelectInstallment({});
                                    checkTransferIntent();
                                  }}
                                  checked={
                                    paymentMethod === 'payment_line'
                                      ? 'checked'
                                      : false
                                  }
                                  type="radio"
                                />
                                <label for="payment-1" className="radio-label">
                                  Pago con tarjeta
                                </label>
                              </div>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="payment-2"
                                  name="payment"
                                  onClick={() => {
                                    setPaymentMethod('payment_transfer');
                                    setTotal(typePrice.amount);
                                  }}
                                  checked={
                                    paymentMethod === 'payment_transfer'
                                      ? 'checked'
                                      : false
                                  }
                                  type="radio"
                                />
                                <label for="payment-2" className="radio-label">
                                  Pago por transferencia
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        {paymentMethod == 'payment_line' && (
                          <div
                            style={{
                              marginTop: 29,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 5,
                            }}
                          >
                            <LabelNormal color="#3C4549" fontSize="22">
                              Cobrado a
                            </LabelNormal>
                            {defaultPayment.brand && (
                              <CardInlineContainer
                                style={{
                                  borderRadius: 10,
                                  minHeight: 71,
                                  maxWidth: 376,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  padding: 16,
                                  minWidth: 338,
                                }}
                                key={Math.random()}
                              >
                                <InlineContainer>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: 38,
                                      width: 50,
                                      background: '#FAFAFB',
                                    }}
                                  >
                                    <img
                                      src={
                                        defaultPayment.brand === 'mastercard'
                                          ? require('@images/icon-cc/mastercard-transparent.png')
                                          : defaultPayment.brand === 'visa'
                                          ? require('@images/icon-cc/visa-transparent.png')
                                          : defaultPayment.brand === 'amex'
                                          ? require('@images/icon-cc/amex.jpg')
                                          : null
                                      }
                                      style={{ height: 'auto', width: 40 }}
                                    />
                                  </div>
                                  <LabelNormal color="#3C4549" fontSize="18">
                                    *
                                    {defaultPayment.last_digits
                                      ? defaultPayment.last_digits
                                      : ''}
                                  </LabelNormal>
                                </InlineContainer>
                              </CardInlineContainer>
                            )}
                            {paymentInstallments.installments && (
                              <>
                                <CardInlineContainer
                                  style={{
                                    minHeight: 71,
                                    maxWidth: 376,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    padding: 16,
                                    minWidth: 338,
                                    borderTopRightRadius: 10,
                                    borderTopLeftRadius: 10,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  key={Math.random()}
                                >
                                  <InlineContainer>
                                    <div
                                      className="radio"
                                      style={{ marginRight: 8 }}
                                    >
                                      <input
                                        id="installments-0"
                                        name="installments"
                                        onClick={() => {
                                          setSelectInstallment({});
                                          setTotal(typePrice.amount);
                                        }}
                                        checked={
                                          Object.keys(selectInstallment)
                                            .length !== 0
                                            ? false
                                            : 'checked'
                                        }
                                        type="radio"
                                      />
                                      <label
                                        htmlFor="installments-0"
                                        className="radio-label"
                                      ></label>
                                    </div>
                                    <LabelNormal
                                      color="#3C4549"
                                      fontSize="17"
                                    >{`1x ${typePrice.amount} ${typePrice.currency}`}</LabelNormal>
                                  </InlineContainer>
                                </CardInlineContainer>
                                {paymentInstallments.installments.map(
                                  (item, index) => {
                                    if (item.count < 9) {
                                      return (
                                        <CardInlineContainer
                                          style={{
                                            minHeight: 71,
                                            maxWidth: 376,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            padding: 16,
                                            minWidth: 338,
                                            borderTopRightRadius:
                                              index + 1 === 0 ? 10 : 0,
                                            borderTopLeftRadius:
                                              index + 1 === 0 ? 10 : 0,
                                            borderBottomRightRadius:
                                              index + 1 ===
                                              paymentInstallments.installments
                                                .length -
                                                1
                                                ? 10
                                                : 0,
                                            borderBottomLeftRadius:
                                              index + 1 ===
                                              paymentInstallments.installments
                                                .length -
                                                1
                                                ? 10
                                                : 0,
                                          }}
                                          key={Math.random()}
                                        >
                                          <InlineContainer>
                                            <div
                                              className="radio"
                                              style={{ marginRight: 8 }}
                                            >
                                              <input
                                                id={`installments-${index + 1}`}
                                                name="installments"
                                                onClick={() => {
                                                  setSelectInstallment({
                                                    count: item.count,
                                                    interval: item.interval,
                                                    type: item.type,
                                                    installment_price:
                                                      item.price,
                                                  });
                                                  setTotal(
                                                    parseFloat(
                                                      item.price
                                                        .replace('$', '')
                                                        .replace(
                                                          /[USD||MXN]/g,
                                                          ''
                                                        )
                                                    )
                                                  );
                                                }}
                                                checked={
                                                  selectInstallment.count !==
                                                  item.count
                                                    ? false
                                                    : 'checked'
                                                }
                                                type="radio"
                                              />
                                              <label
                                                htmlFor={`installments-${
                                                  index + 1
                                                }`}
                                                className="radio-label"
                                              ></label>
                                            </div>
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="17"
                                            >{`${item.description}`}</LabelNormal>
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="17"
                                              style={{
                                                paddingLeft: 10,
                                                color: '#757575',
                                              }}
                                            >{`${item.price}`}</LabelNormal>
                                          </InlineContainer>
                                        </CardInlineContainer>
                                      );
                                    }
                                  }
                                )}
                              </>
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              onClick={() =>
                                defaultPayment.brand
                                  ? setChangeCard(true)
                                  : addCardModal()
                              }
                              label={
                                defaultPayment.brand
                                  ? 'Cambiar método de pago'
                                  : 'Añadir método de pago'
                              }
                            />
                          </div>
                        )}
                        {paymentMethod == 'payment_transfer' && (
                          <div className="row">
                            <div
                              className="col-md-7"
                              style={{ marginTop: 25, marginBottom: 100 }}
                            >
                              <LabelNormal color="#3C4549" fontSize="22">
                                Datos para transferencia
                              </LabelNormal>
                              {!loadingTransfer && (
                                <>
                                  {!transferSend && (
                                    <LabelNormal color="#757575" fontSize="19">
                                      Da clic en Pagar por transferencia para
                                      ver los datos bancarios
                                    </LabelNormal>
                                  )}
                                  {transferSend && !transferError && (
                                    <InlineContainer key={Math.random()}>
                                      <CardInlineContainerTrans
                                        style={{
                                          borderRadius: 10,
                                          minHeight: 204,
                                          minWidth: 320,
                                          maxWidth: 334,
                                          marginTop: 10,
                                          marginBottom: 10,
                                          padding: 16,
                                          marginLeft: 10,
                                        }}
                                      >
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="18"
                                          style={{ marginBottom: 10 }}
                                        >
                                          Datos bancarios
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#757575"
                                          fontSize="16"
                                          style={{ marginBottom: 10 }}
                                        >
                                          Convenio: {bankCode}
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#757575"
                                          fontSize="16"
                                          style={{ marginBottom: 10 }}
                                        >
                                          Banco: {bankName}
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="16"
                                          style={{ marginBottom: 10 }}
                                        >
                                          CLABE: {clabe}
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="16"
                                          style={{ marginBottom: 10 }}
                                        >
                                          Nombre:{' '}
                                          {treatment === 'cryst_aligner'
                                            ? 'Maquiladora y Distribuidora Dental S.A. de C.V.'
                                            : 'Dental Trade S.A. de C.V.'}
                                        </LabelNormal>
                                      </CardInlineContainerTrans>
                                    </InlineContainer>
                                  )}
                                </>
                              )}
                              {loadingTransfer && (
                                <div style={{ marginTop: 25 }}>
                                  <Inline>
                                    <BorderLoader className="text-center">
                                      <div class="loader-inner line-spin-fade-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    </BorderLoader>
                                  </Inline>
                                  <div
                                    className="text-center"
                                    style={{ marginTop: 20 }}
                                  >
                                    <LabelNormal
                                      color="#4F4F4F"
                                      fontSize="17"
                                      style={{ marginBottom: 14 }}
                                    >
                                      Cargando datos bancarios
                                    </LabelNormal>
                                  </div>
                                </div>
                              )}
                              {!transferError && (
                                <div
                                  style={{
                                    marginTop: 45,
                                    background: '#F9EECA',
                                    borderRadius: 13,
                                    padding: '15px 18px',
                                  }}
                                >
                                  <InlineContainer style={{ marginBottom: 11 }}>
                                    <img
                                      src={require('@images/icons/warning-dark.png')}
                                      style={{ height: 29, width: 29 }}
                                    />
                                    <LabelNormal fontSize="22" color="#6B4D00">
                                      En espera de pago
                                    </LabelNormal>
                                  </InlineContainer>
                                  <LabelNormal color="#6B4D00" fontSize="17">
                                    Guardaremos la información de tu caso y
                                    podrás acceder a ella desde Tus casos en el
                                    menú principal. El caso quedará activo una
                                    vez veamos reflejado tu pago, el proceso
                                    puede tomar algunas horas.
                                  </LabelNormal>
                                </div>
                              )}
                              {transferError && (
                                <div
                                  style={{
                                    marginTop: 45,
                                    borderRadius: 13,
                                    padding: '15px 18px',
                                  }}
                                >
                                  <InlineContainer style={{ marginBottom: 11 }}>
                                    <img
                                      src={require('@images/icons/warning-dark.png')}
                                      style={{
                                        height: 29,
                                        width: 29,
                                        marginRight: 10,
                                        marginBottom: 50,
                                      }}
                                    />
                                    <LabelNormal color="#6B4D00" fontSize="17">
                                      Lo sentimos, hubo un problema al a cargar
                                      los datos de pago por transferencia,
                                      intenta con Pago con tarjeta o inténtalo
                                      más tarde.
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              )}
                              <br />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        {treatment === 'smile_system' && (
                          <div>
                            <LabelNormal color="#757575" fontSize="17">
                              Tu pago incluye:
                            </LabelNormal>
                            {packType.name ===
                              'Fit Jig + Brackets Black X-pression' && (
                              <img
                                src={require('@images/payment-include/x-pression.png')}
                                style={{ height: 130, width: 142 }}
                              />
                            )}
                            {packType.name ===
                              'Fit Jig + Brackets X-pression C' && (
                              <img
                                src={require('@images/payment-include/x-pression-C.png')}
                                style={{ height: 130, width: 142 }}
                              />
                            )}
                            {packType.name === 'Fit Jig + Brackets Nova C' && (
                              <img
                                src={require('@images/payment-include/nova-ceramico.png')}
                                style={{ height: 130, width: 142 }}
                              />
                            )}
                            {packType.name ===
                              'Fit Jig + Brackets Nova metálico' && (
                              <img
                                src={require('@images/payment-include/Nova-metalico.png')}
                                style={{ height: 130, width: 142 }}
                              />
                            )}
                            <img
                              src={require('@images/payment-include/fitJig.png')}
                              style={{ height: 130, width: 142 }}
                            />
                          </div>
                        )}
                        {treatment === 'cryst_aligner' && (
                          <div>
                            <LabelNormal color="#757575" fontSize="17">
                              Tu pago incluye:
                            </LabelNormal>
                            <img
                              src={require('@images/payment-include/planificacion.png')}
                              style={{ height: 130, width: 142 }}
                            />
                          </div>
                        )}
                        <div style={{ marginTop: 40, marginBottom: 25 }}>
                          <LabelNormal color="#757575" fontSize="22">
                            Total
                          </LabelNormal>
                          <InlineContainer>
                            <LabelNormal color="#3C4549" fontSize="37">
                              $ {total}
                            </LabelNormal>
                            <LabelNormal fontSize="24" color="#3C4549">
                              {typePrice.currency ? typePrice.currency : ''}
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        {((paymentMethod !== 'payment_transfer' &&
                          treatment !== 'cryst_aligner' &&
                          doctorData.addresses &&
                          doctorData.addresses.length > 0 &&
                          doctorData.cards &&
                          doctorData.cards.length > 0 &&
                          invoice &&
                          defaultTaxData.business_name) ||
                          (paymentMethod !== 'payment_transfer' &&
                            treatment !== 'cryst_aligner' &&
                            doctorData.addresses &&
                            doctorData.addresses.length > 0 &&
                            doctorData.cards &&
                            doctorData.cards.length > 0 &&
                            !invoice) ||
                          (paymentMethod !== 'payment_transfer' &&
                            treatment === 'cryst_aligner' &&
                            doctorData.cards &&
                            doctorData.cards.length > 0 &&
                            filesTreatmentRadiografia.lateral &&
                            filesTreatmentRadiografia.panoramica &&
                            invoice &&
                            defaultTaxData.business_name) ||
                          (paymentMethod !== 'payment_transfer' &&
                            treatment === 'cryst_aligner' &&
                            doctorData.cards &&
                            doctorData.cards.length > 0 &&
                            filesTreatmentRadiografia.lateral &&
                            filesTreatmentRadiografia.panoramica &&
                            !invoice)) && (
                          <ButtonPrime
                            onClick={() => submitPayTreatment()}
                            label="Pagar con tarjeta"
                          ></ButtonPrime>
                        )}
                        {((paymentMethod === 'payment_transfer' &&
                          treatment !== 'cryst_aligner' &&
                          doctorData.addresses &&
                          doctorData.addresses.length > 0 &&
                          invoice &&
                          defaultTaxData.business_name) ||
                          (paymentMethod === 'payment_transfer' &&
                            treatment !== 'cryst_aligner' &&
                            doctorData.addresses &&
                            doctorData.addresses.length > 0 &&
                            !invoice) ||
                          (paymentMethod === 'payment_transfer' &&
                            treatment === 'cryst_aligner' &&
                            filesTreatmentRadiografia.lateral &&
                            filesTreatmentRadiografia.panoramica &&
                            invoice &&
                            defaultTaxData.business_name) ||
                          (paymentMethod === 'payment_transfer' &&
                            treatment === 'cryst_aligner' &&
                            filesTreatmentRadiografia.lateral &&
                            filesTreatmentRadiografia.panoramica &&
                            !invoice)) && (
                          <>
                            <ButtonPrime
                              disabled={transferSend ? true : false}
                              onClick={() => submitPayTreatment()}
                              label="Obtener datos bancarios"
                            ></ButtonPrime>
                          </>
                        )}
                      </div>
                    </div>
                  </ScrollableBody>
                )}
              </div>
            </div>
          )}
          {changeCard && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <Title style={{ marginBottom: 5 }}>Datos de pago</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta tarjeta cargaremos el monto del tratamiento
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Tarjetas guardadas
                  </LabelNormal>
                  {doctorData.cards && doctorData.cards.length > 0 && (
                    <>
                      {doctorData.cards.map((card, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`card-payment-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={card.default ? 'checked' : false}
                              type="radio"
                            />
                            <label
                              htmlFor={`card-payment-${index}`}
                              onClick={() =>
                                changeDefaultCard({
                                  variables: {
                                    user_id: localStorage.getItem('b360-id'),
                                    payment_method_ca: card.service_id_ca,
                                    payment_method_ss: card.service_id_ss,
                                  },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 71,
                              maxWidth: 376,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                            }}
                          >
                            <InlineContainer>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: 38,
                                  width: 50,
                                  background: '#FAFAFB',
                                }}
                              >
                                <img
                                  src={
                                    card.brand === 'mastercard'
                                      ? require('@images/icon-cc/mastercard-transparent.png')
                                      : card.brand === 'visa'
                                      ? require('@images/icon-cc/visa-transparent.png')
                                      : card.brand === 'amex'
                                      ? require('@images/icon-cc/amex-transparent.png')
                                      : null
                                  }
                                  style={{ height: 'auto', width: 40 }}
                                />
                              </div>
                              <LabelNormal color="#757575" fontSize="17">
                                {card.brand
                                  ? card.brand.charAt(0).toUpperCase() +
                                    card.brand.slice(1)
                                  : ''}
                              </LabelNormal>
                              <LabelNormal color="#3C4549" fontSize="18">
                                ****{card.last_digits ? card.last_digits : ''}
                              </LabelNormal>
                            </InlineContainer>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addCardModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir tarjeta'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeCard(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
          {changePayment && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <Title style={{ marginBottom: 5 }}>
                    Información de facturación
                  </Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      Con estos datos fiscales facturamos el pago de tus
                      tratamientos, si usas perfiles diferentes puedes
                      agregarlos todos aquí.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Datos fiscales guardados
                  </LabelNormal>
                  {doctorData.taxdatas && doctorData.taxdatas.length > 0 && (
                    <>
                      {doctorData.taxdatas.map((taxdata, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`taxdata-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                taxdata.id === defaultTaxData.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor={`taxdata-${index}`}
                              onClick={() =>
                                changeDefaultTaxdata({
                                  variables: { tax_data_id: taxdata.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 204,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.business_name
                                ? taxdata.business_name
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.rfc ? taxdata.rfc : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {taxdata.street
                                ? `${taxdata.street} ${taxdata.number}, ${taxdata.suburb}, ${taxdata.postal_code} ${taxdata.state}`
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#3C4549"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.cfdiUsage ? taxdata.cfdiUsage.name : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addTaxDataModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir perfil fiscal'}
                  />
                  <ButtonPrime
                    onClick={() => setChangePayment(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
          {changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7" style={{ marginBottom: 100 }}>
                  <Title style={{ marginBottom: 5 }}>Dirección de Envío</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta dirección enviaremos tu tratamiento.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Direcciones guardados
                  </LabelNormal>
                  {doctorData.addresses && doctorData.addresses.length > 0 && (
                    <>
                      {doctorData.addresses.map((address, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`address-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                address.id === defaultAddressDoctor.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                            />
                            <label
                              htmlFor={`address-${index}`}
                              onClick={() =>
                                changeDefaultAddressDoctor({
                                  variables: { address_id: address.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 105,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {address.name ? address.name : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {address.street
                                ? `${address.street} ${address.number}, ${address.suburb}, ${address.postal_code} ${address.state}`
                                : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addDefaultAddressDoctorModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir dirección de envío'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeAddress(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
        </ContainContent>
      </ContainHome>
      <Prompt
        when={!saved && steps < 7}
        message="¿Seguro que deseas salir? Hay cambios sin guardar"
      />
    </div>
  );
};

// Styled Components

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  width: 100%;
  background-color: #fbfbfc;
  position: sticky;
  top: 17px;
  z-index: 100;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const ScrollableBody = styled.div`
  // overflow-y: auto;
  // max-height: 79vh;
  width: 100%;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const CardInlineContainerTrans = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  display: inline-block;
  margin: 0px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
`;

const ChipCategory = styled.div`
  border: 1.15px solid #060809;
  border-radius: 51.37px;
  padding: 5px 13px 6px 13px;
  box-sizing: border-box;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const CardModelExtended = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 500px;
  min-height: 143px;
  margin-right: 50px;
`;

const SeparatorItem = styled.div`
  width: 10.5px !important;
  height: 5.5px;
  border-radius: 20px;
  margin-top: 14px;
  border: 1.5px solid #dd9139;
`;

const ItemShowMore = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyShowMore = styled.div`
  margin: 15px 6px;
  padding-left: 10px;
`;

const HeaderShowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 22.5px;
`;

const ContainerShowMore = styled.div`
  min-height: 43px;
  max-width: 518px;
  border-radius: 25px;
  background: #fff;
`;

const CustomHref = styled.a`
  color: '#18A1DC';
  textdecoration: 'none';
  &:visited {
    color: inherit;
  }
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;

const mapStateToProps = (state) => ({
  role_id: state.auth.role_id,
});

export default connect(mapStateToProps, null)(NewCases);
