import React from 'react';
import styled from 'styled-components';

const ContainerLoader = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
`;

const Loader = () =>  <ContainerLoader><div className="bt-spinner" /></ContainerLoader>;

export default Loader;