/* eslint-disable no-unused-vars */
/* TODO: remove this eslint disable on typescript migration. */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMutation,
  LazyQueryExecFunction,
  OperationVariables,
} from '@apollo/client';

import {
  MUTATION_END_CONVERSATION,
  MUTATION_CASE_READY,
  MUTATION_UNDO_END_CONVERSATION,
} from '../../graphql/mutation';
import { modal } from '@components/ModalManagement';
import {
  Column,
  CardContainer,
  Divider,
  HeaderCardWithDivision,
  LabelNormal,
} from '../shared/Styled';
import BarsLeft from '../Icons/BarsLeft';
import { FilesApprovedModal } from './modals/FilesApproved';
import { AgreedChangesModal } from './modals/AgreedChanges';
import { FilesAcceptedModal } from './modals/FilesAccepted';
import { ReadyToApproveModal } from './modals/ReadyToApprove';
import { SendAsApproveModal } from './modals/SendAsApproved';
import { FileReviewAction } from './actions/FileReview';
import { TreatmentViewerAction } from './actions/TreatmentViewer';
import { fileStatusEnum, treatmentStatusEnum } from './consts';

/** Panel of chat actions.
 *
 * @typedef {"new_case" | "planning"  | "printing"  | "thermoforming"
 * | "warehouse"  | "packing"  | "shipping"  | "finished"} StatusType
 * @typedef {{
 * areaStatus?: Array<any>,
 * getDetails: LazyQueryExecFunction<any, OperationVariables>,
 * hadPlanningComments: boolean,
 * patientId: number,
 * status?: {id: string, index: number, name: string, type: StatusType},
 * treatmentId: string,
 * treatmentType: "Cryst Aligner" | "Smile System" | null,
 * }} ActionsProps
 * @param {ActionsProps} props
 * @returns {JSX.Element}
 */
const Actions = ({
  areaStatus,
  getDetails,
  hadPlanningComments,
  patientId,
  status,
  treatmentId,
  treatmentType,
}) => {
  const history = useHistory();
  const [filesSectionStatus, setFilesSectionStatus] = useState({
    status: 'La acción no está disponible',
    priority: 0,
  });
  const statusId = Number(status?.id ?? 0);
  const fileStatusId = filesSectionStatus.priority;
  const [hadErrorsReported, setHadErrorsReported] = useState(false);

  useEffect(() => {
    const statusFiles = areaStatus
      ?.filter((s) => s.area === 'files')
      .sort((a, b) => a.priority - b.priority);

    if (statusFiles.length > 0) {
      setFilesSectionStatus(statusFiles[0]);
    }

    if (
      statusFiles.some((sf) => sf.priority === fileStatusEnum.errorsReported)
    ) {
      setHadErrorsReported(true);
    }
  }, [areaStatus]);

  const [endChat] = useMutation(MUTATION_END_CONVERSATION, {
    onCompleted: (data) => {
      getDetails({ variables: { id: parseInt(treatmentId) } });
      openModalOnEndChat(data.endConversationBorgatta.status);
    },
  });

  const [undoEndChat, { loadingUndo }] = useMutation(
    MUTATION_UNDO_END_CONVERSATION,
    {
      onCompleted: (_) => {
        getDetails({ variables: { id: parseInt(treatmentId) } });
      },
    }
  );

  const [planningSendReady] = useMutation(MUTATION_CASE_READY, {
    onCompleted: (_) => openModalOnPlanningSendReady(),
  });

  /**
   * @param {boolean} goBack
   */
  const onClickCloseModal = (goBack) => {
    if (goBack) {
      modal.close(() => history.goBack());
    } else {
      modal.close();
      getDetails({ variables: { id: parseInt(treatmentId) } });
    }
  };

  /** Open a success modal depending on multiple status cases.
   *
   * @param {string} status endConversationBorgatta status.
   */
  const openModalOnEndChat = (status) => {
    /* TODO: this status value could be misspelled. */
    if (status === 'approve_plannig') {
      return modal.open(
        <FilesApprovedModal onClickClose={onClickCloseModal} />
      );
    } else if (
      [
        'error_form_files',
        'error_files_models',
        'error_models',
        'error_files',
      ].includes(status)
    ) {
      return modal.open(
        <AgreedChangesModal onClickClose={onClickCloseModal} />
      );
    } else if (status === 'error_form') {
      return modal.open(
        <FilesAcceptedModal onClickClose={onClickCloseModal} />
      );
    }
  };

  /** Open a confirm modal by on click "planning" action.
   */
  const openModalOnPlanningSendReady = () => {
    if (
      status.type === 'planning' &&
      statusId === treatmentStatusEnum.readyPlanning
    ) {
      return modal.open(
        <SendAsApproveModal onClickClose={onClickCloseModal} />
      );
    } else {
      return modal.open(
        <ReadyToApproveModal onClickClose={onClickCloseModal} />
      );
    }
  };

  const onClickAgreeChanges = () => {
    endChat({ variables: { patient_id: patientId } });
  };

  const onClickSendPlanning = () => {
    planningSendReady({ variables: { patient_id: patientId } });
  };

  const onClickUndo = () => {
    if (!loadingUndo) {
      undoEndChat({ variables: { patient_id: patientId } });
    }
  };

  return (
    <>
      <HeaderCardWithDivision
        minWidth="100%"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '14px',
          paddingLeft: '21px',
          paddingTop: '5px',
          paddingBottom: '5px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <BarsLeft />
        <LabelNormal
          fontSize="20"
          color="#3C4549"
          fontFamily="neue-montreal-medium"
        >
          Acciones
        </LabelNormal>
      </HeaderCardWithDivision>
      <CardContainer style={{ height: '92%' }}>
        <Column gap="10px">
          <LabelNormal
            fontSize="17"
            color="#3C4549"
            fontFamily="neue-montreal-medium"
          >
            Revisión de archivos
          </LabelNormal>
          <FileReviewAction
            fileStatusId={fileStatusId}
            hadErrorsReported={hadErrorsReported}
            loadingUndo={loadingUndo}
            onClickAgreeChanges={onClickAgreeChanges}
            onClickUndo={onClickUndo}
            statusId={statusId}
          />
        </Column>
        <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        {treatmentType === 'Cryst Aligner' && (
          <Column gap="10px">
            <LabelNormal
              fontSize="17"
              color="#3C4549"
              fontFamily="neue-montreal-medium"
            >
              Visor de tratamiento
            </LabelNormal>
            <TreatmentViewerAction
              hadPlanningComments={hadPlanningComments}
              loadingUndo={loadingUndo}
              onClickSendPlanning={onClickSendPlanning}
              onClickUndo={onClickUndo}
              statusId={statusId}
            />
          </Column>
        )}
      </CardContainer>
    </>
  );
};

export default Actions;
