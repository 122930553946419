import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonPrime from '@components/shared/ButtonPrime';
import Select from '@components/shared/Select';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  MUTATION_SELECT_TREATMENT_BRACKETS,
  MUTATION_FORM_ODONTOGRAMA,
  MUTATION_FORM_ADDITIONAL,
  MUTATION_FORM_BRACKETS,
} from '@graphql/mutation';
import { QUERY_GET_PACK } from '@graphql/query';
import { useLazyQuery, useMutation } from '@apollo/client';
import Map from './map';
import { tops, bottoms } from './icons';

import Diagrama from '@images/diagrama.svg';
import DiagramaSupeior from '@images/diagrama-superior.svg';

import RealoadIcon from '@images/reaload-icon.svg';

import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';

const odontograma = [
  { name: 'Diente ausente', icon: DienteAusente, id: 'diente_ausente' },
  { name: 'Diente a extraer', icon: DienteExtraer, id: 'diente_a_extraer' },
];

const FormSS = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [selectOdontograma, setSelectOdontograma] = useState('diente_ausente');
  const [arcadUp, setArcadUp] = useState([
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
  ]);
  const [arcadLo, setArcadLo] = useState([
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
  ]);
  const [typeOdontograma, setTypeOdontograma] = useState(
    'Marcar consideraciones especiales'
  );

  const [positionBrackets, setPositionBrackets] = useState(
    'Personalizar posición de brackets'
  );
  const [bracketsUp, setBracketsUp] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);
  const [bracketsLo, setBracketsLo] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);

  const [additionalInfo, setAdditionalInfo] = useState('');

  const [types, setTypes] = useState([]);
  const [packType, setPackType] = useState(null);
  const [odontogramComments, setOdontogramComments] = useState('');
  const [needComments, setNeedComments] = useState(false);
  const [validateComments, setValidateComments] = useState(true);
  const [validateArcad, setValidateArcad] = useState(true);
  const [validateBrackets, setValidateBrackets] = useState(true);
  const [validatePack, setValidatePack] = useState(true);

  const [selectBrackets] = useMutation(MUTATION_SELECT_TREATMENT_BRACKETS);

  const [fromBrackets] = useMutation(MUTATION_FORM_BRACKETS);

  const [formOdontograma] = useMutation(MUTATION_FORM_ODONTOGRAMA);

  const [formAdditional] = useMutation(MUTATION_FORM_ADDITIONAL);

  const [getPack] = useLazyQuery(QUERY_GET_PACK, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTypes(data.getPacksByType);
      if (props.formSSData.bracket_ss) {
        const ss = props.formSSData;
        const findedPack = data.getPacksByType.find(
          (pk) => pk.name === ss.bracket_ss
        );
        props.setPackTypeBack({
          id: findedPack.id,
          name: findedPack.name,
        });
      }
    },
  });

  useEffect(() => {
    getPack({ variables: { type: 'manufact_ss' } });
  }, []);

  useEffect(() => {
    setDisabled(validationForm());
  }, [
    packType,
    typeOdontograma,
    arcadUp,
    arcadLo,
    bracketsLo,
    bracketsUp,
    positionBrackets,
  ]);

  const validationForm = () => {
    return !(
      positionBrackets === 'Personalizar posición de brackets' ||
      positionBrackets !== 'Personalizar posición de brackets'
    );
  };

  const onSubmitSS = () => {
    try {
      selectBrackets({
        variables: { patient_id: props.patient_id, brackets: packType.name },
      });
      formOdontograma({
        variables: {
          treatment_id: props.treatment_id,
          type: typeOdontograma,
          up_left_third_molar_data: arcadUp[0],
          up_left_second_molar_data: arcadUp[1],
          up_left_first_molar_data: arcadUp[2],
          up_left_second_premolar_data: arcadUp[3],
          up_left_first_premolar_data: arcadUp[4],
          up_left_canine_data: arcadUp[5],
          up_left_lateral_incisor_data: arcadUp[6],
          up_left_central_incisor_data: arcadUp[7],
          up_right_third_molar_data: arcadUp[8],
          up_right_second_molar_data: arcadUp[9],
          up_right_first_molar_data: arcadUp[10],
          up_right_second_premolar_data: arcadUp[11],
          up_right_first_premolar_data: arcadUp[12],
          up_right_canine_data: arcadUp[13],
          up_right_lateral_incisor_data: arcadUp[14],
          up_right_central_incisor_data: arcadUp[15],
          lo_left_third_molar_data: arcadLo[0],
          lo_left_second_molar_data: arcadLo[1],
          lo_left_first_molar_data: arcadLo[2],
          lo_left_second_premolar_data: arcadLo[3],
          lo_left_first_premolar_data: arcadLo[4],
          lo_left_canine_data: arcadLo[5],
          lo_left_lateral_incisor_data: arcadLo[6],
          lo_left_central_incisor_data: arcadLo[7],
          lo_right_third_molar_data: arcadLo[8],
          lo_right_second_molar_data: arcadLo[9],
          lo_right_first_molar_data: arcadLo[10],
          lo_right_second_premolar_data: arcadLo[11],
          lo_right_first_premolar_data: arcadLo[12],
          lo_right_canine_data: arcadLo[13],
          lo_right_lateral_incisor_data: arcadLo[14],
          lo_right_central_incisor_data: arcadLo[15],
          comments: odontogramComments,
        },
      });
      fromBrackets({
        variables: {
          treatment_id: props.treatment_id,
          type: positionBrackets,
          up_positions_data: bracketsUp,
          lo_positions_data: bracketsLo,
        },
      });
      formAdditional({
        variables: {
          patient_id: props.patient_id,
          additional_instructions: additionalInfo,
        },
      });

      props.setSteps(3);
    } catch (e) {
      console.log(e);
    }
  };

  const validateOnSubmitSS = () => {
    let input = null;

    if (packType != null) {
      setValidatePack(true);
    } else {
      input = document.getElementById(`radio-brackets`);
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      setValidatePack(false);
      return;
    }
    if (
      (typeOdontograma !== 'No tengo consideraciones' &&
        (arcadUp.find((v) => v[0] !== '') ||
          arcadLo.find((v) => v[0] !== ''))) ||
      typeOdontograma === 'No tengo consideraciones'
    ) {
      setValidateArcad(true);
    } else {
      input = document.getElementById(`block-odontograma`);
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      setValidateArcad(false);
      return;
    }
    if (
      (positionBrackets !== 'Posicionar todos los brackets en punto FA' &&
        !(
          bracketsUp.every((v) => v === '0,0') &&
          bracketsLo.every((v) => v === '0,0')
        )) ||
      positionBrackets === 'Posicionar todos los brackets en punto FA'
    ) {
      setValidateBrackets(true);
    } else {
      input = document.getElementById(`block-position-brackets`);
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      setValidateBrackets(false);
      return;
    }
    !(odontogramComments === '' && needComments)
      ? setValidateComments(true)
      : setValidateComments(false);

    if (
      ((typeOdontograma !== 'No tengo consideraciones' &&
        (arcadUp.find((v) => v[0] !== '') ||
          arcadLo.find((v) => v[0] !== ''))) ||
        typeOdontograma === 'No tengo consideraciones') &&
      ((positionBrackets !== 'Posicionar todos los brackets en punto FA' &&
        !(
          bracketsUp.every((v) => v === '0,0') &&
          bracketsLo.every((v) => v === '0,0')
        )) ||
        positionBrackets === 'Posicionar todos los brackets en punto FA') &&
      !(odontogramComments === '' && needComments) &&
      packType != null
    ) {
      onSubmitSS();
    }
  };

  useEffect(() => {
    if (props.formSSData.bracket_ss) {
      const ss = props.formSSData;

      setPackType({ id: ss.bracket_ss, name: ss.bracket_ss });

      if (ss.odontograma) {
        setTypeOdontograma(ss.odontograma.type);
        setNeedComments(ss.odontograma?.comments ? true : false);
        setOdontogramComments(ss.odontograma?.comments);
      }
      if (
        ss.odontograma &&
        ss.odontograma.up_left_third_molar_data !== null &&
        ss.odontograma.lo_left_third_molar_data !== null
      ) {
        setArcadUp([
          JSON.parse(ss.odontograma.up_left_third_molar_data),
          JSON.parse(ss.odontograma.up_left_second_molar_data),
          JSON.parse(ss.odontograma.up_left_first_molar_data),
          JSON.parse(ss.odontograma.up_left_second_premolar_data),
          JSON.parse(ss.odontograma.up_left_first_premolar_data),
          JSON.parse(ss.odontograma.up_left_canine_data),
          JSON.parse(ss.odontograma.up_left_lateral_incisor_data),
          JSON.parse(ss.odontograma.up_left_central_incisor_data),
          JSON.parse(ss.odontograma.up_right_third_molar_data),
          JSON.parse(ss.odontograma.up_right_second_molar_data),
          JSON.parse(ss.odontograma.up_right_first_molar_data),
          JSON.parse(ss.odontograma.up_right_second_premolar_data),
          JSON.parse(ss.odontograma.up_right_first_premolar_data),
          JSON.parse(ss.odontograma.up_right_canine_data),
          JSON.parse(ss.odontograma.up_right_lateral_incisor_data),
          JSON.parse(ss.odontograma.up_right_central_incisor_data),
        ]);
        setArcadLo([
          JSON.parse(ss.odontograma.lo_left_third_molar_data),
          JSON.parse(ss.odontograma.lo_left_second_molar_data),
          JSON.parse(ss.odontograma.lo_left_first_molar_data),
          JSON.parse(ss.odontograma.lo_left_second_premolar_data),
          JSON.parse(ss.odontograma.lo_left_first_premolar_data),
          JSON.parse(ss.odontograma.lo_left_canine_data),
          JSON.parse(ss.odontograma.lo_left_lateral_incisor_data),
          JSON.parse(ss.odontograma.lo_left_central_incisor_data),
          JSON.parse(ss.odontograma.lo_right_third_molar_data),
          JSON.parse(ss.odontograma.lo_right_second_molar_data),
          JSON.parse(ss.odontograma.lo_right_first_molar_data),
          JSON.parse(ss.odontograma.lo_right_second_premolar_data),
          JSON.parse(ss.odontograma.lo_right_first_premolar_data),
          JSON.parse(ss.odontograma.lo_right_canine_data),
          JSON.parse(ss.odontograma.lo_right_lateral_incisor_data),
          JSON.parse(ss.odontograma.lo_right_central_incisor_data),
        ]);
      } else {
        setArcadUp([
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ]);
        setArcadLo([
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ]);
      }
      if (ss.brackets && ss.brackets.up_positions_data) {
        setBracketsUp(JSON.parse(ss.brackets.up_positions_data));
        setBracketsLo(JSON.parse(ss.brackets.lo_positions_data));
      }
      if (ss.brackets && ss.brackets.type) {
        setPositionBrackets(ss.brackets.type);
      }
      setAdditionalInfo(ss.additional);
    }
  }, [props.formSSData]);

  return (
    <ScrollableBody>
      <div className='col-md-12'>
        <Title style={{ marginBottom: 15 }}>Formulario de tratamiento</Title>
        <LabelNormal color='#757575' fontSize='17' style={{ marginBottom: 30 }}>
          Selecciona las opciones según tu caso.
        </LabelNormal>
      </div>
      <>
        <div
          className='col-md-12'
          style={{ marginTop: 30 }}
          id='radio-brackets'
        >
          <LabelNormal
            color='#3C4549'
            fontSize='26'
            style={{ marginBottom: 5 }}
          >
            Brackets
          </LabelNormal>
          <InlineContainer style={{ marginBottom: 35 }}>
            {types.map((item) => (
              <div
                className='radio'
                style={{ marginRight: 20 }}
                key={Math.random()}
              >
                <input
                  id={`typespack-${item.id}`}
                  name='typespack'
                  onClick={() => {
                    setPackType(item);
                    props.setPackTypeBack(item);
                  }}
                  defaultChecked={
                    packType !== null && packType.name === item.name
                      ? 'checked'
                      : false
                  }
                  type='radio'
                />
                <label
                  htmlFor={`typespack-${item.id}`}
                  style={{ color: '#3C4549' }}
                  className='radio-label'
                >
                  {item.name.split('Fit Jig + Brackets ')[1]}
                </label>
              </div>
            ))}
          </InlineContainer>
          {!validatePack && (
            <InlineContainer>
              <p
                style={{
                  color: '#C60E02',
                  fontSize: 18,
                  margin: 0,
                  padding: 0,
                  marginTop: 5,
                  fontFamily: 'neue-montreal-regular',
                }}
              >
                *Selecciona una marca de bracket
              </p>
            </InlineContainer>
          )}
        </div>
      </>
      <>
        <div className='col-md-12' style={{ marginTop: 50 }}>
          <LabelNormal
            fontSize='26'
            color='#3C4549'
            style={{ fontWeight: 500, marginBottom: 20 }}
          >
            FitJig
          </LabelNormal>
          <LabelNormal fontSize='17' color='#3C4549'>
            Recibirás tu FitJig con los brackets puestos y listos para cementar.
            Para mayor <br /> comodidad se diseña en tres segmentos de 7 a 4, 3
            a 3 y de 4 a 7.{' '}
          </LabelNormal>
          <InlineContainer style={{ marginTop: 28 }}>
            <img src={Diagrama} style={{ marginRight: 40 }} />
            <img src={DiagramaSupeior} />
          </InlineContainer>
          <LabelNormal fontSize='13' color='#757575' style={{ marginTop: 10 }}>
            *Diagrama explicativo
          </LabelNormal>
        </div>
      </>
      <>
        <div
          className='col-md-12'
          style={{ marginTop: 50 }}
          id='block-odontograma'
        >
          <LabelNormal
            fontSize='26'
            color='#3C4549'
            style={{ fontWeight: 500 }}
          >
            Odontograma
          </LabelNormal>
          <LabelNormal
            fontSize='17'
            color='#757575'
            style={{ fontWeight: 400, marginBottom: 20 }}
          >
            Marcar los dientes que tengan consideraciones especiales o
            selecciona
            <br /> No tengo consideraciones.
          </LabelNormal>
          <InlineContainer>
            <div className='radio' style={{ width: 310 }}>
              <input
                id={`marcar-consideraciones-especiales`}
                name='odontograma'
                onClick={() =>
                  setTypeOdontograma('Marcar consideraciones especiales')
                }
                checked={
                  typeOdontograma === 'Marcar consideraciones especiales'
                    ? 'checked'
                    : false
                }
                type='radio'
              />
              <label
                htmlFor={`marcar-consideraciones-especiales`}
                className='radio-label'
              >
                Marcar consideraciones especiales
              </label>
            </div>
            <div className='radio' style={{ width: 280, marginLeft: 21 }}>
              <input
                id={`no-tengo-consideraciones`}
                name='odontograma'
                onClick={() => setTypeOdontograma('No tengo consideraciones')}
                checked={
                  typeOdontograma === 'No tengo consideraciones'
                    ? 'checked'
                    : false
                }
                type='radio'
              />
              <label
                htmlFor={`no-tengo-consideraciones`}
                className='radio-label'
              >
                No tengo consideraciones
              </label>
            </div>
          </InlineContainer>
          {typeOdontograma === 'No tengo consideraciones' && (
            <LabelNormal fontSize='17' color='#757575'>
              No existen consideraciones especiales para el tratamiento.
            </LabelNormal>
          )}
          {typeOdontograma !== 'No tengo consideraciones' && (
            <>
              <div className='row' style={{ marginTop: 15, marginBottom: 15 }}>
                <div className='col-md-8'>
                  <div
                    className='row'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className='col-md-6'>
                      <LabelNormal fontSize='17' color='#757575'>
                        Selecciona una categoría y haz clic sobre el diente para
                        marcarlo.
                      </LabelNormal>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'right' }}>
                      <Select
                        options={odontograma}
                        onSelect={setSelectOdontograma}
                        value={selectOdontograma}
                        icon
                        maxHeight={'55px'}
                        iconDefault={DienteAusente}
                        style={{
                          textAlign: 'left',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <LabelNormal
                  color='#3C4549'
                  fontSize='17'
                  style={{ marginBottom: 30 }}
                >
                  Arcada superior
                </LabelNormal>
                <div
                  style={{ position: 'absolute', top: -25, display: 'flex' }}
                ></div>
                <div className='row'>
                  <div
                    className='col-md-10'
                    style={{ position: 'relative', maxWidth: 1080 }}
                  >
                    <InlineContainer
                      style={{
                        height: 60,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                      }}
                    >
                      {Array.from(Array(8).keys()).map((_, index) => (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: 60,
                            }}
                          >
                            {arcadUp.find(
                              (v, i) => i === index && v[0] !== ''
                            ) && (
                              <img
                                src={RealoadIcon}
                                onClick={() =>
                                  setArcadUp(
                                    arcadUp.map((v, i) =>
                                      index === i ? [''] : v
                                    )
                                  )
                                }
                                style={{
                                  position: 'absolute',
                                  top: -20,
                                  paddingRight: 16,
                                  cursor: 'pointer',
                                  marginBottom: 10,
                                }}
                              />
                            )}
                            {selectOdontograma &&
                            !arcadUp.find(
                              (v, i) => i === index && v[0] !== ''
                            ) ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingTop: 35,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) => v.id === selectOdontograma
                                    ).icon
                                  }
                                  onClick={() => {
                                    setArcadUp(
                                      arcadUp.map((v, i) =>
                                        index === i
                                          ? odontograma.find(
                                              (v) => v.id === selectOdontograma
                                            ).name === 'Implante'
                                            ? ['Implante', 'Pieza a excluir']
                                            : [
                                                odontograma.find(
                                                  (v) =>
                                                    v.id === selectOdontograma
                                                ).name,
                                              ]
                                          : v
                                      )
                                    );
                                    setBracketsUp(
                                      bracketsUp.map((v, i) =>
                                        i === index ? '0,0' : v
                                      )
                                    );
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight: 16,
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingTop: 35,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) =>
                                        v.name ===
                                        arcadUp.find((v, i) => i === index)[0]
                                    ).icon
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find((v, i) => i === index)[0]
                                      ).name === 'Implante'
                                        ? 0
                                        : 16,
                                    marginRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find((v, i) => i === index)[0]
                                      ).name === 'Implante'
                                        ? -13
                                        : 0,
                                  }}
                                />
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadUp.find((v, i) => i === index)[0]
                                ).name === 'Implante' && (
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.name === 'Pieza a excluir'
                                      ).icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <div
                              style={{
                                paddingRight: 16,
                                marginBottom: 5,
                                marginTop: 10,
                              }}
                            >
                              <LabelNormal color='#9E9E9E' fontSize='17'>
                                {18 - index}
                              </LabelNormal>
                            </div>
                            <div
                              style={{
                                marginTop: 5,
                                height: 50,
                                width: 'auto',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src={tops[index]}
                                style={{
                                  paddingRight: 16,
                                  width: '100%',
                                  opacity: arcadUp.find(
                                    (v, i) => i === index && v[0] !== ''
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {Array.from(Array(8).keys()).map((_, index) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: 60,
                          }}
                        >
                          {arcadUp.find(
                            (v, i) => i === index + 8 && v[0] !== ''
                          ) && (
                            <img
                              src={RealoadIcon}
                              onClick={() =>
                                setArcadUp(
                                  arcadUp.map((v, i) =>
                                    index + 8 === i ? [''] : v
                                  )
                                )
                              }
                              style={{
                                position: 'absolute',
                                top: -20,
                                paddingRight: 16,
                                cursor: 'pointer',
                              }}
                            />
                          )}
                          {selectOdontograma &&
                          !arcadUp.find(
                            (v, i) => i === index + 8 && v[0] !== ''
                          ) ? (
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 9,
                                paddingTop: 35,
                              }}
                            >
                              <img
                                src={
                                  odontograma.find(
                                    (v) => v.id === selectOdontograma
                                  ).icon
                                }
                                onClick={() => {
                                  setArcadUp(
                                    arcadUp.map((v, i) =>
                                      index + 8 === i
                                        ? odontograma.find(
                                            (v) => v.id === selectOdontograma
                                          ).name === 'Implante'
                                          ? ['Implante', 'Pieza a excluir']
                                          : [
                                              odontograma.find(
                                                (v) =>
                                                  v.id === selectOdontograma
                                              ).name,
                                            ]
                                        : v
                                    )
                                  );
                                  setBracketsUp(
                                    bracketsUp.map((v, i) =>
                                      i === index + 8 ? '0,0' : v
                                    )
                                  );
                                }}
                                style={{ cursor: 'pointer', paddingRight: 16 }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 9,
                                paddingTop: 35,
                              }}
                            >
                              <img
                                src={
                                  odontograma.find(
                                    (v) =>
                                      v.name ===
                                      arcadUp.find((v, i) => i === index + 8)[0]
                                  ).icon
                                }
                                style={{
                                  cursor: 'pointer',
                                  paddingRight:
                                    odontograma.find(
                                      (v) =>
                                        v.name ===
                                        arcadUp.find(
                                          (v, i) => i === index + 8
                                        )[0]
                                    ).name === 'Implante'
                                      ? 0
                                      : 16,
                                  marginRight:
                                    odontograma.find(
                                      (v) =>
                                        v.name ===
                                        arcadUp.find(
                                          (v, i) => i === index + 8
                                        )[0]
                                    ).name === 'Implante'
                                      ? -13
                                      : 0,
                                }}
                              />
                              {odontograma.find(
                                (v) =>
                                  v.name ===
                                  arcadUp.find((v, i) => i === index + 8)[0]
                              ).name === 'Implante' && (
                                <img
                                  src={
                                    odontograma.find(
                                      (v) => v.name === 'Pieza a excluir'
                                    ).icon
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight: 16,
                                  }}
                                />
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              paddingRight: 16,
                              marginBottom: 5,
                              marginTop: 10,
                            }}
                          >
                            <LabelNormal color='#9E9E9E' fontSize='17'>
                              {21 + index}
                            </LabelNormal>
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                              height: 50,
                              width: 'auto',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={tops[Math.abs(index - 7)]}
                              style={{
                                paddingLeft: 16,
                                transform: ' rotateY(180deg)',
                                width: '100%',
                                opacity: arcadUp.find(
                                  (v, i) => i === index + 8 && v[0] !== ''
                                )
                                  ? 0.5
                                  : 1,
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </InlineContainer>
                  </div>
                </div>

                <LabelNormal
                  color='#3C4549'
                  fontSize='17'
                  style={{ marginBottom: 30, marginTop: 30 }}
                >
                  Arcada inferior
                </LabelNormal>
                <div className='row'>
                  <div
                    className='col-md-10'
                    style={{ position: 'relative', maxWidth: 1080 }}
                  >
                    <InlineContainer
                      style={{
                        height: 60,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                      }}
                    >
                      {Array.from(Array(8).keys()).map((_, index) => (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: 60,
                            }}
                          >
                            {arcadLo.find(
                              (v, i) => i === index && v[0] !== ''
                            ) && (
                              <img
                                src={RealoadIcon}
                                onClick={() =>
                                  setArcadLo(
                                    arcadLo.map((v, i) =>
                                      index === i ? [''] : v
                                    )
                                  )
                                }
                                style={{
                                  position: 'absolute',
                                  bottom: -20,
                                  paddingRight: 16,
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                            {selectOdontograma &&
                            !arcadLo.find(
                              (v, i) => i === index && v[0] !== ''
                            ) ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingBottom: 42,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) => v.id === selectOdontograma
                                    ).icon
                                  }
                                  onClick={() => {
                                    setArcadLo(
                                      arcadLo.map((v, i) =>
                                        index === i
                                          ? odontograma.find(
                                              (v) => v.id === selectOdontograma
                                            ).name === 'Implante'
                                            ? ['Implante', 'Pieza a excluir']
                                            : [
                                                odontograma.find(
                                                  (v) =>
                                                    v.id === selectOdontograma
                                                ).name,
                                              ]
                                          : v
                                      )
                                    );
                                    setBracketsLo(
                                      bracketsLo.map((v, i) =>
                                        i === index ? '0,0' : v
                                      )
                                    );
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight: 16,
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingBottom: 42,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) =>
                                        v.name ===
                                        arcadLo.find((v, i) => i === index)[0]
                                    ).icon
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find((v, i) => i === index)[0]
                                      ).name === 'Implante'
                                        ? 0
                                        : 16,
                                    marginRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find((v, i) => i === index)[0]
                                      ).name === 'Implante'
                                        ? -13
                                        : 0,
                                  }}
                                />
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadLo.find((v, i) => i === index)[0]
                                ).name === 'Implante' && (
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.name === 'Pieza a excluir'
                                      ).icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: 5,
                                height: 50,
                                width: 'auto',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src={bottoms[index]}
                                style={{
                                  paddingRight: 16,
                                  width: '100%',
                                  opacity: arcadLo.find(
                                    (v, i) => i === index && v[0] !== ''
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                paddingRight: 16,
                                marginBottom: 15,
                                marginTop: 15,
                              }}
                            >
                              <LabelNormal color='#9E9E9E' fontSize='17'>
                                {48 - index}
                              </LabelNormal>
                            </div>
                          </div>
                        </div>
                      ))}
                      {Array.from(Array(8).keys()).map((_, index) => (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: 60,
                            }}
                          >
                            {arcadLo.find(
                              (v, i) => i === index + 8 && v[0] !== ''
                            ) && (
                              <img
                                src={RealoadIcon}
                                onClick={() =>
                                  setArcadLo(
                                    arcadLo.map((v, i) =>
                                      index + 8 === i ? [''] : v
                                    )
                                  )
                                }
                                style={{
                                  position: 'absolute',
                                  bottom: -20,
                                  paddingRight: 16,
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                            {selectOdontograma &&
                            !arcadLo.find(
                              (v, i) => i === index + 8 && v[0] !== ''
                            ) ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingBottom: 42,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) => v.id === selectOdontograma
                                    ).icon
                                  }
                                  onClick={() => {
                                    setArcadLo(
                                      arcadLo.map((v, i) =>
                                        index + 8 === i
                                          ? odontograma.find(
                                              (v) => v.id === selectOdontograma
                                            ).name === 'Implante'
                                            ? ['Implante', 'Pieza a excluir']
                                            : [
                                                odontograma.find(
                                                  (v) =>
                                                    v.id === selectOdontograma
                                                ).name,
                                              ]
                                          : v
                                      )
                                    );
                                    setBracketsLo(
                                      bracketsLo.map((v, i) =>
                                        i === index + 8 ? '0,0' : v
                                      )
                                    );
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight: 16,
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 9,
                                  paddingBottom: 42,
                                }}
                              >
                                <img
                                  src={
                                    odontograma.find(
                                      (v) =>
                                        v.name ===
                                        arcadLo.find(
                                          (v, i) => i === index + 8
                                        )[0]
                                    ).icon
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index + 8
                                          )[0]
                                      ).name === 'Implante'
                                        ? 0
                                        : 16,
                                    marginRight:
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index + 8
                                          )[0]
                                      ).name === 'Implante'
                                        ? -13
                                        : 0,
                                  }}
                                />
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadLo.find((v, i) => i === index + 8)[0]
                                ).name === 'Implante' && (
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.name === 'Pieza a excluir'
                                      ).icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: 5,
                                height: 50,
                                width: 'auto',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src={bottoms[Math.abs(index - 7)]}
                                style={{
                                  paddingLeft: 16,
                                  transform: ' rotateY(180deg)',
                                  width: '100%',
                                  opacity: arcadLo.find(
                                    (v, i) => i === index + 8 && v[0] !== ''
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                paddingRight: 16,
                                marginBottom: 15,
                                marginTop: 15,
                              }}
                            >
                              <LabelNormal color='#9E9E9E' fontSize='17'>
                                {31 + index}
                              </LabelNormal>
                            </div>
                          </div>
                        </div>
                      ))}
                    </InlineContainer>
                  </div>
                </div>
                <LabelNormal
                  color='#757575'
                  fontSize='17'
                  style={{ marginTop: 20 }}
                >
                  Glosario
                </LabelNormal>
                <div className='row' style={{ marginTop: 10 }}>
                  <div className='col-md-2'>
                    <InlineContainer>
                      <img
                        src={DienteAusente}
                        style={{
                          width: '25px',
                          height: '25px',
                          borderRadius: '48.6765px',
                          background: '#FFFFFF',
                        }}
                      />
                      <LabelNormal
                        color='#3C4549'
                        fontSize='17'
                        style={{ fontWeight: 400 }}
                      >
                        Diente ausente
                      </LabelNormal>
                    </InlineContainer>
                  </div>
                  <div className='col-md-2'>
                    <InlineContainer>
                      <img
                        src={DienteExtraer}
                        style={{
                          width: '25px',
                          height: '25px',
                          borderRadius: '48.6765px',
                          background: '#FFFFFF',
                        }}
                      />
                      <LabelNormal
                        color='#3C4549'
                        fontSize='17'
                        style={{ fontWeight: 400 }}
                      >
                        Diente a extraer
                      </LabelNormal>
                    </InlineContainer>
                  </div>
                </div>
                <InlineContainer style={{ marginTop: 44 }}>
                  <Checkbox
                    className={'hover'}
                    checked={needComments}
                    icon={
                      <FontAwesomeIcon
                        icon={faCheck}
                        color='#EB9542'
                        size='sm'
                      />
                    }
                    borderColor='#C8C8C8'
                    style={{ overflow: 'hidden' }}
                    size={20}
                    onChange={(value) => setNeedComments(value)}
                    labelStyle={{
                      marginLeft: 10,
                      userSelect: 'none',
                      fontFamily: 'neue-montreal-regular',
                      color: '#3C4549',
                      marginRight: 16,
                    }}
                    label={'¿No encuentras la opción que buscas?'}
                  />
                </InlineContainer>
                {!validateArcad && (
                  <InlineContainer>
                    <p
                      style={{
                        color: '#C60E02',
                        fontSize: 18,
                        margin: 0,
                        padding: 0,
                        marginTop: 10,
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Modifica la propiedad de al menos un diente o selecciona
                      la opción de No tengo consideraciones
                    </p>
                  </InlineContainer>
                )}
                {needComments && (
                  <>
                    <LabelNormal
                      color='#757575'
                      fontSize='17'
                      style={{ fontWeight: 400, marginTop: 10 }}
                    >
                      ¿Tienes algún diente temporal y será parte del
                      tratamiento? Especifícalo aquí
                    </LabelNormal>
                    <div className='col-md-12' style={{ marginTop: 15 }}>
                      <div className='row col-md-7'>
                        <TextArea
                          borderColor={
                            validateComments
                              ? 'rgba(189, 189, 189, 0.7)'
                              : '#C60E02'
                          }
                          onChange={(e) =>
                            setOdontogramComments(e.target.value)
                          }
                          value={odontogramComments}
                          placeholder='Si tienes algún comentario que no fue cubierto en el formulario, escríbelo aquí...'
                          rows={9}
                        />
                      </div>
                    </div>
                    {!validateComments && (
                      <InlineContainer>
                        <p
                          style={{
                            color: '#C60E02',
                            fontSize: 18,
                            margin: 0,
                            padding: 0,
                            marginTop: 5,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Escríbenos tus comentarios adicionales a las
                          indicaciones especiales o elimina la selección.
                        </p>
                      </InlineContainer>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </>
      <>
        <div
          className='col-md-12'
          style={{ marginTop: 70 }}
          id='block-position-brackets'
        >
          <LabelNormal
            fontSize='26'
            color='#3C4549'
            style={{ fontWeight: 500, marginBottom: 20 }}
          >
            Posición de los brackets
          </LabelNormal>
          <InlineContainer style={{ marginBottom: 15 }}>
            <div className='radio' style={{ width: 340 }}>
              <input
                id={`posicion-brackets`}
                name='brackets'
                onClick={() => {
                  setPositionBrackets(
                    'Posicionar todos los brackets en punto FA'
                  );
                  setBracketsLo([
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                  ]);
                  setBracketsUp([
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                    '0,0',
                  ]);
                }}
                checked={
                  positionBrackets ===
                  'Posicionar todos los brackets en punto FA'
                    ? 'checked'
                    : false
                }
                type='radio'
              />
              <label htmlFor={`posicion-brackets`} className='radio-label'>
                Posicionar todos los brackets en punto FA
              </label>
            </div>
            <div className='radio' style={{ width: 280, marginLeft: 21 }}>
              <input
                id={`brackets-fa`}
                name='brackets'
                onClick={() =>
                  setPositionBrackets('Personalizar posición de brackets')
                }
                checked={
                  positionBrackets === 'Personalizar posición de brackets'
                    ? 'checked'
                    : false
                }
                type='radio'
              />
              <label htmlFor={`brackets-fa`} className='radio-label'>
                Personalizar posición de brackets
              </label>
            </div>
          </InlineContainer>
          {positionBrackets === 'Personalizar posición de brackets' && (
            <>
              <LabelNormal fontSize='17' color='#757575'>
                Haz clic en el diente que quieras personalizar su posición y
                posiciona el indicador haciendo clic en algún punto de la
                cuadrícula o<br />
                ingresa las coordenadas en los campos superiores. Las unidades
                son en milímetros y parten desde la esquina inferior izquierda.{' '}
                <br />
                Recuerda que la posición se considera sobre la corona clínica
              </LabelNormal>

              <Map
                initialNumerationTopLeft={19}
                initialNumerationTopRight={20}
                initialNumerationBottomRight={30}
                initialNumerationBottomLeft={49}
                width={60}
                height={79.25}
                topData={bracketsUp}
                setTopData={setBracketsUp}
                bottomData={bracketsLo}
                setBottomData={setBracketsLo}
                disabledPointTop={arcadUp}
                disabledPointBottom={arcadLo}
              />
              {!validateBrackets && (
                <InlineContainer>
                  <p
                    style={{
                      color: '#C60E02',
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      marginTop: 5,
                      fontFamily: 'neue-montreal-regular',
                    }}
                  >
                    *Modifica la posición del bracker en al menos un diente o
                    selecciona Posicionar todos los brackets en punto FA
                  </p>
                </InlineContainer>
              )}
            </>
          )}
          {positionBrackets === 'Posicionar todos los brackets en punto FA' && (
            <>
              <LabelNormal fontSize='17' color='#757575'>
                Los brackets se posicionarán al centro del diente según
                corresponda.
              </LabelNormal>

              <Map
                initialNumerationTopLeft={19}
                initialNumerationTopRight={20}
                initialNumerationBottomRight={20}
                initialNumerationBottomLeft={49}
                width={60}
                height={79.25}
                topData={bracketsUp}
                setTopData={setBracketsUp}
                bottomData={bracketsLo}
                setBottomData={setBracketsLo}
                disabledPointTop={arcadUp}
                disabled={true}
                disabledPointBottom={arcadLo}
              />
            </>
          )}
        </div>
      </>
      <>
        <div className='col-md-12' style={{ marginTop: 70 }}>
          <LabelNormal
            fontSize='26'
            color='#3C4549'
            style={{ fontWeight: 500, marginBottom: 20 }}
          >
            Comentarios adicionales{' '}
            <span style={{ color: '#757575' }}>
              (con respecto al tratamiento)
            </span>
          </LabelNormal>
          <div className='row col-md-7'>
            <TextArea
              onChange={(e) => setAdditionalInfo(e.target.value)}
              value={additionalInfo}
              placeholder='Comentarios'
              rows={10}
            />
          </div>
          <div className='row col-md-8' style={{ marginTop: 20 }}>
            <ItemShowMore>
              <SeparatorItem />
              <LabelNormal color='#828282' fontSize='18'>
                Se recomienda el uso de FitJig en coronas completamente
                erupcionadas.
              </LabelNormal>
            </ItemShowMore>
            <ItemShowMore>
              <SeparatorItem />
              <LabelNormal color='#828282' fontSize='18'>
                Es responsabilidad del especialista la biomecánica utilizada
                durante el tratamiento y el resultado del mismo.
              </LabelNormal>
            </ItemShowMore>
          </div>
        </div>
      </>
      <div className='col-md-12' style={{ marginTop: 50 }}>
        <ButtonPrime label='Siguiente' onClick={validateOnSubmitSS} />
      </div>
    </ScrollableBody>
  );
};

// Styled Components

const TextArea = styled.textarea`
  background: ${({ backgroundColor, disabled }) =>
    backgroundColor && !disabled
      ? backgroundColor
      : disabled
      ? 'dark(rgba(239, 239, 239, 0.3))'
      : '#FFF'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-top: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-right: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-bottom: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-left: ${({ mask, borderColor }) =>
    `1px solid ${borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'}`};
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 20px;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: 17px;
  min-width: 100%;
  font-family: neue-montreal-regular;
  ::placeholder {
    font-family: neue-montreal-regular;
  }
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '8')}px;
  ${({ readOnly }) =>
    readOnly === undefined
      ? `&:focus {
            outline: none;
            border: 1px solid #E3772D;
            ::placeholder {
            }
        }`
      : `cursor: no-drop;

        &:focus {
            outline: none;
        }`}
`;

const Line = styled.div`
  width: 177.6px;
  height: 6px;
  margin-right: 18px;
  margin-top: 5px;
  background: ${({ background }) => (background ? background : '#E0E0E0')};
  border-radius: 29px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const ScrollableBody = styled.div`
  // overflow-y: auto;
  // max-height: 79vh;
  width: 100%;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const SeparatorItem = styled.div`
  width: 10.5px !important;
  height: 5.5px;
  border-radius: 20px;
  margin-top: 6px;
  margin-right: 5px;
  border: 1.5px solid #dd9139;
`;

const ItemShowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5px;
`;

export default FormSS;
