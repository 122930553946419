import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_FORM_ADDITIONAL } from '@graphql/mutation';
import {
  TextArea,
  InlineContainer,
  LabelNormal,
  SeparatorItem,
  ItemShowMore,
} from './ObservationsCAForm.style';

const ObservationsCAForm = ({
  patientId,
  formData,
  setSteps,
  setSelectTab,
}) => {
  const [additionalInfo, setAdditionalInfo] = useState('');

  const handlePrevious = () => {
    setSelectTab(4);
  };

  const [formAdditional] = useMutation(MUTATION_FORM_ADDITIONAL, {
    onCompleted: (data) => {
      if (data.updateAdditionalInstructions) {
        setSteps(3);
      }
    },
  });

  useEffect(() => {
    if (formData?.arcad && formData?.additional) {
      setAdditionalInfo(formData.additional);
    }
  }, [formData.arcad, formData.additional]);

  return (
    <>
      <div className="col-md-12" style={{ marginTop: 50 }}>
        <LabelNormal
          fontSize="26"
          color="#3C4549"
          style={{ fontWeight: 500, marginBottom: 20 }}
        >
          Observaciones
        </LabelNormal>
        <div className="row col-md-8">
          <ItemShowMore>
            <SeparatorItem />
            <LabelNormal color="#828282" fontSize="18">
              Esta es una simulación de movimientos basada en el diagnóstico y
              plan del tratamiento del especialista
            </LabelNormal>
          </ItemShowMore>
          <ItemShowMore>
            <SeparatorItem />
            <LabelNormal color="#828282" fontSize="18">
              La revisión del simulador y el plan del tratamiento lo establecerá
              el especialista a partir de su diagnóstico
            </LabelNormal>
          </ItemShowMore>
          <ItemShowMore>
            <SeparatorItem />
            <LabelNormal color="#828282" fontSize="18">
              Es responsabilidad del especialista la biomecánica utilizada
              durante el tratamiento y el resultado del mismo
            </LabelNormal>
          </ItemShowMore>
        </div>
      </div>
      <div className="col-md-12" style={{ marginTop: 50 }}>
        <LabelNormal
          fontSize="26"
          color="#3C4549"
          style={{ fontWeight: 500, marginBottom: 20 }}
        >
          Instrucciones adicionales
        </LabelNormal>
        <div className="row col-md-8">
          <TextArea
            onChange={(e) => setAdditionalInfo(e.target.value)}
            value={additionalInfo}
            placeholder="Si tienes algún comentario que no fue cubierto en el formulario, escríbelo aquí..."
            rows={10}
          />
        </div>
      </div>
      <div className="col-md-12" style={{ marginTop: 50 }}>
        <InlineContainer style={{ marginTop: 40, marginBottom: 40 }}>
          <ButtonPrime
            label="Anterior"
            background="transparent"
            color="#3C4549"
            border="1px solid #3C4549"
            style={{ marginRight: 20 }}
            onClick={handlePrevious}
          />
          <ButtonPrime
            label="Finalizar formulario"
            onClick={() =>
              formAdditional({
                variables: {
                  patient_id: patientId,
                  additional_instructions: additionalInfo,
                },
              })
            }
          />
        </InlineContainer>
      </div>
    </>
  );
};

export default ObservationsCAForm;
