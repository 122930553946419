import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_PATIENT_FILES,
  QUERY_DEFAULT_ADDRESS_DATA,
  QUERY_GET_PENDING_MESSAGES,
} from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Input from '@components/shared/Input';
import History from '@components/shared/History';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import LabelCase from '../../../components/shared/LabelCase.js';
import {
  MUTATION_UPDATE_PATIENT,
  MUTATION_ARCHIVIED_TREATMENT,
  MUTATION_ADD_PHOTO_PATIENT,
  MUTATION_CHANGE_MORE_SMARTCHECKS,
  MUTATION_CASE_READY,
  MUTATION_PRINTING_READY,
  MUTATION_THERMOFORMING_READY,
  MUTATION_REFINED_READY,
  MUTATION_BRACES_PLACED,
  MUTATION_ADD_COMMENT,
  MUTATION_RESTORE_CHANNEL_NOTIFICATION,
  MUTATION_DAY_SMARTCHECK,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import { loadPhotoProfileGender } from '../../../utils';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import Card from '../../../components/shared/Card';
import InternalNotesCard from '@components/shared/InternalNotesCard';
import ResumeSS from '../../../components/DoctorFormSS/resume';
import ResumeCA from '../../../components/DoctorFormCA/resume';
import ExtraoralCard from '../../../components/History/ExtraoralCard';
import FileCard from '../../../components/History/FileCard';
import IntraoralCard from '../../../components/History/IntraoralCard';
import CTASection from '../../../components/Case/detailsComponents/CTASection';
import { HeaderCard } from '../../../components/shared/Styled';
import AdminDetails from '../../../components/Case/AdminDetails';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
`;

const CategoryCard = styled.div`
  background: ${({ background }) => (background ? background : '#828282')};
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
`;
const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;
const CardNote = styled.div`
  max-height: 199px;
  border-radius: 30px;
  background: #fff;
`;
const NoteInput = styled.textarea`
  font-size: 17px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 20px;
  width: 100%;
  height: 47px;
  padding: 12px 17px 5px 17px;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border: 1px solid rgba(189, 189, 189, 0.7);
  border-radius: 16px;
  resize: none;
  text-align: justify;
  &:focus {
    height: 87px;
    outline: none;
    border: 1px solid #d97617;
  }
  &:hover {
    outline: none;
    border: 1px solid #d97617;
  }
`;

const Case = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);

  const [edit, setEdit] = useState(false);
  const [historyClinic, setHistoryClinic] = useState(false);
  const [patient, setPatient] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [treatment, setTreatment] = useState('');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [country, setCountry] = useState('');
  const [category, setCategory] = useState('');
  const [month, setMonth] = useState('');
  // const [hasComment, setHasComment] = useState(null);
  const [comments, setComments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [newComment, setNewComment] = useState('');
  const [year, setYear] = useState('');
  const [photo, setPhoto] = useState('');
  const [photoEvidences, setPhotoEvidences] = useState('');
  const [file, setFile] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [visorUrl, setVisorUrl] = useState('');
  const [doctorId, setDoctorId] = useState('');

  const [firstNameDT, setFirstNameDT] = useState('');
  const [lastNameDT, setLastNameDT] = useState('');
  const [photoDT, setPhotoDT] = useState('');
  const [statusTreatment, setStatusTreatment] = useState({});
  const [treatmentId, setTreatmentId] = useState('');
  const [zoom_link, setZoomLink] = useState('');
  const [statusSm, setStatusSm] = useState('');

  const [selectItem, setSelectItem] = useState('Formulario');
  const [filesTreatment, setFilesTreatment] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});
  const [sendModelStl, setSendModelStl] = useState(false);
  const [sendLinkStl, setSendLinkStl] = useState(false);
  const [moreSmartchecks, setMoreSmartchecks] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activePlanningChat, setActivePlanningChat] = useState(false);
  const [messageDoctor, setMessageDoctor] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [hasMessageDoctor, setHasMessageDoctor] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [urlSTL, setUrlSTL] = useState('');
  const [manufactStl, setManufactStl] = useState('');
  const [histories, setHistories] = useState([]);
  const [archived, setArchived] = useState(false);

  const [formCAData, setFormCAData] = useState({});
  const [formSSData, setFormSSData] = useState({});

  const [bracketsSS, setBracketsSS] = useState({});
  const [progressUpload, setProgressUpload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [readMore, setReadMore] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [commentSelected, setCommentSelected] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pendingMessages, setPendingMessages] = useState(null);
  const [isRefinement, setIsRefinement] = useState(false);

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;
        setPatient(patient);
        setIsRefinement(patient.treatment_related.is_refinement);

        let up_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        let lo_odontograma = [
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ];
        if (
          patient.treatment_related.formOdontogram &&
          patient.treatment_related.formOdontogram.up_left_second_molar_data &&
          patient.treatment_related.formOdontogram.type !== 'Sin cambios'
        ) {
          const odontograma = patient.treatment_related.formOdontogram;
          up_odontograma = [
            JSON.parse(odontograma.up_left_third_molar_data),
            JSON.parse(odontograma.up_left_second_molar_data),
            JSON.parse(odontograma.up_left_first_molar_data),
            JSON.parse(odontograma.up_left_second_premolar_data),
            JSON.parse(odontograma.up_left_first_premolar_data),
            JSON.parse(odontograma.up_left_canine_data),
            JSON.parse(odontograma.up_left_lateral_incisor_data),
            JSON.parse(odontograma.up_left_central_incisor_data),
            JSON.parse(odontograma.up_right_third_molar_data),
            JSON.parse(odontograma.up_right_second_molar_data),
            JSON.parse(odontograma.up_right_first_molar_data),
            JSON.parse(odontograma.up_right_second_premolar_data),
            JSON.parse(odontograma.up_right_first_premolar_data),
            JSON.parse(odontograma.up_right_canine_data),
            JSON.parse(odontograma.up_right_lateral_incisor_data),
            JSON.parse(odontograma.up_right_central_incisor_data),
          ];
          lo_odontograma = [
            JSON.parse(odontograma.lo_left_third_molar_data),
            JSON.parse(odontograma.lo_left_second_molar_data),
            JSON.parse(odontograma.lo_left_first_molar_data),
            JSON.parse(odontograma.lo_left_second_premolar_data),
            JSON.parse(odontograma.lo_left_first_premolar_data),
            JSON.parse(odontograma.lo_left_canine_data),
            JSON.parse(odontograma.lo_left_lateral_incisor_data),
            JSON.parse(odontograma.lo_left_central_incisor_data),
            JSON.parse(odontograma.lo_right_third_molar_data),
            JSON.parse(odontograma.lo_right_second_molar_data),
            JSON.parse(odontograma.lo_right_first_molar_data),
            JSON.parse(odontograma.lo_right_second_premolar_data),
            JSON.parse(odontograma.lo_right_first_premolar_data),
            JSON.parse(odontograma.lo_right_canine_data),
            JSON.parse(odontograma.lo_right_lateral_incisor_data),
            JSON.parse(odontograma.lo_right_central_incisor_data),
          ];
        }
        setFormCAData({
          arcad: patient.treatment_related.formArcade,
          shape: patient.treatment_related.formShape,
          ipr: patient.treatment_related.formIpr,
          odontograma: patient.treatment_related.formOdontogram,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
          status: patient.treatment_related.form_status,
          histories: patient.treatment_related.histories,
        });
        setFormSSData({
          bracket_ss: patient.treatment_related.bracket_ss,
          odontograma: patient.treatment_related.formOdontogram,
          brackets: patient.treatment_related.formBracket,
          additional: patient.treatment_related.additional_instructions,
          up_odontograma,
          lo_odontograma,
          status: patient.treatment_related.form_status,
          histories: patient.treatment_related.histories,
        });

        if (patient?.treatment_related?.stl_type === 'shipping')
          setSendModelStl(true);
        else if (patient?.treatment_related?.stl_type === 'url')
          setSendLinkStl(true);

        setDoctorId(patient.doctor.id);

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setPhone(patient.phone);
        setGender(patient.gender);
        setPhoto(
          patient.photo_filename
            ? `${process.env.REACT_APP_API_URL}/patients/${id}/profile/${patient.photo_filename}`
            : ''
        );
        setEmail(patient.email);
        setTreatment(patient.treatment);
        setVisorUrl(patient.treatment_related.visor_url);

        setBracketsSS(patient.treatment_related.bracket_ss);

        setHistories(patient.treatment_related.histories);
        setArchived(patient.treatment_related.archived);
        setMoreSmartchecks(patient.treatment_related.more_smartchecks);
        setMessageDoctor(patient.treatment_related.message_doctor);
        setZoomLink(
          patient.treatment_related.smartsChecks.length > 0
            ? patient.treatment_related.smartsChecks[
                patient.treatment_related.smartsChecks.length - 1
              ].zoom_link
            : ''
        );
        setStatusSm(
          patient.treatment_related.smartsChecks.length > 0
            ? patient.treatment_related.smartsChecks[
                patient.treatment_related.smartsChecks.length - 1
              ].status
            : ''
        );

        if (patient.birthdate) {
          setDay(patient.birthdate.split('-')[2]);
          setMonth(patient.birthdate.split('-')[1]);
          setYear(patient.birthdate.split('-')[0]);
        }
        if (patient.doctor) {
          setCategory(patient.doctor.category);
          setFirstNameDT(patient.doctor.user.first_name);
          setLastNameDT(patient.doctor.user.last_name);
          setPhotoDT(
            patient.doctor.user.photo_filename
              ? `${process.env.REACT_APP_API_URL}/users/${patient.doctor.user.id}/profile/${patient.doctor.user.photo_filename}`
              : ''
          );
          setCountry(patient.doctor.user.country.name);
        }
        setStatusTreatment(patient.treatment_related.treatmentStatus);
        setTreatmentId(patient.treatment_related.id);
        setManufactStl(
          patient.treatment_related.manufact_stl_filename
            ? patient.treatment_related.manufact_stl_filename
            : ''
        );
        setComments(patient.treatment_related.comments);
        getPendingMessages({
          variables: { treatment: patient.treatment_related.id },
        });
        getPatientFiles({ variables: { patient_id: id, onlyErrors: false } });
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  // eslint-disable-next-line no-unused-vars
  const [chatMessages, setChatMessages] = useState([]);

  const [getPendingMessages] = useLazyQuery(QUERY_GET_PENDING_MESSAGES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pendingChatMessages) {
        setPendingMessages(data.pendingChatMessages.length);
      } else {
        setPendingMessages(0);
      }
    },
  });

  const [getDefaultAddress] = useLazyQuery(QUERY_DEFAULT_ADDRESS_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getDefaultAddressData) {
        setDefaultAddress(data.getDefaultAddressData.defaultAddress);
      }
    },
    onError: (err) => {
      console.log(err, 'error address');
    },
  });

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (firstLoad) {
        getPatient({ variables: { id } });
        if (
          statusTreatment.type === 'planning' &&
          statusTreatment.index !== 7 &&
          statusSm === 'Pending' &&
          statusSm !== ''
        ) {
          changeDaySmartcheck({ variables: { patient_id: id } });
        }
      } else {
        setFirstLoad(true);
      }
    }, 20000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentId, statusTreatment, statusSm]);

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;

        let decode = {};
        patientfiles.forEach((item) => {
          decode = {
            ...decode,
            [item.name]: {
              type: item.file_type,
              name: item.file_name,
              url: item.fileUrl,
            },
          };
        });
        setFilesTreatment(decode);
        if (decode.evidence)
          setPhotoEvidences(decode.evidence.url ? decode.evidence.url : '');
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getPatient({ variables: { id } });
    getDefaultAddress({
      variables: {
        user_id: localStorage.getItem('b360-id'),
        patient_id: id,
      },
    });
    // getPatientFiles({ variables: { patient_id: id, onlyErrors: false } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReadMore(!readMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentSelected]);

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: () => {
      if (file.name) addProfilePhoto({ variables: file, patient_id: +id });
      else {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setEdit(false);
                getPatient({ variables: { id } });
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_PHOTO_PATIENT, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setEdit(false);
              getPatient({ variables: { id } });
            })
          }
        >
          <LabelNormal color="#060809" fontSize="22" style={{ marginTop: 30 }}>
            Cambios a perfil guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [changeMoreSmartchecks] = useMutation(
    MUTATION_CHANGE_MORE_SMARTCHECKS,
    {
      onCompleted: () => {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => getPatient({ variables: { id } }))}
          >
            <LabelNormal fontSize="22" color="#060809">
              Segundo Smart check solicitado con éxito
            </LabelNormal>
            <LabelNormal fontSize="17" color="#4F4F4F">
              Espera a que el doctor agende su segundo Smart Check
            </LabelNormal>
          </ModalSuccess>
        );
      },
    }
  );

  const [planningSendReady] = useMutation(MUTATION_CASE_READY, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            El caso está listo para aprobarse
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Espera a que el doctor apruebe el caso para iniciar la manufactura.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [printingReady] = useMutation(MUTATION_PRINTING_READY, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.go(0))}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Impresiones terminadas
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Impresiones terminadas.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [thermoformingReady] = useMutation(MUTATION_THERMOFORMING_READY, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.go(0))}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Termoformado listo
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Termoformado listo.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [refinedReady] = useMutation(MUTATION_REFINED_READY, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.go(0))}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Detallado listo
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Detallado listo.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [bracesPlaced] = useMutation(MUTATION_BRACES_PLACED, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.go(0))}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Brackets colocados
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Brackets colocados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [addComment] = useMutation(MUTATION_ADD_COMMENT, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() => modal.close(() => getPatient({ variables: { id } }))}
        >
          <LabelNormal
            fontSize="22"
            color="#060809"
            style={{ paddingTop: 26, fontWeight: 500, margin: 0 }}
          >
            Comentario añadido
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Comentario agregado correctamente al tratamiento
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [changeDaySmartcheck] = useMutation(MUTATION_DAY_SMARTCHECK, {
    onCompleted: (data) => {
      if (data.daySmartcheck) {
        getPatient({ variables: { id } });
      }
    },
  });

  const [restoreChannelNotificationWithHistoryPlanning] = useMutation(
    MUTATION_RESTORE_CHANNEL_NOTIFICATION,
    {
      onCompleted: (data) => {
        history.push({
          pathname: `/app/tablero/caso/revision-planificacion/${id}`,
          state: {
            treatmentId,
            firstName,
            lastName,
            treatment,
            smart:
              props.location.state && props.location.state.smart
                ? props.location.state.smart
                : false,
            visorReview: true,
          },
        });
      },
    }
  );

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const maskPhone = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
      6,
      val.length
    )}`;
    num = num.trim();
    setPhone(num);
  };

  const refInput = useRef(null);

  const uploadSTLAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query:
          'mutation addTreatmentStl($treatment_id: ID!, $file: Upload!){addTreatmentStl(input: { treatment_id: $treatment_id, file: $file }){id}}',
        variables: {
          file: 'file',
          treatment_id: treatmentId,
        },
      })
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    setLoading(true);
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addTreatmentStl) {
          setProgressUpload(0);
          setLoading(false);
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => getPatient({ variables: { id } }))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Archivos subidos a portal
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Caso enviado a impresión. Puedes cambiar el archivo desde el
                perfil del caso.
              </LabelNormal>
            </ModalSuccess>
          );
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onUploadSTL = async (e) => {
    await uploadSTLAxios(e.target.files[0]);
  };

  const HttpRequest = () => {
    const url = `${process.env.REACT_APP_STL_URL}${treatmentId}`;
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token-b360')}`,
      },
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        // let url = window.URL.createObjectURL(data.url);
        let a = document.createElement('a');
        a.href = data.url;
        a.download = 'stl-zip.zip';
        document.body.appendChild(a);
        a.click();
        a.remove();
        getPatient({ variables: { id } });
      });
  };

  const handleOpenModal = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Mover a casos inactivos pausarí¡ el tratamiento
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          El doctor ya no podra realizar acciones en el caso. Puedes consultar
          tus casos inactivos en el tablero de tus casos y filtrar por status a
          casos inactivos.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            archivedTreatment({ variables: { patient_id: id, archived: true } })
          }
          label="Mover a casos inactivos"
        />
      </ModalWarning>
    );
  };

  const [archivedTreatment] = useMutation(MUTATION_ARCHIVIED_TREATMENT, {
    onCompleted: (data) => {
      if (data.changeArchivedTreatment) {
        modal.close();
        getPatient({ variables: { id } });
      }
    },
    onError: (err) => console.log(err),
  });

  const backHistoryClinic = () => {
    setHistoryClinic(false);
  };

  const parentScroll = useRef(null);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() =>
                historyClinic ? backHistoryClinic() : history.goBack()
              }
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {!edit && !historyClinic && (
                <div className="col-md-12">
                  <Title>Caso</Title>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.goBack()}
                      mainLabel={'Tableros'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                </div>
              )}
              {edit && (
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <Title>Editar perfil</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Edita los datos y guarda los cambios.
                  </LabelNormal>
                </div>
              )}
              {historyClinic && (
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                  <Title>Historial clí­nico</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Consulta los archivos de tu paciente.
                  </LabelNormal>
                </div>
              )}
              <div className={historyClinic ? 'col-md-12' : 'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    {photoEvidences !== '' ? (
                      <>
                        <InlineContainer
                          style={{ alignItems: 'center', marginBottom: 10 }}
                        >
                          {!loadingSkeleton ? (
                            <>
                              <Avatar
                                src={
                                  photo ? photo : loadPhotoProfileGender(gender)
                                }
                                floatButton={edit}
                                edit={!!photo}
                                onChange={onChange}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                              <FeatherIcon
                                icon="arrow-right"
                                size={45}
                                style={{ marginLeft: 40, marginRight: 40 }}
                              />
                              <Avatar
                                src={
                                  photoEvidences
                                    ? photoEvidences
                                    : loadPhotoProfileGender(gender)
                                }
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                            </>
                          ) : (
                            <div>
                              <Skeleton
                                height={161}
                                borderRadius={27}
                                width={161}
                                baseColor={'#EEEEF2'}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          )}
                        </InlineContainer>
                        <InlineContainer>
                          {!loadingSkeleton ? (
                            <>
                              {!edit && !historyClinic && (
                                <div style={{ marginLeft: 35 }}>
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <LabelNormal
                                      color="#436FF1"
                                      fontSize="21"
                                      style={{ marginRight: 7 }}
                                    >
                                      PX.
                                    </LabelNormal>
                                    <LabelNormal
                                      fontSize="30"
                                      color="#3C4549"
                                    >{`${firstName} ${lastName}`}</LabelNormal>
                                  </InlineContainer>
                                  <InlineContainer>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <InlineContainer>
                                          <LabelNormal
                                            color="#4F4F4F"
                                            fontSize="20"
                                          >
                                            ID
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#BDBDBD"
                                            fontSize="20"
                                          >
                                            {id}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      <div className="col-md-8">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="phone" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {phone}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      {email && (
                                        <div className="col-md-7">
                                          <InlineContainer
                                            style={{ alignItems: 'center' }}
                                          >
                                            <FeatherIcon
                                              icon="mail"
                                              size={19}
                                            />
                                            <LabelNormal
                                              fontSize="14"
                                              color="#3C4549"
                                            >
                                              {email}
                                            </LabelNormal>
                                          </InlineContainer>
                                        </div>
                                      )}
                                    </div>
                                  </InlineContainer>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: 35,
                                marginTop: 10,
                                width: '100%',
                              }}
                            >
                              <div>
                                <Skeleton
                                  baseColor={'#F6F6F8'}
                                  width={'70%'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-7">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {historyClinic && (
                            <div style={{ marginLeft: 35 }}>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  fontSize="30"
                                  color="#3C4549"
                                >{`${firstName} ${lastName}`}</LabelNormal>
                              </InlineContainer>
                              <InlineContainer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginBottom: 10,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#828282"
                                        fontSize="13"
                                      >
                                        Tratamiento
                                      </LabelNormal>
                                      <LabelNormal
                                        color={
                                          treatment === 'cryst_aligner'
                                            ? '#18A1DC'
                                            : '#F0BB00'
                                        }
                                        fontSize="20.45"
                                      >
                                        {treatment === 'cryst_aligner'
                                          ? 'Cryst Aligner'
                                          : 'Smile System'}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  className="col-md-8"
                                  style={{ paddingLeft: 0 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="user" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {gender}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-8">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="phone" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {phone}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  className="col-md-8"
                                  style={{ marginTop: 8, paddingLeft: 0 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="gift" />
                                    <LabelNormal
                                      fontSize="14"
                                      color="#3C4549"
                                    >{`${year}-${month}-${day}`}</LabelNormal>
                                  </InlineContainer>
                                </div>
                                {email && (
                                  <div
                                    className="col-md-8"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <img
                                        src={require('@images/icons/email.png')}
                                        style={{ height: 16, width: 15.5 }}
                                        alt="email-icon"
                                      />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {email}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                )}
                              </InlineContainer>
                            </div>
                          )}
                        </InlineContainer>
                      </>
                    ) : (
                      <InlineContainer>
                        {!loadingSkeleton ? (
                          <div>
                            <Avatar
                              src={
                                photo ? photo : loadPhotoProfileGender(gender)
                              }
                              floatButton={edit}
                              edit={!!photo}
                              onChange={onChange}
                              height="161"
                              width="161"
                              borderRadius="30"
                            />
                          </div>
                        ) : (
                          <div>
                            <Skeleton
                              height={161}
                              borderRadius={27}
                              width={161}
                              baseColor={'#EEEEF2'}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        )}
                        {!loadingSkeleton ? (
                          <>
                            {!edit && !historyClinic && (
                              <div style={{ marginLeft: 35 }}>
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 8,
                                  }}
                                >
                                  <LabelNormal
                                    color="#436FF1"
                                    fontSize="21"
                                    style={{
                                      marginRight: 7,
                                      fontFamily: 'neue-montreal-medium',
                                      margin: '0px 12px 0px 0px',
                                    }}
                                  >
                                    PX
                                  </LabelNormal>
                                  <LabelNormal
                                    fontSize="22"
                                    color="#3C4549"
                                    style={{
                                      fontFamily: 'neue-montreal-medium',
                                      margin: 0,
                                    }}
                                  >{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                                <InlineContainer>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ marginBottom: 8 }}
                                      >
                                        <LabelNormal
                                          color="#4F4F4F"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: '0px 12px 0px 0px',
                                          }}
                                        >
                                          ID
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#BDBDBD"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: 0,
                                            letterSpacing: '0.11em',
                                          }}
                                        >
                                          {id}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    <div className="col-md-8">
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <FeatherIcon
                                          icon="phone"
                                          color="#757575"
                                          size={19}
                                        />
                                        <LabelNormal
                                          fontSize="17"
                                          color="#3C4549"
                                          style={{ margin: '0px 8px' }}
                                        >
                                          {phone}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    {email && (
                                      <div className="col-md-7">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="mail" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {email}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                    )}
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 35,
                              marginTop: 10,
                              width: '100%',
                            }}
                          >
                            <div>
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                width={'70%'}
                                height={27}
                                borderRadius={5}
                                highlightColor={'#FFF'}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <div className="row">
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-7">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {historyClinic && (
                          <div style={{ marginLeft: 35 }}>
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <LabelNormal
                                fontSize="30"
                                color="#3C4549"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <div className="row">
                                <div className="col-md-12">
                                  <InlineContainer
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <LabelNormal color="#828282" fontSize="13">
                                      Tratamiento
                                    </LabelNormal>
                                    <LabelNormal
                                      color={
                                        treatment === 'cryst_aligner'
                                          ? '#18A1DC'
                                          : '#F0BB00'
                                      }
                                      fontSize="20.45"
                                    >
                                      {treatment === 'cryst_aligner'
                                        ? 'Cryst Aligner'
                                        : 'Smile System'}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              </div>
                            </InlineContainer>
                            <InlineContainer>
                              <div
                                className="col-md-8"
                                style={{ paddingLeft: 0 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="user" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {gender}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-8">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="phone" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            </InlineContainer>
                            <InlineContainer>
                              <div
                                className="col-md-8"
                                style={{ marginTop: 8, paddingLeft: 0 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="gift" />
                                  <LabelNormal
                                    fontSize="14"
                                    color="#3C4549"
                                  >{`${year}-${month}-${day}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              {email && (
                                <div
                                  className="col-md-8"
                                  style={{ marginTop: 8 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <img
                                      src={require('@images/icons/email.png')}
                                      style={{ height: 16, width: 15.5 }}
                                      alt="email-icon"
                                    />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {email}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              )}
                            </InlineContainer>
                          </div>
                        )}
                      </InlineContainer>
                    )}
                  </div>
                  {!edit && !historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: 47 }}
                      ref={parentScroll}
                    >
                      <Card
                        padding="0px"
                        border="none"
                        minWidth="572px"
                        borderRadius="30px"
                      >
                        <HeaderCard>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="17"
                            style={{
                              fontFamily: 'neue-montreal-medium',
                              margin: 0,
                            }}
                          >
                            Historial
                          </LabelNormal>
                        </HeaderCard>
                        {!loadingSkeleton ? (
                          <CardContainer>
                            <div style={{ textAlign: 'center' }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="12"
                                style={{ textTransform: 'uppercase' }}
                              >
                                Paso actual
                              </LabelNormal>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <History
                                  histories={histories}
                                  type="admin"
                                ></History>
                              </div>
                            </div>
                          </CardContainer>
                        ) : (
                          <>
                            <CardContainer style={{ minHeight: 500 }}>
                              <center>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={16}
                                  width={'20%'}
                                  highlightColor={'#FFF'}
                                />
                              </center>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 33,
                                  border: '3px solid #EEEEF2',
                                  borderRadius: 10,
                                  padding: 10,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </CardContainer>
                          </>
                        )}
                      </Card>
                    </div>
                  )}
                  {edit && (
                    <div className="col-md-12" style={{ marginTop: 17 }}>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            style={{ marginTop: 38 }}
                            className="grid-layout"
                          >
                            <InlineContainer>
                              <div>
                                <Input
                                  placeholder="Nombre"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  label="Nombre del paciente"
                                />
                              </div>
                              <div>
                                <span
                                  style={{ fontSize: 18, visibility: 'hidden' }}
                                >
                                  none
                                </span>
                                <Input
                                  placeholder="Apellidos"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 20 }}
                          >
                            <LabelNormal color="#828282" fontSize="13">
                              Tratamiento
                            </LabelNormal>
                            <LabelNormal
                              color={
                                treatment === 'cryst_aligner'
                                  ? '#18A1DC'
                                  : '#F0BB00'
                              }
                              fontSize="20.45"
                            >
                              {treatment === 'cryst_aligner'
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        <div className="col-md-7">
                          <div>
                            <LabelInput>Sexo</LabelInput>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="gender-1"
                                  onClick={(e) => setGender('Masculino')}
                                  checked={
                                    gender === 'Masculino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-1"
                                  className="radio-label"
                                >
                                  Hombre
                                </label>
                              </div>
                              <div className="radio">
                                <input
                                  id="gender-2"
                                  onClick={(e) => setGender('Femenino')}
                                  checked={
                                    gender === 'Femenino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-2"
                                  className="radio-label"
                                >
                                  Mujer
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 28 }}>
                          <LabelInput>Fecha de nacimiento</LabelInput>
                          <InlineContainer>
                            <Input
                              placeholder="DD"
                              maxWidth="60px"
                              value={day}
                              onChange={(e) => setDay(e.target.value)}
                            />
                            <Input
                              placeholder="MM"
                              maxWidth="60px"
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                            />
                            <Input
                              placeholder="AAAA"
                              maxWidth="90px"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </InlineContainer>
                        </div>
                        <div className="col-md-7" style={{ marginTop: 28 }}>
                          <div>
                            <LabelInput>Telí©fono</LabelInput>
                            <InlineContainer>
                              <Input
                                readOnly
                                disabled
                                value={'+52'}
                                backgroundColor="#F6F6F8"
                                maxWidth="70px"
                              />
                              <Input
                                placeholder="Telí©fono"
                                value={phone}
                                type="int"
                                oonChange={maskPhone}
                              />
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-5" style={{ marginTop: 28 }}>
                          <div className="grid-layout">
                            <Input
                              placeholder="Tu correo"
                              label="Email (Opcional)"
                              value={email}
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              maxWidth="300px"
                            />
                          </div>
                        </div>
                      </div>
                      <ButtonPrime
                        label="Guardar cambios"
                        disabled={false}
                        onClick={() =>
                          updatePatient({
                            variables: {
                              patient_id: id,
                              first_name: firstName,
                              last_name: lastName,
                              email: email === null ? '' : email,
                              phone,
                              gender,
                              birthdate: `${year}-${month}-${day}`,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: historyClinic ? 60 : 0 }}
                    >
                      <InlineContainer
                        style={{
                          background: '#F6F6F8',
                          borderRadius: 40,
                          paddingTop: 30,
                          paddingLeft: 20,
                          paddingBottom: 30,
                          alignItems: 'self-start',
                          boxShadow: '0px 0px 8px rgba(162, 162, 162, 0.1)',
                        }}
                      >
                        <div
                          style={{
                            marginRight: 40,
                            width: 168,
                            position: 'sticky',
                            top: '50px',
                          }}
                        >
                          <div>
                            {[
                              'Formulario',
                              'Intraorales',
                              'Extraorales',
                              "STL's",
                              'Radiografias',
                            ].map((row, index) => (
                              <div
                                style={{
                                  maxWidth: 165,
                                  maxHeight: 55,
                                  background:
                                    selectItem === row ? '#FFF' : 'transparent',
                                  borderBottomColor:
                                    selectItem === row
                                      ? 'transparent'
                                      : '#D4D4D4',
                                  borderBottomStyle: 'solid',
                                  borderBottomWidth: 1,
                                  padding: 15,
                                  borderRadius: selectItem !== row ? 0 : 10,
                                }}
                                key={Math.random()}
                              >
                                <LabelNormal
                                  color="#000000"
                                  fontSize="18.5"
                                  onClick={() => setSelectItem(row)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {row}
                                </LabelNormal>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div ref={parentScroll} style={{ width: '100%' }}>
                          {selectItem === 'Formulario' && (
                            <div className="row">
                              <div className="col-md-12">
                                {formSSData.brackets &&
                                  treatment === 'smile_system' && (
                                    <ResumeSS
                                      formSSData={formSSData}
                                      chatMessages={chatMessages}
                                    />
                                  )}
                                {formCAData.arcad &&
                                  treatment === 'cryst_aligner' && (
                                    <ResumeCA
                                      formCAData={formCAData}
                                      chatMessages={chatMessages}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Radiografias' && (
                            <div className="row">
                              <div className="col-md-7">
                                <InlineContainer style={{ gap: '24px' }}>
                                  <IntraoralCard
                                    image={filesTreatment['lateral']}
                                    alt="lateral"
                                    name="Lateral"
                                  />
                                  <IntraoralCard
                                    image={filesTreatment['panoramica']}
                                    alt="panoramica"
                                    name="Panorámica"
                                  />
                                  {filesTreatment['trazado_cefalometrico'] && (
                                    <IntraoralCard
                                      image={
                                        filesTreatment['trazado_cefalometrico']
                                      }
                                      alt="trazado_cefalometrico"
                                      name="Trazado cefalométrico"
                                    />
                                  )}
                                  {filesTreatment['tomografia'] && (
                                    <IntraoralCard
                                      image={filesTreatment['tomografia']}
                                      alt="tomografia"
                                      name="Tomografía"
                                    />
                                  )}
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === "STL's" && (
                            <div className="row">
                              <div className="col-8">
                                {sendModelStl && (
                                  <div>
                                    <LabelNormal color="#3C4549" fontSize="17">
                                      Se seleccionó envío de modelos por
                                      paquetería a la siguiente dirección:
                                    </LabelNormal>
                                    <InlineContainer>
                                      <CardModel style={{ maxWidth: 356 }}>
                                        <LabelNormal
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            marginRight: 7,
                                            marginBottom: 10,
                                          }}
                                          color="#757575"
                                          fontSize="18"
                                        >
                                          Dirección de envío | Borgatta
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#828282"
                                          style={{ margin: 0, padding: 0 }}
                                          fontSize="17"
                                        >
                                          {defaultAddress.id
                                            ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code}, ${defaultAddress.state}`
                                            : ''}
                                        </LabelNormal>
                                      </CardModel>
                                    </InlineContainer>
                                  </div>
                                )}
                                {sendLinkStl && (
                                  <InlineContainer>
                                    <LabelNormal color="#3C4549" fontSize="20">
                                      {urlSTL}
                                    </LabelNormal>
                                  </InlineContainer>
                                )}
                                {!sendLinkStl && !sendModelStl && (
                                  <InlineContainer style={{ flexWrap: 'wrap' }}>
                                    <FileCard
                                      stl={filesTreatment.arcada_superior}
                                      name="Arcada superior"
                                    />
                                    <FileCard
                                      stl={filesTreatment.oclusion_derecha}
                                      name="Oclusión"
                                    />
                                    {filesTreatment.oclusion_izquierda && (
                                      <FileCard
                                        stl={filesTreatment.oclusion_izquierda}
                                        name="Oclusión izquierda"
                                      />
                                    )}
                                    <FileCard
                                      stl={filesTreatment.arcada_inferior}
                                      name="Arcada inferior"
                                    />
                                  </InlineContainer>
                                )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Extraorales' && (
                            <div className="row">
                              <div
                                class="col-8"
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '32px',
                                  justifyContent: 'center',
                                }}
                              >
                                <ExtraoralCard
                                  image={filesTreatment['frontal_serio']}
                                  alt="frontal_serio"
                                  name="Frontal serio"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['frontal_sonriendo']}
                                  alt="frontal_sonriendo"
                                  name="Frontal sonriendo"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['frontal_retractores']}
                                  alt="frontal_retractores"
                                  name="Frontal con retractores"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['lateral_izq_serio']}
                                  alt="lateral_izq_serio"
                                  name="Lateral izquierda"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['lateral_der_serio']}
                                  alt="lateral_der_serio"
                                  name="Lateral derecha"
                                />
                              </div>
                            </div>
                          )}
                          {selectItem === 'Intraorales' && (
                            <div
                              class="col-9"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                              }}
                            >
                              <InlineContainer
                                style={{ justifyContent: 'space-between' }}
                              >
                                <IntraoralCard
                                  image={filesTreatment['oclusal_superior']}
                                  alt="oclusal_superior"
                                  name="Oclusal superior"
                                />
                                <IntraoralCard
                                  image={filesTreatment['oclusal_inferior']}
                                  alt="oclusal_inferior"
                                  name="Oclusal inferior"
                                />
                              </InlineContainer>
                              <InlineContainer
                                style={{ justifyContent: 'space-between' }}
                              >
                                <IntraoralCard
                                  image={filesTreatment['lat_der_oclusion']}
                                  alt="lat_der_oclusion"
                                  name="Lateral derecha en oclusión"
                                />
                                <IntraoralCard
                                  image={filesTreatment['frontal_oclusion']}
                                  alt="frontal_oclusion"
                                  name="Frontal en oclusión"
                                />
                                <IntraoralCard
                                  image={filesTreatment['lat_izq_oclusion']}
                                  alt="lat_izq_oclusion"
                                  name="Lateral izquierda en oclusión"
                                />
                              </InlineContainer>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!historyClinic && (
                  <div className="row">
                    <AdminDetails
                      patient={patient}
                      treatmentType={treatment}
                      pendingMessages={pendingMessages}
                      loadingSkeleton={loadingSkeleton}
                      changeMoreSmartchecks={changeMoreSmartchecks}
                      planningSendReady={planningSendReady}
                      loading={loading}
                      progressUpload={progressUpload}
                      onUploadSTL={onUploadSTL}
                      HttpRequest={HttpRequest}
                      printingReady={printingReady}
                      thermoformingReady={thermoformingReady}
                      refinedReady={refinedReady}
                      bracesPlaced={bracesPlaced}
                    />
                    <div className="col-md-12" style={{ marginTop: 43 }}>
                      {!loadingSkeleton ? (
                        <Card
                          padding="0px"
                          border="none"
                          minWidth="340px"
                          borderRadius="30px"
                        >
                          <CardContainer style={{ borderRadius: 25 }}>
                            <LabelCase
                              onClick={() =>
                                history.push({
                                  pathname: `/app/doctores/perfil-doctor/${doctorId}`,
                                })
                              }
                              label={'Datos del doctor'}
                              icon={'user'}
                              description={true}
                              descriptionText={'Contacto, Facturación, Enví­o'}
                            />
                            <LabelCase
                              onClick={() => setHistoryClinic(!historyClinic)}
                              label={'Archivos del paciente'}
                              icon={'folder'}
                              description={true}
                              descriptionText={
                                'Fotografí­as, STLs, Radiografí­as'
                              }
                              marginTop={'30'}
                            />
                            <LabelCase
                              onClick={() =>
                                history.push({
                                  pathname: `/app/tablero/soporte/${patient?.id}/${treatmentId}`,
                                })
                              }
                              label={'Escribir al doctor'}
                              icon={'message-circle'}
                              marginTop={'30'}
                            />
                            <LabelCase
                              onClick={() =>
                                activePlanningChat && hasMessageDoctor
                                  ? restoreChannelNotificationWithHistoryPlanning(
                                      { variables: { patient_id: id } }
                                    )
                                  : history.push({
                                      pathname: `/app/tablero/caso/revision-planificacion/${id}`,
                                      state: {
                                        treatmentId,
                                        firstName,
                                        lastName,
                                        treatment,
                                        smart:
                                          props.location.state &&
                                          props.location.state.smart
                                            ? props.location.state.smart
                                            : false,
                                        visorReview: true,
                                      },
                                    })
                              }
                              label={
                                treatment === 'cryst_aligner'
                                  ? 'Revisión de visor'
                                  : 'Datos de planificación'
                              }
                              icon={
                                treatment === 'cryst_aligner'
                                  ? 'message-square'
                                  : 'clipboard-notes'
                              }
                              marginTop={'30'}
                            />
                            {manufactStl !== '' && (
                              <LabelCase
                                onClick={() =>
                                  history.push({
                                    pathname: `/app/tablero/caso/stl-manufactura/${id}`,
                                  })
                                }
                                label={'STLs para manufactura'}
                                icon={'folder'}
                                marginTop={'30'}
                              />
                            )}
                            {statusTreatment.type !== 'new_case' &&
                              archived === false && (
                                <LabelCase
                                  onClick={() => handleOpenModal()}
                                  label={'Mover a casos inactivos'}
                                  icon={'archive'}
                                  marginTop={'30'}
                                />
                              )}
                            {(statusTreatment.type === 'new_case' ||
                              archived === true) && <LabelCase disabled />}
                          </CardContainer>
                        </Card>
                      ) : (
                        <CardContainer style={{ borderRadius: 25 }}>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="row"
                                style={{ alignItems: 'flex-start' }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardContainer>
                      )}
                    </div>
                    {!loadingSkeleton && (
                      <div
                        className="col-md-12"
                        style={{
                          marginTop: 43,
                        }}
                      >
                        <InternalNotesCard
                          comments={comments}
                          treatmentId={treatmentId}
                          addComment={addComment}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
