import { LabelNormal, LabelTitle } from '@components/shared/Styled';
import Input from '@components/shared/Input';

export const PlanningTotalAligners = ({
  alineadoresInferiores,
  alineadoresSuperiores,
  calculateAlineadores,
  isEditMode,
  errorMessages,
  handleOnChange,
  setAlineadoresInferiores,
  setAlineadoresSuperiores,
}) => {
  return (
    <>
      <LabelTitle color="#3C4549" fontSize="22">
        Cantidad total de alineadores
      </LabelTitle>
      <div className="flex flex-row mt-8 gap-4">
        <div className="flex flex-col">
          {isEditMode ? (
            <Input
              label="Superiores"
              placeholder="Cantidad"
              onChange={(value) => {
                setAlineadoresSuperiores(value);
                handleOnChange(value, 'alineadoresSuperiores');
              }}
              style={{ marginTop: 10 }}
              mask="onlyNumber"
              value={alineadoresSuperiores}
              className={errorMessages.alineadoresSuperiores && 'etftxW'}
            />
          ) : (
            <div className="flex flex-col gap-4 pr-16">
              <p className="text-base text-[#757575]">Superiores</p>
              <p className="text-base text-[#3C4549]">
                {alineadoresSuperiores}
              </p>
            </div>
          )}
          {errorMessages.alineadoresSuperiores && (
            <div style={{ marginBottom: 15 }}>
              <LabelNormal fontSize="17" color="#C60E02">
                {errorMessages.alineadoresSuperiores}
              </LabelNormal>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          {isEditMode ? (
            <Input
              label="Inferiores"
              placeholder="Cantidad"
              onChange={(value) => {
                setAlineadoresInferiores(value);
                handleOnChange(value, 'alineadoresInferiores');
              }}
              style={{ marginTop: 10 }}
              mask="onlyNumber"
              value={alineadoresInferiores}
              className={errorMessages.alineadoresInferiores && 'etftxW'}
            />
          ) : (
            <div className="flex flex-col gap-4 pr-16">
              <p className="text-base text-[#757575]">Inferiores</p>
              <p className="text-base text-[#3C4549]">
                {alineadoresInferiores}
              </p>
            </div>
          )}
          {errorMessages.alineadoresInferiores && (
            <div style={{ marginBottom: 15 }}>
              <LabelNormal fontSize="17" color="#C60E02">
                {errorMessages.alineadoresInferiores}
              </LabelNormal>
            </div>
          )}
        </div>
        <div className="flex">
          <div className="flex flex-col gap-4 pr-16">
            <p className="text-base text-[#757575]">Paquete de alineadores</p>
            <p className="text-base text-[#3C4549]">{calculateAlineadores()}</p>
          </div>
        </div>
      </div>
    </>
  );
};
