import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: #E3772D;
  strokeWidth: 2px;
`
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  width: 21px;
  height: 21px;
  background: ${props => (props.checked ? '#FFF' : '#FFF')};
  border-color: #C8C8C8;
  border-width: 1px;
  border-style: solid;
  border-radius: 6.8px;
  transition: all 150ms;
  margin-right: 10px;
  cursor: pointer;
  transition: all .3s ease-out;
  &:hover{
    box-shadow: ${props => (props.checked ? 'unset' : '0 0 0 6px rgba(200, 200, 200, 0.4)')};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <label htmlFor={props.id}>
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </label>
  </CheckboxContainer>
)

export default Checkbox;
