import FeatherIcon from 'feather-icons-react';
import { LabelNormal, DownloadButton } from '../shared/Styled';
import { downloadImage } from '../../utils';

const IntraoralCard = ({ image, alt, name }) => (
  <div style={{ width: '180px' }}>
    <div style={{ position: 'relative' }}>
        <img
            src={image && image.url && alt !== "tomografia" ? image.url : alt === "tomografia" ? require('@images/files/zip.jpg') : require('@images/patient/superior.png')}
            style={{ height: 'auto', width: '100%', borderRadius: '16.28px' }}
            alt={alt}
        />
        <DownloadButton
            width="32px"
            height="32px"
            onClick={() =>
                downloadImage(
                    image.name,
                    image.url
                )
            }
            style={{
                display: image.type === 'pdf' ? 'none' : 'flex',
                right: '8px'
            }}
        >
            <FeatherIcon
                icon="download"
                color="#FFF"
                size="30"
            />
        </DownloadButton>
    </div>
    { image && image.name && (
        <LabelNormal
          style={{
            width: 162,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          color="#828282"
          fontSize="15"
        >
          { image.name }
        </LabelNormal>
      )}
    <LabelNormal color="#3C4549" fontSize="15">
      { name }
    </LabelNormal>
  </div>
);

export default IntraoralCard;
