import React, { useState } from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import ButtonPrime from '@components/shared/ButtonPrime';
import { modal } from '@components/ModalManagement';
import {
  MUTATION_SUBMIT_QUESTION_DOCTOR,
  MUTATION_CHAT_CORRECTION_DOCTOR,
  MUTATION_EDIT_CHAT_CORRECTION_FORM,
} from '@graphql/mutation';
import { useMutation } from '@apollo/client';

const Image = styled.img`
  height: 29px;
  width: 29px;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const QuestionText = styled.textarea`
  border: 1px solid #bdbdbdb2;
  background: #ffffff;
  border-radius: 13.93px;
  padding: 12px 20px 20px 20px;
  min-height: 220px;
  color: #000;
  font-size: 17px;
  font-family: neue-montreal-regular;
  width: 100%;
  margin-top: 20px;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  padding: 5px 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
  font-size: 19px;
  padding: 12px 25px 13px;
  font-family: neue-montreal-regular;
  margin-left: 20px;
`;

const Question = (props) => {
  const [message, setMessage] = useState(props.question ? props.question : '');
  const [loading, setLoading] = useState(false);

  const [onSubmitQuestion] = useMutation(MUTATION_SUBMIT_QUESTION_DOCTOR, {
    onCompleted: (data) => {
      modal.close(() => {
        setMessage('');
        props.onClose();
      });
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  const [onSubmitQuestionForm] = useMutation(MUTATION_CHAT_CORRECTION_DOCTOR, {
    onCompleted: (data) => {
      modal.close(() => {
        setMessage('');
        props.onClose();
      });
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  const [onSubmitQuestionEdit] = useMutation(
    MUTATION_EDIT_CHAT_CORRECTION_FORM,
    {
      onCompleted: (data) => {
        modal.close(() => {
          setMessage('');
          props.onClose();
        });
      },
      onError: (err) => {
        setLoading(false);
      },
    }
  );

  return (
    <div className="modal-large">
      <Inline
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderBottom: '1px solid #000',
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 14,
          paddingBottom: 14,
        }}
      >
        <FeatherIcon icon="help-circle" size="16" />
        <LabelNormal fontSize="20" color="#3C4549">
          {props.form ? 'Preguntas a doctor' : '¿Tienes dudas?'}
        </LabelNormal>
      </Inline>
      <div style={{ padding: 20 }}>
        <div
          style={{
            padding: 12,
            background: '#FFE5E3',
            borderRadius: 20,
            display: 'flex',
            gap: 20,
          }}
        >
          <Image src={require('@images/icons/unsubscribe.png')} />
          <LabelNormal fontSize="14" color="#C60E02" style={{ margin: 0 }}>
            Escribe las dudas generales que existan sobre este caso. Estas se le
            enviaran al doctor por el chat y el caso será puesto en pausa hasta
            que sean resueltas. Si tienes un comentario sobre un archivo en
            específico, usa el apartado correspondiente
          </LabelNormal>
        </div>
        <QuestionText
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder="Escribe tu pregunta"
        ></QuestionText>
        <div style={{ marginTop: 20, display: 'flex' }}>
          {(props.question === '' || props.question !== '') && props.form && (
            <ButtonPrime
              label="Guardar y continuar"
              onClick={() => {
                setLoading(true);
                if (props.question === '') {
                  onSubmitQuestionForm({
                    variables: {
                      doctor_id: props.doctorId,
                      treatment_id: props.treatmentId,
                      question: message,
                    },
                  });
                } else {
                  onSubmitQuestionEdit({
                    variables: {
                      treatment_id: props.treatmentId,
                      question: message,
                      form: true,
                    },
                  });
                }
              }}
              disabled={message && !loading ? false : true}
            ></ButtonPrime>
          )}
          {!props.doctorEdit && !props.form && (
            <ButtonPrime
              label="Guardar y continuar"
              onClick={() => {
                setLoading(true);
                onSubmitQuestion({
                  variables: {
                    doctor_id: props.doctorId,
                    treatment_id: props.treatmentId,
                    question: message,
                  },
                });
              }}
              disabled={message && !loading ? false : true}
            ></ButtonPrime>
          )}
          {props.doctorEdit && !props.form && (
            <ButtonPrime
              label="Guardar y continuar"
              onClick={() => {
                setLoading(true);
                onSubmitQuestionEdit({
                  variables: {
                    treatment_id: props.treatmentId,
                    question: message,
                    form: false,
                  },
                });
              }}
              disabled={message && !loading ? false : true}
            ></ButtonPrime>
          )}
          <ButtonModal onClick={() => modal.close(() => setMessage(''))}>
            {props.form ||
            ((props.question === '' || props.question !== '') && !props.form)
              ? 'Cancelar'
              : 'Cancelar'}
          </ButtonModal>
        </div>
      </div>
    </div>
  );
};

export default Question;
