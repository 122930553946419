import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as action from '@redux/actions/auth-action';
import { QUERY_GET_SIDEBAR_DATA, QUERY_GET_DOCTOR_TOKEN } from '@graphql/query';
import { DoctorsIcon } from '../../Icons/Doctors';
import { HelpIcon } from '../../Icons/Help';
import SectionsDoctor from './Sections/SectionsDoctor';
import SectionsTeacher from './Sections/SectionsTeacher';
import SectionsUniversity from './Sections/SectionsUniversity';
import SectionsStudent from './Sections/SectionsStudent';
import SectionsAdmin from './Sections/SectionsAdmin';
import Brand from '../Brand/Brand';
import Item from '../Item/Item';

const ContainerWidth = styled.div`
  margin-right: 216px;
  z-index: 11;
`;

const Container = styled.div`
  padding: 40px 20px 40px 22px;
  height: auto !important;
  margin: 0 auto -60px;
  min-height: 100vh;
  position: fixed;
  width: 226px;
  background-color: #f6f6f8;
`;

const ContainFooter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  bottom: 0;
  margin-bottom: 42px;
`;

const Sidebar = (props) => {
  const [Id, setId] = useState('');
  const [photo, setPhoto] = useState('');

  const [getDetailsMe] = useLazyQuery(QUERY_GET_SIDEBAR_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.me) {
        setId(data.me.id);
        setPhoto(data.me.photo_filename);
      }
    },
  });

  const [getDetails] = useLazyQuery(QUERY_GET_DOCTOR_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDoctorAuth) {
        const doctor = data.getDoctorAuth;

        setId(doctor.user.id);
        setPhoto(doctor.user.photo_filename);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (props.isLoggedIn === 'admin') {
      getDetailsMe({});
    }
    if (props.isLoggedIn === 'doctor') {
      getDetails({});
    }
  }, [getDetailsMe, getDetails, props.isLoggedIn]);

  return (
    <ContainerWidth>
      <Container>
        <div style={{ marginLeft: 14, marginBottom: 46 }}>
          <Brand
            src={require('@images/brand.png')}
            height={'31'}
            width={'70'}
          />
        </div>

        {props.isLoggedIn === 'admin' && <SectionsAdmin name={props.name} />}
        {props.isLoggedIn === 'doctor' && <SectionsDoctor name={props.name} />}
        {props.isLoggedIn === 'university' && (
          <SectionsUniversity name={props.name} />
        )}
        {props.isLoggedIn === 'teacher' && (
          <SectionsTeacher name={props.name} />
        )}
        {props.isLoggedIn === 'student' && (
          <SectionsStudent name={props.name} />
        )}

        <ContainFooter>
          {(props.isLoggedIn === 'doctor' ||
            props.isLoggedIn === 'student' ||
            props.isLoggedIn === 'teacher') && (
            <Item
              label="Soporte"
              to={
                props.isLoggedIn === 'doctor' || props.isLoggedIn === 'student'
                  ? '/doctor/soporte'
                  : props.isLoggedIn === 'teacher'
                  ? '/profesor/soporte'
                  : ''
              }
              icon={
                <HelpIcon
                  color={
                    props.name === 'soporte'
                      ? '#FFFFFF'
                      : 'rgba(78, 90, 95, 0.6)'
                  }
                />
              }
              color={props.name === 'soporte' ? '#FFFFFF' : '#4E5A5F'}
              $background={props.name === 'soporte' && '#333C3F'}
            />
          )}
          {props.name && (
            <Item
              label="Tu perfil"
              to={
                props.isLoggedIn === 'admin'
                  ? '/app/perfil'
                  : props.isLoggedIn === 'doctor' ||
                    props.isLoggedIn === 'student'
                  ? '/doctor/perfil'
                  : props.isLoggedIn === 'university'
                  ? '/universidad/perfil'
                  : props.isLoggedIn === 'teacher'
                  ? '/profesor/perfil'
                  : ''
              }
              icon={
                !photo ? (
                  <DoctorsIcon
                    color={
                      props.name === 'perfil'
                        ? '#FFFFFF'
                        : 'rgba(78, 90, 95, 0.6)'
                    }
                  />
                ) : (
                  <></>
                )
              }
              img={
                photo &&
                `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${photo}`
              }
              color={props.name === 'perfil' ? '#FFFFFF' : '#4E5A5F'}
              $background={props.name === 'perfil' && '#333C3F'}
              height={'20'}
              width={'20'}
            />
          )}
        </ContainFooter>
      </Container>
    </ContainerWidth>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(action.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
