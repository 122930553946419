import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { CardContainer, InlineContainer, LabelNormal } from '../shared/Styled';

const CardWithLink = ({ linkTo, content, icon = null, active = false }) => (
  <div
    className="col-md-12"
    style={{
      marginTop: 30,
      cursor: 'pointer',
      filter: 'drop-shadow(0px 0px 4px rgba(162, 162, 162, 0.1))',
    }}
  >
    <Link to={linkTo}>
      <CardContainer
        style={{
          borderRadius: 25,
          padding: 20,
          border: active ? '2px solid #EB9542' : '2px solid transparent',
        }}
      >
        <InlineContainer>
          {icon && (
            <FeatherIcon
              icon="message-circle"
              color={active ? '#EB9542' : '#3C4549'}
              size={20}
              style={{ marginTop: 7 }}
            />
          )}
          <LabelNormal style={{ marginLeft: 10 }} color="#3C4549" fontSize="20">
            {content}
          </LabelNormal>
        </InlineContainer>
      </CardContainer>
    </Link>
  </div>
);

export default CardWithLink;
