import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

export const PlanningReviewNavigation = ({ history }) => {
  return (
    <Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 17,
          marginLeft: 51,
        }}
      >
        <ButtonNavigation
          icon={require('@images/icons/chevron-left.png')}
          onClick={() => history.goBack()}
        />
        <ButtonNavigation
          icon={require('@images/icons/chevron-right.png')}
          onClick={() => history.goForward()}
        />
      </div>
    </Header>
  );
};
