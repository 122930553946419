import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Input from '@components/shared/Input';
import CloseSession from '../../components/shared/CloseSession';
import LinkUnderline from '../../components/shared/LinkUnderline';
import LabelUnderline from '../../components/shared/LabelUnderline';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { 
    MUTATION_UPDATE_PASSWORD,
    MUTATION_DELETED_TAX_DATA,
    MUTATION_DELETED_ADDRESS
} from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import UnSubscribeModal from '@components/Modal/ModalUnSubscribe';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import { loadPhotoProfileGender } from '../../utils';
import moment from 'moment';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 28px;
    margin-right: 79px;
    margin-bottom: 100px;max-width: 1072px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const HeaderCard = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const ContainForm = styled.div`
    margin-top: 39px;
`;

const LabelInput = styled.div`
    font-size: 18px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    line-height: 21.6px;
`;

const LabelMute = styled.p`
    font-size: 15px;
    font-family: neue-montreal-regular;
    color: #757575;
`;

const DecoratorBlue = styled.div`
    background: linear-gradient(#1AA5E0, #088EC7);
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const DecoratorYellow = styled.div`
    background: #F0BB00;
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const ScrollHorizontal = styled.div`
    align-items: stretch;
    display: flex;    
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
`;

const CardInlineContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
    display:inline-block;
    margin: 0px 5px;
`;

const Student = (props) => {
    const history = useHistory();
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);

    const [changePassword, setChangePassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [crystAligner, setCrystAligner] = useState('');
    const [smileSystem, setSmileSystem] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [photo, setPhoto] = useState('');
    const [file, setFile] = useState({});
    const [idUser, setIdUser] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');

    const [nameUniversity, setNameUniversity] = useState('');
    const [idUniversity, setIdUniversity] = useState('');
    const [period, setPeriod] = useState('');
    const [type, setType] = useState('');

    const [taxDatas, setTaxDatas] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [creditCards, setCreditCards] = useState([]);

    const [treatments, setTreatments] = useState([]);

    const [getStudent] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.doctor){
                const doctor = data.doctor;

                setTreatments(doctor.treatments);

                setTaxDatas(doctor.taxdatas)
                setAddresses(doctor.addresses);
                setCreditCards(doctor.cards);

                setNameUniversity(doctor.university.name);
                setType(doctor.university.type);
                setPeriod(doctor.university.graduate_periods);

                setIdUser(doctor.user.id);
                setFirstName(doctor.user.first_name);
                setLastName(doctor.user.last_name);
                setGender(doctor.user.gender);
                setCountry(doctor.user.country.name);
                setEmail(doctor.user.email);
                setCreatedAt(doctor.user.created_at);
                setPhoto(doctor.user.photo_filename);
                setPhone(doctor.user.phone);

                setCrystAligner(doctor.cryst_aligner);
                setSmileSystem(doctor.smile_system);

                setIdUniversity(doctor.university.id);
            }
            setLoadingSkeleton(false);
        },
        onError: err => console.log(err)
    })

    useEffect(() => {
        setLoadingSkeleton(true);
        getStudent({ variables: { id: localStorage.getItem('b360-role_id') } });
    },[])

    const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD, {
        onCompleted: data => {
            if(data.updatePassword){
                setChangePassword(false);
                setPassword('');
                setConfirmPass('');
                modal.open(
                    <ModalSuccess onClick={() => modal.close()}>
                        <LabelNormal color="#060809" fontSize="22" style={{marginBottom: 14}}>Cambiaste tu contraseña</LabelNormal>
                        <LabelNormal color="#4F4F4F" fontSize="17" style={{marginBottom: 14}}>Podrás ingresar con la nueva contraseña cuando inicies sesión en el portal.</LabelNormal>
                    </ModalSuccess>
                )
            }
        }
    });

    const [deleteTaxData] = useMutation(MUTATION_DELETED_TAX_DATA, {
        onCompleted: data => {
            if(data.deleteTaxData){
                getStudent({ variables: { id: localStorage.getItem('b360-role_id') } })
            }
        }
    })

    const onSubmitDeleteTaxData = async (id) => {
        await deleteTaxData({ variables: { id } });
        modal.close();
    }

    const onModalDeleteTaxData = (id) => {
        modal.open(
            <UnSubscribeModal withoutIcon={true}>
                <LabelNormal color="#060809" fontSize="22">¿Seguro de que deseas eliminar este perfil fiscal?</LabelNormal>
                <ButtonPrime onClick={() => onSubmitDeleteTaxData(id)} label="Eliminar perfil fiscal" />
            </UnSubscribeModal>
        )
    }

    const [deleteAddress] = useMutation(MUTATION_DELETED_ADDRESS, {
        onCompleted: data => {
            if(data.deleteAddress){
                getStudent({ variables: { id: localStorage.getItem('b360-role_id') } })
            }
        }
    })

    const onSubmitDeleteAddress = async (id) => {
        await deleteAddress({ variables: { id } });
        modal.close();
    }

    const onModalDeleteAddress = (id) => {
        modal.open(
            <UnSubscribeModal withoutIcon={true}>
                <LabelNormal color="#060809" fontSize="22">¿Seguro de que deseas eliminar esta dirección de envío?</LabelNormal>
                <ButtonPrime onClick={() => onSubmitDeleteAddress(id)} label="Eliminar dirección de envío" />
            </UnSubscribeModal>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="perfil" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title>Perfil</Title>
                                <div style={{marginTop: 10, marginBottom: 39}}>
                                </div>
                            </div>
                            <div className={'col-md-7'}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <InlineContainer>
                                            <div>
                                                {!loadingSkeleton ?
                                                <>
                                                    <Avatar src={photo && !file.name ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}` : file.name ? photo : loadPhotoProfileGender(gender)} height="161" width="161" borderRadius="30" />
                                                    <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>Miembro desde</LabelNormal>
                                                    <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>{moment(createdAt).format('DD/MM/YYYY')}</LabelNormal>
                                                </>
                                                :
                                                <div>
                                                    <Skeleton height={161} borderRadius={27} width={161} baseColor={'#EEEEF2'} highlightColor={'#FFF'} />
                                                    <center>
                                                        <Skeleton count={1} baseColor={'#F6F6F8'} width={97} height={27} borderRadius={5} style={{marginTop: 10}} highlightColor={'#FFF'} />
                                                    </center>
                                                </div>
                                                }
                                            </div>
                                            {!loadingSkeleton ?
                                            <div style={{marginLeft: 35}}>
                                                <InlineContainer style={{alignItems: 'center'}}>
                                                    <LabelNormal fontSize="30" color="#3C4549" style={{marginRight: 20}}>{`${firstName} ${lastName}`}</LabelNormal>
                                                    <Link to={`/estudiante/perfil/${localStorage.getItem('b360-role_id')}`}>
                                                        <FeatherIcon icon="edit" color="#3C4549" />
                                                    </Link>
                                                </InlineContainer>
                                                <InlineContainer>
                                                    <div className='row'>
                                                        <div className='col-md-7'>
                                                            <InlineContainer>
                                                                <FeatherIcon icon="user" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{gender}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <InlineContainer>
                                                                <FeatherIcon icon="phone" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{phone}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="mail" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{email}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="map-pin" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{country}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                    </div>
                                                </InlineContainer>
                                                <LabelUnderline color="#757575" fontSize="17" onClick={() => setChangePassword(!changePassword)} label={'Cambiar contraseña'}/>
                                            </div>
                                            :
                                            <div style={{marginLeft: 35, marginTop: 10, width: '100%'}}>
                                                <div>
                                                    <Skeleton baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                </div>
                                                <div style={{marginTop: 10}}>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{marginTop: 10}}>
                                                        <div className='col-md-5'>
                                                            <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{marginTop: 10}}>
                                                        <div className='col-md-4'>
                                                            <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </InlineContainer>
                                    </div>
                                    {!changePassword ?
                                        <div className='col-md-12' style={{marginTop: 47}}>
                                            {!loadingSkeleton ?
                                            <>
                                                <LabelNormal fontSize="22" color="#454D58">Casos asignados</LabelNormal>
                                                <HeaderCard style={{display: 'block'}}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">Paciente</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">ID</LabelNormal>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">Status</LabelNormal>
                                                        </div>
                                                    </div>
                                                </HeaderCard>
                                                
                                                <CardContainer>
                                                    {treatments.length > 0 ?
                                                        <>
                                                            {treatments.map(item => (
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <Link to={`/estudiante/caso/${item.patient.id}`}>
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.patient.first_name} ${item.patient.last_name}`}</LabelNormal>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <LabelNormal color="#757575" style={{textAlign: 'center'}} fontSize="15">{item.id}</LabelNormal>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.treatmentStatus.name}</LabelNormal>
                                                                    </div>
                                                                </div>
                                                            )) }
                                                        </>
                                                    :
                                                        <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin casos registrados</LabelNormal>
                                                    }
                                                </CardContainer>
                                            </>
                                            :
                                                <>
                                                    <div style={{marginBottom: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={27} width={'50%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                    <Skeleton baseColor={'#EEEEF2'} height={50} width={'100%'} style={{borderTopLeftRadius: 27, borderTopRightRadius: 27}} highlightColor={'#FFF'} />
                                                    <Skeleton baseColor={'#EEEEF2'} height={110} width={'100%'}style={{borderBottomRightRadius: 27, borderBottomLeftRadius: 27}} highlightColor={'#FFF'} />
                                                </>
                                            }
                                        </div>
                                    :
                                        <div className='col-md-12' style={{marginTop: 40}}>
                                            <LabelNormal color="#3C4549" fontSize="22" style={{letterSpacing: '2%', margin: 0}}>Cambio de contraseña</LabelNormal>
                                            <div style={{marginTop: 15}}>
                                                <Input label="Nueva contraseña" placeholder="Contraseña" type="password" value={password} onChange={(e) => setPassword(e.target.value)} validation="passwordlenght" style={{borderColor: (password != "" && confirmPass != ""  && password !== confirmPass) ? '#C60E02' : '', borderWeight: 1, borderstyle: 'solid', maxWidth: 305}}/>
                                                <div style={{marginTop: 20}}>
                                                    <Input label="Confirmar contraseña" placeholder="Contraseña" type="password" onChange={(e) => setConfirmPass(e.target.value)} style={{borderColor: (password != "" && confirmPass != ""  && password !== confirmPass) ? '#C60E02' : (password.length >= 8 && confirmPass != "") ? '#169D00' : '', borderWeight: 1, borderstyle: 'solid', maxWidth: 305}} />
                                                    {(password != "" && password.length >= 8 && confirmPass != "" && password !== confirmPass) && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Contraseñas no coinciden</p>}
                                                    {(password != "" && password.length >= 8 && confirmPass != "" && password === confirmPass) && <p style={{color: '#169D00', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Contraseñas coinciden</p>}
                                                </div>
                                            </div>
                                            <InlineContainer style={{marginTop: 25, alignItems: 'center'}}>
                                                <ButtonPrime label="Guardar cambios" disabled={password != "" && password.length >= 8 && confirmPass != "" && password !== confirmPass} maxWidth="198px" onClick={() => updatePassword({ variables: { password, password_confirmation: confirmPass }})}/>
                                                <ButtonPrime label="Cancelar" background="transparent" color="#3C4549" border="1px solid #3C4549" minWidth="127" style={{marginLeft: 20}} onClick={() => setChangePassword(false)}/>
                                            </InlineContainer>
                                        </div>
                                    }
                                    {!loadingSkeleton ?
                                    <>
                                    {!changePassword &&
                                    <div className='col-md-12'>
                                        <div className='bootstrap-wrapper' style={{marginTop: 66}}>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <LabelNormal color="#454D58" fontSize="22">Perfiles fiscales</LabelNormal>
                                                    {taxDatas.length > 0 &&
                                                        <ScrollHorizontal>
                                                            {taxDatas.reverse().map(row => (
                                                                <CardInlineContainer style={{borderRadius: 10, minHeight: 204, maxWidth: 334, marginTop: 17, marginBottom: 18, padding: 16}} className={row.default ? 'gradiant-border' : ''} key={Math.random()}>
                                                                    <LabelNormal color="#3C4549" fontSize="18" style={{marginBottom: 10}}>{row.business_name}</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="16" style={{marginBottom: 10}}>{row.rfc}</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="16" style={{marginBottom: 10, width: 302}}>{`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}</LabelNormal>
                                                                    <LabelNormal color="#3C4549" fontSize="16" style={{marginBottom: 10}}>{row.cfdiUsage.name}</LabelNormal>
                                                                    <InlineContainer>
                                                                        <LinkUnderline label={"Editar"} link={`/estudiante/editar-perfil-fiscal/${row.id}`} data={{...row}}/>
                                                                        {taxDatas.length > 1 &&
                                                                        <LabelUnderline color="#757575" fontSize="17" onClick={() => onModalDeleteTaxData(row.id)} label={'Eliminar'}/>
                                                                        }
                                                                    </InlineContainer>
                                                                </CardInlineContainer>
                                                            ))}
                                                        </ScrollHorizontal>
                                                    }
                                                    <LinkUnderline label={"Añadir perfil"} link={'/estudiante/agregar-perfil-fiscal'} letterSpacing={'2%'}/>
                                                </div>
                                                <div className='col-md-12' style={{marginTop: 36}}> 
                                                    <LabelNormal color="#454D58" fontSize="22">Direcciones de envío</LabelNormal>
                                                    {addresses.length > 0 &&
                                                        <ScrollHorizontal>
                                                            {addresses.reverse().map(row => (
                                                                <CardInlineContainer style={{borderRadius: 10, minHeight: 133, maxWidth: 334, marginTop: 17, marginBottom: 18, padding: 16}} className={row.default ? 'gradiant-border' : ''} key={Math.random()}>
                                                                    <LabelNormal color="#3C4549" fontSize="18" style={{marginBottom: 10}}>{row.name}</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="16" style={{marginBottom: 10, width: 302}}>{`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}</LabelNormal>
                                                                    <InlineContainer>
                                                                        <LinkUnderline label={"Editar"} link={`/estudiante/editar-direccion-envio/${row.id}`} data={{...row}}/>
                                                                        {addresses.length > 1 &&
                                                                            <LabelUnderline color="#757575" fontSize="17" onClick={() => onModalDeleteAddress(row.id)} label={'Eliminar'}/>
                                                                        }
                                                                    </InlineContainer>
                                                                </CardInlineContainer>
                                                            ))}
                                                        </ScrollHorizontal>
                                                    }
                                                    <LinkUnderline label={"Añadir dirección"} link={'/estudiante/agregar-direccion-envio'} letterSpacing={'2%'}/>
                                                </div>
                                                <div className='col-md-12' style={{marginTop: 36}}>
                                                    <LabelNormal color="#454D58" fontSize="22">Tarjetas guardadas</LabelNormal>
                                                    {creditCards.length > 0 &&
                                                        <ScrollHorizontal>
                                                            {creditCards.reverse().map(row => (
                                                                <CardInlineContainer style={{borderRadius: 10, minHeight: 71, maxWidth: 376, marginTop: 17, marginBottom: 18, padding: 16}} className={row.default ? 'gradiant-border' : ''} key={Math.random()}>
                                                                    <InlineContainer>
                                                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 38, width: 50, background: '#FAFAFB'}}>
                                                                            <img src={require(`@images/icon-cc/${row.brand}-transparent.png`)} style={{height: 19, width: 30}} />
                                                                        </div>
                                                                        {/* <LabelNormal color="#757575" fontSize="17">{row.brand.charAt(0).toUpperCase()+row.brand.slice(1)}</LabelNormal> */}
                                                                        <LabelNormal color="#3C4549" fontSize="18">****{row.last_digits}</LabelNormal>
                                                                        {/* {creditCards.length > 1 &&
                                                                            <InlineContainer>
                                                                                <LabelNormal onClick={() => onModalDeleteCreditCard(Id, row.service_id)} color="#757575" fontSize="16" style={{textDecorationLine: 'underline', cursor: 'pointer'}}>Eliminar</LabelNormal>
                                                                            </InlineContainer>
                                                                        } */}
                                                                    </InlineContainer>
                                                                    <LinkUnderline label={"Ver tarjeta"} link={`/estudiante/editar-tarjeta-credito`} card={{...row}}/>
                                                                </CardInlineContainer>
                                                            ))}
                                                        </ScrollHorizontal>
                                                    }
                                                    <LinkUnderline label={"Añadir tarjeta"} link={'/estudiante/agregar-tarjeta-credito'} letterSpacing={'2%'}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    :
                                    <div className='col-md-12'>
                                        <div className='bootstrap-wrapper' style={{marginTop: 66}}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div style={{marginBottom: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={27} width={'50%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                    <Skeleton baseColor={'#EEEEF2'} height={205} borderRadius={10} highlightColor={'#FFF'} />
                                                    <div style={{marginTop: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={20} width={'30%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' style={{marginTop: 36}}>
                                                <div className='col-md-6'>
                                                    <div style={{marginBottom: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={27} width={'60%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                    <Skeleton baseColor={'#EEEEF2'} height={132} borderRadius={10} highlightColor={'#FFF'} />
                                                    <div style={{marginTop: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={20} width={'50%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' style={{marginTop: 36}}>
                                                <div className='col-md-4'>
                                                    <div style={{marginBottom: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={27} width={'90%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                    <Skeleton baseColor={'#EEEEF2'} height={110} borderRadius={10} highlightColor={'#FFF'} />
                                                    <div style={{marginTop: 10}}>
                                                        <Skeleton baseColor={'#EEEEF2'} height={20} width={'40%'} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            {!changePassword &&
                            <div className='col-md-4'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {!loadingSkeleton ?
                                        <>
                                            <HeaderCard style={{justifyContent: 'space-between'}}>
                                                <LabelNormal color="#3C4549" fontSize="17">Estudiante</LabelNormal>
                                                <LabelNormal color="#757575" fontSize="17">{period}º {type}</LabelNormal>
                                            </HeaderCard>
                                            
                                            <CardContainer>
                                                <div className='row'>
                                                    <div className='col-md-8' style={{marginBottom: 30}}>
                                                        <LabelNormal color="#828282" fontSize="16" style={{marginBottom: 10}}>Universidad</LabelNormal>
                                                        <LabelNormal color="#3C4549" fontSize="17">{nameUniversity}</LabelNormal>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <LabelNormal color="#828282" fontSize="16" style={{marginBottom: 10}}>Capacitaciones</LabelNormal>
                                                        {crystAligner &&
                                                            <>
                                                                <DecoratorBlue style={{marginTop: 10}}>
                                                                    <LabelNormal color="#FFF" fontSize="17">Cryst Aligner</LabelNormal>
                                                                </DecoratorBlue>
                                                                <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                                    <LabelNormal color="#BDBDBD" fontSize="13">ACTIVOS</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="17">0</LabelNormal>
                                                                </InlineContainer>
                                                                <InlineContainer style={{marginBottom: 10, justifyContent: 'space-between'}}>
                                                                    <LabelNormal color="#BDBDBD" fontSize="13">TOTALES</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="17">0</LabelNormal>
                                                                </InlineContainer>
                                                            </>
                                                        }
                                                        {smileSystem &&
                                                            <>
                                                                <DecoratorYellow>
                                                                    <LabelNormal color="#FFF" fontSize="17">Smile System</LabelNormal>
                                                                </DecoratorYellow>
                                                                <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                                    <LabelNormal color="#BDBDBD" fontSize="13">ACTIVOS</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="17">0</LabelNormal>
                                                                </InlineContainer>
                                                                <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                                    <LabelNormal color="#BDBDBD" fontSize="13">TOTALES</LabelNormal>
                                                                    <LabelNormal color="#757575" fontSize="17">0</LabelNormal>
                                                                </InlineContainer>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </CardContainer>
                                        </>
                                        :
                                        <div className='row' style={{height: 345}}>
                                            <div className='col-md-12'>
                                                <Skeleton baseColor={'#EEEEF2'} height={50} width={'100%'} style={{borderTopLeftRadius: 27, borderTopRightRadius: 27}} highlightColor={'#FFF'} />
                                                <Skeleton baseColor={'#F6F6F8'} height={'100%'} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}} highlightColor={'#FFF'} />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    {!loadingSkeleton &&
                                    <div className='col-md-12' style={{marginTop: 108, marginBottom: 108, justifyContent: 'center', display: 'flex'}}>
                                        <CloseSession onClick={() => props.logout()}/>
                                    </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(action.logout())
})

export default connect(null, mapDispatchToProps)(Student);