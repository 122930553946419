import { gql } from '@apollo/client';

export const typeDefs = gql`
  input CreatePartialityInput {
    bottom: String
    upper: String
  }

  input UpdatePartialityInput {
    id: Int
    bottom: String
    upper: String
  }
`;
