import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  MUTATION_REQUEST_APPROVAL_STUDENT,
  MUTATION_APPROVED_BY_TEACHER,
} from '@graphql/mutation';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import ButtonPrime from '@components/shared/ButtonPrime';
import { LabelNormal } from '../../shared/Styled';
import ChangesButton from './ChangesButton';
import CorrectFilesButton from './CorrectFilesButton';
import MessagesButton from './MessagesButton';
import NewSmartCheckButton from './NewSmartcheckButton';
import PlanificationButton from './PlanificationButton';
import UploadEvidencesButton from './UploadEvidencesButton';

/**
 * Status Groups
 * - new_case
 * - planning
 * - finished
 */

/** Important status for this section
 * Status of new_case
 * - 2: En espera de pago
 * - 5: Errores reportados
 * - 7: Caso pausado
 * - 9: Mensaje de Borgatta
 *
 * Status of planning
 * - 1: Visor listo
 * - 3: En espera de Smartcheck
 * - 4: Listo para aprobarse
 * - 5: Snartcheck agendado
 * - 7: Día de smartcheck
 * - 8: Smartcheck perdido
 * - 9: Smartcheck finalizado / En espera de planificación
 * - 11: Smartcheck finalizado / Caso listo para aprobarse
 * - 13: Esperando aprobación del profesor
 * - 14: Aprobado por profesor
 *
 * Status of finished
 * - 2: Listo para subir evidencias
 */

const Buttons = ({
  patient,
  treatment,
  role,
  activeBasicChat,
  pendingMessages,
  setPhotoEvidences,
  reload,
}) => {
  const { id: patientId, firstName, lastName } = patient;
  const { type: statusType, index: statusIndex } =
    treatment?.treatmentStatus ?? {};
  const treatmentId = parseInt(treatment?.id) ?? null;
  const treatmentTypeId = parseInt(treatment?.treatmentType?.id) ?? null;
  const moreSmartchecks = treatment?.more_smartchecks ?? false;
  const makeSmartchecks = treatment?.make_smartcheck ?? false;
  const hasChangesCA = treatment?.has_changes_ca;
  const hasChangesSS = treatment?.has_changes_ss;
  const messageDoctor = treatment?.message_doctor;
  const smartChecks =
    treatment?.smartsChecks.filter((sm) => sm.status === 'Pending') ?? [];
  const zoom_link =
    smartChecks.length > 0 ? smartChecks[smartChecks.length - 1].zoom_link : '';

  const [lastPayment, setLastPayment] = useState(null);

  const [sendApprovedTeacher] = useMutation(MUTATION_REQUEST_APPROVAL_STUDENT, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => reload())}>
          <LabelNormal fontSize="22" color="#060809">
            Solicitud de aprobación a profesor completada
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [approvedTeacher] = useMutation(MUTATION_APPROVED_BY_TEACHER, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => reload())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            El estudiante podrá mandar a manufactura cuando apruebe el caso.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onSubmitTeacher = async () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal color="#060809" fontSize="22" style={{ marginBottom: 14 }}>
          Este caso se mandará a producción
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Confirmar este caso aprobará la planificación y el alumno procederá al
          pago para su manufactura.
        </LabelNormal>
        <ButtonPrime
          label="Aprobar caso"
          onClick={() =>
            approvedTeacher({ variables: { patient_id: patientId } })
          }
        />
      </ModalWarning>
    );
  };

  useEffect(() => {
    if (treatment) {
      const lastPaymentObject =
        treatment?.payments?.find(
          (payment) =>
            payment.type === 'manufact' && payment.status === 'pending'
        ) ?? null;
      setLastPayment(lastPaymentObject);
    }
  }, [treatment]);

  const messageButton = (
    <MessagesButton
      role={role}
      patientId={patientId}
      treatmentId={treatmentId}
      messageDoctor={messageDoctor}
      activeBasicChat={activeBasicChat}
      pendingMessages={pendingMessages}
    />
  );

  if (pendingMessages) return messageButton;

  switch (statusType) {
    case 'new_case':
      switch (statusIndex) {
        case 2:
          if (lastPayment)
            return (
              <ChangesButton
                role={role}
                patientId={patientId}
                treatmentTypeId={treatmentTypeId}
                hasChangesSS={hasChangesSS}
                activeBasicChat={activeBasicChat}
              />
            );
          return null;
        case 5:
          return (
            <CorrectFilesButton
              role={role}
              patientId={patientId}
              treatmentId={treatmentId}
              firstName={firstName}
              lastName={lastName}
            />
          );
        case 7:
        case 9:
          if (
            (!hasChangesCA && treatmentTypeId === 1) ||
            (hasChangesSS && treatmentTypeId === 2)
          )
            return messageButton;
          else if (
            (treatmentTypeId === 2 && !hasChangesSS) ||
            (((makeSmartchecks === false && smartChecks.length === 0) ||
              (makeSmartchecks === true && smartChecks.length > 0)) &&
              treatmentTypeId === 1 &&
              hasChangesCA)
          )
            return (
              <ChangesButton
                role={role}
                patientId={patientId}
                treatmentTypeId={treatmentTypeId}
                hasChangesSS={hasChangesSS}
                activeBasicChat={activeBasicChat}
              />
            );
          return null;
        default:
          return null;
      }
    case 'planning':
      switch (statusIndex) {
        case 1:
          return <PlanificationButton role={role} patientId={patientId} />;
        case 3:
          if (treatmentTypeId === 2)
            return (
              <NewSmartCheckButton
                role={role}
                patientId={patientId}
                reschedule={false}
              />
            );
          else return null;
        case 11:
          /* TODO: Validate this logic when smart check will be functional by CA. */
          if (treatmentTypeId === 1 && makeSmartchecks)
            return (
              <NewSmartCheckButton
                role={role}
                patientId={patientId}
                reschedule={false}
              />
            );
          return (
            <ChangesButton
              role={role}
              patientId={patientId}
              treatmentTypeId={treatmentTypeId}
              hasChangesSS={hasChangesSS}
              activeBasicChat={activeBasicChat}
            />
          );
        case 4:
          if (!makeSmartchecks && smartChecks.length === 0)
            return (
              <ChangesButton
                role={role}
                patientId={patientId}
                treatmentTypeId={treatmentTypeId}
                hasChangesSS={hasChangesSS}
                activeBasicChat={activeBasicChat}
              />
            );
          return null;
        case 5:
        case 8:
          return (
            <NewSmartCheckButton
              role={role}
              patientId={patientId}
              reschedule={true}
            />
          );
        case 7:
          return (
            <ButtonPrime
              style={{ padding: '9px 11px' }}
              fontSize={15}
              label="Ir a Smart check"
              onClick={() => window.open(zoom_link, '_blank')}
            />
          );
        case 13:
          if (role === 'teacher') {
            return (
              <ButtonPrime
                style={{ padding: '9px 16px' }}
                fontSize={15}
                label="Aprobar caso"
                onClick={onSubmitTeacher}
              />
            );
          }
          return null;
        case 14:
          if (
            role === 'student' &&
            !makeSmartchecks &&
            smartChecks.length === 0 &&
            treatmentTypeId === 1
          )
            return (
              <ChangesButton
                role={role}
                patientId={patientId}
                treatmentTypeId={treatmentTypeId}
                hasChangesSS={hasChangesSS}
                activeBasicChat={activeBasicChat}
              />
            );
          return null;

        default:
          return null;
      }
    case 'finished':
      switch (statusIndex) {
        case 2:
          return (
            <UploadEvidencesButton
              patientId={patientId}
              statusType={statusType}
              statusIndex={statusIndex}
              setPhotoEvidences={setPhotoEvidences}
              reload={reload}
            />
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export default Buttons;
