import {
  CardInlineContainerTrans,
  InlineContainer,
  LabelNormal,
  Tag,
} from '../shared/Styled';

const TransferInfo = ({ bankName, clabe, referenceCode, treatment }) => (
  <InlineContainer key={Math.random()}>
    <CardInlineContainerTrans
      style={{
        borderRadius: 10,
        minWidth: 320,
        maxWidth: '350px',
        padding: 16,
        margin: 0,
        borderColor: '#757575',
        borderWidth: '2px',
      }}
    >
      <LabelNormal color="#757575" fontSize="16" style={{ marginBottom: 10 }}>
        <b>CLABE:</b> {clabe}
      </LabelNormal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'center',
          fontSize: '16px',
          color: '#757575',
          fontFamily: 'neue-montreal-regular',
          margin: '5px',
          marginBottom: 10,
        }}
      >
        <b>Referencia:</b> {referenceCode}{' '}
        <Tag type="danger">* Obligatoria</Tag>
      </div>
      <LabelNormal color="#757575" fontSize="16" style={{ marginBottom: 10 }}>
        <b>Banco:</b>{' '}
        {bankName ? `${bankName[0]}${bankName.slice(1).toLowerCase()}` : ''}
      </LabelNormal>
      <div
        style={{
          fontSize: '16px',
          fontFamily: 'neue-montreal-regular',
          color: '#757575',
          margin: '5px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        <b>Nombre:</b>{' '}
        {treatment === 'cryst_aligner'
          ? 'Maquiladora y Distribuidora Dental S.A. de C.V.'
          : 'Dental Trade S.A. de C.V.'}
      </div>
    </CardInlineContainerTrans>
  </InlineContainer>
);

export default TransferInfo;