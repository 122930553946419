import Card from '../shared/Card';
import { CardContainer, HeaderCard, LabelNormal } from '../shared/Styled';
import SkeletonDetails from './detailsComponents/SkeletonDetails';
import DetailsBody from './detailsComponents/DetailsBody';

const Details = ({
  patient,
  treatment,
  activeBasicChat,
  loadingSkeleton,
  pendingMessages,
  setPhotoEvidences,
  reload = () => {},
}) => {
  return (
    <div
      className="col-md-12"
      style={{ filter: 'drop-shadow(0px 0px 4px rgba(162, 162, 162, 0.1))' }}
    >
      <Card padding="0px" border="none" minWidth="340px" borderRadius="30px">
        <HeaderCard>
          <LabelNormal
            color="#3C4549"
            fontSize="17"
            style={{
              fontFamily: 'neue-montreal-medium',
              margin: 0,
            }}
          >
            Detalles de caso
          </LabelNormal>
        </HeaderCard>
        <CardContainer>
          <div
            className="row"
            style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
          >
            {!loadingSkeleton ? (
              <DetailsBody
                patient={patient}
                treatment={treatment}
                activeBasicChat={activeBasicChat}
                pendingMessages={pendingMessages}
                setPhotoEvidences={setPhotoEvidences}
                reload={reload}
              />
            ) : (
              <SkeletonDetails />
            )}
          </div>
        </CardContainer>
      </Card>
    </div>
  );
};

export default Details;
