import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '')};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  gap: ${({ gap }) => (gap ? gap : '')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0')}px;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  padding-left: ${({ paddingLeft }) => paddingLeft ?? '0'};
  padding-right: ${({ paddingRight }) => paddingRight ?? '0'};
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const LabelTitle = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  padding: 24px 0 8px 0;
  margin-top: 24px;
  border-top: 1px solid #9e9e9e;
`;

export const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
`;

export const ToothContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'neue-montreal-regular';
  color: #9e9e9e;
  height: 21px;
  width: 30px;
`;

export const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

export const LabelMuted = styled.span`
  color: #828282;
  font-family: neue-montreal-regular;
  font-size: 16px;
  margin: 10px 0;
`;

export const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

export const LabelOrange = styled.p`
  font-family: neue-montreal-regular;
  font-size: 17px;
  color: #eb9542;
`;

export const LabelDescription = styled.p`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #757575;
  margin: 0;
`;

export const LabelAnotation = styled.span`
  font-size: 13px;
  color: #757575;
  font-family: 'neue-montreal-regular';
  min-height: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputComponent = styled.input`
  background: ${({ backgroundColor, disabled }) =>
    backgroundColor && !disabled
      ? backgroundColor
      : disabled
      ? 'dark(rgba(239, 239, 239, 0.3))'
      : '#FFF'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-top: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-right: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-bottom: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-left: ${({ mask, borderColor }) =>
    mask === 'credit_card'
      ? 'none'
      : `1px solid ${borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'}`};
  border-radius: ${({ mask }) =>
    mask === 'credit_card' ? '0 16px 16px 0' : '16px'};
  transition: all 0.3s ease-out;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 20px;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "'neue-montreal-regular'"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '8')}px;
  ${({ readOnly }) =>
    readOnly === undefined
      ? `&:focus {
            outline: none;
            border: 1px solid #E3772D;
            ::placeholder {
            }
        }
        &:hover {
            outline: none;
            border: 1px solid #E3772D;
        }`
      : `cursor: no-drop;

        &:focus {
            outline: none;
        }`}
`;

export const ErrorMessage = styled.p`
  color: #c60e02;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: 'neue-montreal-regular';
  min-height: 24px;
`;

export const DownloadButton = styled.button`
  border: none;
  background: #eb9542;
  height: ${({ height }) => height ?? '48px'};
  width: ${({ width }) => width ?? '48px'};
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  &:hover {
    background: #d97617;
  }
  &:focus {
    background: #ab5d12;
  }
`;
export const InvoiceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 20px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
  border-radius: 30px;
  width: 380px;
  &.current {
    outline: 4px solid #f2b559 !important;
  }
`;

export const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

export const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

export const Tag = styled.p`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${({ type }) =>
    type
      ? type === 'danger'
        ? '#FFE5E3'
        : type === 'warning'
        ? '#F9EECA'
        : ''
      : '#E0E0E0'};
  padding: ${({ padding }) => padding ?? '2px 8px'};
  margin: 0;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  letter-spacing: 2%;
  line-height: 16.2px;
  font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '6px'};
  color: ${({ type }) =>
    type
      ? type === 'danger'
        ? '#C60E02'
        : type === 'warning'
        ? '#6B4D00'
        : ''
      : '#757575'};
  border: 1px solid
    ${({ type }) =>
      type
        ? type === 'danger'
          ? '#C60E02'
          : type === 'warning'
          ? '#6B4D00'
          : ''
        : '#E0E0E0'};
  width: max-content;
`;

export const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

export const CardInlineContainerTrans = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  display: inline-block;
  margin: 0px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
`;

export const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
  white-space: nowrap;
`;

export const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
  white-space: nowrap;
`;

export const ChipCircle = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1aa5e0;
  margin-left: 11px;
`;

export const ChipCircleSecond = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1aa5e01f;
  margin-left: 11px;
`;

export const ChipCircleLong = styled.div`
  width: 100%;
  height: 43px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1aa5e0;
  margin-left: 11px;
`;

export const CustomHR = styled.hr`
  border: none;
  height: 1px;
  background-color: #757575;
`;

export const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 8;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 49px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
`;

export const ScrollableBody = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

export const HeaderCardWithDivision = styled.div`
  background: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-bottom-width: 0.7px;
  border-bottom-style: solid;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

export const CardImg = styled.img`
  width: 100%;
  height: 190px;
  border-radius: 6px;
  background-position: cover;
  padding: 10px;
  objectfit: cover;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 64px;
`;

export const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 380px;
  min-height: 143px;
  margin-right: 50px;
`;

export const CardModelExtended = styled.div`
  border: 2px solid transparent;
  border-radius: 10.1164px;
  max-width: 380px;
  min-height: 130px;
  margin-right: 50px;
  margin-top: 20px;
`;

export const StateContainer = styled.div`
  background: ${({ background }) => (background ? background : '#1DBB24')};
  padding-left: 12px;
  padding-right: 12px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  white-space: nowrap;
`;

export const OverflowDiv = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Divider = styled.hr`
  color: #bdbdbd;
`;

export const CategoryCard = styled.div`
  background: ${({ background }) => (background ? background : '#828282')};
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding ?? '8px 14px'};
`;
