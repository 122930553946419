import styled from "styled-components";
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const DataErrorFont = styled.div`
  font-size: 17px;
  font-family: neue-montreal-regular;
  color: #C60E02;
  width: 250px;
`;

const EditProfileForm = ({ 
  firstName, 
  setFirstName, 
  disabled, 
  lastName, 
  setLastName,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  gender,
  setGender,
  countryName,
  phone,
  setPhone,
  email,
  setEmail,
  onSubmitUpdateDoctor
}) => {
  return (
    <div className="bootstrap-wrapper">
      <div style={{ marginTop: 38 }} className="grid-layout">
        <InlineContainer>
          <div>
            <Input
              placeholder="Nombre"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="Nombres"
              style={{
                borderColor:
                  disabled && firstName === ''
                    ? '#C60E02'
                    : '#D1D1D1',
              }}
            />
            {disabled && firstName === '' && (
              <DataErrorFont> *Introduce el nombre(s) </DataErrorFont>
            )}
          </div>
          <div>
            <span
              style={{ fontSize: 18, visibility: 'hidden' }}
            >
              none
            </span>
            <Input
              placeholder="Apellidos"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{
                borderColor:
                  disabled && lastName === ''
                    ? '#C60E02'
                    : '#D1D1D1',
              }}
            />
            {disabled && lastName === '' && (
              <DataErrorFont>*Introduce los apellidos</DataErrorFont>
            )}
          </div>
        </InlineContainer>
      </div>
      <div>
        <LabelInput>Género</LabelInput>
        <InlineContainer>
          <div className="radio" style={{ marginRight: 20 }}>
            <input
              id="radio-1"
              onClick={(e) => setGender('Masculino')}
              checked={ gender === 'Masculino' ? 'checked' : false }
              name="gender"
              type="radio"
            />
            <label for="radio-1" className="radio-label">
              Masculino
            </label>
          </div>
          <div className="radio">
            <input
              id="radio-2"
              onClick={(e) => setGender('Femenino')}
              checked={ gender === 'Femenino' ? 'checked' : false }
              name="gender"
              type="radio"
            />
            <label for="radio-2" className="radio-label">
              Femenino
            </label>
          </div>
        </InlineContainer>
      </div>
      <div className="col-md-6" style={{ marginTop: 28, paddingLeft: 0 }}>
        <LabelInput>Fecha de nacimiento</LabelInput>
          <InlineContainer style={{ marginTop: 5 }}>
            <Input
              placeholder="DD"
              maxWidth="72px"
              value={day}
              onChange={(e) => setDay(e.target.value)}
              style={{ textAlign: 'center' }}
              type="number"
            />
            <Input
              placeholder="MM"
              maxWidth="72px"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              style={{ textAlign: 'center' }}
              type="number"
            />
            <Input
              placeholder="AAAA"
              maxWidth="90px"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={{ textAlign: 'center' }}
              type="number"
            />
          </InlineContainer>
        </div>
      <div style={{ marginTop: 28 }}>
        <LabelInput>Teléfono</LabelInput>
        <InlineContainer>
          <Input
            readOnly
            disabled
            value={
              countryName !== null
                ? countryName === 'México'
                  ? '+52'
                  : countryName === 'Panamá'
                    ? '+507'
                    : '+502'
                : ''
            }
            backgroundColor="#F6F6F8"
            maxWidth={countryName === 'México' ? '68px' : '75px'}
          />
          <Input
            placeholder="Teléfono"
            value={phone}
            onChange={setPhone}
            name="phone"
            mask="phone"
            validation="phone"
            style={{
              borderColor:
                disabled && phone === ''
                  ? '#C60E02'
                  : '#D1D1D1',
            }}
          />
        </InlineContainer>
        {disabled && phone === '' && (
          <DataErrorFont>*Introduce tu teléfono</DataErrorFont>
        )}
      </div>
      <div style={{ marginTop: 28 }} className="row">
        <div className="col-md-5">
          <Input
            placeholder="Tu correo"
            label="Correo"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
              style={{
                borderColor:
                  disabled && email === ''
                    ? '#C60E02'
                    : '#D1D1D1',
              }}
          />
          {disabled && email === '' && (
            <DataErrorFont>*Introduce tu correo</DataErrorFont>
          )}
        </div>
      </div>
      <div style={{ marginTop: 31, marginBottom: 41 }}>
        <ButtonPrime
          label="Guardar cambios"
          onClick={() => onSubmitUpdateDoctor()}
        ></ButtonPrime>
      </div>
    </div>
  )
};

export default EditProfileForm;