import React, { useEffect, useRef, useState } from 'react';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import Checkbox from 'react-custom-checkbox';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import {
  MUTATION_CREATE_CREDIT_CARD,
  MUTATION_CREATE_CREDIT_CARD_V2,
} from '@graphql/mutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalUnSubscribe from '@components/Modal/ModalUnSubscribe';
import { getTypeCard, validateCVV, validateExpDate } from '../../utils';
import ButtonClose from '../shared/ButtonClose';
import ModalError from './ModalError';

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const OPTION = {
  style: {
    base: {
      fontSize: '17px',
      color: '#3C4549',
      letterSpacing: '0.02em',
      background: '#FFF',
      '::placeholder': {
        color: '##828282',
      },
    },
    invalid: {
      color: '#3C4549',
    },
  },
};

const ModalFormCard = (props) => {
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expDate, setExpDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [defaul, setDefault] = useState(false);
  const [brand, setBrand] = useState('');
  const [loading, setLoading] = useState(false);
  const [validForm, setvalidForm] = useState(false);

  const [createCardV2] = useMutation(MUTATION_CREATE_CREDIT_CARD_V2, {
    onCompleted: (data) => {
      if (data.addDoctorCardV2) {
        modal.close();
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => props.onFinish())}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Tarjeta agregada con éxito
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Ahora la podrás utilizar para pagar tus tratamientos.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      let errorMessage = '';
      if (graphQLErrors?.length > 0) {
        errorMessage = graphQLErrors[0].message;
      } else if (networkError)
        errorMessage =
          'Lo sentimos, no se puede procesar la transacción debido a una falla en tu conexión. Por favor, verifícala e inténtalo de nuevo.';
      modal.close();
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
          >
            Tarjeta no guardada
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Hubo un error al procesar tu tarjeta. Verifica tus datos e inténtalo
            de nuevo.
          </LabelNormal>
          <div
            style={{
              backgroundColor: '#FFE5E3',
              padding: '15px',
              borderRadius: 13,
              color: '#C60E02',
            }}
          >
            <p style={{ fontWeight: '600' }}>Error de sistema:</p>
            <p style={{ fontWeight: '600' }}>{errorMessage}</p>
          </div>
        </ModalError>
      );
    },
  });

  const onSubmit = async () => {
    console.log(props.treatmentType);
    modal.open(
      <ModalWaiting>
        <LabelNormal color="#060809" fontSize="22" style={{ marginBottom: 14 }}>
          Procesando...
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginBottom: 14 }}>
          Un momento por favor...
        </LabelNormal>
      </ModalWaiting>
    );
    createCardV2({
      variables: {
        user_id: localStorage.getItem('b360-id'),
        card_name: cardName,
        card_number: cardNumber,
        exp_month: parseInt(expDate.split('/')[0]),
        exp_year: parseInt(`20${expDate.split('/')[1]}`),
        cvv: cvv,
        default: defaul,
        treatment_type: parseInt(props.treatmentType),
      },
    });
  };

  const getBrand = () => {
    setBrand(getTypeCard(cardNumber));
  };

  const validateForm = () => {
    if (
      cardName === '' ||
      cardNumber.length < 19 ||
      brand === '' ||
      !validateExpDate(expDate) ||
      !validateCVV(brand, cvv)
    ) {
      setvalidForm(false);
    } else {
      setvalidForm(true);
    }
  };

  useEffect(() => {
    if (cardNumber !== '') getBrand();
    else setBrand('');
  }, [cardNumber]);

  return (
    <div
      className="modal"
      style={{ maxWidth: 855, height: '100%', margin: 74 }}
    >
      <Inline>
        <ButtonClose onClick={() => modal.close()} />
      </Inline>
      <div className="bootstrap-wrapper">
        <div className="row">
          <div className="col-md-11">
            <Title style={{ marginBottom: 10 }}>Tarjetas guardadas</Title>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 39 }}
            >
              Agrega tarjetas para utilizarlas en tus pagos
            </LabelNormal>
            <LabelNormal color="#454D58" fontSize="22">
              Añadir tarjeta
            </LabelNormal>
          </div>
          <div class="col-md-12">
            <div className="bootstrap-wrapper">
              <div style={{ marginTop: 20 }} className="row">
                <div className="col-md-8">
                  <Input
                    placeholder="Nombre y apellidos"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    onBlur={validateForm}
                    label="Nombre y apellidos (como aparece en el plástico)"
                    maxWidth="65%"
                  />
                </div>
              </div>
              <div style={{ marginTop: 20 }} className="row">
                <div className="col-md-7">
                  <LabelNormal color="#3C4549" fontSize="18">
                    Número de tarjeta
                  </LabelNormal>
                  <div className="row" style={{ alignItems: 'flex-start' }}>
                    <div
                      className="col-md-2"
                      style={{
                        margin: 0,
                        marginLeft: 20,
                        padding: 0,
                        maxWidth: 60,
                      }}
                    >
                      <div
                        style={{
                          height: 52,
                          background: '#F6F6F8',
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {brand !== '' && brand !== 'unknown' && (
                          <img
                            src={require(`@images/icon-cc/${brand}-transparent.png`)}
                            style={{ height: 19, width: 28 }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-7" style={{ margin: 0, padding: 0 }}>
                      <Input
                        mask="credit_card"
                        style={{ marginTop: 0 }}
                        validation="credit_card"
                        value={cardNumber}
                        onChange={setCardNumber}
                        onBlur={validateForm}
                        placeholder="Número de tarjeta"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20 }} className="row">
                <div className="col-md-8">
                  <div
                    className="row"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className="col-md-4">
                      <LabelNormal color="#3C4549" fontSize="18">
                        Vencimiento
                      </LabelNormal>
                      <Input
                        mask="exp_date"
                        validation="exp_date"
                        value={expDate}
                        onChange={setExpDate}
                        onBlur={validateForm}
                        placeholder="Mes / año"
                      />
                    </div>
                    <div className="col-md-4">
                      <LabelNormal color="#3C4549" fontSize="18">
                        CVV
                      </LabelNormal>
                      <Input
                        mask="cvv"
                        validation="cvv"
                        value={cvv}
                        onChange={setCvv}
                        onBlur={validateForm}
                        placeholder="CVV"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ margin: 0, paddingTop: 28 }} className="row">
                <Checkbox
                  checked={defaul}
                  icon={
                    <FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />
                  }
                  borderColor="#C8C8C8"
                  style={{ overflow: 'hidden' }}
                  size={20}
                  onChange={(value) => setDefault(value)}
                  labelStyle={{
                    marginLeft: 5,
                    userSelect: 'none',
                    fontFamily: 'neue-montreal-regular',
                    color: '#3C4549',
                  }}
                  label="Definir como tarjeta predeterminada"
                />
              </div>
              <div style={{ marginTop: 31, marginBottom: 41 }}>
                <ButtonPrime
                  label={'Guardar tarjeta'}
                  disabled={loading}
                  onClick={onSubmit}
                ></ButtonPrime>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(
  'pk_test_51KH8C4Ffg8Tlm1Hi029lR33oAYxvR530Kgs2UYRAGJY3RFb7F6aus0LZGHXs1kIOpwkwtDZgi8lhxY1cWFYNjAyn00pYmhD5Lj'
);

const ProviderModalCard = (props) => (
  <Elements stripe={stripePromise}>
    <ModalFormCard {...props} />
  </Elements>
);

export default ProviderModalCard;
