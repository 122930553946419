import React, { useEffect, useState } from 'react';
import { MainLogin, ImgLogin, SpanLogin, H2Login, InputContainer, SpanInput } from '@components/Login/Login.style';
import ButtonPrime from '@components/shared/ButtonPrime';
import InputSet from '@components/shared/InputSet';
import InputPassword from '@components/shared/InputPassword';
import LoginImg from '@images/Union.svg';
import LinkUnderline from '@components/shared/LinkUnderline';
import { useMutation } from '@apollo/client';
import { MUTATION_LOGIN } from '@graphql/mutation';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';

const Login = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [getLogin] = useMutation(MUTATION_LOGIN, {
        onCompleted: data => {
            if(data.login){
                props.login(data.login.token, data.login.type, data.login.user_id, data.login.role_id);
            }
        },
        onError: err => {
            setError(true);
        }
    })

    const validationPrev = () => {
        if(email === ''){
            setDisabled(true);
        }else if(password === ''){
            setDisabled(true);
        }else{
            setDisabled(false);
            getLogin({ variables: { email, password } })
        }
    }

     return (
        <MainLogin>
            <ImgLogin src={LoginImg} />
            <H2Login>Bienvenido a B360</H2Login>
            <SpanLogin>Ingresa tu correo y contraseña para<br />acceder a la plataforma</SpanLogin>
            <InputContainer>
                <InputSet inputLabel="Email" placeholder="Tu Correo" type="email" name="email" onChange={(e) => setEmail(e.target.value)}/>
                {(email === '' && disabled) && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Introduce tu correo</p>}
            </InputContainer>
            <InputContainer>
                <SpanInput>Contraseña</SpanInput>
                <InputPassword placeholder="Tu contraseña" name="password" onChange={(e) => setPassword(e.target.value)} style={error ? {borderColor: '#C60E02', borderWeight: 1, borderstyle: 'solid'} : {}} />
                {(password === '' && disabled) && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Introduce una contraseña</p>}
                {error && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Correo o contraseña inválido</p>}
            </InputContainer>
            <ButtonPrime label="Iniciar sesión" onClick={validationPrev} loading={true}/>
            <LinkUnderline label={"¿Olvidaste tu contraseña?"} link={`/auth/recuperar-contrasena`} marginTop={35}/>
        </MainLogin>
    );
}


const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
    login: (token, type, id, role_id) => dispatch(action.loginSuccess(token, type, id, role_id))
});

export default connect(null, mapDispatchToProps)(Login);