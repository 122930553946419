import Step from './Step';

const Stepper = ({ steps, setSteps, currentStep, lastStep }) => {
  return (
    <ul
      className="clr-timeline clr-timeline-horizontal"
      style={{ marginBottom: 30 }}
    >
      {steps.map((step) => (
        <Step
          index={step.name}
          title={step.title}
          currentStep={currentStep}
          setSteps={setSteps}
          lastStep={lastStep}
          totalSteps={steps.length}
        />
      ))}
    </ul>
  );
};

export default Stepper;
