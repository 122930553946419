import Skeleton from "react-loading-skeleton";
import { InlineContainer, LabelNormal } from "../../shared/Styled";

const SkeletonDetails = () => (
  <div className="row" style={{ marginLeft: 10 }}>
    <div className="col-md-9">
      <LabelNormal color="#828282" fontSize="16" style={{ marginBottom: 10 }}>
        Tratamiento
      </LabelNormal>
      <Skeleton
        baseColor={'#EEEEF2'}
        height={37}
        width={'70%'}
        borderRadius={5}
        highlightColor={'#FFF'}
      />
    </div>
    <div className="col-md-12">
      <LabelNormal
        color="#828282"
        fontSize="16"
        style={{ marginBottom: 10, marginTop: 30 }}
      >
        Status del caso
      </LabelNormal>
      <InlineContainer style={{ alignItems: 'center' }}>
        <Skeleton
          baseColor={'#EEEEF2'}
          height={14}
          circle
          width={14}
          highlightColor={'#FFF'}
          style={{ marginRight: 15 }}
        />
        <Skeleton
          baseColor={'#EEEEF2'}
          height={23}
          width={150}
          borderRadius={5}
          highlightColor={'#FFF'}
        />
      </InlineContainer>
    </div>
  </div>
);

export default SkeletonDetails
