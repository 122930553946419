import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import {
  QUERY_GET_DATA_POSTAL_CODE,
  QUERY_GET_DETAILS_DOCTOR,
  QUERY_GET_COUNTRIES
} from '@graphql/query';
import Input from '@components/shared/Input';
import SelectCustomAddress from '@components/shared/SelectCustomAddress';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonPrime from '@components/shared/ButtonPrime';
import { validateZipCode, validateURL } from '../../utils';
import ButtonClose from '../shared/ButtonClose';
import axios from 'axios';
import _ from 'lodash';
import B360NetworkDoctorOfficeForm from '../Doctor/B360NetworkDoctorOfficeForm';
import { useForm } from 'react-hook-form';
import NumberInput from '../shared/inputs/NumberInput';
import { Column, Row } from '../shared/Styled';

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
  margin-left: 0px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModalFormSurgery = (props) => {
  const { control, formState: { errors, isValid }, clearErrors, setValue, getValues, trigger } = useForm({
    defaultValues: {
      cedula: ''
    },
    mode: 'onBlur'
  })
  const [submit, setSubmit] = useState(0)
  const [country, setCountry] = useState(null)
  const [countries, setCountries] = useState([])
  const [validDoctorOffice, setValidDoctorOffice] = useState(true)
  const [doctorOfficeData, setDoctorOfficeData] = useState({})
  const [officeDefaultValues, setOfficeDefaultValues] = useState(null)
  const [addressDefaultValues, setAddressDefaultValues] = useState(null)

  const [getCountries] = useLazyQuery(QUERY_GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCountries(data.countries.data);
    },
  });

  const onSubmitForm = async () => {
    setSubmit(submit + 1)
    if (validDoctorOffice && isValid) {
      const { street, number, internal_number, state, locality, postal_code, clinic_name: name, city, references, clinic_phone: phone, alternative_phone: otherPhone, website: url } = doctorOfficeData
      const cedula = getValues('cedula')
      const data = {
        name,
        phone,
        otherPhone,
        street,
        number,
        internal_number,
        state,
        locality,
        suburb: city?.value ?? '',
        postal_code,
        url,
        references,
        cedula: cedula,
        doctor_id: props.doctorId
      }

      const token = localStorage.getItem('token-b360');
      const instance = axios.create({
        baseURL: process.env.REACT_APP_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      try {
        if (props.data && props.data.id) {
          await instance.post(`/api/surgery/update/${props.data.id}`, data);
        } else {
          await instance.post(`/api/surgery/create`, data);
        }
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => props.reloadList())}>
            <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
              {props.data
                ? 'Cambios guardados en perfil de Dr.Finder'
                : 'Perfil Dr. Finder guardado con éxito.'}
            </LabelNormal>
            {!props.data && (
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 10 }}
              >
                {'Ahora aparecerá en el buscador de '}
                <a
                  style={{ color: '#4F4F4F', textDecoration: 'underline' }}
                  rel="noopener noreferrer"
                  href="https://encuentra-tu-doctor.b360.borgatta.com.mx"
                  target="_blank"
                >
                  encuentra-tu-doctor.b360.borgatta.com.mx
                </a>
              </LabelNormal>
            )}
          </ModalSuccess>
        )
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (props.data) {
      setValue('cedula', props.data.cedula)
      setOfficeDefaultValues({
        clinic_name: props.data.name,
        website: props.data.url,
        clinic_phone: props.data.phone,
        alternative_phone: props.data.otherPhone ?? ''
      })
      setAddressDefaultValues({
        street: props.data.street,
        number: props.data.number,
        internal_number: props.data.internal_number ?? '',
        postal_code: props.data.postal_code,
        city: props.data.suburb ?? '',
        locality: props.data.locality ?? '',
        state: props.data.state,
        timezone: '',
        timezone_name: '',
        references: props.data.references
      })
      trigger()
    }
  }, [props.data])

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    onCompleted: (data) => {
      if (data.doctor) {
        setCountry(data.doctor.user.country)
        if (!props.data) {
          setValue('cedula', data.doctor.professional_license)
          trigger()
        }
      }
    }
  })

  useEffect(() => {
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    })
    getCountries()
  }, [])

  return (
    <div
      className="modal"
      style={{ maxWidth: 855, height: '100%', margin: 74 }}
    >
      <Inline>
        <ButtonClose onClick={() => modal.close()} />
      </Inline>
      <div>
        <div className="bootstrap-wrapper" style={{ zIndex: 999 }}>
          <div className="row">
            <div className="col-md-11">
              <Title style={{ marginBottom: 10 }}>Consultorios Red B360</Title>
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginBottom: 15 }}
              >
                Si los datos no están actualizados, los pacientes no podrán
                encontrate ni contactarte. Al llenar este formulario aceptas que
                estos datos sean publicados en la red.
              </LabelNormal>
              <LabelNormal color="#454D58" fontSize="22">
                {props.data
                  ? 'Editar datos de Dr.Finder'
                  : 'Registro de Dr. Finder'}
              </LabelNormal>
            </div>
            <div className="col-md-12">
              <B360NetworkDoctorOfficeForm 
                countries={countries} 
                country={country?.id} 
                setValid={setValidDoctorOffice} 
                setValues={setDoctorOfficeData} 
                defaultValues={officeDefaultValues} 
                defaultValuesAddress={addressDefaultValues} 
                doctor={true}
                submit={submit}
              />
              <Column>
                <Row>
                  <NumberInput
                    control={control}
                    label="Cédula profesional"
                    placeholder="Cédula"
                    name="cedula"
                    errors={errors}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    rules={{ 
                      required: "*Introduce tu cédula profesional", 
                      maxLength: {
                        value: 8,
                        message: "Longitud máxima de 8 caracteres"
                      }, 
                      minLength: {
                        value: 8,
                        message: "Longitud mínima de 8 caracteres"
                      }
                    }}
                    limit={8}
                  />
                </Row>
              </Column>
              <div style={{ marginTop: 31, marginBottom: 41 }}>
                <ButtonPrime
                  label={
                    props.data ? 'Guardar cambios' : 'Registrar consultorio'
                  }
                  onClick={() => onSubmitForm()}
                ></ButtonPrime>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFormSurgery;
