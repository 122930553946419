import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import InputSearch from '@components/shared/InputSearch';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonAction from '@components/shared/ButtonAction';
import Card from '@components/shared/Card';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_STUDENTS_BY_TEACHER } from '@graphql/query';
import { loadPhotoProfileGender } from '../../utils';
import { Cap } from '@components/shared/ChipIcons';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;

const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 28px;
    margin-right: 79px;
    margin-bottom: 100px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LabelNormal = styled.p`
    font-size: 9px;
    font-family: neue-montreal-regular;
    color: #757575;
    margin: 0px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
    margin-bottom: 39px;
`;

const options = [
    {id: 'Con casos activos', name: 'Con casos activos'},
    {id: 'Sin casos', name: 'Sin casos'},
    {id: 'Desactivados', name: 'Desactivados'}
]

const Students = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [students, setStudents] = useState([]);
    const [selectedOption, setSelectedOption] = useState(['Con casos activos', 'Sin casos', 'Desactivados']);
    const [search, setSearch] = useState('');

    const [getStudentsByTeachers] = useLazyQuery(QUERY_GET_STUDENTS_BY_TEACHER, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if(data.getStudentsByTeachers){
                setStudents(data.getStudentsByTeachers);
            }
            setLoading(false);
        },
        onError: error => {
            setLoading(false);
        }
    });

    useEffect(() => {
        setLoading(true);
        if(search !== ''){
            getStudentsByTeachers({ variables: { teacher_id: +localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
        }else{
            getStudentsByTeachers({ variables: { teacher_id: +localStorage.getItem('b360-role_id'), filters: selectedOption } });
        }
    },[selectedOption])

    useEffect(() => {
        setLoading(true);
        if(search !== ''){
            getStudentsByTeachers({ variables: { teacher_id: +localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
        }else{
            getStudentsByTeachers({ variables: { teacher_id: +localStorage.getItem('b360-role_id'), filters: selectedOption } });
        }
    },[search])

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="estudiantes" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} />
                    </div>
                </Header>
                <ContainContent>
                    <Title>Estudiantes</Title>
                    <InlineContainer style={{ justifyContent: 'space-between' }}>
                        <InlineContainer>
                            <InputSearch placeholder="Busca por nombre o ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                            <DropdownCustom label="Filtros" data={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption}  />
                        </InlineContainer>
                    </InlineContainer>
                    <div className='bootstrap-wrapper' style={{marginTop: 43}}>
                        {loading ?
                            <>
                                <div style={{display: 'flex', marginTop: 30}}>
                                    {Array.from(Array(4).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 17px'}}>
                                            <Skeleton height={159} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                                <div style={{display: 'flex', marginTop: 16}}>
                                    {Array.from(Array(4).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 17px'}}>
                                            <Skeleton height={159} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                                <div style={{display: 'flex', marginTop: 16}}>
                                    {Array.from(Array(4).keys()).map(() => (
                                        <div style={{padding: '0px 17px 0px 17px'}}>
                                            <Skeleton height={159} borderRadius={27} width={262} baseColor={'#F6F6F8'} highlightColor={'#FFF'} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        :
                            <div className='row' style={{ maxWidth: '85%'}}>
                                {students.map(item => (
                                    <div style={{marginTop: 15, padding: 10}} key={Math.random()}>
                                        <Card to={`/profesor/estudiante/${item.id}`}>
                                            <InlineContainer style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                                <img src={ item.user.photo_filename ? `${process.env.REACT_APP_API_URL}/users/${item.user.id}/profile/${item.user.photo_filename}` : loadPhotoProfileGender(item.user.gender)} style={{height: 36, width: 36, marginTop: 4, borderRadius: 50}} />
                                                <Cap color="#C8C8C8" />
                                            </InlineContainer>
                                            <LabelNormal style={{color: '#3C4549', fontSize: 15, marginTop: 14}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                            <InlineContainer style={{justifyContent: 'space-between', alignItems: 'center', marginTop: 5}}>
                                                <LabelNormal fontSize="11" color="#BDBDBD">ACTIVOS {item.active_treatments ? item.active_treatments.length : '0'}</LabelNormal>
                                                <LabelNormal fontSize="11" color="#BDBDBD">TERMINADOS {item.count_inactives_treatments ? item.count_inactives_treatments : '0'}</LabelNormal>
                                            </InlineContainer>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    );
}

export default Students;