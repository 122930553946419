/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_INSERT_ADDRESS, MUTATION_UPDATE_ADDRESS } from '@graphql/mutation';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AddressForm from '../../components/Doctor/AddressForm';
import { useForm } from 'react-hook-form';
import TextInput from '../../components/shared/inputs/TextInput';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 79px;
    max-width: 875px;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const Address = () => {
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation()
    const { control, clearErrors, formState: { errors, isValid }, setValue, watch, getValues, trigger } = useForm({
        defaultValues: {
            name: '',
            default: false
        }
    })
    const [country, setCountry] = useState({});
    const [formValid, setFormValid] = useState(false)
    const [values, setValues] = useState(null)
    const [defaultValues, setDefaultValues] = useState(null)
    const [submit, setSubmit] = useState(0)

    const isDefault = watch('default')

    useEffect(() => {
        if(location.state) {
            setValue('name', location.state.name)
            setValue('default', location.state.default)
            setDefaultValues({
                street: location.state.street,
                number: location.state.number,
                internal_number: location.state.internal_number,
                postal_code: location.state.postal_code,
                city: location.state.suburb,
                locality: location.state.locality,
                state: location.state.state,
                timezone: '',
                timezone_name: '',
                references: location.state.references
            })
        }
    },[location])

    const [addAddress] = useMutation(MUTATION_INSERT_ADDRESS, {
        onCompleted: data => {
            if(data.registerAddress){
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => history.push('/doctor/perfil'))}>
                        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>Dirección de envío agregada</LabelNormal>
                        <LabelNormal fontSize="17" color="#4F4F4F" style={{ paddingTop: 14 }}>Ahora podrás seleccionarla para tus envíos. </LabelNormal>
                    </ModalSuccess>
                )
            }
        }
    })

    const [updateAddress] = useMutation(MUTATION_UPDATE_ADDRESS, {
        onCompleted: data => {
            if(data.updateAddress){
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => history.push('/doctor/perfil'))}>
                        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>Cambios a dirección de envío guardados</LabelNormal>
                    </ModalSuccess>
                )
            }
        }
    })

    const onSubmitAddress = () => {
        trigger()
        setSubmit(submit + 1)
        const formValues =  getValues()
        if (formValid && isValid) {
            if(location.state) {
                updateAddress({ variables: {
                    ...formValues,
                    ...values,
                    id
                }});
            } else {
                addAddress({ variables: {
                    ...formValues,
                    ...values
                }});
            }
        }
    }

    const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
        onCompleted: data => {
            if(data.doctor) setCountry(data.doctor.user.country)
        }
    })

    useEffect(() => {
        getDoctorDetails({ variables: { id: localStorage.getItem('b360-role_id') } })
    }, [])

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="perfil" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title style={{marginBottom: 39}}>Dirección de envío</Title>
                                <LabelNormal color="#454D58" fontSize="22" style={{ marginBottom: 16, marginLeft: 0 }}>{location.state ? "Editar dirección de envío" : "Agregar dirección de envío"}</LabelNormal>
                            </div>
                            <div className="col-md-12">
                                <div className='bootstrap-wrapper'>
                                    <TextInput
                                        name="name"
                                        control={control}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        rules={{ required: '*Introduce el nombre del consultorio o clínica' }}
                                        placeholder="Nombre de consultorio o clínica"
                                        label="Nombre"
                                        muted="Asigna un nombre a tu dirección para ubicarla fácilmente en el futuro"
                                        parentProps={{ style: {
                                            flex: '0 0 75%',
                                            maxWidth: '75%'
                                        }}}
                                    />
                                    <AddressForm 
                                        country={country.id} 
                                        setValid={setFormValid} 
                                        setValues={setValues} 
                                        defaultValues={defaultValues} 
                                        doctor={true} 
                                        profileFiscal={false} 
                                        mode='onBlur'
                                        submit={submit}
                                    />
                                    <div style={{margin: 0, paddingTop: 28}} className="row">
                                        <Checkbox
                                            checked={isDefault}
                                            icon={<FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />}
                                            borderColor="#C8C8C8"
                                            style={{ overflow: "hidden" }}
                                            size={20}
                                            onChange={(value) => setValue('default', value)}
                                            labelStyle={{ marginLeft: 5, userSelect: "none", fontFamily: 'neue-montreal-regular', color: '#3C4549' }}
                                            label='Definir como dirección de envío predeterminada'
                                        />
                                    </div>
                                    <div style={{marginTop: 31, marginBottom: 41}}>
                                        <ButtonPrime label={location.state ? "Guardar cambios" : "Registrar dirección"} onClick={() => onSubmitAddress()}></ButtonPrime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

export default Address;