import { LabelNormal, Row } from '../../shared/Styled';
import ButtonSecondary from '../../shared/buttons/secondary';
import Info from '../../Icons/Info';
import { treatmentStatusEnum } from '../consts';

/**
 * @param {{
 * hadPlanningComments: boolean,
 * loadingUndo: boolean,
 * onClickSendPlanning: () => void,
 * onClickUndo: () => void,
 * statusId: number
 *  }} props
 */
export const TreatmentViewerAction = ({
  hadPlanningComments,
  loadingUndo,
  onClickSendPlanning,
  onClickUndo,
  statusId,
}) => {
  /* TODO: is necessary to confirm which status is used to determine pausedCase 
  while on planning stage. */
  const isDoctorMessage = () => {
    return statusId === treatmentStatusEnum.doctorMessage;
  };

  const isReadyVisor = () => {
    return (
      statusId === treatmentStatusEnum.doubtsResolved ||
      statusId === treatmentStatusEnum.waitingApprove
    );
  };

  const isWaitingPlanningWithCommentsOrFurther = () => {
    return (
      statusId >= treatmentStatusEnum.waitingPlanning &&
      statusId <= treatmentStatusEnum.endedCase &&
      hadPlanningComments === true
    );
  };

  const isWaitingPlanningOrFurther = () => {
    return (
      statusId >= treatmentStatusEnum.waitingPlanning &&
      statusId <= treatmentStatusEnum.endedCase
    );
  };

  console.log({ statusId, hadPlanningComments });

  if (isDoctorMessage())
    return (
      <ButtonSecondary
        fontSize="15px"
        width="fit-content"
        background="##EB9542"
        label="Cambios acordados"
        onClick={onClickSendPlanning}
      />
    );

  if (isReadyVisor())
    return (
      <>
        <Row justifyContent="space-between">
          <LabelNormal
            fontSize="14"
            fontWeight="500"
            style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <Info />
            Cambios acordados
          </LabelNormal>
          <LabelNormal
            fontSize="14"
            fontWeight="500"
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              cursor: loadingUndo ? 'not-allowed' : 'pointer',
              textDecoration: 'underline',
            }}
            onClick={onClickUndo}
          >
            Deshacer
          </LabelNormal>
        </Row>
      </>
    );

  if (isWaitingPlanningWithCommentsOrFurther()) {
    return (
      <LabelNormal
        fontSize="14"
        fontWeight="500"
        style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
      >
        <Info />
        Cambios acordados
      </LabelNormal>
    );
  }

  if (isWaitingPlanningOrFurther())
    return (
      <LabelNormal
        fontSize="14"
        fontWeight="500"
        style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
      >
        <Info />
        No se hicieron comentarios en visor
      </LabelNormal>
    );

  /* Default case */
  return (
    <>
      <ButtonSecondary
        fontSize="15px"
        width="fit-content"
        background="transparent"
        label="Cambios acordados"
        disabled={true}
      />
      <LabelNormal
        fontSize="14"
        fontWeight="500"
        style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
      >
        <Info />
        La acción no está disponible
      </LabelNormal>
    </>
  );
};
