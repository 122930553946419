/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_PATIENT_FILES,
  QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
} from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_APPROVED_FILE_TREATMENT,
  MUTATION_REJECT_FILE_TREATMENT,
  MUTATION_UNDO_CORRECTION_TREATMENT,
  MUTATION_REVIEW_TREATMENT,
  MUTATION_ACCEPT_DECLINE_FORM,
  MUTATION_DELETE_CHAT_CORRECTION_FORM,
  MUTATION_CHANGE_STL_ON_REVIEW,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import ModalQuestion from '@components/Modal/ModalQuestion';
import ModalWarning from '@components/Modal/ModalWarning';
import Breadcrumb from '@components/Navigation/Breadcrumb/Breadcrumb';
import { Row } from '../../../components/shared/Styled';
import FilesSidebar from '../../../components/Corrections/FilesSidebar';
import FileDisplay from '../../../components/Corrections/FileDisplay';
import Comments from '../../../components/Corrections/Comments';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Review = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [patient, setPatient] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [filesTreatment, setFilesTreatment] = useState([]);
  const [selectTreatmentFile, setSelectTreatmentFile] = useState({});
  const [commentsCorrections, setCommentsCorrections] = useState([]);
  const [reject, setReject] = useState('');
  const [treatmentId, setTreatmentId] = useState('');
  const [typeSTL, setTypeSTL] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [doctorData, setDoctorData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [editCorrection, setEditCorrection] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [formCorrection, setFormCorrection] = useState('');
  const [contactQuestion, setContactQuestion] = useState('');
  const [formStatus, setFormStatus] = useState('');
  const [statusTreatment, setStatusTreatment] = useState({});
  const [alertCommentReject, setAlertCommentReject] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patientData = data.pacient;
        setPatient(patientData);

        setFirstName(patientData.first_name);
        setLastName(patientData.last_name);
        setTreatmentId(patientData.treatment_related.id);
        setTypeSTL(patientData.treatment_related.stl_type);
        setDoctorId(patientData.doctor.id);
        setDoctorData(patientData.doctor);
        setFormCorrection(
          patientData.treatment_related.form_correction
            ? patientData.treatment_related.form_correction
            : ''
        );
        setContactQuestion(
          patientData.treatment_related.contact_question
            ? patientData.treatment_related.contact_question
            : ''
        );
        setFormStatus(
          patientData.treatment_related.form_status
            ? patientData.treatment_related.form_status
            : ''
        );
        setStatusTreatment(patientData.treatment_related.treatmentStatus);

        if (firstLoad) {
          if (
            patientData.treatment_related.treatmentStatus.type === 'new_case' &&
            patientData.treatment_related.treatmentStatus.index === 6
          ) {
            changeStlOnReview({ variables: { patient_id: id } });
          } else {
            getPatientFiles({
              variables: {
                patient_id: id,
                onlyErrors:
                  patientData.treatment_related.treatmentStatus.type ===
                    'new_case' &&
                  patientData.treatment_related.treatmentStatus.index === 6
                    ? true
                    : false,
              },
            });
            getCommentsCorrection({
              variables: {
                onlyErrors:
                  patientData.treatment_related.treatmentStatus.type ===
                    'new_case' &&
                  patientData.treatment_related.treatmentStatus.index === 6
                    ? true
                    : false,
                treatment_id: patientData.treatment_related?.id,
              },
            });
            setFirstLoad(false);
          }
        }
      }
    },
    onError: (err) => console.log(err),
  });

  // eslint-disable-next-line no-unused-vars
  const [chatMessages, setChatMessages] = useState([]);

  const refStatusCorrections = useRef(null);

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;

        let decode = patientfiles.map((item) => ({
          id: item.id,
          review: item.name,
          type: item.name !== 'modelo' ? item.file_type : 'image',
          name: item.file_name,
          url: item.fileUrl,
          preview:
            item.file_type === 'stl' && item.name !== 'modelo'
              ? require('@images/files/stl.jpg')
              : item.file_type === 'zip'
              ? require('@images/files/zip.jpg')
              : item.fileUrl,
          section: item.section,
          status: item.FileStatus.name,
          statusId: item.FileStatus.id,
        }));

        decode = [
          decode.find((v) => v.type === 'pdf')
            ? decode.find((v) => v.type === 'pdf')
            : {
                id: 'id-review',
                review: 'pdf',
                type: 'pdf',
                name: 'form',
                url: '',
                section: '',
                status: patientfiles[0].treatment.form_status,
              },
          ...decode.filter((v) => v.type !== 'pdf'),
        ];
        const formFile = decode.find((v) => v.type === 'pdf')
          ? decode.find((v) => v.type === 'pdf')
          : null;

        if (
          selectTreatmentFile &&
          selectTreatmentFile.id &&
          selectTreatmentFile.name !== ''
        ) {
          let index = decode.findIndex(
            (f) =>
              f.section === selectTreatmentFile.section &&
              f.review === selectTreatmentFile.review
          );
          if (index < 0) {
            index = 0;
          }
          setSelectTreatmentFile(decode[index]);
        } else {
          if (selectTreatmentFile && selectTreatmentFile.name === '') {
            setSelectTreatmentFile(
              decode.find((d) => d.review === selectTreatmentFile.review)
            );
          } else {
            setSelectTreatmentFile(decode[0]);
          }
        }

        if (typeSTL === 'url') {
          const arcada_superior = decode.filter(
            (d) => d.review === 'arcada_superior'
          );
          if (arcada_superior.length <= 0 && statusTreatment.index !== 6) {
            decode.push({
              id: Math.random(),
              review: 'arcada_superior',
              type: 'stl',
              name: '',
              url: require('@images/stl/arcada-superior.jpg'),
              section: 'stl',
              status: 'upload',
            });
          }

          const arcada_inferior = decode.filter(
            (d) => d.review === 'arcada_inferior'
          );
          if (arcada_inferior.length <= 0 && statusTreatment.index !== 6) {
            decode.push({
              id: Math.random(),
              review: 'arcada_inferior',
              type: 'stl',
              name: '',
              url: require('@images/stl/arcada-inferior.jpg'),
              section: 'stl',
              status: 'upload',
            });
          }

          const oclusion = decode.filter(
            (d) => d.review === 'oclusion_derecha'
          );
          if (oclusion.length <= 0 && statusTreatment.index !== 6) {
            decode.push({
              id: Math.random(),
              review: 'oclusion_derecha',
              type: 'stl',
              name: '',
              url: require('@images/stl/oclusion.jpg'),
              section: 'stl',
              status: 'upload',
            });
          }
        } else if (typeSTL === 'shipping') {
          const modelo = decode.filter((d) => d.review === 'modelo');
          if (
            modelo.length <= 0 &&
            !(
              statusTreatment.type === 'new_case' && statusTreatment.index === 6
            )
          ) {
            decode.push({
              id: Math.random(),
              review: 'modelo',
              type: 'image',
              name: '',
              url: require('@images/files/modelo-placeholder.png'),
              preview: require('@images/files/modelo-placeholder.png'),
              section: 'stl',
              status: 'upload',
            });
          }
        }

        if (
          formFile.status === 'Aprobado' &&
          statusTreatment.type === 'new_case' &&
          statusTreatment.index === 6
        ) {
          decode = decode.filter((v) => v.type !== 'pdf');
          setSelectTreatmentFile(
            decode.find((d) => d.id === selectTreatmentFile.id)
              ? decode.find((d) => d.id === selectTreatmentFile.id)
              : decode[0]
          );
        }
        setFilesTreatment(decode);
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getPatient({ variables: { id } });
  }, [id]);

  const [approvedFile] = useMutation(MUTATION_APPROVED_FILE_TREATMENT, {
    onCompleted: (data) => {
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
    },
  });

  const [rejectFile] = useMutation(MUTATION_REJECT_FILE_TREATMENT, {
    onCompleted: (data) => {
      setReject('');
      setAlertCommentReject(false);
      setEditCorrection(false);
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
    },
  });

  const [changeStlOnReview] = useMutation(MUTATION_CHANGE_STL_ON_REVIEW, {
    onCompleted: (data) => {
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
      setFirstLoad(false);
    },
  });

  const [undoFile] = useMutation(MUTATION_UNDO_CORRECTION_TREATMENT, {
    onCompleted: (data) => {
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
    },
  });

  const [chatCorrection] = useMutation(MUTATION_DELETE_CHAT_CORRECTION_FORM, {
    onCompleted: (data) => {
      modal.close();
      setReject('');
      setEditCorrection(false);
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getPatient({ variables: { id } });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
    },
  });

  const [getCommentsCorrection] = useLazyQuery(
    QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getCorrectionCommentsByTreatment.correctionsComments) {
          setCommentsCorrections(
            data.getCorrectionCommentsByTreatment.correctionsComments
          );
        }
      },
    }
  );

  const questionDoctor = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatmentId}
        doctorId={doctorId}
        onClose={() => {
          getPatientFiles({
            variables: {
              patient_id: id,
              onlyErrors:
                statusTreatment.type === 'new_case' &&
                statusTreatment.index === 6
                  ? true
                  : false,
            },
          });
          getPatient({ variables: { id } });
        }}
      />
    );
  };

  const questionDoctorForm = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatmentId}
        question={contactQuestion !== '' ? contactQuestion : formCorrection}
        doctorId={doctorId}
        form={true}
        onClose={() => {
          setReject('');
          setEditCorrection(false);
          getPatientFiles({ variables: { patient_id: id, onlyErrors: false } });
          getCommentsCorrection({
            variables: {
              onlyErrors: false,
              treatment_id: treatmentId,
            },
          });
          getPatient({ variables: { id } });
        }}
      />
    );
  };

  const questionDoctorEdit = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatmentId}
        question={contactQuestion}
        doctorId={doctorId}
        form={false}
        doctorEdit={true}
        onClose={() => {
          getPatientFiles({
            variables: {
              patient_id: id,
              onlyErrors:
                statusTreatment.type === 'new_case' &&
                statusTreatment.index === 6
                  ? true
                  : false,
            },
          });
          getPatient({ variables: { id } });
        }}
      />
    );
  };

  const deleteQuestion = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          El borrado de la pregunta es permanente
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          La pregunta antes realizada será borrada completamente del chat.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            chatCorrection({
              variables: {
                treatment_id: treatmentId,
                form: formCorrection !== '' ? true : false,
              },
            })
          }
          label="Borrar pregunta"
        />
      </ModalWarning>
    );
  };

  const deleteQuestionForm = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se aceptará el formulario
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Al borrar el contenido de la pregunta se aceptará el formulario.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            chatCorrection({
              variables: {
                treatment_id: treatmentId,
                form: formCorrection !== '' ? true : false,
              },
            })
          }
          label="Borrar pregunta"
        />
      </ModalWarning>
    );
  };

  const openWarning = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se pausará el caso
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Espera a que el doctor envíe sus correcciones y conteste a tus dudas
          para aprobar los archivos.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            reviewTreatment({
              variables: { treatment_id: treatmentId },
            })
          }
          label="Enviar correcciones"
        />
      </ModalWarning>
    );
  };

  const [reviewTreatment] = useMutation(MUTATION_REVIEW_TREATMENT, {
    onCompleted: (data) => {
      if (formCorrection === '' && contactQuestion === '') {
        if (
          commentsCorrections.length > 0 &&
          commentsCorrections.filter((c) => c.type !== 'success').length > 0
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Errores enviados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Espera a que el doctor corrija sus archivos para revisarlos
                nuevamente.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Archivos aprobados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Archivos aprobados y caso enviado a planificación.
              </LabelNormal>
            </ModalSuccess>
          );
        }
      } else {
        if (
          commentsCorrections.length > 0 &&
          commentsCorrections.filter((c) => c.type !== 'success').length > 0
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Errores enviados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Espera a que el doctor corrija sus archivos para revisarlos
                nuevamente.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          if (contactQuestion !== '') {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.replace(`/app/tablero/caso/${id}`))
                }
              >
                <LabelNormal
                  fontSize="22"
                  color="#060809"
                  style={{ paddingTop: 30 }}
                >
                  Contacto establecido
                </LabelNormal>
                <LabelNormal
                  fontSize="17"
                  color="#4F4F4F"
                  style={{ paddingTop: 14, marginBottom: 15 }}
                >
                  Espera a que el doctor conteste en la conversación para llegar
                  a acuerdos
                </LabelNormal>
              </ModalSuccess>
            );
          } else {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.replace(`/app/tablero/caso/${id}`))
                }
              >
                <LabelNormal
                  fontSize="22"
                  color="#060809"
                  style={{ paddingTop: 30 }}
                >
                  Archivos aprobados
                </LabelNormal>
                <LabelNormal
                  fontSize="17"
                  color="#4F4F4F"
                  style={{ paddingTop: 14, marginBottom: 15 }}
                >
                  Archivos aprobados y caso enviado a planificación.
                </LabelNormal>
              </ModalSuccess>
            );
          }
        }
      }
    },
  });

  useEffect(() => {
    if (document.querySelector('#card-container-scroll')) {
      document.querySelector('#card-container-scroll').scrollTop =
        document.querySelector('#card-container-scroll')?.scrollHeight;
    }
  }, [reject, commentsCorrections]);

  const getNextImageReview = (id) => {
    if (reject) {
      setAlertCommentReject(true);
    } else {
      const reply = filesTreatment;
      const index = reply.findIndex((r) => r.id === id);
      if (index < reply.length - 1) {
        setSelectTreatmentFile({ ...reply[index + 1], index: index + 1 });
      }
    }
  };

  const [acceptDeclineForm] = useMutation(MUTATION_ACCEPT_DECLINE_FORM, {
    onCompleted: (data) => {
      setReject('');
      setEditCorrection(false);
      getPatientFiles({
        variables: {
          patient_id: id,
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            statusTreatment.type === 'new_case' && statusTreatment.index === 6
              ? true
              : false,
          treatment_id: treatmentId,
        },
      });
      getPatient({ variables: { id } });
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <div
          style={{
            position: 'fixed',
            height: '20px',
            width: '90%',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
            top: 0,
            left: 0,
          }}
        ></div>
        <Header
          style={{
            position: 'sticky',
            top: '20px',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent style={{ marginTop: 0, marginRight: 0 }}>
          <div className="bootstrap-wrapper">
            <div
              className="row"
              style={{
                position: 'sticky',
                top: '90px',
                backgroundColor: '#f6f6f8',
                zIndex: 10,
                marginRight: 0,
              }}
            >
              <div className="col-md-7">
                <Title>Revisión de archivos</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.push('/app/tablero')}
                    mainLabel={'Tableros'}
                    midLabel={`${firstName} ${lastName}`}
                    onSecondClick={() => history.goBack()}
                    endLabel={'Revisión de archivos'}
                  />
                  <LabelNormal color="#757575" fontSize="17">
                    Revisa cada archivo y acéptalos o recházalos para registrar
                    el caso.
                  </LabelNormal>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginRight: 0 }}>
              <div className="col-md-12" style={{ maxWidth: 1145 }}>
                <Row style={{ flexWrap: 'nowrap' }}>
                  <FilesSidebar
                    loadingSkeleton={loadingSkeleton}
                    loading={loading}
                    files={filesTreatment}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    type="admin"
                  />
                  <FileDisplay
                    type="admin"
                    loadingSkeleton={loadingSkeleton}
                    loading={loading}
                    setLoading={setLoading}
                    file={selectTreatmentFile}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    caseId={id}
                    patient={patient}
                    treatment={patient?.treatment}
                    filesTreatment={filesTreatment}
                    setFilesTreatment={setFilesTreatment}
                    typeSTL={typeSTL}
                    chatMessages={chatMessages}
                    activeChat={false}
                    getNextImageReview={getNextImageReview}
                    doctorData={doctorData}
                    defaultAddress={{}}
                    errorForm={false}
                    formStatus={formStatus}
                    questionDoctorForm={questionDoctorForm}
                    acceptDeclineForm={acceptDeclineForm}
                    approvedFile={approvedFile}
                    undoFile={undoFile}
                    reject={reject}
                    setReject={setReject}
                  />
                  <Comments
                    role="admin"
                    loading={loadingSkeleton}
                    loadingImage={loading}
                    patientId={patient?.id}
                    treatmentId={treatmentId}
                    statusTreatment={statusTreatment}
                    comments={commentsCorrections}
                    typeSTL={typeSTL}
                    files={filesTreatment}
                    refStatusCorrections={refStatusCorrections}
                    file={selectTreatmentFile}
                    setSelectFile={setSelectTreatmentFile}
                    reject={reject}
                    alertCommentReject={alertCommentReject}
                    formCorrection={formCorrection}
                    contactQuestion={contactQuestion}
                    reviewTreatment={reviewTreatment}
                    openWarning={openWarning}
                    acceptDeclineForm={acceptDeclineForm}
                    rejectFile={rejectFile}
                    questionDoctor={questionDoctor}
                    questionDoctorForm={questionDoctorForm}
                    questionDoctorEdit={questionDoctorEdit}
                    deleteQuestionForm={deleteQuestionForm}
                    deleteQuestion={deleteQuestion}
                  />
                </Row>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Review;
