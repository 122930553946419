import styled from 'styled-components';

export const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

export const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;
