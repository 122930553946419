import React from 'react';
import styled from 'styled-components';
import { modal } from '@components/ModalManagement';
import ButtonClose from '../shared/ButtonClose';

const Image = styled.img`
  height: 60px;
  width: 60px;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const UnSubscribe = (props) => {
    return (
      <div className='modal'>
        <Inline>
          {!props.withoutIcon &&
            <div className='text-center'>
              <Image src={require('@images/icons/unsubscribe.png')} />
            </div>
          }
          <ButtonClose onClick={() => modal.close()} />
        </Inline>
        <div style={{ maxWidth: '90%' }}>
          {props.children}
        </div>
      </div>
    );
};
   
export default UnSubscribe;