import { useHistory } from 'react-router-dom';
import ButtonNext from '@components/Navigation/ButtonNavigation/ButtonNext';
import ButtonBack from '@components/Navigation/ButtonNavigation/ButtonBack';
import { Header, HeaderContainer } from '../shared/Styled';

const NewCaseHeader = ({
  steps,
  setSteps,
  selectTab,
  setSelectTab,
  subSteps,
  setSubStep,
  lastStep,
}) => {
  const history = useHistory();

  return (
    <Header>
      <HeaderContainer>
        {/* <ButtonBack
          onClick={() => {
            if (steps === 2) {
              if (selectTab > 1) {
                setSelectTab(selectTab - 1);
              } else {
                setSteps(1);
              }
            } else if (steps === 1) history.goBack();
            else if (steps === 3 && subSteps === 1) {
              setSubStep(subSteps - 1);
            } else {
              setSteps(steps - 1);
            }
          }}
        />
        <ButtonNext
          onClick={() => {
            if (steps === 2) {
              if (selectTab < 5) {
                setSelectTab(selectTab + 1);
              } else {
                setSteps(3);
              }
            } else if (steps === 7) history.goForward();
            else if (steps === 3 && subSteps === 0) {
              setSubStep(subSteps + 1);
            } else if (steps < lastStep) {
              setSteps(steps + 1);
            }
          }}
        /> */}
      </HeaderContainer>
    </Header>
  );
};

export default NewCaseHeader;
