import { InlineContainer, LabelNormal } from '../shared/Styled';

const Warning = ({ title, body }) => (
  <div
    style={{
      background: '#F9EECA',
      borderRadius: 13,
      padding: '15px 18px',
      maxWidth: '350px'
    }}
  >
    <InlineContainer style={{ marginBottom: 11, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '7px' }}>
      <img
        src={require('@images/icons/warning-dark.png')}
        style={{ height: 29, width: 29 }}
        alt=""
      />
      <LabelNormal fontSize="22" color="#6B4D00" fontWeight="600">
        { title }
      </LabelNormal>
    </InlineContainer>
    <div style={{ fontSize: '17px', fontFamily: 'neue-montreal-regular', color: "#6B4D00", margin: '5px', fontWeight: '500' }}>
      { body }
    </div>
  </div>
);

export default Warning
