import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import Avatar from '@components/shared/Avatar';
import CloseSession from '../../components/shared/CloseSession';
import LabelUnderline from '../../components/shared/LabelUnderline';
import { QUERY_GET_TEACHER } from '@graphql/query';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { 
    MUTATION_UPDATE_PASSWORD
} from '@graphql/mutation';
import { loadPhotoProfileGender } from '../../utils';
import ButtonPrime from '@components/shared/ButtonPrime';
import moment from 'moment';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;

const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 28px;
    margin-right: 79px;
    margin-bottom: 100px;max-width: 1072px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const HeaderCard = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const LabelInput = styled.div`
    font-size: 18px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    line-height: 21.6px;
`;

const LabelMute = styled.p`
    font-size: 15px;
    font-family: neue-montreal-regular;
    color: #757575;
`;

const DecoratorBlue = styled.div`
    background: linear-gradient(#1AA5E0, #088EC7);
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const DecoratorYellow = styled.div`
    background: #F0BB00;
    width: 128px;
    height: 37px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

const Teacher = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [changePassword, setChangePassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [photo, setPhoto] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [createdAt, setCreatedAt] = useState('');

    const [nameUniversity, setNameUniversity] = useState('');
    const [idUniversity, setIdUniversity] = useState('');

    const [idUser, setIdUser] = useState('');
    const [status, setStatus] = useState(false);

    const [students, setStudents] = useState([]);
    const [treatments, setTreatments] = useState([]);

    const [treatmentDetails, setTreatmentDetails] = useState({});

    const [getTeacher] = useLazyQuery(QUERY_GET_TEACHER, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if(data.teacher){
                const teacher = data.teacher;
                setTreatmentDetails(data.teacher);

                setStatus(teacher.status);
                setStudents(teacher.students);
                setTreatments(teacher.treatments);

                setNameUniversity(teacher.university.name);
                setIdUniversity(teacher.university.id);

                setIdUser(teacher.user.id);

                setFirstName(teacher.user.first_name);
                setLastName(teacher.user.last_name);
                setGender(teacher.user.gender);
                setCountry(teacher.user.country.name);
                setEmail(teacher.user.email);
                setPhoto(teacher.user.photo_filename);
                setCreatedAt(teacher.user.created_at);
            }
            setLoading(false);
        },
        onError: err => {
            console.log(err);
            setLoading(false);
        }
    })

    useEffect(() => {
        setLoading(true);
        getTeacher({ variables: { id: localStorage.getItem('b360-role_id') } });
    },[])

    const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD, {
        onCompleted: data => {
            if(data.updatePassword){
                setChangePassword(false);
                setPassword('');
                setConfirmPass('');
                modal.open(
                    <ModalSuccess onClick={() => modal.close()}>
                        <LabelNormal color="#060809" fontSize="22" style={{marginBottom: 14}}>Cambiaste tu contraseña</LabelNormal>
                        <LabelNormal color="#4F4F4F" fontSize="17" style={{marginBottom: 14}}>Podrás ingresar con la nueva contraseña cuando inicies sesión en el portal.</LabelNormal>
                    </ModalSuccess>
                )
            }
        }
    });

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="perfil" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title>Perfil</Title>
                            </div>
                            <div className={'col-md-7'} style={{marginTop: 39}}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <InlineContainer>
                                            {!loading ?
                                                <div>
                                                    <Avatar src={photo ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}` : loadPhotoProfileGender(gender)} height="161" width="161" borderRadius="30" />
                                                    <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>Miembro desde</LabelNormal>
                                                    <LabelNormal color="#828282" fontSize="14" style={{textAlign: 'center'}}>{moment(createdAt).format('DD/MM/YYYY')}</LabelNormal>
                                                </div>
                                            :
                                                <div>
                                                    <Skeleton height={161} borderRadius={27} width={161} baseColor={'#EEEEF2'} highlightColor={'#FFF'} />
                                                    <center>
                                                        <Skeleton count={1} baseColor={'#F6F6F8'} width={97} height={27} borderRadius={5} style={{marginTop: 10}} highlightColor={'#FFF'} />
                                                    </center>
                                                </div>
                                            }
                                            {!loading ?
                                            <div style={{marginLeft: 35}}>
                                                <InlineContainer style={{alignItems: 'center'}}>
                                                    <LabelNormal fontSize="30" color="#3C4549" style={{marginRight: 20}}>{`${firstName} ${lastName}`}</LabelNormal>
                                                    <Link to={`/profesor/perfil/${localStorage.getItem('b360-role_id')}`}>
                                                        <FeatherIcon icon="edit" color="#3C4549" />
                                                    </Link>
                                                </InlineContainer>
                                                <InlineContainer>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="mail" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{email}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <InlineContainer style={{alignItems: 'center'}}>
                                                                <FeatherIcon icon="map-pin" size={19} />
                                                                <LabelNormal fontSize="14" color="#3C4549">{country}</LabelNormal>
                                                            </InlineContainer>
                                                        </div>
                                                    </div>
                                                </InlineContainer>
                                                <LabelUnderline color="#757575" fontSize="17" onClick={() => setChangePassword(!changePassword)} label={'Cambiar contraseña'}/>
                                            </div>
                                            :
                                                <div style={{marginLeft: 35, marginTop: 10, width: '100%'}}>
                                                    <div>
                                                        <Skeleton baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                    </div>
                                                    <div style={{marginTop: 10}}>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                            </div>
                                                            <div className='col-md-7'>
                                                                <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                            </div>
                                                        </div>
                                                        <div className='row' style={{marginTop: 10}}>
                                                            <div className='col-md-5'>
                                                                <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                            </div>
                                                            <div className='col-md-7'>
                                                                <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                            </div>
                                                        </div>
                                                        <div className='row' style={{marginTop: 10}}>
                                                            <div className='col-md-4'>
                                                                <Skeleton count={1} baseColor={'#F6F6F8'} height={27} borderRadius={5} highlightColor={'#FFF'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </InlineContainer>
                                    </div>
                                    {!changePassword ?
                                        <div className='col-md-12' style={{marginTop: 47}}>
                                            <LabelNormal fontSize="22" color="#454D58">Estudiantes asignados</LabelNormal>
                                            <HeaderCard style={{display: 'block'}}>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <LabelNormal color="#3C4549" fontSize="17">Estudiante</LabelNormal>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <LabelNormal color="#3C4549" fontSize="17">Caso</LabelNormal>
                                                    </div>
                                                </div>
                                            </HeaderCard>
                                            {!loading ? 
                                                <CardContainer>
                                                    {treatments.length > 0 ?
                                                        <>
                                                            {treatments.map(item => (
                                                                <div className='row'>
                                                                    <div className='col-md-8'>
                                                                        <Link to={`/app/universidades/${idUniversity}/estudiante/${item.doctor.id}`}>
                                                                            <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.doctor.user.first_name} ${item.doctor.user.last_name}`}</LabelNormal>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{`${item.patient.first_name} ${item.patient.last_name}`}</LabelNormal>
                                                                    </div>
                                                                </div>
                                                            )) }
                                                        </>
                                                    :
                                                        <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin estudiantes asignados</LabelNormal>
                                                    }
                                                </CardContainer>
                                            :
                                                <div className='row' style={{height: 230}}>
                                                    <div className='col-md-12'>
                                                        <Skeleton baseColor={'#EEEEF2'} height={'100%'} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}} highlightColor={'#FFF'} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    :
                                        <div className='col-md-12' style={{marginTop: 40}}>
                                            <LabelNormal color="#3C4549" fontSize="22" style={{letterSpacing: '2%', margin: 0}}>Cambio de contraseña</LabelNormal>
                                            <div style={{marginTop: 15}}>
                                                <Input label="Nueva contraseña" placeholder="Contraseña" type="password" value={password} onChange={(e) => setPassword(e.target.value)} validation="passwordlenght" style={{borderColor: (password != "" && confirmPass != ""  && password !== confirmPass) ? '#C60E02' : '', borderWeight: 1, borderstyle: 'solid', maxWidth: 305}}/>
                                                <div style={{marginTop: 20}}>
                                                    <Input label="Confirmar contraseña" placeholder="Contraseña" type="password" onChange={(e) => setConfirmPass(e.target.value)} style={{borderColor: (password != "" && confirmPass != ""  && password !== confirmPass) ? '#C60E02' : (password.length >= 8 && confirmPass != "") ? '#169D00' : '', borderWeight: 1, borderstyle: 'solid', maxWidth: 305}} />
                                                    {(password != "" && password.length >= 8 && confirmPass != "" && password !== confirmPass) && <p style={{color: '#C60E02', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Contraseñas no coinciden</p>}
                                                    {(password != "" && password.length >= 8 && confirmPass != "" && password === confirmPass) && <p style={{color: '#169D00', fontSize: 18, margin: 0, padding: 0, marginTop: 5, fontFamily: 'neue-montreal-regular'}}>*Contraseñas coinciden</p>}
                                                </div>
                                            </div>
                                            <InlineContainer style={{marginTop: 25, alignItems: 'center'}}>
                                                <ButtonPrime label="Guardar cambios" disabled={password != "" && password.length >= 8 && confirmPass != "" && password !== confirmPass} maxWidth="198px" onClick={() => updatePassword({ variables: { password, password_confirmation: confirmPass }})}/>
                                                <ButtonPrime label="Cancelar" background="transparent" color="#3C4549" border="1px solid #3C4549" minWidth="127" style={{marginLeft: 20}} onClick={() => setChangePassword(false)}/>
                                            </InlineContainer>
                                        </div>
                                    }
                                </div>
                            </div>
                            {!changePassword &&
                            <div className='col-md-4'  style={{marginTop: 39}}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <HeaderCard>
                                            <LabelNormal color="#3C4549" fontSize="17">Profesor</LabelNormal>
                                        </HeaderCard>
                                        {!loading ?
                                            <CardContainer>
                                                <div className='row'>
                                                    <div className='col-md-8' style={{marginBottom: 30}}>
                                                        <LabelNormal color="#828282" fontSize="16" style={{marginBottom: 10}}>Universidad</LabelNormal>
                                                        <LabelNormal color="#3C4549" fontSize="17">{nameUniversity}</LabelNormal>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <LabelNormal color="#828282" fontSize="16" style={{marginBottom: 10}}>Capacitaciones</LabelNormal>
                                                        <DecoratorBlue style={{marginTop: 10}}>
                                                            <LabelNormal color="#FFF" fontSize="17">Cryst Aligner</LabelNormal>
                                                        </DecoratorBlue>
                                                        <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                            <LabelNormal color="#BDBDBD" fontSize="13">ACTIVOS</LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="17">{treatmentDetails.treatments_ca ? treatmentDetails.treatments_ca.length : '0'}</LabelNormal>
                                                        </InlineContainer>
                                                        <InlineContainer style={{marginBottom: 10, justifyContent: 'space-between'}}>
                                                            <LabelNormal color="#BDBDBD" fontSize="13">TOTALES</LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="17">{treatmentDetails.treatments_ca ? treatmentDetails.treatments_ca.length : '0'}</LabelNormal>
                                                        </InlineContainer>
                                                        <DecoratorYellow>
                                                            <LabelNormal color="#FFF" fontSize="17">Smile System</LabelNormal>
                                                        </DecoratorYellow>
                                                        <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                            <LabelNormal color="#BDBDBD" fontSize="13">ACTIVOS</LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="17">{treatmentDetails.treatments_ss ? treatmentDetails.treatments_ss.length : '0'}</LabelNormal>
                                                        </InlineContainer>
                                                        <InlineContainer style={{marginTop: 10, justifyContent: 'space-between'}}>
                                                            <LabelNormal color="#BDBDBD" fontSize="13">TOTALES</LabelNormal>
                                                            <LabelNormal color="#757575" fontSize="17">{treatmentDetails.treatments_ss ? treatmentDetails.treatments_ss.length : '0'}</LabelNormal>
                                                        </InlineContainer>
                                                    </div>
                                                </div>
                                            </CardContainer>
                                        :
                                            <div className='row' style={{height: 345}}>
                                                <div className='col-md-12'>
                                                    <Skeleton baseColor={'#EEEEF2'} height={'100%'} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}} highlightColor={'#FFF'} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {!loading &&
                                        <div className='col-md-12' style={{marginTop: 108, marginBottom: 108, justifyContent: 'center', display: 'flex'}}>
                                            <CloseSession onClick={() => props.logout()}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(action.logout())
})

export default connect(null, mapDispatchToProps)(Teacher);