import React from 'react';
import { CasesIcon } from '../../../Icons/Cases';
import { NewCaseIcon } from '../../../Icons/NewCase';
import { UilStar, UilBookOpen, UilChatBubbleUser } from '@iconscout/react-unicons';
import Item from '../../Item/Item';

const SidebarDoctor = (props) => {
  return (
    <>
      <Item
        label='Nuevo caso'
        to='/doctor/nuevo-caso'
        icon={
          <NewCaseIcon
            color={props.name === 'nuevo-caso' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'nuevo-caso' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'nuevo-caso' && '#333C3F'}
      />
      <Item
        label='Tus casos'
        to='/doctor/tus-casos'
        icon={
          <CasesIcon
            color={props.name === 'tus-casos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'tus-casos' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'tus-casos' && '#333C3F'}
      />
      <Item
        label='Beneficios'
        to='/doctor/beneficios'
        icon= {
          <UilStar
            color={props.name === 'beneficios' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'beneficios' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'beneficios' && '#333C3F'}
      />
      <Item
        label='Recursos'
        onClick={() => window.open('https://hub-de-contenidos-borgatta.super.site/', '_blank')}
        to=''
        icon= {
          <UilBookOpen
            color={props.name === 'recursos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
            fill={'rgba(78, 90, 95, 0.6)'}
          />
        }
        color='#3C4549'
        $background={false}
      />
      {/* <Item
        label='Asesorías'
        to='/doctor/asesorias'
        icon={
          <UilChatBubbleUser
            color={props.name === 'asesorias' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'}
          />
        }
        color={props.name === 'asesorias' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'asesorias' && '#333C3F'}
      /> */}
    </>
  );
};
  
export default SidebarDoctor;