import { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { MUTATION_UPLOAD_EVIDENCE } from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { LabelNormal } from '../../shared/Styled';

const UploadEvidencesButton = ({ 
    patientId, 
    statusType, 
    statusIndex, 
    setPhotoEvidences, 
    reload 
}) => {
    const refInputEvidence = useRef(null);

    const [uploadEvidence] = useMutation(MUTATION_UPLOAD_EVIDENCE, {
        onCompleted: (data) => {
          if (data.addPatientEvidencePhoto) {
            modal.close();
            if ( !(statusType === 'finished' && statusIndex === 3)) {
              modal.open(
                <ModalSuccess
                  onClick={() =>
                    modal.close(() => {
                      setPhotoEvidences(
                        data.addPatientEvidencePhoto.fileUrl
                          ? data.addPatientEvidencePhoto.fileUrl
                          : ''
                      );
                      reload();
                    })
                  }
                >
                  <LabelNormal
                    fontSize='22'
                    color='#060809'
                    style={{ paddingTop: 30 }}
                  >
                    ¡Felicidades! Un caso más a la bolsa
                  </LabelNormal>
                  <LabelNormal
                    fontSize='17'
                    color='#4F4F4F'
                    style={{ paddingTop: 14, marginBottom: 15 }}
                  >
                    Evidencias de fin de caso subidas, puedes consultarlas en tu
                    historial clínico. <br />
                    Te notificaremos de la acumulación de puntos cuando definamos la
                    dificultad de tu caso.
                  </LabelNormal>
                </ModalSuccess>
              );
            } else {
              modal.open(
                <ModalSuccess
                  onClick={() =>
                    modal.close(() => {
                        reload();
                    })
                  }
                >
                  <LabelNormal
                    fontSize='22'
                    color='#060809'
                    style={{ paddingTop: 30 }}
                  >
                    Evidencia actualizada
                  </LabelNormal>
                  <LabelNormal
                    fontSize='17'
                    color='#4F4F4F'
                    style={{ paddingTop: 14, marginBottom: 15 }}
                  >
                    Fotografía de evidencia correctamente actualizada
                  </LabelNormal>
                </ModalSuccess>
              );
            }
          }
        }
    });

    return (
        <div
            className="col-md-12"
            style={{ padding: '0px 0px 0px 0px' }}
        >
            <ButtonPrime
                label="Subir evidencias"
                onClick={() => refInputEvidence.current.click()}
            />
            <input
                type="file"
                accept=".png, .jpe, .jpeg"
                style={{ display: 'none' }}
                ref={refInputEvidence}
                onChange={(e) =>
                    uploadEvidence({
                        variables: {
                            file: e.target.files[0],
                            patient_id: patientId,
                        }
                    })
                }
            />
        </div>
    )
}

export default UploadEvidencesButton