import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getHeaders = () => {
  const token = localStorage.getItem('token-b360');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

const api = {
  get: (url, config = {}) => {
    return instance.get(url, { ...config, headers: getHeaders() });
  },
};

export default api;
