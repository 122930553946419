import styled from 'styled-components';

export const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

export const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

export const ScrollableBody = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

export const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

export const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

export const CardInlineContainerTrans = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  display: inline-block;
  margin: 0px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
`;

export const ChipCategory = styled.div`
  border: 1.15px solid #060809;
  border-radius: 51.37px;
  padding: 5px 13px 6px 13px;
  box-sizing: border-box;
`;

export const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

export const CardModelExtended = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 500px;
  min-height: 143px;
  margin-right: 50px;
`;

export const SeparatorItem = styled.div`
  width: 10.5px !important;
  height: 5.5px;
  border-radius: 20px;
  margin-top: 14px;
  border: 1.5px solid #dd9139;
`;

export const ItemShowMore = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BodyShowMore = styled.div`
  margin: 15px 6px;
  padding-left: 10px;
`;

export const HeaderShowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 22.5px;
`;

export const ContainerShowMore = styled.div`
  min-height: 43px;
  max-width: 518px;
  border-radius: 25px;
  background: #fff;
`;

export const CustomHref = styled.a`
  color: '#18A1DC';
  textdecoration: 'none';
  &:visited {
    color: inherit;
  }
`;

export const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

export const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;
