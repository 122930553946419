/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_ADD_TAX_DATA, MUTATION_UPDATE_TAX_DATA } from '@graphql/mutation';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalError from '@components/Modal/ModalError';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import FiscalForm from '../../components/Doctor/FiscalForm';

const ContainHome = styled.div`
    width: 100%;
    background-color: #FBFBFC;
`;
const Header = styled.div`
    height: 70px;
    width: 100%;
    background-color: #F6F6F833;
`;
const ContainContent = styled.div`
    margin-left: 51px;
    margin-top: 30px;
    margin-right: 79px;
    max-width: 875px;
`;
const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;
const Title = styled.h1`
    font-size: 30px;
    font-family: neue-montreal-regular;
    color: #3C4549;
    margin: 0; padding: 0;
`;

const requiredFields = ['business_name', 'type', 'rfc', 'tax_system_id', 'cfdi_usage_id', 'street', 'number', 'postal_code', 'suburb']

const ProfileFiscal = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation();
    const [disabled, setDisabled] = useState(false)
    const [country, setCountry] = useState({})
    const [formValid, setFormValid] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const [defaultValuesAddress, setDefaultValuesAddress] = useState(null)
    const [formValues, setFormValues] = useState({})
    const [submit, setSubmit] = useState(0)

    useEffect(() => {
        if(location.state) {
            setDefaultValues({
                business_name: location.state.business_name,
                type: location.state.type,
                rfc: location.state.rfc,
                tax_system_id: location.state.taxSystem !== null ? parseInt(location.state.taxSystem?.id) : null,
                cfdi_usage_id: location.state.cfdiUsage !== null ? parseInt(location.state.cfdiUsage?.id) : null,
                default: location.state.default
            })
            console.log('Suburb:', location.state.suburb)
            setDefaultValuesAddress({
                street: location.state.street,
                number: location.state.number,
                internal_number: location.state.internal_number,
                postal_code: location.state.postal_code,
                city: location.state.suburb ?? '',
                locality: location.state.locality,
                state: location.state.state,
                timezone: '',
                timezone_name: '',
                references: ''
            })
        }
    },[location])

    const [addTaxData] = useMutation(MUTATION_ADD_TAX_DATA, {
        onCompleted: data => {
            if(data.registerTaxData) {
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => history.push('/doctor/perfil'))}>
                        <LabelNormal fontSize="22" color="#060809" style={{paddingTop: 30}}>Perfil fiscal agregado</LabelNormal>
                        <LabelNormal fontSize="17" color="#4F4F4F" style={{paddingTop: 14, marginBottom: 40}}>Ahora podrás elegir este perfil para facturar tus pagos.</LabelNormal>
                    </ModalSuccess>
                )
            }
        },
        onError: err => {
            modal.open(
                <ModalError onClick={() => { modal.close() }}>
                    <LabelNormal
                        color="#060809"
                        fontSize="22"
                        style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
                    >
                        Razón social no registrada
                    </LabelNormal>
                    <LabelNormal
                        color="#4F4F4F"
                        fontSize="17"
                        style={{ marginBottom: 14 }}
                    >
                        Ocurrió un error al registrar la razón social
                    </LabelNormal>
                    <div
                        style={{
                            backgroundColor: '#FFE5E3',
                            padding: '15px',
                            borderRadius: 13,
                            color: '#C60E02',
                        }}
                    >
                        <p style={{ fontWeight: '600' }}>{err?.message}</p>
                    </div>
                </ModalError>
            )
        }
    })

    const [updateTaxData] = useMutation(MUTATION_UPDATE_TAX_DATA, {
        onCompleted: data => {
            if(data.updateTaxData){
                modal.open(
                    <ModalSuccess onClick={() => modal.close(() => history.push('/doctor/perfil'))}>
                        <LabelNormal fontSize="22" color="#060809" style={{paddingTop: 30}}>Cambios a perfil fiscal guardados</LabelNormal>
                    </ModalSuccess>
                )
            }
        },
        onError: err => {
            modal.open(
                <ModalError onClick={() => modal.close()}>
                    <LabelNormal
                        color="#060809"
                        fontSize="22"
                        style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
                    >
                        Razón social no actualizada
                    </LabelNormal>
                    <LabelNormal
                        color="#4F4F4F"
                        fontSize="17"
                        style={{ marginBottom: 14 }}
                    >
                        Ocurrió un error al registrar la razón social
                    </LabelNormal>
                    <div
                        style={{
                            backgroundColor: '#FFE5E3',
                            padding: '15px',
                            borderRadius: 13,
                            color: '#C60E02',
                        }}
                    >
                        <p style={{ fontWeight: '600' }}>{err?.message}</p>
                    </div>
                </ModalError>
            )
        }
    })

    const onSubmitTaxData = () => {
        setSubmit(submit + 1)
        if (formValid && requiredFields.every((key) => Object.keys(formValues).includes(key))) {
            if (location.state) {
                updateTaxData({ variables: {
                    ...formValues,
                    id
                }})
            } else {
                addTaxData({ variables: formValues });
            }
        }
    }

    const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
        onCompleted: data => {
            if (data.doctor) setCountry(parseInt(data.doctor.user.country?.id))
        }
    })

    useEffect(() => {
        setDisabled(!formValid)
    }, [formValid])

    useEffect(() => {
        getDoctorDetails({ variables: { id: localStorage.getItem('b360-role_id') } })
    }, [])

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar name="perfil" />
            <ContainHome>
                <Header>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 17, marginLeft: 51}}>
                        <ButtonNavigation icon={require('@images/icons/chevron-left.png')} onClick={() => history.goBack()} />
                        <ButtonNavigation icon={require('@images/icons/chevron-right.png')} onClick={() => history.goForward()} />
                    </div>
                </Header>
                <ContainContent>
                    <div className='bootstrap-wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Title style={{marginBottom: 39}}>Perfil fiscal</Title>
                                <LabelNormal color="#454D58" fontSize="22">{location.state ? "Editar datos fiscales" : "Agregar perfil fiscal"}</LabelNormal>
                            </div>
                            <div className="col-md-12">
                                <div className='bootstrap-wrapper' style={{ paddingTop: '20px' }}>
                                    <FiscalForm 
                                        country={country} 
                                        setValid={setFormValid} 
                                        setValues={setFormValues} 
                                        defaultValues={defaultValues} 
                                        defaultValuesAddress={defaultValuesAddress}
                                        submit={submit}
                                    />
                                    <div style={{marginTop: 31, marginBottom: 41}}>
                                        {disabled && submit > 0 &&
                                            <div style={{marginBottom: 18, background: '#FFE5E3', border: '1px solid #E41D11', padding: '5px 10px 7px 10px', borderRadius: 7, maxWidth: 256}}>
                                                <LabelNormal fontSize="14" color="#C60E02">*Completa los campos faltantes</LabelNormal>
                                            </div>
                                        }
                                        <ButtonPrime label={location.state ? "Guardar cambios" : "Guardar perfil"} onClick={() => onSubmitTaxData()}></ButtonPrime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainContent>
            </ContainHome>
        </div>
    )
}

export default ProfileFiscal;