import { Select, Space, ConfigProvider } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';

const { Option } = Select;

const PatientSelector = ({
  setSelectedPatient,
  updatePatientInfo,
  doctorId,
  patient_id,
  setExpirationDate,
  setRefinementAligners,
  error,
  selectedPatientData,
  setSelectedPatientData,
  isEditing,
  patientDataEditable,
}) => {
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const handleChange = (value) => {
    setSelectedValue(value);

    if (value.length) {
      const patientData = patients.find((patient) => patient.id === value[0]);
      console.log('Patient:', patientData);
      updatePatientInfo(patientData.patient);
      setExpirationDate(patientData.expirationDate);
      setRefinementAligners(patientData.aligner);
    }
    setSelectedPatient(value.length ? value[0] : null);
  };

  useEffect(() => {
    if (!isLoaded) {
      getPatients();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (patient_id) {
      const patientId = parseInt(patient_id);
      let patientData = null;
      if (patients.length && !isEditing) {
        patientData = patients.find(
          (patient) => patient.patient.id === patientId
        );
        setExpirationDate(patientData.expirationDate);
        setRefinementAligners(patientData.aligner);
      }
      if (!isEditing && patientData) {
        setSelectedPatientData({ id: patientData.id, name: patientData.name });
        setSelectedValue([patientData.id]);
        setSelectedPatient([patientData.id]);
        setIsLoaded(true);
      }
      if (isEditing && patientDataEditable) {
        setSelectedPatientData({
          id: patientDataEditable.id,
          name: patientDataEditable.name,
        });
        setSelectedValue([patientDataEditable.id]);
        setSelectedPatient(patientDataEditable.id);
        setIsLoaded(true);
      }
      setSelectedPatient(patientId);
    }
  }, [patient_id, patients]);

  const getPatients = async () => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await instance.get(
      `/api/treatments/get_patients/${doctorId}`
    );
    setPatients(response.data);

    if (!patient_id) {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if ((patientDataEditable || selectedPatientData) && isEditing) {
      const id = patientDataEditable
        ? patientDataEditable.id
        : selectedPatientData.id;
      getDataRefinement(id);
    }
  }, [selectedValue, isEditing]);

  const getDataRefinement = async (id) => {
    console.log('id', id);
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await instance.get(
      `api/treatments/get_refinement_data/${id}`
    );
    setSelectedPatient(id);
    setExpirationDate(response.data.expirationDate);
    setRefinementAligners(response.data.aligner);
  };

  const clearPatient = () => {
    setSelectedValue([]);
    setSelectedPatient(null);
    setSelectedPatientData(null);
    updatePatientInfo({
      first_name: '',
      email: '',
      phone: '',
      last_name: '',
      gender: '',
      birth_date: '01-01-1960',
    });
    setExpirationDate(null);
    setRefinementAligners(0);
  };

  return (
    <React.Fragment>
      {selectedPatientData && (
        <ConfigProvider
          renderEmpty={() => 'No se encontraron resultados de búsqueda.'}
        >
          <Select
            size="large"
            style={{
              borderRadius: '20px !important',
              width: '100%',
            }}
            onChange={() => {}}
            placeholder="Ingresa nombre de paciente"
            allowClear={false}
            defaultValue={[selectedPatientData.id]}
            mode="multiple"
            optionFilterProp="label"
          >
            <Option
              key={selectedPatientData.name}
              value={selectedPatientData.id}
              label={selectedPatientData.name}
              disabled={true}
            >
              <Space align="center">
                <span style={{ display: 'block', width: 25, height: 25 }}>
                  <img
                    src={require('@images/people/usuario_masculino.jpg')}
                    alt="avatar"
                    style={{
                      borderRadius: '50%',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </span>
                {selectedPatientData.name}
              </Space>
            </Option>
          </Select>
          <p
            style={{
              color: '#C60E02',
              marginTop: 10,
              fontSize: 17,
              fontWeight: 400,
            }}
          >
            {error ? error : ''}
          </p>
        </ConfigProvider>
      )}

      {!selectedPatientData && isLoaded ? (
        <ConfigProvider
          renderEmpty={() => 'No se encontraron resultados de búsqueda.'}
        >
          <Select
            size="large"
            style={{
              borderRadius: '20px !important',
              width: '100%',
            }}
            onChange={handleChange}
            placeholder="Ingresa nombre de paciente"
            allowClear
            defaultValue={selectedValue ? selectedValue : []}
            mode="multiple"
            optionFilterProp="label"
            status={error ? 'error' : 'success'}
            onDeselect={clearPatient}
          >
            {patients.map((patient) => (
              <Option
                key={patient.name}
                value={patient.id}
                label={patient.name}
                disabled={
                  selectedValue.length > 0
                    ? selectedValue.includes(patient.id)
                      ? false
                      : true
                    : false
                }
              >
                <Space align="center">
                  <span style={{ display: 'block', width: 25, height: 25 }}>
                    <img
                      src={require('@images/people/usuario_masculino.jpg')}
                      alt="avatar"
                      style={{
                        borderRadius: '50%',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </span>
                  {patient.name}
                </Space>
              </Option>
            ))}
          </Select>
          <p
            style={{
              color: '#C60E02',
              marginTop: 10,
              fontSize: 17,
              fontWeight: 400,
            }}
          >
            {error ? error : ''}
          </p>
        </ConfigProvider>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default PatientSelector;
