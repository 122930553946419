import React from 'react';
import styled from 'styled-components';
import Alert from '../Icons/Alert';
import ButtonClose from '../shared/ButtonClose';

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const ModalError = (props) => {
    return (
      <div className='modal'>
        <Inline>
          <div className='text-center'>
            <Alert />
          </div>
          <ButtonClose onClick={props.onClick} />
        </Inline>
        <div>
          {props.children}
        </div>
      </div>
    );
};
   
export default ModalError;