import FeatherIcon from 'feather-icons-react';
import { LabelNormal } from '../../shared/Styled';

const StatusCase = ({
  statusColor,
  statusType,
  statusIndex,
  statusName,
  archived,
}) => {
  return (
    <>
      {statusColor === 'error' &&
      statusType === 'new_case' &&
      statusIndex === 5 &&
      archived === false ? (
        <FeatherIcon icon="alert-triangle" size="16" color="#E41D11" />
      ) : archived === false ? (
        <div
          style={{
            background: statusColor === 'success' ? '#169D00' : '#E41D11',
            height: 10,
            width: 10,
            borderRadius: 5,
            marginRight: 8,
          }}
        />
      ) : (
        <div
          style={{
            background: '#BDBDBD',
            height: 10,
            width: 10,
            borderRadius: 5,
          }}
        />
      )}
      {archived === false ? (
        <LabelNormal
          style={{
            color: statusColor === 'success' ? '#169D00' : '#E41D11',
            fontSize: 15.5,
          }}
        >
          {statusName}
        </LabelNormal>
      ) : (
        <LabelNormal style={{ color: '#BDBDBD', fontSize: 15.5 }}>
          Caso Inactivo
        </LabelNormal>
      )}
    </>
  );
};

export default StatusCase;
