export const FileError = (props) => {
  if(props.size === 'md'){
    return (
      <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.916016" y="0.257812" width="51.7608" height="51.7608" rx="25.8804" fill="#C60E02"/>
        <path d="M26.7965 37.7082C33.1858 37.7082 38.3654 32.5286 38.3654 26.1392C38.3654 19.7499 33.1858 14.5703 26.7965 14.5703C20.4071 14.5703 15.2275 19.7499 15.2275 26.1392C15.2275 32.5286 20.4071 37.7082 26.7965 37.7082Z" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.7969 30.7656H26.8082" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.7969 21.5117V26.1393" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  } else if (props.size === 'sm'){
    return (
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="46" rx="23" fill="#C60E02"/>
        <path d="M23.5 33.7511C29.161 33.7511 33.7501 29.162 33.7501 23.501C33.7501 17.8401 29.161 13.251 23.5 13.251C17.8391 13.251 13.25 17.8401 13.25 23.501C13.25 29.162 17.8391 33.7511 23.5 33.7511Z" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.5 27.6001H23.5092" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.5 19.4009V23.5009" stroke="#FBFBFC" strokeWidth="2.75339" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  } else {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.71875" y="0.582031" width="26.143" height="26.143" rx="13.0715" fill="#C60E02"/>
        <path d="M13.7907 20.1884C17.4003 20.1884 20.3264 17.2622 20.3264 13.6526C20.3264 10.043 17.4003 7.11682 13.7907 7.11682C10.181 7.11682 7.25488 10.043 7.25488 13.6526C7.25488 17.2622 10.181 20.1884 13.7907 20.1884Z" stroke="#FBFBFC" strokeWidth="1.55551" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.79 16.2675H13.7965" stroke="#FBFBFC" strokeWidth="1.55551" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.79 11.0389V13.6533" stroke="#FBFBFC" strokeWidth="1.55551" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }
};
