import { Card, Divider } from 'antd';
import { LabelNormal } from '../../shared/Styled';

const PriceCard = () => {
  return (
    <div className="row">
      <div className="col-6">
        <Card className="price-aligners" style={{ textAlign: 'center' }}>
          <LabelNormal color="#3C4549" fontSize="18">
            Alineadores individuales
          </LabelNormal>

          <Divider style={{ margin: 0 }} />
          <LabelNormal color="#757575" fontSize="16">
            $599.00 MXN c/u
          </LabelNormal>
        </Card>
      </div>
      <div className="col-6">
        <Card className="price-aligners" style={{ textAlign: 'center' }}>
          <LabelNormal color="#3C4549" fontSize="18">
            Upgrade a paquete moderado
          </LabelNormal>
          <LabelNormal color="#9E9E9E" fontSize="12">
            Este upgrade a Moderado te da acceso a 60 alineadores
          </LabelNormal>
          <Divider style={{ margin: 0 }} />
          <LabelNormal color="#757575" fontSize="16">
            $1,000.00 MXN
          </LabelNormal>
        </Card>
      </div>
    </div>
  );
};

export default PriceCard;
