import { Column, InlineContainer, LabelNormal } from '../../shared/Styled';
import UniversityIcon from '../../../images/icons/university.svg';

const UsersAssigned = ({ treatment, currentView }) => (
  <div
    className="col-md-12"
    style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
  >
    {currentView === 'admin' && (
      <Column>
        <LabelNormal color="#828282" fontSize="16">
          Universidad
        </LabelNormal>
        <InlineContainer style={{ alignItems: 'center' }}>
          <img
            src={UniversityIcon}
            style={{
              height: 24,
              width: 24,
            }}
            alt="university-icon"
          />
          <LabelNormal color="#3C4549" fontSize="17">
            {treatment?.doctor?.university?.name}
          </LabelNormal>
        </InlineContainer>
      </Column>
    )}
    <Column>
      <LabelNormal color="#828282" fontSize="16">
        Profesor asignado
      </LabelNormal>
      <InlineContainer style={{ alignItems: 'center' }}>
        <img
          src={
            treatment?.teacher?.user?.photo_filename ??
            require('@images/empty-profile.png')
          }
          style={{
            height: 46,
            width: 46,
            borderRadius: 50,
          }}
          alt="profile"
        />
        <LabelNormal color="#3C4549" fontSize="17">
          {`${treatment?.teacher?.user?.first_name} ${treatment?.teacher?.user?.last_name}`}
        </LabelNormal>
      </InlineContainer>
    </Column>
    <Column>
      <LabelNormal color="#828282" fontSize="16">
        Estudiante asignado
      </LabelNormal>
      <InlineContainer style={{ alignItems: 'center' }}>
        <img
          src={
            treatment?.doctor?.user?.photo_filename ??
            require('@images/empty-profile.png')
          }
          style={{
            height: 46,
            width: 46,
            borderRadius: 50,
          }}
          alt="profile"
        />
        <LabelNormal color="#3C4549" fontSize="17">
          {`${treatment?.doctor?.user?.first_name} ${treatment?.doctor?.user?.last_name}`}
        </LabelNormal>
      </InlineContainer>
    </Column>
  </div>
);

export default UsersAssigned;
