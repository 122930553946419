import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 305px;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#FFF'};
  border: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: 17px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '305px')};
  &:focus {
    outline: none;
    border: 1px solid #e3772d;
    ::placeholder {
    }
  }
`;

/*Componente input, incluye span para identificación, estilos basicos
    /-Se hace uso de componente InputPrime-/

    Descripcion de props
    --------------------
    inputLabel: Texto que se mostrara en el span del input, como descripcion,
    placeholder: Texto que se mostrara en el placeholder del Input,
    type: Configuracion del tipo de input (password, text, etc),
    name: Nombre del Input, importante para obtener sus valores en el componente padre,
    action: Acción correspondiente que se ejecutara en el evento onChange del input, es una funcion que se describe en el componente padre
*/

const InputPassword = (props) => {
  const [eye, setEye] = useState(false);
  return (
    <Container>
      <Input
        placeholder={props.placeholder}
        maxWidth={props.maxWidth}
        type={eye ? 'text' : 'password'}
        name={props.name}
        onBlur={props.onBlur}
        {...props}
        borderColor={
          props.validation !== undefined && props.validation
            ? '#D11105'
            : 'rgba(189, 189, 189, 0.7)'
        }
      />
      <div
        style={{ position: 'absolute', right: 17, top: 20, cursor: 'pointer' }}
        onClick={() => setEye(!eye)}
      >
        {eye ? (
          <FontAwesomeIcon icon={faEyeSlash} color="#828282" />
        ) : (
          <FontAwesomeIcon icon={faEye} color="#828282" />
        )}
      </div>
    </Container>
  );
};

export default InputPassword;
