import { Link } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';

const CorrectFilesButton = ({
  role,
  patientId,
  treatmentId,
  firstName,
  lastName,
}) => (
  <Link
    to={{
      pathname: `/${
        role === 'teacher' ? 'profesor' : 'doctor'
      }/caso/correccion/${patientId}`,
      state: { treatmentId, firstName, lastName },
    }}
  >
    <ButtonPrime size="md" fontSize={15} label="Corregir errores" />
  </Link>
);

export default CorrectFilesButton;
