import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import ButtonPrime from '@components/shared/ButtonPrime';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_PATIENT_MANUFACT_STL,
} from '@graphql/query';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import FeatherIcon from 'feather-icons-react';
import zipmanufact from '@images/files/zip-manufact.svg';
import zip from '@images/files/zip.jpg';
import stl from '@images/files/stl.jpg';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import { MUTATION_UPLOAD_STL_ZIP } from '@graphql/mutation';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const CardImgContainer = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BigCircleError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  background-color: #c60e02;
  border-radius: 23px;
`;

const MediumCircleError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 23px;
  border: 3px solid white;
`;

const ManufactStl = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [treatmentId, setTreatmentId] = useState('');
  const [statusTreatment, setStatusTreatment] = useState({});

  const [loading, setLoading] = useState(false);
  const [fadeOut, setfadeOut] = useState('');

  const [error, setError] = useState(false);

  const refInput = useRef(null);

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setTreatmentId(patient.treatment_related.id);
        setStatusTreatment(patient.treatment_related.treatmentStatus);
        getPatientManufactStl({ variables: { patient_id: id } });
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatientManufactStl] = useLazyQuery(QUERY_GET_PATIENT_MANUFACT_STL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getPatientStlManufact) {
        setFileName(data.getPatientStlManufact.fileName);
        setFileUrl(data.getPatientStlManufact.fileUrl);
      }
    },
    onError: (err) => console.log(err),
  });

  const [uploadSTL] = useMutation(MUTATION_UPLOAD_STL_ZIP, {
    onCompleted: (data) => {
      if (data.addTreatmentStl) {
        if (
          (statusTreatment.type === 'planning' &&
            statusTreatment.index === 12) ||
          (statusTreatment.type === 'printing' && statusTreatment.index === 1)
        ) {
          getPatientManufactStl({ variables: { patient_id: id } });
          setfadeOut('fadeOut');
          setError(false);
          setLoading(!loading);
        } else {
          modal.close();
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => getPatient({ variables: { id } }))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Archivo cambiado correctamente
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    },
    onError: (err) => {
      console.log(err);
      setError(true);
    },
  });

  const onUploadSTL = (e) => {
    if (
      (statusTreatment.type === 'planning' && statusTreatment.index === 12) ||
      (statusTreatment.type === 'printing' && statusTreatment.index === 1)
    ) {
      setLoading(!loading);
    } else {
      modal.open(
        <ModalWaiting>
          <LabelNormal fontSize="22" color="#060809">
            Subiendo archivos
          </LabelNormal>
        </ModalWaiting>
      );
    }
    uploadSTL({
      variables: { treatment_id: treatmentId, file: e.target.files[0] },
    });
  };

  useEffect(() => {
    getPatient({ variables: { id } });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Archivos para impresión 3D</Title>
                <div style={{ marginTop: 10, marginBottom: 5 }}>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.push({ pathname: `/app/tablero` })}
                    mainLabel={'Tableros'}
                    midLabel={`${firstName} ${lastName}`}
                    onSecondClick={() => history.goBack()}
                    endLabel={'STL’s para manufactura'}
                  />
                </div>
                {(statusTreatment.type === 'planning' &&
                  statusTreatment.index === 12) ||
                  (statusTreatment.type === 'printing' &&
                    statusTreatment.index === 1 && (
                      <LabelNormal color="#757575" fontSize="17">
                        Sube los archivos para la manufactura del caso. Solo
                        archivos en formato .zip.
                      </LabelNormal>
                    ))}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ marginTop: 20 }}>
                <div style={{ paddingBottom: 10 }}>
                  <div style={{ position: 'relative' }}>
                    {statusTreatment.type === 'planning' &&
                    statusTreatment.index === 12 ? (
                      <CardImgContainer
                        style={{
                          height: 320,
                          minWidth: 495,
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        src={zip}
                        onClick={() => refInput.current.click()}
                      />
                    ) : (
                      <CardImgContainer
                        style={{
                          height: 320,
                          minWidth: 495,
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        src={stl}
                        onClick={() => refInput.current.click()}
                      />
                    )}

                    {loading && (
                      <div
                        className={fadeOut}
                        style={{
                          height: 159,
                          width: 159,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          right: 46,
                          bottom: 120,
                        }}
                      >
                        <div className="loader" />
                        <p
                          style={{
                            fontSize: 25,
                            color: '#9E9E9E',
                            position: 'absolute',
                            right: 50,
                            bottom: 40,
                          }}
                        >
                          00%
                        </p>
                      </div>
                    )}

                    {error && (
                      <div
                        style={{
                          height: 159,
                          width: 250,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          right: 5,
                          bottom: 120,
                        }}
                      >
                        <BigCircleError>
                          <MediumCircleError>
                            <p
                              style={{
                                fontWeight: 'bolder',
                                color: 'white',
                                fontSize: 17,
                              }}
                            >
                              !
                            </p>
                          </MediumCircleError>
                        </BigCircleError>
                        <p style={{ fontSize: 16.8, color: '#757575' }}>
                          No se pudo subir tu archivo.
                          <br />
                          Inténtalo de nuevo
                        </p>
                      </div>
                    )}

                    {statusTreatment.type === 'planning' &&
                      statusTreatment.index === 12 &&
                      (loading === false ? (
                        <div>
                          <ButtonPrime
                            icon="share"
                            size="30"
                            color="#FFF"
                            border="none"
                            onClick={() => refInput.current.click()}
                            style={{
                              background: '#EB9542',
                              borderRadius: 26,
                              height: 46,
                              width: 46,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'absolute',
                              right: -90,
                              bottom: 65,
                              paddingRight: 2,
                            }}
                          />
                          <input
                            type="file"
                            accept=".zip"
                            style={{ display: 'none' }}
                            ref={refInput}
                            onChange={(e) => onUploadSTL(e)}
                          />
                        </div>
                      ) : (
                        <div></div>
                      ))}

                    {fileUrl && loading === false ? (
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          background: '#EB9542',
                          height: 48,
                          width: 48,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 26,
                          position: 'absolute',
                          right: -90,
                          bottom: 70,
                        }}
                        download={fileName}
                      >
                        <FeatherIcon icon="download" color="#FFF" size="30" />
                      </a>
                    ) : (
                      <div></div>
                    )}

                    <LabelNormal color="#3C4549" fontSize="28">
                      {'STL’s impresión 3D'}
                    </LabelNormal>
                  </div>
                </div>
              </div>
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <LabelNormal
                  color="#828282"
                  fontSize="18.5"
                  style={{ marginTop: 0 }}
                >
                  {fileName}
                </LabelNormal>
                {statusTreatment.type === 'printing' &&
                  statusTreatment.index === 1 && (
                    <div className="col-md-6" style={{ marginLeft: 30 }}>
                      <ButtonPrime
                        label="Cambiar archivo"
                        fontSize="17"
                        background="transparent"
                        colorEnabled="#436FF1"
                        color="#757575"
                        border="none"
                        backgroundEnabled="transparent"
                        onClick={() => refInput.current.click()}
                        style={{ textDecoration: 'underline' }}
                      />
                      <input
                        type="file"
                        accept=".zip"
                        style={{ display: 'none' }}
                        ref={refInput}
                        onChange={(e) => onUploadSTL(e)}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ManufactStl;
