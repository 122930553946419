import { Alert } from 'antd';

const CustomAlert = ({ type, description, ...props }) => {
  return (
    <Alert
      {...props}
      style={{
        marginTop: 10,
        backgroundColor: '#eeeef2',
        borderColor: '#9E9E9E',
      }}
      description={description}
      type={type}
      showIcon
    />
  );
};

export default CustomAlert;
