import { InlineContainer, LabelNormal } from '../../shared/Styled';

const DoctorAssigned = ({ doctor }) => (
  <div className="col-md-12">
    <LabelNormal
      color="#828282"
      fontSize="16"
      style={{ marginBottom: 10, marginTop: 30 }}
    >
      Doctor
    </LabelNormal>
    <InlineContainer style={{ alignItems: 'center' }}>
      <img
        src={
          doctor?.user?.photo_filename ?? require('@images/empty-profile.png')
        }
        style={{
          height: 46,
          width: 46,
          borderRadius: 50,
        }}
        alt="profile"
      />
      <LabelNormal color="#3C4549" fontSize="17">
        {`${doctor?.user?.first_name} ${doctor?.user?.last_name}`}
      </LabelNormal>
    </InlineContainer>
  </div>
);

export default DoctorAssigned;
