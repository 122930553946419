import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_MY_COUNTRIES } from '@graphql/query';
import { useQuery } from '@apollo/client';
import Kanban from '@components/shared/Kanban';
import { useHistory } from 'react-router-dom';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
  transition: all 0.3s ease-out;
  &:hover {
    font-size: 25px;
    border-bottom: 2px solid transparent !important;
    color: #3c4549;
  }
  &:active {
    color: #3c4549;
    border-bottom: 2px solid #3c4549 !important;
  }
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
`;

const options = [
  { id: 'REQUIRE_ATENTION', name: 'Requieren de atención' },
  { id: 'IN_PROCESS', name: 'En proceso' },
  { id: 'CLOSED', name: 'Terminados / inactivos' },
];

const Home = () => {
  const history = useHistory();

  const [selectItem, setSelectItem] = useState('cryst_aligner');
  const [selectedOption, setSelectedOption] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedCountryLabel, setSelectedCountryLabel] = useState('');

  const formatter = new Intl.ListFormat('es', {
    style: 'long',
    type: 'conjunction',
  });

  useEffect(() => {
    if (localStorage.getItem('memori-treatment')) {
      setSelectItem(localStorage.getItem('memori-treatment'));
    }
  }, []);

  useEffect(() => {
    if (selectItem) {
      localStorage.setItem('memori-treatment', selectItem);
    }
  }, [selectItem]);

  const { loading } = useQuery(QUERY_GET_MY_COUNTRIES, {
    onCompleted: (data) => {
      setCountries(data.getCountries);
      setSelectedCountries(data.getCountries.map((country) => country.id));
      setSelectedCountryLabel(
        formatter.format(data.getCountries.map((country) => country.name))
      );
    },
  });

  useEffect(() => {
    setSelectedOption(['REQUIRE_ATENTION', 'IN_PROCESS', 'CLOSED']);
  }, []);

  useEffect(() => {
    if (!firstLoad) setFirstLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <InlineContainer
            style={{
              justifyContent: 'space-between',
              marginRight: '25%',
              marginLeft: '25%',
              marginBottom: 43,
            }}
          >
            <LabelNormal
              fontSize="25"
              onClick={() => setSelectItem('cryst_aligner')}
              color={selectItem === 'cryst_aligner' ? '#3C4549' : '#C8C8C8'}
              style={{
                borderBottomColor:
                  selectItem === 'cryst_aligner' ? '#3C4549' : 'transparent',
                borderBottomStyle: 'solid',
                borderBottomWidth: 2,
                cursor: 'pointer',
              }}
            >
              Cryst Aligner
            </LabelNormal>
            <LabelNormal
              fontSize="25"
              onClick={() => setSelectItem('smile_system')}
              color={selectItem === 'smile_system' ? '#3C4549' : '#C8C8C8'}
              style={{
                borderBottomColor:
                  selectItem === 'smile_system' ? '#3C4549' : 'transparent',
                borderBottomStyle: 'solid',
                borderBottomWidth: 2,
                cursor: 'pointer',
              }}
            >
              Smile System
            </LabelNormal>
          </InlineContainer>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                onChange={(e) => setSearch(e.target.value)}
                onBlur={() => setSearching(true)}
                value={search}
                marginRight="33"
              />
              <DropdownCustom
                label="Categorías"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              {!loading && countries.length > 0 && (
                <DropdownCustom
                  label="País"
                  data={countries}
                  selectedOption={selectedCountries}
                  setSelectedOption={(options) => {
                    const countriesSelected = countries.filter((country) =>
                      options.includes(country.id)
                    );
                    setSelectedCountryLabel(
                      formatter.format(
                        countriesSelected.map((country) => country.name)
                      )
                    );
                    setSelectedCountries(options);
                  }}
                />
              )}
              <LabelNormal
                style={{
                  fontSize: 15,
                  marginRight: 3,
                  marginTop: 3,
                }}
              >
                {!selectedCountries.length
                  ? 'No se ha seleccionado ningun país de la lista'
                  : `Se muestran pacientes de ${selectedCountryLabel}`}
              </LabelNormal>
            </InlineContainer>
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 27 }}>
            {countries !== undefined && countries.length > 0 && firstLoad && (
              <div>
                {selectItem === 'cryst_aligner' ? (
                  <Kanban
                    treatment={'CRYST_ALIGNER'}
                    categories={selectedOption}
                    countries={selectedCountries}
                    search={search}
                    searching={searching}
                    setSearching={setSearching}
                    display={selectItem === 'cryst_aligner'}
                    key="cryst_aligner"
                  />
                ) : (
                  <Kanban
                    treatment={'SMILE_SYSTEM'}
                    categories={selectedOption}
                    countries={selectedCountries}
                    search={search}
                    searching={searching}
                    setSearching={setSearching}
                    display={selectItem === 'smile_system'}
                    key="smile_system"
                  />
                )}
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Home;
