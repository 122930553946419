import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import LinkUnderline from '../../components/shared/LinkUnderline';
import ModalFormSurgery from '../../components/Modal/ModalFormSurgery';
import { modal } from '@components/ModalManagement';
import LabelUnderline from '../../components/shared/LabelUnderline';
import ModalError from '../../components/Modal/ModalError';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import ButtonPrime from '@components/shared/ButtonPrime';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const Surgery = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [surgeries, setSurgeries] = useState([]);

  useEffect(() => {
    fetchSurgeries();
  }, []);

  const getInstance = () => {
    const token = localStorage.getItem('token-b360');
    return axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchSurgeries = async () => {
    const instance = getInstance();
    const response = await instance.get(`/api/surgery/list/${id}`);
    const data = await response.data.surgeries;
    setSurgeries(data);
  };

  const deleteSurgery = async (id) => {
    const instance = getInstance();
    try {
      await instance.get(`/api/surgery/delete/${id}`);
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => fetchSurgeries())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Consultorio eliminado exitosamente
          </LabelNormal>
        </ModalSuccess>
      );
    } catch (e) {
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
          >
            Error al borrar el consultorio
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Hubo un error al intentar borrar el consultorio. Verifica que no
            este siendo utilizado e inténtalo de nuevo.
          </LabelNormal>
        </ModalError>
      );
    }
  };

  const deleteModal = (idSurgery) => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
          Se eliminará el consultorio del Dr. Finder
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginBottom: 14 }}>
          Tu consultorio ya no aparecerá en el mapa del Dr. Finder en B360.mx
        </LabelNormal>
        <div style={{ marginTop: 10 }}>
          <ButtonPrime
            label={'Eliminar consultorio'}
            onClick={() => deleteSurgery(idSurgery)}
          ></ButtonPrime>
        </div>
      </ModalWarning>
    );
  };

  const showModalForm = (idSurgery) => {
    const data = surgeries.find((sur) => sur.id === idSurgery);

    modal.open(
      <ModalFormSurgery doctorId={id} data={data} reloadList={fetchSurgeries} />
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="perfil" />
      <ContainHome style={{ height: '100vh' }}>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Perfil Dr. Finder</Title>
              </div>
              <div
                className="col-md-8"
                style={{ marginBottom: 10, marginTop: 15 }}
              >
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{ marginLeft: 0 }}
                >
                  Los consultorios que aparezcan aquí, son los que se publican
                  en el Dr. Finder de b360.mx
                </LabelNormal>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Title style={{ fontSize: 22 }}>Consultorios</Title>
              </div>
              {!surgeries?.length && (
                <div
                  className="col-md-4 offset-md-4"
                  style={{
                    marginBottom: 10,
                    height: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LabelNormal color="#757575" fontSize="18">
                    Registra tu consultorio para la Red B360.
                  </LabelNormal>
                </div>
              )}
            </div>

            <div className="row">
              {surgeries?.map((surgery) => {
                return (
                  <div
                    className="col-md-4"
                    style={{ marginBottom: 10 }}
                    key={`${surgery.id}-surg`}
                  >
                    <CardInlineContainer
                      style={{
                        borderRadius: 10,
                        width: 320,
                        marginTop: 17,
                        marginBottom: 18,
                        padding: 16,
                      }}
                    >
                      <LabelNormal
                        color="#3C4549"
                        fontSize="18"
                        fontWeight="400"
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        {surgery.name}
                      </LabelNormal>

                      <LabelNormal
                        color="#828282"
                        fontSize="17"
                        fontWeight="400"
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        {`${surgery.street} ${surgery.number ?? ''} ${
                          surgery.internal_number || ''
                        },
                          ${surgery.state}, ${surgery.postal_code} ${
                          surgery.locality
                        }`}
                      </LabelNormal>
                      <InlineContainer>
                        <LabelUnderline
                          color="#757575"
                          fontSize="17"
                          label={'Editar'}
                          onClick={() => showModalForm(surgery.id)}
                        />

                        <LabelUnderline
                          color="#757575"
                          fontSize="17"
                          onClick={() => deleteModal(surgery.id)}
                          label={'Eliminar'}
                        />
                      </InlineContainer>
                    </CardInlineContainer>
                  </div>
                );
              })}
            </div>
            <LabelUnderline
              color="#757575"
              fontSize="17"
              onClick={() => showModalForm()}
              label="Añadir consultorio"
            />
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Surgery;
