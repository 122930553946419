export const treatmentStatusEnum = Object.freeze({
  incompleteRegistry: 1,
  pendingPayment: 2,
  reviewingFiles: 3,
  waitingModel: 4,
  reportedErrors: 5,
  solvedError: 6,
  pausedCase: 7,
  readyToPlanning: 8,
  readyVisor: 9,
  waitingPlanning: 10,
  readyPlanning: 11,
  readyToApprove: 12,
  scheduledSmartcheck: 13,
  rescheduledSmartcheck: 14,
  daySmartcheck: 15,
  missedSmartcheck: 16,
  endedSmartcheck: 17,
  scheduledSecondSmartcheck: 18,
  endedSecondSmartcheck: 19,
  approvedManufacture: 20,
  readyToPrinting: 21,
  printing: 22,
  thermoforming: 23,
  warehouse: 24,
  packing: 25,
  readyToShipping: 26,
  shipping: 27,
  delivered: 28,
  waitingEvidence: 29,
  endedCase: 30,
  inactiveCase: 31,
  waitingApprove: 33,
  doctorMessage: 35,
  doubtsResolved: 36,
});

export const fileStatusEnum = Object.freeze({
  unavailable: 0,
  agreedChanges: 1,
  errorsReported: 2,
  allFilesApproved: 3,
});
