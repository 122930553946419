import {
  CardInlineContainer,
  ChipCircle,
  ChipCircleLong,
  ChipCircleSecond,
  CustomHR,
  InlineContainer,
  LabelNormal,
} from '../shared/Styled';

const PackCard = ({
  onClick,
  selected,
  pack,
  totalAligners,
  disable = false,
}) => {
  const enabled =
    totalAligners <=
    parseInt(pack.principal_aligners ?? totalAligners);
  return (
    <CardInlineContainer
      key={Math.random()}
      onClick={() => (enabled && !disable ? onClick() : null)}
      style={{
        borderRadius: 10,
        minHeight: 105,
        maxWidth: 334,
        marginTop: 10,
        marginBottom: 10,
        padding: 16,
        cursor:
          enabled && (!disable || (disable && selected.id === pack.id))
            ? 'pointer'
            : 'not-allowed',
        opacity:
          enabled && (!disable || (disable && selected.id === pack.id))
            ? 1
            : 0.3,
        boxShadow: '0px 0px 8px rgba(162, 162, 162, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
      className={selected.id === pack.id ? 'gradiant-border' : ''}
    >
      {pack.principal_aligners ? (
        <div className="flex gap-2">
          <div className="flex flex-col gap-1">
            <ChipCircle>
              <LabelNormal fontSize="17" color="#FFF">
                {pack.principal_aligners}
              </LabelNormal>
            </ChipCircle>
            <LabelNormal color="#3C4549" fontSize="13">
              Alineadores
            </LabelNormal>
          </div>
          {pack.refinement_aligners !== '0' && (
            <div className="flex flex-col gap-1">
              <ChipCircleSecond>
                <LabelNormal fontSize="17" color="#3C4549">
                  {pack.refinement_aligners}
                </LabelNormal>
              </ChipCircleSecond>
              <LabelNormal color="#3C4549" fontSize="13">
                Refinamiento
              </LabelNormal>
            </div>
          )}
        </div>
      ) : (
        <InlineContainer>
          <div className="col-md-11" align="center">
            <ChipCircleLong>
              <LabelNormal fontSize="17" color="#FFF">
                Alineadores ilimitados
              </LabelNormal>
            </ChipCircleLong>
          </div>
        </InlineContainer>
      )}
      <CustomHR />
      <div>
        <InlineContainer>
          <LabelNormal color="#3C4549" fontSize="17">
            {pack.name}
          </LabelNormal>
        </InlineContainer>
        <InlineContainer>
          <LabelNormal
            color="#3C4549"
            fontSize="17"
          >{`$${pack.price.amount} ${pack.price.currency}`}</LabelNormal>
        </InlineContainer>
      </div>
    </CardInlineContainer>
  );
};

export default PackCard;
