import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import InputSearch from '@components/shared/InputSearch';
import CloseSession from '../../components/shared/CloseSession';
import LabelUnderline from '../../components/shared/LabelUnderline';
import {
  QUERY_GET_UNIVERSITY_ID,
  QUERY_GET_UNIVERSITY_DATA,
} from '@graphql/query';
import { useHistory } from 'react-router-dom';
import Input from '@components/shared/Input';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ChipIcons, { Cap, ClipBoard } from '@components/shared/ChipIcons';
import { MUTATION_UPDATE_PASSWORD } from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import moment from 'moment';
import { Count } from '@components/Icons/Count';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import MailIcon from '@images/icons/mail.svg';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
  max-width: 1072px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const HeaderCardTable = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const University = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [changePass, setChangePass] = useState(false);
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [search, setSearch] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [photo, setPhoto] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const [countCases, setCountCases] = useState('');
  const [countTeacher, setCountTeacher] = useState('');
  const [countStudent, setCountStudent] = useState('');
  const [countDetails, setCountDetails] = useState({});

  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [idUser, setIdUser] = useState('');
  const [treatments, setTreatments] = useState([]);

  const [getUniversity] = useLazyQuery(QUERY_GET_UNIVERSITY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.university) {
        const university = data.university;

        setIdUser(university.user.id);
        setTreatments(university.treatments);

        setName(university.name);
        setCountry(university.user.country.name);
        setFirstName(university.user.first_name);
        setLastName(university.user.last_name);
        setPhone(university.user.phone);
        setEmail(university.user.email);
        setPhoto(university.user.photo_filename);
        setCreatedAt(university.user.created_at);

        setCountCases(university.count_treatments);
        setCountStudent(university.count_doctors);
        setCountTeacher(university.count_teachers);

        setStudents(university.students);
        setTeachers(university.teachers);

        setCountDetails(university);
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  const [getUniversityData] = useLazyQuery(QUERY_GET_UNIVERSITY_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getUniversityData) {
        const university = data.getUniversityData;
        setTreatments(university.treatments);
        setStudents(university.doctors);
        setTeachers(university.teachers);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getUniversity({ variables: { id: localStorage.getItem('b360-role_id') } });
    getUniversityData({
      variables: { university_id: +localStorage.getItem('b360-role_id') },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingSkeleton(true);
    if (search) {
      getUniversityData({
        variables: {
          university_id: +localStorage.getItem('b360-role_id'),
          search,
        },
      });
    } else {
      getUniversityData({
        variables: { university_id: +localStorage.getItem('b360-role_id') },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD, {
    onCompleted: (data) => {
      if (data.updatePassword) {
        setChangePass(false);
        setPassword('');
        setConfirmPass('');
        modal.open(
          <ModalSuccess onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Cambiaste tu contraseña con éxito
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Ingresa con la nueva contraseña cuando inicies sesión de nuevo en
              la plataforma.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="perfil" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => {}}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Perfil</Title>
              </div>
              <div className={'col-md-7'} style={{ marginTop: 39 }}>
                <div className="row">
                  <div className="col-md-12">
                    <InlineContainer>
                      {!loadingSkeleton ? (
                        <div>
                          <Avatar
                            src={
                              photo
                                ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                                : require('@images/university.png')
                            }
                            height="161"
                            width="161"
                            borderRadius="30"
                          />
                          <LabelNormal
                            color="#828282"
                            fontSize="14"
                            style={{ textAlign: 'center' }}
                          >
                            Miembro desde
                          </LabelNormal>
                          <LabelNormal
                            color="#828282"
                            fontSize="14"
                            style={{ textAlign: 'center' }}
                          >
                            {moment(createdAt).format('DD/MM/YYYY')}
                          </LabelNormal>
                        </div>
                      ) : (
                        <div>
                          <Skeleton
                            height={161}
                            borderRadius={27}
                            width={161}
                            baseColor={'#EEEEF2'}
                            highlightColor={'#FFF'}
                          />
                          <center>
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              width={97}
                              height={27}
                              borderRadius={5}
                              style={{ marginTop: 10 }}
                              highlightColor={'#FFF'}
                            />
                          </center>
                        </div>
                      )}
                      {!loadingSkeleton ? (
                        <div style={{ marginLeft: 35 }}>
                          <InlineContainer style={{ alignItems: 'center' }}>
                            <LabelNormal
                              style={{ marginRight: 15 }}
                              fontSize="30"
                              color="#3C4549"
                            >
                              {name}
                            </LabelNormal>
                            <Link
                              to={`/universidad/perfil/${localStorage.getItem(
                                'b360-role_id'
                              )}`}
                            >
                              <FeatherIcon icon="edit" color="#3C4549" />
                            </Link>
                          </InlineContainer>
                          <InlineContainer>
                            <div className="row">
                              <div className="col-md-5">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="map-pin" size={19} />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {country}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-5">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="user" size={19} />
                                  <LabelNormal
                                    fontSize="14"
                                    color="#3C4549"
                                  >{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-5">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="phone" size={19} />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <img src={MailIcon} alt="email" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {email}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-12">
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  onClick={() => setChangePass(!changePass)}
                                  label={'Cambiar contraseña'}
                                />
                              </div>
                            </div>
                          </InlineContainer>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginLeft: 35,
                            marginTop: 10,
                            width: '100%',
                          }}
                        >
                          <div>
                            <Skeleton
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <div className="row">
                              <div className="col-md-3">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div className="col-md-7">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-5">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div className="col-md-7">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-4">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </InlineContainer>
                  </div>
                  {!changePass && (
                    <div className="col-md-12" style={{ marginTop: 66 }}>
                      <InputSearch
                        placeholder="Busca por nombre o ID"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div style={{ marginTop: 30 }}>
                        {!loadingSkeleton ? (
                          <div className="col-md-12">
                            <LabelNormal fontSize="22" color="#454D58">
                              Casos
                            </LabelNormal>

                            <HeaderCardTable>
                              <div className="row">
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Paciente
                                  </LabelNormal>
                                </div>
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    ID
                                  </LabelNormal>
                                </div>
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Status
                                  </LabelNormal>
                                </div>
                              </div>
                            </HeaderCardTable>
                            <CardContainer>
                              {treatments.length > 0 ? (
                                <>
                                  {treatments.map((item) => (
                                    <div className="row">
                                      <div className="col-md-4">
                                        <Link
                                          to={`/universidad/caso/${item.patient.id}`}
                                        >
                                          <LabelNormal
                                            color="#757575"
                                            fontSize="15"
                                            style={{
                                              textDecorationLine: 'underline',
                                            }}
                                          >{`${item.patient.first_name} ${item.patient.last_name}`}</LabelNormal>
                                        </Link>
                                      </div>
                                      <div className="col-md-4">
                                        <LabelNormal
                                          color="#757575"
                                          style={{ textAlign: 'center' }}
                                          fontSize="15"
                                        >
                                          {item.id}
                                        </LabelNormal>
                                      </div>
                                      <div className="col-md-4">
                                        <LabelNormal
                                          color="#757575"
                                          fontSize="15"
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item.treatmentStatus.name}
                                        </LabelNormal>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <LabelNormal
                                  color="#BDBDBD"
                                  fontSize="15"
                                  style={{ textAlign: 'center' }}
                                >
                                  Sin casos registrados
                                </LabelNormal>
                              )}
                            </CardContainer>
                          </div>
                        ) : (
                          <div className="row" style={{ height: 130 }}>
                            <div className="col-md-12">
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={27}
                                width={130}
                                highlightColor={'#FFF'}
                                style={{ marginBottom: 20 }}
                              />
                              <Skeleton
                                baseColor={'#EEEEF2'}
                                height={50}
                                style={{
                                  borderTopLeftRadius: 20,
                                  borderTopRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={'100%'}
                                style={{
                                  borderBottomLeftRadius: 20,
                                  borderBottomRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          </div>
                        )}

                        {!loadingSkeleton ? (
                          <div className="col-md-12" style={{ marginTop: 66 }}>
                            <InlineContainer
                              style={{ justifyContent: 'space-between' }}
                            >
                              <LabelNormal fontSize="22" color="#454D58">
                                Estudiantes
                              </LabelNormal>
                              {/* <LinkUnderline
                                id={localStorage.getItem('b360-role_id')}
                                label={'Agregar nuevo estudiante'}
                                name={name}
                                link={`/universidad/${localStorage.getItem(
                                  'b360-role_id'
                                )}/registrar-estudiante`}
                              /> */}
                            </InlineContainer>

                            <HeaderCardTable>
                              <div className="row">
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Estudiante
                                  </LabelNormal>
                                </div>
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Activos
                                  </LabelNormal>
                                </div>
                                <div className="col-md-4">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Terminados
                                  </LabelNormal>
                                </div>
                              </div>
                            </HeaderCardTable>
                            <CardContainer>
                              {students.length > 0 ? (
                                <>
                                  {students.map((item) => (
                                    <div className="row">
                                      <div className="col-md-4">
                                        <Link
                                          to={`/universidad/estudiante/${item.id}`}
                                        >
                                          <LabelNormal
                                            color="#757575"
                                            fontSize="15"
                                            style={{
                                              textDecorationLine: 'underline',
                                            }}
                                          >{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                        </Link>
                                      </div>
                                      <div className="col-md-4">
                                        {item.count_treatments > 0 ? (
                                          <LabelNormal
                                            color="#757575"
                                            fontSize="15"
                                            style={{ textAlign: 'center' }}
                                          >
                                            {item.count_treatments}
                                          </LabelNormal>
                                        ) : (
                                          <Count />
                                        )}
                                      </div>
                                      <div className="col-md-4">
                                        <LabelNormal
                                          color="#757575"
                                          fontSize="15"
                                          style={{ textAlign: 'center' }}
                                        >
                                          {item.count_inactives_treatments}
                                        </LabelNormal>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <LabelNormal
                                  color="#BDBDBD"
                                  fontSize="15"
                                  style={{ textAlign: 'center' }}
                                >
                                  Sin estudiantes registrados
                                </LabelNormal>
                              )}
                            </CardContainer>
                          </div>
                        ) : (
                          <div
                            className="row"
                            style={{ height: 130, marginTop: 120 }}
                          >
                            <div className="col-md-12">
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={27}
                                width={130}
                                highlightColor={'#FFF'}
                                style={{ marginBottom: 20 }}
                              />
                              <Skeleton
                                baseColor={'#EEEEF2'}
                                height={50}
                                style={{
                                  borderTopLeftRadius: 20,
                                  borderTopRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={'100%'}
                                style={{
                                  borderBottomLeftRadius: 20,
                                  borderBottomRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          </div>
                        )}
                        {!loadingSkeleton ? (
                          <div className="col-md-12" style={{ marginTop: 66 }}>
                            <InlineContainer
                              style={{ justifyContent: 'space-between' }}
                            >
                              <LabelNormal fontSize="22" color="#454D58">
                                Profesores
                              </LabelNormal>
                              {/* <LinkUnderline
                                id={localStorage.getItem('b360-role_id')}
                                label={'Agregar nuevo profesor'}
                                name={name}
                                link={`/universidad/${localStorage.getItem(
                                  'b360-role_id'
                                )}/registrar-profesor`}
                              /> */}
                            </InlineContainer>
                            <HeaderCardTable>
                              <div className="row">
                                <div className="col-md-7">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Profesor
                                  </LabelNormal>
                                </div>
                                <div className="col-md-5">
                                  <LabelNormal color="#3C4549" fontSize="17">
                                    Estudiantes asignados
                                  </LabelNormal>
                                </div>
                              </div>
                            </HeaderCardTable>
                            <CardContainer>
                              {teachers.length > 0 ? (
                                <>
                                  {teachers.map((item) => (
                                    <div className="row">
                                      <div className="col-md-7">
                                        <Link
                                          to={`/universidad/profesor/${item.id}`}
                                        >
                                          <LabelNormal
                                            color="#757575"
                                            fontSize="15"
                                            style={{
                                              textDecorationLine: 'underline',
                                            }}
                                          >{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                        </Link>
                                      </div>
                                      <div className="col-md-5">
                                        {item.count_students > 0 ? (
                                          <LabelNormal
                                            color="#757575"
                                            fontSize="15"
                                            style={{ textAlign: 'center' }}
                                          >
                                            {item.count_students}
                                          </LabelNormal>
                                        ) : (
                                          <Count />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <LabelNormal
                                  color="#BDBDBD"
                                  fontSize="15"
                                  style={{ textAlign: 'center' }}
                                >
                                  Sin profesores registrados
                                </LabelNormal>
                              )}
                            </CardContainer>
                          </div>
                        ) : (
                          <div
                            className="row"
                            style={{ height: 130, marginTop: 120 }}
                          >
                            <div className="col-md-12">
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={27}
                                width={130}
                                highlightColor={'#FFF'}
                                style={{ marginBottom: 20 }}
                              />
                              <Skeleton
                                baseColor={'#EEEEF2'}
                                height={50}
                                style={{
                                  borderTopLeftRadius: 20,
                                  borderTopRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={'100%'}
                                style={{
                                  borderBottomLeftRadius: 20,
                                  borderBottomRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {changePass && (
                    <div className="col-md-12" style={{ marginTop: 66 }}>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="22"
                        style={{ letterSpacing: '2%', margin: 0 }}
                      >
                        Cambio de contraseña
                      </LabelNormal>
                      <div style={{ marginTop: 15 }}>
                        <Input
                          label="Nueva contraseña"
                          placeholder="Contraseña"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          validation="passwordlenght"
                          style={{
                            borderColor:
                              password !== '' &&
                              confirmPass !== '' &&
                              password !== confirmPass
                                ? '#C60E02'
                                : '',
                            borderWeight: 1,
                            borderstyle: 'solid',
                            maxWidth: 305,
                          }}
                        />
                        <div style={{ marginTop: 20 }}>
                          <Input
                            label="Confirmar contraseña"
                            placeholder="Contraseña"
                            type="password"
                            onChange={(e) => setConfirmPass(e.target.value)}
                            style={{
                              borderColor:
                                password !== '' &&
                                confirmPass !== '' &&
                                password !== confirmPass
                                  ? '#C60E02'
                                  : password.length >= 8 && confirmPass !== ''
                                  ? '#169D00'
                                  : '',
                              borderWeight: 1,
                              borderstyle: 'solid',
                              maxWidth: 305,
                            }}
                          />
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            password !== confirmPass && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas no coinciden
                              </p>
                            )}
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            password === confirmPass && (
                              <p
                                style={{
                                  color: '#169D00',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas coinciden
                              </p>
                            )}
                        </div>
                      </div>
                      <InlineContainer
                        style={{ marginTop: 25, alignItems: 'center' }}
                      >
                        <ButtonPrime
                          label="Guardar cambios"
                          disabled={
                            password === '' ||
                            password.length < 8 ||
                            confirmPass === '' ||
                            password !== confirmPass
                          }
                          maxWidth="198px"
                          onClick={() =>
                            updatePassword({
                              variables: {
                                password,
                                password_confirmation: confirmPass,
                              },
                            })
                          }
                        />
                        <ButtonPrime
                          label="Cancelar"
                          background="transparent"
                          color="#3C4549"
                          border="1px solid #3C4549"
                          minWidth="127"
                          style={{ marginLeft: 20 }}
                          onClick={() => setChangePass(false)}
                        />
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              {!changePass && (
                <div className="col-md-4" style={{ marginTop: 39 }}>
                  <div className="row">
                    {!loadingSkeleton ? (
                      <div className="col-md-12">
                        <HeaderCard>
                          <LabelNormal color="#3C4549" fontSize="17">
                            Resumen
                          </LabelNormal>
                        </HeaderCard>
                        <CardContainer>
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              marginBottom: 25,
                            }}
                          >
                            <LabelNormal color="#757575" fontSize="16">
                              Estudiantes
                            </LabelNormal>
                            <ChipIcons
                              name={countStudent}
                              icon="cap"
                              color="#C8C8C8"
                            />
                          </InlineContainer>
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              marginBottom: 25,
                            }}
                          >
                            <LabelNormal color="#757575" fontSize="16">
                              Profesores
                            </LabelNormal>
                            <ChipIcons
                              name={countTeacher}
                              icon="libraries"
                              color="#C8C8C8"
                            />
                          </InlineContainer>
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              marginBottom: 25,
                            }}
                          >
                            <LabelNormal color="#757575" fontSize="16">
                              Casos
                            </LabelNormal>
                            <ChipIcons
                              name={countCases}
                              icon="clipboard"
                              color="#C8C8C8"
                            />
                          </InlineContainer>
                        </CardContainer>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={50}
                            width={'100%'}
                            style={{
                              borderTopLeftRadius: 27,
                              borderTopRightRadius: 27,
                            }}
                            highlightColor={'#FFF'}
                          />
                          <Skeleton
                            baseColor={'#F6F6F8'}
                            height={200}
                            style={{
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                            }}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    )}
                    {!loadingSkeleton ? (
                      <div className="col-md-12" style={{ marginTop: 25 }}>
                        <HeaderCard>
                          <LabelNormal color="#3C4549" fontSize="17">
                            Detalle
                          </LabelNormal>
                        </HeaderCard>
                        <CardContainer>
                          <InlineContainer style={{ alignItems: 'center' }}>
                            <Cap color="#757575" />
                            <LabelNormal fontSize="17" color="#757575">
                              Estudiantes
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              marginTop: 10,
                            }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Actuales
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_doctors
                                ? countDetails.count_doctors
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{ justifyContent: 'space-between' }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Egresados activos
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_doctors_active
                                ? countDetails.count_doctors_active
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{ justifyContent: 'space-between' }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Egresados inactivos
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_doctors_inactive
                                ? countDetails.count_doctors_inactive
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>

                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              borderTop: '1px solid #C8C8C8',
                              marginTop: 10,
                            }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Totales
                            </LabelNormal>
                            <LabelNormal color="#4F4F4F" fontSize="17">
                              {countDetails.count_doctors
                                ? countDetails.count_doctors
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>

                          <InlineContainer
                            style={{ alignItems: 'center', marginTop: 33 }}
                          >
                            <ClipBoard color="#757575" />
                            <LabelNormal fontSize="17" color="#757575">
                              Casos
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              marginTop: 10,
                            }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Activos
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_treatments
                                ? countDetails.count_treatments
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{ justifyContent: 'space-between' }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Terminados
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_finished_treatments
                                ? countDetails.count_finished_treatments
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{ justifyContent: 'space-between' }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Inactivos
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_inactives_treatments
                                ? countDetails.count_inactives_treatments
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                          <InlineContainer
                            style={{ justifyContent: 'space-between' }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Heredados
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              {countDetails.count_heredity_treatments
                                ? countDetails.count_heredity_treatments
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>

                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              borderTop: '1px solid #C8C8C8',
                              marginTop: 10,
                            }}
                          >
                            <LabelNormal color="#BDBDBD" fontSize="14">
                              Totales
                            </LabelNormal>
                            <LabelNormal color="#4F4F4F" fontSize="17">
                              {countDetails.count_treatments
                                ? countDetails.count_treatments
                                : '0'}
                            </LabelNormal>
                          </InlineContainer>
                        </CardContainer>
                      </div>
                    ) : (
                      <div className="col-md-12" style={{ marginTop: 25 }}>
                        <div className="col-md-12">
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={50}
                            width={'100%'}
                            style={{
                              borderTopLeftRadius: 27,
                              borderTopRightRadius: 27,
                            }}
                            highlightColor={'#FFF'}
                          />
                          <Skeleton
                            baseColor={'#F6F6F8'}
                            height={400}
                            style={{
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                            }}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    )}
                    {!loadingSkeleton && (
                      <div
                        className="col-md-12"
                        style={{
                          marginTop: 108,
                          marginBottom: 108,
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <CloseSession onClick={() => props.logout()} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(action.logout()),
});

export default connect(null, mapDispatchToProps)(University);
