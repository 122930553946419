import React, { Component } from 'react';
import styled from 'styled-components';
import { EventEmitter } from 'events';
import FeatherIcons from 'feather-icons-react';

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(226, 226, 226, 0.3);
  margin-left: 210px;
  box-sizing: border-box;
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 100vh;
  overflow-y: none;
  box-sizing: border-box;
`;

const ContainerColumns = styled.div`
    display: flex;
    flex-flow: column;
    height: 100vh;
    width: 100%;
    margin-top: 45px;
`;

const ItemColumn = styled.div`
    display: flex;
    flex: 1 1 auto;
    max-height: 6.3%;
    float: left;
    align-items: ${({alignItem}) => alignItem ? alignItem : 'flex-start'};
`;

const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 504px;
    padding: 40px;
    border-radius: 30px;
    background: #fff;
    margin-left: 15px;
    align-self: ${({alignSelf}) => alignSelf ? alignSelf : 'flex-start'};
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const ContainerPoints = styled.div`
    display: flex;
    flex-directions: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;

const PointIndicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: ${({background}) => background ? background : '#D4D4D4'};
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContainerCenter = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
`;

const ArrowLeft = () => (
    <svg width="78" height="38" viewBox="0 0 78 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3078 37.2443L0.761522 20.8244C-0.253836 19.8168 -0.253836 18.1832 0.761522 17.1756L17.3078 0.755698C18.3232 -0.251909 19.9694 -0.251909 20.9848 0.755698C22.0001 1.7633 22.0001 3.39695 20.9848 4.40456L8.87696 16.4199L75.4 16.4199C76.8359 16.4199 78 17.575 78 19C78 20.425 76.8359 21.5801 75.4 21.5801L8.87695 21.5801L20.9848 33.5954C22.0001 34.603 22.0001 36.2367 20.9848 37.2443C19.9694 38.2519 18.3232 38.2519 17.3078 37.2443Z" fill="url(#paint0_linear_1074_23554)"/>
        <defs>
            <linearGradient id="paint0_linear_1074_23554" x1="103.376" y1="19" x2="1.93181" y2="-25.0342" gradientUnits="userSpaceOnUse">
                <stop stop-color="#E3772D"/>
                <stop offset="1" stop-color="#F2B559"/>
            </linearGradient>
        </defs>
    </svg>
)

class ModalOnBoarding extends EventEmitter {
  constructor() {
    super();
    this.structure = null;
    this.dom = null;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(structure) {
    this.dom = true;
    this.structure = structure;
    this.emitChange();
  }

  close(callback) {
    if (typeof callback === 'function') callback();
    this.dom = null;
    this.emitChange();
  }

  emitChange() {
    this.emit('onboarding', {structure: this.structure, dom: this.dom});
  }
}

export const onboarding = new ModalOnBoarding();

export default class ModalComponent extends Component {
  state = {
    dom: null,
    structure: {},
    step: 0
  };

  componentDidMount() {
    onboarding.addListener('onboarding', this.handleModal);
    window.addEventListener('keydown', this.hendleKeyClose);
  }

  componentWillUnmount() {
    onboarding.removeListener('onboarding', this.handleModal);
    window.removeEventListener('keydown', this.hendleKeyClose);
  }

  handleModal = (object) => {
    this.setState({ dom: object.dom, structure: object.structure });
  };

  hendleKeyClose = (e) => {
    if (e.code !== 'Escape') return;
    //onboarding.close();
  };

  hendleClickClose = (e) => {
    if (e.target !== e.currentTarget) return;
    onboarding.close();
  };

  handlerNext = () => {
      if((this.state.step + 1) < this.state.structure.columns.length){
          this.setState({
              step: this.state.step + 1
          })
      }else{
        this.setState({
          step: 0,
          dom: null,
          structure: {}
        })
        localStorage.removeItem('onboarding');
      }
  }

  handlerSubs = () => {
    if(this.state.step > 0){
        this.setState({
            step: this.state.step - 1
        })
    }
}

  render() {
    const { dom, structure: { steps, columns }, step } = this.state;
    return (
      !!dom && (
        <BackdropContainer>
          <ScrollContainer>
            {(columns && columns.findIndex(c => c.position === 'center') === step) &&
                <ContainerCenter>
                    <ModalContainer alignSelf="none">
                        <LabelNormal color="#060809" fontSize="22">{columns.find(c => c.position === 'center').title}</LabelNormal>
                        <LabelNormal color="#4F4F4F" fontSize="17">{columns.find(c => c.position === 'center').body}</LabelNormal>
                        <ContainerPoints>
                            {columns.map((_, index) => (
                                <PointIndicator key={index} style={{marginLeft: index > 0 ? 13 : 0}} background={index < (step + 1) ? 'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)' : null} />
                            ))}
                        </ContainerPoints>
                        <InlineContainer style={{alignContent: 'center', justifyContent: 'center', marginTop: 33}}>
                            <div onClick={this.handlerSubs}>
                                <FeatherIcons icon={'arrow-left'} size="19" color="#EB9542" />
                            </div>
                            <div onClick={this.handlerNext}>
                                <FeatherIcons icon={'arrow-right'} size="19" color="#EB9542" />
                            </div>
                        </InlineContainer>
                    </ModalContainer>
                </ContainerCenter>
            }
            <ContainerColumns>
                {Array.from({length: 15}, (v, i) => i).map(cl => (
                    <ItemColumn key={cl} alignItem={cl > 11 ? 'flex-start' : 'flex-end'}>
                        {(columns && columns.findIndex(c => c.section === cl && c.position === 'left') === step) &&
                        <>
                            <div style={{alignSelf: cl > 11 ? 'flex-end' : 'flex-start'}}><ArrowLeft /></div>
                            {
                                (columns.find(c => c.section === cl && c.position === 'left')) ?
                                    <ModalContainer alignSelf={`flex-${cl > 11 ? 'end' : 'start'}`}>
                                        <LabelNormal color="#060809" fontSize="22">{columns.find(c => c.section === cl && c.position === 'left').title}</LabelNormal>
                                        <LabelNormal color="#4F4F4F" fontSize="17">{columns.find(c => c.section === cl && c.position === 'left').body}</LabelNormal>

                                        <ContainerPoints>
                                            {columns.map((_, index) => (
                                                <PointIndicator key={index} style={{marginLeft: index > 0 ? 13 : 0}} background={index < (step + 1) ? 'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)' : null} />
                                            ))}
                                        </ContainerPoints>
                                        <InlineContainer style={{alignContent: 'center', justifyContent: 'center', marginTop: 33}}>
                                            <div onClick={this.handlerSubs}>
                                                <FeatherIcons icon={'arrow-left'} size="19" color="#EB9542" />
                                            </div>
                                            <div onClick={this.handlerNext}>
                                                <FeatherIcons icon={'arrow-right'} size="19" color="#EB9542" />
                                            </div>
                                        </InlineContainer>
                                    </ModalContainer>
                                : null
                            }
                        </>
                        }
                    </ItemColumn>
                ))}
            </ContainerColumns>
          </ScrollContainer>
        </BackdropContainer>
      )
    );
  }
}