import React from 'react';
import styled from 'styled-components';

const OuterCircle = styled.div`
  width: ${(({size}) => size === 'lg' ? '150' : size === 'md' ? '121' : size === 'sm' ? '30' : '65')}px;
  height: ${(({size}) => size === 'lg' ? '150' : size === 'md' ? '121' : size === 'sm' ? '30' : '65')}px;
  margin: 0 !important;
  background-color: transparent;
  border-radius: 50%;
  position: ${((fileSection) => fileSection ? 'inherit' : 'absolute')};
  top: ${(({top}) => top ? top : '48')}px;
  left: ${(({left}) => left ? left : '48')}px;
`;

const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(({size}) => size === 'lg' ? '150' : size === 'md' ? '121' : size === 'sm' ? '30' : '65')}px;
  height: ${(({size}) => size === 'lg' ? '150' : size === 'md' ? '121' : size === 'sm' ? '30' : '65')}px;
  border-radius: 50%;
  border: ${(({size}) => size === 'lg' ? '12.4' : size === 'md' ? '10' : size === 'sm' ? '2.5' : '5.4')}px solid #eec79e;
  position: absolute;
`;

const Percent = styled.p`
  font-size: ${(({size}) => size === 'lg' ? '37.5' : size === 'md' ? '30.25' : size === 'sm' ? '9' : '16.25')}px;
  color: #757575;
  font-family: 'neue-montreal-regular';
  font-weight: 500;
`;

const FileUploader = (props) => (
  <div className={props.fadeOut ? 'fadeOutFiles' : ''} style={{zIndex: '2', position:'relative'}}>
    <OuterCircle size={props.size} top={props.top} left={props.left}>
      <InnerCircle size={props.size}>
        <Percent size={props.size}>
          {props.loader}%
        </Percent>
      </InnerCircle>
      <svg xmlns='https://www.w3.org/2000/svg' version='1.1' className='fileUploaderCircle'>
        <defs>
          <linearGradient id='GradientColor'>
            <stop offset='0%' stop-color='#e3772d'/>
            <stop offset='100%' stop-color='rgba(242, 181, 89, 1)'/>
          </linearGradient>
        </defs>
        <circle 
          cx={props.size === 'lg' ? '75' : props.size === 'md' ? '60.5' : props.size === 'sm' ? '15' : '32.5'}
          cy={props.size === 'lg' ? '75' : props.size === 'md' ? '60.5' : props.size === 'sm' ? '15' : '32.5'} 
          r={props.size === 'lg' ? '68.8' : props.size === 'md' ? '55.5' : props.size === 'sm' ? '13.75' : '29.7'} 
          strokeLinecap='round' 
          style={{ strokeWidth: props.size === 'lg' ? '12.36px' : props.size === 'md' ? '9.97' : props.size === 'sm' ? '2.47px' : '5.36px'}}/>
      </svg>
    </OuterCircle>
  </div>
)

export default FileUploader;