import Skeleton from 'react-loading-skeleton';
import Card from '../shared/Card';
import {
  CardContainer,
  CategoryCard,
  DecoratorBlue,
  DecoratorYellow,
  HeaderCard,
  InlineContainer,
  LabelNormal,
  Row,
} from '../shared/Styled';
import CTASection from './detailsComponents/CTASection';
import { useRef } from 'react';
import TreatmentTypeTag from './detailsComponents/TreatmentTypeTag';
import UsersAssigned from './detailsComponents/UsersAssigned';
import DoctorAssigned from './detailsComponents/DoctorAssigned';

const AdminDetails = ({
  patient,
  treatmentType,
  pendingMessages,
  loadingSkeleton,
  changeMoreSmartchecks,
  planningSendReady,
  loading,
  progressUpload,
  onUploadSTL,
  HttpRequest,
  printingReady,
  thermoformingReady,
  refinedReady,
  bracesPlaced,
}) => {
  const treatment = patient?.treatment_related;
  const doctor = treatment?.doctor;
  const statusTreatment = treatment?.treatmentStatus;
  const zoomLink =
    treatment?.smartsChecks?.length > 0
      ? treatment?.smartsChecks[treatment?.smartsChecks?.length - 1]
          ?.zoom_link ?? ''
      : '';
  const refInput = useRef(null);

  return (
    <div className="col-md-12">
      <Card padding="0px" border="none" minWidth="340px" borderRadius="30px">
        <HeaderCard>
          <LabelNormal
            color="#3C4549"
            fontSize="17"
            style={{
              fontFamily: 'neue-montreal-medium',
              margin: 0,
            }}
          >
            Detalles de caso
          </LabelNormal>
        </HeaderCard>
        <CardContainer>
          {!loadingSkeleton ? (
            <div
              className="row"
              style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
            >
              <div className="col-md-12">
                <LabelNormal
                  color="#828282"
                  fontSize="16"
                  style={{ marginBottom: 10 }}
                >
                  Tratamiento
                </LabelNormal>
                <Row gap="10px" style={{ flexWrap: 'wrap' }}>
                  <TreatmentTypeTag
                    treatmentTypeId={parseInt(treatment?.treatmentType?.id)}
                  />
                  {doctor?.user?.type === 'doctor' && (
                    <CategoryCard padding="6px 14px">
                      <LabelNormal
                        color="#FFF"
                        fontSize="17"
                        style={{ padding: 0, margin: 0 }}
                      >
                        {doctor.category === null ? 'Menudeo' : doctor.category}
                      </LabelNormal>
                    </CategoryCard>
                  )}
                  <CategoryCard padding="6px 14px">
                    <LabelNormal
                      color="#FFF"
                      fontSize="17"
                      style={{ padding: 0, margin: 0 }}
                    >
                      {doctor.user?.country?.name}
                    </LabelNormal>
                  </CategoryCard>
                  {doctor?.user?.type === 'student' && (
                    <CategoryCard padding="6px 14px">
                      <LabelNormal
                        color="#FFF"
                        fontSize="17"
                        style={{ padding: 0, margin: 0 }}
                      >
                        Estudiante
                      </LabelNormal>
                    </CategoryCard>
                  )}
                </Row>
              </div>
              {doctor?.user?.type === 'student' ? (
                <UsersAssigned treatment={treatment} currentView="admin" />
              ) : (
                <DoctorAssigned doctor={doctor} />
              )}
              <div className="col-md-12 flex flex-col gap-2">
                <LabelNormal color="#828282" fontSize="16">
                  Status del caso
                </LabelNormal>
                <InlineContainer style={{ alignItems: 'center' }}>
                  {statusTreatment?.id && (
                    <>
                      <>
                        <div
                          style={{
                            background:
                              statusTreatment?.color_type === 'success'
                                ? '#169D00'
                                : statusTreatment?.color_type === 'error'
                                ? 'red'
                                : '#BDBDBD',
                            height: 10,
                            width: 10,
                            borderRadius: 5,
                          }}
                        />
                        <LabelNormal
                          style={{
                            color:
                              statusTreatment?.color_type === 'success'
                                ? '#169D00'
                                : statusTreatment?.color_type === 'error'
                                ? 'red'
                                : '#BDBDBD',
                            fontSize: 15.5,
                          }}
                        >
                          {statusTreatment?.admin_name}
                        </LabelNormal>
                      </>
                    </>
                  )}
                </InlineContainer>
                {statusTreatment && (
                  <CTASection
                    id={patient?.id}
                    treatmentId={treatment?.id}
                    firstName={patient?.first_name}
                    lastName={patient?.last_name}
                    treatment={treatmentType}
                    bracketsSS={treatment?.bracket_ss}
                    pendingMessages={pendingMessages}
                    statusTreatment={statusTreatment}
                    zoom_link={zoomLink}
                    moreSmartchecks={treatment?.more_smartchecks}
                    changeMoreSmartchecks={changeMoreSmartchecks}
                    planningSendReady={planningSendReady}
                    loading={loading}
                    progressUpload={progressUpload}
                    refInput={refInput}
                    onUploadSTL={onUploadSTL}
                    HttpRequest={HttpRequest}
                    printingReady={printingReady}
                    thermoformingReady={thermoformingReady}
                    refinedReady={refinedReady}
                    bracesPlaced={bracesPlaced}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-9">
                <LabelNormal
                  color="#828282"
                  fontSize="16"
                  style={{ marginBottom: 10 }}
                >
                  Tratamiento
                </LabelNormal>
                <Skeleton
                  baseColor={'#EEEEF2'}
                  height={37}
                  width={'70%'}
                  borderRadius={5}
                  highlightColor={'#FFF'}
                />
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ marginTop: 10, marginRight: -10 }}
                  >
                    <Skeleton
                      baseColor={'#EEEEF2'}
                      height={37}
                      width={'100%'}
                      borderRadius={5}
                      highlightColor={'#FFF'}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: 10 }}>
                    <Skeleton
                      baseColor={'#EEEEF2'}
                      height={37}
                      width={'100%'}
                      borderRadius={5}
                      highlightColor={'#FFF'}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <LabelNormal
                  color="#828282"
                  fontSize="16"
                  style={{ marginBottom: 10, marginTop: 30 }}
                >
                  Doctor
                </LabelNormal>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <Skeleton
                    baseColor={'#EEEEF2'}
                    height={46}
                    circle
                    width={46}
                    highlightColor={'#FFF'}
                    style={{ marginRight: 15 }}
                  />
                  <Skeleton
                    baseColor={'#EEEEF2'}
                    height={23}
                    width={150}
                    borderRadius={5}
                    highlightColor={'#FFF'}
                  />
                </InlineContainer>
              </div>
              <div className="col-md-12">
                <LabelNormal
                  color="#828282"
                  fontSize="16"
                  style={{ marginBottom: 10, marginTop: 30 }}
                >
                  Status del caso
                </LabelNormal>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <Skeleton
                    baseColor={'#EEEEF2'}
                    height={14}
                    circle
                    width={14}
                    highlightColor={'#FFF'}
                    style={{ marginRight: 15 }}
                  />
                  <Skeleton
                    baseColor={'#EEEEF2'}
                    height={23}
                    width={150}
                    borderRadius={5}
                    highlightColor={'#FFF'}
                  />
                </InlineContainer>
              </div>
            </div>
          )}
        </CardContainer>
      </Card>
    </div>
  );
};

export default AdminDetails;
