import styled from 'styled-components';

export const TextArea = styled.textarea`
  background: ${({ backgroundColor, disabled }) =>
    backgroundColor && !disabled
      ? backgroundColor
      : disabled
      ? 'dark(rgba(239, 239, 239, 0.3))'
      : '#FFF'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-top: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-right: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-bottom: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-left: ${({ mask, borderColor }) =>
    `1px solid ${borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'}`};
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 20px;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: 17px;
  min-width: 100%;
  font-family: neue-montreal-regular;
  ::placeholder {
    font-family: neue-montreal-regular;
  }
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '8')}px;
  ${({ readOnly }) =>
    readOnly === undefined
      ? `&:focus {
            outline: none;
            border: 1px solid #E3772D;
            ::placeholder {
            }
        }`
      : `cursor: no-drop;

        &:focus {
            outline: none;
        }`}
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

export const SeparatorItem = styled.div`
  width: 10.5px !important;
  height: 5.5px;
  border-radius: 20px;
  margin-top: 6px;
  margin-right: 5px;
  border: 1.5px solid #dd9139;
`;

export const ItemShowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5px;
`;
