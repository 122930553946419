import React from 'react';
import Item from '../../Item/Item';
import { NewCaseIcon } from '../../../Icons/NewCase';
import { CasesIcon } from '../../../Icons/Cases';

const SectionsStudent = (props) => {
  return (
    <>
      <Item
        label="Nuevo caso"
        to="/doctor/nuevo-caso"
        icon={
          <NewCaseIcon
            color={
              props.name === 'nuevo-caso' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
            }
          />
        }
        color={props.name === 'nuevo-caso' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'nuevo-caso' && '#333C3F'}
      />
      <Item
        label="Tus casos"
        to="/doctor/tus-casos"
        icon={
          <CasesIcon
            color={
              props.name === 'tus-casos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
            }
          />
        }
        color={props.name === 'tus-casos' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'tus-casos' && '#333C3F'}
      />
    </>
  );
};

export default SectionsStudent;
