import React, { useState } from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import ButtonPrime from '@components/shared/ButtonPrime';
import { modal } from '@components/ModalManagement';
import InputSearch from '@components/shared/InputSearch';
import { Count } from '@components/Icons/Count';
import { Link } from 'react-router-dom';

const Image = styled.img`
  height: 29px;
  width: 29px;
`;

const HeaderCardTable = styled.div`
    background: #F6F6F8;
    min-height: 51px;
    padding: 14px 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const CardContainer = styled.div`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 22px;
    background: #FFF;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const QuestionText = styled.textarea`
    border: 1px solid #BDBDBDB2;
    background: #FFFFFF;
    border-radius: 13.93px;
    padding: 12px 20px 20px 20px;
    min-height: 220px;
    color: #000;
    font-size: 17px;
    font-family: neue-montreal-regular;
    width: 100%;
    margin-top: 20px;
`;

const ButtonModal = styled.button`
    border-radius: 40px;
    padding: 5px 12px;
    border: 1px solid #3C4549;
    background: transparent;
    cursor: pointer;
    font-size: 19px;
    padding: 12px 25px 13px;
    font-family: neue-montreal-regular;
    margin-left: 20px;
`;

const Question = (props) => {
    const [type, setType] = useState('student');
    const [search, setSearch] = useState('');

    return (
        <div className='modal-large' style={{borderRadius: 20, minWidth: 863}}>
            <Inline style={{alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(0, 0, 0, 0.15)', paddingLeft: 22, paddingRight: 22, paddingTop: 14, paddingBottom: 14}}>
                <Inline style={{alignItems: 'center'}}>
                    <FeatherIcon icon="users" color="rgba(60, 69, 73, 1)" size={19} />
                    <LabelNormal style={{marginLeft: 10}} color="rgba(60, 69, 73, 1)" fontSize="20">Reasignar caso</LabelNormal>
                </Inline>
            </Inline>
            <div className='bootstrap-wrapper row' style={{padding: 18}}>
                <div className='col-md-12'>
                    <LabelNormal fontSize="17" color="#757575">Selecciona una opción para reasignar</LabelNormal>
                    <Inline style={{justifyContent: 'flex-start'}}>
                        <div className="radio" style={{marginRight: 20}}>
                            <input id="radio-Type-1" onClick={(e) => setType('student')} checked={type === 'student' ? 'checked' : false} name="Type" type="radio" />
                            <label for="radio-Type-1" className="radio-label">Estudiante</label>
                        </div>
                        <div className="radio" style={{marginRight: 20}}>
                            <input id="radio-Type-2" onClick={(e) => setType('teacher')} checked={type === 'teacher' ? 'checked' : false}  name="Type" type="radio" />
                            <label for="radio-Type-2" className="radio-label">Profesor</label>
                        </div>
                    </Inline>
                </div>
                <div className='col-md-12 row'>
                    <div className='col-md-12' style={{marginTop: 25, marginBottom: 25}}>
                        <InputSearch placeholder="Busca por nombre o ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    {type === 'student' ? 
                    <div className='col-md-12'>
                        <HeaderCardTable>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">Estudiante</LabelNormal>
                                </div>
                                <div className='col-md-3'>
                                    <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">Activos</LabelNormal>
                                </div>
                                <div className='col-md-3'>
                                    <LabelNormal color="#3C4549" style={{textAlign: 'center'}} fontSize="17">Terminados</LabelNormal>
                                </div>
                                <div className='col-md-3'></div>
                            </div>
                        </HeaderCardTable>
                        <CardContainer style={{border: '1px solid rgba(161, 161, 161, 0.1)'}}>
                            {props.students.length > 0 ?
                                <>
                                    {props.students.map(item => (
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                    <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                            </div>
                                            <div className='col-md-3'>
                                                {item.count_treatments > 0 ?
                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_treatments}</LabelNormal>
                                                :
                                                    <Count />
                                                }
                                            </div>
                                            <div className='col-md-3'>
                                                <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_inactives_treatments}</LabelNormal>
                                            </div>
                                            <div className='col-md-3'>
                                                <LabelNormal color="#757575" fontSize="14" onClick={() => props.reasigneCase({type: 'Estudiante', idRole: item.id, name: `${item.user.first_name} ${item.user.last_name}` })} style={{cursor: 'pointer', textAlign: 'center', textDecorationLine: 'underline'}}>Reasignar caso</LabelNormal>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            :
                                <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin estudiantes registrados</LabelNormal>
                            }
                        </CardContainer>
                    </div>
                    :
                    <div className='col-md-12'>
                        <HeaderCardTable>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <LabelNormal color="#3C4549" fontSize="17" style={{textAlign: 'center'}}>Profesor</LabelNormal>
                                </div>
                                <div className='col-md-4'>
                                    <LabelNormal color="#3C4549" fontSize="17" style={{textAlign: 'center'}}>Estudiantes asignados</LabelNormal>
                                </div>
                                <div className='col-md-3'></div>
                            </div>
                        </HeaderCardTable>
                        <CardContainer style={{border: '1px solid rgba(161, 161, 161, 0.1)'}}>
                            {props.teachers.length > 0 ?
                                <>
                                    {props.teachers.map(item => (
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                    <LabelNormal color="#757575" fontSize="15" style={{textDecorationLine: 'underline'}}>{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                                            </div>
                                            <div className='col-md-4'>
                                                {item.count_doctors > 0 ?
                                                    <LabelNormal color="#757575" fontSize="15" style={{textAlign: 'center'}}>{item.count_doctors}</LabelNormal>
                                                :
                                                    <Count />
                                                }
                                            </div>
                                            <div className='col-md-3'>
                                                <LabelNormal color="#757575" fontSize="14" onClick={() => props.reasigneCase({type: 'Profesor', idRole: item.id, name: `${item.user.first_name} ${item.user.last_name}`})} style={{cursor: 'pointer', textAlign: 'center', textDecorationLine: 'underline'}}>Reasignar caso</LabelNormal>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            :
                                <LabelNormal color="#BDBDBD" fontSize="15" style={{textAlign: 'center'}}>Sin profesores registrados</LabelNormal>
                            }
                        </CardContainer>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
   
export default Question;