/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  validateEmail,
  validateNumber,
  validateRFC,
  validatePassword,
  validatePhone,
  validateURL,
  validateZipCode,
  getTypeCard,
  validateExpDate,
  validateCVV,
} from '../../utils';

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputComponent = styled.input`
  background: ${({ backgroundColor, disabled }) =>
    backgroundColor && !disabled
      ? backgroundColor
      : disabled
      ? 'dark(rgba(239, 239, 239, 0.3))'
      : '#FFF'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-top: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-right: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-bottom: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  border-left: ${({ mask, borderColor }) =>
    mask === 'credit_card'
      ? 'none'
      : `1px solid ${borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'}`};
  border-radius: ${({ mask }) =>
    mask === 'credit_card' ? '0 16px 16px 0' : '16px'};
  transition: all 0.3s ease-out;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 20px;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "'neue-montreal-regular'"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '8')}px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '')};
  ${({ readOnly }) =>
    readOnly === undefined
      ? `&:focus {
            outline: none;
            border: 1px solid #E3772D;
            ::placeholder {
            }
        }
        &:hover {
            outline: none;
            border: 1px solid #E3772D;
        }`
      : `cursor: no-drop;

        &:focus {
            outline: none;
        }`}
`;

const LabelMuted = styled.span`
  color: #828282;
  font-family: neue-montreal-regular;
  font-size: 16px;
  margin: 10px 0;
`;

const Input = (props) => {
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (props.type === 'phone') maskPhone({ target: { value: props.value } });
  }, []);

  const onBlur = (e) => {
    if (
      (props.type === 'email' &&
        !validateEmail(props.value) &&
        props.required) ||
      (props.type === 'email' &&
        props.value !== '' &&
        !validateEmail(props.value)) ||
      (props.validation === 'passwordlenght' &&
        !validatePassword(props.value)) ||
      (props.validation === 'rfc' &&
        !validateRFC(props.value) &&
        props.required) ||
      (props.validation === 'int' &&
        !validateNumber(props.value) &&
        props.required) ||
      (props.validation === 'phone' && !validatePhone(props.value)) ||
      (props.validation === 'url' && !validateURL(props.value)) ||
      (props.validation === 'zip_code' &&
        !validateZipCode(props.value, props.country ? props.country : 1)) ||
      (props.validation === 'credit_card' &&
        (getTypeCard(props.value) === '' ||
          (getTypeCard(props.value) !== 'amex' && props.value.length < 19) ||
          (getTypeCard(props.value) === 'amex' && props.value.length < 18))) ||
      (props.validation === 'exp_date' && !validateExpDate(props.value)) ||
      (props.validation === 'cvv' &&
        !validateCVV(props.cvv || '', props.value)) ||
      ((props.value === '' || props.value === undefined) && props.required)
    ) {
      setValidation(true);
    } else {
      setValidation(false);
    }
    if (
      props.type === 'day' &&
      parseInt(props.value) < 10 &&
      props.value[0] !== '0'
    ) {
      props.onChange('0'.concat(props.value));
    } else if (
      props.type === 'monthInput' &&
      parseInt(props.value) < 10 &&
      props.value[0] !== '0'
    ) {
      props.onChange('0'.concat(props.value));
    }
    if (props.onBlur) props.onBlur(e);
  };

  const maskPhone = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '').replace(/[^0-9]/g, '');
    if (val.length <= 10) {
      let num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
        6,
        val.length
      )}`;
      num = num.trim();
      props.onChange(num);
    }
  };

  const maskNumberCP = (event) => {
    let val = event.target.value;
    if (val.length < 6) {
      val = val.replace(/[^0-9]/g, '');
      props.onChange(val);
    }
  };

  const maskDate2 = (event) => {
    let val = event.target.value;
    if (val.length < 3) {
      val = val.replace(/[^0-9]/g, '');
      if (props.type === 'day' && parseInt(val) > 31) {
        props.onChange('31');
      } else if (props.type === 'monthInput' && parseInt(val) > 12) {
        props.onChange('12');
      } else {
        props.onChange(val);
      }
    }
  };

  const maskRFC = (event) => {
    let val = event.target.value;
    props.onChange(val.toUpperCase());
  };

  const maskDate4 = (event) => {
    let val = event.target.value;
    if (val.length < 5) {
      val = val.replace(/[^0-9]/g, '');
      props.onChange(val);
    }
  };

  const maskExpDate = (event) => {
    let val = event.target.value;
    val = val
      .replace(/ /gm, '')
      .replace(/[^0-9]/g, '')
      .replace(/\//g, '');
    if (val.length <= 4) {
      let num = '';
      if (val.length === 1) {
        val = val.split('');
        if (parseInt(val[0]) > 1) {
          val[1] = val[0];
          val[0] = '0';
        }
        num = val.join('');
      } else if (val.length === 2) {
        if (parseInt(val) > 12) {
          num = `0${val.substring(0, 1)}/${val.substring(1, val.length)}`;
          num = num.trim();
        } else {
          num = val;
        }
      } else if (val.length > 2) {
        num =
          val.length >= 3
            ? `${val.substring(0, 2)}/${val.substring(2, val.length)}`
            : val;
        num = num.trim();
      }
      props.onChange(num);
    }
  };

  const maskName = (event) => {
    let val = event.target.value;
    val = val.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    props.onChange(val);
  };

  const maskCreditCard = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '').replace(/[^0-9]/g, '');
    if (val.length <= 16) {
      let num = `${val.substring(0, 4)} ${val.substring(4, 8)} ${val.substring(
        8,
        12
      )} ${val.substring(12, val.length)}`;
      num = num.trim();
      props.onChange(num);
    }
  };

  const maskCVV = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '').replace(/[^0-9]/g, '');
    if (val.length <= 4) {
      props.onChange(val);
    }
  };

  const maskInt = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '').replace(/[^0-9]/g, '');
    props.onChange(val);
  };

  if (props.type === 'email') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          borderColor={
            props.emptyVal
              ? '#D11105'
              : (validation || props.outsideValidation) && props.value !== ''
              ? '#D11105'
              : 'rgba(189, 189, 189, 0.7)'
          }
        />
        {(validation || props.outsideValidation) && props.value !== '' && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            {props.validationText ?? '*Email inválido'}
          </p>
        )}
      </Container>
    );
  } else if (props.type === 'custom') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          borderColor={
            props.validation ? 'rgba(189, 189, 189, 0.7)' : '#D11105'
          }
        />
      </Container>
    );
  } else if (props.validation === 'rfc') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskRFC}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
        {validation && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *RFC inválido
          </p>
        )}
      </Container>
    );
  } else if (props.validation === 'passwordlenght') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
        {validation && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *Contraseña inválida
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'phone' && props.validation === 'phone') {
    return (
      <Container style={{ position: 'relative' }}>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskPhone}
          borderColor={
            props.emptyVal
              ? '#D11105'
              : validation && props.value !== ''
              ? '#D11105'
              : 'rgba(189, 189, 189, 0.7)'
          }
        />
        {validation && props.value !== '' && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
              position: 'absolute',
              bottom: -25,
            }}
          >
            *Teléfono inválido
          </p>
        )}
        {props.emptyVal && props.value === '' && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
              position: 'absolute',
              bottom: -25,
            }}
          >
            *Introduce tu teléfono
          </p>
        )}
      </Container>
    );
  } else if (props.type === 'url') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          borderColor={
            validation && props.value !== ''
              ? '#D11105'
              : 'rgba(189, 189, 189, 0.7)'
          }
        />
        {validation && props.value !== '' && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *URL inválida
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'zip_code') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskNumberCP}
          borderColor={
            props.emptyVal
              ? '#D11105'
              : validation && props.value !== ''
              ? '#D11105'
              : 'rgba(189, 189, 189, 0.7)'
          }
        />
        {validation && props.value !== '' && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            {props.error ? props.error : '*Código postal inválido'}
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'date2') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskDate2}
          borderColor={props.validDate ? 'rgba(189, 189, 189, 0.7)' : '#D11105'}
        />
      </Container>
    );
  } else if (props.mask === 'date4') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskDate4}
          borderColor={props.validDate ? 'rgba(189, 189, 189, 0.7)' : '#D11105'}
        />
      </Container>
    );
  } else if (props.mask === 'exp_date') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskExpDate}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
        {validation && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *Fecha inválida
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'name') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskName}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
      </Container>
    );
  } else if (props.mask === 'credit_card') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskCreditCard}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
        {validation && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *Número de tarjeta incorrecta
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'cvv') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          onChange={maskCVV}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
        {validation && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            *CVV incorrecto
          </p>
        )}
      </Container>
    );
  } else if (props.mask === 'onlyNumber') {
    return (
      <Container>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onChange={maskInt}
          borderColor={validation ? '#D11105' : 'rgba(189, 189, 189, 0.7)'}
        />
      </Container>
    );
  } else {
    return (
      <Container {...props.parentProps}>
        {props.label && <LabelInput>{props.label}</LabelInput>}
        {props.muted && <LabelMuted>{props.muted}</LabelMuted>}
        <InputComponent
          {...props}
          onBlur={onBlur}
          borderColor={
            validation || props.outsideValidation
              ? '#D11105'
              : 'rgba(189, 189, 189, 0.7)'
          }
        />
        {(validation || props.outsideValidation) && (
          <p
            style={{
              color: '#C60E02',
              fontSize: 14,
              margin: 0,
              padding: 0,
              marginTop: 5,
              fontFamily: 'neue-montreal-regular',
            }}
          >
            {props.validationText ?? '*Campo requerido'}
          </p>
        )}
      </Container>
    );
  }
};

export default Input;
