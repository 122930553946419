import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import {
  QUERY_GET_PATIENT_FILES,
  QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
  QUERY_GET_PATIENT,
  QUERY_DEFAULT_ADDRESS_DATA,
  QUERY_GET_DETAILS_DOCTOR,
} from '@graphql/query';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import { MUTATION_SEND_CORRECTIONS } from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import FilesSidebar from '../../components/Corrections/FilesSidebar';
import { Row } from '../../components/shared/Styled';
import FileDisplay from '../../components/Corrections/FileDisplay';
import Comments from '../../components/Corrections/Comments';
import MessageIcon from '../../components/Icons/Message';
import ButtonSecondary from '@components/shared/buttons/secondary';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Corrections = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [loading, setLoading] = useState(false);

  const [patient, setPatient] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [filesTreatment, setFilesTreatment] = useState([]);
  const [selectTreatmentFile, setSelectTreatmentFile] = useState({});
  const [commentsCorrections, setCommentsCorrections] = useState([]);
  const [imageHash, setImageHash] = useState(Date.now());
  const [typeSTL, setTypeSTL] = useState('');
  const [defaultAddress, setDefaultAddress] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [chatMessages, setChatMessages] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [formStatus, setFormStatus] = useState('');

  const refStatusCorrections = useRef(null);

  useEffect(() => {
    if (props.location) {
      setFirstName(props.location.state.firstName);
      setLastName(props.location.state.lastName);
    }
  }, [props.location]);

  const [activeChat, setActiveChat] = useState(false);

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.pacient) {
        const patientData = data.pacient;
        setPatient(data.pacient);
        setFirstName(patientData.first_name);
        setLastName(patientData.last_name);
        setTypeSTL(patientData.treatment_related.stl_type);
        setChatMessages(patientData.treatment_related?.messages ?? []);
        setActiveChat(
          patientData.treatment_related.form_status === 'Rechazado'
        );
        getPatientFiles({ variables: { onlyErrors: true, patient_id: id } });
        setFormStatus(
          patientData.treatment_related.form_status
            ? patientData.treatment_related.form_status
            : ''
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;
        setImageHash(Date.now());
        const decode = patientfiles.map((item) => ({
          id: item.id,
          review: item.name,
          type: item.file_type,
          name: item.file_name,
          preview:
            item.file_type === 'stl' && item.name !== 'modelo'
              ? require('@images/files/stl.jpg')
              : item.file_type === 'zip'
              ? require('@images/files/zip.jpg')
              : item.fileUrl,
          section: item.section,
          status: item.FileStatus.name,
          statusId: item.FileStatus.id,
        }));

        setFilesTreatment(decode);

        if (selectTreatmentFile && selectTreatmentFile.id) {
          setSelectTreatmentFile({
            ...decode.filter((d) => d.id === selectTreatmentFile.id)[0],
          });
        } else {
          setSelectTreatmentFile(decode[0]);
        }
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getPatient({ variables: { id } });
    // getPatientFiles({ variables: { onlyErrors: true, patient_id: id } });
  }, [id]);

  const [getCommentsCorrection] = useLazyQuery(
    QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data.getCorrectionCommentsByTreatment.correctionsComments) {
          setCommentsCorrections(
            data.getCorrectionCommentsByTreatment.correctionsComments
          );
          scrollBottom();
        }
      },
    }
  );

  const [getDefaultAddress] = useLazyQuery(QUERY_DEFAULT_ADDRESS_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getDefaultAddressData) {
        setDefaultAddress(data.getDefaultAddressData.defaultAddress);
      }
    },
    onError: (err) => {
      console.log(err, 'error address');
    },
  });

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        setDoctorData(data.doctor);
      }
    },
  });

  useEffect(() => {
    getCommentsCorrection({
      variables: {
        onlyErrors: true,
        treatment_id: props.location ? props.location.state.treatmentId : null,
      },
    });
    getDefaultAddress({
      variables: { user_id: localStorage.getItem('b360-id'), patient_id: id },
    });
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    });
  }, []);

  const scrollBottom = () => {
    refStatusCorrections?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [sendCorrectionsTreatment] = useMutation(MUTATION_SEND_CORRECTIONS, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => history.replace(`/doctor/caso/${id}`))
          }
        >
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Correciones enviadas
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Revisaremos tus correcciones. Te notificaremos cuando puedas
            revisarla. Este proceso dura aproximadamente 1 día hábil.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const getNextImageReview = (fileId) => {
    const reply = filesTreatment;
    const index = reply.findIndex((r) => r.id === fileId);
    if (index < reply.length - 1) {
      setSelectTreatmentFile({ ...reply[index + 1], index: index + 1 });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <div
          style={{
            position: 'fixed',
            height: '20px',
            width: '98%',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
            top: 0,
            left: 0,
          }}
        ></div>
        <Header
          style={{
            position: 'sticky',
            top: '20px',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div
              className="row"
              style={{ paddingLeft: 12, paddingRight: 24, marginRight: 0 }}
            >
              <div
                className="col-md-12"
                style={{
                  marginBottom: 24,
                  position: 'sticky',
                  top: '90px',
                  backgroundColor: '#f6f6f8',
                  zIndex: 11,
                }}
              >
                <Title>Errores por corregir</Title>
                <div style={{ marginTop: 10, marginBottom: 12 }}>
                  <Breadcrumb
                    elements={2}
                    onSecondClick={() => history.push(`/doctor/caso/${id}`)}
                    onClick={() => history.push(`/doctor/tus-casos`)}
                    mainLabel={'Tus casos'}
                    midLabel={`${firstName} ${lastName}`}
                    endLabel={'Errores por corregir'}
                  />
                  <LabelNormal color="#757575" fontSize="17">
                    Corrige los siguientes errores en la captura de tus datos
                    para continuar con el caso.{' '}
                  </LabelNormal>
                </div>
                {chatMessages.length > 0 && (
                  <div
                    style={{
                      marginBottom: 24,
                      position: 'absolute',
                      top: '-36px',
                      right: '20px',
                      backgroundColor: '#f6f6f8',
                      zIndex: 13,
                      paddingBottom: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      width: '322px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '4px',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <MessageIcon color={activeChat ? '#EB9542' : '#3C4549'} />
                      <p
                        style={{
                          fontFamily: 'neue-montreal-regular',
                          fontWeight: '500',
                          fontSize: '22px',
                          lineHeight: '26.4px',
                        }}
                      >
                        Mensajes de Borgatta
                      </p>
                    </div>
                    <LabelNormal
                      style={{
                        margin: 0,
                        textAlign: 'end',
                      }}
                      color="#757575"
                      fontSize="17"
                    >
                      {activeChat
                        ? 'Contesta los mensajes en la conversación, puedes corregir el resto de tus archivos. Solo podrás enviar tus correcciones hasta que finalice la conversación.'
                        : 'Ya resolviste las dudas de Borgatta, ahora puedes corregir tus archivos'}{' '}
                    </LabelNormal>
                    <Link
                      to={{
                        pathname: `/${
                          location.state && location.state.student
                            ? 'estudiante'
                            : 'doctor'
                        }/caso/mensajes-borgatta/${id}`,
                      }}
                    >
                      {activeChat ? (
                        <ButtonPrime
                          marginTop="0"
                          marginRight="0"
                          fontWeight={400}
                          size="sm"
                          style={{
                            backgroundColor: '#EB9542',
                            border: '1px solid #EB9542',
                            color: '#FFf',
                          }}
                          label="Contestar mensajes"
                        />
                      ) : (
                        <ButtonSecondary
                          fontSize="19px"
                          width="fit-content"
                          outline
                        >
                          <span
                            className="flex gap-2 items-center"
                            style={{ marginLeft: '12px', marginRight: '12px' }}
                          >
                            <p
                              style={{
                                fontWeight: 500,
                                fontFamily: 'Neue Montreal',
                              }}
                            >
                              Ver la conversación archivada
                            </p>
                          </span>
                        </ButtonSecondary>
                      )}
                    </Link>
                  </div>
                )}
              </div>

              <div className="col-md-12" style={{ maxWidth: 1145 }}>
                <Row style={{ flexWrap: 'nowrap' }}>
                  <FilesSidebar
                    loadingSkeleton={loadingSkeleton}
                    loading={loading}
                    files={filesTreatment}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    corrections={true}
                  />
                  <FileDisplay
                    loadingSkeleton={loadingSkeleton}
                    loading={loading}
                    setLoading={setLoading}
                    file={selectTreatmentFile}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    caseId={id}
                    patient={patient}
                    filesTreatment={filesTreatment}
                    setFilesTreatment={setFilesTreatment}
                    typeSTL={typeSTL}
                    chatMessages={chatMessages}
                    activeChat={activeChat}
                    getNextImageReview={getNextImageReview}
                    doctorData={doctorData}
                    defaultAddress={defaultAddress}
                    errorForm={errorForm}
                    formStatus={formStatus}
                  />
                  <Comments
                    loading={loadingSkeleton}
                    loadingImage={loading}
                    patientId={patient?.id}
                    comments={commentsCorrections}
                    typeSTL={typeSTL}
                    files={filesTreatment}
                    refStatusCorrections={refStatusCorrections}
                    sendCorrectionsTreatment={sendCorrectionsTreatment}
                  />
                </Row>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Corrections;
