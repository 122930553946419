import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import InputPassword from '@components/shared/InputPassword';
import { SpanInput } from '@components/Login/Login.style';
import EditProfile from '../../components/shared/EditProfle';
import CloseSession from '../../components/shared/CloseSession';
import LinkUnderline from '../../components/shared/LinkUnderline';
import LabelUnderline from '../../components/shared/LabelUnderline';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_EDIT_DOCTOR,
  MUTATION_DELETED_TAX_DATA,
  MUTATION_DELETED_ADDRESS,
  MUTATION_DELETE_CREDIT_CARD,
  MUTATION_UPDATE_PASSWORD,
} from '@graphql/mutation';
import { QUERY_GET_DOCTOR_TOKEN } from '@graphql/query';
import { modal } from '@components/ModalManagement';
import UnSubscribeModal from '@components/Modal/ModalUnSubscribe';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';
import moment from 'moment';
import { loadPhotoProfileGender } from '../../utils';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import UserIcon from '@images/icons/user.svg';
import PhoneIcon from '@images/icons/phone.svg';
import GiftIcon from '@images/icons/gift.svg';
import MailIcon from '@images/icons/mail.svg';
import LocationIcon from '@images/icons/location.svg';
import Card from '../../components/shared/Card';
import EditProfileForm from '../../components/Doctor/EditProfileForm';
import PaymentCardCard from '../../components/Doctor/PaymentCardCard';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily === 'medium' ? 'neue-montreal-medium' : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding: 25px;
  background: #fff;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px;
`;

const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0px 0px 10px 0px;
  padding: 0;
`;

const DecoratorBlue = styled.div`
  display: flex;
  background: linear-gradient(96.92deg, #1aa5e0 -10.46%, #088ec7 166.07%);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  display: flex;
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ScrollHorizontal = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 17px;
  margin-top: 17px;
  margin-bottom: 18px;
`;

const Profile = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [id, setIdProfile] = useState('');
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [countryName, setCountryName] = useState('');
  const [treatment, setTreatment] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [asesor, setAsesor] = useState(false);
  const [category, setCategory] = useState('');
  const [idDoctor, setIdDoctor] = useState('');
  const [idAsesor, setIdAsesor] = useState('');
  const [premium, setPremium] = useState(false);
  const [professionalLicense, setProfessionalLicense] = useState('');
  const [editPass, setEditPass] = useState(false);
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState({});
  const [Id, setId] = useState('');
  const [timezone, setTimezone] = useState('');
  const [timezone_name, setTimezoneName] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicPhone, setClinicPhone] = useState('');
  const [street, setStreet] = useState('');
  const [exterior, setExterior] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [city, setCity] = useState('');
  const [locality, setLocality] = useState('');
  const [province, setProvince] = useState('');
  const [created, setCreated] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const [crystAlignerTotal, setCrystAlignerTotal] = useState('');
  const [crystAlignerActive, setCrystAlignerActive] = useState('');

  const [smileSystemTotal, setSmileSystemTotal] = useState('');
  const [smileSystemActive, setSmileSystemActive] = useState('');

  const [taxDatas, setTaxDatas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [surgieries, setSurgeries] = useState(0);

  const [loader, setLoader] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [downloadError, setDownloadError] = useState(false);
  const [type, setType] = useState('');

  const [getDetails] = useLazyQuery(QUERY_GET_DOCTOR_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      try {
        if (data.getDoctorAuth) {
          setIdProfile(data.getDoctorAuth.id);
          const doctor = data.getDoctorAuth;
          setType(doctor.user?.type);
          setTaxDatas(doctor.taxdatas.sort((a, b) => a.default && !b.default));
          setAddresses(doctor.addresses);
          setCreditCards(doctor.cards);

          setId(doctor.user.id);
          setFirstName(doctor.user.first_name);
          setLastName(doctor.user.last_name);
          setEmail(doctor.user.email);

          setId(doctor.user.id);
          setFirstName(doctor.user.first_name);
          setLastName(doctor.user.last_name);
          setEmail(doctor.user.email);

          setDay(doctor.birthdate?.split('-')[2]);
          setMonth(doctor.birthdate?.split('-')[1]);
          setYear(doctor.birthdate?.split('-')[0]);

          setPhone(doctor.user.phone);
          setGender(doctor.user.gender);
          setCountry(doctor.user.country.id);
          setCountryName(doctor.user.country.name);
          setPhoto(doctor.user.photo_filename);
          setCreated(doctor.user.created_at);
          setCategory(doctor.category);

          setTreatment(
            doctor.cryst_aligner && doctor.smile_system
              ? 'Ambos'
              : doctor.cryst_aligner
              ? 'Cryst Aligner'
              : doctor.smile_system
              ? 'Smile System'
              : ''
          );

          setTimezone(doctor.timezone);
          setTimezoneName(doctor.timezone_name);
          setIdDoctor(doctor.id_doctor);
          setAsesor(doctor.id_asesor ? true : false);
          setIdAsesor(doctor.id_asesor);
          setPremium(doctor.is_doctor);
          setProfessionalLicense(
            doctor.professional_license ? doctor.professional_license : ''
          );
          if (doctor.clinic) {
            setClinicName(doctor.clinic.name);
            setClinicPhone(doctor.clinic.phone);
            setStreet(doctor.clinic.street);
            setExterior(doctor.clinic.number);
            setCodePostal(doctor.clinic.postal_code);
            setCity(doctor.clinic.suburb);
            setLocality(doctor.clinic.locality);
            setProvince(doctor.clinic.state);
          }

          setCrystAlignerTotal(doctor.count_treatments_cryst_aligner);
          setCrystAlignerActive(doctor.active_treatments_cryst_aligner.length);

          setSmileSystemTotal(doctor.count_treatments_smile_system);
          setSmileSystemActive(doctor.active_treatments_smile_system.length);
          setSurgeries(doctor.surgeries.length);
        }
        setLoadingSkeleton(false);
      } catch (error) {
        console.log('Error:', error);
      }
    },
    onError: (err) => console.log(err),
  });

  const [updateDoctor] = useMutation(MUTATION_EDIT_DOCTOR, {
    onCompleted: (data) => {
      if (data.updateDoctor) {
        if (file.name) onSubmitFile(data.updateDoctor.id);
        else {
          setDownloadError(false);
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  getDetails({});
                  setEdit(false);
                })
              }
            >
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginTop: 30 }}
              >
                Cambios a perfil guardados
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    },
  });

  const [deleteTaxData] = useMutation(MUTATION_DELETED_TAX_DATA, {
    onCompleted: (data) => {
      if (data.deleteTaxData) {
        getDetails({});
      }
    },
  });

  const onSubmitDeleteTaxData = async (id) => {
    await deleteTaxData({ variables: { id } });
    modal.close();
  };

  const onModalDeleteTaxData = (id) => {
    modal.open(
      <UnSubscribeModal withoutIcon={true}>
        <LabelNormal color="#060809" fontSize="22">
          ¿Seguro de que deseas eliminar este perfil fiscal?
        </LabelNormal>
        <ButtonPrime
          onClick={() => onSubmitDeleteTaxData(id)}
          label="Eliminar perfil fiscal"
        />
      </UnSubscribeModal>
    );
  };

  const [deleteAddress] = useMutation(MUTATION_DELETED_ADDRESS, {
    onCompleted: (data) => {
      if (data.deleteAddress) {
        getDetails({});
      }
    },
  });

  const onSubmitDeleteAddress = async (id) => {
    await deleteAddress({ variables: { id } });
    modal.close();
  };

  const onModalDeleteAddress = (id) => {
    modal.open(
      <UnSubscribeModal withoutIcon={true}>
        <LabelNormal
          color="#060809"
          fontSize="22"
          style={{ marginBottom: '15px' }}
        >
          ¿Seguro de que deseas eliminar esta dirección de envío?
        </LabelNormal>
        <ButtonPrime
          onClick={() => onSubmitDeleteAddress(id)}
          label="Eliminar dirección de envío"
        />
      </UnSubscribeModal>
    );
  };

  const [deleteCreditCard] = useMutation(MUTATION_DELETE_CREDIT_CARD, {
    onCompleted: (data) => {
      if (data.deleteDoctorCard) {
        getDetails({});
      }
    },
  });

  const onSubmitDeleteCreditCard = async (card_id) => {
    await deleteCreditCard({
      variables: { user_id: parseInt(Id), card_id: card_id },
    });
    modal.close();
  };

  const onModalDeleteCreditCard = (card_id) => {
    modal.open(
      <UnSubscribeModal withoutIcon={true}>
        <LabelNormal
          color="#060809"
          fontSize="22"
          style={{ marginBottom: '15px' }}
        >
          ¿Seguro de que deseas eliminar esta tarjeta?
        </LabelNormal>
        <ButtonPrime
          onClick={() => onSubmitDeleteCreditCard(card_id)}
          label="Eliminar tarjeta"
        />
      </UnSubscribeModal>
    );
  };

  const onSubmitUpdateDoctor = () => {
    if (validationForms()) {
      updateDoctor({
        variables: {
          user_id: +Id,
          first_name: firstName,
          last_name: lastName,
          phone,
          email,
          gender,
          birthdate: `${year}-${month}-${day}`,
          country_id: +country,
          //clinic_name: clinicName,
          //clinic_phone: clinicPhone,
          cryst_aligner:
            treatment === 'Cryst Aligner' || treatment === 'Ambos'
              ? true
              : false,
          smile_system:
            treatment === 'Smile System' || treatment === 'Ambos'
              ? true
              : false,
          //street,
          //number: exterior,
          //suburb: city,
          //state: province,
          //locality: locality,
          //postal_code: codePostal,
          // timezone: timezone,
          // timezone_name: timezone_name,
          id_doctor: idDoctor,
          id_adviser: idAsesor,
          has_adviser: asesor,
          is_doctor: premium,
          status: 'approved',
          professional_license: professionalLicense,
        },
      });
    }
  };

  const onUploadPhoto = async (e) => {
    await uploadPhotoAxios(e);
  };

  const uploadPhotoAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `mutation addProfilePhoto($file: Upload!, $user_id: Int!) {
            addProfilePhoto(input: { user_id: $user_id, file: $file }) {
              id
            }
          }`,
        variables: {
          file: 'file',
          user_id: +Id,
        },
      })
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addProfilePhoto) {
          setPhoto(URL.createObjectURL(file));
          setProgressUpload(0);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        setDownloadError(true);
      });
  };

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        setDownloadError(false);
        setFile({});
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                getDetails({});
                setEdit(false);
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => {
      console.log(err);
      setDownloadError(true);
    },
  });

  const onSubmitFile = async (id) => {
    addProfilePhoto({ variables: { file: file, user_id: +Id } });
  };

  const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD, {
    onCompleted: (data) => {
      if (data.updatePassword) {
        setEditPass(false);
        setPassword('');
        setConfirmPass('');
        modal.open(
          <ModalSuccess onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Contraseña cambiada
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Ingresa con la nueva contraseña cuando inicies sesión de nuevo en
              la plataforma.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getDetails({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationForms = () => {
    if (
      firstName !== '' &&
      lastName !== '' &&
      phone !== '' &&
      email !== '' &&
      gender !== '' &&
      treatment !== '' &&
      idDoctor !== ''
    ) {
      setDisabled(false);
      return true;
    } else {
      setDisabled(true);
      return false;
    }
  };

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    if (prefile) {
      setFile(prefile);
      setLoader(true);
      onUploadPhoto(prefile);
      setDownloadError(false);
    }
    evt.target.value = '';
  };

  const navigateBack = () => {
    setDownloadError(false);
    setEdit(false);
  };

  useEffect(() => {
    validationPass();
  }, [password, confirmPass]);

  const validationPass = () => {
    if (password === '') {
      setDisabled(true);
    } else if (confirmPass === '') {
      setDisabled(true);
    } else if (password.length < 8) {
      setDisabled(true);
    } else if (password !== confirmPass) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="perfil" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => (edit ? navigateBack() : history.goBack())}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>{'Tu perfil'}</Title>
                {!edit && (
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginBottom: 39 }}
                  >
                    Esta información nos sirve para comunicarnos contigo y
                    registrar tus casos
                  </LabelNormal>
                )}
                {edit && (
                  <>
                    <LabelNormal
                      color="#454D58"
                      fontSize="22"
                      style={{ marginBottom: 30 }}
                    >
                      Editar datos de perfil
                    </LabelNormal>
                  </>
                )}
              </div>
              <div className="col-md-8">
                <InlineContainer>
                  <div>
                    {!loadingSkeleton ? (
                      <>
                        <Avatar
                          src={
                            photo
                              ? photo.includes('http')
                                ? photo
                                : `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${photo}`
                              : loadPhotoProfileGender(gender)
                          }
                          fadeOut={progressUpload === 100 ? true : false}
                          floatButton={edit}
                          edit={true}
                          loader={progressUpload}
                          error={downloadError}
                          onChange={onChange}
                          height="161"
                          width="161"
                          borderRadius="14"
                          state={loader ? true : false}
                        />
                        {!edit && !editPass && (
                          <>
                            <LabelNormal
                              color="#757575F2"
                              fontSize="14"
                              style={{ textAlign: 'center', marginTop: 20 }}
                            >
                              Miembro desde
                            </LabelNormal>
                            <LabelNormal
                              color="#757575F2"
                              fontSize="17"
                              fontFamily="medium"
                              style={{ textAlign: 'center' }}
                            >
                              {moment(created).format('DD/MM/YYYY')}
                            </LabelNormal>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <Skeleton
                          height={161}
                          borderRadius={27}
                          width={161}
                          baseColor={'#EEEEF2'}
                          highlightColor={'#FFF'}
                        />
                        <center>
                          <Skeleton
                            count={1}
                            baseColor={'#F6F6F8'}
                            width={97}
                            height={27}
                            borderRadius={5}
                            style={{ marginTop: 10 }}
                            highlightColor={'#FFF'}
                          />
                        </center>
                      </div>
                    )}
                  </div>

                  {!loadingSkeleton ? (
                    <>
                      {!edit && !editPass && (
                        <div style={{ marginLeft: 30 }}>
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 13 }}
                          >
                            <LabelNormal
                              fontSize="26"
                              color="#3C4549"
                              fontFamily="medium"
                            >
                              {`${
                                type === 'doctor' ? 'Dr. ' : ''
                              }${firstName} ${lastName}`}
                            </LabelNormal>
                            <EditProfile
                              color="#757575"
                              width="24"
                              onClick={setEdit}
                            />
                          </InlineContainer>
                          <InlineContainer>
                            <div className="row">
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={UserIcon}
                                    alt="user"
                                    style={{ marginRight: 10 }}
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {gender}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={PhoneIcon}
                                    style={{ marginRight: 10 }}
                                    alt="phone"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {countryName !== null
                                      ? countryName === 'México'
                                        ? '+52 '
                                        : countryName === 'Panamá'
                                        ? '+507 '
                                        : '+502 '
                                      : ''}
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={GiftIcon}
                                    style={{ marginRight: 10 }}
                                    alt="date"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {day ? `${day}/${month}/${year}` : ''}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={MailIcon}
                                    style={{ marginRight: 10 }}
                                    alt="email"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {email}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <img
                                    src={LocationIcon}
                                    style={{ marginRight: 10 }}
                                    alt="location"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {countryName}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div
                                className="col-md-9"
                                style={{ marginTop: 13 }}
                              >
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  onClick={() => setEditPass(!editPass)}
                                  style={{ letterSpacing: '2%' }}
                                  label={'Cambiar contraseña'}
                                />
                              </div>
                            </div>
                          </InlineContainer>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{ marginLeft: 35, marginTop: 10, width: '100%' }}
                    >
                      <div>
                        <Skeleton
                          baseColor={'#F6F6F8'}
                          height={27}
                          borderRadius={5}
                          highlightColor={'#FFF'}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <div className="row">
                          <div className="col-md-3">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div className="col-md-7">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-md-5">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div className="col-md-7">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-md-4">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </InlineContainer>
                {!loadingSkeleton ? (
                  <>
                    {!edit && !editPass && (
                      <div
                        className="bootstrap-wrapper"
                        style={{ marginTop: 46 }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <LabelNormal
                              color="#454D58"
                              fontSize="22"
                              fontFamily="medium"
                            >
                              Perfiles fiscales
                            </LabelNormal>
                            {taxDatas.length > 0 && (
                              <ScrollHorizontal>
                                {taxDatas.map((row) => (
                                  <Card
                                    minWidth="334px"
                                    padding="0px"
                                    border="none"
                                  >
                                    <CardInlineContainer
                                      style={{
                                        borderRadius: 14,
                                        minHeight: 204,
                                        maxWidth: 334,
                                        padding: 16,
                                        gap: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                      className={
                                        row.default ? 'gradiant-border' : ''
                                      }
                                      key={Math.random()}
                                    >
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="18"
                                        fontFamily="medium"
                                      >
                                        {row.business_name}
                                      </LabelNormal>
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="16"
                                        fontFamily="medium"
                                      >
                                        {row.rfc}
                                      </LabelNormal>
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="16"
                                        margin="0"
                                        style={{ width: 302 }}
                                      >
                                        {`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}
                                      </LabelNormal>
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="16"
                                        fontFamily="medium"
                                      >
                                        {row.cfdiUsage.name}
                                      </LabelNormal>
                                      <InlineContainer>
                                        <LinkUnderline
                                          label={'Editar'}
                                          link={`/doctor/editar-perfil-fiscal/${row.id}`}
                                          data={{ ...row }}
                                          margin={'0px'}
                                        />
                                        {taxDatas.length > 1 && (
                                          <LabelUnderline
                                            color="#757575"
                                            fontSize="17"
                                            margin={'0px 7px'}
                                            onClick={() =>
                                              onModalDeleteTaxData(row.id)
                                            }
                                            label={'Eliminar'}
                                          />
                                        )}
                                      </InlineContainer>
                                    </CardInlineContainer>
                                  </Card>
                                ))}
                              </ScrollHorizontal>
                            )}
                            <LinkUnderline
                              label={'Añadir perfil'}
                              link={'/doctor/agregar-perfil-fiscal'}
                              letterSpacing={'2%'}
                              margin={'0px'}
                            />
                          </div>
                          <div className="col-md-12" style={{ marginTop: 36 }}>
                            <LabelNormal
                              color="#454D58"
                              fontSize="22"
                              fontFamily="medium"
                            >
                              Direcciones de envío
                            </LabelNormal>
                            {addresses.length > 0 && (
                              <ScrollHorizontal>
                                {addresses.map((row) => (
                                  <CardInlineContainer
                                    style={{
                                      borderRadius: 10,
                                      minHeight: 133,
                                      maxWidth: 334,
                                      padding: 16,
                                      gap: 10,
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                    className={
                                      row.default ? 'gradiant-border' : ''
                                    }
                                    key={Math.random()}
                                  >
                                    <LabelNormal
                                      color="#3C4549"
                                      fontSize="18"
                                      fontFamily="medium"
                                    >
                                      {row.name}
                                    </LabelNormal>
                                    <LabelNormal
                                      color="#757575"
                                      fontSize="16"
                                      style={{ width: 302 }}
                                    >{`${row.street} ${row.number}, ${row.suburb}, ${row.postal_code} ${row.state}`}</LabelNormal>
                                    <InlineContainer>
                                      <LinkUnderline
                                        label={'Editar'}
                                        link={`/doctor/editar-direccion-envio/${row.id}`}
                                        data={{ ...row }}
                                        margin={'0px'}
                                      />
                                      {addresses.length > 1 && (
                                        <LabelUnderline
                                          color="#757575"
                                          fontSize="17"
                                          onClick={() =>
                                            onModalDeleteAddress(row.id)
                                          }
                                          label={'Eliminar'}
                                        />
                                      )}
                                    </InlineContainer>
                                  </CardInlineContainer>
                                ))}
                              </ScrollHorizontal>
                            )}
                            <LinkUnderline
                              label={'Añadir dirección de envio'}
                              link={'/doctor/agregar-direccion-envio'}
                              letterSpacing={'2%'}
                              margin={'0px'}
                            />
                          </div>
                          <div className="col-md-12" style={{ marginTop: 36 }}>
                            <LabelNormal
                              color="#454D58"
                              fontSize="22"
                              fontFamily="medium"
                            >
                              Tarjetas guardadas
                            </LabelNormal>
                            {creditCards.length > 0 && (
                              <ScrollHorizontal>
                                {creditCards.map((row) => (
                                  <PaymentCardCard
                                    card={row}
                                    onModalDeleteCreditCard={
                                      onModalDeleteCreditCard
                                    }
                                    canDelete={creditCards.length > 1}
                                  />
                                ))}
                              </ScrollHorizontal>
                            )}
                            <LinkUnderline
                              label={'Añadir tarjeta'}
                              link={'/doctor/agregar-tarjeta-credito'}
                              letterSpacing={'2%'}
                              margin={'0px'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="bootstrap-wrapper" style={{ marginTop: 66 }}>
                    <div className="row">
                      <div className="col-md-6">
                        <div style={{ marginBottom: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={27}
                            width={'50%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                        <Skeleton
                          baseColor={'#EEEEF2'}
                          height={205}
                          borderRadius={10}
                          highlightColor={'#FFF'}
                        />
                        <div style={{ marginTop: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={20}
                            width={'30%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 36 }}>
                      <div className="col-md-6">
                        <div style={{ marginBottom: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={27}
                            width={'60%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                        <Skeleton
                          baseColor={'#EEEEF2'}
                          height={132}
                          borderRadius={10}
                          highlightColor={'#FFF'}
                        />
                        <div style={{ marginTop: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={20}
                            width={'50%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 36 }}>
                      <div className="col-md-4">
                        <div style={{ marginBottom: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={27}
                            width={'90%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                        <Skeleton
                          baseColor={'#EEEEF2'}
                          height={110}
                          borderRadius={10}
                          highlightColor={'#FFF'}
                        />
                        <div style={{ marginTop: 10 }}>
                          <Skeleton
                            baseColor={'#EEEEF2'}
                            height={20}
                            width={'40%'}
                            borderRadius={5}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {editPass && (
                    <div className="col-md-12" style={{ marginTop: 40 }}>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="22"
                        style={{ letterSpacing: '2%' }}
                      >
                        Cambio de contraseña
                      </LabelNormal>
                      <div style={{ marginTop: 15 }}>
                        <SpanInput>Nueva contraseña</SpanInput>
                        <InputPassword
                          placeholder="Contraseña"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          validation="passwordlenght"
                          style={{
                            borderColor:
                              password !== '' && confirmPass !== '' && disabled
                                ? '#C60E02'
                                : '',
                            borderWeight: 1,
                            borderstyle: 'solid',
                            maxWidth: 305,
                          }}
                        />
                        <div style={{ marginTop: 20 }}>
                          <SpanInput>Confirmar contraseña</SpanInput>
                          <InputPassword
                            placeholder="Contraseña"
                            onChange={(e) => setConfirmPass(e.target.value)}
                            style={{
                              borderColor:
                                password !== '' &&
                                confirmPass !== '' &&
                                disabled
                                  ? '#C60E02'
                                  : password.length >= 8 && confirmPass !== ''
                                  ? '#169D00'
                                  : '',
                              borderWeight: 1,
                              borderstyle: 'solid',
                              maxWidth: 305,
                            }}
                          />
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            disabled && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas no coinciden
                              </p>
                            )}
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            !disabled && (
                              <p
                                style={{
                                  color: '#169D00',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas coinciden
                              </p>
                            )}
                        </div>
                      </div>
                      <InlineContainer
                        style={{ marginTop: 25, alignItems: 'center' }}
                      >
                        <ButtonPrime
                          label="Guardar cambios"
                          disabled={disabled}
                          maxWidth="198px"
                          onClick={() =>
                            updatePassword({
                              variables: {
                                password,
                                password_confirmation: confirmPass,
                              },
                            })
                          }
                        />
                        <ButtonPrime
                          label="Cancelar"
                          background="transparent"
                          color="#3C4549"
                          border="1px solid #3C4549"
                          minWidth="127"
                          style={{ marginLeft: 20 }}
                          onClick={() => setEditPass(false)}
                        />
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!edit && !editPass && (
                  <div className="row">
                    <div className="col-md-12">
                      <Card padding="0px" border="none" minWidth="340px">
                        <HeaderCard>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="17"
                            fontFamily="medium"
                          >
                            Resumen
                          </LabelNormal>
                        </HeaderCard>
                        {!loadingSkeleton ? (
                          <CardContainer>
                            <DecoratorBlue style={{ marginTop: 0 }}>
                              <LabelNormal color="#FBFBFC" fontSize="17">
                                Cryst Aligner
                              </LabelNormal>
                            </DecoratorBlue>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                marginRight: 30,
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  color="#BDBDBD"
                                  style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.2em',
                                  }}
                                  fontSize="13"
                                >
                                  Activos
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="17"
                                  style={{ marginLeft: 8.48 }}
                                >
                                  {crystAlignerActive.toString()}
                                </LabelNormal>
                              </InlineContainer>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  color="#BDBDBD"
                                  style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.2em',
                                  }}
                                  fontSize="13"
                                >
                                  Totales
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="17"
                                  style={{ marginLeft: 8.48 }}
                                >
                                  {crystAlignerTotal.toString()}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <DecoratorYellow>
                              <LabelNormal color="#FBFBFC" fontSize="17">
                                Smile System
                              </LabelNormal>
                            </DecoratorYellow>
                            <div
                              style={{
                                marginTop: 10,
                                marginRight: 30,
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  color="#BDBDBD"
                                  style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.2em',
                                  }}
                                  fontSize="13"
                                >
                                  Activos
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="17"
                                  style={{ marginLeft: 8.48 }}
                                >
                                  {smileSystemActive.toString()}
                                </LabelNormal>
                              </InlineContainer>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  color="#BDBDBD"
                                  style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.2em',
                                  }}
                                  fontSize="13"
                                >
                                  Totales
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="17"
                                  style={{ marginLeft: 8.48 }}
                                >
                                  {smileSystemTotal.toString()}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                          </CardContainer>
                        ) : (
                          <div className="row" style={{ height: 345 }}>
                            <div className="col-md-12">
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                height={'100%'}
                                style={{
                                  borderBottomLeftRadius: 20,
                                  borderBottomRightRadius: 20,
                                }}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          </div>
                        )}
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card
                        padding="0px"
                        border="none"
                        minWidth="340px"
                        style={{ marginTop: 43 }}
                      >
                        <CardInlineContainer
                          style={{
                            borderRadius: 14,
                            width: 340,
                            padding: 25,
                          }}
                        >
                          <LabelNormal
                            color="#3C4549"
                            fontSize="22"
                            fontFamily="medium"
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            Red B360
                          </LabelNormal>

                          <LabelNormal
                            color="#828282"
                            fontSize="17"
                            style={{
                              marginBottom: 10,
                              letterSpacing: '0.02em',
                            }}
                          >
                            ¿Ya verificaste que tus datos de contacto son
                            correctos? Así te encontrarán en nuestra red de
                            doctores de la página B360.mx
                          </LabelNormal>
                          <div style={{ marginTop: 30 }}>
                            <ButtonPrime
                              style={{ width: 182 }}
                              label={
                                surgieries === 0
                                  ? 'Registrar datos'
                                  : 'Revisar datos'
                              }
                              onClick={() =>
                                history.push(`/doctor/surgery/${id}`)
                              }
                            />
                          </div>
                        </CardInlineContainer>
                      </Card>
                    </div>
                    {!loadingSkeleton && (
                      <div
                        className="col-md-12"
                        style={{
                          marginTop: 59,
                          marginBottom: 59,
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <CloseSession onClick={() => props.logout()} />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-7">
                {edit && (
                  <EditProfileForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    day={day}
                    setDay={setDay}
                    month={month}
                    setMonth={setMonth}
                    year={year}
                    setYear={setYear}
                    gender={gender}
                    setGender={setGender}
                    countryName={countryName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    onSubmitUpdateDoctor={onSubmitUpdateDoctor}
                    disabled={disabled}
                  />
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(action.logout()),
});

export default connect(null, mapDispatchToProps)(Profile);
