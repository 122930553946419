import React from 'react';
import styled from "styled-components";
import X from '@images/icons/fi_x.svg';
import FeatherIcon from 'feather-icons-react';

const ButtonCloseStyle = styled.button`
  position: absolute;
  right: 18px;
  top: ${(({top}) => top ? top : '17px')};
  border-radius: 50px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: #F6F6F8;
  transition: all .2s ease-out;
  color: #4F4F4F;
  &:hover {
    background: transparent;
    border: 1px solid #3C4549;
  }
  &:active {
    background: #828282;
    border: 1px solid #828282;
    color: #F6F6F8;
  }
`;

 const ButtonClose = (props) =>
    <ButtonCloseStyle onClick={() => props.onClick()}>
        <FeatherIcon icon="x" style={{height: 27.57, width: 27.57}}/>
        {/* <img src={X}  /> */}
    </ButtonCloseStyle>
 export default ButtonClose;