import Skeleton from 'react-loading-skeleton';
import UploadFile from '../NewCase/Files/UploadFile';
import { useState, useEffect } from 'react';
import {
  CardModel,
  CardModelExtended,
  FileContainer,
  InlineContainer,
  LabelNormal,
} from '../shared/Styled';
import ButtonPrime from '@components/shared/ButtonPrime';
import { modal } from '@components/ModalManagement';
import ModalOdontogram from '@components/Modal/ModalOdontogram';
import ModalBrackets from '@components/Modal/ModalBrackets';
import ModalPosterior from '@components/Modal/ModalPosterior';
import ModalIpr from '@components/Modal/ModalIpr';
import LabelUnderline from '../shared/LabelUnderline';
import ResumeSS from '../DoctorFormSS/resume';
import ResumeCA from '../DoctorFormCA/resume';
import {
  lowOdontogramaTemplate,
  odontogramaArray,
  setLowOdontogram,
  setUpOdontogram,
  upOdontogramaTemplate,
} from '../../utils';

const FileDisplay = ({
  type = 'doctor',
  loadingSkeleton,
  loading,
  setLoading,
  caseId,
  patient,
  treatment,
  file,
  setSelectTreatmentFile,
  filesTreatment,
  setFilesTreatment,
  typeSTL,
  chatMessages,
  activeChat,
  getNextImageReview,
  doctorData,
  defaultAddress,
  errorForm,
  formStatus,
  reject,
  setReject,
  questionDoctorForm = () => {},
  acceptDeclineForm = () => {},
  approvedFile = () => {},
  undoFile = () => {},
}) => {
  const [click, setClick] = useState(0);
  const [formCAData, setFormCAData] = useState({});
  const [formSSData, setFormSSData] = useState({});
  const [linkData, setLinkData] = useState([]);

  const odontogramData = () => {
    modal.open(
      <ModalOdontogram
        formCAData={formCAData}
        odontograma={odontogramaArray}
        treatment={treatment}
        formSSData={formSSData}
      />
    );
  };

  const posteriorData = () => {
    modal.open(<ModalPosterior formCAData={formCAData} />);
  };

  const iprData = () => {
    modal.open(<ModalIpr formCAData={formCAData} />);
  };

  const bracketsData = () => {
    modal.open(<ModalBrackets formSSData={formSSData} />);
  };

  useEffect(() => {
    if (patient) {
      const treatment = patient.treatment_related;
      let up_odontograma = [...upOdontogramaTemplate];
      let lo_odontograma = [...lowOdontogramaTemplate];
      if (
        treatment.formOdontogram &&
        treatment.formOdontogram.up_left_second_molar_data &&
        treatment.formOdontogram.type !== 'Sin cambios'
      ) {
        const odontograma = treatment.formOdontogram;
        up_odontograma = setUpOdontogram(odontograma);
        lo_odontograma = setLowOdontogram(odontograma);
      }
      setFormCAData({
        arcad: treatment.formArcade,
        shape: treatment.formShape,
        ipr: treatment.formIpr,
        odontograma: treatment.formOdontogram,
        additional: treatment.additional_instructions,
        up_odontograma,
        lo_odontograma,
      });
      setFormSSData({
        bracket_ss: treatment.bracket_ss,
        odontograma: treatment.formOdontogram,
        brackets: treatment.formBracket,
        additional: treatment.additional_instructions,
        up_odontograma,
        lo_odontograma,
      });
      setLinkData(treatment.link_data ? JSON.parse(treatment.link_data) : ['']);
    }
  }, [patient]);

  if (loadingSkeleton) {
    return (
      <div
        style={{
          flexGrow: 1,
          maxWidth: '43vw',
          marginLeft: '24px',
          marginRight: '24px',
          marginTop: '64px',
        }}
      >
        <Skeleton
          height={320}
          borderRadius={20}
          width={'100%'}
          baseColor={'#EEEEF2'}
          highlightColor={'#FFF'}
        />
        <div style={{ marginTop: 20 }}>
          <Skeleton
            height={38}
            borderRadius={11}
            width={'60%'}
            baseColor={'#EEEEF2'}
            highlightColor={'#FFF'}
          />
          <Skeleton
            height={28}
            borderRadius={11}
            width={'40%'}
            baseColor={'#EEEEF2'}
            highlightColor={'#FFF'}
            style={{ marginTop: 10 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Skeleton
            height={46}
            borderRadius={20}
            width={'40%'}
            baseColor={'#EEEEF2'}
            highlightColor={'#FFF'}
          />
        </div>
      </div>
    );
  }
  return (
    <div style={{ flexGrow: 1, maxWidth: '43vw' }}>
      {file && (
        <FileContainer>
          {file?.type === 'pdf' ? (
            <>
              {treatment === 'smile_system' ? (
                <div>
                  <ResumeSS
                    formSSData={formSSData}
                    chatMessages={chatMessages}
                    review={true}
                    formStatus={formStatus}
                    odontogramData={odontogramData}
                    bracketsData={bracketsData}
                  />
                </div>
              ) : (
                <div>
                  <ResumeCA
                    formCAData={formCAData}
                    chatMessages={chatMessages}
                    review={true}
                    formStatus={formStatus}
                    posteriorData={posteriorData}
                    iprData={iprData}
                    odontogramData={odontogramData}
                  />
                </div>
              )}
            </>
          ) : (
            <UploadFile
              userType={type}
              name={file?.review}
              patientId={patient.id}
              section={file?.section?.toUpperCase()}
              filesTreatment={filesTreatment}
              setFilesTreatment={setFilesTreatment}
              parentLoading={loading}
              setParentLoading={setLoading}
              corrections={true}
              click={click}
              errorForm={errorForm}
              typeSTL={typeSTL}
              setSelectTreatmentFile={setSelectTreatmentFile}
            />
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginBottom: '36px',
            }}
          >
            {type === 'doctor' ? (
              <>
                {file?.statusId === '4' &&
                  (file?.type !== 'stl' ||
                    (file?.type === 'stl' && typeSTL !== 'shipping')) && (
                    <InlineContainer style={{ alignItems: 'flex-end' }}>
                      <LabelNormal color="#169D00" fontSize="20">
                        Archivo actualizado
                      </LabelNormal>
                      <LabelUnderline
                        color="#757575"
                        fontSize="17"
                        onClick={() => setClick(click + 1)}
                        label={'Cambiar archivo'}
                      />
                    </InlineContainer>
                  )}
                {((chatMessages.length > 0 && !activeChat) ||
                  chatMessages.length === 0) && (
                  <div>
                    {file?.type !== 'pdf' &&
                      ((file?.statusId !== '4' && file?.type !== 'stl') ||
                        (file?.statusId !== '4' &&
                          file?.type === 'stl' &&
                          typeSTL !== 'shipping') ||
                        (filesTreatment[filesTreatment.length - 1].id !==
                          file?.id &&
                          (file?.type !== 'stl' ||
                            (file?.type === 'stl' &&
                              typeSTL !== 'shipping')))) && (
                        <ButtonPrime
                          label={
                            file?.statusId !== '4'
                              ? 'Subir archivo nuevo'
                              : 'Subir siguiente archivo'
                          }
                          onClick={() => {
                            file?.statusId === '4'
                              ? getNextImageReview(file?.id)
                              : setClick(click + 1);
                          }}
                          disabled={loading}
                        />
                      )}
                  </div>
                )}
              </>
            ) : (
              <>
                {typeSTL === 'shipping' && file?.section === 'stl' && (
                  <LabelNormal fontSize="17" color="#828282">
                    Sube la fotografía del modelo para aprobarlo o rechazarlo
                  </LabelNormal>
                )}
                {(file?.statusId === '4' || file?.statusId === '1') &&
                  ((file?.type === 'stl' && typeSTL === 'url') ||
                    (file?.type === 'image' &&
                      file?.section === 'stl' &&
                      typeSTL === 'shipping')) && (
                    <InlineContainer style={{ alignItems: 'flex-end' }}>
                      <LabelUnderline
                        color="#757575"
                        fontSize="17"
                        onClick={() => setClick(click + 1)}
                        label={'Cambiar archivo'}
                      />
                    </InlineContainer>
                  )}
                {(file?.status === 'Pendiente' ||
                  file?.status === '' ||
                  file?.status === 'Corregido' ||
                  file?.status === 'upload') &&
                reject === '' ? (
                  <InlineContainer
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      minWidth: 490,
                      width: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <ButtonPrime
                      label="Aceptar"
                      onClick={() =>
                        file?.status === 'upload'
                          ? {}
                          : file?.type === 'pdf'
                          ? acceptDeclineForm({
                              variables: {
                                patient_id: patient.id,
                                status: 'Aprobado',
                              },
                            })
                          : approvedFile({
                              variables: {
                                file_id: file?.id,
                              },
                            })
                      }
                      disabled={
                        (file?.type === 'image' &&
                          (file?.status === 'Corregido' ||
                            file?.status === 'upload') &&
                          typeSTL === 'shipping' &&
                          file?.review === 'modelo') ||
                        (file?.type === 'stl' &&
                          file?.status === 'upload' &&
                          typeSTL === 'url') ||
                        (file?.type === 'stl' &&
                          file?.status === 'upload' &&
                          typeSTL === 'shipping' &&
                          file?.review === 'modelo') ||
                        loading
                          ? true
                          : false
                      }
                    />
                    <ButtonPrime
                      onClick={() => {
                        if (file?.status !== 'upload' && file?.type !== 'pdf') {
                          setReject(file?.id);
                        } else {
                          questionDoctorForm();
                        }
                      }}
                      label="Rechazar"
                      background="transparent"
                      border="1px solid #3C4549"
                      color="#3C4549"
                      disabled={
                        (file?.type === 'image' &&
                          (file?.status === 'Corregido' ||
                            file?.status === 'upload') &&
                          typeSTL === 'shipping' &&
                          file?.review === 'modelo') ||
                        (file?.type === 'stl' &&
                          file?.status === 'upload' &&
                          typeSTL === 'url') ||
                        (file?.type === 'stl' &&
                          file?.status === 'upload' &&
                          typeSTL === 'shipping' &&
                          file?.review === 'modelo') ||
                        loading
                          ? true
                          : false
                      }
                    />
                  </InlineContainer>
                ) : (
                  <InlineContainer
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 26,
                      minWidth: 490,
                      width: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <LabelNormal
                      style={{
                        textDecorationLine: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        reject !== ''
                          ? setReject('')
                          : file?.type === 'pdf'
                          ? acceptDeclineForm({
                              variables: {
                                patient_id: patient.id,
                                status: 'Pendiente',
                              },
                            })
                          : undoFile({
                              variables: {
                                file_id: file?.id,
                              },
                            })
                      }
                      fontSize="17"
                      color="#757575"
                    >
                      Deshacer
                    </LabelNormal>
                    {filesTreatment[filesTreatment.length - 1].id !==
                      file?.id && (
                      <ButtonPrime
                        onClick={() => getNextImageReview(file?.id)}
                        disabled={loading}
                        label="Siguiente"
                        background="transparent"
                        border="1px solid #3C4549"
                        color="#3C4549"
                      />
                    )}
                  </InlineContainer>
                )}
                {typeSTL === 'url' && file?.section === 'stl' && (
                  <div
                    style={{
                      border: '1px solid #C8C8C8',
                      padding: 21,
                      borderRadius: 10,
                      marginTop: 24,
                    }}
                  >
                    <LabelNormal color="#757575" fontSize="18">
                      Link de STLs
                    </LabelNormal>
                    <LabelNormal color="#757575" fontSize="17">
                      Descarga los archivos desde el link y súbelos a la
                      plataforma para aprobarlos o rechazarlos
                    </LabelNormal>
                    <div
                      style={{
                        wordWrap: 'break-word',
                      }}
                    >
                      {linkData.map((item, key) => (
                        <LabelNormal fontSize="17" color="#3C4549">
                          <a
                            href={
                              /^(ftp|http|https):\/\//.test(item)
                                ? item
                                : `https://${item}`
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                        </LabelNormal>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {type === 'doctor' &&
            file?.type === 'stl' &&
            typeSTL === 'shipping' && (
              <>
                <LabelNormal color="#3C4549" fontSize="28">
                  Modelo
                </LabelNormal>
                <LabelNormal color="#828282" fontSize="17">
                  Manda tus modelos por el servicio de paquetería de tu elección
                  a la siguiente dirección y con los siguientes datos en una
                  hoja de papel dentro del paquete.
                </LabelNormal>
                <CardModel>
                  <LabelNormal
                    style={{
                      margin: 0,
                      padding: 0,
                      marginRight: 7,
                      marginBottom: 10,
                    }}
                    color="#757575"
                    fontSize="18"
                  >
                    Datos a incluir
                  </LabelNormal>
                  <InlineContainer>
                    <LabelNormal
                      color="#BDBDBD"
                      style={{ margin: 0, padding: 0, marginRight: 7 }}
                      fontSize="17"
                    >
                      Nombre del doctor
                      <span
                        style={{
                          color: '#828282',
                          marginLeft: '5px',
                          fontWeight: 600,
                        }}
                      >
                        {doctorData.user && doctorData.user.first_name
                          ? `${doctorData.user.first_name} ${doctorData.user.last_name}`
                          : ''}
                      </span>
                    </LabelNormal>
                  </InlineContainer>
                  <InlineContainer>
                    <LabelNormal
                      color="#BDBDBD"
                      style={{ margin: 0, padding: 0, marginRight: 7 }}
                      fontSize="17"
                    >
                      Nombre del paciente
                      <span
                        style={{
                          color: '#828282',
                          marginLeft: '5px',
                          fontWeight: 600,
                        }}
                      >
                        {`${patient.first_name} ${patient.last_name}`}
                      </span>
                    </LabelNormal>
                  </InlineContainer>
                  <InlineContainer>
                    <LabelNormal
                      color="#BDBDBD"
                      style={{ margin: 0, padding: 0, marginRight: 7 }}
                      fontSize="17"
                    >
                      ID de caso
                      <span
                        style={{
                          color: '#828282',
                          marginLeft: '5px',
                          fontWeight: 600,
                        }}
                      >
                        {caseId}
                      </span>
                    </LabelNormal>
                  </InlineContainer>
                </CardModel>
                <CardModelExtended>
                  <LabelNormal
                    style={{
                      margin: 0,
                      padding: 0,
                      marginRight: 7,
                      marginBottom: 10,
                    }}
                    color="#757575"
                    fontSize="18"
                  >
                    Dirección de envío | Borgatta
                  </LabelNormal>
                  <InlineContainer>
                    <LabelNormal
                      color="#828282"
                      style={{ margin: 0, padding: 0 }}
                      fontSize="17"
                    >
                      <span style={{ color: '#BDBDBD' }}>Dirección: </span>
                      {defaultAddress.id
                        ? `${defaultAddress.street}, ${defaultAddress.number} ${defaultAddress.suburb}, CP ${defaultAddress.postal_code} ${defaultAddress.city}, ${defaultAddress.state}`
                        : ''}
                    </LabelNormal>
                  </InlineContainer>
                </CardModelExtended>
              </>
            )}
        </FileContainer>
      )}
    </div>
  );
};

export default FileDisplay;
