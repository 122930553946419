/* eslint-disable react-hooks/exhaustive-deps */
import { Controller } from "react-hook-form"
import { Container, LabelInput, LabelMuted, InputComponent, InlineContainer, ErrorMessage } from "../Styled"
import { useEffect, useState } from "react"
import moment from "moment"

const DateInput = ({ name, control, errors, clearErrors, setError, setValue, rules, label, muted, value, ...props }) => {
    const birthdayDay = value?.birthdate.split('-')[2];
    const birthdayMonth = value?.birthdate.split('-')[1];
    const birthdayYear = value?.birthdate.split('-')[0];

    const [date , setDate] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')

    useEffect(() => {
        setDate(birthdayDay);
        setMonth(birthdayMonth);
        setYear(birthdayYear);
    }, [value])

    const maskDate = (event) => {
        let val = event.target.value;
        val = val.replace(/[^0-9]/g, '')
        if (val.length <= 2) setDate(val)
    }
    const maskMonth = (event) => {
        let val = event.target.value;
        val = val.replace(/[^0-9]/g, '')
        if (val.length <= 2) setMonth(val)
    }
    const maskYear = (event) => {
        let val = event.target.value;
        val = val.replace(/[^0-9]/g, '')
        if (val.length <= 4) setYear(val)
    }

    const validate = () => {
        const fullDate = moment(`${year}-${month}-${date}`)
        if (!fullDate.isValid()) setError(name, { type: 'custom', message: '*Fecha incorrecta' })
        else clearErrors(name)
        setValue(name, `${year}-${month}-${date}`)
    }

    return (
        <Container>
            { label && <LabelInput>{ label }</LabelInput> }
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <InlineContainer>
                        <InputComponent
                            {...field}
                            {...props}
                            placeholder="DD"
                            onFocus={() => clearErrors && clearErrors(name)}
                            onBlur={validate}
                            onChange={maskDate}
                            value={date}
                            borderColor={ errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)' }
                            maxWidth="60px"

                        />
                        <InputComponent
                            {...field}
                            {...props}
                            placeholder="MM"
                            onFocus={() => clearErrors && clearErrors(name)}
                            onBlur={validate}
                            onChange={maskMonth}
                            value={month}
                            borderColor={ errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)' }
                            maxWidth="60px"
                        />
                        <InputComponent
                            {...field}
                            {...props}
                            placeholder="AAAA"
                            onFocus={() => clearErrors && clearErrors(name)}
                            onBlur={validate}
                            onChange={maskYear}
                            value={year}
                            borderColor={ errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)' }
                            maxWidth="90px"
                        />
                    </InlineContainer>
                )}
            />
            <InlineContainer>
                <ErrorMessage>{ errors && errors[name] && errors[name]?.message }</ErrorMessage>
            </InlineContainer>
        </Container>
    )
}

export default DateInput