import { CardImg, LabelNormal } from '../shared/Styled';

const FileCard = ({ 
    index, 
    item, 
    borderTop,
    selectFile,
    type = 'doctor'
}) => {
  return (
    <div
      style={ index !== 0 && borderTop
          ? {
              borderTopColor: '#000',
              borderTopStyle: 'solid',
              borderTopWidth: 2,
              marginTop: 8,
              paddingTop: 8,
              position: 'relative',
            }
          : { position: 'relative' }
      }
      key={Math.random()}
      onClick={selectFile}
    >
      { item.status === 'Rechazado' && (
        <div
          style={{
            background: '#C60E02',
            width: 115,
            height: 33,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 7,
            position: 'absolute',
            top: '50%',
            left: '50%',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <LabelNormal fontSize="12" color="#F4F4F4">
            Requiere atención
          </LabelNormal>
        </div>
      )}
      { (item.status === 'Aprobado' || item.status === 'Correción Aprobada') && (
        <div
          style={{
            background: '#1DBB24',
            width: 82,
            height: 33,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 7,
            position: 'absolute',
            top: '50%',
            left: '50%',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <LabelNormal fontSize="12" color="#F4F4F4">
            Aceptado
          </LabelNormal>
        </div>
      )}
      {type === 'doctor' && item.statusId === '4' && (
        <div
          style={{
            background: '#1DBB24',
            width: 82,
            height: 33,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 7,
            position: 'absolute',
            top: '50%',
            left: '50%',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <LabelNormal fontSize="12" color="#F4F4F4">
            Actualizado
          </LabelNormal>
        </div>
      )}
      <CardImg
        style={{
          cursor: 'pointer',
          borderColor:
            (item.status === 'Aprobado' || item.status === 'Correción Aprobada')
              ? '#1DBB24'
              : item.status === 'Rechazado'
              ? '#C60E02'
              : 'transparent',
          borderWidth: 2,
          borderStyle: 'solid',
          height: 85,
          maxHeight: 85,
          padding: 0
        }}
        src={
          (item.type === 'image' || (item.type === 'stl' && item.review === 'modelo'))
            ? `${item.preview}`
            : item.type === 'stl'
            ? require('@images/files/stl.jpg')
            : item.type === 'zip'
            ? require('@images/files/zip.jpg')
            : require('@images/files/form.png')
        }
        key={Math.random()}
      />
    </div>
  );
};

export default FileCard;
