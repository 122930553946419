import React from 'react';
import styled from 'styled-components';
import ButtonClose from '../shared/ButtonClose';
import Map from '@components/DoctorFormCA/map';
import { modal } from '@components/ModalManagement';
import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';
import RealoadIcon from '@images/reaload-icon.svg';
import { tops, bottoms } from '@components/DoctorFormCA/icons';

const Inline = styled.div`
  display: flex;
  align-item: left;
  flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ModalOdontogram = (props) => {

    return (
        <div className='modal' style={{maxWidth: 950, height: '100%', margin: 84}}>
            <Inline>
                <LabelNormal fontSize="26" color="#3C4549" bold>Odontograma</LabelNormal>
                <ButtonClose onClick={() => modal.close()} top={'40px'}/>
            </Inline>
            {props.treatment !== "smile_system" &&
                <div>
                    <div className='bootstrap-wrapper' style={{zIndex: 999}}>
                        <InlineContainer style={{alignItems:'center', marginTop: 20}}>
                            <LabelNormal fontSize="17" color="#757575">{props.formCAData.odontograma.type}</LabelNormal>
                        </InlineContainer>
                        <div>
                            <LabelNormal color="#3C4549" fontSize="17" style={{marginBottom: 30}}>Arcada superior</LabelNormal>
                            <div className='row'>
                                <div className='col-md-10' style={{position: 'relative', maxWidth: 990}}>
                                    <InlineContainer style={{height: 60, alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 35}}>
                                                    {props.formCAData.up_odontograma.find((v,i) => i === index && v[0] !== "") &&
                                                        <div style={{position: 'absolute', zIndex: 9}}>
                                                            {props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === index)[0]) &&
                                                                <img src={props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === index)[0])?.icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' ? -13 : 0}} />
                                                            }
                                                            {props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                        </div>
                                                    }
                                                    <img src={tops[index]} style={{paddingRight: 16, width: '100%', opacity: props.formCAData.up_odontograma.find((v,i) => i === index && v[0] !== "") ? 0.5 : 1}} />
                                                </div>
                                            </div>
                                        ))}
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 35}}>
                                                {props.formCAData.up_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") &&
                                                <div style={{position: 'absolute', zIndex: 9}}>
                                                    {props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === (index + 8))[0]) &&
                                                        <img src={props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === (index + 8))[0])?.icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' ? -13 : 0}} />
                                                    }
                                                    {props.odontograma.find((v) => v.name === props.formCAData.up_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                </div>
                                                }
                                                <img src={tops[Math.abs(index - 7)]} style={{paddingLeft: 16, transform: 'rotateX(380deg) rotateY(180deg)', width: '100%', opacity: props.formCAData.up_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") ? 0.5 : 1}} />
                                            </div>
                                        ))}
                                    </InlineContainer>
                                </div>
                            </div>
                            
                            <LabelNormal color="#3C4549" fontSize="17" style={{marginBottom: 30, marginTop: 30}}>Arcada inferior</LabelNormal>
                            <div className='row'>
                                <div className='col-md-10' style={{position: 'relative', maxWidth: 990}}>
                                    <InlineContainer style={{height: 60, alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30}}>
                                                    {props.formCAData.lo_odontograma.find((v,i) => i === index && v[0] !== "") &&
                                                        <div style={{position: 'absolute', zIndex: 9}}>
                                                            {props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === index)[0]) &&
                                                                <img src={props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === index)[0])?.icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' ? -13 : 0}} />
                                                            }
                                                            {props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === index)[0])?.name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir")?.icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                        </div>
                                                    }
                                                    <img src={bottoms[index]} style={{paddingRight: 16, width: '100%', opacity: props.formCAData.lo_odontograma.find((v,i) => i === index && v[0] !== "") ? 0.5 : 1}} />
                                                </div>
                                            </div>
                                        ))}
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30}}>
                                                {props.formCAData.lo_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") &&
                                                    <div style={{position: 'absolute', zIndex: 9}}>
                                                        {props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === (index + 8))[0]) &&
                                                            <img src={props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === (index + 8))[0])?.icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' ? -13 : 0}} />
                                                        }
                                                        {props.odontograma.find((v) => v.name === props.formCAData.lo_odontograma.find((v, i) => i === (index + 8))[0])?.name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir")?.icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                    </div>
                                                }
                                                <img src={bottoms[Math.abs(index - 7)]} style={{paddingLeft: 16, transform: 'rotateX(380deg) rotateY(180deg)', width: '100%', opacity: props.formCAData.lo_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") ? 0.5 : 1}} />
                                            </div>
                                        ))}
                                    </InlineContainer>
                                </div>
                            </div>
                            <LabelNormal color="#757575" fontSize="17" style={{marginTop: 20}}>Glosario</LabelNormal>
                            <div className='row' style={{marginTop: 10}}>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={DienteAusente} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente ausente</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={Implante} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Implante</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={ProtesisFija} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Prótesis fija</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={ErosionDental} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Erosión dental</LabelNormal>
                                    </InlineContainer>
                                </div>
                            </div>
                            <div className='row' style={{marginTop: 10}}>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={DienteExtraer} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente a extraer</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={PiezaErupcion} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Pieza en erupción</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={DienteIncluidoRetenido} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente incluido retenido</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={FracturaCorona} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Fractura en corona</LabelNormal>
                                    </InlineContainer>
                                </div>
                            </div>
                            <div className='row' style={{marginTop: 10}}>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={PiesaExcluir} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Pieza a excluir</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={Inscrustacion} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Incrustación</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={DienteConEndodoncia} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente con endodoncia</LabelNormal>
                                    </InlineContainer>
                                </div>
                                <div className='col-md-3'>
                                    <InlineContainer>
                                        <img src={Restauracion} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                        <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Restauración</LabelNormal>
                                    </InlineContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {props.treatment === "smile_system" &&
                <div>
                    <div className='bootstrap-wrapper' style={{zIndex: 999}}>
                        <InlineContainer style={{alignItems:'center', marginTop: 20}}>
                            <LabelNormal fontSize="17" color="#757575">{props.formSSData.odontograma.type}</LabelNormal>
                        </InlineContainer>
                        <div>
                            <LabelNormal color="#3C4549" fontSize="17" style={{marginBottom: 30}}>Arcada superior</LabelNormal>
                            <div className='row'>
                                <div className='col-md-10' style={{position: 'relative', maxWidth: 990}}>
                                    <InlineContainer style={{height: 60, alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 35}}>
                                                    {props.formSSData.up_odontograma.find((v,i) => i === index && v[0] !== "") && <img src={RealoadIcon} style={{position: 'absolute', top: -20, paddingRight: 16, cursor: 'pointer'}} />}
                                                    {props.formSSData.up_odontograma.find((v,i) => i === index && v[0] !== "") &&
                                                        <div style={{position: 'absolute', zIndex: 9, bottom: -15}}>
                                                            <img src={props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === index)[0]).icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === index)[0]).name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === index)[0]).name === 'Implante' ? -13 : 0}} />
                                                            {props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === index)[0]).name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                        </div>
                                                    }
                                                    <div style={{paddingRight: 16, marginBottom: 5, marginTop: 10}}>
                                                        <LabelNormal color="#9E9E9E" fontSize="13">{18-index}</LabelNormal>
                                                    </div>
                                                    <div style={{marginTop: 5, height: 35, width: 'auto', alignItems: 'center', justifyContent: 'center'}}>
                                                        <img src={tops[index]} style={{paddingRight: 16, width: '100%', opacity: props.formSSData.up_odontograma.find((v,i) => i === index && v[0] !== "") ? 0.5 : 1}} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 35}}>
                                                {props.formSSData.up_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") && <img src={RealoadIcon} style={{position: 'absolute', top: -20, paddingRight: 16, cursor: 'pointer'}} />}
                                                {props.formSSData.up_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") &&
                                                <div style={{position: 'absolute', zIndex: 9, bottom: -15}}>
                                                    <img src={props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === (index + 8))[0]).icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' ? -13 : 0}} />
                                                    {props.odontograma.find((v) => v.name === props.formSSData.up_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                </div>
                                                }
                                                <div style={{paddingRight: 16, marginBottom: 5, marginTop: 10}}>
                                                    <LabelNormal color="#9E9E9E" fontSize="13">{21+index}</LabelNormal>
                                                </div>
                                                <div style={{marginTop: 5, height: 35, width: 'auto', alignItems: 'center', justifyContent: 'center'}}>
                                                    <img src={tops[Math.abs(index - 7)]} style={{paddingLeft: 16, transform: 'rotateX(380deg) rotateY(180deg)', width: '100%', opacity: props.formSSData.up_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") ? 0.5 : 1}} />
                                                </div>
                                            </div>
                                        ))}
                                    </InlineContainer>
                                </div>
                            </div>
                            
                            <LabelNormal color="#3C4549" fontSize="17" style={{marginBottom: 30, marginTop: 30}}>Arcada inferior</LabelNormal>
                            <div className='row'>
                                <div className='col-md-10' style={{position: 'relative', maxWidth: 990}}>
                                    <InlineContainer style={{height: 60, alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 35}}>
                                                    {props.formSSData.lo_odontograma.find((v,i) => i === index && v[0] !== "") && <img src={RealoadIcon} style={{position: 'absolute', bottom: -20, paddingRight: 16, cursor: 'pointer'}} />}
                                                    {props.formSSData.lo_odontograma.find((v,i) => i === index && v[0] !== "") &&
                                                        <div style={{position: 'absolute', zIndex: 9, top: -15}}>
                                                            <img src={props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === index)[0]).icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === index)[0]).name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === index)[0]).name === 'Implante' ? -13 : 0}} />
                                                            {props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === index)[0]).name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                        </div>
                                                    }
                                                    <div style={{marginTop: 5, height: 35, width: 'auto', alignItems: 'center', justifyContent: 'center'}}>
                                                        <img src={bottoms[index]} style={{paddingRight: 16, width: '100%', opacity: props.formSSData.lo_odontograma.find((v,i) => i === index && v[0] !== "") ? 0.5 : 1}} />
                                                    </div>
                                                    <div style={{paddingRight: 16, marginBottom: 15, marginTop: 15}}>
                                                        <LabelNormal color="#9E9E9E" fontSize="13">{48-index}</LabelNormal>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {Array.from(Array(8).keys()).map((_, index) => (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 35}}>
                                                {props.formSSData.lo_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") && <img src={RealoadIcon} style={{position: 'absolute', bottom: -20, paddingRight: 16, cursor: 'pointer'}} />}
                                                {props.formSSData.lo_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") &&
                                                    <div style={{position: 'absolute', zIndex: 9, top: -15}}>
                                                        <img src={props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === (index + 8))[0]).icon} style={{cursor: 'pointer', paddingRight: props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' ? 0 : 16, marginRight: props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' ? -13 : 0}} />
                                                        {props.odontograma.find((v) => v.name === props.formSSData.lo_odontograma.find((v, i) => i === (index + 8))[0]).name === 'Implante' && <img src={props.odontograma.find((v) => v.name === "Pieza a excluir").icon} style={{cursor: 'pointer', paddingRight: 16}} />}
                                                    </div>
                                                }
                                                <div style={{marginTop: 5, height: 35, width: 'auto', alignItems: 'center', justifyContent: 'center'}}>
                                                    <img src={bottoms[Math.abs(index - 7)]} style={{paddingLeft: 16, transform: 'rotateX(380deg) rotateY(180deg)', width: '100%', opacity: props.formSSData.lo_odontograma.find((v,i) => i === (index + 8) && v[0] !== "") ? 0.5 : 1}} />
                                                </div>
                                                <div style={{paddingRight: 16, marginBottom: 15, marginTop: 15}}>
                                                    <LabelNormal color="#9E9E9E" fontSize="13">{31+index}</LabelNormal>
                                                </div>
                                            </div>
                                        ))}
                                    </InlineContainer>
                                </div>
                            </div>
                        </div>
                        <LabelNormal color="#757575" fontSize="17" style={{marginTop: 40}}>Glosario</LabelNormal>
                        <div className='row' style={{marginTop: 10}}>
                            <div className='col-md-3'>
                                <InlineContainer>
                                    <img src={DienteAusente} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                    <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente ausente</LabelNormal>
                                </InlineContainer>
                            </div>
                            <div className='col-md-3'>
                                <InlineContainer>
                                    <img src={DienteExtraer} style={{width: '25px', height: '25px', borderRadius: '48.6765px', background: '#FFFFFF'}} />
                                    <LabelNormal color="#3C4549" fontSize="17" style={{fontWeight: 400}}>Diente a extraer</LabelNormal>
                                </InlineContainer>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ModalOdontogram;