import { useHistory } from 'react-router-dom';
import { UilClipboardNotes, UilEye } from '@iconscout/react-unicons';
import Card from '../shared/Card';
import { CardContainer, InlineContainer, LabelNormal } from '../shared/Styled';
import SkeletonActions from './actionsComponents/SkeletonActions';
import LabelCase from '../shared/LabelCase';

const Actions = ({
  patientId,
  treatmentRelated,
  archived,
  loadingSkeleton,
  setEdit,
  setHistoryClinic,
  navigateToInvoices,
  handleOpenModal,
}) => {
  const history = useHistory();
  const treatmentTypeId = parseInt(treatmentRelated?.treatmentType?.id) ?? null;
  const { type: statusType, index: statusIndex } =
    treatmentRelated?.treatmentStatus ?? {};
  const role = localStorage.getItem('b360-role');
  return (
    <div
      className="col-md-12"
      style={{
        marginTop: 25,
        filter: 'drop-shadow(0px 0px 4px rgba(162, 162, 162, 0.1))',
      }}
    >
      {((statusType === 'planning' && statusIndex < 14 && !loadingSkeleton) ||
        (statusType === 'new_case' &&
          (statusIndex === 7 || statusIndex === 9)) ||
        statusType === 'printing' ||
        statusType === 'thermoforming' ||
        statusType === 'warehouse' ||
        statusType === 'packing' ||
        statusType === 'shipping' ||
        statusType === 'finished') && (
        <div className="col-md-12" style={{ marginBottom: 25, padding: 0 }}>
          <Card
            padding="0px"
            border="none"
            minWidth="340px"
            borderRadius="30px"
            minHeight="64px"
          >
            <CardContainer
              style={{
                borderRadius: 25,
                padding: '15px 30px 13px 30px',
                height: '4rem',
              }}
            >
              <InlineContainer
                style={{
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push({
                    pathname: `/${
                      role === 'teacher' ? 'profesor' : 'doctor'
                    }/caso/planificacion/${patientId}`,
                    state: {},
                  })
                }
              >
                {treatmentTypeId === 2 ? (
                  <UilClipboardNotes size="24" color="#444E53" />
                ) : (
                  <UilEye size="24" color="#444E53" />
                )}
                <LabelNormal color="#444E53" fontSize="20">
                  {treatmentTypeId === 2
                    ? 'Datos de planificación'
                    : 'Visor de tratamiento'}
                </LabelNormal>
              </InlineContainer>
            </CardContainer>
          </Card>
        </div>
      )}
      {!loadingSkeleton ? (
        <>
          <Card
            padding="0px"
            border="none"
            minWidth="340px"
            borderRadius="25px"
          >
            <CardContainer style={{ borderRadius: 30, padding: '26px 34px' }}>
              <LabelCase
                onClick={() => {
                  setEdit(true);
                  setHistoryClinic(false);
                }}
                label={'Editar'}
                icon={'edit'}
              />
              <LabelCase
                onClick={() => {
                  setEdit(false);
                  setHistoryClinic(true);
                }}
                label={'Ver historial clinico'}
                icon={'file'}
                marginTop={'10'}
              />
              <LabelCase
                onClick={() => {
                  setEdit(false);
                  navigateToInvoices();
                }}
                label={'Facturas'}
                icon={'file-minus'}
                marginTop={'10'}
              />
              {statusType !== 'new_case' &&
                archived === false &&
                !(statusType === 'planning' && statusIndex === 7) && (
                  <LabelCase
                    onClick={() => handleOpenModal()}
                    label={'Mover a casos inactivos'}
                    icon={'archive'}
                    marginTop={'10'}
                  />
                )}
              {(statusType === 'new_case' ||
                archived === true ||
                (statusType === 'planning' && statusIndex === 7)) && (
                <LabelCase disabled />
              )}
            </CardContainer>
          </Card>
        </>
      ) : (
        <SkeletonActions />
      )}
    </div>
  );
};

export default Actions;
