import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_PATIENT } from '@graphql/query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_APPROVED_VISOR,
  MUTATION_CHANGE_MESSAGE_DOCTOR,
  MUTATION_APPROVED_BY_TEACHER,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import ModalWarning from '@components/Modal/ModalWarning';
import FeatherIcons from 'feather-icons-react';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import { UilExternalLinkAlt } from '@iconscout/react-unicons';
import { MUTATION_APPROVE_CASE_SS } from '../../graphql/mutation';
import ButtonSecondary from '../../components/shared/buttons/secondary';
import ButtonPrimary from '../../components/shared/buttons/primary';
import Info from '../../components/Icons/Info';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: ${({ margin }) => margin ?? '5'}px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const validCommentsButtonStatus = {
  planning: [1, 4, 12, 14, 15],
};

const validApproveButtonStatus = {
  planning: [1, 4, 12, 14, 15],
};

const validApproveTeacherButtonStatus = {
  planning: [13],
};

const validPaymentButtonStatus = {
  planning: [4],
};

const pausedCaseStatus = {
  new_case: [7, 9],
  planning: [2, 13],
};

const Case = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const role = localStorage.getItem('b360-role');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatment, setTreatment] = useState('');
  const [typeTreatment, setTypeTreatment] = useState('');

  const [visorUrl, setVisorUrl] = useState('');
  const [visorPatientUrl, setVisorPatientUrl] = useState('');
  const [messageDoctor, setMessageDoctor] = useState(false);
  const [software, setSoftware] = useState('ARCAD');
  const [linkvisordesk, setLinkVisorDesk] = useState('');
  const [treatmentStatus, setTreatmentStatus] = useState('');
  const [hasChangesCA, setHasChangesCA] = useState(false);

  const [makeSmart, setMakeSmart] = useState(false);
  const [channelStatus, setChannelStatus] = useState({});
  const [smartChecks, setSmartchecks] = useState([]);
  const [patientLink, setPatientLink] = useState('');

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setTreatment(patient.treatment_related.id);
        setTypeTreatment(patient.treatment);
        setVisorUrl(patient.treatment_related.visor_url);
        setVisorPatientUrl(patient.treatment_related.visor_patient_link);
        setMessageDoctor(patient.treatment_related.message_doctor);

        setLinkVisorDesk(patient.treatment_related.visor_desk_link);
        setSoftware(patient.treatment_related.planning_software);
        setTreatmentStatus(patient.treatment_related.treatmentStatus);
        setHasChangesCA(patient.treatment_related.has_changes_ca);

        setMakeSmart(patient.treatment_related.make_smartcheck);
        setSmartchecks(
          patient.treatment_related.smartsChecks.filter(
            (sm) => sm.status === 'Pending'
          )
        );
        const mp4File = patient.treatment_related.files.find((file) => {
          return file.name === 'link_visor_patient';
        });

        setPatientLink(
          mp4File
            ? `${process.env.REACT_APP_API_URL}/patients/${id}/treatment/form/${mp4File.file_name}`
            : patient.treatment_related.visor_patient_link
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const [getPatientRefresh] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        if (
          !localStorage.getItem(
            `modal-treatment-${patient.treatment_related.id}`
          )
        ) {
          if (
            patient.treatment_related.make_smartcheck &&
            patient.treatment_related.channels &&
            patient.treatment_related.channels.find(
              (c) =>
                c.channel_url ===
                `${
                  process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
                }private_chat_planning_treatment_${
                  patient.treatment_related.id
                }`
            ).status === 'inactive'
          ) {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() =>
                    localStorage.setItem(
                      `modal-treatment-${patient.treatment_related.id}`,
                      true
                    )
                  )
                }
              >
                <LabelNormal fontSize="22" color="#060809">
                  Conversación finalizada y Smart check habilitado
                </LabelNormal>
                <LabelNormal color="#4F4F4F" fontSize="17">
                  Agenda tu Smart check desde el perfil del caso para revisar tu
                  planificación.
                </LabelNormal>
              </ModalSuccess>
            );
          }
          if (
            !patient.treatment_related.make_smartcheck &&
            patient.treatment_related.channels &&
            patient.treatment_related.channels.find(
              (c) =>
                c.channel_url ===
                `${
                  process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
                }private_chat_planning_treatment_${
                  patient.treatment_related.id
                }`
            ).status === 'inactive'
          ) {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() =>
                    localStorage.setItem(
                      `modal-treatment-${patient.treatment_related.id}`,
                      true
                    )
                  )
                }
              >
                <LabelNormal
                  fontSize="22"
                  color="#060809"
                  style={{ paddingTop: 30 }}
                >
                  El caso está listo para aprobarse
                </LabelNormal>
                <LabelNormal
                  fontSize="17"
                  color="#4F4F4F"
                  style={{ paddingTop: 14, marginBottom: 15 }}
                >
                  Revisaremos los cambios y correcciones hechos en el visor para
                  definir el número de alineadores en tu planificación.
                </LabelNormal>
              </ModalSuccess>
            );
          }
        }
        setHasChangesCA(patient.treatment_related.has_changes_ca);
        setTreatmentStatus(patient.treatment_related.treatmentStatus);
        setMakeSmart(patient.treatment_related.make_smartcheck);
        setChannelStatus(
          patient.treatment_related.channels
            ? patient.treatment_related.channels.find(
                (c) =>
                  c.channel_url ===
                  `${
                    process.env.REACT_APP_DEVELOPE === 'true' ? 'test_' : ''
                  }private_chat_planning_treatment_${
                    patient.treatment_related.id
                  }`
              )
            : {}
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const [approvedVisor] = useMutation(MUTATION_APPROVED_VISOR, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() => modal.close(() => getPatient({ variables: { id } }))}
        >
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14 }}
          >
            En espera de confirmación
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Revisaremos los cambios y correcciones hechos en el visor para
            definir el número de alineadores en tu planificación.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [changeMessageDoctor] = useMutation(MUTATION_CHANGE_MESSAGE_DOCTOR, {
    onCompleted: (data) => {
      if (data.changeMessageDoctor) {
        modal.close();
        history.push(`/doctor/caso/mensajes-borgatta/${id}`);
      }
    },
  });

  const [approvedTeacher] = useMutation(MUTATION_APPROVED_BY_TEACHER, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() => modal.close(() => getPatient({ variables: { id } }))}
        >
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            El estudiante podrá mandar a manufactura cuando apruebe el caso.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onSubmitTeacher = async () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal color="#060809" fontSize="22" style={{ marginBottom: 14 }}>
          Este caso se mandará a producción
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Confirmar este caso aprobará la planificación y el alumno procederá al
          pago para su manufactura.
        </LabelNormal>
        <ButtonPrime
          label="Aprobar caso"
          onClick={() => approvedTeacher({ variables: { patient_id: id } })}
        />
      </ModalWarning>
    );
  };

  const [approveCaseSS] = useMutation(MUTATION_APPROVE_CASE_SS, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Iniciaremos la manufactura de tu FitJig. Te notificaremos cuando
            hayan sido enviados. Este proceso dura aproximadamente 8 días
            hábiles.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const confirm = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal color="#060809" fontSize="22" style={{ marginBottom: 14 }}>
          El caso se pausará
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginBottom: 14 }}>
          Podrás aprobar tu caso cuando finalice la conversación.
        </LabelNormal>
        <ButtonPrime
          label="Hacer comentarios"
          onClick={() => changeMessageDoctor({ variables: { patient_id: id } })}
        />
      </ModalWarning>
    );
  };

  const openVisorDesck = () => {
    //window.open(`arcadviewer://ARCADIEWER/CaseID={${idCase}}&UserID={${email}}&PatientName={${firstName} ${lastName}}`)
    window.open(linkvisordesk);
  };

  const openVisor = () => {
    if (
      !patientLink ||
      (treatmentStatus.index === 4 && treatmentStatus.type === 'planning')
    ) {
      return;
    }
    if (patientLink.includes('http')) {
      window.open(patientLink, '_blank');
    } else {
      window.open(`http://${patientLink}`, '_blank');
    }
  };

  useEffect(() => {
    getPatient({ variables: { id } });

    const intervalID = setInterval(() => {
      getPatientRefresh({ variables: { id } });
    }, 3000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="flex flex-col gap-12">
              {/* Title, breadcrumb and description */}
              <div className="flex flex-col gap-3 max-w-2xl">
                <Title>Planificación</Title>
                <Breadcrumb
                  elements={2}
                  onClick={() => history.push(`/doctor/tus-casos`)}
                  onSecondClick={() => history.push(`/doctor/caso/${id}`)}
                  mainLabel={'Tus casos'}
                  midLabel={`${firstName} ${lastName}`}
                  endLabel={'Planificación'}
                />
                <LabelNormal color="#7A7A7A" fontSize="17">
                  {software === 'ARCAD'
                    ? role === 'teacher'
                      ? 'Revisa el visor del tratamiento y aprueba la planificación para que el alumno pueda realizar el pago de manufactura.'
                      : 'Revisa tu planificación y haz cambios en el visor según requieras. Reporta esos cambios haciendo click en el botón Hacer comentarios. Si no necesitas hacer cambios en tu planeación, selecciona la opción No tengo comentarios y te notificaremos cuando puedas aprobar tu planeación.'
                    : typeTreatment === 'smile_system'
                    ? treatmentStatus.index === 12
                      ? 'Abre y descarga el video para compartirlo con tu paciente'
                      : 'Revisa el visor del tratamiento y agenda tu SmartCheck para aprobar el caso.'
                    : 'Revisa el visor del tratamiento y ve al pago para aprobar el caso. Si lo necesitas puedes hacer comentarios y te contestaremos lo mas pronto posible'}
                </LabelNormal>
              </div>
              {/* Visor section */}
              <div className="flex flex-col gap-3 max-w-5xl">
                <LabelNormal color="#757575" fontSize="22">
                  Visor de planificación
                </LabelNormal>
                <div className="flex flex-col gap-3">
                  {/* ARCAD visor links */}
                  {typeTreatment === 'cryst_aligner' && (
                    <div className="flex flex-col gap-3">
                      <p className="text-default font-medium text-prominence-default">
                        Para la revisión del tratamiento
                      </p>
                      <div className="flex flex-row gap-12">
                        <div className="flex flex-col gap-4 w-1/2">
                          <ButtonPrime
                            icon="eye"
                            iconSize="24"
                            size="19"
                            marginTop="0"
                            marginRight="0"
                            padding="12px 24px"
                            label={'Abrir visor en escritorio con ' + software}
                            onClick={() => openVisorDesck()}
                          />
                          <InlineContainer
                            style={{
                              alignContent: 'center',
                              alignItems: 'center',
                              gap: '3px',
                            }}
                          >
                            <LabelNormal
                              color="#757575"
                              fontSize="17"
                              margin="0"
                            >
                              ¿No lo tienes descargado? Descárgalo
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="17"
                              margin="0"
                              marginTop="0"
                              marginRight="0"
                              style={{
                                textDecorationLine: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_URL}/files/smilestudioinstaller.exe`,
                                  '_blank'
                                )
                              }
                            >
                              aquí.
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        <div className="flex flex-col gap-1 w-1/2">
                          <InlineContainer
                            style={{
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              visorUrl.includes('http')
                                ? window.open(visorUrl, '_blank')
                                : window.open(`http://${visorUrl}`, '_blank')
                            }
                          >
                            <FeatherIcons
                              icon="external-link"
                              color="#757575"
                            />
                            <LabelNormal fontSize="17" color="#757575">
                              Abrir visor en web
                            </LabelNormal>
                          </InlineContainer>
                          <LabelNormal fontSize="17" color="#757575" margin="0">
                            Para una mejor experiencia te recomendamos abrir el
                            visor en escritorio con ARCAD aunque puedes utilizar
                            el visor en web en caso de no contar con el
                            software.
                          </LabelNormal>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Patient link */}
                  <div className="flex flex-row gap-12">
                    <div className="flex flex-col gap-3">
                      <p className="text-default font-medium text-prominence-default ">
                        Para compartir con el paciente
                      </p>

                      {typeTreatment === 'cryst_aligner' ? (
                        <div
                          className="flex flex-row gap-1 cursor-pointer"
                          onClick={openVisor}
                        >
                          <UilExternalLinkAlt size="24" color="#757575" />
                          <LabelNormal color="#757575" fontSize="17" margin="0">
                            Abrir visor para compartir con paciente
                          </LabelNormal>
                        </div>
                      ) : (
                        <>
                          <div
                            className={`flex flex-row gap-1 ${
                              !patientLink ||
                              (treatmentStatus.index === 4 &&
                                treatmentStatus.type === 'planning')
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                            onClick={openVisor}
                          >
                            <UilExternalLinkAlt size="24" color="#757575" />
                            <LabelNormal
                              color="#757575"
                              fontSize="17"
                              margin="0"
                            >
                              Video de planificación Fit Jig para compartir con
                              paciente
                            </LabelNormal>
                          </div>
                          {treatmentStatus.index === 4 &&
                          treatmentStatus.type === 'planning' ? (
                            <LabelNormal
                              color="#7A7A7A"
                              fontSize="17"
                              margin="0"
                            >
                              Descarga el video para compartirlo
                            </LabelNormal>
                          ) : (
                            <LabelNormal
                              color="#7A7A7A"
                              fontSize="17"
                              margin="0"
                            >
                              Agenda tu Smartcheck y podrás consultar el visor
                              al finalizar la llamada.
                            </LabelNormal>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="text-divider" />
              {/* Approve section */}
              <div className="flex flex-col gap-4">
                <LabelNormal color="#757575" fontSize="22">
                  Aprobación de caso
                </LabelNormal>
                {role !== 'teacher' &&
                  !validPaymentButtonStatus[treatmentStatus.type]?.includes(
                    treatmentStatus.index
                  ) && (
                    <p className="text-default text-prominence-semiweak max-w-lg">
                      <b>¿Hiciste cambios en el visor?</b> Repórtalos haciendo
                      clic en el botón Hacer comentarios
                    </p>
                  )}
                {/* Buttons section */}
                {role === 'teacher' ? (
                  <div className="flex flex-row gap-6 max-w-xl">
                    <div className="w-auto">
                      <ButtonSecondary
                        outline
                        fontSize="19px"
                        padding="8px 24px"
                        onClick={() => onSubmitTeacher()}
                        disabled={
                          !Object.keys(
                            validApproveTeacherButtonStatus
                          ).includes(treatmentStatus.type) ||
                          !validApproveTeacherButtonStatus[
                            treatmentStatus.type
                          ].includes(treatmentStatus.index)
                        }
                      >
                        Aprobar caso
                      </ButtonSecondary>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row gap-6 max-w-xl">
                    {Object.keys(validPaymentButtonStatus).includes(
                      treatmentStatus.type
                    ) &&
                    validPaymentButtonStatus[treatmentStatus.type].includes(
                      treatmentStatus.index
                    ) ? (
                      <div className="w-auto">
                        <ButtonPrimary
                          size="md"
                          onClick={() => {
                            history.push(`/doctor/aprobar-caso/${id}`);
                          }}
                        >
                          Ir al pago
                        </ButtonPrimary>
                      </div>
                    ) : (
                      <>
                        <div className="w-auto">
                          <ButtonSecondary
                            outline
                            fontSize="19px"
                            padding="8px 24px"
                            onClick={() => confirm()}
                            disabled={
                              !Object.keys(validCommentsButtonStatus).includes(
                                treatmentStatus.type
                              ) ||
                              !validCommentsButtonStatus[
                                treatmentStatus.type
                              ].includes(treatmentStatus.index)
                            }
                          >
                            Hacer comentarios
                          </ButtonSecondary>
                        </div>
                        <div className="w-auto">
                          <ButtonPrimary
                            size="md"
                            disabled={
                              !Object.keys(validApproveButtonStatus).includes(
                                treatmentStatus.type
                              ) ||
                              !validApproveButtonStatus[
                                treatmentStatus.type
                              ].includes(treatmentStatus.index)
                            }
                            onClick={() => {
                              approvedVisor({
                                variables: { patient_id: id },
                              });
                            }}
                          >
                            No tengo comentarios
                          </ButtonPrimary>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {role !== 'teacher' &&
                  pausedCaseStatus[treatmentStatus.type]?.includes(
                    treatmentStatus.index
                  ) && (
                    <div className="flex gap-1">
                      <Info width="22" height="22" color="#EB9542" />
                      <p className="text-primary text-default">
                        {treatmentStatus.type === 'new_case'
                          ? 'Contesta a los comentarios en Mensajes a Borgatta'
                          : treatmentStatus.type === 'planning'
                          ? treatmentStatus.index === 13
                            ? 'Esperando aprobación de tu profesor'
                            : 'En espera de planificación'
                          : ''}
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
