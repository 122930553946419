import { Link } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';

const PlanificationButton = ({ role, patientId }) => (
  <Link
    to={{
      pathname: `/${
        role === 'teacher' ? 'profesor' : 'doctor'
      }/caso/planificacion/${patientId}`,
      state: { student: role === 'student' ? true : false },
    }}
    style={{ marginTop: 30 }}
  >
    <ButtonPrime label="Ver planificación" />
  </Link>
);

export default PlanificationButton;
