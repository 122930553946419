import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import Avatar from '@components/shared/Avatar';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  MUTATION_UPDATE_TEACHER,
  MUTATION_ADD_MEMBER_PHOTO,
} from '@graphql/mutation';
import { QUERY_GET_TEACHER } from '@graphql/query';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useHistory, useParams } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { loadPhotoProfileGender, validateEmail } from '../../utils';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const UpdateTeacher = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState({});
  const [idUser, setIdUser] = useState('');
  const [photo, setPhoto] = useState('');

  const [getTeacher] = useLazyQuery(QUERY_GET_TEACHER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.teacher) {
        const teacher = data.teacher;

        setIdUser(teacher.user.id);
        setFirstName(teacher.user.first_name);
        setLastName(teacher.user.last_name);
        setEmail(teacher.user.email);
        setGender(teacher.user.gender);
        setPhoto(teacher.user.photo_filename);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getTeacher({ variables: { id } });
  }, []);

  const [updateTeacher] = useMutation(MUTATION_UPDATE_TEACHER, {
    onCompleted: async (data) => {
      if (data.updateTeacher) {
        if (file.name) onSubmitFile();
        else
          modal.open(
            <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
              <LabelNormal
                style={{ marginTop: 20 }}
                color="#060809"
                fontSize="22"
              >
                Cambios en perfil guardados{' '}
              </LabelNormal>
            </ModalSuccess>
          );
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, email, gender]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (email === '' || !validateEmail(email)) {
      setDisabled(true);
    } else if (!gender) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    updateTeacher({
      variables: {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        gender,
        treatment: 'ambos',
      },
    });
  };

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: (data) => {
      if (data.addProfilePhoto) {
        getTeacher({ variables: { id } });
        setFile({});
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              style={{ marginTop: 20 }}
              color="#060809"
              fontSize="22"
            >
              Cambios en perfil guardados{' '}
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitFile = () => {
    addProfilePhoto({ variables: { file: file, user_id: +idUser } });
  };

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Editar perfil</Title>

          <div style={{ marginTop: 39 }}>
            <Avatar
              src={
                photo && !file.name
                  ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                  : file.name
                  ? photo
                  : loadPhotoProfileGender(gender)
              }
              floatButton={true}
              edit={!!photo}
              onChange={onChange}
              height="161"
              width="161"
              borderRadius="30"
            />
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre(s)"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="Nombre y apellidos"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div>
            <LabelInput>Género</LabelInput>
            <InlineContainer>
              <div className="radio" style={{ marginRight: 20 }}>
                <input
                  id="radio-1"
                  onClick={(e) => setGender('Masculino')}
                  checked={gender === 'Masculino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-1" className="radio-label">
                  Masculino
                </label>
              </div>
              <div className="radio">
                <input
                  id="radio-2"
                  onClick={(e) => setGender('Femenino')}
                  checked={gender === 'Femenino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-2" className="radio-label">
                  Femenino
                </label>
              </div>
            </InlineContainer>
          </div>

          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Guardar cambios"
              disabled={disabled}
              onClick={() => handlerSubmit()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default UpdateTeacher;
