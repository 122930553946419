import {
  DecoratorBlue,
  DecoratorYellow,
  LabelNormal,
} from '../../shared/Styled';

const TreatmentTypeTag = ({ treatmentTypeId }) => {
  return treatmentTypeId === 1 ? (
    <DecoratorBlue>
      <LabelNormal color="#FFF" fontSize="17">
        Cryst Aligner
      </LabelNormal>
    </DecoratorBlue>
  ) : (
    <DecoratorYellow>
      <LabelNormal color="#FFF" fontSize="17">
        Smile System
      </LabelNormal>
    </DecoratorYellow>
  );
};

export default TreatmentTypeTag;
