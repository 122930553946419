import React from 'react';
import styled from 'styled-components';
import DownloadError from './DownloadError';
import FileUploader from './FileUploader';

const ImageAvatar = styled.img`
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '30'}px;
  opacity: ${({ loader }) => (loader ? '0.3' : '1')};
  object-fit: cover;
`;

const Container = styled.div`
  position: relative;
  width: ${({ width }) => width ?? '161'}px;
`;

const FloatButton = styled.label`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  padding: 12px;
  background: linear-gradient(#e3772d, #f2b559);
  bottom: -7px;
  right: 8px;
  z-index: 2;
  cursor: pointer;
  &:hover {
    background: #d97617;
    color: 'white';
  }
`;

const Avatar = (props) => (
  <Container width={props.width}>
    {props.state && (
      <FileUploader loader={props.loader} fadeOut={props.fadeOut} />
    )}
    {props.error && <DownloadError />}
    <ImageAvatar
      src={props.src}
      height={props.height}
      width={props.width}
      loader={props.state || props.error}
      borderRadius={props.borderRadius}
    />
    {props.floatButton && (
      <FloatButton htmlFor="file">
        {props.edit ? (
          <img
            src={require('@images/icons/fi_edit.png')}
            style={{ height: 24, width: 24 }}
            alt="avatar"
          />
        ) : (
          <img
            src={require('@images/icons/plus.png')}
            style={{ height: 16, width: 16 }}
            alt="avatar"
          />
        )}
        <input
          id="file"
          accept=".jpg, .png, .jpeg"
          style={{ display: 'none' }}
          type="file"
          onChange={(e) => props.onChange(e)}
        />
      </FloatButton>
    )}
  </Container>
);

export default Avatar;
