import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  InlineContainer,
  LabelInput,
  LabelNormal,
  ScrollableBody,
  Title,
} from '../../shared/Styled';
import AvatarUploader from './AvatarUploader';
import { validateEmail, validatePhone } from '../../../utils';
import PatientSelector from './PatientSelector';
import CustomAlert from './CustomAlert';
import AlignersAlert from './AlignersAlert';
import PriceCard from './PriceCards';

const PatientInfo = forwardRef(
  (
    {
      patientId,
      doctor,
      setFilePhoto,
      treatment,
      setTreatment,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      gender,
      setGender,
      day,
      setDay,
      month,
      setMonth,
      year,
      setYear,
      phone,
      setPhone,
      email,
      setEmail,
      currentStep,
      registerPatient,
      updatePatient,
      photoUploadError,
      treatmentType,
      setTreatmentType,
      patient_id,
      aligners,
      setSelectedPatientData,
      selectedPatientData,
      isEditing,
    },
    ref
  ) => {
    const privateRef = useRef();

    const {
      id: doctorId,
      cryst_aligner: crystAligner,
      smile_system: smileSystem,
      // user: {
      //   country: { name: countryName },
      // },
    } = doctor ?? {
      id: null,
      cryst_aligner: false,
      smile_system: false,
      user: { country: { name: '' } },
    };
    let countryName = '';
    if (doctor && doctor.user && doctor.user.country) {
      countryName = doctor.user.country.name;
    }
    const [validName, setvalidName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validGender, setValidGender] = useState(true);
    const [validDate, setValidDate] = useState(true);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [refinementAligners, setRefinementAligners] = useState(aligners);
    const [error, setError] = useState(null);

    const emptyValidation = () => {
      let valid = true;
      if (day !== '' && month !== '' && year !== '') {
        let date = moment(`${year}-${month}-${day}`);
        if (!date.isValid()) valid = false;
        setValidDate(date.isValid());
      }
      if (
        currentStep === 1 &&
        firstName !== '' &&
        lastName !== '' &&
        treatment !== '' &&
        gender !== '' &&
        day !== '' &&
        month !== '' &&
        year !== '' &&
        (phone === '' || (phone !== '' && validatePhone(phone))) &&
        (email === '' || (email !== '' && validateEmail(email))) &&
        valid
      ) {
        if (patientId === null) {
          registerPatient({
            variables: {
              doctor_id: doctor.id,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
              birthdate: `${year}-${month}-${day}`,
              gender,
              treatment,
              treatment_id: selectedPatient,
            },
          });
        } else {
          updatePatient({
            variables: {
              patient_id: patientId,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
              birthdate: `${year}-${month}-${day}`,
              gender,
              treatment,
            },
          });
        }
        setError(null);
      } else {
        setError('*Selecciona un paciente para continuar.');
        if (firstName === '') {
          setvalidName(false);
        } else {
          setvalidName(true);
        }
        if (lastName === '') {
          setValidLastName(false);
        } else {
          setValidLastName(true);
        }
        if (day === '' || month === '' || year === '') {
          setValidDate(false);
        } else {
          setValidDate(true);
        }
        if (gender === '') {
          setValidGender(false);
        } else {
          setValidGender(true);
        }
      }
    };

    const publicRef = {
      elementRef: privateRef,
      instanceMethod: () => {
        // do cool stuff
      },
    };

    useImperativeHandle(ref, () => publicRef);

    const updatePatientInfo = (patient) => {
      if (patient.first_name) {
        setError(null);
        setSelectedPatientData(null);
      }
      setFirstName(patient.first_name);
      setLastName(patient.last_name);
      setEmail(patient.email || '');
      setPhone(patient.phone || '');
      setGender(patient.gender);
      setDay(moment(patient.birthdate).format('DD'));
      setMonth(moment(patient.birthdate).format('MM'));
      setYear(moment(patient.birthdate).format('YYYY'));
    };

    return (
      <ScrollableBody ref={privateRef}>
        <div className="col-md-12">
          <Title style={{ marginBottom: 5 }}>Datos del paciente</Title>
          <LabelNormal
            color="#757575"
            fontSize="17"
            style={{ marginBottom: 30 }}
          >
            Ingresa la información para guardar el perfil de tu paciente.
          </LabelNormal>
        </div>
        <div className="col-md-7">
          <InlineContainer>
            <AvatarUploader
              patientId={patientId}
              setFilePhoto={setFilePhoto}
              uploadError={photoUploadError}
            />
          </InlineContainer>
        </div>
        {/** Treatment Selector */}
        <div className="col-md-7">
          <div style={{ marginTop: 28 }}>
            <LabelInput>Tratamiento</LabelInput>
            <InlineContainer>
              {crystAligner && (
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="treatment-1"
                    name="treatment"
                    onClick={() => setTreatment('cryst_aligner')}
                    checked={treatment === 'cryst_aligner' ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                    disabled={patientId !== null}
                  />
                  <label htmlFor="treatment-1" className="radio-label">
                    Cryst Aligner
                  </label>
                </div>
              )}
              {smileSystem && (
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="treatment-2"
                    name="treatment"
                    onClick={() => {
                      setTreatment('smile_system');
                      setTreatmentType(false);
                      setSelectedPatient(null);
                    }}
                    checked={treatment === 'smile_system' ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                    disabled={patientId !== null}
                  />
                  <label htmlFor="treatment-2" className="radio-label">
                    Smile System
                  </label>
                </div>
              )}
            </InlineContainer>
          </div>
        </div>
        {/** End Treatment Selector */}
        {/** Treatment type Selector */}
        <div className="col-md-7">
          <div style={{ marginTop: 28 }}>
            <LabelInput>Tipo de registro</LabelInput>

            <InlineContainer>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="treatment-type-1"
                  name="treatment-type"
                  onClick={() => setTreatmentType(false)}
                  checked={treatmentType === false ? 'checked' : false}
                  type="radio"
                  onChange={() => {}}
                  disabled={patientId !== null}
                />
                <label htmlFor="treatment-type-1" className="radio-label">
                  Nuevo caso
                </label>
              </div>
              {treatment === 'cryst_aligner' && (
                <div className="radio">
                  <input
                    id="treatment-type-2"
                    name="treatment-type"
                    onClick={() => setTreatmentType(true)}
                    checked={treatmentType === true ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                    disabled={patientId !== null}
                  />
                  <label htmlFor="treatment-type-2" className="radio-label">
                    Refinamiento / Retratamiento
                  </label>
                </div>
              )}
            </InlineContainer>
          </div>
        </div>
        {/** End Treatment type Selector */}
        {/** Patient Selector */}
        {treatmentType && doctorId && (
          <div className="col-md-7">
            <div style={{ marginTop: 28 }}>
              <LabelInput>Ligar paciente</LabelInput>
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginTop: 13, marginLeft: 0, marginBottom: 13 }}
              >
                Elige un paciente para ver las opciones disponibles.
              </LabelNormal>
              <div className="col-md-9" style={{ padding: 0 }}>
                <PatientSelector
                  doctorId={doctorId}
                  patient_id={patient_id}
                  updatePatientInfo={updatePatientInfo}
                  setSelectedPatient={setSelectedPatient}
                  setExpirationDate={setExpirationDate}
                  setRefinementAligners={setRefinementAligners}
                  selectedPatientData={selectedPatientData}
                  setSelectedPatientData={setSelectedPatientData}
                  error={error}
                  isEditing={isEditing}
                  patientDataEditable={
                    isEditing
                      ? { id: patientId, name: `${firstName} ${lastName}` }
                      : null
                  }
                />
              </div>
              {!selectedPatient && (
                <div
                  className="col-md-10"
                  style={{ padding: 0, marginBottom: 32 }}
                >
                  <CustomAlert
                    description="Se muestran solo los pacientes que ya han recibido sus alineadores y están disponibles para refinamiento/retratamiento"
                    type="info"
                  />
                </div>
              )}
              {selectedPatient && expirationDate && (
                <AlignersAlert
                  type="info"
                  aligners={refinementAligners}
                  date={expirationDate}
                />
              )}
            </div>
          </div>
        )}
        {treatmentType && selectedPatient && (
          <div className="col-md-9">
            <div className="col-md-12" style={{ padding: 0, marginBottom: 32 }}>
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{
                  marginTop: 13,
                  marginLeft: 0,
                  marginBottom: 13,
                }}
              >
                Si te hacen falta alineadores, podrás comprar alguna de las
                siguientes opciones para{' '}
                <span style={{ fontWeight: 500 }}>Refinamiento:</span>
              </LabelNormal>
              <PriceCard />
            </div>
          </div>
        )}
        {/** End Patient Selector */}
        {!treatmentType && (
          <React.Fragment>
            <div className="col-md-7">
              <div style={{ marginTop: 38 }} className="grid">
                <InlineContainer>
                  <div>
                    <Input
                      placeholder="Nombre(s)"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      label="Nombre del paciente"
                      type="custom"
                      validation={validName}
                    />
                    {!validName && (
                      <InlineContainer>
                        <p
                          style={{
                            color: '#C60E02',
                            fontSize: 18,
                            margin: 0,
                            padding: 0,
                            marginTop: 5,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Introduce el nombre(s)
                        </p>
                      </InlineContainer>
                    )}
                  </div>
                  <div>
                    <span style={{ fontSize: 18, visibility: 'hidden' }}>
                      none
                    </span>
                    <Input
                      placeholder="Apellidos"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="custom"
                      validation={validLastName}
                    />
                    {!validLastName && (
                      <InlineContainer>
                        <p
                          style={{
                            color: '#C60E02',
                            fontSize: 18,
                            margin: 0,
                            padding: 0,
                            marginTop: 5,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Introduce los apellidos
                        </p>
                      </InlineContainer>
                    )}
                  </div>
                </InlineContainer>
              </div>
            </div>
            <div className="col-md-7">
              <div>
                <LabelInput>Sexo</LabelInput>
                <InlineContainer>
                  <div
                    className="radio"
                    style={{ marginRight: 20, width: 150 }}
                  >
                    <input
                      id="gender-1"
                      onClick={(e) => setGender('Masculino')}
                      checked={gender === 'Masculino' ? 'checked' : false}
                      name="gender"
                      type="radio"
                      onChange={() => {}}
                    />
                    <label htmlFor="gender-1" className="radio-label">
                      Hombre
                    </label>
                  </div>
                  <div
                    className="radio"
                    style={{ marginRight: 20, width: 150 }}
                  >
                    <input
                      id="gender-2"
                      onClick={(e) => setGender('Femenino')}
                      checked={gender === 'Femenino' ? 'checked' : false}
                      name="gender"
                      type="radio"
                      onChange={() => {}}
                    />
                    <label htmlFor="gender-2" className="radio-label">
                      Mujer
                    </label>
                  </div>
                </InlineContainer>
                {!validGender && (
                  <InlineContainer>
                    <p
                      style={{
                        color: '#C60E02',
                        fontSize: 18,
                        margin: 0,
                        padding: 0,
                        marginTop: 5,
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Indica el sexo
                    </p>
                  </InlineContainer>
                )}
              </div>
            </div>
            <div className="col-md-6" style={{ marginTop: 28 }}>
              <LabelInput>Fecha de nacimiento</LabelInput>
              <InlineContainer>
                <Input
                  placeholder="DD"
                  maxWidth="60px"
                  value={day}
                  onChange={setDay}
                  mask="date2"
                  type="day"
                  validDate={validDate}
                />
                <Input
                  placeholder="MM"
                  maxWidth="60px"
                  value={month}
                  onChange={setMonth}
                  mask="date2"
                  type="monthInput"
                  validDate={validDate}
                />
                <Input
                  placeholder="AAAA"
                  maxWidth="90px"
                  value={year}
                  onChange={setYear}
                  mask="date4"
                  validDate={validDate}
                />
              </InlineContainer>
              {!validDate && (
                <InlineContainer>
                  <p
                    style={{
                      color: '#C60E02',
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      marginTop: 5,
                      fontFamily: 'neue-montreal-regular',
                    }}
                  >
                    {(!validDate && day === '') || month === '' || year === ''
                      ? '*Introduce tu fecha de nacimiento'
                      : '*Fecha incorrecta'}
                  </p>
                </InlineContainer>
              )}
            </div>
            <div className="col-md-7">
              <div style={{ marginTop: 28 }}>
                <LabelInput>Teléfono (Opcional)</LabelInput>
                <InlineContainer>
                  <Input
                    readOnly
                    disabled
                    value={
                      countryName !== null
                        ? countryName === 'México'
                          ? '+52 '
                          : countryName === 'Panamá'
                          ? '+507 '
                          : '+502 '
                        : ''
                    }
                    backgroundColor="#F6F6F8"
                    maxWidth={countryName === 'México' ? '68px' : '75px'}
                  />
                  <Input
                    placeholder="Teléfono"
                    value={phone}
                    onChange={setPhone}
                    mask="phone"
                    validation="phone"
                  />
                </InlineContainer>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ marginTop: 38 }} className="grid">
                <Input
                  placeholder="Tu correo"
                  label="Email (Opcional)"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  maxWidth="300px"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="col-md-7" style={{ marginBottom: 85 }}>
          <ButtonPrime label="Siguiente" onClick={() => emptyValidation()} />
        </div>
      </ScrollableBody>
    );
  }
);

export default PatientInfo;
