import { Link } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';
import ButtonReactive from '@components/shared/ButtonReactive';
import { LabelNormal } from '../../shared/Styled';

const CTASection = ({
  id,
  treatmentId,
  firstName,
  lastName,
  treatment,
  bracketsSS,
  statusTreatment,
  pendingMessages,
  zoom_link,
  moreSmartchecks,
  changeMoreSmartchecks,
  planningSendReady,
  loading,
  progressUpload,
  refInput,
  onUploadSTL,
  HttpRequest,
  printingReady,
  thermoformingReady,
  refinedReady,
  bracesPlaced,
}) => {
  const answerMessages = (
    <div className="flex flex-col w-fit">
      <Link
        to={{
          pathname: `/app/tablero/soporte/${id}/${treatmentId}`,
          state: {
            treatmentId,
            ...statusTreatment,
            id,
          },
        }}
      >
        <ButtonPrime label="Contestar mensajes" />
      </Link>
    </div>
  );

  if (statusTreatment.type === 'new_case') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([3, 6].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonReactive
            background="#EB9542"
            path={`/app/tablero/caso/revision/${id}`}
            stateId={treatmentId}
            label={'Revisar archivos del paciente'}
          />
        </div>
      );
    } else if ([8].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonReactive
            background="#EB9542"
            path={`/app/tablero/caso/planificacion/${id}`}
            stateId={treatmentId}
            firstName={firstName}
            lastName={lastName}
            treatment={treatment}
            typebrackets={bracketsSS}
            label={'Generar planificación'}
          />
        </div>
      );
    }
  } else if (statusTreatment.type === 'planning') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1, 2].includes(statusTreatment.index)) {
      return (
        <Link
          to={{
            pathname: `/app/tablero/revision-planificacion/${id}`,
            state: { visorReview: true },
          }}
        >
          <ButtonPrime label="Ir a Revisión de visor" />
        </Link>
      );
    } else if ([7].includes(statusTreatment.index)) {
      return (
        <div style={{ marginLeft: 12 }}>
          <ButtonPrime
            style={{ padding: '9px 11px' }}
            fontSize={15}
            label="Ir a Smart check"
            onClick={() => window.open(zoom_link, '_blank')}
          />
        </div>
      );
    } else if ([9].includes(statusTreatment.index) && !moreSmartchecks) {
      return (
        <>
          <div className="flex flex-col w-fit" style={{ marginTop: 10 }}>
            <LabelNormal
              color="#3C4549"
              fontSize="18"
              style={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
              onClick={() =>
                changeMoreSmartchecks({
                  variables: { patient_id: id },
                })
              }
            >
              Solicitar un segundo Smart Check
            </LabelNormal>
            <Link
              to={{
                pathname: `/app/tablero/caso/revision-planificacion/${id}`,
                state: { visorReview: true },
              }}
            >
              <ButtonPrime label="Ir a planificación" />
            </Link>
          </div>
        </>
      );
    } else if ([12].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            label={
              loading && progressUpload > 0
                ? `Subiendo archivo: ${progressUpload}%`
                : 'Subir archivos'
            }
            onClick={() => refInput.current.click()}
            disabled={loading}
          />
          <input
            type="file"
            accept=".zip"
            style={{ display: 'none' }}
            ref={refInput}
            onChange={(e) => onUploadSTL(e)}
          />
        </div>
      );
    } else if ([15].includes(statusTreatment.index)) {
      return (
        <Link
          to={{
            pathname: `/app/tablero/revision-planificacion/${id}`,
            state: { visorReview: true },
          }}
        >
          <ButtonPrime label="Ir a Revisión de visor" />
        </Link>
      );
    }
  } else if (statusTreatment.type === 'printing') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            label="Descargar archivo"
            onClick={() => HttpRequest()}
          />
        </div>
      );
    } else if ([2].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            onClick={() =>
              printingReady({
                variables: { patient_id: id },
              })
            }
            label="Impresiones terminadas"
          />
        </div>
      );
    }
  } else if (statusTreatment.type === 'thermoforming') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            onClick={() =>
              thermoformingReady({
                variables: { patient_id: id },
              })
            }
            label="Termoformado Listo"
          />
        </div>
      );
    }
  } else if (statusTreatment.type === 'warehouse') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            onClick={() =>
              refinedReady({
                variables: { patient_id: id },
              })
            }
            label="Detallado Listo"
          />
        </div>
      );
    }
  } else if (statusTreatment.type === 'packing') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <ButtonPrime
            onClick={() =>
              bracesPlaced({
                variables: { patient_id: id },
              })
            }
            label="Brackets colocados"
          />
        </div>
      );
    }
  } else if (statusTreatment.type === 'shipping') {
    if (pendingMessages > 0) {
      return answerMessages;
    } else if ([1].includes(statusTreatment.index)) {
      return (
        <div className="flex flex-col w-fit">
          <Link
            to={{
              pathname: `/app/tablero/envio-tratamiento/${treatmentId}`,
              state: {},
            }}
          >
            <ButtonPrime label="Introducir guías" />
          </Link>
        </div>
      );
    }
  }
  return null;
};

export default CTASection;
