import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';

import ArcoRedondo from '@images/forms/arco-redonda.svg';
import Arco from '@images/forms/arco.svg';
import ArcoOvoide from '@images/forms/arco-avoide.svg';
import PlanosIncisalesRectos from '@images/forms/planos-incisales-rectos.svg';
import CurvaSonrisa from '@images/forms/curva-de-sonrisa.svg';
import RealoadIcon from '@images/reaload-icon.svg';

import Top1 from '@images/arcada-superior/top1.svg';
import Top2 from '@images/arcada-superior/top2.svg';
import Top3 from '@images/arcada-superior/top3.svg';
import Top4 from '@images/arcada-superior/top4.svg';
import Top5 from '@images/arcada-superior/top5.svg';
import Top6 from '@images/arcada-superior/top6.svg';
import Top7 from '@images/arcada-superior/top7.svg';
import Top8 from '@images/arcada-superior/top8.svg';

import Bottom1 from '@images/arcada-inferior/bottom1.svg';
import Bottom2 from '@images/arcada-inferior/bottom2.svg';
import Bottom3 from '@images/arcada-inferior/bottom3.svg';
import Bottom4 from '@images/arcada-inferior/bottom4.svg';
import Bottom5 from '@images/arcada-inferior/bottom5.svg';
import Bottom6 from '@images/arcada-inferior/bottom6.svg';
import Bottom7 from '@images/arcada-inferior/bottom7.svg';
import Bottom8 from '@images/arcada-inferior/bottom8.svg';

const tops = [Top1, Top2, Top3, Top4, Top5, Top6, Top7, Top8];
const bottoms = [
  Bottom1,
  Bottom2,
  Bottom3,
  Bottom4,
  Bottom5,
  Bottom6,
  Bottom7,
  Bottom8,
];

export const icons = { RealoadIcon };

export const archShapeImages = { ArcoRedondo, Arco, ArcoOvoide };

export const previousZoneImages = { PlanosIncisalesRectos, CurvaSonrisa };

export const odontogramIcons = {
  DienteAusente,
  DienteExtraer,
  PiesaExcluir,
  Implante,
  PiezaErupcion,
  Inscrustacion,
  ProtesisFija,
  DienteIncluidoRetenido,
  DienteConEndodoncia,
  ErosionDental,
  FracturaCorona,
  Restauracion,
};

export { bottoms, tops };
