import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MUTATION_APPROVE_CASE_SS } from '@graphql/mutation';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ButtonPrime from '@components/shared/ButtonPrime';
import { LabelNormal } from '../../shared/Styled';

const ChangesButton = ({
  role,
  patientId,
  treatmentTypeId,
  hasChangesSS,
  activeBasicChat,
}) => {
  const history = useHistory();
  const [errorOnApprove, setErrorOnApprove] = useState(false);

  const [approveCaseSS] = useMutation(MUTATION_APPROVE_CASE_SS, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.go(0))}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Iniciaremos la manufactura de tu FitJig. Te notificaremos cuando
            hayan sido enviados. Este proceso dura aproximadamente 8 días
            hábiles.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  return (
    <>
      {treatmentTypeId === 1 ? (
        <>
          <Link
            to={{
              pathname: `/${
                role === 'teacher' ? 'profesor' : 'doctor'
              }/aprobar-caso/${patientId}`,
              state: { treatmentId: patientId },
            }}
          >
            <ButtonPrime
              style={{ padding: '9px 16px' }}
              fontSize={15}
              label="Aprobar caso"
            />
          </Link>
        </>
      ) : (
        <>
          {errorOnApprove && (
            <div
              style={{
                marginBottom: 18,
                background: '#FFE5E3',
                border: '1px solid #E41D11',
                padding: '5px 10px 7px 10px',
                borderRadius: 7,
                maxWidth: 290,
              }}
            >
              <LabelNormal fontSize="14" color="#C60E02">
                *Espera a que Borgatta finalice la conversación
              </LabelNormal>
            </div>
          )}
          <ButtonPrime
            style={{ padding: '9px 11px' }}
            fontSize={15}
            label="Aprobar caso"
            onClick={() =>
              hasChangesSS && activeBasicChat
                ? setErrorOnApprove(true)
                : approveCaseSS({ variables: { patient_id: patientId } })
            }
          />
        </>
      )}
    </>
  );
};

export default ChangesButton;
