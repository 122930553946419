import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AppRoutes from '../routes/app';
import AuthRoutes from '../routes/auth';
import DoctorRoutes from '../routes/doctor';
import UniversityRoutes from '../routes/university';
import StudentRoutes from '../routes/student';
import TeacherRoutes from '../routes/teacher';
import { AuthContext } from '../provider/auth';
//import Loading from '../screens/Loading';

const Loading = () => <div />;

const Navigation = () => {
  const auth = useContext(AuthContext);
  const user = auth.user;

  return (
    <BrowserRouter>
      {user === null && <Loading />}
      {user === 'unauth' && (
        <Switch>
          <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
          <Redirect from="/" to="/auth/iniciar-sesion" />
        </Switch>
      )}
      {user === 'admin' && (
        <Switch>
          <Route path="/app" render={(props) => <AppRoutes {...props} />} />
          <Redirect from="/" to="/app/casa" />
        </Switch>
      )}
      {(user === 'doctor' || user === 'student') && (
        <Switch>
          <Route
            path="/doctor"
            render={(props) => <DoctorRoutes {...props} />}
          />
          <Redirect from="/" to="/doctor/tus-casos" />
        </Switch>
      )}
      {user === 'university' && (
        <Switch>
          <Route
            path="/universidad"
            render={(props) => <UniversityRoutes {...props} />}
          />
          <Redirect from="/" to="/universidad/casos" />
        </Switch>
      )}
      {/* {user === 'student' && (
                <Switch>
                    <Route path="/estudiante" render={(props) => <StudentRoutes {...props} />} />
                    <Redirect from="/" to="/estudiante/tus-casos" />
                </Switch>
            )} */}
      {user === 'teacher' && (
        <Switch>
          <Route
            path="/profesor"
            render={(props) => <TeacherRoutes {...props} />}
          />
          <Redirect from="/" to="/profesor/casos" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default Navigation;
