import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import InputSearch from '@components/shared/InputSearch';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonAction from '@components/shared/ButtonAction';
import Card from '@components/shared/Card';
import { useLazyQuery } from '@apollo/client';
import {
  QUERY_GET_ADMINS,
  QUERY_GET_DEPARTAMENT,
  QUERY_GET_MY_COUNTRIES,
} from '@graphql/query';
import { useQuery } from '@apollo/client';
import { loadPhotoProfileGender } from '../../utils';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: 9px;
  font-family: neue-montreal-regular;
  color: #757575;
  margin: 0px;
`;

const Team = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [departaments, setDepartaments] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedOption, setSelectedOption] = useState([1]);
  const [search, setSearch] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [getAdmins] = useLazyQuery(QUERY_GET_ADMINS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getAdminsByFilters) {
        setAdmins(data.getAdminsByFilters);
      }
      setLoadingSkeleton(false);
    },
    onError: (erro) => {
      setLoadingSkeleton(false);
    },
  });

  const [getDepartament] = useLazyQuery(QUERY_GET_DEPARTAMENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.departments.data) {
        setDepartaments(data.departments.data);
        setSelectedOption(data.departments.data.map((d) => d.id));
      }
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getDepartament({});
  }, []);

  useEffect(() => {
    if (selectedOption) {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
          search,
        },
      });
    } else {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
        },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (search !== '') {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
          search,
        },
      });
    } else {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (search !== '') {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
          search,
        },
      });
    } else {
      getAdmins({
        variables: {
          departments: selectedOption.map((o) => +o),
          countries: selectedCountries.map((o) => +o),
        },
      });
    }
  }, [selectedCountries]);

  const { loading, data } = useQuery(QUERY_GET_MY_COUNTRIES, {
    onCompleted: (data) => {
      setCountries(data.getCountries);
      setSelectedCountries(data.getCountries.map((country) => country.id));
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="equipo" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Buscar miembro"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <DropdownCustom
                label="Filtros"
                data={departaments}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <InlineContainer>
                {!loading && countries.length > 0 && (
                  <DropdownCustom
                    label="País"
                    data={countries}
                    selectedOption={selectedCountries}
                    setSelectedOption={setSelectedCountries}
                  />
                )}
              </InlineContainer>
            </InlineContainer>
            <ButtonAction
              label="Añadir usuario"
              icon
              to="/app/equipo/registrar-miembro"
            />
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 27 }}>
            {!loadingSkeleton ? (
              <div className="row">
                {admins.map((item) => (
                  <div style={{ padding: 8 }} key={Math.random()}>
                    <Card to={`/app/equipo/perfil-miembro/${item.id}`}>
                      <InlineContainer
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <img
                          src={
                            item.user.photo_filename
                              ? `${process.env.REACT_APP_API_URL}/users/${item.user.id}/profile/${item.user.photo_filename}`
                              : loadPhotoProfileGender(item.user.gender)
                          }
                          style={{
                            height: 36,
                            width: 36,
                            marginTop: 4,
                            borderRadius: 50,
                          }}
                          alt="user"
                        />
                        <LabelNormal style={{ textTransform: 'uppercase' }}>
                          {item.department.name}
                        </LabelNormal>
                      </InlineContainer>
                      <LabelNormal
                        style={{
                          color: '#000000',
                          fontSize: 15,
                          marginTop: 14,
                        }}
                      >{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div style={{ display: 'flex' }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Team;
