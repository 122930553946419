import { odontogramIcons } from './icons';

export const itemsTabs = [
  'Arcada y secciones',
  'Forma de arco, clases y zonas',
  'Instrucciones IPR y Torque',
  'Odontograma',
  'Observaciones',
];

export const odontograma = [
  {
    name: 'Diente ausente',
    icon: odontogramIcons?.DienteAusente,
    id: 'diente_ausente',
  },
  {
    name: 'Diente a extraer',
    icon: odontogramIcons?.DienteExtraer,
    id: 'diente_a_extraer',
  },
  {
    name: 'Diente a excluir de los movimientos',
    icon: odontogramIcons?.PiesaExcluir,
    id: 'diente_a_excluir_de_los_movimientos',
  },
  { name: 'Implante', icon: odontogramIcons?.Implante, id: 'implante' },
  {
    name: 'Pieza a excluir',
    icon: odontogramIcons?.PiesaExcluir,
    id: 'pieza_a_excluir',
  },
  {
    name: 'Diente en erupción',
    icon: odontogramIcons?.PiezaErupcion,
    id: 'diente_en_erupcion',
  },
  {
    name: 'Diente con prótesis fija',
    icon: odontogramIcons?.ProtesisFija,
    id: 'diente_con_protesis_fija',
  },
  {
    name: 'Diente incluido retenido',
    icon: odontogramIcons?.DienteIncluidoRetenido,
    id: 'diente_incluido_retenido',
  },
  {
    name: 'Diente con endodoncia',
    icon: odontogramIcons?.DienteConEndodoncia,
    id: 'diente_con_endodoncia',
  },
  {
    name: 'Diente con erosión dental',
    icon: odontogramIcons?.ErosionDental,
    id: 'diente_con_erosion_dental',
  },
  {
    name: 'Fractura en corona',
    icon: odontogramIcons?.FracturaCorona,
    id: 'fractura_en_corona',
  },
  {
    name: 'Diente con incrustación',
    icon: odontogramIcons?.Inscrustacion,
    id: 'diente_con_incrustacion',
  },
  {
    name: 'Diente con restauración',
    icon: odontogramIcons?.Restauracion,
    id: 'diente_con_restauracion',
  },
];
