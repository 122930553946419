import {
  Title,
  LabelNormal,
  InlineContainer,
  CardInlineContainer,
} from '@components/Cases/cases.style';
import LabelUnderline from '@components/shared/LabelUnderline';
import ButtonPrime from '@components/shared/ButtonPrime';

const Addresses = ({
  addresses,
  setChangeAddress,
  defaultAddress,
  changeDefaultAddress,
  addDefaultAddress,
}) => {
  return (
    <div className="bootstrap-wrapper">
      <div className="row">
        <div className="col-md-7" style={{ marginBottom: 100 }}>
          <Title style={{ marginBottom: 5 }}>Dirección de Envío</Title>
          <div style={{ marginTop: 10, marginBottom: 39 }}>
            <LabelNormal color="#757575" fontSize="17">
              A esta dirección enviaremos tu tratamiento.{' '}
            </LabelNormal>
          </div>
          <LabelNormal
            color="#454D58"
            fontSize="22"
            style={{ marginBottom: 30 }}
          >
            Direcciones guardados
          </LabelNormal>
          {addresses && addresses.length > 0 && (
            <>
              {addresses.map((address, index) => (
                <InlineContainer key={Math.random()}>
                  <div className="radio">
                    <input
                      name={`address-${index}`}
                      style={{
                        fontSize: 20,
                        padding: 5,
                        background: '#eee',
                      }}
                      checked={
                        address.id === defaultAddress.id ? 'checked' : false
                      }
                      onChange={() => {}}
                      type="radio"
                    />
                    <label
                      htmlFor={`address-${index}`}
                      onClick={() =>
                        changeDefaultAddress({
                          variables: { address_id: address.id },
                        })
                      }
                      className="radio-label"
                    ></label>
                  </div>
                  <CardInlineContainer
                    style={{
                      borderRadius: 10,
                      minHeight: 105,
                      maxWidth: 334,
                      marginTop: 10,
                      marginBottom: 10,
                      padding: 16,
                      marginLeft: 10,
                    }}
                  >
                    <LabelNormal
                      color="#3C4549"
                      fontSize="18"
                      style={{ marginBottom: 10 }}
                    >
                      {address.name ? address.name : ''}
                    </LabelNormal>
                    <LabelNormal
                      color="#757575"
                      fontSize="16"
                      style={{ marginBottom: 10, width: 302 }}
                    >
                      {address.street
                        ? `${address.street} ${address.number}, ${address.suburb}, ${address.postal_code} ${address.state}`
                        : ''}
                    </LabelNormal>
                  </CardInlineContainer>
                </InlineContainer>
              ))}
            </>
          )}
          <LabelUnderline
            color="#757575"
            fontSize="17"
            marginBottom="30px"
            onClick={addDefaultAddress}
            label={'Añadir dirección de envío'}
          />
          <ButtonPrime
            onClick={() => setChangeAddress(false)}
            label="Volver al pago"
          />
        </div>
      </div>
    </div>
  );
};

export default Addresses;
