import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import axios from 'axios';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Insights = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [powerBiToken, setPowerBiToken] = useState(null);

  useEffect(() => {
    setLoadingSkeleton(true);
    getToken();
    const timeout = setTimeout(() => {
      setLoadingSkeleton(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const getToken = async () => {
    const response = await axios.get(
      'https://api.masvida.acueducto.dev/api/bi/token',
      null,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = response.data;
    const { accessToken, embedUrl } = data;
    setEmbedUrl(embedUrl);
    setPowerBiToken(accessToken);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name='insights' />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className='bootstrap-wrapper'>
            <div className='row'>
              <div className='col-md-12'>
                <Title>Insights</Title>
              </div>
              <div
                className='col-md-12'
                style={{ marginTop: 10, height: '100vh' }}
              >
                {powerBiToken && (
                  <PowerBIEmbed
                    embedConfig={{
                      type: 'report',
                      embedUrl: embedUrl,
                      accessToken: powerBiToken,
                      settings: {
                        background: 1,
                        filterPaneEnabled: false,
                        navContentPaneEnabled: true,
                        panes: {
                          filters: {
                            expanded: false,
                            visible: false,
                          },
                          pageNavigation: {
                            visible: false,
                          },
                        },
                      },
                    }}
                    cssClassName='powerbi-embed'
                  />
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Insights;
