import React from 'react';
import styled from 'styled-components';
import ButtonClose from '../shared/ButtonClose';
import { modal } from '@components/ModalManagement';
import MapBrackets from '@components/DoctorFormSS/map';

const Inline = styled.div`
  display: flex;
  align-item: left;
  flex-direction: row;
`;

const LabelNormal = styled.p`
    font-size: ${(({fontSize}) => fontSize ? fontSize : '9')}px;
    font-family: neue-montreal-regular;
    color: ${(({color})=> color ? color : '#757575' )};
    margin: 5px;
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ModalBrackets = (props) => {

    return (
        <div className='modal' style={{maxWidth: 750, height: '100%', margin: 84}}>
            <Inline>
                <LabelNormal fontSize="26" color="#3C4549" bold>Posición de los brackets</LabelNormal>
                <ButtonClose onClick={() => modal.close()} top={'40px'}/>
            </Inline>
            <div>
                <div className='bootstrap-wrapper' style={{zIndex: 999}}>
                    <InlineContainer style={{alignItems:'center', marginTop: 20}}>
                        <LabelNormal fontSize="17" color="#3C4549">{props.formSSData.brackets.type}</LabelNormal>
                    </InlineContainer>
                    <MapBrackets disabled={true} initialNumerationTopLeft={19} initialNumerationTopRight={20} initialNumerationBottomRight={20} initialNumerationBottomLeft={49} width={38} height={79.25} topData={props.formSSData.brackets.up_positions_data ? JSON.parse(props.formSSData.brackets.up_positions_data) : ["0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0"]} setTopData={() => {}} bottomData={props.formSSData.brackets.lo_positions_data ? JSON.parse(props.formSSData.brackets.lo_positions_data) : ["0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0", "0,0"]} setBottomData={() => {}} disabledPointTop={props.formSSData.up_odontograma} disabledPointBottom={props.formSSData.lo_odontograma} />
                </div>
            </div>
        </div>
    );
}

export default ModalBrackets;