import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

const ButtonStyle = styled(Link)`
  border-radius: 69px;
  min-width: 180px;
  min-height: 46px;
  text-decoration: none;
  padding: 12px;
  background: ${({ background }) => (background ? background : '#C8C8C8')};
  display: flex;
  line-height: 19px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:disabled {
    cursor: default;
    background: #c8c8c8;
  }
  &:hover:enabled {
    background: ${({ background }) => (background ? '#D97617' : '#D97617')};
    color: ${({ color }) => (color ? 'white' : 'white')};
  }
  &:active:enabled {
    background: ${({ background }) => (background ? '#AB5D12' : '#AB5D12')};
    color: ${({ color }) => (color ? 'white' : 'white')};
  }
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#FFFFFF')};
  margin: 5px;
`;

const ButtonStyleBasic = styled.button`
  border-radius: 69px;
  min-width: 180px;
  min-height: 46px;
  background: ${({ background }) => (background ? background : '#E88322')};
  border: ${({ background }) =>
    background ? '2px solid #828282' : '2px solid transparent'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0')}px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0')}px;
  &:disabled {
    cursor: default;
    background: #c8c8c8;
    border: 2px solid transparent;
  }
  &:hover:enabled {
    background: ${({ background }) => (background ? '#3C4549' : '#D97617')};
    border: 2px solid transparent;
    ${LabelNormal} {
      color: #ffffff;
    }
  }
  &:active:enabled {
    background: ${({ background }) => (background ? '#4F4F4F' : '#AB5D12')};
    border: 2px solid transparent;
    ${LabelNormal} {
      color: #ffffff;
    }
  }
`;

const ButtonReactive = (props) => {
  let treatmentId = props.stateId;
  let firstName = props.firstName;
  let lastName = props.lastName;
  let treatment = props.treatment;
  let brackets = props.typebrackets;

  return (
    <>
      {props.onClick && props.notRelative && (
        <ButtonStyleBasic
          onClick={() => props.onClick()}
          marginTop={props.marginTop}
          marginRight={props.marginRight}
          disabled={props.disabled ? props.disabled : false}
          background={props.background}
        >
          <LabelNormal
            color={props.disabled ? '#FFFFFF' : props.color}
            fontSize="19"
          >
            {props.label}
          </LabelNormal>
        </ButtonStyleBasic>
      )}
      {props.onClick && !props.notRelative && (
        <ButtonStyleBasic
          style={{ zIndex: 999, position: 'relative' }}
          onClick={() => props.onClick()}
          marginTop={props.marginTop}
          marginRight={props.marginRight}
        >
          <LabelNormal color="#FBFBFC" fontSize="19">
            {props.label}
          </LabelNormal>
        </ButtonStyleBasic>
      )}
      {treatmentId && firstName && lastName && treatment && !brackets && (
        <ButtonStyle
          background={props.background}
          to={{
            pathname: props.path,
            state: { treatmentId, firstName, lastName, treatment },
          }}
        >
          <LabelNormal fontSize="19" color="#FFFFFF">
            {props.label}
          </LabelNormal>
        </ButtonStyle>
      )}
      {treatmentId && firstName && lastName && treatment && brackets && (
        <ButtonStyle
          background={props.background}
          to={{
            pathname: props.path,
            state: { treatmentId, firstName, lastName, treatment, brackets },
          }}
        >
          <LabelNormal fontSize="19" color="#FFFFFF">
            {props.label}
          </LabelNormal>
        </ButtonStyle>
      )}
      {treatmentId && !firstName && !lastName && !treatment && (
        <ButtonStyle
          background={props.background}
          to={{ pathname: props.path, state: { treatmentId } }}
        >
          <LabelNormal fontSize="19" color="#FFFFFF">
            {props.label}
          </LabelNormal>
        </ButtonStyle>
      )}
    </>
  );
};
export default ButtonReactive;
