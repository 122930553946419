import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_UPDATE_TEACHER } from '@graphql/mutation';
import { QUERY_GET_TEACHER } from '@graphql/query';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useHistory, useParams } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { validateEmail } from '../../../utils';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const UpdateTeacher = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [treatment, setTreatment] = useState('');
  const [errors, setErrors] = useState({});

  const [nameUniversity, setNameUniversity] = useState('');

  const [getTeacher] = useLazyQuery(QUERY_GET_TEACHER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.teacher) {
        const teacher = data.teacher;

        setNameUniversity(teacher.university.name);
        setTreatment(
          teacher.cryst_aligner && teacher.smile_system
            ? 'ambos'
            : teacher.cryst_aligner
            ? 'cryst_aligner'
            : 'smile_system'
        );

        setFirstName(teacher.user.first_name);
        setLastName(teacher.user.last_name);
        setEmail(teacher.user.email);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getTeacher({ variables: { id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateTeacher] = useMutation(MUTATION_UPDATE_TEACHER, {
    onCompleted: async (data) => {
      if (data.updateTeacher) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Profesor actualizado con éxito
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => {
      if (err.graphQLErrors) {
        const errors = err.graphQLErrors;
        errors.forEach((error) => {
          if (error.extensions?.category === 'validation') {
            const keys = Object.keys(error.extensions?.validation);
            keys.forEach((key) => {
              if (key === 'input.email') {
                setErrors({
                  ...errors,
                  email: 'Email duplicado',
                });
              }
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    validationForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, treatment]);

  const validationForms = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      treatment === ''
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    updateTeacher({
      variables: {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        treatment,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Editar un profesor</Title>
          <div style={{ marginTop: 10, marginBottom: 39 }}>
            <Breadcrumb
              elements={1}
              onClick={() => history.goBack()}
              mainLabel={`${nameUniversity}`}
              endLabel={'Editar un profesor'}
            />
          </div>

          <div className="flex flex-col gap-7">
            <div className="flex flex-row max-w-3xl">
              <div className="flex flex-col justify-end w-full">
                <Input
                  placeholder="Nombre(s)"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label="Nombre y apellidos"
                />
              </div>
              <div className="flex flex-col justify-end w-full">
                <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
                <Input
                  placeholder="Apellidos"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-row max-w-3xl">
              <div className="flex flex-col justify-end w-1/2">
                <Input
                  placeholder="Correo"
                  label="Correo"
                  type="email"
                  value={email}
                  outsideValidation={Object.keys(errors).includes('email')}
                  validationText={errors?.email ?? null}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => {
                    if (Object.keys(errors).includes('email')) {
                      const { email, ...props } = errors;
                      setErrors({ ...props });
                    }
                  }}
                  required
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 max-w-xl">
              <span className="font-neue-montreal font-medium text-lg text-prominence-default">
                Capacitaciones
              </span>
              <p className="font-neue-montreal text-default text-prominence-semiweak">
                Selecciona una capacitación para que los estudiantes puedan
                seleccionar a su profesor al dar de alta un caso.
              </p>
              <div className="flex flex-row gap-2">
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="treatment-1"
                    name="cryst_aligner"
                    onClick={() => setTreatment('cryst_aligner')}
                    checked={treatment === 'cryst_aligner' ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                  />
                  <label htmlFor="treatment-1" className="radio-label">
                    Cryst Aligner
                  </label>
                </div>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="treatment-2"
                    name="smile_system"
                    onClick={() => setTreatment('smile_system')}
                    checked={treatment === 'smile_system' ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                  />
                  <label htmlFor="treatment-2" className="radio-label">
                    Smile System
                  </label>
                </div>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="treatment-3"
                    name="ambos"
                    onClick={() => setTreatment('ambos')}
                    checked={treatment === 'ambos' ? 'checked' : false}
                    type="radio"
                    onChange={() => {}}
                  />
                  <label htmlFor="treatment-3" className="radio-label">
                    Ambos
                  </label>
                </div>
              </div>
            </div>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Guardar cambios"
              disabled={disabled}
              onClick={() => handlerSubmit()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default UpdateTeacher;
